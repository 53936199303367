import {
    IDirectiveFactory,
    IAttributes,
    IAugmentedJQuery
} from "angular";
import {
    IToolScope,
    Tool
} from "./tool";
import {
    ImageEditorController,
    IShape,
} from "../imageEditorCore";
import {
    IPointLike,
    Vector2,
    Triangle as TriangleGeometry,
    Traceable
} from "../../../utility/utils";
import ToolActivationContext from "./toolActivationContext";
import {
    TextBoxControl
} from "../imageEditorControls";
import {
    Triangle,
    Text
} from "../shapes/shapes";

interface ITextToolScope extends angular.IScope {
    model: ITextModel;
    config: ITextModel;
}

interface ITextModel extends IShape {
    type: "text" | string;
    font: string;
    text: string;
    fontSize: number | string;
    primaryColour: string;
    secondaryColour: string;
    width: number;
    height: number;
    points: [IPointLike];

    /** Unitless factor which bases the line height on the font height. */
    lineHeight: number;

    borderColour: string;
    borderThickness: number;
    paddingX: number;
    paddingY: number;
}


export class TextTool extends Tool < Text, ITextModel > {
    defaultFontSize = 14; //pts
    defaultLineHeight = 1.25;
    defaultBackgroundColour = "white";
    defaultColour = "black";
    defaultBorderColour = "black";
    defaultBorderThickness = 0.0; //Default to no border.
    defaultFont = "Arial";
    defaultPadding: IPointLike = {
        x: 0.1,
        y: 0.1
    }; //Default to 1mm on all sides.
    defaultSize: IPointLike = {
        x: 8,
        y: 3
    };
    /** Always show the selection shadow for the text tool. */
    protected $showShadow() {
        return true;
    }

    /** Whether to render the box model for the text box for debugging purposes. */
    private $renderBoxModel: boolean = false;

    /** Parses a floating point number out of a string, and checks that the string also contains
     * a unit definition.
     * @param text The text to parse.
     * @param units The units to check for. */
    parseUnits(text: string, units: "cm" | "pt" | "px"): number {
        const value = parseFloat(text);
        if (isNaN(value)) {
            this.$trace && this.$trace("Could not parse float from the start:", text);
            throw Error("Could not parse float from text");
        }
        if (text.lastIndexOf(units) < 0) {
            this.$trace && this.$trace(`No [${units}] units found in text:`, text);
            throw Error(`Could not parse [${units}] units from text`);
        }
        return value;
    }


    /** Gets a font size in points from the provided value by taking unitless numbers to be
     * be in points already, and ensuring that strings actually specify pt as the unit.
     * if a null or undefined font is provided, the default is used.
     * @returns A CSS string defining the size in points: "14pt"
     */
    getFontSizeString(fontSize: number | string): string {
        return this.getFontSize(fontSize) + "pt";
    }
    /** Gets a font size in points from the provided value by taking unitless numbers to be
     * be in points already, and ensuring that strings actually specify pt as the unit.
     * if a null or undefined font is provided, the default is used.
     * @returns A number defining the size in points: 14.
     */
    getFontSize(fontSize: number | string): number {
        let num = this.defaultFontSize;
        if (typeof fontSize === "number") {
            num = fontSize;
        } else if (typeof fontSize === "string") {
            num = this.parseUnits( < string > fontSize, "pt");
        }
        return num;
    }

    /** Checks that the object is a number and is not a NaN. */
    private isFiniteNumber(obj: any): obj is number {
        return typeof obj === "number" && !isNaN(obj);
    }

    protected $createViewModel(dm ? : ITextModel): Text {
        const cmToStage = this.cmToStage(1);
        dm = dm || < any > {};
        const points = (dm.points && dm.points.length > 0) ?
            dm.points.map(p => new Vector2(p).multiply(cmToStage)) :
            [{
                x: 0,
                y: 0
            }];
        const vm = new Text((dm.text) || "", points[0]);
        vm.backgroundColour = dm.secondaryColour || this.defaultBackgroundColour;
        vm.borderColour = dm.borderColour || this.defaultBorderColour;
        vm.borderThickness = this.cmToStage(this.isFiniteNumber(dm.borderThickness) ?
            dm.borderThickness :
            this.defaultBorderThickness);
        vm.colour = dm.primaryColour || this.defaultColour;
        vm.font = dm.font || this.defaultFont;
        if (this.isFiniteNumber(dm.fontSize)) {
            vm.fontSize = this.pointsToStage(dm.fontSize);
        } else if (typeof dm.fontSize === "string") {
            vm.fontSize = this.pointsToStage(this.parseUnits(dm.fontSize, "pt"));
        } else {
            vm.fontSize = this.pointsToStage(this.defaultFontSize);
        }
        vm.lineHeight = this.isFiniteNumber(dm.lineHeight) ?
            dm.lineHeight :
            this.defaultLineHeight;
        vm.padding = new Vector2(
            this.isFiniteNumber(dm.paddingX) ? dm.paddingX : this.defaultPadding.x,
            this.isFiniteNumber(dm.paddingY) ? dm.paddingY : this.defaultPadding.y
        ).multiply(cmToStage);
        vm.size = new Vector2(
            this.isFiniteNumber(dm.width) ? dm.width : this.defaultSize.x,
            this.isFiniteNumber(dm.height) ? dm.height : this.defaultSize.y
        ).multiply(cmToStage);
        if (this.compositeOp) {
            vm.ink.compositeOperation = this.compositeOp;
        }
        this.$copyTraceSettingsTo(vm);
        vm.debug = this.$renderBoxModel;
        return vm;
    }

    protected $updateDataModel(viewModel: Text = this.$viewModel, dataModel ? : ITextModel): ITextModel {
        const dm = dataModel || < ITextModel > {};
        const config = ( < ITextToolScope > < any > this.$scope).config;
        dm.type = "text";
        dm.text = viewModel.text;
        dm.points = [new Vector2(viewModel.position).multiply(this.stageToCm(1))];
        dm.secondaryColour = viewModel.backgroundColour;
        dm.borderColour = viewModel.borderColour;
        dm.borderThickness = this.stageToCm(viewModel.borderThickness);
        dm.primaryColour = viewModel.colour;
        dm.font = viewModel.font;
        dm.fontSize = this.getFontSizeString(this.stageToPoints(viewModel.fontSize));
        dm.lineHeight = viewModel.lineHeight;
        dm.paddingX = this.stageToCm(viewModel.padding.x);
        dm.paddingY = this.stageToCm(viewModel.padding.y);
        dm.width = this.stageToCm(viewModel.size.x);
        dm.height = this.stageToCm(viewModel.size.y);
        return dm;
    }

    private $createTextBox(initialText: string, context: ToolActivationContext): TextBoxControl {
        const ctrl = context.controls.createTextBoxControl(initialText);
        ctrl.observe("changed", this.$pipe.setViewModel("text"));
        return ctrl;
    }

    private $watchChanges(context: ToolActivationContext, model ? : ITextModel) {
        const {
            setViewModel,
            default: orDefault,
            unitToStage,
            processTuple
        } = this.$pipe;
        context.dispose.add(
            //Here we map defaults first to the original model value, and then to the default value.
            //This covers a corner case where the toolConfig doesn't have an option configured (such
            //as lineHeight), but a model actually may have it configured (via a migration). In that
            //case we don't want to overwrite the model's value with the default.
            this.watchConfig("primaryColour", orDefault(model && model.primaryColour, this.defaultColour), setViewModel("colour")),
            this.watchConfig("secondaryColour", orDefault(model && model.secondaryColour, this.defaultBackgroundColour), setViewModel("backgroundColour")),
            this.watchConfig("font", orDefault(model && model.font, this.defaultFont), setViewModel("font")),
            this.watchConfig("fontSize", orDefault(model && model.fontSize), v => this.getFontSize(v), unitToStage("pt"), setViewModel("fontSize")),
            this.watchConfig("lineHeight", orDefault(model && model.lineHeight, this.defaultLineHeight), setViewModel("lineHeight")),
            this.watchConfig("borderColour", orDefault(model && model.borderColour, this.defaultBorderColour), setViewModel("borderColour")),
            this.watchConfig("borderThickness", orDefault(model && model.borderThickness, this.defaultBorderThickness), unitToStage("cm"), setViewModel("borderThickness")),
            this.watchConfigGroup(["width", "height"],
                processTuple(...[model && model.width, model && model.height].map(orDefault)),
                processTuple(...[this.defaultSize.x, this.defaultSize.y].map(orDefault)),
                (size: [number, number]) =>
                new Vector2(size[0], size[1]).multiply(this.cmToStage(1)),
                processTuple(setViewModel("size"))),
            this.watchConfigGroup(["paddingX", "paddingY"],
                processTuple(...[model && model.paddingX, model && model.paddingY].map(orDefault)),
                processTuple(...[this.defaultPadding.x, this.defaultPadding.y].map(orDefault)),
                (padding: [number, number]) =>
                new Vector2(padding[0], padding[1]).multiply(this.cmToStage(1)),
                setViewModel("padding"))
        );
    }

    $createResizeShape(edgeLen: number, colour: string) {
        const tri = new TriangleGeometry(
                0, 0,
                edgeLen, 0,
                0, edgeLen)
            .translate(-this.$viewModel.shadowExtraThickness);
        return new Triangle(tri, undefined, undefined, colour);
    }

    private $createMoveAndResizeControls(context: ToolActivationContext) {
        //Create a grip control which resizes the text box. Draw it as a triangle on the bottom
        //right of the select outline.
        const resizeGripPos = new Vector2(this.$viewModel.position).add(this.$viewModel.size);
        const resizeGripShape = this.$createResizeShape(
            context.controls.defaultGripRadius * 2,
            context.controls.defaultGripColour);
        resizeGripShape.ink.rotation = 180;

        const resizeGrip = context.controls.createGripControl(resizeGripPos, resizeGripShape);
        resizeGrip.bind(this.$viewModel.size, () => this.update());
        //Create grip control which moves the text around.
        context.controls.createGripControl().bind(
            this.$viewModel.position,
            resizeGrip.ink,
            () => this.update());
    }

    activate(context: ToolActivationContext) {
        super.activate(context);
        const editModel = < ITextModel > context.editModel;
        let textBoxControl: TextBoxControl;

        /** A control used initially to place a text control when the user clicks. It is removed
         * once the text box has been placed. */
        if (this.$isEditing) {
            this.addAcceptDeselectButtons(context);
            textBoxControl = this.$createTextBox(editModel.text, context);
            this.$viewModel = this.$createViewModel(editModel);
            this.$watchChanges(context, editModel);
            context.scratchLayer.addChild(this.$viewModel.ink);
            this.$createMoveAndResizeControls(context);
        } else {
            let initialPlacementControl = context.controls.createInteractControl();
            initialPlacementControl.subscribe(
                p => {
                    this.addAcceptDeselectButtons(context);
                    textBoxControl = this.$createTextBox("", context);
                    this.$viewModel = this.$createViewModel(editModel);
                    this.$viewModel.position.set(p);
                    this.$watchChanges(context);
                    context.scratchLayer.addChild(this.$viewModel.ink);
                    this.update();
                },
                p => {
                    this.$viewModel.position.set(p);
                    this.update();
                },
                () => {
                    context.controls.remove(initialPlacementControl);
                    initialPlacementControl = null;
                    this.$createMoveAndResizeControls(context);
                },
                () => context.deselect(true));
        }

        function focusTextBox() {
            if (textBoxControl != null) {
                textBoxControl.focus();
            }
        }
        focusTextBox();
        context.observe("finished-interact", focusTextBox);

        context.observe("accept", () => {
            if (textBoxControl != null) {
                this.$viewModel.bakeTransform();
                context.addOrUpdate(this.$updateDataModel(this.$viewModel, editModel));
            }
            context.deselect(true);
        });
        context.observe("deselect", context.reactivate.bind(context));
        context.observe("delete", () => {
            context.removeEditShape();
            context.reactivate()
        });
    }

    /** Allows tracing to be configured, and provides an additional option
     * "toolManager.tools.text.renderBoxModel" for visually debugging the text box model
     * using similar colours to the chrome debug console. */
    setTracing(options: Traceable.Options & {
        "toolManager.tools.text.renderBoxModel" ? : boolean
    }) {
        super.setTracing(options);
        const renderBoxModel = options["toolManager.tools.text.renderBoxModel"];
        if (renderBoxModel != null) {
            this.$renderBoxModel = !!renderBoxModel;
        }
    }

    constructor(
        public id: string,
        ctrl: ImageEditorController,
        scope: IToolScope,
        public domStageContainer: string | HTMLElement | JQuery) {
        super(ctrl, scope, "text");
    }
}



//Allows text to be drawn on a canvas with configurable colour, font, and font size.
export default <IDirectiveFactory>(() =>
    ({
        require: "^mdsImageEditor",
        restrict: "E",
        scope: < {
            [boundProperty: string]: string
        } > {
            config: "="
        },


        link(scope: IToolScope, element: IAugmentedJQuery, attr: IAttributes, ctrl: ImageEditorController) {
            ctrl.tools.registerTool("text", new TextTool("text", ctrl, scope, element));
        }
    }));