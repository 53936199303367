import * as angular from "angular";
import { Glossary, ExamType, GlossaryCategory, BusinessModelService } from "../../../businessModels";


export interface IGlossaryPromptResult {
    entry: Glossary;
}

interface IGlossaryPromptDialogLocals {
    examTypes: Array<ExamType>;
    categories: Array<GlossaryCategory>;
    selectedCategory: GlossaryCategory;
}

interface Controller extends angular.IController { };
class Controller implements angular.IController, IGlossaryPromptDialogLocals {
    examTypes: Array<ExamType>;
    categories: Array<GlossaryCategory>;

    categoryText: string;
    selectedCategory: GlossaryCategory;

    entry: Glossary;

    static $inject = ["$q", "businessModels", "$mdDialog"];
    constructor(private readonly $q: angular.IQService, private readonly models: BusinessModelService, private readonly mdDialog: angular.material.IDialogService) {
        this.entry = models.Glossary.create("", "");
    }

    filterCategories(searchText: string): angular.IPromise<Array<GlossaryCategory>> {
        var defaultFilter = (x) => !x.isDeleted;

        if (!searchText || searchText.trim().length === 0)
            return this.$q.when(this.categories.filter(defaultFilter));

        return this.$q.when(this.categories.filter(defaultFilter).filter((category) => {
            return category.name && category.name.toUpperCase().indexOf(searchText.toUpperCase()) !== -1;
        }));
    }

    accept() {
        this.mdDialog.hide(this.createResult());
    }

    cancel() {
        this.entry.breezeEntity().entityAspect.rejectChanges();
        this.mdDialog.cancel();
    }

    private findCategoryByString(text: string): GlossaryCategory {
        for (var cat of this.categories) {
            if (cat.name.toUpperCase() === text.trim().toUpperCase())
                return cat;
        }
        return null;
    }

    private createResult(): IGlossaryPromptResult {
        let category = this.selectedCategory;

        if (!category) 
            category = this.findCategoryByString(this.categoryText);

        if (!category)
            category = this.models.GlossaryCategory.create(this.categoryText);

        this.entry.category = category;

        return {
            entry: this.entry
        };
    }
}

export class GlossaryPromptDialogOptions implements angular.material.IDialogOptions {
    constructor(examTypes: Array<ExamType>, categories: Array<GlossaryCategory>, selectedCategory: GlossaryCategory) {
        this.locals = {
            examTypes: examTypes,
            categories: categories,
            selectedCategory: selectedCategory
        }
    }

    locals: IGlossaryPromptDialogLocals;

    openFrom = "#admin-primary-button";
    controller = Controller;
    controllerAs = "$ctrl";
    bindToController = true;
    templateUrl = require('./glossaryPrompt.html');
}