import { module as ngModule } from "angular";
import { SearchResultViewModel } from "../../utility/search/search";

interface SearchResultsComponentController extends angular.IController {}
class SearchResultsComponentController {
    /** Results to display. */
    results: SearchResultViewModel[];
    /** Whether to navigate to the state for this search result on click. EG, go to the study or
     * patient. */
    redirect: boolean;

    /** Callback when item is clicked. We think the intention here was to allow some finer gained
     * control over whether navigation happens, but we're not sure it works any longer. */
    // tslint:disable-next-line:variable-name
    onSelect: ( {$result: SearchResultViewModel}) => boolean;

    resultSelected(event: Event, result: SearchResultViewModel) {
        if (this.onSelect) {
            return this.onSelect({ $result: result });
        }
        return true;
    }
}

export default ngModule("midas.utility.search.searchResults", [])
    .component("mdsSearchResults", {
        controller: SearchResultsComponentController,
        templateUrl: require("./mdsSearchResults.html"),
        bindings: {
            results: "=",
            redirect: "<",
            onSelect: "&"
        }
    });