import { IController } from "angular";
import examTypeNameConfigurationService, { IExamTypeMappingModel }
    from "./examTypeNameConfigurationService";

interface examTypeNameConfigurationFormController extends IController { }
class examTypeNameConfigurationFormController implements IController {

    private mapping: IExamTypeMappingModel;
    private prevMasterName: string;
    
    static $inject = ["examTypeNameConfigurationService"];
    constructor(private readonly mappingService: examTypeNameConfigurationService) {
        this.prevMasterName = "";
    }

    private onTypeChange(): void {
        if (this.mapping.masterName !== this.prevMasterName) {
            this.mappingService.examTypeList.filter(
                e => e.typeName === this.prevMasterName).forEach(e => e.isMapped = false);
            this.prevMasterName = this.mapping.masterName;
        }        
    }
}

export default <angular.IComponentOptions>{
    controller: examTypeNameConfigurationFormController,
    templateUrl: require('./examTypeNameConfigurationForm.html'),
    bindings: {
        mapping: "<"
    }
}