/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import * as angular from "angular";
angular.module("midas.utility.measurement.views").controller("EAortaCtrl", ["$scope", function($scope) {
 const { values } = $scope.measurements;
 const setAllNormal = function()  {
   values.POCAATransverseFindings.value = "normal < 3 cm throughout";
   values.POCAALongitudinalFindings.value = "normal < 3 cm throughout";
   values.POCAARetroperitonealHaematomaFindings.value = "none";
   values.POCAAFreeFluidFindings.value = "none";
   values.POCAARenalArteriesFindings.value = "normal";
   values.POCAALtIliacArteryFindings.value = "normal";
   values.POCAARtIliacArteryFindings.value = "normal";
   console.log("All Normal");
 };
 $scope.clickAllNormal = () => setAllNormal();
}
]);
// CoffeeScript
