import { IController } from "angular";
import { LoDashStatic } from "lodash";
import { IStudyTitle } from "./StudyTitle";
import {
  InstituteSettingsMetadataService
} from "../../config/instituteSettingsMetadata";
import {
  BusinessModelService,
  Institute,
  Study,
  ExamType,
  Modality,
  Physician,
  Technician,
  Site
} from "../../businessModels";
import { editModalityDialogOptions } from "./studyDetailsModalityCtrl";

class StudyDetailsController implements IController {

  private modalities: Modality[];
  private physicians: Physician[];
  private technicians: Technician[];
  private sites: Site[];
  private readonly study: Study;
  private readonly inst: Institute;
  private readonly title: IStudyTitle;    

  static $inject = ["$scope", "$q", "$mdDialog", "lodash", "businessModels", "instituteSettingsMetadata"];
  constructor(
    private readonly $scope: angular.IScope,
    private readonly $q: angular.IQService,
    private readonly $dialog: angular.material.IDialogService,
    private readonly _: LoDashStatic,
    private readonly models: BusinessModelService,
    private readonly instSettings: InstituteSettingsMetadataService
  ) { }

  $onInit() {
    this.title.text = "Details";
    this.title.clearButtons();
    this.title.backButton.show = false;
    this.title.acceptButton.disable();

    this.$scope.$watch(() => this.study.exams[0].type, (newType: ExamType, oldType: ExamType) => {
      if (newType && oldType && newType !== oldType) {
        this.models.save(() => {
          this.study.exams[0].images
            .filter(function (item) { return (item.imageKey !== null) })
            .forEach(img => img.delete());
        });
      }
    });

    this.$scope.$watch(() => this.study.modality, (newModality: Modality, oldModality: Modality) => {
      if (newModality && newModality !== oldModality) {
        this.study.modality.load();
      }
    });

    this.getDataForDropDowns();
  }

  private getDataForDropDowns() {
    return this.$q.all({
      mdl: this.models.Modality.list(),
      phys: (this.study.siteEntity == null) ? this.models.Physician.list() : this.models.Physician.listFilteredBySite(this.study.siteEntity),
      tech: (this.study.siteEntity == null) ? this.models.Technician.list() : this.models.Technician.listFilteredBySite(this.study.siteEntity),
      site: this.models.Site.list()
    })
    .then(result => {
      this.modalities = result.mdl;
      this.physicians = result.phys;
      this.technicians = result.tech;
      this.sites = result.site;
    });
  }

  updateDataForUsers() {
    return this.$q.all({
      phys: (this.study.siteEntity == null) ? this.models.Physician.list() : this.models.Physician.listFilteredBySite(this.study.siteEntity),
      tech: (this.study.siteEntity == null) ? this.models.Technician.list() : this.models.Technician.listFilteredBySite(this.study.siteEntity)
    })
    .then(result => {
      this.physicians = result.phys;
      this.technicians = result.tech;
    });
  }
    
  saveStudyDate(): angular.IPromise<void> {
    const value = this.study.reportedAt;
    return this.models.save(() => this.study.reportedAt = value);
  }

  saveExamDate(): angular.IPromise<void> {
    const value = this.study.exams[0].performedAt;
    return this.models.save(() => this.study.exams[0].performedAt = value);
  };

  moduleExamTypes(): ExamType[] {
    if (this.study.modality) {
      return this._.filter(
        this.models.ExamType.listActive(), et => et.modality === this.study.modality);
    } else { return []; }
  };

  updateModality(): void {
    const opt: angular.material.IDialogOptions = editModalityDialogOptions(this.study.id);
    this.$dialog.show(opt)
      .then(() => {                
          this.models.save();
      });
    }
}

export default <angular.IComponentOptions>{
  controller: StudyDetailsController,
  templateUrl: require('./studyDetails.html'),
  bindings: {
    study: '<',
    title: '<',
    inst: '<'
  }
}