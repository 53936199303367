import { ICacheFactoryService, ICacheObject } from "angular";

// Include all measurement templates in the build.
const requireTemplates = require["context"]('.', true, /\.html$/i);
const templates = (requireTemplates.keys() as string[]).reduce<{ [url: string]: string }>(
    (bag, url) => {
        bag[url] = requireTemplates(url);
        return bag;
    },
    {});

const leadingPathSymbols = /^[.\\/]*/;

/** Caches all HTML templates from this directory into the provided cache. The cache keys will have
 * no path information, so they can be requested as "single.html", etc. */
export function cacheTemplates(cache: ICacheObject) {
    for (const key of Object.keys(templates)) {
        cache.put(key.replace(leadingPathSymbols, ""), templates[key]);
    }
}