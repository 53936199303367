import { module as ngModule } from "angular";
import { Site } from "../../businessModels";
import { Events, IDisposable, ObservableCallback } from "../../utility/utils";
import { SearchTermService } from "../../utility/search/search";
import { ISearchTermProvider } from "../../utility/search/searchTermProviders";
import { IAuthService, serviceName as authServiceName } from "../../authService";

export class SessionSiteService {
  static $inject = ["searchTermService", "siteTermProvider", authServiceName];
  constructor(
    private readonly searchTermService: SearchTermService,
    private readonly siteTermProvider: ISearchTermProvider,
    authService: IAuthService) {
      authService.onUserChanged(() => this.clear());
    }

  private currentSite: Site;
  private listeners = new Events();

  public getCurrentSite(): Site {
    return this.currentSite;
  }

  public on(event: "change", fn: ObservableCallback<Site>): IDisposable {
    return this.listeners.observe(event, fn);
  }

  public select(site: Site): void {
    this.publish(site);
    this.searchTermService.replace(
      this.siteTermProvider.transform(site)
    );
  }

  public clear(): void {
    this.publish(null);
  }

  private publish(site: Site): void {
    this.listeners.notify("change", site);
  }
}

export const serviceName = "sessionSiteService";
export default ngModule("midas.users.sessionSiteService", [])
  .service(serviceName, SessionSiteService);