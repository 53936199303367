/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */

export default [() =>
  ({
    restrict: "A",
    require: "^mdsDiagramContainer",
    link($scope, $element, $attrs, $ctrl) {
      if ($ctrl.getVariant() !== "Readonly") {
        $element.css('cursor', 'pointer');
        return $element.on('click', function() { 
          const measurements = $scope.$eval($attrs.mdsSetMeasurement);
          for (let key in measurements) { $ctrl.setMeasurement(key, measurements[key]); }
          $scope.$apply();
        });
      }
    }
  })

];
