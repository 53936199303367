import {
  IController,
  IPromise,
  IQService
} from "angular";
import "angular-material";
import {
  IMappingViewModel,
  IDicomTagViewModel,
  DicomViewModelFactory
} from "./dicomPropertyMapping.models";
import { IEntityProperty, construct as constructStudyProperties } from "./studyProperties";
import { createDialogOptions } from "./dicomPropertyMappingPrompt";
import {
  BusinessModelService,
  ExamType,
  Physician,
  Technician,
  StudyPropertyDerivedFromDicom,
  Institute,
  Site
} from "../../../businessModels";

class DefaultExamTypeMatchController implements IController {
  dicomTags = new Array<IDicomTagViewModel>();  
  mappings: IMappingViewModel[];
  filteredMappings: IMappingViewModel[];
  studyProperties = new Array<IEntityProperty>();  
  selectedStudyProperty: IEntityProperty;
  totalItems: number;
  currentPage: number;
  itemsPerPage: number;
  maxSize: number; //Number of pager buttons to show

  static $inject = ["businessModels", "$q", "$mdToast", "$mdDialog"];
  constructor(
    private readonly models: BusinessModelService,
    private readonly $q: IQService,
    private readonly $mdToast: angular.material.IToastService,
    private readonly $mdDialog: angular.material.IDialogService) {
  }

  $onInit = () => {
    this.dicomTags = DicomViewModelFactory.getViewModels();
    this.populateStudyProperties().then(() => this.populateMappings());

    //Set pagination
    this.itemsPerPage = 5;        
    this.currentPage = 1;
    this.maxSize = 5;
  }

  /** Save and deselect a mapping.  */
  saveAndClose(mapping: IMappingViewModel): IPromise<void> {
    return this.saveMapping(mapping).then(() => {
      mapping.selected = false;
    });
  }

  /** Discard mapping changes and deselect. */
  discardAndClose(mapping: IMappingViewModel): void {
    this.copyToViewModel(mapping);
    mapping.selected = false;
  }

  /** Retrieve requirements and populate study property list. */
  populateStudyProperties(): IPromise<void> {
    const modules = this.models.Modality.listNow();
    const examTypes: ExamType[] = this.models.ExamType.listActive();
    const physWait: IPromise<Physician[]> = this.models.Physician.list();
    const techWait: IPromise<Technician[]> = this.models.Technician.list();
    const siteWait: IPromise<Site[]> = this.models.Site.list();
    const waitAll: IPromise<any> = this.$q.all([physWait, techWait, siteWait]).then(results => {
      const physicians: Physician[] = results[0] as Physician[];
      const technicians: Technician[] = results[1] as Technician[];
      const sites: Site[] = results[2] as Site[];
      this.studyProperties = constructStudyProperties(modules, examTypes, physicians, technicians, sites);
    });
    return waitAll;
  }

  /** Create a mapping view model from a business model. */
  private viewModelFromBusiness(mapping: StudyPropertyDerivedFromDicom): IMappingViewModel {
    return {
      dicomTag: this.getDicomTag(mapping.dicomTag),
      dicomValue: mapping.matchValue,
      studyProperty: this.getStudyPropertyByPath(mapping.studyProperty),
      studyValue: mapping.derivedValue,
      entity: mapping
    };
  }

  /** Find a study property by the server model path. */
  getStudyPropertyByPath(path: string): IEntityProperty {
    for (const key in this.studyProperties) {
      if (this.studyProperties.hasOwnProperty(key)) {
        const obj: IEntityProperty = this.studyProperties[key];
        if (obj.path === path) {
          return obj;
        }
      }
    }
    return null;
  }

  /** Find a DICOm tag view model from the full tag string representation. */
  getDicomTag(dicomTag: string): IDicomTagViewModel {
    dicomTag = dicomTag.replace("(", "");
    dicomTag = dicomTag.replace(")", "");
    const bits: string[] = dicomTag.split(",");
    if (bits.length !== 2) {
      return null;
    }
    const group: string = bits[0];
    const element: string = bits[1];
    for (const tag of this.dicomTags) {
      if (tag.group === group && tag.element === element) {
        return tag;
      }
    }
    return null;
  }

  /** Retrieve DICOM mappings from API and populate. */
  populateMappings(): IPromise<void> {
    this.models.Institute.find({
      id: this.models.Institute.current.id
    }, "studyPropertiesDerivedFromDicom")
    .then((institute: Institute) => {
      this.mappings = institute.studyPropertiesDerivedFromDicom.map(x => this.viewModelFromBusiness(x));
      this.filteredMappings = this.mappings;
      this.totalItems = this.mappings.length;
    });
    return this.$q.when();
  }

  applyFilter() {
    if (this.selectedStudyProperty != null)
      this.filteredMappings = this.mappings.filter(m => m.studyProperty.display == this.selectedStudyProperty.display);
    else this.filteredMappings = this.mappings;
    if (this.filteredMappings) this.totalItems = this.filteredMappings.length;
  }

  /** Launch a dialog for entry of new DICOM mappings. */
  promptNewMapping(): void {
    const opt: angular.material.IDialogOptions = 
      createDialogOptions(this.dicomTags, this.studyProperties);
    this.$mdDialog.show(opt).then((x: IMappingViewModel) => {
      this.models.save(() => {
        const entity: StudyPropertyDerivedFromDicom = this.models.StudyPropertyDerivedFromDicom.create();
        x.entity = entity;
        this.copyToEntity(x);
      })
      .catch(_ => this.$mdToast.showSimple(
        "There was an issue saving the new mapping. Please try again and contact support if this problem persists."))
        .then(() => this.populateMappings()
          .then(() => this.$mdToast.showSimple("Mapping added")));
    });
  }

  /** Commit pending changes to the business model and save. */
  saveMapping(mapping: IMappingViewModel): IPromise<void> {
    return this.models.save(() => this.copyToEntity(mapping));
  }

  /** Copy the business model's properties to the view model.
   * This can act as a 'revert' function for pending changes.
   */
  copyToViewModel(mapping: IMappingViewModel): void {
    mapping.dicomValue = mapping.entity.matchValue;
    mapping.dicomTag = this.getDicomTag(mapping.entity.dicomTag);
    mapping.studyValue = mapping.entity.derivedValue;
    mapping.studyProperty = this.getStudyPropertyByPath(mapping.entity.studyProperty);
  }

  /** Commit pending changes to the business model. */
  copyToEntity(mapping: IMappingViewModel): void {
    mapping.entity.dicomTag = `${mapping.dicomTag.group},${mapping.dicomTag.element}`;
    mapping.entity.matchValue = mapping.dicomValue;
    mapping.entity.derivedValue = mapping.studyValue;
    mapping.entity.studyProperty = mapping.studyProperty.path;
  }

  /** Mark mapping business model for deletion and commit. */
  deleteMapping(mapping: IMappingViewModel): IPromise<void> {
    return this.models.save(() => mapping.entity.markForHardDeletion())
    .then(() => {
      this.populateMappings().then(() => this.$mdToast.showSimple("Mapping deleted."));
    })
    .catch(() => this.$mdToast.showSimple("An error occurred while attempting to delete a mapping."));
  }
}

export default <angular.IComponentOptions> {
  controller: DefaultExamTypeMatchController,
  templateUrl: require("./dicomPropertyMappingList.component.html")
};