import { module as ngModule, IScope } from "angular";
import "angular-ui-router";
import midasConfigModule from "../../config/config"; // For $mdToast decorator.
import DocumentsController from "./documentsCtrl";
import DiagramEditCtrl from "./diagramEditCtrl";
import notesModule from "./notes/notes";
import ChartsController from "./chartsCtrl";
import DuplicateController from "./duplicatesCtrl";
import DicomController from "./importDicomCtrl";
import ImportNonDicomCtrl from "./importNonDicomCtrl";
import MeasurementsController from "./measurementsCtrl";
import NewStudyController from "./newCtrl";
import pendingCtrlModule from "./pendingCtrl";
import StudyPinImageController from "./pinImage";
import PreviewController from "./previewCtrl";
import studyDetailsComponent from "./studyDetails";
import StudyDiagramSummaryController from "./studyDiagramSummary";
import StudyImageDetailsController from "./studyImageDetailsCtrl";
import StudyTabsController from "./studyTabsCtrl";
import StudySummaryController from "./studySummaryCtrl";
import PromptLoseChangesAndNavigateService from "./promptLoseChangesAndNavigate";
import { PatternService } from "../../utility/drawing/patterns";
import imageEditorModule from "../../utility/drawing/imageEditor";
import layoutModule from "../../utility/layout/layout";
import reportStateModule from "./reports";

import snapModule from "angular-snap"; // For study summary control.
import * as introModule from "angular-intro"; // Used by study summary control
import pdfJsModule from "../../pdfjs.module"; // Used by previewCtrl.

import { BusinessModelService, Study, Institute } from "../../businessModels";
import { IStudyTitle, IStudyTitleButton } from "./StudyTitle";
import DuplicatePatientService from "../patients/duplicates/duplicateDetection.service";

import "angular-file-saver";

export default ngModule("midas.midas.studies", [
        midasConfigModule.name,
        notesModule.name,
        imageEditorModule.name,
        layoutModule.name,
        introModule.name,
        snapModule,
        pdfJsModule.name,
        studyDetailsComponent.name,
        reportStateModule.name,
        "ngFileSaver",
        pendingCtrlModule.name
    ])
    .controller("documentsCtrl", DocumentsController)
    .controller("diagramEditCtrl", DiagramEditCtrl)
    .controller("chartsCtrl", ChartsController)
    .controller("duplicatesCtrl", DuplicateController)
    .controller("importDicomCtrl", DicomController)
    .controller("importNonDicomCtrl", ImportNonDicomCtrl)
    .controller("measurementsCtrl", MeasurementsController)
    .controller("studyNewCtrl", NewStudyController)
    .controller("studyPinImageCtrl", StudyPinImageController)
    .controller("reportPreviewCtrl", PreviewController)
    .controller("studyDiagramsCtrl", StudyDiagramSummaryController)
    .controller("studyImageDetailsCtrl", StudyImageDetailsController)
    .controller("studySummaryCtrl", StudySummaryController)
    .controller("studyTabsCtrl", StudyTabsController)
    .service("promptLoseChangesAndNavigate", PromptLoseChangesAndNavigateService)
    .service("duplicatePatients", DuplicatePatientService)
    .config(["layoutStateProvider", (stateProvider: angular.ui.IStateProvider) => stateProvider
        .state("midas.studies.view.documents", {
            url: "/documents",
            views: {
                "content@midas.studies.view": {
                    templateUrl: require("./documents.html"),
                    controller: "documentsCtrl as $ctrl"
                }
            }
        }).state("midas.studies.view.diagrams.new", {
            url: "/new/:templateId",
            views: {
                "content@midas.studies.view": {
                    templateUrl: require("./diagramEdit.html"),
                    controller: "diagramEditCtrl"
                }
            },
            resolve: {
                patterns: ["patternService", (patternService: PatternService) => patternService.init()]
            }
        }).state("midas.studies.view.diagrams.edit", {
            url: "/edit/:templateId",
            views: {
                "content@midas.studies.view": {
                    templateUrl: require("./diagramEdit.html"),
                    controller: "diagramEditCtrl"
                }
            },
            resolve: {
                patterns: ["patternService", (patternService: PatternService) => patternService.init()]
            }
        }).state("midas.studies", {
            url: "/studies",
            abstract: true
        }).state("midas.studies.new", {
            url: "/new/:patientId",
            views: {
                "work@midas": {
                    templateUrl: require("./new.html"),
                    controller: "studyNewCtrl"
                },
                "reference@midas": {
                    template: ""
                }
            }
        }).state("midas.studies.importDicom", {
            url: "/dicomImport",
            views: {
                "work@midas": {
                    templateUrl: require("./importDicom.html"),
                    controller: "importDicomCtrl"
                },
                "reference@midas": {
                    templateUrl: require("./duplicates.html"),
                    controller: "duplicatesCtrl"
                }
            }
        }).state("midas.studies.importNonDicom", {
            url: "/import",
            views: {
                "work@midas": {
                    templateUrl: require("./importNonDicom.html"),
                    controller: "importNonDicomCtrl"
                },
                "reference@midas": {
                    templateUrl: require("./duplicates.html"),
                    controller: "duplicatesCtrl"
                }
            }
        }).state("midas.studies.pending", {
            url: "/pending/:studyId",
            views: {
                "work@midas": {
                    templateUrl: require("./pending.html"),
                    controller: "pendingCtrl"
                },
                "reference@midas": {
                    templateUrl: require("./duplicates.html"),
                    controller: "duplicatesCtrl"
                }
            }
        }).state("midas.studies.view", {
                url: "/:studyId",
                abstract: true,
                views: {
                    "work@midas": {
                        templateUrl: require("./studySummary.html"),
                        controller: "studySummaryCtrl as ctrl"
                    },
                    "tabs@midas.studies.view": {
                        templateUrl: require("./studyTabs.html"),
                        controller: "studyTabsCtrl"
                    },
                    "content@midas.studies.view": {
                        controller: ["$scope", "study", "title", "inst", (
                            $scope: IScope,
                            study: Study,
                            title: IStudyTitle,
                            inst: Institute) => {
                                $scope.study = study;
                                $scope.title = title;
                                $scope.inst = inst;
                        }],
                        template: "<study-details study='study', title='title', inst='inst'></study-details>"
                    },
                    "reference@midas": {
                        templateUrl: require("../reference/reference.html"),
                        controller: "referenceCtrl"
                    }
                },
                resolve: {
                    study: ["businessModels", "$stateParams",
                    (businessModels: BusinessModelService, $stateParams: angular.ui.IStateParamsService) =>
                        businessModels.Study.find({ id: $stateParams.studyId },
                            "patient.person.personTitleType,patient.documents,modality,status,physician," +
                            "technician,exams.studyType.templates," +
                            "exams.diagrams,exams.imageLinks.image,exams.measurementValues," +
                            "exams.jobs.job,documents,charts.image,notes")
                            .then(study => {
                                if (study == null || study.isDeleted) {
                                    throw new Error("Study Not Found");
                                }
                                return study.modality.load().then(() => study);
                            })
                    ],
                    history: ["businessModels", "study", (businessModels, study) => businessModels.Study.list({
                        "patient.id": study.patient.id
                    }, "exams.diagrams")],
                    title: ["$rootScope", "$stateParams", "$q",
                    function(
                      $rootScope: angular.IRootScopeService,
                      $stateParams: angular.ui.IStateParamsService,
                      $q: angular.IQService) {
                        const titleLocal = {
                            text: "Loading...",
                            buttons: [],
                            clearButtons() {
                                if (!this.buttons) {
                                    this.buttons = [];
                                } else {
                                    this.buttons = this.buttons.filter(button => button.keep);
                                }
                            },
                            addButton(obj: IStudyTitleButton) {
                                if (!obj.show) {
                                    obj.show = () => true;
                                }
                                if (obj.confirmText) {
                                    const {
                                        execute
                                    } = obj;
                                    obj.execute = () => obj.confirming = true;
                                    obj.confirm = function() {
                                        let result = execute();
                                        result = $q.when(result);
                                        return result.then(() => obj.confirming = false);
                                    };
                                    obj.cancel = () => obj.confirming = false;
                                }
                                if (typeof obj.icon === "string") {
                                  const str = obj.icon;
                                  obj.icon = () => str;
                                }
                                return this.buttons.push(obj);
                            },
                            backButton: {
                                show: false,
                                url: `#/studies/${$stateParams.studyId}`
                            },
                            acceptButton: {
                                show: false,
                                enabled: false,
                                loading() {
                                    this.show = true;
                                    return this.enabled = false;
                                },
                                ready() {
                                    this.show = true;
                                    return this.enabled = true;
                                },
                                disable() {
                                    this.show = false;
                                    return this.enabled = false;
                                }
                            }
                        };

                        return titleLocal;
                    }]
                }
        }).state("midas.studies.view.details", {
            url: "/details",
            views: {
                "content@midas.studies.view": {
                    controller: ["$scope", "study", "title", "inst", (
                        $scope: IScope,
                        study: Study,
                        title: IStudyTitle,
                        inst: Institute) => {
                            $scope.study = study;
                            $scope.title = title;
                            $scope.inst = inst;
                    }],
                    template: "<study-details study='study', title='title', inst='inst'></study-details>"
                }
            }
        }).state("midas.studies.view.images", {
            url: "/images",
            views: {
                "content@midas.studies.view": {
                    templateUrl: require("./pinImage.html"),
                    controller: "studyPinImageCtrl"
                }
            }
        }).state("midas.studies.view.diagrams", {
            url: "/diagrams",
            views: {
                "content@midas.studies.view": {
                    templateUrl: require("./studyDiagramSummary.html"),
                    controller: "studyDiagramsCtrl"
                }
            }
        }).state("midas.studies.view.measurements", {
            url: "/measurements",
            views: {
                "content@midas.studies.view": {
                    templateUrl: require("./measurements.html"),
                    controller: "measurementsCtrl"
                }
            }
        }).state("midas.studies.view.charts", {
            url: "/charts",
            views: {
                "content@midas.studies.view": {
                    templateUrl: require("./charts.html"),
                    controller: "chartsCtrl as chartsCtrl"
                }
            }
        }).state("midas.studies.view.preview", {
            url: "/preview",
            views: {
                "content@midas.studies.view": {
                    templateUrl: require("./preview.html"),
                    controller: "reportPreviewCtrl"
                }
            }
        }).state("midas.studies.view.notes", {
            url: "/notes",
            views: {
                "content@midas.studies.view": {
                    templateUrl: require("./notes/notes.html"),
                    controller: "notesCtrl as ctrl"
                }
            }
        })
    ]);