import { IAttributes, IDirective, IScope, IAugmentedJQuery, IInterpolateService } from "angular";
import { MeasurementsController, IMeasurementSection } from "./mdsMeasurements";
import { isNullOrWhitespace } from '../../../utility/utils';

// A class to add to a measurement template to indicate that there should be a jump list link
// added for navigation. For jump link functionality this directive must be a child of the
// mds-measurements directive.
interface MeasurementSectionDirective extends IDirective {}
class MeasurementSectionDirective {
    restrict = "C";
    require = "?^mdsMeasurements";
    // Want to get in early enough that we can get uncompiled expressions from id and title.
    priority: 99;
    constructor(private readonly $interpolate: IInterpolateService) { }
    link(scope: IScope, _element: IAugmentedJQuery, attrs: IAttributes, measurementCtrl: MeasurementsController) {
        // Manually interpolate the attributes here as we don't want to create a new scope that
        // could mess with any others on this tag. Since this is a comment directive, it really
        // has to behave like it doesn't exist.

        if (measurementCtrl != null) {
            const watchers = [
                this.interpolateAttr(attrs, "id"),
                this.interpolateAttr(attrs, "title")
            ].map(expr => ((s: IScope) => expr(s)));

            const results: IMeasurementSection = { id: "", text: "" };

            scope.$watchGroup(watchers, (newValues: string[]) => {
                results.id = newValues[0];
                results.text = newValues[1];
            });

            measurementCtrl.addSection(results).disposeWith(scope);
        }
    }

    interpolateAttr(attrs: IAttributes, attrName: string) {
        const attrValue = attrs[attrName];
        if (isNullOrWhitespace(attrValue)) {
            throw new Error(`mds-measurement-section has no required attribute ${attrName}.`);
        }
        return this.$interpolate(attrValue);
    }
}

export default ["$interpolate", ($interpolate: IInterpolateService) => new MeasurementSectionDirective($interpolate)];