import {
  InstituteSettingsMetadataService as MetadataService,
  Setting as SettingMetadata
} from "../../config/instituteSettingsMetadata";
import "angular";
import "angular-material";
import { BusinessModelService, Institute } from "../../businessModels";

interface ViewModel {
  readonly key: string;
  readonly label: string;
  readonly displayAs: "divider" | "switch" | "select" | "JSON";
}

/** Displayed as a divider by the template. */
class Divider implements ViewModel {
  readonly displayAs: "divider" = "divider";
  constructor(public readonly label: string, public readonly key = label) { }
}

/** Institute settings view model. */
class SettingView implements ViewModel {
  constructor(
    private readonly $setting: SettingMetadata,
    private readonly $models: BusinessModelService,
    private readonly $reportError: (kind: string, err: any) => void) {
    this.key = $setting.key;
    this.label = $setting.label;
    this.detail = $setting.detail;
    this.$institute = $models.Institute.current;
    //Fill out select options where they're needed.
    if ($setting.options instanceof Array) {
      this.options = $setting.options;
      this.displayAs = "select";
    } else if ($setting.options === "exam-types") {
      this.options = $models.ExamType.listActive().map(et => ({
        value: et.key,
        label: et.display || et.key
      }));
      this.options.unshift({value: "", label: "Select Exam Type"});
      this.displayAs = "select";
    } else if ($setting.options === "exam-types-custom") {
        this.options = new Array<SettingMetadata.Option>();
        this.displayAs = "JSON";
    } else {
      this.displayAs = "switch";
    }
  }
  private readonly $institute: Institute;
  /** The key defining the setting in the database. */
  readonly key: SettingMetadata.Keys;
  /** The label displayed to the user for this setting. */
  readonly label: string;
  /** An optional detail string, displayed as a tooltip or in some other way. */
  readonly detail?: string;
  /** How this setting should be displayed. */
  readonly displayAs: "switch" | "select" | "JSON";
  /** The allowed values for the setting. */
  readonly options?: SettingMetadata.Option[];
  /** Gets the value of the setting on the view. */
  get value(): boolean | string {
    const value = this.$institute.getSetting(this.key);
    if (this.options) {
      return value;
    }
    return (typeof value === "string") ? value.toLowerCase() === "true" : false;
  }
  /** Sets the value of the setting on the view. */
  set value(value: string | boolean) {
    const strValue = this.options ? <string>value : (value ? "True" : "False");
    this.$institute.setSetting(this.key, strValue);
  }
  /** Resets the setting value to default. */
  setDefault() {
    this.$institute.setSetting(this.key, this.$setting.defaultValue);
  }
  /** Gets whether this setting contains a valid value according to it's options. */
  isValid() {
    const value = this.$institute.getSetting(this.key);
    return value === "False" ||
        value === "True" ||
        (this.options && this.options.some(s => s.value === value)) ||
        (this.displayAs === 'JSON' && this.isJsonString(this.value));
  }
  /** Saves just this setting. */
  save() {
    var self = this;
    return self.$models.
      saveEntities(self.$institute.settings.filter(s => s.key === self.key))
      .then(() => {
        if (self.key === "ReportCongruenceDisabled" && self.value === true) {
          self.$institute.setSetting("ReportCongruenceMandatory", "False");
          self.$institute.setSetting("ReportCongruenceForSonographer", "False");
          self.$models.saveEntities(self.$institute.settings.filter(
              s => s.key === "ReportCongruenceMandatory" || s.key === "ReportCongruenceForSonographer"))
          .catch(err => this.$reportError("save", err));
        }      
      })
      .catch(err => this.$reportError("save", err));
  }

  /** Gets the enabled/disabled status of the ReportCongruenceMandatory setting. */
  get disabled(): boolean {
    if (this.key !== "ReportCongruenceMandatory" && this.key !== "ReportCongruenceForSonographer") {
      return false;
    } else {
      const congruenceValue = this.$institute.getSetting("ReportCongruenceDisabled");
      return (typeof congruenceValue === "string") ? congruenceValue.toLowerCase() === "true" : false;
    }
  }
  private isJsonString(str) {
      try {
          JSON.parse(str);
      } catch (e) {
          return false;
      }
      return true;
  }
}

interface InstituteSettings extends angular.IController {}
class InstituteSettings implements angular.IController {
  settings: ViewModel[];

  static $inject = ["$mdToast", "businessModels", "instituteSettingsMetadata"];
  constructor(
    private readonly $mdToast: angular.material.IToastService,
    private readonly $models: BusinessModelService,
    private readonly $metadata: MetadataService) { }

  private onError(kind: string, _err: any) {
    switch(kind) {
      case "save":
        this.$mdToast.showSimple("Error saving settings change to server");
        break;
      default:
      this.$mdToast.showSimple("Unspecified error encountered");
    }
  }

  $onInit() {
    if (this.$models.Institute.current) {
      const onError = this.onError.bind(this);
      this.settings = [];

      //Build up the settings, grouped by category.
      const grouped = this.$metadata.byCategories();
      Object.keys(grouped).sort().forEach(category => {
        if (category) { //Empty string is ordered first.
          this.settings.push(new Divider(category));
        }
        this.settings.push(
          ...grouped[category].map(setting => new SettingView(setting, this.$models, onError)));
      });
      //Ensure the values are assigned and valid options.
      for (const setting of this.settings) {
        if (setting instanceof SettingView && !setting.isValid()) {
            setting.setDefault();
        }
      }
    }
  }
}

export default <angular.IComponentOptions>{
  controller: InstituteSettings,
  templateUrl: require("./settings-panel.component.html")
};