import "es6-promise/auto"; //Always polyfill native Promise if it's not already provided.
import { module as ngModule } from "angular";

import app from "./ng-modules";

import utility from "./utility/utils";
import admin from "./admin/admin";
import reference from "./midas/reference/reference";
import patients from "./midas/patients/patients";
import unauthorisedInterceptor from "./unauthorisedHttpInterceptor";
import stateConfig from "./config";
import splash from "./anon/splashCtrl";
import demo from "./anon/demo";
import stateLoadingModule from "./utility/layout/mdsStateLoading";
import measurement from "./utility/measurement/measurement";
import usersModule from "./midas/users/users";
import inlineEditors from "./utility/inlineEditors/inlineEditor";
import filters from "./utility/filters";
import errors from "./utility/errors/errors.module";
import "./midas/nav.css";
import "./core.css";
import "./fixes.scss";

const midas = ngModule("midas", [
  app.midasOld.name,
  utility.name,
  splash.name,
  demo.name,
  admin.name,
  patients.name,
  reference.name,
  unauthorisedInterceptor.name,
  stateConfig.name,
  stateLoadingModule.name,
  measurement.name,
  usersModule.name,
  inlineEditors.name,
  filters.name,
  errors.name
]);
export default midas;

// Webpack provides the environment variables, and since their value is considered static it will
// eliminate code based on their value.
declare const process;
if (process.env.NODE_ENV === 'production') {
  midas.config(['$compileProvider', function ($compileProvider: angular.ICompileProvider) {
    console.log("Disabling angular debug info. To re-enable enter `angular.reloadWithDebugInfo();` on the console.");
    $compileProvider.debugInfoEnabled(false);
  }]);
}