import * as angular from "angular";
import "angular-material";
import {
    Patient,
    Study
} from "../../businessModels";
import "../../utility/search/mdsSearchDirectives";
import utilsModule, {
    LoadingStatus,
    Throttle,
    Disposable,
    createDisposable
} from "../../utility/utils";
import {
    StudySearchExecutorApi,
    SearchResultViewModel
} from "../../utility/search/search";

export interface PatientStudyListController extends angular.IController {}
export class PatientStudyListController implements angular.IController {
    readonly status: LoadingStatus;
    studies: SearchResultViewModel[] = [];
    private readonly patient: Patient; //Component bound.
    private entitySubscription?: Disposable;

    static $inject = ["loadingStatus", "$mdToast", "$q", "studySearchExecutor", "$scope", "throttle"];
    constructor(
        loadingStatus: typeof LoadingStatus,
        private readonly $mdToast: angular.material.IToastService,
        private readonly $q: angular.IQService,
        private readonly studySearchExecutor: StudySearchExecutorApi,
        private readonly $scope: angular.IScope,
        private readonly throttle: typeof Throttle
    ) {
        this.status = new loadingStatus();
    }

    $onChanges?(): void {
        if (this.entitySubscription)
            this.entitySubscription();
        this.studies = [];
        this.search(this.patient).disposeWith(this.$scope);
    }

    private search(patient: Patient): Disposable {
        const entityManager = this.patient.breezeEntity().entityAspect.entityManager;
        const studyType = entityManager.metadataStore.getEntityType("Study");

        const disposable = createDisposable();

        this.patient.getStudies().then(studies => {
                this.studies = this.toSearchViewModels(studies);

                const throttledChanges = new this.throttle<breeze.EntityChangedEventArgs>(250);
                const entityChangedReg = entityManager.entityChanged.subscribe(
                    data => {
                        if (data.entity.entityType === studyType) {
                            throttledChanges.onNext(data);
                        }
                    });

                disposable.add(() => {
                    throttledChanges.cancel();
                    entityManager.entityChanged.unsubscribe(entityChangedReg);
                });

                throttledChanges.observe(data => {
                    this.studies = this.toSearchViewModels(this.patient.getLocalStudies());
                });
            })
            .catch(reason => {
                this.$mdToast.showSimple("Unable to load study list for patient.");
                disposable();
                return this.$q.reject(reason);
            });

        return disposable;
    }

    private toSearchViewModels(studies: Study[]) {
        return studies.map(study => this.studySearchExecutor.toViewModel(study));
    }
}

export default angular.module("midas.patients.study-list-component", [utilsModule.name])
    .component("patientStudyList",
    {
        controller: PatientStudyListController,
        bindings: { patient: "<" },
        templateUrl: require("./patientStudyList.component.html")
    });