import * as angular from "angular";
import businessModelModule, { BusinessModelService, Exam, ExamType, Technician, Physician, User } from "../businessModels";
import { InstituteSettingsMetadataService } from "../config/instituteSettingsMetadata";
import instituteSettingModule from "../config/config";
import examTypeNameConfigurationService, { ISearchResult }
    from "../admin/institute/examTypeNameConfigurationService";

const module = angular.module("midas.utility.filters", [
    businessModelModule.name, instituteSettingModule.name]);
export default module;

module.filter("whenNull", function () {
    return function (x, valueWhenNull) {
        if (x != null) {
            return x;
        } else {
            return valueWhenNull;
        }
    };
});

module.filter("whenUndefined", function () {
    return function (x, valueWhenUndefined) {
        if (x === void 0) {
            return valueWhenUndefined;
        } else {
            return x;
        }
    };
});

module.filter("whenNullOrWhitespace", function () {
    return function (x, valueWhenNullOrWhite) {
        if (x != null) {
            if (!/^\s*$/m.test(x)) {
                return x;
            }
        }
        return valueWhenNullOrWhite;
    };
});

module.filter("replaceSpacesWithDash", function () {
    return function (x) {
        if (!x) {
            return x;
        }
        return x.replace(new RegExp(' ', 'g'), '-');
    };
});

module.filter("yearsToMonths", function () {
    return function (x) {
        return Math.round(x * 12);
    };
});

module.filter("round", function () {
    return function (x: number, decimals: number = 0) {
        var multiplier = Math.pow(10, decimals);
        return typeof x === "number" ? Math.round(x * multiplier) / multiplier : x;
    };
});


/**
 * Replaces non-printable values with version which are readable. Specifically null -> "null",
 * undefined -> "undefined", and surrounds empty or whitespace strings with the " character.
 * @param {String} x Input string which is possibly null, undefined, blank, or whitespace.
 * @return {String} Visible string representing the input value.
 */

module.filter("ensurePrintable", function () {
    return function (x) {
        if (x === void 0) {
            return "undefined";
        } else if (x === null) {
            return "null";
        } else if (/^\s*$/m.test(x)) {
            return '"' + x + '"';
        } else {
            return x;
        }
    };
});

module.filter("fullName", function () {
    return function (person) {
        var result, _ref, _ref1;
        if (person != null) {
            result = (((_ref = person.firstName) != null ? _ref : "") + " " + ((_ref1 = person.lastName) != null ? _ref1 : "")).trim();
            if (result === "") {
                return null;
            } else {
                return result;
            }
        } else {
            return null;
        }
    };
});

module.filter("examTypeTitle", ["examTypeNameConfigurationService",
    function (mappingService: examTypeNameConfigurationService) {
        return function (exam: Exam | ExamType  | string) : string {
            if (exam === null) {
                return "Unknown";
            }

            let title: string;
            let mapped : ISearchResult;

            if (exam instanceof Exam) {
                if (exam.type !== null) {
                    mapped = mappingService.getCustomName(exam.type.key)
                }
            }
            else if (exam instanceof ExamType)
                mapped = mappingService.getCustomName(exam.key)
            else
                mapped = mappingService.getCustomName(exam);

            if (mapped !== null && mapped.matchFound === true)
                title = mapped.examType;
            else {
                if (exam instanceof Exam) {
                    title = exam.title !== null
                        ? exam.title
                        : (exam.type !== null
                            ? (exam.type.display != null ? exam.type.display : exam.type.key)
                            : "Unknown")
                }
                else if (exam instanceof ExamType){
                    title = exam !== null
                        ? (exam.display !== null ? exam.display : exam.key)
                        : "Unknown"
                }
                else {
                    title = exam !== null ? exam : "Unknown"
                }
            }

            return title;
        }
    }
]);

module.filter("staffSortOrder", ["businessModels", "instituteSettingsMetadata", '$filter',
    function (
        models: BusinessModelService,
        instSettings: InstituteSettingsMetadataService,
        $filter: angular.IFilterService) {
        return function (staff) {
            let setting = instSettings.DefaultPersonSortOrder;
            if (models.Institute.current == null) 
                return $filter('orderBy')(staff, ['lastName', 'firstName'], false);
            let sortOrder = models.Institute.current.getSetting(setting.key) || setting.defaultValue;
                switch (sortOrder) {
                    case "LNFN":
                        return $filter('orderBy')(staff, ['lastName', 'firstName'], false);
                    case "FNLN":
                        return $filter('orderBy')(staff, ['firstName', 'lastName'], false);
                    default:
                        return $filter('orderBy')(staff, ['lastName', 'firstName'], false);
                }
            };
        }
]);

module.filter("personNameDisplayFormat", ["businessModels", "instituteSettingsMetadata", '$filter',
    function (
        models: BusinessModelService,
        instSettings: InstituteSettingsMetadataService) {
        return function (person) {
            let result: string;
            let first: string; 
            let last: string;
            if (person != null) {
                let setting = instSettings.DefaultPersonDisplayFormat;
                if (models.Institute.current == null) return null;
                let displayFormat = models.Institute.current.getSetting(setting.key) || setting.defaultValue;
                switch (displayFormat) {
                    case "LNB_Comma_Space_FN":  //CITIZEN, John
                        result = ( 
                            ((last = person.lastName) != null ? last.toLocaleUpperCase() : "") +
                            ", " +
                            ((first = person.firstName) != null ? first.charAt(0).toLocaleUpperCase() + first.substring(1).toLocaleLowerCase() : "")
                        ).trim(); 
                        break;
                    case "LN_Comma_Space_FN":  //Citizen, John
                        result = (
                            ((last = person.lastName) != null ? last.charAt(0).toLocaleUpperCase() + last.substring(1).toLocaleLowerCase() : "") +
                            ", " + 
                            ((first = person.firstName) != null ? first.charAt(0).toLocaleUpperCase() + first.substring(1).toLocaleLowerCase() : "")
                        ).trim();
                        break;
                    case "FN_Space_LN":  //John Citizen
                        result = (
                            ((first = person.firstName) != null ? first.charAt(0).toLocaleUpperCase() + first.substring(1).toLocaleLowerCase() : "") +
                            " " +
                            ((last = person.lastName) != null ? last.charAt(0).toLocaleUpperCase() + last.substring(1).toLocaleLowerCase() : "")
                        ).trim();
                        break;
                    case "FN_Space_LNB":  //John CITIZEN
                        result = (
                            ((first = person.firstName) != null ? first.charAt(0).toLocaleUpperCase() + first.substring(1).toLocaleLowerCase() : "") +
                            " " +
                            ((last = person.lastName) != null ? last.toLocaleUpperCase() : "")
                        ).trim();
                        break;
                    default:  //Citizen, John
                        result = (
                            ((last = person.lastName) != null ? last.charAt(0).toLocaleUpperCase() + last.substring(1).toLocaleLowerCase() : "") +
                            ", " +
                            ((first = person.firstName) != null ? first.charAt(0).toLocaleUpperCase() + first.substring(1).toLocaleLowerCase() : "")
                        ).trim();
                }
                if (result === "") {
                    return null;
                } else {
                    return result;
                }
            } else {
                return null;
            }
        };
    }
]);

module.filter("lowerCase", function () {
    return function (str) {
        if (str != null) {
            return str.toLowerCase();
        } else {
            return str;
        }
    };
});

module.filter("upperCase", function () {
    return function (str) {
        if (str != null) {
            return str.toUpperCase();
        } else {
            return str;
        }
    };
});

module.filter("capitalise", function () {
    return function (str) {
        if (!str) {
            return str;
        }
        return str.substring(0, 1).toUpperCase() + str.substring(1).toLowerCase();
    };
});

module.filter("camelcase", function () {
    return function (str) {
        if (!str) {
            return str;
        }
        return str.replace(/([a-z])([A-Z])/g, '$1 $2').replace(/\b([A-Z]+)([A-Z])([a-z])/, '$1 $2$3').replace(/^./, function (str) {
            return str.toUpperCase();
        });
    };
});

module.filter("charAt", function () {
    return function (str, index) {
        if ((str != null) && str.length > index) {
            return str.charAt(index);
        } else {
            return str;
        }
    };
});


/**
 * Dumps an object or value to the console.
 * @param {String | Object} options If 'options' is provided and is a string then it used as the
    label for the value (the first argument to console.log). If it is an object then it may have
    the following properties:
    String  options.label: The label to print before the dumped value
    Boolean options.json: Whether to print the value as a pretty formatted JSON string
    Boolean options.copy: Whether to make a deep copy of the object before dumping it to the
                console This ensures the actual state of the object at the moment of dumping is
                displayed, rather than the lazily evaluated state when the object is expanded by the
                user. If 'json' is also specified then that takes precedence and no copy is made.
    * @return {Object} The original 'value' passed as an argument.
    */
module.filter("dump", function () {
    return function (value, options) {
        var dumpee, label;
        if (options == null) {
            options = null;
        }
        dumpee = value;
        if (options != null) {
            if (options.json === true) {
                dumpee = angular.toJson(dumpee, true);
            } else if (options.copy === true) {
                dumpee = angular.copy(dumpee);
            }
            label = angular.isString(options) ? options : options.label != null ? options.label : null;
            if (label != null) {
                console.log(label, dumpee);
            } else {
                console.log(dumpee);
            }
        } else {
            console.log(dumpee);
        }
        return value;
    };
});


/**
 * Gets the JS type of a value
 * @return {String} The JS type of the value.
 */

module.filter("typeOf", () => {
    return (value) => typeof value;
});


/**
 * Joins an array into a string using the provided separator ("," by default). Synonym for
 * JavaScript's  array.join() function.
 * @param {Object[]} array The array to join
 * @param {String ? ","} separator The string which is placed between each item in the output.
 * @return {String} The result of joining all elements of 'array' using 'separator'.
 */

module.filter("join", function () {
    return function (array, separator) {
        if (separator == null) {
            separator = ",";
        }
        if (!angular.isArray(array)) {
            return array;
        }
        return array.join(separator);
    };
});

module.filter("userDate", ["businessModels", (models) => {
    return (date: moment.MomentComparable, kind: null | "" | "short" | "long") => {
        if (date == null) {
            return date;
        }
        const user = models.User.current;
        if (user == null) {
            return date.toString();
        }
        switch (kind) {
            case "short": return user.formatShortDate(date);
            case "long": return user.formatLongDate(date);
            default: return user.formatDate(date);
        }
    };
}]);

module.filter("userDateTime", [
    "businessModels", (models: BusinessModelService) => {
        return (date: moment.MomentComparable) => {
            if (models.User.current == null) return ""
            else return models.User.current.formatShortDateTime(date);
        };
    }
]);

module.filter("userLongDateTime", [
    "businessModels", (models: BusinessModelService) => {
        return (date: moment.MomentComparable) => {
            if (models.User.current == null) return ""
            else return models.User.current.formatLongDateTime(date);
        };
    }
]);

module.filter('slice', [
    function () {
        return function (arr, start, end) {
            return (arr || []).slice(start, end);
        };
    }
]);

module.filter("userRole", [
    "businessModels", function (models) {
        return function (roleName) {
            if (models.User.current)
              return models.User.current.hasRole(roleName)
          else return false;
        };
    }
]);


module.filter("instituteSettingEquals", ["businessModels",
function InstituteSettingEqualsFilter(models: BusinessModelService) {
    return (key: string, values: string | Array<string>) => {
        let array = values instanceof Array ? values : new Array(values);
        if (models.Institute.current == null) return false;
        let setting = models.Institute.current.getSetting(key);
        for (let value of array) {
            let valid = (setting === null && value === null) || (setting != null && value != null && setting.toUpperCase() === value.toString().toUpperCase());
            if (valid) {
                return true;
            }
        }
        return false;
    };
}]);

module.filter("userSettingEquals", ["businessModels",
function(models: BusinessModelService) {
    return (key: string, values: string | string[]) => {
        const user = models.User.current;
        if (user == null) return false;
        const array = values instanceof Array ? values : new Array(values);
        const setting = user.settings[key];
        for (const value of array) {
            const valid =
              (setting === null && value === null) ||
              (setting != null && value != null && setting.toUpperCase() === value.toString().toUpperCase());
            if (valid) {
                return true;
            }
        }
        return false;
    };
}]);

module.filter("personInitials", function PersonInitialsFilter() {
    return (person: User | Physician | Technician) => {
        if (person == null)
            return null;
        let initials = "";
        if (person.firstName && person.firstName.length > 0) {
            initials += person.firstName[0];
        }
        if (person.lastName && person.lastName.length > 0) {
            initials += person.lastName[0];
        }
        return initials;
    };
});

module.filter("trustedHtml", ["$sce", function TrustedHtmlFilter($sce: angular.ISCEService) {
    return (text:string): string => $sce.trustAsHtml(text);
}]);