/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import { module as ngModule } from "angular";
import businessModuleModule from "../../businessModels";

export default ngModule("midas.utility.layout.stateLoading", [businessModuleModule.name]).directive("mdsStateLoading",
  ["$state", "$timeout", "businessModels", ($state, $timeout, models) =>
    ({
      restrict: "A",
      scope: { 
        wait: "=?",
        title: "=?"
      },
      link($scope, $element, _$attrs) {
        let waitingForState = false;
        let waitingForLoading = false;
      
        $scope.wait = $scope.wait != null ? $scope.wait : 500;
        let lastState = null;
      
        const show = () => $element.removeClass("ng-hide");
        const hide = () => $element.addClass("ng-hide");
      
        var updateVisibility = function(wait = true) {
          if (!waitingForState && !waitingForLoading) {
            return hide();
          } else if (wait) {
            return $timeout( 
              () => updateVisibility(false),
              $scope.wait);
          } else { 
            return show();
          }
        };
      
        updateVisibility();
      
        models.load.onChanged(function(status) {
          $scope.title = status.title;
          waitingForLoading = status.isLoading;
          return updateVisibility();
        });
      
        $scope.$on("$stateChangeStart", function(event, toState) {
          //Do not show if state change has been prevented
          if (event.defaultPrevented) { return; }
          lastState = toState;
          waitingForState = true;
          return updateVisibility();
        });
          
        return $scope.$on("$stateChangeSuccess", function(_event, toState) {
          if (waitingForState && (lastState === toState)) {
            waitingForState = false;
            return updateVisibility();
          }
        });
      }
    })
  
  ]);
