import { IController, IDirective, module, INgModelController, IAttributes, IScope } from "angular";
import * as lodash from "lodash";

class UniqueValidationDirectiveController implements IController {

  ngModel: INgModelController;

  static $inject = ["$scope", "$attrs"];
  constructor(private readonly $scope: IScope, private readonly $attrs: IAttributes) {
  }

  $onInit(): void {
    this.ngModel.$validators.unique = (modelValue) => {
      const items: any[] = this.$scope.$eval(this.$attrs.mdsUniqueValidation);
      if (items instanceof Array === false) {
        return false;
      }
      const current = items[this.$scope.$index];
      for (const item of items) {
        if (item !== current && item === modelValue) {
          return false;
        }
      }
      return true;
    };
  }
}

export default module("midas.utility.validation.uniqueValidationDirective", [
])
  .directive("mdsUniqueValidation", () => <IDirective> {
    restrict: "A",
    controller: UniqueValidationDirectiveController,
    bindToController: true,
    require: {
      ngModel: "ngModel"
    }
  });
