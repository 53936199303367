import { IController } from "angular";
import { IExamTypeMappingModel } from "./examTypeNameConfigurationService";

interface examTypeNameConfigurationNewController extends IController { }
class examTypeNameConfigurationNewController implements IController {

    private mapping: IExamTypeMappingModel;

    static $inject = ["$mdDialog"];
    constructor(
        private readonly $mdDialog: angular.material.IDialogService){}

    accept(): void {
        this.$mdDialog.hide(this.mapping);
    }

    cancel(): void {
        this.$mdDialog.cancel();
    }
}

export function createDialogOptions(mapping: IExamTypeMappingModel)
    : angular.material.IDialogOptions {
        return {
            templateUrl: require('./examTypeNameConfigurationNew.html'),
            controller: examTypeNameConfigurationNewController,
            openFrom: "#newMappingDialog",
            locals: {
                mapping: mapping
            },
            controllerAs: "$ctrl",
            bindToController: true
        }
}