/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS104: Avoid inline assignments
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import "./mdsCard.css";

export default ["$state", "$location", ($state, $location) =>
  ({
    restrict: "E",
    replace: true,
    templateUrl : require("./mdsCard.html"),
    scope: {
      itemId: "=",
      type: "=",
      abbreviation: "=",
      primary: "=",
      secondary: "=",
      aside1: "=",
      aside2: "=",
      aside3: "=",
      stateName: "=?",
      stateArgs: "=?",
      redirect: "=?",
      notification: "=?"
    },
    link($scope) {
  
      let left;
      $scope.redirect = $scope.redirect != null ? $scope.redirect : true;
      $scope.stateName = $scope.stateName != null ? $scope.stateName : "";
      $scope.url = (left = $state.href($scope.stateName, $scope.stateArgs)) != null ? left : "#";
      $scope.getIsCurrent = () => $scope.url === (`#${$location.path()}`);
      return $scope.goTo = function(e) { if ($scope.redirect) { return $state.go($scope.stateName, $scope.stateArgs); } };
    }
  })

];
