import { MeasurementController, IMeasurementScope } from "../MeasurementController";
import * as angular from "angular";
class DXBoneScanController extends MeasurementController {
  static $inject = ["$scope"];
  constructor(scope: IMeasurementScope) {
    super(scope);
  }

  clickOK() {
    this.setMeasurementValue("NMBoneScanRtBoneLesions", "There are no osseous lesions suspicious of metastatic disease. ");
    this.setMeasurementValue("NMBoneScanRtSoftTissueContours", "Normal. ");
    this.setMeasurementValue("NMBoneScanRtSoftKidneys", "Normal. ");
    this.setMeasurementValue("NMBoneScanRtOtherComment", "None. ");
    this.setMeasurementValue("NMBoneScanConclusion", "No evidence of osseous metastatic disease. ");
    console.log("All normal clicked")
  }

}

angular.module("midas.utility.measurement.views").controller("DXBoneScanCtrl", DXBoneScanController);