/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import "./measurementDiagram.css";


export default [() =>
  ({
    restrict: "A",
    transclude: true,
    require: "^mdsDiagramContainer",
    scope: { 
      header: "@"
    },
    template: `\
<div class='mds-diagram-popup ng-animate' ng-show='getIsVisible()'> \
<div class='header'> \
{{header}} \
<i class='glyphicon glyphicon-remove pull-right' style='cursor: pointer;' ng-click='close()'></i> \
</div> \
<div class='body-container'> \
<div class='body' ng-transclude> \
</div> \
</div> \
</div>`,
    link($scope, $element, $attrs, $ctrl) {
      $element.addClass('mds-popup-container');
      const key = $attrs.mdsDiagramPopup;
      $scope.getIsVisible = () => $ctrl.getVisibility(key);
      $scope.close = () => $ctrl.setVisibility(key, false);
      if ($ctrl.getVariant() !== "Readonly") {
        return $element.on('mousedown', e => e.stopPropagation());
      }
    }
  })

];
