import * as angular from "angular";
import "angular-ui-router";

import { IAdminService } from "./adminProvider";
import { User, BusinessModelService } from "../businessModels";
import { IAuthService } from '../authService';

interface IAdminScope extends angular.IScope {
    sections: Array<IAdminNavigationItem>;
    user: User;
}

interface IAdminNavigationItem {
    title: string;
    state: string;
    iconClasses: string;
}

interface AdminController extends angular.IController { }
class AdminController {
    static $inject = ["$scope", "admin", "businessModels", "authService", "$state"];
    constructor(private scope: IAdminScope, admin: IAdminService, models: BusinessModelService, private authService: IAuthService, private state: angular.ui.IStateService) {
        this.scope.sections = [];
        for (let section of admin.getSections()) {
            scope.sections.push({
                title: section.title,
                state: `midas.admin.${section.state}`,
                iconClasses: "fa fa-cog"
            });
        }
        this.scope.user = models.User.current;
    }

    logout() {
        this.authService.logout();
        this.state.go("splash");
    }
}

export default AdminController;