import { BusinessModelService } from '../../businessModels';
import { SearchTermService } from "../../utility/search/mdsSearchDirectives";

/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS103: Rewrite code to no longer use __guard__
 * DS104: Avoid inline assignments
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */

export default [
  "$scope", 
  "$rootScope", 
  "loadingStatus", 
  "$state", 
  "$stateParams", 
  "$q", 
  "Upload", 
  "lodash", 
  "businessModels", 
  "inst", 
  "$anchorScroll", 
  "$location",
  "$mdToast", 
  "searchTermService",
  function(
    $scope, 
    $rootScope, 
    loadingStatus, 
    $state, 
    $stateParams, 
    $q, 
    Upload, 
    lodash, 
    models: BusinessModelService, 
    inst, 
    $anchorScroll, 
    $location,
    $mdToast: angular.material.IToastService,
    searchTermService: SearchTermService) {
    
    let goToStudy, pauseCountdown, stopCountdown, study, writeStudy, showDetails;
    
    $scope.study = (study = {});
    $scope.canUpload = true;
    $scope.files = [];
    $scope.waitLoadTick = 100.0;
    $scope.redirecting = false;
    $scope.paused = false;
    $scope.batchSize = 5;
    $scope.showDetails = false;

    const filterExamTypes = function() {
      if (!($scope.study != null ? $scope.study.module : undefined)) {
        return $scope.examTypes = [];
      } else {
        const allExamTypes = models.ExamType.listActive();
        $scope.examTypes = lodash.filter(allExamTypes, x => (x.modality.key === __guard__($scope.study != null ? $scope.study.module : undefined, x1 => x1.key)) && (x.isDeleted !== true));
        if ($scope.examTypes.length === 1) { return ($scope.study != null ? $scope.study.examType = $scope.examTypes[0] : undefined); }
      }
    };
    
    $scope.modules = lodash.filter(models.Modality.listNow(), x => x.isDeleted !== true);
    if ($scope.modules.length === 1) { if ($scope.study != null) {
      $scope.study.module = $scope.modules[0];
    } }
    filterExamTypes();
    
    models.Physician.list().then(function(p) { 
      $scope.physicians = p;
      if ($scope.physicians.length === 1) { return $scope.study.physician = $scope.physicians[0]; }});
        
    models.Technician.list().then(function(t) { 
      $scope.technicians = t;
      if ($scope.technicians.length === 1) { return $scope.study.technician = $scope.technicians[0]; }});
    
    $scope.$watch("study.module", function() { 
      if ($scope.study != null) {
        $scope.study.examType = null;
      }
      return filterExamTypes();
    });
    
    const upload = file =>
      file.upload = Upload.upload({
        url: `api/midas/ImportDicomStudy/`,
        data: { file }
      })
      .then(function(response) {
        $scope.uploadsRemaining--;
        file.done = true;
        
        if ((response.data != null ? response.data.type : undefined) === "StudyExists") {
          $scope.showDetails = false;         
          return $scope.dupeStudy = { 
            id: response.data.studyId, 
            uid: response.data.studyUId 
          }
        }
        else {
          $scope.showDetails = true;
          if (!$scope.patient) {
            models.Study.find({ id: response.data.studyId })
            .then(x => study.entity = x);
            $scope.patient = { 
              firstName: response.data.firstName,
              lastName: response.data.lastName
            };          
            $scope.study.hasCheckedPatientDupes = true;
            $scope.study.performedAt = response.data.performedAt;
            return $scope.study.id = response.data.studyId;
          }
      }})
      .catch(function(error) {
        $scope.uploadsRemaining--;
        $scope.showDetails = false;
        console.error('upload error', error);
        file.error = error;          
        file.getErrorMessage = function() {
          let left, left1;
          return (
            left = (
              left1 = __guard__(this.error != null ? this.error.data : undefined, x => x.ExceptionMessage) != null 
                ? __guard__(this.error != null ? this.error.data : undefined, x => x.ExceptionMessage) 
                : __guard__(this.error != null ? this.error.data : undefined, x1 => x1.Message)
              ) != null ? left1 : __guard__(this.error != null ? this.error.data : undefined, x2 => x2.ExceptionType)
            ) != null ? left : (this.error != null  ? this.error.statusText : undefined); 
        };
        if (typeof file.error.data === "string") {
          switch(file.error.status) {
            case 413:
              $mdToast.showSimple("The DICOM file you tried to upload is too large. Files exceeding 100MB can be only loaded via the MIDAS Connect Service. Please contact MIDAS support.");
              break;
            case 404:
              $mdToast.showSimple("There was a problem uploading the DICOM file. Probably it is too large. Files exceeding 100MB can be only loaded via the MIDAS Connect Service. Please contact MIDAS support.");
              break;
            default:
              $mdToast.showSimple(file.error.data.substr(0, 100));
          }
        } 
        else {
          $mdToast.showSimple(file.getErrorMessage());
        }
      });
        
    $scope.uploadFiles = function(files, _errFiles) {
      if (!files) { return; }
      $scope.uploaded = false;
      $scope.canUpload = false;
      $scope.fileIndex = 0;
      const uploads = [];
      $scope.uploadsRemaining = files.length;
      const index = 0;
      for (let f of files) { $scope.files.push(f); }
      progressNext(0, files, $scope.batchSize)
      .then(function() {
        openStudiesNewSearchList();
        console.log('done');
        $scope.canUpload = true;
        return $scope.uploaded = true;
      });
    };

    var openStudiesNewSearchList = () => {
      let statuses = models.Status.listNow();
      const newStatus = lodash.find(statuses, x => x.key === "New");
      let studyFilter = [
        {"id": "Studies_recordType", "text": "Studies", "value": "Studies", "type": "recordType"},
        {"id": `${newStatus.id}_status`, "text": "New", "value": newStatus.id, "type": "status"}
      ];
      searchTermService.load({ tags: studyFilter, apply: false, toggle: true });
    }
    
    var progressNext = function(index, files, batchSize) {
      const startIndex = index;
      const promises = [];
      while (index < (startIndex + batchSize)) {
        const batchItem = files[index++];
        if (batchItem) { promises.push(upload(batchItem)); }
      }
      
      console.log(`progress index:${index}, batch size:${batchSize}, items:${promises.length}`);
                  
      if (!promises.length) { return $q.when([]); }
      
      return $q.all(promises)
      .then(() => progressNext(index, files, batchSize));
    };
    
    var dupesFound = function(dupes) {
      $scope.study.hasDuplicates = dupes != null ? dupes.length : undefined;
      $scope.study.dupeMessage = `${dupes.length} potential duplicates found`;
      if (dupes.length === 0) { return $scope.study.hasCheckedPatientDupes = true; }
    };
    
    var dupeSelected = function(dupe) {
      $scope.study.hasCheckedPatientDupes = true;
      if (dupe) { 
        $scope.study.dupeMessage = "Duplicate selected";
        return models.Patient.find({
          id: dupe.stateArgs.patientId})
        .then(function(x) { 
          console.log(x, dupe);
          return $scope.study.dupe = x;
        });
      } else { 
        return $scope.study.dupeMessage = "No match selected";
      }
    };
    
    $scope.recheckDuplicates = function() {
      $scope.study.hasCheckedPatientDupes = true;
      if (!$scope.study) { return; }
      return $rootScope.$broadcast('duplicateCheckRequested', { 
        studyId: $scope.study.id,
        dupesFound,
        dupeSelected
      }
      );
    };
    
    $scope.goToExistingStudy = function() {
      if (!$scope.dupeStudy) { return; }
      return $state.go("midas.studies.view.details", { 
        studyId: $scope.dupeStudy.id,
        inst: inst.key
      }
      );
    };
    
    $scope.cancelImport = () => {
      $scope.dupeStudy = null;
      $scope.files = [];
      $scope.uploadsRemaining = undefined;
      $scope.uploaded = true;
    }
   
    $scope.goToStudy = (goToStudy = function(write = true) { 
      let wait = $q.when([]);
      if (write) { wait = writeStudy(); }
      return wait.then(() => 
        $state.go("midas.studies.view.details", { 
          studyId: $scope.study.id,
          inst: inst.key
        }
        )
      );
    });
    
    let countdownInterval = null;
    
    const startCountdown = function() {
      $location.hash('countdown');
      $anchorScroll();
      $scope.redirecting = true;
      const tick = function() {
        $scope.waitLoadTick = $scope.waitLoadTick - 1;
        $scope.$apply();
        
        if ($scope.waitLoadTick === 0) {
          clearInterval(countdownInterval);
          return goToStudy();
        }
      };
        
      return countdownInterval = setInterval(tick, 500);
    };
    
    $scope.pauseCountdown = (pauseCountdown = function() {
      $scope.paused = true;
      return stopCountdown();
    });
    
    $scope.stopCountdown = (stopCountdown = function() {
      clearInterval(countdownInterval);
      $scope.waitLoadTick = 10;
      return $scope.redirecting = false;
    });
      
    $scope.writeStudy = (writeStudy = function() {
      const write = function() {
        $scope.study.entity.status = lodash.find(models.Status.listNow(), x => x.key === "Saved");
        $scope.study.entity.modality = $scope.study.module;
        $scope.study.entity.exams[0].type = $scope.study.examType;
        $scope.study.entity.exams[0].title = $scope.study.examType.key;
        $scope.study.entity.physician = $scope.study.physician;
        $scope.study.entity.technician = $scope.study.technician;
        if ($scope.study.dupe && ($scope.study.entity.patient.id !== $scope.study.dupe.id)) {
          $scope.study.entity.patient.isDeleted = true;
          return $scope.study.entity.patient = $scope.study.dupe;
        }
      };
      return models.save(write, false, false);
    });

    $scope.readyToGo = function() { 
      const isReady = $scope.uploaded && !$scope.paused && ($scope.study != null ? $scope.study.module : undefined) && ($scope.study != null ? $scope.study.examType : undefined) && ($scope.study != null ? $scope.study.physician : undefined) && ($scope.study != null ? $scope.study.technician : undefined) && ($scope.study != null ? $scope.study.hasCheckedPatientDupes : undefined);
      if (isReady && !$scope.redirecting) { startCountdown(); }
      if (!isReady && $scope.redirecting) { stopCountdown(); }
      return isReady;
    };

    return $scope.$on("$destroy", () => stopCountdown());
  }
  ];

function __guard__(value, transform) {
  return (typeof value !== 'undefined' && value !== null) ? transform(value) : undefined;
}