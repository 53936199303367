import { IController, module as ngModule } from "angular";
import { MeasurementType } from "../../../businessModels";
import measurementSelectorModule from "../../../utility/measurement/search/measurement-selector.component";
import "./report-formatter-measurement-type.dialog.scss";

interface PromptMeasurementTypeController extends IController {}
class PromptMeasurementTypeController {
  measurementTypes: Readonly<MeasurementType[]>;

  static $inject = ["$mdDialog"];
  constructor(private readonly $mdDialog: angular.material.IDialogService) {}

  onSelectionChanged(measurementType: MeasurementType): void {
    this.$mdDialog.hide(measurementType);
  }

  cancel(): void {
    this.$mdDialog.cancel();
  }
}

const createOptions = (measurementTypes: Readonly<MeasurementType[]>): angular.material.IDialogOptions => {
  return {
    controller: PromptMeasurementTypeController,
    templateUrl: require("./report-formatter-measurement-type.dialog.html"),
    controllerAs: "$ctrl",
    bindToController: true,
    fullscreen: true,
    clickOutsideToClose: true,
    locals: {
      measurementTypes
    }
  };
};

export default ngModule("midas.admin.reportFormatters.measurementTypeDialog", [measurementSelectorModule.name])
  .decorator("$mdDialog", ["$delegate", ($mdDialog: angular.material.IDialogService) => {
    $mdDialog.showMeasurementTypePrompt =
      (measurementTypes) => $mdDialog.show(createOptions(measurementTypes));
    return $mdDialog;
  }
]);

declare module "angular" {
  export namespace material {
    interface IDialogService {
      /**
       * Displays to the user a list of measurement types to choose from.
       * @returns A promise of a selected measurement type.
       */
      showMeasurementTypePrompt(measurementTypes: Readonly<MeasurementType[]>): IPromise<MeasurementType>;
    }
  }
}