/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import { module as ngModule } from "angular";


export default ngModule("midas.utility.animation", [])
  .animation(".mds-animate-fullScreenSlideLeft", function() {
  let previousWidthPercent = null;
  
  const animation = {  
    addClass(element, className, done) { 
    
      const width = element.width();
      const parentWidth = element.offsetParent().width();
      previousWidthPercent = (100*width)/parentWidth;
    
      element.css("position", "absolute");
      element.css("z-index", "10");
      element.css("right", "5px");
      jQuery(element).animate(
        {width: "100%"}
      , { done });
    
      return function(isCancelled) {
        if (isCancelled) { jQuery(element).stop(); }
      };
    },

    removeClass(element, className, done) {
      jQuery(element).animate(
        {width: `${previousWidthPercent}%`}
      , {
          done() {
            element.css("position", "");
            element.css("right", "");
            element.css("width", "");
            element.css("z-index", "");
            return done();
          }
        });
    
      return function(isCancelled) {
        if (isCancelled) { jQuery(element).stop(); }
      };
    }
  };
        
  return animation;
})
.animation(".mds-animate-fullScreenSlideRight", function() {
  let previousWidthPercent = null;
  
  const animation = {  
    addClass(element, className, done) { 
    
      const width = element.width();
      const parentWidth = element.offsetParent().width();
      previousWidthPercent = (100 * width) / parentWidth;
    
      element.css("position", "absolute");
      element.css("z-index", "10");
      element.css("left", "5px");
      jQuery(element).animate(
        { width: "100%" }
      , { done });
    
      return function(isCancelled) {
        if (isCancelled) { jQuery(element).stop(); }
      };
    },

    removeClass(element, className, done) {
      jQuery(element).animate(
        {width: `${previousWidthPercent}%`}
      , {
          done() {
            element.css("position", "");
            element.css("left", "");
            element.css("width", "");
            element.css("z-index", "");
            return done();
          }
        });
    
      return function(isCancelled) {
        if (isCancelled) { jQuery(element).stop(); }
      };
    }
  };
        
  return animation;
})
.animation(".mds-animate-fadeout", function() {
  const animation = {  
    addClass(element, className, done) { 
      element.css("opacity", "1");
      jQuery(element).animate(
        {opacity: "0"}
      , done);
    
      return function(isCancelled) {
        if (isCancelled) { jQuery(element).stop(); }
      };
    },
    

    removeClass(element, className, done) {
      element.css("opacity", "0");
      jQuery(element).animate(
        {opacity: "1"}
      , done);
    
      return function(isCancelled) {
        if (isCancelled) { jQuery(element).stop(); }
      };
    }
  };
        
  return animation;
});
