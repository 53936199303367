import { IComponentController, IComponentOptions, module as ngModule } from "angular";

class CurrentDateController implements IComponentController {
    format: string;
    now: Date;

    $onInit = () => {
        this.now = new Date();
    };
}

class CurrentDateComponent implements IComponentOptions {
    template = "<span>{{$ctrl.now | date: $ctrl.format}}</span>";
    controller = CurrentDateController;
    bindings = {
        format: '@'
    }
}

export default
    ngModule("midas.utility.currentDate", [])
        .component("currentDate", new CurrentDateComponent());