import { module as ngModule } from "angular";
import "angular-material";
import Accordion from "./accordion.component";
import ExpansionPanel from "./expansionPanel.component";
import ExpansionHeader from "./expansionPanelHeader.component";
import SlideOpenDirective, { directiveName as slideOpenName } from "./slideOpen.directive";
//Register our expansion styles with the theme provider so that it can parse and replace the
//variables with correct colours and hues. The 2 loaders override our default, so this won't
//automatically be loaded into a style tag. Instead the SCSS will be compiled and then the CSS will
//be loaded directly into JS as a string we can use here.
const expansionPanelTheme = require("!raw-loader!sass-loader!./expansionTheme.scss");

export default ngModule("midas.utility.expansionPanel", ["ngMaterial", "ngAnimate"])
    .component("mdsAccordion", Accordion)
    .component("mdsExpansionPanel", ExpansionPanel)
    .component("mdsExpansionPanelHeader",  ExpansionHeader)
    .directive(slideOpenName, SlideOpenDirective)
    .config(["$mdThemingProvider", (themeProvider: angular.material.IThemingProvider) => {
        themeProvider.registerStyles(expansionPanelTheme);
    }]);