import {
  IAugmentedJQuery,
  IParseService,
  module as ngModule,
  element as $
} from "angular";
import filterModule from "./condition-expression.filters";
import { startsWith } from "../../mdsUtils";
import expressionSerialiserModule, {
  serviceName as expressionSerialiserName,
  IBlueprintExpressionSerialiser
} from "./condition-expression-serialiser.service";
import { BlueprintExpression } from './condition-expression.models';
import { WidgetService, WidgetController, IWidgetCompilationContext } from './widget';

export const serviceName = "conditional";
/** The query selector used to find widgets of this type. */
export const querySelector = "[data-conditional]";
const conditionClass = "mds-condition";
const conditionSelector = "." + conditionClass;
const hiddenQuerySelector = `${querySelector}.mds-condition-false`;
const rawBlockDom = "<div data-conditional>";
const rawInlineDom = "<span data-conditional>";
const rawConditionHtml = `<span class="${conditionClass}">`;

/** The class added to a condition element when the condition is false. */
const conditionFalseClass = "mds-condition-false";

/** A service to evaluate an expression and output the result as text into the Blueprint. */
export class ConditionalService extends WidgetService<ConditionalController> {
  static $inject = ["$parse", "$animate", expressionSerialiserName];
  constructor(
    private readonly $parse: IParseService,
    private readonly $animate: angular.animate.IAnimateService,
    private readonly exprSer: IBlueprintExpressionSerialiser) {
    super(querySelector);
  }

  /** Creates a new detached expression object. The result can be attached by accessing the $element
   * member.
   * @param block Whether to create a block conditional rather than an inline one. */
  create(block: boolean = false): ConditionalController {
    return new ConditionalController(
      $(block ? rawBlockDom : rawInlineDom),
      this.$animate, this.$parse, this.exprSer);
  }

  /** Gets a controller object for the provided DOM. It is not checked first. */
  get(element: JQuery): ConditionalController {
    return new ConditionalController(element, this.$animate, this.$parse, this.exprSer);
  }

  /** Gets whether the provided element is editable or not, according to this service. Differs from
   * the base implementation by only preventing editing within the condition part. */
  isEditable(element: JQuery): boolean {
    return !this.isConditionPart(element) || !this.isInBetweenConditionOrContent(element);
  }

  /** Gets whether the provided element is hidden by any parent conditional widget with a false
   * condition. Parents must be in a fresh state. */
  isHidden(element: JQuery) {
    return element.closest(hiddenQuerySelector).length > 0;
  }

  /** Gets whether the provided element is in the condition part of a conditional. */
  isConditionPart(element: JQuery) {
    return element.closest(conditionSelector).length > 0;
  }

  isInBetweenConditionOrContent(element: JQuery) {
    return element.parent().hasClass("mds-conditional");
  }

  /** Gets whether the provided element is in the content part of a conditional. If the element is
   * in the condition part of a conditional nested inside the content part of another conditional,
   * this will return false. */
  isContent(element: JQuery) {
    return element.closest(".mds-conditional-content").length > 0
           && !this.isConditionPart(element);
  }
}

/** A wrapper directive which defines the scope for a conditional section. It is shown/hidden
 * based on conditions added by condition directives beneath it. All registered condition directives
 * must pass for this to be shown. */
export class ConditionalController extends WidgetController {
  /** Gets the value of this condition. IE whether it is included in the report. */
  result: boolean;

  constructor(
    readonly $element: IAugmentedJQuery,
    private readonly $animate: angular.animate.IAnimateService,
    private readonly $parse: IParseService,
    private readonly exprSer: IBlueprintExpressionSerialiser) {
      super();
    }

  /** Adds/removes the ng-hide class to the element based on the `condition` argument. */
  private ngHide(hideWhen: boolean) {
    // This is lifted straight out of the body of the ng-hide directive in angular. It means
    // we can use the structural animations with this just like ng-hide.
    this.$animate[hideWhen ? "addClass" : "removeClass"](this.$element, conditionFalseClass, <any> {
      tempClasses: "mds-condition-animate"
      });
  }

  /** Gets the expression from the DOM. */
  get expression(): BlueprintExpression {
    const exprNode = this.getConditionNode().children();
    if (exprNode.length > 0) {
      return this.exprSer.deserialise(exprNode);
    }
    return undefined;
  }

  /** Sets the expression into the DOM and refreshes the directive. */
  set expression(expression: BlueprintExpression) {
    const conditionNode = this.getConditionNode(true);
    if (conditionNode.length > 0) {
      conditionNode.children().remove();
    }
    if (expression) {
      const serialisedExpression = this.exprSer.serialise(expression);
      conditionNode.append(serialisedExpression);
    }
  }

  /** Refreshes `this.result`, and updates the depth and visibility classes. */
  refresh(context: IWidgetCompilationContext) {
    const expressionNode = this.getConditionNode().children();
    let result = false;
    if (expressionNode.length > 0) {
      const expressionText = expressionNode.text();
      const compiledExpression = this.$parse(expressionText);
      result = this.result = compiledExpression(context.values, {
        // Returns true or undefined instead of true or false so that it works on it's own (where
        // undefined is still falsy), and also with `| exists` and `| doesNotExist`.
        $marker(name) { return context.markers.has(name) ? true : undefined; }
      });
      this.$element.removeClass("no-conditions");
    } else {
      this.$element.addClass("no-conditions");
    }
    this.ngHide(!result);
  }

  /** Gets whether this conditional is implemented using a <div> or a <span>. This would be way
   * simpler as a class. Shrug. */
  get isBlock() {
    return this.$element.is("div");
  }

  /** If this condition is included in the report then it's conditional expression is stripped. If
   * it is not included in the report then the entire condition is removed. */
  strip() {
    if (this.result) {
      // Always remove the condition node since it shouldn't be in the report.
      const condition = this.getConditionNode();
      if (condition != null) {
        condition.remove();
      }
    } else {
      // If the condition is false or there's no content then remove the entire thing.
      this.remove();
    }
  }

  /** Gets whether this object was created with an appropriate element. */
  get isValid() {
    return this.$element != null && this.$element.length === 1 && this.$element.is(querySelector);
  }

  /** Gets all nodes (Text or Element) under this node which are not condition nodes. */
  getContentNodes() {
    const contentNodes = this.$element.contents().filter((i, el) => {
      if (el.nodeType === 3) { // Text
        return true;
      } else if (el.nodeType === 1) { // Element && not the condition element.
        return !el.classList.contains(conditionClass);
      }
      return false;
    });
    return contentNodes;
  }

  /** Appends some text or elements to the end of the contents of this condition. */
  appendContent(newContent: string | JQuery | Text | Element | DocumentFragment) {
    if (typeof newContent === "string") {
      this.$element.append(new Text(newContent));
    } else {
      this.$element.append(newContent);
    }
  }

  /** Gets the condition node. The child can be passed directly to the expression serialiser.
   * @param createIfNotExists Whether to create the node and attach it to the DOM if it doesn't
   * already exist. DO NOT set this to true anywhere angular might be compiling (like
   * `this.refresh()`). */
  getConditionNode(createIfNotExists: boolean = false) {
    let conditionNode = this.$element.children(conditionSelector);
    if (conditionNode.length === 0 && createIfNotExists) {
      conditionNode = $(rawConditionHtml);
      conditionNode.prependTo(this.$element);
    }
    return conditionNode;
  }
}

export default ngModule("midas.blueprint.conditions", [
  filterModule.name,
  expressionSerialiserModule.name
]).service(serviceName, ConditionalService);