/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS103: Rewrite code to no longer use __guard__
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */

export default [
  "$scope", "$q", "$state", "$stateParams", "lodash", "$rootScope", "businessModels", "inst",
  function($scope, $q, $state, $stateParams, lodash, $rootScope, models, inst) {

    console.log(inst);

    let patient = null;
    let modalities = [];
    let physicians = [];
    let technicians = [];
    let statuses = [];
    const now = new Date();

    const goBack = () => $state.go("midas");

    $scope.openCalenders = [];
    $scope.selectedStudyTypes = [];
    $scope.sites = inst.sites;

    $scope.preventDefaultandStop = function(evt) {
      evt.preventDefault();
      return evt.stopPropagation();
    };

    $scope.newStudy = {
      isBusy: false,
      entity: {},
      globalExamDate: now,
      showIndividualExamDates: false,
      accept() {
          this.isBusy = true;
          const studyTypes = $scope.selectedStudyTypes;
          return models.save(() => {
            for (let obj of studyTypes) {
              const examDate = this.showIndividualExamDates ? obj.examDate : this.globalExamDate;
              this.entity.createExam(obj.studyType, examDate);
            }
            console.log(this.entity);

        }).then(() => $state.go("midas.studies.view.details", { inst: inst.key, studyId: this.entity.id }))
          .catch(error => {
            this.errors = [];
            for (let entityError of error.entityErrors) { this.errors.push(entityError.errorMessage); }
            return this.isBusy = false;
          });
        },
      cancel() {
        models.breeze.rejectChanges();
        return goBack();
      }
    };

    $scope.calculateColumnClass = function(collection, max) {
      if ((collection == null) || (collection.length == null)) { return ""; }
      if (collection.length >= max) {
        return 12 / max;
      } else {
        return 12 / collection.length;
      }
    };

    $scope.assignModality = function(modality) {
      $scope.studyTypes = [];
      $scope.selectedStudyTypes = [];
      if ($scope.newStudy.entity === null) { return; }
      $scope.newStudy.entity.modality = modality;
      $scope.newStudy.isBusy = true;
      return models.ReportFormatter.list({ "user.id": null }, 1000)
        .then(function(formatters) {
          for (var formatter of formatters) {
            if (formatter.examType.modality.key === modality.key) {
              $scope.studyTypes.push({studyType: formatter.examType, examDate: now});
            }
          }
          return $scope.newStudy.isBusy = false;
        });
    };

    $scope.assignStudyType = function(studyType) {
      if (lodash.contains($scope.selectedStudyTypes, studyType)) {
        return lodash.remove($scope.selectedStudyTypes, studyType);
      } else {
        return $scope.selectedStudyTypes.push(studyType);
      }
    };

    $scope.modalityIsSelected = function(modality) {
      const isSelected = __guard__($scope.newStudy != null ? $scope.newStudy.entity : undefined, x => x.modality) === modality;
      return isSelected;
    };

    $scope.studyTypeIsSelected = obj => lodash.contains($scope.selectedStudyTypes, obj);

    const waitListModules = models.Modality.list().then(m => modalities = m);
    const waitGetPatient = models.Patient.find({ id: $stateParams.patientId}).then(px => patient = px);
    const waitListPhysicians = models.Physician.list().then(x => physicians = x);
    const waitListTechnicians = models.Technician.list().then(x => technicians = x);
    const waitListStatuses = models.Status.list().then(x => statuses = x);

    return $q.all([waitListModules, waitGetPatient, waitListPhysicians, waitListTechnicians, waitListStatuses]).then(function() {
      let savedStatus;
      $scope.modalities = modalities;
      $scope.physicians = physicians;
      $scope.technicians = technicians;
      for (let status of statuses) {
        if (status.key === "Saved") { savedStatus = status; }
      }
      if (patient && savedStatus) {
        let modality = null;
        let physician = null;
        let technician = null;
        if (modalities.length === 1) { modality = modalities[0]; }
        if (physicians.length === 1) { physician = physicians[0]; }
        if (technicians.length === 1) { technician = technicians[0]; }
        return models.Study.create(now, patient, modality, savedStatus, physician, technician)
          .then(function(study) {
            $scope.newStudy.entity = study;
            if (modality !== null) { return $scope.assignModality(modality); }
        });
      }
    });
  }
];

function __guard__(value, transform) {
  return (typeof value !== 'undefined' && value !== null) ? transform(value) : undefined;
}