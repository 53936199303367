/** An object defining an institute level setting. */
export interface Setting {
    /** The key defining the setting in the database. */
    readonly key: Setting.Keys;
    /** The label displayed to the user for this setting. */
    readonly label: string;
    /** An optional detail string, displayed as a tooltip or in some other way. */
    readonly detail?: string;
    /** Optional category under which to group this setting. */
    readonly category?: string;
    /** The default value of the setting. */
    readonly defaultValue: string;
    /** The allowed values for the setting. This can be a list of options, the "boolean"
     * string which uses the "True" and "False" values behind the scenes, or the "exam-types"
     * string, which uses the exam types of the institute. */
    readonly options: "boolean" | "exam-types" | "exam-types-custom" | Setting.Option[];
};
export declare namespace Setting {
    /** Allowed string literal types for settings keys. */
    export type Keys = keyof typeof settings;
    /** The type of each option for a setting containing many options. */
    export type Option = {
        value: string,
        label: string
    };
}

/** Contains the raw settings definitions. */
const settings = {
    "ManualStudyCreationDisabled": <Setting>{
        category: "Study",
        label: "Disable manual study creation",
        detail: "Setting this option will prevent staff from manually creating studies.",
        defaultValue: "False",
        options: "boolean"
    },
    "ManualStudyUploadDisabled": <Setting>{
        category: "Study",
        label: "Disable manual study upload",
        detail: "Setting this option will prevent staff from manually uploading studies.",
        defaultValue: "False",
        options: "boolean"
    },
    "ManualPatientCreationDisabled": <Setting>{
        category: "Patient",
        label: "Prevent staff adding patients",
        detail: "Setting this option will prevent staff from manually adding new patients.",
        defaultValue: "False",
        options: "boolean"
    },
    "DefaultExamType": <Setting>{
        category: "Study",
        label: "Default examination type",
        detail: "The default examination type for new studies.",
        defaultValue: "",
        options: "exam-types"
    },
    "ExamTypeNameConfiguration": <Setting>{
        category: "Study",
        label: "Exam type mapping",
        detail: "Customized exam types' names specific to the Institute.",
        defaultValue: "[]",
        options: "exam-types-custom"
    },
    "PhysicianRequiredForStudies": <Setting>{
        category: "Study",
        label: "Require physician for new studies",
        detail: "Setting this option ensures that a physician is always assigned for new studies.",
        defaultValue: "False",
        options: "boolean"
    },
    "NavigateOnMarkedProvisional": <Setting>{
        category: "Navigation",
        label: "When study is marked provisional",
        detail: "Defines an action to take when a study is marked as provisional.",
        defaultValue: "next-study",
        options: [
            {
                value: "stay",
                label: "Do nothing"
            },
            {
                value: "next-study",
                label: "Navigate to next study"
            },
            {
                value: "search-list",
                label: "Open search list"
            }
        ]
    },
    "NavigateOnMarkedFinal": <Setting>{
        category: "Navigation",
        label: "When study is marked final",
        detail: "Defines an action to take when a study is marked as final.",
        defaultValue: "next-study",
        options: [
            {
                value: "stay",
                label: "Do nothing"
            },
            {
                value: "next-study",
                label: "Navigate to next study"
            },
            {
                value: "search-list",
                label: "Open search list"
            }
        ]
    },
    "DefaultReportFontSize": function () {
        const sizeMap = {
            "6pt": "8px",
            "7pt": "9px",
            "7.5pt": "10px",
            "8pt": "11px",
            "9pt": "12px",
            "10pt": "13px",
            "10.5pt": "14px",
            "11pt": "15px",
            "12pt": "16px",
            "13pt": "17px",
            "13.5pt": "18px",
            "14pt": "19px",
            "14.5pt": "20px",
            "15pt": "21px",
            "16pt": "22px",
            "17pt": "23px",
            "18pt": "24px",
            "20pt": "26px",
            "22pt": "29px",
            "24pt": "32px",
            "26pt": "35px",
            "27pt": "36px",
            "28pt": "37px",
            "29pt": "38px",
            "30pt": "40px",
            "32pt": "42px",
            "34pt": "45px",
            "36pt": "48px"
        };
        const sizes = [];
        for (var pt in sizeMap) {
            const px = sizeMap[pt];
            sizes.push({
                value: px,
                label: pt
            })
        }
        return <Setting>{
            category: "Report",
            label: "Default Report Font Size",
            detail: "The default font size applied to the report when first loaded.",
            defaultValue: "11px",
            options: sizes
        }
    }(),
    "DefaultReportFontFamily": function () {
        const defaultFontFamily = "arial,helvetica,sans-serif;";
        const sizeInPx = new Array<string>("Arial");
        const fonts = {
            "Andale Mono": "andale mono,times",
            "Arial": "arial,helvetica,sans-serif",
            "Arial Black": "arial black,avant garde",
            "Book Antiqua": "book antiqua,palatino",
            "Comic Sans MS": "comic sans ms,sans-serif",
            "Courier New": "courier new,courier",
            "Georgia": "georgia,palatino",
            "Helvetica": "helvetica",
            "Impact": "impact,chicago",
            "Symbol": "symbol",
            "Tahoma": "tahoma,arial,helvetica,sans-serif",
            "Terminal": "terminal,monaco",
            "Times New Roman": "times new roman,times",
            "Trebuchet MS": "trebuchet ms,geneva",
            "Verdana": "verdana,geneva",
            "Webdings": "webdings",
            "Wingdings": "wingdings,zapf dingbats"
        };
        const options = new Array<Setting.Option>();
        for (var name in fonts) {
            const def = fonts[name];
            options.push({
                label: name,
                value: def
            });
        }
        return <Setting>{
            category: "Report",
            label: "Default Report Font Family",
            detail: "The default font family applied to the report when first loaded.",
            defaultValue: defaultFontFamily,
            options: options
        };
    }(),
    "DefaultPersonSortOrder": function () {
        const defaultSortOrder = "LNFN";
        const availableSortOrders = {
            "Citizen John": "LNFN",
            "John Citizen": "FNLN"
        };
        const options = new Array<Setting.Option>();
        for (var order in availableSortOrders) {
            const def = availableSortOrders[order];
            options.push({
                label: order,
                value: def
            });
        }
        return <Setting>{
            category: "Study",
            label: "Default Person Sort Order",
            detail: "Defines the persons' names sort order.",
            defaultValue: defaultSortOrder,
            options: options
        };
    }(),
    "DefaultPersonDisplayFormat": function () {
        const defaultDisplayFormat = "LN_Comma_Space_FN";
        const availableDisplayFormats = {
            "CITIZEN, John": "LNB_Comma_Space_FN",
            "Citizen, John": "LN_Comma_Space_FN",
            "John Citizen": "FN_Space_LN",
            "John CITIZEN": "FN_Space_LNB"
        };
        const options = new Array<Setting.Option>();
        for (var display in availableDisplayFormats) {
            const def = availableDisplayFormats[display];
            options.push({
                label: display,
                value: def
            });
        }
        return <Setting>{
            category: "Study",
            label: "Default Person Display Format",
            detail: "Defines the persons' display format.",
            defaultValue: defaultDisplayFormat,
            options: options
        };
    }(),
    "ReportCongruenceDisabled": <Setting>{
        category: "Report Congruence",
        label: "Disable report congruence",
        detail: "Setting this option will show/hide the report congruence section.",
        defaultValue: "True",
        options: "boolean"
    },
    "ReportCongruenceMandatory": <Setting>{
        category: "Report Congruence",
        label: "Make report congruence mandatory",
        detail: "Setting this option will make report congruence mandatory.",
        defaultValue: "False",
        options: "boolean"
    },
    "ReportCongruenceForSonographer": <Setting>{
        category: "Report Congruence",
        label: "Sonographers can view report congruence",
        detail: "Setting this option will allow sonographers to view report congruence for their own studies.",
        defaultValue: "False",
        options: "boolean"
    },
    ReportEditorBreakType: function() {
        const paragraphOption: Setting.Option = {
            label: "Paragraph",
            value: "paragraph"
        };
        const lineBreak: Setting.Option = {
            label: "Line break",
            value: "line-break"
        };
        return <Setting> {
            category: "Report",
            label: "Report editor line break type",
            detail: "What type of line break to use when the user presses enter in the report editor.",
            defaultValue: paragraphOption.value,
            options: [
                paragraphOption,
                lineBreak
            ] as Setting.Option[]
        };
    }(),
    ReportTabDefaultReferenceSection: function() {
        const defaultSectionKey = "Measurements";
        const sectionKeys = [
            "Home",
            "Images",
            "Measurements",
            "Charts",
            "Diagrams",
            "Reports",
            "Preview",
            "Documents",
            "Notes"
        ];
        const options: Setting.Option[] = sectionKeys.map(key => {
            return {
                label: key,
                value: key
            };
        });
        return <Setting> {
            category: "Report",
            label: "Default Report editing Reference Area section.",
            detail: "When editing a report, what 'Reference Area' (right hand side) section should be automatically shown to the user.",
            defaultValue: defaultSectionKey,
            options
        };
    }(),
    AutomaticallyNavigateWhenReportSaves: function() {
        const defaultTabKey = "Preview";
        const tabKeys = [
            "Details",
            "Documents",
            "Images",
            "Diagrams",
            "Notes",
            "Preview"
        ];
        const options: Setting.Option[] = tabKeys.map(key => {
            return {
                label: key,
                value: key
            };
        });
        return <Setting> {
            category: "Report",
            label: "Automatically navigate on report save.",
            detail: "The tab to navigate to after saving a report.",
            defaultValue: defaultTabKey,
            options
        };
    }()
};

/** The actual service value to expose. */
const setupConfig = {
    list(): Setting[] { return settingsList.slice(); },
    byCategories(): { [category: string]: Setting[] } {
        const grouped: { [category: string]: Setting[] } = {};
        for (const setting of settingsList) {
            const key = setting.category || "";
            let list = grouped[key];
            if (list == null) {
                list = grouped[key] = [setting];
            } else {
                list.push(setting);
            }
        }
        return grouped;
    }
};

/** A final configuration of the settings and service, along with collecting all settings into
 * a list for later. */
const settingsList: Setting[] = Object.keys(settings).map(key => {
    const setting = settings[key];
    setting.key = key;
    setupConfig[key] = setting;
    return setting;
});

/** The metadata which describes the shape of the institute settings. */
export type InstituteSettingsMetadataService = {
    [P in Setting.Keys]: Setting;
} & {
        /** Gets a list of the institute settings objects. */
        list(): Setting[];
        /** Gets the institute settings objects, grouped by category. Those with no category will placed
         * under the "" key. */
        byCategories(): { [category: string]: Setting[] };
    };

/** The configuration object to expose from this module. This does not expose settings, it
 * exposes the metadata for those settings. */
export default <InstituteSettingsMetadataService>setupConfig;