/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */

export default ["$scope", "$state", "loadingStatus", "businessModels", 
  function($scope, $state, loadingStatus, models) {
    $scope.status = new loadingStatus;
    $scope.title = "Change My Password";
    $scope.user = models.User.current;
    
    const checkMatch = function(newValue, oldValue) {
      if (newValue !== oldValue) { 
        const valid = $scope.new === $scope.repeat;
        $scope.form.new.$setValidity("match", valid);
        return $scope.form.repeat.$setValidity("match", valid);
      }
    };
      
    $scope.$watch("new", checkMatch);
    $scope.$watch("repeat", checkMatch);
      
    $scope.change = function() {
      $scope.error = null;  
      
      const waitForChange = $scope.user.changePassword($scope.current, $scope.new);
      waitForChange.then(() => $state.go("midas.myDetails"));
      waitForChange.error(function(error) { 
        $scope.error = error.ExceptionMessage;
        console.log($scope.error);
      });
      
      return $scope.status.track(waitForChange);
    };

    return $scope.$on("$destroy", () => models.breeze.rejectChanges());
  }
  ];
