import {
  IDirectiveFactory,
  IAttributes,
  IAugmentedJQuery
} from "angular";
import {
  IShape,
  IImageEditorScope,
  ImageEditorController
} from "../imageEditorCore";
import {
  Tool,
  IToolScope
} from "./tool";
import {
  Spline
} from "../shapes/shapes";
import {
  IDisposable,
  IPointLike,
  Vector2
} from "../../../utility/utils";
import ToolActivationContext from "./toolActivationContext";
import { PatternService } from "../patterns";

interface ISplineModel extends IShape {
  type: "path" | string;
  thickness: number;
  primaryColour: string;
  secondaryColour: string;
  points: IPointLike[];
  arrowStart ? : boolean;
  arrowEnd ? : boolean;
  pattern ? : string;
  tension ? : number;
  angleDamping ? : number;
}

interface ISplineScope extends IImageEditorScope {
  config: {
    arrowStart ? : boolean;
    arrowEnd ? : boolean;
    thickness ? : number;
    primaryColour ? : string;
    secondaryColour ? : string;
    pattern ? : string;
    tension ? : number;
    angleDamping ? : number;
  }
}

class PathTool extends Tool < Spline, ISplineModel > {
  $scope: ISplineScope;

  protected $createViewModel(dataModel ? : ISplineModel): Spline {
    const model = dataModel || this.$scope.config;
    const cmToStage = this.cmToStage(1);
    const points = ((dataModel && dataModel.points) || []).map(p => new Vector2(p).multiply(cmToStage));
    const vm = new Spline(points, model.thickness * cmToStage, model.primaryColour, model.secondaryColour, model.arrowStart, model.arrowEnd, this.patternService, model.pattern, model.tension, model.angleDamping);
    if (this.compositeOp) {
      vm.ink.compositeOperation = this.compositeOp;
    }
    this.$copyTraceSettingsTo(vm);
    return vm;
  }

  protected $updateDataModel(viewModel: Spline = this.$viewModel, dataModel ? : ISplineModel): ISplineModel {
    dataModel = dataModel || < any > {};
    dataModel.type = this.$shapeType;
    dataModel.thickness = this.stageToCm(viewModel.thickness);
    dataModel.primaryColour = viewModel.colour;
    dataModel.secondaryColour = viewModel.backgroundColour;
    dataModel.pattern = viewModel.pattern;
    const stageToCm = this.stageToCm(1);
    dataModel.points = viewModel.points.map(p => p.clone().multiply(stageToCm));
    dataModel.arrowStart = viewModel.arrowStart;
    dataModel.arrowEnd = viewModel.arrowEnd;
    dataModel.tension = viewModel.tension;
    dataModel.angleDamping = viewModel.angleDamping;

    return dataModel;
  }

  /** Creates a grip which moves a point around with it.
   * @param p The point to create a grip for.
   * @returns A disposable that deregisters all control bindings, and removes the control.
   */
  protected $createGripFor(context: ToolActivationContext, p: Vector2): IDisposable {
    const grip = context.controls.createGripControl(p, undefined, 0);
    const registration = grip.bind(p, () => this.update());
    return () => {
      context.controls.remove(grip);
      registration();
    }
  }

  addPoint(p: Vector2) {
    this.$viewModel.points.push(p);
    this.update();
  }

  setLastPoint(p: Vector2) {
    const vm = this.$viewModel;
    vm.points[vm.points.length - 1].set(p);
    this.update();
  }

  removeLastPoint() {
    const vm = this.$viewModel;
    vm.points.splice(vm.points.length - 1, 1);
    this.update();
  }

  /** Gets the last point in the view model */
  get lastPoint() {
    return this.$viewModel.points[this.$viewModel.points.length - 1];
  }

  activate(context: ToolActivationContext) {
    super.activate(context);
    const editModel = < ISplineModel > context.editModel;
    this.$viewModel = this.$createViewModel(editModel);
    if (this.$isEditing) {
      for (let p of this.$viewModel.points) {
        this.$createGripFor(context, p);
      }
      this.addAcceptDeselectButtons(context);
      this.update();
    }

    this.$ctrl.context.scratch.addChild(this.$viewModel.ink);

    const pipe = this.$pipe;
    context.dispose.add(
      this.watchConfig("thickness", pipe.unitToStage("cm"), pipe.setViewModel("thickness")),
      this.watchConfig("primaryColour", pipe.setViewModel("colour")),
      this.watchConfig("secondaryColour", pipe.setViewModel("backgroundColour")),
      this.watchConfig("pattern", pipe.setViewModel("pattern")),
      this.watchConfig("arrowStart", pipe.setViewModel("arrowStart")),
      this.watchConfig("arrowEnd", pipe.setViewModel("arrowEnd")),
      this.watchConfig("tension", pipe.setViewModel("tension")),
      this.watchConfig("angleDamping", pipe.setViewModel("angleDamping")));

    //Create a control which spans the entire stage, and use it to place points on click.
    context.controls.createInteractControl().subscribe(
      p => {
        this.addPoint(new Vector2(p));
        this.addAcceptDeselectButtons(context);
      },
      p => this.setLastPoint(new Vector2(p)),
      () => this.$createGripFor(context, this.lastPoint),
      () => this.removeLastPoint());

    context.observe("accept", () => {
      this.$viewModel.bakeTransform();
      context.addOrUpdate(this.$updateDataModel(this.$viewModel, editModel));
      context.deselect(true);
    });
    context.observe("deselect", () => context.reactivate());
    context.observe("delete", () => {
      context.removeEditShape();
      context.reactivate()
  });
  }

  constructor(
    public id: string,
    ctrl: ImageEditorController,
    scope: IToolScope,
    private readonly patternService: PatternService,
    public compositeOp: string = null) {
    super(ctrl, scope, "path");
  }
}

export default ["patternService", (patternService: PatternService) => ({
  require: "^mdsImageEditor",
  restrict: "E",
  scope: {
    config: "="
  },

  link(scope: any, element: IAugmentedJQuery, attrs: IAttributes, ctrl: ImageEditorController) {
    ctrl.tools.registerTool('path', new PathTool('path', ctrl, scope, patternService));
  }
})];