import * as angular from "angular";
import "angular-material";
import ReferenceService from "../../reference/referencesService";
import * as BusinessModels from "../../../businessModels";
import { SearchTermService } from "../../../utility/search/mdsSearchDirectives";

interface INotePromptScope extends angular.IController {
    content?: string;
    isNew?: boolean;
    dialog?: angular.material.IDialogService;
}

export default class NoteService {
    static $inject = ["$mdDialog", "businessModels", "$rootScope", "$injector", "$q", "searchTermService"];

    static defaultOpenFromElementId = "btnAddNote";
    static defaultCloseToElementId = "tabNotes";

    private referenceService: ReferenceService;

    constructor(private dialog: angular.material.IDialogService,
        private models: BusinessModels.BusinessModelService,
        private scope: angular.IScope,
        private injector: angular.auto.IInjectorService,
        private $q: angular.IQService,
        private searchTermService: SearchTermService) {}

    promptAdd(study: BusinessModels.Study, openFromElementId?:string, closeToElementId?:string) : angular.IPromise<any> {
        return this.prompt("", true, openFromElementId, closeToElementId)
            .then((content) => {
                return this.models.save(() => {
                    return study.createNote(content).then(note => {  return note; })
                })
                .then(note => {
                    this.refreshReferenceArea();
                    this.searchTermService.refresh();
                    return note;
                })
            })
    }

    promptEdit(note: BusinessModels.StudyNote, openFromElementId?: string, closeToElementId?: string): angular.IPromise<any> {
        return this.prompt(note.content, false, openFromElementId, closeToElementId).then((content) => {
            return this.models.save(() => {
                note.content = content;
                note.modifiedAt = new Date();
                note.modifiedBy = BusinessModels.User.current;
                return note;
            }).then(() => {
                this.refreshReferenceArea();
                return note;
            });
        });
    }

    private refreshReferenceArea(): void {
        //Reference service must be injected on demand due to circular dependency issues.
        if (!this.referenceService) {
            this.referenceService = this.injector.get("references") as ReferenceService;
        }
        this.referenceService.refresh("Notes", true);
    }

    delete(note: BusinessModels.StudyNote): angular.IPromise<void> {
        return this.models.save(() => {
          note.isDeleted = true;
        }).then(() => {
          this.refreshReferenceArea();
          this.searchTermService.refresh()
        });
    }

    private prompt(content: string, isNew: boolean, openFromElementId?: string, closeToElementId?: string): angular.IPromise<string> {
        openFromElementId = openFromElementId ? openFromElementId : NoteService.defaultOpenFromElementId;
        closeToElementId = closeToElementId ? closeToElementId : NoteService.defaultCloseToElementId;
        const scope = this.scope.$new();
        scope.content = content;
        scope.isNew = isNew;
        scope.dialog = this.dialog;
        const dialog: angular.material.IDialogOptions = {
            openFrom: `#${openFromElementId}`,
            closeTo: `#${closeToElementId}`,
            clickOutsideToClose: false,
            focusOnOpen: true,
            templateUrl: require("./notePrompt.html"),
            scope
        };
        return this.dialog.show(dialog).then(
            (promptContent: string) => promptContent,
            () => this.$q.reject());
    }
}

