import { module, IQService, fromJson, IHttpService, toJson } from "angular";
import { ExamType, BusinessModelService } from "../../../businessModels";
import { EntityQuery, QueryResult } from "breeze";
import { MeasurementUIElement } from "./mds-measurement-layout";

/** A service to get and update Measurement Layout for an exam type. */
export class MeasurementLayoutService {

  static $inject = ["$q", "businessModels", "$http"];
  constructor(
    private readonly $q: IQService,
    private readonly models: BusinessModelService,
    private readonly $http: IHttpService) {}

  get(examType: ExamType) {
    const query = EntityQuery
      .from("MeasurementUserInterfaceConfiguration")
      .where("studyTypeId", "==", examType.id);
    return new this.$q<QueryResult>((resolve, reject) =>
      this.models.breeze.executeQuery(query, resolve, reject))
          .then(data => {
            if (data.results.length === 0) {
              return null;
            }
            return fromJson(<string> ((<any> data.results[0]).configuration));
          });
  }

  update(examType: ExamType, layout: MeasurementUIElement[]) {
    return this.$http.post(`api/midas/updateMeasurementLayout/${examType.id}`, {
      json: toJson(layout)
    });
  }
}

export const serviceName = "mdsMeasurementLayoutService";
export default module("midas.utility.measurement.layoutService", [])
  .service(serviceName, MeasurementLayoutService);