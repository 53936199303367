/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import { module as ngModule } from "angular";
import currentDateModule from "../utility/currentDate";
import businessModelsModule from "../businessModels";


export default ngModule("midas.anon.splash", [currentDateModule.name, businessModelsModule.name])
  .controller("splashCtrl", [
    "$scope", "businessModels", "$state",
    function($scope, models, $state) {
      $scope.loginHref = $state.href("loginGuide");
      const promise = models.Institute.getSingleKey().then(function(key) {
        if (key) { return $scope.loginHref = $state.href("login", { inst: key }); }});
      return models.load.track(promise);
    }
  ]);
