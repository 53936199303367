import { IController, IComponentOptions, module as ngModule } from "angular";
import "angular-material";
import * as _ from "lodash";
import { BusinessModelService, Modality, MeasurementType } from "../../../businessModels";
import measurementTypeOptionsEditor, { IMTypeModel } from "./measurement-type-options-editor.component";
import measurementSelector from "../../../utility/measurement/search/measurement-selector.component";
import "./measurement-type-options.scss";

interface IModalityViewModel {
    modality: Modality;
    filteredMeasurementTypes: MeasurementType[];
    selectedMeasurementType: IMTypeModel;
    isTypeContentLoaded: boolean;
}

interface MeasurementTypeOptionsController extends IController { }

class MeasurementTypeOptionsController {

    allModalitiesModels: IModalityViewModel[] = [];

    static $inject = ["businessModels"];
    constructor(
        private readonly models: BusinessModelService) {
    }

    $onInit(): void {
        this.reload();
    }

    private reload = (): void => {
        this.allModalitiesModels = this.models.Modality.listNow()
            .map(m => {
                return {
                    modality: m,
                    filteredMeasurementTypes: null,
                    selectedMeasurementType: null,
                    isTypeContentLoaded: false
                } as IModalityViewModel;
            }) as IModalityViewModel[];
    }

    getModalityContent(model: IModalityViewModel): void {
        if (!model.isTypeContentLoaded) {
            model.modality.load()
                .then(() => {
                    model.filteredMeasurementTypes =
                        _.filter(model.modality.getMeasurementTypes(), x => {
                            return x.dropdownOptions;
                        });
                    model.isTypeContentLoaded = true;
                });
        }
    }

    onSelectionChanged(measurementType: MeasurementType, modality: IModalityViewModel): void {
        modality.selectedMeasurementType = {
            msmType: measurementType,
            options: measurementType.dropdownOptions
        } as IMTypeModel;
    }
}

const componentOptions: IComponentOptions = {
    controller: MeasurementTypeOptionsController,
    templateUrl: require("./measurement-type-options.component.html")
};

export default ngModule("midas.admin.measurementTypeOptions",
    [measurementSelector.name, measurementTypeOptionsEditor.name])
    .component("measurementTypeOptions", componentOptions);