/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS103: Rewrite code to no longer use __guard__
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import * as angular from "angular";
import * as moment from "moment";

//Directive based on http://eonasdan.github.io/bootstrap-datetimepicker/#options
//It uses moment for date parsing and formatting, so can support everything that does,
//including different languages.
//Example usage:
//
// <mds-date-time-picker
//   picker-options="{pickTime: false}"
//   ng-model="model"
//   on-hide="save()">
//   <button type='button' class="btn">{{model | date}}</button>
// </mds-date-time-picker>
//
//This will add a button containing the date, which displays the date picker on click and calls
//save() when the picker closes.

export default [ "$timeout", "$log", function($timeout, $log) {
  let index = 1;
  return ({
    restrict: "E",
    templateUrl: require("./dateTimePicker.html"),
    transclude: true,
    scope: {
      //options = { calendarWeeks, collapse, daysOfWeekDisabled, defaultDate, direction,
      //  disabledDates, enabledDates, format, icons, language, maxDate, minDate, minuteStepping,
      //  minViewMode, orientation, pickDate, pickTime, showToday, sideBySide, useCurrent,
      //  useMinutes, useSeconds, useStrict, viewMode, widgetParent, width }
      options: "=pickerOptions",
      enabled: "=?",
      ngModel: "=",
      onShow: "&?",
      onHide: "&?"
    },

    link(scope, element, attrs, controller) {
      let hidden = true;
      let picker = null;

      const id = (scope.id = `mds-datepicker-${index++}`);
      element.attr("id", id);

      if (attrs.enabled != null) {
        scope.$watch("enabled", function(newValue) {
          if (newValue === true) { picker.enable(); } else { picker.disable(); }
        });
      }

      const toDate = function(date) {
        if ((date == null) || angular.isDate(date)) { return date; } else { return date.toDate(); }
      };

      scope.$watch("ngModel", function(newValue) {
        if (!__guard__(picker.getDate(), x => x.isSame(newValue))) {
          picker.setDate(newValue);
        }
      });

      const updateModel = function() {
        const newValue = __guard__(picker.getDate(), x => x.clone());
        return $timeout(function() {
          if (!(newValue != null ? newValue.isSame(scope.ngModel) : undefined)) {
            scope.ngModel = toDate(newValue);
          }
        });
      };
      const hide = function($event) {
        if (!hidden) {
          hidden = true;
          if (attrs.onHide != null) {
            const locals = {$event, $element: element};
            updateModel().finally(() =>
              $timeout(function() {
                try { scope.onHide(locals); } catch (ex) { $log("Error in on-hide", ex); }
              })
            );
          }
        }
      };
      const show = function($event) {
        if (hidden) {
          hidden = false;
          if (attrs.onShow != null) {
            const locals = {$event, $element: element};
            try { scope.onShow(locals); } catch (ex) { $log("Error in on-show", ex); }
          }
        }
      };

      const init = function(newOptions) {
        const options = angular.isObject(newOptions) ?
            newOptions
          : angular.isString(newOptions) && (newOptions.length > 0) ?
            angular.fromJson(newOptions)
          :
            {}; //Use defaults (update here if necessary)

        options.widgetParent = `#${id}`;
        const dtp = element.datetimepicker(options);
        dtp.on("change.dp", updateModel);
        dtp.on("dp.show", show);
        dtp.on("dp.hide", hide);
        picker = dtp.data("DateTimePicker");
      };

      return init(scope.options);
    }
   });
}];

function __guard__(value, transform) {
  return (typeof value !== 'undefined' && value !== null) ? transform(value) : undefined;
}