export type LogFunction = (message?: any, ...optionalParams: any[]) => void;

/** Global trace function. */
export const trace: LogFunction = console.log.bind(console);
/** Global warn function. */
export const warn: LogFunction = (console.warn || console.log).bind(console);
/** Global error function. */
export const error: LogFunction = (console.error || console.log).bind(console);
/** Undefined, which should be ignored by all trace code. */
export const traceIgnore: LogFunction = undefined;
/** Undefined, which should be ignored by all trace code. */
export const warnIgnore: LogFunction = undefined;
/** Undefined, which should be ignored by all trace code. */
export const errorIgnore: LogFunction = undefined;

/** An interface for types which might allow tracing. */
export interface IMaybeTraceable {
  setTracing?(options: Traceable.Options): void;
}

export namespace Traceable {
    /** Options object for configuring instances of Traceable. */
    export type Options = {[key in "error" | "warn" | "trace"]?: boolean | LogFunction; }
  }

/** Classes may inherit this to provide basic trace capabilities. */
export class Traceable implements IMaybeTraceable {
  /** The function to use for logging trace information. */
  protected $trace: LogFunction;
  /** The function to use for logging warning information. */
  protected $warn: LogFunction;
  /** The function to use for logging error information. */
  protected $error: LogFunction;

  constructor(error = errorIgnore, warn = warnIgnore, trace = traceIgnore) {
    this.$error = error;
    this.$warn = warn;
    this.$trace = trace;
  }

  /** Allows trace/warn/error logging to be enabled or disabled for this object. */
  setTracing(options: Traceable.Options) {
    if ("error" in options) {
      const errOption = options["error"];
      this.$error = errOption ? (typeof errOption === "function" ? errOption : error) : errorIgnore;
    }
    if ("warn" in options) {
      const warnOption = options["warn"];
      this.$warn = warnOption ? (typeof warnOption === "function" ? warnOption : warn) : warnIgnore;
    }
    if ("trace" in options) {
      const traceOption = options["trace"];
      this.$trace = traceOption ? (typeof traceOption === "function" ? traceOption : trace) : traceIgnore;
    }
  }
}

/** Aggregate a bunch of traceables and allow them to be configured in a controlled manner. */
export class TraceableManager {
  private $registered: { [name: string]: IMaybeTraceable } = {};

  /** List of names of traceables which should have tracing enabled as part of their
   * registration process. This can be useful for traceables which are created and destroyed
   * regularly. */
  enableOnRegister: string[] = [];

  /** The options object to use for enabling all tracing. */
  protected enableAllOptions: Traceable.Options | { [name: string]: any } = {
    error: true,
    warn: true,
    trace: true
  };

  /** The options object to use for disabling all tracing. */
  protected disableAllOptions: Traceable.Options | { [name: string]: any } = {
    error: false,
    warn: false,
    trace: false
  };

  /** Adds a boolean option to the set of options passed to enableAll() and disableAll().
   * This is purely for convenience as there can be a lot of repetition otherwise.
   * @param optionName The name of the option to add.
   * @param enableValue The value to pass when enableAll() is called. The opposite is passed
   * when disableAll() is called. */
  protected addBooleanOption(optionName: string, enableValue: boolean = true) {
    this.enableAllOptions[optionName] = enableValue;
    this.disableAllOptions[optionName] = !enableValue;
  }

  /** Gets all registered traceables which match the argument. */
  protected getTraceables(match: string | RegExp): IMaybeTraceable[] {
    if (typeof match === "string") {
      return [this.$registered[match]];
    } else if (match instanceof RegExp) {
      return Object.keys(this.$registered)
        .filter(key => match.test(key))
        .map(key => this.$registered[key]);
    }
    return [];
  }

  /** Register a traceable with this class.
   * @param name The unique name which identifies this traceable. Ignored if null, throws if a
   * traceable is already registered by that name.
   * @param traceable The traceable to register with this class.
   * @returns The registered taceable. */
  register(name: string, traceable: IMaybeTraceable, enableArgs?: { [name: string]: any }, disableArgs?: { [name: string]: any }) {
    if (name != null) {
      if (this.$registered[name] != null) {
        throw Error(`${name} is already registered`);
      }
      this.$registered[name] = traceable;
      if (enableArgs != null) {
        Object.keys(enableArgs).forEach(key => this.enableAllOptions[key] = enableArgs[key]);
      }
      if (disableArgs != null) {
        Object.keys(disableArgs).forEach(key => this.disableAllOptions[key] = disableArgs[key]);
      }
      if (this.enableOnRegister.indexOf(name) >= 0) {
        traceable.setTracing(this.enableAllOptions);
      }
    }
    return traceable;
  }

  /** Removes all traceables and functions registered with this class which match the argument.
   * @param match A string exactly identifying a single registered traceable, or a regex
   * matching 0 or more registered traceables. */
  remove(match: string | RegExp): void {
    if (typeof match === "string") {
      delete this.$registered[match];
    } else if (match instanceof RegExp) {
      for (var key of Object.keys(this.$registered)) if (match.test(key)) {
        delete this.$registered[key];
      }
    }
  }

  /** Sets the error, warn, and/or trace loggers for one or more registered traceable objects.
   * @param match A string exactly identifying a single registered traceable, or a regex
   * matching 0 or more registered traceables.
   * @param options A hash of options defining how/whether to set the logging for selected
   * traceables. If a boolean, the associated error logger will be set to the default trace or
   * ignore value. If a function, the function itself will be assigned as the logger. Additional
   * named options may be passed  */
  set(match: string | RegExp, options: Traceable.Options | { [name: string]: any }): void {
    for (var traceable of this.getTraceables(match)) {
      if (traceable && typeof traceable.setTracing === "function") {
        traceable.setTracing(options);
      }
    }
  }

  /** Enable every registered traceable with the enableAllOptions object as argument. */
  enableAll() {
    this.set(/.*/, this.enableAllOptions);
  }

  /** Enable every registered traceable with the disableAllOptions object as argument. */
  disableAll() {
    this.set(/.*/, this.disableAllOptions);
  }
}
