export interface IMeasurementScope extends angular.IScope {
    measurements: IMeasurementContainer;
}

export interface IMeasurementContainer {
    values: { [key: string]: IMeasurement };
    variant: string;
}

export interface IMeasurement {
    value: string;
}

export interface MeasurementController extends angular.IController {}
export abstract class MeasurementController implements angular.IController {

    private static instanceNumber: number = 0;
    private readonly instanceNumberVar: number;

    constructor(protected scope: IMeasurementScope) {
        this.instanceNumberVar = MeasurementController.instanceNumber++;
    }

    protected getMeasurementValue(key: string) : string {
        var mmt = this.scope.measurements.values[key];
        return mmt === null ? null : mmt.value;
    }

    protected setMeasurementValue(key: string, value: any, force: boolean = false) {
        const measurement = this.scope.measurements.values[key];
        if (!measurement) {
            console.error(`Cannot find measurement by key: ${key}`);
        }
        else if (force || measurement.value !== value) {
            measurement.value = value;
        }
    }

    protected watchMeasurement(key: string, fn: () => void) {
        this.scope.$watch(`measurements.values.${key}.value`, () => fn());
    }

    /* Provide our own round function as Math.round and .toFixed have significant issues
        that would cause rounding to do not what we expect.
        See: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Math/round#PHP-Like_rounding_Method */
    protected round(number: number, precision : number): number {
        var factor: number = Math.pow(10, precision);
        var tempNumber: number = number * factor;
        var roundedTempNumber: number = Math.round(tempNumber);
        return roundedTempNumber / factor;
    }
}