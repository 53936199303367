import { BindingOf, IChangesObject } from "../componentBindings";
import "./expansionPanel.component.scss";
import { Accordion } from "./accordion.component";
import "angular-animate";
import * as angular from "angular";
import HeaderComponent from "./expansionPanelHeader.component";

interface Bindings {
    /** Whether the panel is expanded or collapsed. */
    expanded?: boolean;
    /** Whether to display the toggle indicator arrow. */
    hideToggle?: boolean;
    /** Whether this expansion panel is disabled. */
    disabled?: boolean;
    /** Event emitted every time the ExpansionPanel is opened. */
    opened: () => void;
    /** Event emitted every time the ExpansionPanel is closed. */
    closed: () => void;
}

/** MatExpansionPanel's states. */
export type ExpansionPanelState = "expanded" | "collapsed";

function updateClass(element: angular.IAugmentedJQuery, cssClass: string) {
    return function(isExpanded: boolean) {
        if (isExpanded) {
            element.addClass(cssClass);
        } else {
            element.removeClass(cssClass);
        }
    };
}

export interface ExpansionPanel extends angular.IController, Bindings {}
export class ExpansionPanel implements angular.IController, Bindings {
    accordion?: Accordion;
    opened: () => void;
    closed: () => void;

    static $inject = ["$scope", "$element", "$mdTheming"];
    constructor(
        private readonly $scope: angular.IScope,
        private readonly $element: angular.IAugmentedJQuery,
        private readonly $mdTheming
    ) {
        /* Set up css classes & attributes to match those in the angular2 mat-expansion-panel.
        They're applied directly to the directive element in those, and Angular seems to provide
        functionality for that case. AngularJS expects you to add ng-class and whatnot to an
        element inside the template, which isn't quite the same.*/
        $element.addClass("mds-expansion-panel");
        $scope.$watch(() => this.hasSpacing(), updateClass($element, "mds-expansion-panel-spacing"));
    }

    $onInit() {
        this.$element.addClass("_md");     // private md component indicator for styling
        this.$mdTheming(this.$element);

        if (this.accordion) {
            this.accordion.registerPanel(this);
            this.$scope.$watch(() => this.expanded, expanded => {
                if (expanded) {
                    this.accordion.onPanelOpen(this);
                }
            });
        }
    }

    $onDestroy() {
        if (this.accordion) {
            this.accordion.removePanel(this);
        }
    }

    /** Whether the expansion indicator should be hidden. */
    showToggle(): boolean {
        if (this.accordion) {
            return !this.accordion.hideToggle;
        }
        return !this.hideToggle;
    }

    /** Determines whether the expansion panel should have spacing between it and its siblings. */
    hasSpacing(): boolean {
        if (this.accordion && this.expanded) {
            return (this.accordion.displayMode || "default") === "default";
        }
        return false;
    }

    toggle() {
        this.expanded = !this.expanded;
        if (this.expanded) {
            this.$element.addClass("mds-expanded");
            if (this.opened) {
                this.opened();
            }
        } else {
            this.$element.removeClass("mds-expanded");
            if (this.closed) {
                this.closed();
            }
        }
    }

    close() {
        if (this.expanded) {
            this.toggle();
        }
    }

    open() {
        if (!this.expanded) {
            this.toggle();
        }
    }
}

export default <angular.IComponentOptions> {
    controller: ExpansionPanel,
    bindings: <BindingOf<Bindings>> {
        expanded: "=?",
        hideToggle: "<?",
        opened: "&?",
        closed: "&?"
    },
    require: {
        accordion: "?^^mdsAccordion"
    },
    transclude: {
        header: "mdsExpansionPanelHeader",
        content: "mdsExpansionPanelContent",
        actionRow: "?mdsExpansionPanelActionRow"
    },
    templateUrl: require("./expansionPanel.component.html")
};