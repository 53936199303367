import { IScope } from 'angular';
import { BusinessModelService } from "../../businessModels";

/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */

export default ["$scope", "$state", "businessModels", ($scope: IScope, $state, models: BusinessModelService) => {
  $scope.checkAndGo = function() {
    $scope.error = null;
    $scope.guide.orgName.$setValidity("checking", false);
    const checkLoad = models.Institute.checkKeyIsFree($scope.orgName.toLowerCase())
    .then(function(available) {
      $scope.guide.orgName.$setValidity("checking", true);
      if (available) { 
        return $scope.error = "Cannot find an organisation by that name. Please check and try again";
      } else { 
        return $state.go("login", { inst: $scope.orgName.toLowerCase() }); }});
    return models.load.track(checkLoad);
  }
}];
