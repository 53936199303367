import { IController } from "angular";
import { IStudyTitle } from './StudyTitle';

interface ChartsController extends IController {}
class ChartsController implements IController {
    static $inject = ["$scope", "study", "title", "references", "businessModels"];

    constructor(
        private scope: IChartsControllerScope,
        private study,
        private title: IStudyTitle,
        private references,
        private models) {
        title.text = "Charts";
        title.clearButtons();
        title.backButton.show = false;
        title.acceptButton.show = false;
        scope.study = study;
    }

    view(img) {
        this.references.select("Charts", img.id);
    }

    toggleInReport(img) {
        if (!this.study.userCanEdit() || this.models.User.current.hasRole('Read-Only Mode'))
            return;
        this.models.save(() => {
            img.isIncludedInReport = !img.isIncludedInReport;
        });
    }
}

export interface IChartsControllerScope extends angular.IScope {
    study;
}

export default ChartsController;