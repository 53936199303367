import {module as ngModule} from "angular";
import { ReportFormatter } from "../../businessModels";

function isFormatterLegacy(formatter: ReportFormatter): boolean {
  return formatter.type !== "SelfServe";
}

function isFormatterRemoved(formatter: ReportFormatter): boolean {
  return formatter.isDeleted;
}

function isFormatterEditable(formatter: ReportFormatter): boolean {
  return !isFormatterLegacy(formatter) && !isFormatterRemoved(formatter);
}

export default ngModule("midas.admin.reportFormatters.reportFormatterListFilters", [])
  .filter("isFormatterLegacy", () => isFormatterLegacy)
  .filter("isFormatterRemoved", () => isFormatterRemoved)
  .filter("isFormatterEditable", () => isFormatterEditable);