import {
  module as ngModule,
  IScope,
  IAttributes
} from "angular";
import reportEditorModule, {
  TinymceConfigurationController,
  tinymceConfigurationRequire,
  ToolbarAttributes
} from "../mds-rich-text-editor.component";
import { MeasurementType } from "../../../businessModels";
import { BindingOf } from "../../../utility/componentBindings";
import { Editor, Settings, ui } from "tinymce";
import {
  IMeasurementTypeExpression, ILiteralString, IBinaryExpression, BlueprintExpression, IUnaryExpression
} from "../../../utility/reportFormatter/widgets/condition-expression.models";
import {
  IBlueprintExpressionSerialiser, serviceName
} from "../../reportFormatter/widgets/condition-expression-serialiser.service";
import reportFormatterMeasurementTypeDialogModule from "../../../admin/reportFormatters/dialogs/report-formatter-measurement-type.dialog";
import { directiveName, BlueprintContextController } from '../../../utility/reportFormatter/blueprintContext.directive';

/** Prompt user for a measurement type. It will output a condition for each drop down option.  */
class InsertOptionsController extends TinymceConfigurationController implements IBindings {
  context: BlueprintContextController;
  onInserted: () => void;

  static $inject = ["$scope", "$attrs", "$mdDialog", serviceName];
  constructor(
    private readonly $scope: IScope,
    private readonly $attrs: IAttributes,
    private readonly $mdDialog: angular.material.IDialogService,
    private readonly conditionService: IBlueprintExpressionSerialiser) {
      super();
  }

  configure(options: Settings) {
      const toolbar = ToolbarAttributes.FromAttributes(this.$attrs);
      this.extendToolbar(toolbar.id, "insert-options", options);
  }

  setup(editor: Editor) {
      const _this = this;
      const buttonOptions = <any>{
          tooltip: "Insert options into report",
          icon: "fa fa-list",
          onPostRender() { _this.disableWhenTextSelected(editor, this); },
          onclick: () => this.$scope.$apply(() => this.onInsertOptionsClicked(editor))
      };
      editor.addButton("insert-options", buttonOptions);
  }

  /** Watch selection change and disable button if the user has selected text. */
  private disableWhenTextSelected(editor: Editor, button: ui.Control): void {
    editor.on("NodeChange", () => {
      button.disabled(!editor.selection.isCollapsed());
    });
  }

  /** Prompt user for measurement type and insert the options. */
  private onInsertOptionsClicked(editor: Editor): void {
    this.$mdDialog.showMeasurementTypePrompt(this.context.measurementTypes).then(mt => this.insertOptions(editor, mt));
  }

  /**
   * Insert a condition for a measurement type. If it has dropdown options, it will output a condition for each option.
   * If the measurement type does not have dropdowns it will create
   * a condition that checks for existance of the measurement.
   * @param editor the TinyMCE editor.
   * @param mt the measurement type to insert options.
   */
  private insertOptions(editor: Editor, mt: MeasurementType): void {
    const operand1: IMeasurementTypeExpression = {
      type: "measurement-type",
      value: mt.key
    };
    if (mt.dropdownOptions != null && mt.dropdownOptions.length > 1) {
      for (const opt of mt.dropdownOptions) {
        const operand2: ILiteralString = {
          type: "literal-string",
          value: opt
        };
        const condition: IBinaryExpression = {
          type: "binary-expression",
          operand1,
          operator: "===",
          operand2
        };
        this.insertCondition(editor, condition, opt);
      }
    } else {
      const condition: IUnaryExpression = {
        type: "unary-expression",
        operand1,
        operator: "| exists"
      };
      const measurement = `<report-measurement key="${mt.key}"></report-measurement>`;
      const content = `${mt.key} is ${measurement}`;
      this.insertCondition(editor, condition, content);
    }
    if (this.onInserted != null) {
      this.onInserted();
    }
  }

  /**
   * Insert html template for a condition.
   * @param editor the TinyMCE editor
   * @param condition the condition expression to serialise into the template.
   * @param content the content for the conditional.
   */
  private insertCondition(editor: Editor, condition: BlueprintExpression, content: string): void {
    const tagType = "span";
    const conditionHtml = this.conditionService.serialise(condition);
    const conditionalHtml =
      `<${tagType} data-conditional class="mds-conditional">
        <span data-condition class="mds-condition">
          ${conditionHtml}
        </span>
        <${tagType} class="mds-conditional-content">${content}</${tagType}>
      </${tagType}>`;
    editor.insertContent(conditionalHtml);
  }
}

interface IBindings {
  onInserted: () => void;
}

const bindings: BindingOf<IBindings> = {
  onInserted: "&?"
};

export default ngModule("midas.utility.tinymce.insert-options", [
  reportEditorModule.name,
  reportFormatterMeasurementTypeDialogModule.name
])
.component("insertOptionsButton", {
  require: {
      ...tinymceConfigurationRequire,
      context: "^" + directiveName
  },
  controller: InsertOptionsController,
  bindings
});