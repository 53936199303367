/*** IMPORTS FROM imports-loader ***/
var define = false;
var exports = (exports || {});
exports.__esModule = true;
var _css = require("Content/NonNuget/introjs.min.css");
var $ = require("jquery");

(function (root, factory) {
    if (typeof define === 'function' && define.amd) {
        define(["angular", "intro"], factory);
    } else if (typeof exports === 'object') {
        module.exports = factory(require('angular'), require('intro'));
    } else {
        root.angularIntroJs = factory(root.angular, root.introJs);
    }
}(this, function (angular, introJs) {


    var ngIntroDirective = angular.module('angular-intro', []);


    ngIntroDirective.directive('ngIntroOptions', ['$timeout', function ($timeout) {

        return {
            restrict: 'A',
            scope: {
                ngIntroMethod: "=",
                ngIntroExitMethod: "=?",
                ngIntroOptions: '=',
                ngIntroOncomplete: '=',
                ngIntroOnexit: '=',
                ngIntroOnchange: '=',
                ngIntroOnbeforechange: '=',
                ngIntroOnafterchange: '=',
                ngIntroAutostart: '&',
                ngIntroAutorefresh: '='
            },
            link: function(scope, element, attrs) {

                var intro;

                scope.ngIntroMethod = function(step) {


                    var navigationWatch = scope.$on('$locationChangeStart', function(){
                      //intro.exit();
                    });

                    if (typeof(step) === 'string') {
                        intro = introJs(step);

                    } else {
                        intro = introJs();
                    }

                    intro.setOptions(scope.ngIntroOptions);

                    if (scope.ngIntroAutorefresh) {
                      scope.$watch(function(){
                        intro.refresh();
                      });
                    }

                    if (scope.ngIntroOncomplete) {
                      intro.oncomplete(function () {
                            scope.ngIntroOncomplete.call(this, scope);
                            $timeout(function() {scope.$digest()});
                            navigationWatch();
                        });
                    }

                    if (scope.ngIntroOnexit) {
                        intro.onexit(function() {
                            scope.ngIntroOnexit.call(this, scope);
                            $timeout(function() {scope.$digest()});
                            navigationWatch();
                        });
                    }

                    if (scope.ngIntroOnchange) {
                      intro.onchange(function (targetElement) {
                            var stepIndex = $(this)[0]._currentStep;
                            scope.ngIntroOnchange.call(this, targetElement, scope, stepIndex);
                            $timeout(function() {scope.$digest()});
                        });
                    }

                    if (scope.ngIntroOnbeforechange) {
                      intro.onbeforechange(function (targetElement) {
                            intro.refresh();
                            var stepIndex = $(this)[0]._currentStep;
                            scope.ngIntroOnbeforechange.call(this, targetElement, scope, stepIndex);
                            $timeout(function() {scope.$digest()});
                        });
                    }

                    if (scope.ngIntroOnafterchange) {
                      intro.onafterchange(function (targetElement) {
                            var stepIndex = $(this)[0]._currentStep;
                            scope.ngIntroOnafterchange.call(this, targetElement, scope, stepIndex);
                            $timeout(function() {scope.$digest()});
                        });
                    }

                    if (typeof(step) === 'number') {
                        intro.goToStep(step).start();
                    } else {
                        intro.start();
                    }
                };

                scope.ngIntroExitMethod = function (callback) {
                    intro.exit();
                    callback();
                };

                if (scope.ngIntroAutostart()) {
                    $timeout(function() {
                        scope.ngIntroMethod();
                    });
                }
            }
        };
    }]);

    return ngIntroDirective;

}));



