import { IDirectiveFactory, IAttributes, IAugmentedJQuery } from "angular";
import { PenTool } from "./penTool";
import { IToolScope } from "./tool";
import { ImageEditorController, } from "../imageEditorCore";

/**
 * This directive allows free erasing with configurable thickness.
 * Requires a mapping to a config object of the following format:
 * scope.config = { thickness: 5 };
 * ...
 * <div pen-tool="config"></div>
 */
export default <IDirectiveFactory>(() => ({
    require: "^mdsImageEditor",
    restrict: "E",
    scope: <{ [key: string]: string }>{
        config: "="
    },

    link(scope: IToolScope, _element: IAugmentedJQuery, _attr: IAttributes, ctrl: ImageEditorController) {
        ctrl.tools.registerTool("eraser", new PenTool("eraser", "eraser", ctrl, scope, "destination-out"));
    }
}));