import {
    IShape,
    IImageEditorScope,
    ImageEditorController
} from "../imageEditorCore";
import {
    IPointLike,
    Vector2,
    IDisposable
} from "../../../utility/utils";
import {
    Tool,
    IToolScope
} from "./tool";
import {
    Line
} from "../shapes/shapes";
import ToolActivationContext from "./toolActivationContext";
import {
    IDirectiveFactory,
    IAttributes,
    IAugmentedJQuery
} from "angular";

export interface ILineModel extends IShape {
    type: "line";
    thickness: number;
    primaryColour: string;
    points: IPointLike[];
}

export interface ILineScope extends IImageEditorScope {
    config: {
        arrowStart ? : boolean;
        arrowEnd ? : boolean;
        thickness ? : number;
        primaryColour ? : string;
    };
    colours: string[];
}

export class LineTool < TModel extends ILineModel > extends Tool < Line, TModel > {
    $shapeType: "line";
    $scope: ILineScope;

    protected $createViewModel(dataModel ? : ILineModel): Line {
        const cmToStage = this.cmToStage(1);
        const points = ((dataModel && dataModel.points) || []).map(p => new Vector2(p).multiply(cmToStage));
        let model = dataModel || this.$scope.config;
        const vm = new Line(points, this.cmToStage(model.thickness), model.primaryColour);
        if (this.compositeOp) {
            vm.ink.compositeOperation = this.compositeOp;
        }
        this.$copyTraceSettingsTo(vm);
        return vm;
    }

    protected $updateDataModel(viewModel: Line = this.$viewModel, dataModel?: ILineModel): ILineModel {
        dataModel = dataModel || <any>{};
        dataModel.type = this.$shapeType;
        dataModel.thickness = this.stageToCm(viewModel.thickness);
        dataModel.primaryColour = viewModel.colour;
        const stageToCm = this.stageToCm(1);
        dataModel.points = viewModel.points.map(p => p.clone().multiply(stageToCm));
        return dataModel;
    }

    /** Creates a grip which moves a point around with it.
     * @param p The point to create a grip for.
     * @returns A disposable that deregisters all control bindings, and removes the control.
     */
    protected $createGripFor(context: ToolActivationContext, p: Vector2): IDisposable {
        const grip = context.controls.createGripControl(p, undefined, 0);
        const registration = grip.bind(p, () => this.$viewModel.render(this.$isEditing));
        return () => {
            context.controls.remove(grip);
            registration();
        }
    }

    /** Returns true if the model is a line, and has either a start or end arrow. */
    isValid(dataModel: IShape): dataModel is TModel {
        return dataModel && dataModel.type === this.$shapeType && !dataModel["arrowStart"] && !dataModel["arrowEnd"];
    }

    addPoint(p: Vector2) {
        this.$viewModel.points.push(p);
        this.update();
    }

    setLastPoint(p: Vector2) {
        const vm = this.$viewModel;
        vm.points[vm.points.length - 1].set(p);
        this.update();
    }

    removeLastPoint() {
        const vm = this.$viewModel;
        vm.points.splice(vm.points.length - 1, 1);
        this.$viewModel
    }

    /** Gets the last point in the view model */
    get lastPoint() {
        return this.$viewModel.points[this.$viewModel.points.length - 1];
    }

    activate(context: ToolActivationContext) {
        super.activate(context);
        const editModel = < ILineModel > context.editModel;
        this.$viewModel = this.$createViewModel(editModel);
        if (context.editModel) {
            for (let p of this.$viewModel.points) {
                this.$createGripFor(context, p);
            }
            this.addAcceptDeselectButtons(context);
            this.update();
        }

        this.$ctrl.context.scratch.addChild(this.$viewModel.ink);

        const pipe = this.$pipe;
        context.dispose.add(
            this.watchConfig("thickness", pipe.unitToStage("cm"), pipe.setViewModel("thickness")),
            this.watchConfig("primaryColour", pipe.setViewModel("colour")));

        //Create a control which spans the entire stage, and use it to place points on click.
        context.controls.createInteractControl().subscribe(
            p => {
                this.addPoint(new Vector2(p));
                this.addAcceptDeselectButtons(context);
            },
            p => this.setLastPoint(new Vector2(p)),
            () => this.$createGripFor(context, this.lastPoint),
            () => this.removeLastPoint());

        context.observe("accept", () => {
            this.$viewModel.bakeTransform();
            context.addOrUpdate(this.$updateDataModel(this.$viewModel, editModel));
            context.deselect(true);
        });
        context.observe("deselect", () => context.reactivate());
        context.observe("delete", () => {
            context.removeEditShape();
            context.reactivate()
        });
    }

    constructor(
        public id: string,
        ctrl: ImageEditorController,
        scope: IToolScope,
        public compositeOp: string = null,
        shapeType: string = "line"
    ) {
        super(ctrl, scope, shapeType);
    }
}

export default < IDirectiveFactory > () => ({
    require: "^mdsImageEditor",
    restrict: "E",
    scope: {
        config: "="
    },
    link(scope: any, element: IAugmentedJQuery, attrs: IAttributes, ctrl: ImageEditorController) {
        ctrl.tools.registerTool("line", new LineTool("line", ctrl, scope));
    }
});