import { IAuthService } from '../../authService';
import { IStateService } from 'angular-ui-router';

/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */

export default ["$state", "authService", ($state: IStateService, authService: IAuthService) =>
  ({
    restrict: "A",
    link(_$scope, $element, _$attrs) {
      return $element.bind('click', function() {
        authService.logout();
        return $state.go("splash", { inst: $state.params.inst });
    });
    }
  })

];
