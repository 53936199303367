import { element as ngElement } from "angular";
import { Editor } from 'tinymce';

const insertMarkerId = "mds-bp-insert-marker";
/** A element HTML which is used to insert our own HTML. Good old tinymce refuses to insert an empty
 * span (or one with an &nbsp;) into an empty document for some reason, hence the content. It's
 * shithouse because it creates an intermediate undo step. */
const insertMarker = `<span id="${insertMarkerId}">_</span>`;

/** An interface used during compilation of a blueprint against a concrete exam. It contains
 * information on measurement values, and incremental compilation information such as markers which
 * were rendered into the report. */
export interface IWidgetCompilationContext {
  /** The value for each measurement key. This may be read or assigned to. Measurement values for
   * any bound exam are the default for each measurement key, unless overridden. */
  values: {};
  /** A set of marker names where inclusion in this set indication a particular marker was
   * rendered into the output of the report. */
  markers: Set<string>;
}

/** The API for services for managing report/blueprint DOM widgets by creating widget controllers
 * from DOM elements. */
export abstract class WidgetService<TController extends WidgetController> {
  constructor(public readonly querySelector: string) { }
  /** Creates a new detached widget object. */
  abstract create(): TController;

  /** Gets a controller object for the provided DOM. It is not checked first, but can be checked
   * after construction using isValid. */
  abstract get(element: JQuery): TController;

  /** Gets whether the provided element is editable according to this kind of widget. */
  isEditable(element: JQuery): boolean {
    return this.closest(element) == null;
  }

  /** Finds the closest appropriate widget up the DOM hierarchy, starting with the provided node. */
  closest(element: JQuery): TController {
    const closest = element.closest(this.querySelector);
    if (closest.length === 1) {
      return this.get(closest);
    }
    return null;
  }

  /** Finds all appropriate widgets beneath the provided element. */
  descendants(element: JQuery): TController[] {
    const result: TController[] = [];
    element.find(this.querySelector).each((_, el) => {
      result.push(this.get(ngElement(el)));
    });
    return result;
  }
}

/** A report/blueprint widget controller, which wraps some DOM and provides functions for
 * interrogating and interacting with the widget. */
export abstract class WidgetController {
  abstract readonly $element: JQuery;

  /** Tests whether the widget controller is valid, like checking that it was created with the
   * appropriate DOM. */
  abstract readonly isValid: boolean;

  /** Refreshes the widget against the provided context, updating the DOM where needed. */
  abstract refresh(context: IWidgetCompilationContext);

  /** Removes this expression from the DOM. */
  remove() {
    if (this.$element) {
      this.$element.remove();
    }
  }

  /** Strips all non-report content from the widget. Conditional widgets that are not visible should
   * be stripped entirely. */
  abstract strip();

  /** Inserts/moves this widget instance into the provided editor at the current cursor. */
  insert(editor: Editor) {
    // We do a bit of a dance here to insert the exact DOM of this widget, rather than a copy.
    // Inserting a copy (which is how tinymce prefers you to do it) makes it very difficult to
    // then go and do anything with the inserted widget, such as selecting part of it or getting a
    // handle on it's controller. This way the original widget controller still works, which is a
    // lot more how you'd expect things to work. tinymce itself does a similar dance internally.
    editor.insertContent(insertMarker, { format: "raw" });
    const marker = editor.dom.get(insertMarkerId);
    ngElement(marker).replaceWith(this.$element);
    editor.fire("change");
    editor.setDirty(true);
  }
}