import { IScope, IQService, ITimeoutService, ILogService } from "angular";
import "angular-ui-router";
import { IStateService, IStateParamsService } from "angular-ui-router";
import { Study, BusinessModelService } from "../../businessModels";
import { IStudyTitle } from "../../midas/studies/StudyTitle";
import { LoDashStatic } from "lodash";
import ReferenceService from "../../midas/reference/referencesService";

/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */

export default [
  "$scope", "$state", "$stateParams", "study", "title", "businessModels", "$q",
  "promptLoseChangesAndNavigate", "lodash", "$timeout", "references", "$log",
  function($scope: IScope, $state: IStateService, $stateParams: IStateParamsService,
           study: Study, title: IStudyTitle, models: BusinessModelService, $q: IQService,
           promptLoseChangesAndNavigate, lodash: LoDashStatic, $timeout: ITimeoutService,
           references: ReferenceService, $log: ILogService) {

    $scope.study = study;
    title.text = "Measurements";
    title.acceptButton.loading();
    title.clearButtons();

    models.save.status.onChanged(status => title.acceptButton.isBusy = status.isLoading)
          .disposeWith($scope);

    const saveRequested = function (redirectAllowed = true, toState?, toParams?, basicSave = false): angular.IPromise<any> {

        const saveDefer = $q.defer();
        models.load.track(saveDefer.promise);

        //Keep track of if we've already asked the children to save. We could receive multiple "editAccepted" acknowledgments,
        //as there could be multiple measurement views in the case of multiple exams
        let saveQueued = false;
        //Any of the measurement views could emit an error, so save the latest one for display\logging
        let error = null;
        //Ask measurements to commit changes to the models. They'll signal when they're done

        $scope.$broadcast("acceptEdit");

        $scope.$on("editAccepted", function (e, err) {
            if (err != null) { error = err; }
            if (saveQueued) { return; }
            saveQueued = true;

            //Set timeout so that all children have a chance to commit to models before we trigger the save
            return $timeout(() =>
                //Even if there is an error in committing, probably best to attempt a save
                {
                    if (basicSave === false) {
                        models.save(function () { })
                        .then(() =>
                            //Reload and tell all exam images to refresh, as there may be updated images
                            //due to screenshots being taken of measurement elements
                            models.Study.find({id: study.id}, "exams.imageLinks.image")
                            .then(() => study.recreateCharts())
                            .then(() => references.refresh("Charts", true))
                            .then(function() {
                                saveDefer.resolve();
                                if (error != null) {
                                    $log.error("Error saving measurements", error);
                                } else if (redirectAllowed) {
                                    if (toState && toParams) {
                                        $state.go(toState, toParams);
                                        return;
                                    }
                                return goToNextState();
                                }
                            })
                        )
                    } else {
                        models.save().then(function () {
                            saveDefer.resolve()
                        });
                    }
                }
            );
        });

        return saveDefer.promise;
    };

    var goToNextState = function() {
      const args: any = { studyId: $stateParams.studyId };
      //Go to charts tab if we have some
      let state = (study.charts != null ? study.charts.length : undefined) > 0 ? "midas.studies.view.charts" : "midas.studies.view.reports";
      //Add fullscreen layout state to state if it's present
      if ($state.params.layoutState && $state.params.layoutArgs) {
        state = state + ".layout";
        args.layoutState = $state.params.layoutState;
        args.layoutArgs = $state.params.layoutArgs;
      }

      return $state.go(state, args);
    };

    //saveRequested comes from save, provisional or final buttons
    //If it is the P or F buttons then they want to handle the navigation
    //to the next study, so redirection is not allowed by child
    $scope.$on("saveRequested", function(e, args) {
      $scope.$root.$broadcast("reference-reload", { ifCategory: "Measurements" });
      //Prevent caller from continuing until we have finished saving.
      e.preventDefault();
      //Emit event for caller on completion
      return saveRequested(args != null ? args.redirectAllowed : undefined).then(() => $scope.$emit("saveComplete"));
    });

    const canEdit = study.userCanEdit() && !models.User.current.hasRole('Read-Only Mode');
    title.text = (canEdit ? "Edit" : "View") + " Measurements";
    title.acceptButton.ready();
    $scope.mode = canEdit ? "" : "Readonly";

    //Multiple option objects, one for each exam
    $scope.options = {};
    for (let exam of study.exams) {
      $scope.options[exam.id] = {};
    }

    const stop = promptLoseChangesAndNavigate.subscribe({
      //Is dirty function is injected onto each view's option object
        isDirty() {
            return lodash.any<any>($scope.options, o => o.isDirty());
        },
        save(state, params, basicSave): angular.IPromise<any> {
            return saveRequested(true, state, params, basicSave)
        }
    });
    $scope.$on("$destroy", stop);
  }
];
