import {
  IParseService, module as ngModule, element as ngElement
} from "angular";
import filterModule from "./condition-expression.filters";
import { ConditionalController } from "./conditional.service";
import { BlueprintExpression } from './condition-expression.models';
import expressionSerialiserModule, {
  serviceName as expressionSerialiserName,
  IBlueprintExpressionSerialiser
} from "./condition-expression-serialiser.service";
import { WidgetController, WidgetService, IWidgetCompilationContext } from "./widget";

export const serviceName = "reportVariable";
export const querySelector = "[data-variable]";

/** The most basic raw DOM string for creating report VARIABLES. */
const rawDom = "<span data-variable>";

export class VariableService extends WidgetService<VariableController> {
  static $inject = ["$parse", expressionSerialiserName];
  constructor(
    private readonly $parse: IParseService,
    private readonly exprSer: IBlueprintExpressionSerialiser) {
      super(querySelector);
  }

  /** Creates a new detached expression object. */
  create(): VariableController {
    return new VariableController(ngElement(rawDom), this.$parse, this.exprSer);
  }

  /** Gets a controller object for the provided DOM. It is not checked first. */
  get(element: JQuery): VariableController {
    return new VariableController(element, this.$parse, this.exprSer);
  }
}

/** A widget controller to evaluate an expression and store the result in the report context,
 * depending on the surrounding conditions. */
export class VariableController extends WidgetController {

  /** The parent condition surrounding this variable, if it has one. Used to determine whether this
   * variable is included in the report or not. */
  private readonly condition?: ConditionalController;

  /** The value of the variable. Updated when `this.refresh()` is called. */
  result: any;

  constructor(
    readonly $element: angular.IAugmentedJQuery,
    private readonly $parse: IParseService,
    private readonly exprSer: IBlueprintExpressionSerialiser) {
      super();
  }

  /** Gets the variable name text from the DOM. */
  get nameText(): string {
      return this.getNameNode().text();
  }

  /** Sets the variable name text into the DOM. */
  set nameText(name: string) {
    this.getNameNode(true).text(name);
  }

  /** Gets the expression from the DOM. */
  get expression(): BlueprintExpression {
    const exprNode = this.getExpressionNode().children();
    if (exprNode.length) {
      return this.exprSer.deserialise(exprNode);
    }
    return undefined;
  }

  /** Sets the expression into the DOM. */
  set expression(expression: BlueprintExpression) {
    const exprNode = this.getExpressionNode(true);
    if (exprNode.length > 0) {
      exprNode.children().remove();
    }
    if (expression) {
      const serialisedExpression = this.exprSer.serialise(expression);
      exprNode.append(serialisedExpression);
    }
  }

  /** Refreshes `this.result` from the current expression and context, then updates the context
   * depending on how this variable is included in the report. */
  refresh(context: IWidgetCompilationContext) {
    const expressionText = this.getExpressionNode().text();
    const compiledExpression = this.$parse(expressionText);
    const result = this.result = compiledExpression(context.values);

    const name = this.nameText;

    if (result !== undefined) {
      context.values[name] = result;
    } else if (context.values.hasOwnProperty(name)) {
      delete context.values[name];
    }
  }

  /** Strips the entire node from the DOM, as variables are purely compile time constructs. If the
   * value should be applied, then ensure you refresh before calling this. */
  strip() {
    this.remove();
  }

  /** Gets the name node.
   * @param createIfNotExists Whether to create the node and attach it to the DOM if it doesn't
   * already exist. DO NOT set this to true anywhere angular might be compiling (like
   * `this.refresh()`). */
  getNameNode(createIfNotExists: boolean = false) {
    let nameNode = this.$element.children(".name");
    if (nameNode.length === 0 && createIfNotExists) {
        nameNode = ngElement('<span class="name">');
        nameNode.prependTo(this.$element);
    }
    return nameNode;
  }

  /** Gets the expression node. The child can be passed directly to the expression serialiser.
   * @param createIfNotExists Whether to create the node and attach it to the DOM if it doesn't
   * already exist. DO NOT set this to true anywhere angular might be compiling (like
   * `this.refresh()`). */
  getExpressionNode(createIfNotExists: boolean = false) {
    let expressionNode = this.$element.children(".expr");
    if (expressionNode.length === 0 && createIfNotExists) {
        expressionNode = ngElement('<span class="expr">');
        expressionNode.appendTo(this.$element);
    }
    return expressionNode;
  }

  /** Gets whether this object was created with an appropriate element. */
  get isValid() {
    return this.$element != null && this.$element.length === 1 && this.$element.is(querySelector);
  }
}

export default ngModule("midas.blueprint.variable", [
  filterModule.name,
  expressionSerialiserModule.name
]).service(serviceName, VariableService);