import { IComponentOptions, IController } from "angular";
import { BindingOf, IChangesObject } from "../../componentBindings";

interface Bindings {
    limit: number;
    label: string;
    colour: string;
    colours: string[];
}

interface ColourSelectControl extends IController, Bindings { }

const limitDefault = 5;

class ColourSelectControl implements IController {
    limitTo: number;
    $onInit() {
        if (this.limit == null) {
            this.limitTo = limitDefault;
        }
    }
    $onChanges?(changes: IChangesObject<Bindings>) {
        if (changes.limit) {
            this.limitTo = changes.limit.currentValue != null
                ? changes.limit.currentValue
                : limitDefault;
        }
    }
}

export default <IComponentOptions>{
    bindings: <BindingOf<Bindings>>{
        label: "<?",
        colour: "=",
        colours: "<",
        limit: "<?"
    },
    controller: ColourSelectControl,
    templateUrl: require("./colourSelectControl.html")
};