import * as angular from "angular";
import { BindingOf, IChangesObject } from "../../../utility/componentBindings";
import { Glossary } from "../../../businessModels";

interface ComponentBindings {
    entry: Glossary;
    hideToolbar: boolean;
    onUpdated: () => void;
    onDeleteRequested: () => void;
}

class Controller implements angular.IComponentController, ComponentBindings {
    entry: Glossary;
    hideToolbar: boolean;
    onUpdated: () => void;
    onDeleteRequested: () => void;

    static $inject = ["$log"];

    $onChanges(obj: IChangesObject<ComponentBindings>): void {
        if (!obj.entry)
            return;
        this.entry = obj.entry.currentValue;
    }

    notifyUpdate() {
        this.onUpdated();
    }

    delete() {
        this.onDeleteRequested();
    }
}

export default <angular.IComponentOptions> {
    templateUrl: require('./glossaryEntry.html'),
    bindings: {
        entry: '<',
        hideToolbar: '<?',
        onUpdated: '&',
        onDeleteRequested: '&'
    } as BindingOf<ComponentBindings>,
    controller: Controller
};