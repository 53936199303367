import { Diagram, DiagramTemplate, BusinessModelService, Study } from "../../businessModels";
import { LoDashStatic } from "lodash";
import { IStudyTitle } from "./StudyTitle";

class DiagramView {
    public diagram: Diagram;
    public template: DiagramTemplate;
    private name: string;
    private editPath: string;

    constructor($scope: any, diagram: Diagram, template: any) {
        if (template == null) {
            this.template = null;
        }
        if (diagram != null) {
            this.diagram = diagram;
        }
        this.template = template != null ? template : diagram.template;
        this.name = this.template.name;
        if (diagram != null) {
            Object.defineProperty(this, "imagePath", {
                get() {
                    return diagram.imagePath;
                }
            });
            this.editPath = "midas.studies.view.diagrams.edit({ studyId: " + $scope.studyId + ", templateId: " + diagram.template.id + " })";
        } else {
            Object.defineProperty(this, "imagePath", {
                get() {
                    return template.imagePath;
                }
            });
            this.editPath = "midas.studies.view.diagrams.new({ studyId: " + $scope.studyId + ", templateId: " + template.id + " })";
        }
    }
}

const __hasProp = {}.hasOwnProperty;
export default [
    "$scope", "businessModels", "$state", "$stateParams", "lodash", "$rootScope", "study", "title", "$uibModal", "Upload",
    ($scope: angular.IScope, models: BusinessModelService, $state: angular.ui.IStateService,
        $stateParams: angular.ui.IStateParamsService, _: LoDashStatic, $rootScope: angular.IRootScopeService,
        study: Study, title: IStudyTitle, $modal, Upload) => {
        var _ref, _ref1;

        title.text = "Diagrams";
        title.clearButtons();
        title.backButton.show = false;
        title.acceptButton.disable();
        $scope.isMobile = (_ref = (_ref1 = $state.current.data) != null ? _ref1.isMobile : void 0) != null ? _ref : false;
        $scope.studyId = $stateParams.studyId;
        $scope.study = study;
        var reloadInstituteTemplates = () => models.DiagramTemplate.list().then(() => {
            // Get all diagrams instances for the exams in the study.
            var examKey, key, values, _l, _len3;
            let diagrams = new Array<Diagram>();
            for (let exam of study.exams) {
                for (let diagram of exam.diagrams) {
                    if (diagram.isDeleted) {
                        continue;
                    }

                    diagrams.push(diagram);
                }
            }

            let examTypeKeys = new Array<string>();
            for (let exam of study.exams) {
                examTypeKeys.push(exam.type.key);
            }

            // All existing diagrams are included in the result.
            let viewModels = new Array<DiagramView>();
            for (let diagram of diagrams) {
                viewModels.push(new DiagramView($scope, diagram, null));
            }

            // Also add templates which don't already have a diagram in the result.
            if (study.userCanEdit()) {
                let templates = new Array<DiagramTemplate>();
                for (let template of study.institute.diagramTemplates) {
                    if (template.examType == null || template.examType.isDeleted || examTypeKeys.indexOf(template.examType.key) === -1 || template.isDeleted) {
                        continue;
                    }
                    templates.push(template);
                }

                for (let template of _.difference(templates, viewModels.map(vm => vm.template))) {
                    viewModels.push(new DiagramView($scope, null, template));
                }
            }

            // Group and sort the view models into their display shape as an array.
            let viewModelsByKey = _.groupBy(viewModels, vm => {
                const template = vm.template;
                if (template != null) {
                    const examType = template.examType;
                    if (examType != null) {
                        return examType.key;
                    }
                }
            });
            $scope.diagramGroups = (() => {
                var _results;
                _results = [];
                for (examKey in viewModelsByKey) {
                    if (!__hasProp.call(viewModelsByKey, examKey)) continue;
                    values = viewModelsByKey[examKey];
                    _results.push({
                        examKey: examKey,
                        diagrams: values
                    });
                }
                return _results;
            })();
            for (_l = 0, _len3 = examTypeKeys.length; _l < _len3; _l++) {
                key = examTypeKeys[_l];
                if (!viewModelsByKey[key]) {
                    $scope.diagramGroups.push({
                        examKey: key,
                        diagrams: []
                    });
                }
            }
        });

        reloadInstituteTemplates();

        $scope.remove = (vm: DiagramView) => {
            var modal;
            if (vm.diagram) {
                modal = $modal.open({
                    templateUrl: 'confirmDelete.html',
                    scope: $scope
                });
            }

            $scope.removeDiagram = () => models.save(() => (vm.diagram.breezeEntity().isDeleted = true)).then(() => {
                modal.close();
                return reloadInstituteTemplates();
            });

            $scope.cancel = () => {
                console.log('cancelling');
                return modal.dismiss();
            };
        };

        $scope.uploadFiles = (files, errFiles, examKey) => {
            var file;
            $scope.errors = [];
            if (!files) {
                return;
            }
            file = files[0];
            file.upload = Upload.upload({
                url: "api/midas/UploadDiagramTemplate/" + examKey,
                data: {
                    file: file
                }
            }).then(response => {
                if (response.data.errors != null ? response.data.errors.length : void 0) {
                    for (let error of response.data.errors) {
                        $scope.errors.push(error);
                    }
                }
                $scope.isSuccessful = response.data.successful;
                return reloadInstituteTemplates();
            })["catch"](error => {
                var _ref2;
                return $scope.errors.push("A problem occurred trying to upload the template: " + ((_ref2 = error.data) != null ? _ref2.Message : void 0));
            });
            models.load.track(file.upload);
        };
    }
]