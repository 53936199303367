import { module as ngModule, element as ngElement , IAttributes, IScope, material, INgModelController} from "angular";
import {
  TinymceConfigurationController,
  tinymceConfigurationRequire,
  ToolbarAttributes
} from '../mds-rich-text-editor.component';
import * as tinymce from "tinymce";
import { BlueprintContextController, directiveName } from '../../reportFormatter/blueprintContext.directive';
import businessModelsModule, { BusinessModelService, Exam } from '../../../businessModels';
import reportCompilerModule, {
  serviceName as reportCompilerName,
  ReportFormatterTemplateService
} from '../../../utility/reportFormatter/reportFormatterTemplate.service';


const reportPreviewButtonId = "report-preview-button";

/** Rich text editor plugin which adds a button to show a preview of the report in a popup, when
 * compiled against an exam. */
class ReportPreviewController extends TinymceConfigurationController {

  /** The context controller required by the component. */
  context: BlueprintContextController;

  /** Since this component must be below a rich text editor, and that uses ng-model to bind the
   * template, we should always be able to require this. */
  ngModel: INgModelController;

  static $inject = ["$scope", "$attrs", "$mdDialog", "businessModels", reportCompilerName];
  constructor(
    private readonly $scope: IScope,
    private readonly $attrs: IAttributes,
    private readonly $mdDialog: material.IDialogService,
    private readonly models: BusinessModelService,
    private readonly reportCompiler: ReportFormatterTemplateService) {
    super();
  }

  setup(editor: tinymce.Editor) {
    this.context = ngElement(editor.getElement()).controller(directiveName);

    editor.addButton(reportPreviewButtonId, {
      text: "Preview",
      tooltip: "Show report preview for exam",
      onclick: () => {
        this.$scope.$apply(() => {
          this.showExamPrompt().then(exam => this.showPreview(exam));
        });
      }
    });
  }

  configure(options: tinymce.Settings) {
    const toolbar = ToolbarAttributes.FromAttributes(this.$attrs);
    this.extendToolbar(toolbar.id, reportPreviewButtonId, options);
  }

  showPreview(exam: Exam) {
    return this.$mdDialog.show(
      this.$mdDialog.alert()
          .ok("Close")
          .title("Report Preview")
          .clickOutsideToClose(true)
          .escapeToClose(true)
          .htmlContent(this.reportCompiler.compile(exam, this.ngModel.$modelValue)));
  }

  showExamPrompt(): angular.IPromise<Exam> {
    return this.$mdDialog
      .showStudyPrompt(this.context.examType)
      .then(id => this.models.Study.find({ id }, "exams.measurementValues.measurementType"))
      .then(study => study == null ? null : study.exam);
  }
}

export default ngModule("midas.utility.tinymce.reportPreviewPlugin", [
  businessModelsModule.name,
  reportCompilerModule.name
])
  .component("reportPreviewButton", {
    require: {
      ...tinymceConfigurationRequire,
      ngModel: '^'
    },
    controller: ReportPreviewController
  });