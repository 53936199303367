import { IPointLike, Vector2 } from "../../../utility/utils";
import RenderableShape, { shadowDefaultExtraThickness, shadowDefaultColour } from "./renderableShape";
import { coordExtent } from "../imageEditorVars";
import { Shape } from "createjs";

/** A filled circle which can draw itself to a canvas. */
export default class Circle extends RenderableShape {
    /** The handle to the graphical representation of this shape. */
    ink: Shape;
    /** The centre of the circle. */
    pos: Vector2;
    /** The radius of the circle. */
    radius: number;
    /** The colour to use to draw the circle. */
    colour: string;
    /** The colour to use to draw the shadow below the circle. */
    shadowColour: string = shadowDefaultColour;
    /** How much thicker the shadow should be compared to the shape. */
    shadowExtraThickness: number = shadowDefaultExtraThickness * coordExtent;
    /** The thickness of the line used to draw the circle. */
    thickness: number;
    /** The colour used to fill the circle. */
    backgroundColour: string;
  
    /** Renders a filled circle onto the provided graphics object.
     * @param ink The graphics object to render to.
     * @param pos The centre of the circle to render.
     * @param radius The radius of the circle to render.
     * @param colour The colour of the circle to render.
     * @param thickness The thickness of the line used to draw the circle.
     * @param fillColour The colour used to fill the circle.
     */
    static render(ink: Shape, pos: IPointLike, radius: number, colour: string, thickness: number = radius / 10, fillColour: string = colour): Shape {
      ink.graphics
        .setStrokeStyle(thickness)
        .beginStroke(colour)
        .beginFill(fillColour)
        .drawCircle(pos.x, pos.y, radius)
        .endFill()
        .endStroke();
      return ink;
    }
  
    /** Clears the graphics object and redraws this circle to it. Should be called whenever
     * changes are made to one of the properties which isn't yet reflected in the graphics object.
     */
    render(drawShadow: boolean = false, target: Shape = this.ink): Shape {
      target.graphics.clear();
      if (drawShadow) {
        return Circle.render(target, this.pos, this.radius, this.shadowColour, this.thickness + this.shadowExtraThickness, "transparent");
      }
      return Circle.render(target, this.pos, this.radius, this.colour, this.thickness, this.backgroundColour);
    }
  
    /** Checks hit detection against this circle. Takes into account both the draw
     * position and the ink transform.
     * @param p The point to hit test against this circle.
     */
    hitTest(p: IPointLike): boolean {
      if (this.ink.parent) {
        p = this.ink
            .getMatrix(RenderableShape.ScratchMatrix)
            .invert()
            .transformPoint(p.x, p.y, RenderableShape.ScratchPoint);
        return this.pos.equals(p, this.radius);
      }
      return false;
    }
  
    /** Bakes the current ink position into the points and re-renders. Essentially shifts any
     * offset from the ink position to the actual pixel positions. */
    bakeTransform(): void {
      this.$trace && this.$trace("Circle.bakeTransform() - only bakes position");
      this.pos.add(this.ink);
      this.ink.x = this.ink.y = 0;
    }
  
    /** Creates a new filled circle object, including a new graphics object.
     * @param pos The initial position of the circle.
     * @param radius The initial radius of the circle.
     * @param colour The initial colour of the circle.
     * @param thickness The thickness of the line used to draw the circle.
     * @param fillColour The colour used to fill the circle.
     */
    constructor(pos: IPointLike, radius: number, colour: string, thickness: number = radius / 10, fillColour: string = colour) {
      super();
      this.pos = new Vector2(pos);
      this.radius = radius;
      this.colour = colour;
      this.thickness = thickness;
      this.backgroundColour = fillColour;
      this.ink = new Shape();
      this.ink.name = "Circle";
      this.render();
    }
  }