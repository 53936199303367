import { IDisposable, Traceable, Disposable, ObservableCallback, createDisposable, IPointLike } from "../../../utility/utils";
import { IShape, ImageEditorController } from "../imageEditorCore";
import { ControlManager } from "../imageEditorControls";
import { HistoryManager } from "../historyManager";
import { InteractionEvents } from "../inputManager";

/** A class which provides a context for activated tools, with many common operations scoped
 * automatically to this object. Its lifetime is managed by the tool manager, so registrations
 * can be added to the dispose function and automatically cleaned up once the tool deactivates. */
export default class ToolActivationContext extends Traceable {
    /** Cleans up any resources and listener registrations made during the lifetime of this tool
     * activation context. */
    dispose: Disposable;

    /** A copy of the shape to being edited. Tools should call addOrUpdate() to write changes
     * back to the history list. */
    editModel: IShape;

    /** The control manager for this activation context. */
    controls: ControlManager;

    /** Gets the mouse cursor to display. */
    get cursor(): string { return this.ctrl.context.cursor; }
    /** Sets the mouse cursor to display. */
    set cursor(value: string) { this.ctrl.context.cursor = value; }

    /** Gets the scratch layer. */
    get scratchLayer() { return this.ctrl.context.scratch; }

    /** Updates the model managed by this activation context, or adds it to the history if there's
     * no current model. Also handles firing changed events. */
    public addOrUpdate(model: IShape): void {
      if (model == null || model.points == null || model.points.length === 0) {
        this.removeEditShape();
      } else if (this.$editModel) {
        //Update edit model in place to exactly match the provided one.
        for (var key of Object.getOwnPropertyNames(this.editModel)) {
          this.$editModel[key] = undefined;
        }
        for (var key of Object.getOwnPropertyNames(model)) {
          this.$editModel[key] = model[key];
        }
        this.$trace && this.$trace("Existing history item updated by tool.");
        this.$history.itemChanged(this.$editModel);
      } else {
        this.$history.items.push({ ...model });
        this.$trace && this.$trace("New history item added by tool.");
      }
    }

    /** Removes the current edit shape from the history list, if possible. If the model was removed,
     * then this.editModel is set to undefinedd. */
    public removeEditShape(): boolean {
      if (this.$editModel && this.$history.remove(this.$editModel)) {
        this.editModel = undefined;
        this.$trace && this.$trace("Existing history item removed by tool.");
        return true;
      }
      return false;
    }

    /** Tries to issue a select event for the first shape at the provided stage position.
     * @param position The stage position to pick a shape from.
     * @returns Whether a shape was hit and a select event issued. */
    selectShapeAt(position: IPointLike): boolean {
      const history = this.$history.items;
      const tools = this.ctrl.tools;
      for (var shape of history)
          if (tools.hitTest(shape, position)) {
              this.ctrl.input.select(shape);
              return true;
          }
        return false;
    }

    /** Updates the stage. */
    updateStage() {
      this.ctrl.context.update();
    }

    /** Signals the input system to send a cancel interaction event. Useful if the tool provides
     * custom controls for ending interactions. */
    cancelInteraction() {
      this.ctrl.input.cancelInteract();
    }

    /** Signals the input system to send an end interaction event. Useful if the tool provides
     * custom controls for ending interactions. */
    endInteraction() {
      this.ctrl.input.endInteract();
    }

    /** Signals the input system to send an accept event. Useful if the tool provides custom
     * controls for accepting changes. */
    accept() {
      this.ctrl.input.accept();
    }

    /** Signals the input system to send a delete event. Useful if the tool provides custom
     * controls for deletion. */
    delete() {
      this.ctrl.input.delete();
    }

    /** Signals the input system to send a deselect event. Useful if the tool provides custom
     * controls for deselecting items.
     * @param force Whether to force the event to be sent, even if we're not currently editing.
     * This can be useful to force tool reinitialisation. */
    deselect(force: boolean = false) {
      this.ctrl.input.deselect(force);
    }

    /** Signals the image editor to dispose the current tool activation, and activate again with
     * a fresh activation context. */
    reactivate() {
      this.ctrl.tools.reactivate();
    }

    isEditing(shape: IShape) {
      return shape === this.$editModel;
    }

    /** Observe the accept event, which fires when the user indicates they wish to accept
     * something. This event can be quite tool specific, and is generally only useful when
     * a tool requires more than one interaction while building up a shape. This registration
     * is automatically disposed with the tool activation context it is registered from.
     * @param event The name of the event to subscribe to.
     * @param callback Gets notified whenever the user asks to accept something.
     * @returns A disposable which stops this registration from receiving notifications.
     * This registration is automatically disposed with the tool activation context it is
     * registered from. */
    observe(event: "accept", callback: ObservableCallback<void>): IDisposable;
    /** Observe the deselect event, which fires when the user isn't interacting or panning and
     * zooming, and they indicate they wish to cancel an operation. Usually this means any current
     * edits or selections should be removed. This registration is automatically disposed with the
     * tool activation context it is registered from.
     * @param event The name of the event to subscribe to.
     * @param callback Gets notified whenever the user asks to deselect something.
     * @returns A disposable which stops this registration from receiving notifications. This registration is automatically
     * disposed with the tool activation context it is registered from. */
    observe(event: "deselect", callback: ObservableCallback<void>): IDisposable;
    /** Observe the delete event, which fires when the user indicates they wish to delete
     * something. This registration is automatically disposed with the tool activation context
     * it is registered from.
     * @param event The name of the event to subscribe to.
     * @param callback Gets notified whenever the user asks to delete something.
     * @returns A disposable which stops this registration from receiving notifications. */
    observe(event: "delete", callback: ObservableCallback<void>): IDisposable;
    /** Notifies when either "cancel-interact" or "end-interact" is notified, signifying that
     * an interaction has come to and end either successfully or unsuccessfully. */
    observe(event: "finished-interact", callback: ObservableCallback<void>): IDisposable;
    observe(event: InteractionEvents, callback: ObservableCallback<any>): IDisposable;
    observe(event: InteractionEvents, callback: ObservableCallback<any>): IDisposable {
      const self = this;
      const registration = this.ctrl.input.observe(event, function () {
        callback.apply(undefined, arguments);
        self.updateStage();
      });
      this.dispose.add(registration);
      return registration;
    }

    static $inject = ["imageEditor", "imageEditor.history", "editModel"];
    constructor(
      public readonly ctrl: ImageEditorController,
      private readonly $history: HistoryManager,
      private readonly $editModel: IShape
    ) {
        super();
        this.editModel = this.$editModel ? { ...this.$editModel } : this.$editModel;
        this.controls = this.ctrl.$injectorInstantiate(ControlManager);
        this.ctrl.debug.register("controlManager", this.controls);
        this.ctrl.debug.register("toolActivationContext", this);

        const input = this.ctrl.input;
        const stageToUser = this.ctrl.context.stageToUser.bind(this.ctrl.context);

        this.dispose = createDisposable(
            this.controls.dispose,
            () => {
                this.ctrl.debug.remove("controlManager");
                this.ctrl.debug.remove("toolActivationContext");
                this.updateStage();
                this.scratchLayer.removeAllChildren();
                this.cursor = null;
            },
            //Remove these event subscriptions on dispose.
            input.observe("start-interact", p => {
                const userPoint = stageToUser(p);
                const control = this.controls.findControlAt(userPoint);
                if (control) {
                    this.controls.safeInteractStart(control, userPoint);
                }
            }),
            input.observe("interact", p => this.controls.safeInteract(stageToUser(p))),
            input.observe("end-interact", () => this.controls.safeInteractEnd()),
            input.observe("cancel-interact", () => this.controls.safeInteractCancel()));
        this.updateStage();
    }
}