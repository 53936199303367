import ToolActivationContext from "./toolActivationContext";
import {
    ImageEditorController
} from "../imageEditorCore";
import {
    IDirectiveFactory
} from "angular";

export default <IDirectiveFactory>( () =>
    ({
        require: "^mdsImageEditor",
        restrict: "E",

        link(_scope, _element, _attr, ctrl: ImageEditorController) {
            ctrl.tools.registerTool("select", {
                id: "select",
                activate(context: ToolActivationContext) {
                    context.controls.createInteractControl().subscribe(p =>
                        context.selectShapeAt(p)
                    );
                }
            });
        }
    }));