import {  module as ngModule, IPromise, IScope, IQService, ILogService, IRootScopeService } from "angular";
import { IStudyTitle } from "../../midas/studies/StudyTitle";
import { Study, Institute, BusinessModelService } from "../../businessModels";
import ReferenceService from "../../midas/reference/referencesService";
import { InstituteSettingsMetadataService } from "../../config/instituteSettingsMetadata";
import { PromptLoseChangesAndNavigateService } from "../../midas/studies/promptLoseChangesAndNavigate";
import { Disposable } from "../../utility/utils";
import reportFormatterTemplateModule, {
  ReportFormatterTemplateService
} from "../../utility/reportFormatter/reportFormatterTemplate.service";
import { IStateService } from "angular-ui-router";
import { LoadingStatus } from "../../utility/loadingStatus";
import glossaryMenuModule from "../../utility/tinymce/plugins/glossary-plugin";
import reportModeModule from "../../utility/tinymce/plugins/report-preview-plugin";
import reportContextModule from "../../utility/reportFormatter/blueprintContext.directive";
import layoutModule from "../../utility/layout/layout";
import "tinymce/plugins/paste";
import "tinymce/plugins/autoresize";
import blueprintMeasurementModule from "../../utility/tinymce/plugins/blueprint-measurement-plugin";
import autoFocusPlugin from '../../utility/tinymce/plugins/auto-focus-plugin';
import pastePlugin from '../../utility/tinymce/plugins/paste-plugin';
import instituteSettingsPlugin from '../../utility/tinymce/plugins/institute-settings-plugin';
import fixedToolbarPlugin from '../../utility/tinymce/plugins/fixed-toolbar-plugin';
import { Throttle, createDisposable } from '../../utility/utils';
import pushService, { PushService } from '../../utility/push/push.service';
import reportCongruenceModule from "./reportCongruence";
import ReportService from "./reportService";

/** Report tab. */
class ReportsController {

    /** The bound exam report text. */
    reportText: string;

    /** Indicates if the report has been changed. */
    dirty: boolean;

    /** Function to dispose "Prompt save lost changes" subscription.  */
    subscribeToLostChangesDispose: () => void;

    /** Disposable to remove subscription to system-wide save change events. */
    subscribeToSaveStatusDispose: Disposable;

    /** Function to dispose save requests that occur from the Provisional and Finalise buttons. */
    subscribeToSaveRequestDispose: () => void;

    /** UI status for tracking the regeneration of the report. */
    regenStatus = new LoadingStatus();

    /**
     * UI status for tracking the 'cleaning' of the report.
     * Cleaning is when the dirty flag of an exam conclusion is removed.
     */
    cleanStatus = new LoadingStatus();

    /** Handle blueprint publishing subscription disposal. */
    stopListening : any;

    physician_confirmed: boolean;

    static $inject =
        ["study",
         "inst",
         "instituteSettingsMetadata",
         "title",
         "businessModels",
         "references",
         "promptLoseChangesAndNavigate",
         "reportFormatter",
         "$q",
         "$state",
         "$mdToast",
         "$scope",
         "$log",
         "pushService",
         "$rootScope",
         "reportService"];

    constructor(
        private readonly study: Study,
        private readonly inst: Institute,
        private readonly instituteSettings: InstituteSettingsMetadataService,
        private readonly title: IStudyTitle,
        private readonly models: BusinessModelService,
        private readonly referenceService: ReferenceService,
        private readonly promptLoseChangesAndNavigate: PromptLoseChangesAndNavigateService,
        private readonly reportFormatterService: ReportFormatterTemplateService,
        private readonly $q: IQService,
        private readonly $state: IStateService,
        private readonly $mdToast: angular.material.IToastService,
        private readonly $scope: IScope,
        private readonly $log: ILogService,
        private readonly pushService: PushService,
        private readonly $rootScope: IRootScopeService,
        private readonly reportService: ReportService) {
            /* The controller is not used as a component so we need to call init from constructor.
               Also we rely on the scope to destroy.
               TODO: Convert tab to component */
            this.$onInit();
            this.$scope.$on("$destroy", () => this.$onDestroy());
            this.$scope.$on("saveComplete", () => this.redirectToNextSection());
        }

    $onInit(): void {
      this.initButtons();
      this.subscribeToBlueprintUpdate();
      this.loadReport();
      this.displayDefaultReferenceSection();
      this.subscribeToLostChanges();
      this.subscribeToGlobalSaveEvents();
      this.subscribeToSaveRequest();
      this.physician_confirmed = this.reportService.getPhysicianConfirmed(this.study.id);
    }

    private confirmPhysician(): void {
      this.$rootScope.$broadcast("physicianConfirmed", { studyId: this.study.id });
      this.physician_confirmed = true;    
    }

    checkPhysicianYes(): void {
      this.confirmPhysician();
      this.study.physician = this.models.User.current.physician;
      this.models.save(() => this.study.physician = this.models.User.current.physician)
      .then(() => {
        this.$scope.$broadcast("showCongruence");
        this.$mdToast.showSimple("Study details updated.")})
      .catch(() => this.$mdToast.showSimple("There was an error updating the physician."))
    }

    checkPhysicianNo(): void {
      this.confirmPhysician();
    }
  
    /** Set title and buttons custom buttons for Report tab. */
    initButtons(): void {
        this.title.text = "Reports";
        this.title.acceptButton.loading();
        this.title.clearButtons();

        /* There is an issue where if this tab is loaded directly from URL, this button will not appear.
           This may be because the User entity has not yet loaded. */
        if (this.study.userCanEdit() && !this.models.User.current.hasRole('Read-Only Mode')) {
            this.title.addButton({
                id: "btnRegenerateReports",
                tooltip: "Regenerate from rules",
                icon: "fa fa-file-text-o",
                execute: () => this.regenerateReport(),
                confirmText: "Regenerate Reports?"
            });
        }
    }

    /**
     * Load exam conclusion from server. If there is no conclusion then generate one from the formatter.
     * @returns A promise that is resolved when the exam conclusion report text has been loaded and shown to the user.
     */
    loadReport(): IPromise<any> {
      this.title.acceptButton.loading();
      return this.models.Exam.find({ id: this.study.exam.id })
        .then(exam => exam.loadLatestConclusion())
        .then(conclusion => {
          if (conclusion == null) {
            return this.regenerateReport();
          } else {
            this.reportText = conclusion;
          }
        }).catch(() => {
          this.$mdToast.showSimple(
            "There was an error loading the report for this exam. Please reload the page and try again.");
        }).finally(() => {
          this.title.acceptButton.ready();
        });
    }

    /**
     * Creates a new exam conclusion from the text the user has entered.
     * After saving to the server, the system will optionally navigate to the next section.
     * @param redirectAllowed if the system should navigate after successfully saving the conclusion.
     * @param toState the system navigation state to go to.
     * @param toParams the system navigation state parameters.
     * @returns A promise that will be resolved when the conclusion has been saved to the server.
     */
    saveRequested(redirectAllowed: boolean = true, toState?: string, toParams?: string): IPromise<void> {
        this.title.acceptButton.loading();
        return this.study.exam.addConclusion(this.reportText)
        .then(() => {
             this.dirty = false;
             if (redirectAllowed) {
                 this.redirectToNextSection(toState, toParams);
             }
         }).catch(() => {
             this.$mdToast.showSimple("There was an issue saving this report. Please press save and try again.");
         }).finally(() => {
             this.title.acceptButton.ready();
         });
    }

    /** Load the reference area section that has been configured to show alongside this tab. */
    displayDefaultReferenceSection(): void {
        const id = this.study.exam ? this.study.exam.id : null;
        const refGroup =
            this.inst.getSetting("ReportTabDefaultReferenceSection") ||
            this.instituteSettings.ReportTabDefaultReferenceSection.defaultValue;
      if (refGroup === "Home") 
        this.referenceService.reset(id)
      else this.referenceService.select(refGroup, id);
    }

    /** Subscribe to the service that notifies the user when changes will be lost by navigation. */
    subscribeToLostChanges(): void {
        if (this.subscribeToLostChangesDispose) {
            this.subscribeToLostChangesDispose();
        }
        this.subscribeToLostChangesDispose =
            this.promptLoseChangesAndNavigate.subscribe({
                isDirty: () => this.dirty,
                save: (state, params) => this.saveRequested(true, state, params)
            });
    }

    /**
     * Subscribe to any save events, so the accept button can be disabled.
     * This prevents multiple save requests to be sent at once.
     */
    subscribeToGlobalSaveEvents(): void {
        // TODO: This should be something the title object should handle generally perhaps.
        if (this.subscribeToSaveStatusDispose) {
            this.subscribeToSaveStatusDispose();
        }
        this.subscribeToSaveStatusDispose =
            this.models.save.status.onChanged(status => this.title.acceptButton.isBusy = status.isLoading);
    }

    /**
     * Subscribe to the scope event that indicates a save is requested.
     * These events come from buttons found on the study summary toolbar,
     * such as Provisional and Finalise.
     */
    subscribeToSaveRequest(): void {
        if (this.subscribeToSaveRequestDispose) {
            this.subscribeToSaveRequestDispose();
        }
        this.subscribeToSaveRequestDispose =
            this.$scope.$on("saveRequested", (e, args) => {
                e.preventDefault();
                this.saveRequested(false) // Do not navigate. The event broadcaster will handle that.
                    .then(() => this.$scope.$emit("saveComplete"));
            });
    }

    /** Mark as dirty when the report editor notifies of a change. */
    onReportChange(): void {
        this.dirty = true;
    }

    /**
     * Use the formatter service to generate a new report and save the result to server.
     * @returns A promise that will resolve when the generated report has been saved to server.
     */
    regenerateReport(): IPromise<any> {
        return this.reportFormatterService
            .getCompiledReport(this.study.exam)
            .then(content => {
              this.reportText = content;
              this.dirty = true;
            })
            .then(() => this.setClean())
            .catch((error) => {
              this.$mdToast.showSimple(
                "There was a problem regenerating the report. Please reload and try again.");
              this.$log.error(error);
            });
    }

    /**
     * Clear the dirty flag from the latest conclusion so it will not prompt the user to regenerate.
     * @returns A promise that resolves when the dirty flag has been cleared and saved to the server.
     */
    setClean(): IPromise<void> {
      return this.cleanStatus.track(
        this.models.save(() => this.study.exam.isDirty = false)
      ).promise;
    }

    /**
     * Redirect to next section, given provided parameters or configured defaults.
     * @param toState Optional state to navigate to.
     * @param toParams Optional state parameters.
     * @returns A promise that is resolved when the navigation is complete.
     */
    redirectToNextSection(toState?: string, toParams?: any): IPromise<void> {
        if (!toState) {
            const defaultTab =
                this.inst.getSetting("AutomaticallyNavigateWhenReportSaves") ||
                this.instituteSettings.AutomaticallyNavigateWhenReportSaves.defaultValue;
            if (!defaultTab) {
                return this.$q.when();
            }
            toParams = {};
            switch (defaultTab) {
                case "Details":
                    toState = "midas.studies.view.details";
                    break;
                case "Documents":
                    toState = "midas.studies.view.documents";
                    break;
                case "Images":
                    toState = "midas.studies.view.images";
                    break;
                case "Diagrams":
                    toState = "midas.studies.view.diagrams";
                    break;
                case "Notes":
                    toState = "midas.studies.view.notes";
                    break;
                case "Preview":
                    toState = "midas.studies.view.preview";
                    break;
                default:
                    return this.$q.when();
            }
            /* Handle full screen layout params.
               TODO: Add a service to handle navigation because this is annoying. */
            const requireLayoutParams = this.$state.params.layoutState && this.$state.params.layoutArgs;
            if (requireLayoutParams) {
                toState += ".layout";
                toParams.layoutState = this.$state.params.layoutState;
                toParams.layoutArgs = this.$state.params.layoutArgs;
            }
            toParams.studyId = this.study.id;
        }
        return this.$state.go(toState, toParams);
    }

    /** Cleanup listeners for subscriptions that would waste resources or create inconsistent behaviour. */
    $onDestroy(): void {
        if (this.subscribeToLostChangesDispose) {
            this.subscribeToLostChangesDispose();
        }
        if (this.subscribeToSaveStatusDispose) {
            this.subscribeToSaveStatusDispose();
        }
        if (this.subscribeToSaveRequestDispose) {
            this.subscribeToSaveRequestDispose();
        }
        createDisposable(this.stopListening);
    }

    // Clean the report template's cache when the server indicates there is a new blueprint published.
    subscribeToBlueprintUpdate(): void {
      const refreshTrottle = new Throttle<number>(2500);
      refreshTrottle.observe(reportFormatterId =>
        this.models.ReportFormatter.find({ id: reportFormatterId })
          .then(formatter => formatter.uncacheTemplate()));
      this.stopListening = this.pushService.onBlueprintPublished(
        args => refreshTrottle.onNext(args[0]));
    }
}

export default ngModule("midas.midas.studies.report", [
    layoutModule.name,
    reportFormatterTemplateModule.name,
    glossaryMenuModule.name,
    reportContextModule.name,
    reportModeModule.name,
    blueprintMeasurementModule.name,
    autoFocusPlugin.name,
    pastePlugin.name,
    instituteSettingsPlugin.name,
    fixedToolbarPlugin.name,
    pushService.name,
    reportCongruenceModule.name
]).service("reportService", ReportService)
  .config(["layoutStateProvider", (stateProvider: angular.ui.IStateProvider) =>
    stateProvider.state("midas.studies.view.reports", {
        url: "/reports",
        views: {
            "content@midas.studies.view": {
                templateUrl: require("./reports.html"),
                controller: ReportsController,
                controllerAs: "$ctrl"
            }
        }
    })
]);
