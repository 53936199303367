/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import * as angular from "angular";
import utilsModule, { Crypto, LocalStorage, Disposable } from "./utility/utils";
import { ObservableCallback, notify, observe } from "./utility/events/rawEvents";
import snapModule from "angular-snap";
import { IHttpService } from 'angular';

export interface IUserIdentity {
  isAuthenticated?: boolean;
  userName?: string;
  bearerToken?: string;
  expirationDate?: Date;
}

export interface IAuthService {
  isAuthenticated(): boolean;
  getUser(): IUserIdentity;
  logout(): void;
  onUserChanged(callback: ObservableCallback<IUserIdentity>): Disposable;
  authenticate(userName: string, institute: string, password: string, remember?: boolean): angular.IHttpPromise<IUserIdentity>;
}

export const serviceName = "authService";

export default angular.module("midas.auth", [utilsModule.name, snapModule])
  .service(serviceName, ["$http", "crypto", "localStorage", "snapRemote",
   function($http: IHttpService, crypto: Crypto, localStorage: LocalStorage, snapRemote) {

    let userCache: IUserIdentity = null;
    const userChangedObservers: ObservableCallback<IUserIdentity>[] = [];

    const setUserCache = function(data, remember?: boolean) {
      if (!userCache) {
        userCache = {};
      }
      userCache.isAuthenticated = true;
      userCache.userName = data.userName;
      userCache.bearerToken = data.access_token;
      userCache.expirationDate = new Date(data[".expires"]);
      if (remember) {
        localStorage.set("auth_data", userCache);
      }
      notify(userChangedObservers, userCache);
    };

    const retrieveUserCache = function() {
      userCache = localStorage.get("auth_data");
      if (userCache) {
        setAuthHeader(userCache.bearerToken);
        notify(userChangedObservers, userCache);
      }
    };

    const clearUserCache = function() {
      userCache = null;
      notify(userChangedObservers, userCache);
    };

    const clearCookie = () => localStorage.remove("auth_data");

    const setAuthHeader = token => $http.defaults.headers.common.Authorization = `Bearer ${token}`;

    const clearAuthHeader = () => $http.defaults.headers.common.Authorization = null;

    const isExpired = function(expiry) {
      const now = new Date();
      const expirationDate = new Date(expiry);
      return (expirationDate.valueOf() - now.valueOf()) < 0;
    };


    retrieveUserCache();

    const api = <IAuthService> {
      isAuthenticated() {
        if (!userCache) {
          retrieveUserCache();
        }

        return userCache && userCache.isAuthenticated && !isExpired(userCache.expirationDate);
      },

      getUser() { return userCache; },

      logout() {
        clearCookie();
        clearUserCache();
        clearAuthHeader();
        snapRemote.close();
      },

      //Registers a callback for notification of a changed user. Returns a deregistration function.
      onUserChanged(callback: ObservableCallback<IUserIdentity>) {
        const dispose = observe(userChangedObservers, callback);
        callback(userCache);
        return dispose;
      },

      authenticate(userName: string, institute: string, password: string, remember?: boolean) {
        const joinedUserName = `${userName}#${institute}`;
        const encryptedPassword = encodeURIComponent(crypto.sha1(password, true));
        const parameters = {
          method: "POST",
          url: "token",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded"
          },
          data: `grant_type=password&username=${joinedUserName}&password=${encryptedPassword}`
        };

        return $http(parameters)
          .success(function(data) {
            setAuthHeader(data["access_token"]);
            setUserCache(data, remember);
            return userCache;
        });
      }
    };

    return api;
  }
]);
