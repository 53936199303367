import { coordExtent, defaultCanvasWidth, DefaultDotsPerCentimetre } from "./imageEditorVars";
import { Vector2 } from "../../utility/utils";
import * as angular from "angular";
import { Graphics, Matrix2D } from "createjs";
import * as graniteUri from "./fill/granitepat.png"; //Will be inlined as data-uri if less than 8K.

type Colour = string;

/** A pattern which can be drawn to a canvas. */
interface IPattern {
  /** The name of the pattern as retrieved from the service. */
  name: string;
  /** The image or canvas element containing the pixel data. */
  pixelData: HTMLCanvasElement | HTMLImageElement;
  /** The optimal display width of the image in centimetres, irrespective of the number of pixels. */
  width: number;
  /** The optimal display height of the image in centimetres, irrespective of the number of pixels. */
  height: number;
}

/** A class which defines a canvas based pattern which can be tiled onto other canvases. It
 * contains functions for generating various patterns. */
class CanvasPattern implements IPattern {
  pixelData: HTMLCanvasElement;
  /** The width of the canvas pattern in cm. */
  width: number;
  /** The height of the canvas pattern in cm. */
  height: number;
  constructor(public name: string, widthCm: number, heightCm: number, dotsPerCm: number) {
    const canvas = this.pixelData = document.createElement('canvas');
    this.width = widthCm;
    this.height = heightCm;
    canvas.width = Math.max(1, widthCm * dotsPerCm);
    canvas.height = Math.max(1, heightCm * dotsPerCm);
  }

  /** Fills the entire canvas with the provided colour.
    * @param colour The colour to fill.
    * @param alpha If provided, the global alpha is set to this for the fill op.
    */
  fill(colour?: string, alpha?: number): this {
    const ctx = this.pixelData.getContext('2d');
    const oldFillStyle = ctx.fillStyle;
    const oldGlobalAlpha = ctx.globalAlpha;
    try {
      ctx.beginPath();
      ctx.rect(0, 0, this.pixelData.width, this.pixelData.height);
      ctx.fillStyle = colour;
      if (alpha != null) {
        ctx.globalAlpha = alpha;
      }
      ctx.fill();
    } finally {
      ctx.globalAlpha = oldGlobalAlpha;
      ctx.fillStyle = oldFillStyle;
    }
    return this;
  }

  /** Draws a line from and to normalised coordinates.
    * @param x Normalised start x value.
    * @param y Normalised start y value.
    * @param xEnd Normalised end x value.
    * @param yEnd Normalised end y value.
    * @param thickness Line thickness, normalised against largest of the canvas dimensions.
    * @param colour The colour of the line.
    */
  drawNormalisedLine(
    x: number, y: number,
    xEnd: number, yEnd: number,
    thickness: number, colour: string): this {
    const ctx = this.pixelData.getContext('2d');
    const w = this.pixelData.width;
    const h = this.pixelData.height;
    ctx.beginPath();
    ctx.moveTo(w * x, h * y);
    ctx.lineTo(w * xEnd, h * yEnd);
    ctx.lineWidth = thickness * (w > h ? w : h);
    ctx.strokeStyle = colour;
    ctx.stroke();
    return this;
  }

  /** Draws a filled rectangle using normalised coordinates.
    * @param x Normalised top left corner.
    * @param y Normalised top left corner.
    * @param width Normalised width.
    * @param height Normalised height.
    * @param colour The fill colour.
    */
  drawNormalisedFilledRect(
    x: number, y: number,
    width: number, height: number,
    colour: string): this {
    const ctx = this.pixelData.getContext('2d');
    const w = this.pixelData.width;
    const h = this.pixelData.height;
    ctx.beginPath();
    ctx.rect(x * w, y * h, width * w, height * h);
    ctx.fillStyle = colour;
    ctx.fill();
    return this;
  }

  /** Draws a filled circle using normalised coordinates, where the radius is normalised
   * against the longest dimension.
    * @param x Normalised centre.
    * @param y Normalised centre.
    * @param radius Radius, normalised against the longest edge.
    * @param colour The fill colour.
    */
  drawNormalisedFilledCircle(
    x: number, y: number,
    radius: number,
    colour: string): this {
    return this.drawNormalisedFilledElipse(x, y, radius, radius, colour);
  }

  /** Draws a filled ellipse using normalised coordinates, where each radius is normalised
   * against it's respective dimension.
    * @param x Normalised centre.
    * @param y Normalised centre.
    * @param xRadius Normalised radius in the x axis.
    * @param yRadius Normalised radius in the y axis.
    * @param colour The fill colour.
    */
  drawNormalisedFilledElipse(
    x: number, y: number,
    xRadius: number, yRadius: number,
    colour: string): this {
    const ctx = this.pixelData.getContext('2d');
    const w = this.pixelData.width;
    const h = this.pixelData.height;
    ctx.save();
    ctx.translate(x * w, y * h);
    ctx.scale(xRadius * w, yRadius * h);
    ctx.beginPath();
    ctx.arc(0, 0, 1, 0, 2 * Math.PI);
    ctx.restore();
    ctx.fillStyle = colour;
    ctx.fill();
    return this;
  }

  /** Draws the percent fill using circles (pixels) of varies densities.
   * @param pixelRadius The normalised radius of each 'pixel' in the percent fill.
   * @param colour The primary colour of the fill.
   */
  drawPercentFill(pixelRadius: number, colour: string) {
    let xRadius = pixelRadius, yRadius = pixelRadius;
    if (this.width < this.height) {
      xRadius *= this.height / this.width;
    } else if (this.width > this.height) {
      yRadius *= this.width / this.height;
    }
    return this
      .drawNormalisedFilledElipse(0.5, 0.5, xRadius, yRadius, colour)
      .drawNormalisedFilledElipse(0, 0, xRadius, yRadius, colour)
      .drawNormalisedFilledElipse(1, 0, xRadius, yRadius, colour)
      .drawNormalisedFilledElipse(0, 1, xRadius, yRadius, colour)
      .drawNormalisedFilledElipse(1, 1, xRadius, yRadius, colour);
  }
}

export class PatternService {
  /** The desired pixel quality for generated patterns, in pixels per centimetres. */
  dotsPerCm = DefaultDotsPerCentimetre; //118dpcm = 300 dpi.
  /** The width of the page this pattern is being rendered on, in centimetres. */
  pageWidthCm = defaultCanvasWidth; //A4 paper size.
  /** The extent of the coordinate system this pattern will be rendered on. */
  coordExtent = coordExtent;

  static imageUrls: {
    /** Gets image pattern information for a particular image.
     * @param key The name of the image to use. */
    [key: string]: {
      /** The URI to the image to use as a pattern. */
      uri: string;
      /** The most appropriate width of the pattern, in centimetres, irrespective of pixel size. */
      width: number;
      /** The most appropriate height of the pattern, in centimetres, irrespective of pixel size. */
      height: number;
    }
  } = {
    "GraniteFill": { uri: graniteUri, width: 2.5, height: 2.5 }
  };

  static $inject = ["$q"];
  constructor($q: angular.IQService) {
    this.init = () => {
      const promises: angular.IPromise<void>[] = [];
      for (const key in PatternService.imageUrls) {
        const value = PatternService.imageUrls[key];
        const deferred: angular.IDeferred<void> = $q.defer<void>();
        promises.push(deferred.promise);
        const image = new Image();
        image.onerror = (ev: ErrorEvent) => deferred.reject({ patternName: key, error: ev.error });
        image.onload = () => {
          PatternService.images[key] = () => ({
            name: key,
            pixelData: image,
            width: value.width,
            height: value.height
          });
          deferred.resolve();
        };
        image.src = value.uri;
      }
      return $q.all(promises);
    }
  }

  /** Initialises the pattern service by preloading any required images. */
  init: () => angular.IPromise<void[]>;

  /** Gets a pattern for use as a background on a canvas. */
  getPattern(key: string, primary: Colour, secondary: Colour, dotsPerCm = this.dotsPerCm): IPattern {
    const patternFn = this.patternFns[key];
    if (patternFn) {
      const pattern = patternFn(primary, secondary, dotsPerCm);
      if ((<HTMLCanvasElement>pattern).tagName) {
        return {
          name: key,
          pixelData: <HTMLCanvasElement>pattern,
          width: 0.3,
          height: 0.3
        };
      }
      return <IPattern>pattern;
    }
    const imageFn = PatternService.images[key];
    if (imageFn) {
      return imageFn();
    }
    return null;
  };

  /** Begins a pattern fill operation on the provided graphics object.
   * @param graphics The graphics object to configure.
   * @param key The key of the pattern to use for the fill.
   * @param primary The primary colour of the pattern.
   * @param secondary The secondary colour of the pattern.
   * @param dotsPerCm The desired pixel density of the pattern.
   * @param pageWidthCm The width of the page the pattern is being rendered on. This will be
   * used to normalise the size of the pattern to the desired size.
   * @param coordExtent The extent of the coordinate system this pattern is being rendered on.
   * Defaults to the coordinate extent of the image editor.
   */
  beginFill(graphics: Graphics, key: string, primary: Colour, secondary: Colour, dotsPerCm = this.dotsPerCm, pageWidthCm: number = this.pageWidthCm, coordExtent = this.coordExtent): void {
    if (key) {
      const pattern = this.getPattern(key, primary, secondary);
      if (pattern) {
        const scale = ((pattern.width / pageWidthCm) / pattern.pixelData.width) * coordExtent;
        graphics.beginBitmapFill(
          pattern.pixelData,
          "repeat",
          new Matrix2D().scale(scale, scale));
      } else {
        console.log(`No pattern named '${key}' in pattern service`);
      }
    } else if (secondary) {
      graphics.beginFill(secondary);
    }
  }

  private drawPixels(ctx: CanvasRenderingContext2D, colour: Colour, rows: number[][]): void {
    let columnIndex = 0;
    let rowIndex = 0;
    while (rowIndex < rows.length) {
      const columns = rows[rowIndex];
      while (columnIndex < columns.length) {
        const bit = columns[columnIndex];
        if (bit === 1) {
          ctx.beginPath();
          ctx.rect(rowIndex, columnIndex, 1, 1);
          ctx.fillStyle = colour;
          ctx.fill();
        }
        columnIndex = columnIndex + 1;
      }
      columnIndex = 0;
      rowIndex = rowIndex + 1;
    }
  };

  private static images: {
    [patternKey: string]: () => IPattern;
  } = {};

  private patternFns: {
    [patternKey: string]: (primary: Colour, secodary: Colour, dotsPerCm?: number) => HTMLCanvasElement | IPattern;
    } = {
    "Horizontal": (primary: Colour, secondary: Colour, dotsPerCm: number = this.dotsPerCm) =>
      new CanvasPattern("Horizontal", 0.3, 0.3, dotsPerCm)
        .fill(secondary)
        .drawNormalisedLine(0, 0.5,  1, 0.5,  .1,  primary),
    "Vertical": (primary: Colour, secondary: Colour, dotsPerCm: number = this.dotsPerCm) =>
      new CanvasPattern("Vertical", 0.3, 0.3, dotsPerCm)
        .fill(secondary)
        .drawNormalisedLine(0.5, 0,  0.5, 1,  .1,  primary),
    "ForwardDiagonal": (primary: Colour, secondary: Colour, dotsPerCm: number = this.dotsPerCm) =>
      new CanvasPattern("ForwardDiagonal", 0.3, 0.3, dotsPerCm)
        .fill(secondary)
        .drawNormalisedLine(0, 0, 1, 1, .1, primary) //Main slash
        .drawNormalisedLine(-1, 0, 1, 2, .1, primary) //Edge of neighbour slash on this tile.
        .drawNormalisedLine(0, -1, 2, 1, .1, primary), //Edge of neighbour slash on this tile.
    "BackwardDiagonal": (primary: Colour, secondary: Colour, dotsPerCm: number = this.dotsPerCm) =>
      new CanvasPattern("BackwardDiagonal", 0.3, 0.3, dotsPerCm)
        .fill(secondary)
        .drawNormalisedLine(0, 1, 1, 0, .1, primary) //Main slash
        .drawNormalisedLine(-1, 1, 1, -1, .1, primary) //Edge of neighbour slash on this tile.
        .drawNormalisedLine(0, 2, 2, 0, .1, primary), //Edge of neighbour slash on this tile.
    "LargeGrid": (primary, secondary, dotsPerCm: number = this.dotsPerCm) =>
      new CanvasPattern("LargeGrid", 0.3, 0.3, dotsPerCm)
        .fill(secondary)
        .drawNormalisedLine(0, 0.5, 1, 0.5, .1, primary)
        .drawNormalisedLine(0.5, 0, 0.5, 1, .1, primary),
    "DiagonalCross": (primary, secondary, dotsPerCm: number = this.dotsPerCm) =>
      new CanvasPattern("DiagonalCross", 0.3, 0.3, dotsPerCm)
        .fill(secondary)
        .drawNormalisedLine(0, 0, 1, 1, .1, primary)
        .drawNormalisedLine(0, 1, 1, 0, .1, primary),

    "None": (primary, secondary, dotsPerCm: number = this.dotsPerCm) =>
      new CanvasPattern("None", 0.1, 0.1, dotsPerCm).fill(secondary),
    "Alpha25": (primary, secondary, dotsPerCm: number = this.dotsPerCm) =>
      new CanvasPattern("None", 0.1, 0.1, dotsPerCm).fill(secondary, 0.25),
    "Alpha50": (primary, secondary, dotsPerCm: number = this.dotsPerCm) =>
      new CanvasPattern("None", 0.1, 0.1, dotsPerCm).fill(secondary, 0.5),
    "Alpha75": (primary, secondary, dotsPerCm: number = this.dotsPerCm) =>
      new CanvasPattern("None", 0.1, 0.1, dotsPerCm).fill(secondary, 0.75),

    "Percent05": (primary: Colour, secondary: Colour, dotsPerCm: number = this.dotsPerCm) =>
      new CanvasPattern("Percent05", 0.4, 0.4, dotsPerCm)
        .fill(secondary)
        .drawPercentFill(0.1, primary),
    "Percent10": (primary: Colour, secondary: Colour, dotsPerCm: number = this.dotsPerCm) =>
      new CanvasPattern("Percent10", 0.4, 0.4 / 2, dotsPerCm)
        .fill(secondary)
        .drawPercentFill(0.1, primary),
    "Percent20": (primary: Colour, secondary: Colour, dotsPerCm: number = this.dotsPerCm) =>
      new CanvasPattern("Percent20", 0.4 / 2, 0.4 / 2, dotsPerCm)
        .fill(secondary)
        .drawPercentFill(0.1 * 2, primary),
    "Percent25": (primary: Colour, secondary: Colour, dotsPerCm: number = this.dotsPerCm) =>
      new CanvasPattern("Percent25", 0.4 / 2, 0.4 / 2.5, dotsPerCm)
        .fill(secondary)
        .drawPercentFill(0.1 * 2, primary),
    "Percent30": (primary: Colour, secondary: Colour, dotsPerCm: number = this.dotsPerCm) =>
      new CanvasPattern("Percent30", 0.4 / 2.5, 0.4 / 2.5, dotsPerCm)
        .fill(secondary)
        .drawPercentFill(0.1 * 2.5, primary),
    "Percent40": (primary: Colour, secondary: Colour, dotsPerCm: number = this.dotsPerCm) =>
      new CanvasPattern("Percent40", 0.4 / 2.5, 0.4 / 3, dotsPerCm)
        .fill(secondary)
        .drawPercentFill(0.1 * 2.5, primary),
    "Percent50": (primary: Colour, secondary: Colour, dotsPerCm: number = this.dotsPerCm) =>
      new CanvasPattern("Percent50", 0.4 / 3, 0.4 / 3, dotsPerCm)
        .fill(secondary)
        .drawPercentFill(0.1 * 3, primary),
    "Percent60": (primary: Colour, secondary: Colour, dotsPerCm: number = this.dotsPerCm) =>
      new CanvasPattern("Percent60", 0.4 / 2.5, 0.4 / 3, dotsPerCm)
        .fill(primary)
        .drawPercentFill(0.1 * 2.5, secondary),
    "Percent70": (primary: Colour, secondary: Colour, dotsPerCm: number = this.dotsPerCm) =>
      new CanvasPattern("Percent70", 0.4 / 2.5, 0.4 / 2.5, dotsPerCm)
        .fill(primary)
        .drawPercentFill(0.1 * 2.5, secondary),
    "Percent75": (primary: Colour, secondary: Colour, dotsPerCm: number = this.dotsPerCm) =>
      new CanvasPattern("Percent75", 0.4 / 2, 0.4 / 2.5, dotsPerCm)
        .fill(primary)
        .drawPercentFill(0.1 * 2, secondary),
    "Percent80": (primary: Colour, secondary: Colour, dotsPerCm: number = this.dotsPerCm) =>
      new CanvasPattern("Percent80", 0.4 / 2, 0.4 / 2, dotsPerCm)
        .fill(primary)
        .drawPercentFill(0.1 * 2, secondary),
    "Percent90": (primary: Colour, secondary: Colour, dotsPerCm: number = this.dotsPerCm) =>
      new CanvasPattern("Percent90", 0.4, 0.4 / 2, dotsPerCm)
        .fill(primary)
        .drawPercentFill(0.1, secondary),
    "SphereFill": (primary: Colour, secondary: Colour, dotsPerCm: number = this.dotsPerCm) => {
      const radius = new Vector2(1, 1).length() / 4;
      return new CanvasPattern("SphereFill", 0.3, 0.3, dotsPerCm)
        .fill(secondary)
        .drawNormalisedFilledCircle(0.5, 0.5, radius, primary)
        .drawNormalisedFilledElipse(0.4, 0.4, radius / 3, radius / 5, secondary)
        .drawNormalisedFilledCircle(0, 0, radius, primary)
        .drawNormalisedFilledCircle(1, 0, radius, primary)
        .drawNormalisedFilledCircle(0, 1, radius, primary)
        .drawNormalisedFilledCircle(1, 1, radius, primary)
        .drawNormalisedFilledElipse(0.9, 0.9, radius / 3, radius / 5, secondary);
    }
  };
}

interface IPatternDirectiveScope extends angular.IScope {
  mdsPattern: {
    pattern: string;
    primary: string;
    secondary?: string;
  };
}

const mdsPattern: angular.IDirectiveFactory = (patterns: PatternService) =>
  ({
    restrict: "A",
    scope: <{[key:string]:string}>{
      "mdsPattern": "="
    },
    link($scope: IPatternDirectiveScope, $element: angular.IAugmentedJQuery) {
      const canvas = <HTMLCanvasElement>$element[0];
      if (canvas.tagName.toLowerCase() !== "canvas") {
        throw new Error(`mdsPattern must be applied to a canvas element, not a ${canvas.tagName}`);
      }
      const context = canvas.getContext('2d');

      $scope.$watchCollection(s => s.mdsPattern, (state: IPatternDirectiveScope) => {
        context.clearRect(0, 0, $element.width(), $element.height());
        const pattern = patterns.getPattern(state.pattern, state.primary, state.secondary);
        if (!pattern) {
          throw new Error(`Cannot find pattern ${$scope.mdsPattern}`);
        }
        const img = context.createPattern(pattern.pixelData, "repeat");
        context.beginPath();
        context.rect(0, 0, $element.width(), $element.height());
        context.fillStyle = img;
        context.fill();
      });
    }
  });

export const PatternDirective = ["patternService", mdsPattern];