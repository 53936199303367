import ReferenceGroup from "./ReferenceGroup";
import { NoteService } from "../../studies/notes/notes";
import { IQService } from "angular";
import { Study } from "../../../businessModels";
import * as thumbnailUrl from "../templates/noteThumbnail.html";
import * as fullUrl from "../templates/noteFull.html";

export default class NoteGroup extends ReferenceGroup {
    constructor(public study: Study, private $q: IQService, private noteService: NoteService) {
        super("Notes", thumbnailUrl, fullUrl);

        this.iconClass = "fa fa-sticky-note";

        if (study.userCanEdit()) {
            this.addAction({
                text: "Add",
                iconClasses: "fa fa-plus",
                htmlIdSuffix: "AddNote",
                tooltip: "Add Note",
                execute: () => {
                    return noteService.promptAdd(this.study, "referencesActionAddNote", "referenceArea");
                }
            });
        }
    }

    loadThumbnails() {
        return this.$q.when(this.thumbnails = this.study.notes);
    }
}