import { ILogService, fromJson, toJson, IWindowService } from 'angular';

export default class LocalStorage {
    /** Whether local storage is supported by the current browser. */
    hasSupport: boolean;

    static $inject = ["$window", "$log"];
    constructor($window: IWindowService, $log: ILogService) {
        this.hasSupport = $window['localStorage'] !== null;

        if (!this.hasSupport) {
            $log.warn("LocalStorage not supported.");
        }
    }

    get(key: string) {
        if (this.hasSupport) {
            const str = localStorage.getItem(key);
            try {
                return fromJson(str);
            } catch (ex) {
                console.log("Error parsing local store JSON", ex);
            }
        }
        return undefined;
    }

    set(key: string, obj: any) {
        if (this.hasSupport) {
            const str = toJson(obj != null ? obj : null);
            localStorage.setItem(key, str);
        }
    }

    remove(key: string) {
        localStorage.removeItem(key);
    }

    clear() { localStorage.clear(); }

    list() { return Object.keys(localStorage); }
}