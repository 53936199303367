import { IController } from "angular";
import * as Raven from "raven-js";
import { User, Institute } from "../../businessModels";
import * as SentryConfig from "./sentry-config";

export interface IErrorNotificationService {
    notificationLevel: LogLevel;
    enabled: boolean;
    registerUser(user: User): void;
    log(message: string, level?: LogLevel, extra?: {}, exception?: Error): void;
    logError(message: string, exception?: Error, extra?: {}): void;
    logMessage(message: string, level?: LogLevel, extra?: {}): void;
}

export enum LogLevel {
    Critical = 4,
    Error = 3,
    Warning = 2,
    Info = 1,
    Debug = 0
}

export const errorsServiceName: string = "errors";

export interface ErrorNotificationService extends IController {}
export class ErrorNotificationService implements IErrorNotificationService  {
    notificationLevel: LogLevel = LogLevel.Error;

    public registerUser(user: User): void {
        if(!user) {
            this.clearSentryContext();
            this.registerInstitute(null);
            return;
        }
        Raven.setUserContext({
            id: user.id.toString(),
            username: user.userName,
            email: user.email
        });
        this.registerInstitute(user.institute);
    }

    private registerInstitute(institute: Institute): void {
        if(!institute) {
            return;
        }
        Raven.setTagsContext({
            institute: institute.key
        });
    }

    private clearSentryContext(): void {
        Raven.setUserContext();
        Raven.setTagsContext(null);
    }

    private _enabled:boolean = false;
    get enabled():boolean {
        return this._enabled;
    }
    set enabled(val:boolean) {
        this._enabled = val;
        if(val) {
            SentryConfig.enable();
        } else {
            SentryConfig.disable();
        }
    }

    logError(message: string, exception?: Error, extra?: any[], ): void {
        this.log(message, LogLevel.Error, extra, exception);
    }

    logMessage(message: string, level?: LogLevel, extra?: any[]): void {
        this.log(message, level, extra);
    }

    log(message: string, level?: LogLevel, extra?: any[], exception?: Error): void {
        level = level || LogLevel.Debug;
        if(!this.enabled || level < this.notificationLevel) {
            return;
        }

        const opt: Raven.RavenOptions = {
            level: this.toSentryLogLevel(level),
            extra: extra
        };

        if(exception) {
            opt.extra.message = message;
            Raven.captureException(exception, opt);
        } else {
            Raven.captureMessage(message, opt);
        }
    }

    private toSentryLogLevel(level: LogLevel): Raven.LogLevel {
        switch (level) {
            case LogLevel.Debug:
                return "debug";
            case LogLevel.Info:
                return "info";
            case LogLevel.Warning:
                return "warning";
            case LogLevel.Error:
                return "error";
            case LogLevel.Critical:
                return "critical";
            default:
                return "debug";
        }
    }
}