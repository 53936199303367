import { module as ngModule, IController } from "angular";
import filterModule from "./expression-editor.filters";
import { ExpressionOperatorType } from '../../../utility/reportFormatter/widgets/condition-expression.models';

interface OpSelectCtrl extends IController { }
class OpSelectCtrl implements IController {
  operator: ExpressionOperatorType;
  options: ExpressionOperatorType[];
  /** Whether to show the remove menu item, which returns null as the operator. */
  showRemove: boolean;
  operatorSelected: (args: { $op: ExpressionOperatorType }) => void;

  set(value: ExpressionOperatorType) {
    if (this.operatorSelected) {
      this.operatorSelected({ $op: value });
    }
  }
}

export default ngModule("midas.admin.blueprint.expression.expressionOpSelect", [ filterModule.name ])
.component("operatorSelect", {
  templateUrl: require("./operator-select-menu.component.html"),
  bindings: {
    operator: "<",
    options: "<",
    operatorSelected: "&",
    showRemove: "<"
  },
  controller: OpSelectCtrl
});