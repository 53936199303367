import { IImageEditorScope, IShape, ImageEditorController } from "../imageEditorCore";
import { RenderableShape } from "../shapes/shapes";
import { IMaybeTraceable, IPointLike, Traceable, Throttle, IDisposable } from "../../../utility/utils";
import ToolActivationContext from "./toolActivationContext";

/** Interface for tools wishing to register themselves to draw on the canvas. */
export interface ITool extends IMaybeTraceable {
    id: string;
    /** Called when a tool is being activated and should set up controls to handle interaction. */
    activate?(context?: ToolActivationContext): void;
    /** Renders an existing model which this tool can render, without any interaction. */
    render?(shape: IShape): createjs.DisplayObject | createjs.DisplayObject[];
    /** Checks whether this tool can edit a model. */
    isValid?(model: IShape): boolean;
    /** Checks whether a point is over a model according to this tool. */
    hitTest?(model: IShape, point: IPointLike): boolean;
}

export interface IToolScope extends IImageEditorScope {
    config: {
        thickness?: number;
        primaryColour?: string;
        secondaryColour?: string;
    }
}

/** A base class for most tools, providing lots of common functionality */
export abstract class Tool<TViewModel extends RenderableShape, TDataModel extends IShape> extends Traceable implements ITool {
    protected $viewModel: TViewModel;
    protected $isEditing: boolean = false;
    /** View model render throttle. This allows good performance when the model is changing very
     * quickly. */
    protected $renderThrottle = new Throttle<boolean>(10);
    /** Whether to render bounding boxes for shapes which support them. */
    renderBoundingBoxes: boolean = false;

    /** If set, this composite operation is assigned to shapes rendered by this tool. */
    compositeOp: string = undefined;

    /** Whether to show the selection shadow. Defaults to the value of $isEditing. */
    protected $showShadow() { return this.$isEditing; }

    /** The id of this tool. */
    id: string;

    constructor(
      public $ctrl: ImageEditorController,
      public $scope: IToolScope,
      public $shapeType: string) {
      super();
      this.$renderThrottle.observe(drawShadow => {
        const vm = this.$viewModel;
        if (vm) {
          const shadow = vm.shadowExtraThickness;
          let ink: createjs.DisplayObject;
          try {
            if (drawShadow) {
              vm.shadowExtraThickness /= this.$ctrl.context.user.scaleX;
            }
            ink = vm.render(drawShadow);
          } finally {
            vm.shadowExtraThickness = shadow;
          }
          if (this.renderBoundingBoxes) {
            vm.renderBounds(<createjs.Shape>ink); //This will deal fine with whatever it's given.
          }
          this.$ctrl.context.update();
        }
      });
    }

    /** Performs some base setup for activation. Call before overriding code. */
    activate(context: ToolActivationContext) {
      this.$trace && this.$trace(`${this.id}.activate()`);
      this.$isEditing = context.editModel != null;
      const updateShadowOnPanZoom = () => {
        if (this.$showShadow()) {
          this.update();
        }
      };
      context.observe("pan-zooming", updateShadowOnPanZoom);
      context.observe("finished-pan-zoom", updateShadowOnPanZoom);
      context.observe("zoom-or-reset", updateShadowOnPanZoom);
    }

    /** Allows tracing to be configured, and provides an additional option
     * "toolManager.tools.renderBoundingBoxes" for debugging bounding boxes by drawing when each
     * shape is rendered. */
    setTracing(options: Traceable.Options & { "toolManager.tools.renderBoundingBoxes"?: boolean }) {
      super.setTracing(options);
      const renderBoundingBoxes = options["toolManager.tools.renderBoundingBoxes"];
      if (renderBoundingBoxes != null) {
        this.renderBoundingBoxes = !!renderBoundingBoxes;
      }
    }

    private $acceptDeselectSubscription: () => void = null;
    /** Adds accept/deselect buttons and wires them up to send accept and deselect events via the
     * provided context. Only one set of buttons will ever be added at once by this function.
     * @param context The tool activation context to create buttons for.
     * @returns A function which removes the controls from the ui.
     */
    protected addAcceptDeselectButtons(context: ToolActivationContext): () => void {
      if (this.$acceptDeselectSubscription == null) {
        context.dispose.add(() => {
          const dispose = this.$acceptDeselectSubscription
          if (dispose) {
            this.$acceptDeselectSubscription = null;
            dispose();
          }
        });
        const control = context.controls.createYesNoControl();
        control.subscribe(
          () => context.accept(),
          () => context.deselect(true));

        this.$acceptDeselectSubscription = () => context.controls.remove(control);
      }
      return this.$acceptDeselectSubscription;
    }

    /** Checks whether the provided data model type matches the shape type of this tool. */
    isValid(dataModel: IShape): dataModel is TDataModel {
      return dataModel && dataModel.type === this.$shapeType;
    }

    /** Renders the provided data model to createjs rendering primitives by using the view model
     * generation functions of this tool.
     * @param dataModel The data model to render.
     * @param boundBoxes Whether to render bounding boxes. If set, this overrides the
     * this.renderBoundingBoxes setting.
     * @returns A display object or list of display objects representing the rendered version of the
     * provided data model. */
    render(dataModel: IShape, boundingBoxes?: boolean): createjs.DisplayObject | createjs.DisplayObject[] {
      if (this.isValid(dataModel)) {
        const vm = this.$createViewModel(dataModel);
        if (vm) {
          this.$trace && this.$trace(`${this.id}.render(${this.$traceFormatDataModel(dataModel)})`);
          this.$copyTraceSettingsTo(vm);
          const ink = vm.render();
          if (this.compositeOp != null) {
            ink.compositeOperation = this.compositeOp;
          }
          if (boundingBoxes != null ? boundingBoxes : this.renderBoundingBoxes) {
            vm.renderBounds(<any>ink);
          }
          return ink;
        }
      }
      return null;
    }

    /** Schedules the view model to re-render and the stage to update.
     * @param immediate If true the shape re-render is done immediately, but the stage update is
     * still scheduled via normal mechanisms.
     */
    update(immediate?: boolean) {
      if (immediate) {
        this.$renderThrottle.flush(this.$showShadow());
      } else {
        this.$renderThrottle.onNext(this.$showShadow());
      }
    }

    hitTest(dataModel: IShape, point: IPointLike): boolean {
      if (this.isValid(dataModel)) {
        const vm = this.$createViewModel(dataModel);
        if (vm) {
          const scratch = this.$ctrl.context.scratch;
          scratch.addChild(vm.ink);
          try {
            const hit = vm.hitTest(point);
            this.$trace && this.$trace(`${this.id}.hitTest(${this.$traceFormatDataModel(dataModel)}, ${this.$traceFormatPoint(point, 3)}) = ${hit}`);
            return hit;
          } finally {
            scratch.removeChild(vm.ink);
          }
        }
      }
      return false;
    }

    /** Functions for building pipelines for use with the watchConfig() functions. */
    protected $pipe = {
      /** Gets a function which converts a number with the provided units into stage coordinates.
      * @param units The units to get a converter function for. */
      unitToStage: (fromUnits: "px" | "cm" | "pt"): ((val: number) => number) => {
        switch (fromUnits) {
          case "px": return this.$ctrl.context.pixelsToStage.bind(this.$ctrl.context);
          case "cm": return this.$ctrl.context.cmToStage.bind(this.$ctrl.context);
          case "pt": return this.$ctrl.context.pointsToStage.bind(this.$ctrl.context);
          default: throw Error(`No conversion from ${fromUnits} to stage coordinates`);
        }
      },

      /** Gets a function which converts a number from stage coordinates into the provided units.
       * @param units The units to get a converter function for. */
      stageToUnit: (toUnits: "px" | "cm" | "pt"): ((val: number) => number) => {
        switch (toUnits) {
          case "px": return this.$ctrl.context.pixelsToStage.bind(this.$ctrl.context);
          case "cm": return this.$ctrl.context.cmToStage.bind(this.$ctrl.context);
          case "pt": return this.$ctrl.context.pointsToStage.bind(this.$ctrl.context);
          default: throw Error(`No conversion from ${toUnits} to stage coordinates`);
        }
      },

      /** Replaces null and undefined values with the first provided non-nullable value. */
      default: (...defaultValues: any[]) => {
        return value => {
          if (value == null) {
            for (var defaultValue of defaultValues) if (defaultValue != null) {
              return defaultValue;
            }
          }
          return value;
        };
      },

      /** Processes each item in a tuple with its own processor, returning the result of each. */
      processTuple: (...processors: ((value: any) => any)[]) =>
        (value: any[]) => {
          for (let i = 0, len = Math.min(value.length, processors.length); i < len; ++i) {
            if (typeof processors[i] === "function") {
              value[i] = processors[i](value[i]);
            }
          }
          return value;
        },

      /** Gets a setter function to assign a value into a property. */
      setViewModel: (property: keyof TViewModel): (value: any) => any => {
        return value => {
          const vm = this.$viewModel;
          if (vm != null) {
            const previous = vm[property];
            vm[property] = value;
            this.update();
            this.$trace && this.$trace(`${this.id}.${property} updated to ${typeof value === "string" ? '"' + value + '"' : value} from ${typeof previous === "string" ? '"' + previous + '"' : previous}`);
          }
          return value;
        };
      }
    };

    /** Watch a property in the configuration and runs a pipeline of functions on the result.
     * @param configProperty The property to watch on the config object.
     * @param processors The processors to run on each new value.
     */
    watchConfig(configProperty: keyof TDataModel, ...processors: ((value: any) => any)[]): IDisposable {
      this.$trace && this.$trace(`${this.id} tool watching 'config.${configProperty}'`);
      return this.$scope.$watch<any>(`config.${configProperty}`, value => {
        for (let processor of processors) {
          value = processor(value);
        }
      });
    }

    /** Watch a group of properties in the configuration, and use a function to update the view
     * model.
     * @param configProperty The property to watch on the config object.
     * @param updateModel A callback to update the model.
     * @returns A function for de-registering the watcher. */
    watchConfigGroup(configProperties: (keyof TDataModel)[], ...processors: ((value: any) => any)[]): IDisposable {
      this.$trace && this.$trace(`${this.id} tool watching 'config.[${configProperties.join(", ")}]'`);
      return this.$scope.$watchGroup(configProperties.map(p => "config." + p), (value: any[]) => {
        value = value.slice();
        for (let processor of processors) {
          value = processor(value);
        }
        this.$ctrl.context.update();
      });
    }

    /** Converts stage size/coordinates to pixels.
     * @param stageValue The value in stage coordinates to convert.
     * @returns The result in CSS pixels.
     */
    stageToPixels(stageValue: number) {
      return stageValue != null ? this.$ctrl.context.stageToPixels(stageValue) : stageValue;
    }

    /** Converts stage size/coordinates to points.
     * @param stageValue The value in stage coordinates to convert.
     * @returns The result in points.
     */
    stageToPoints(stageValue: number) {
      return stageValue != null ? this.$ctrl.context.stageToPoints(stageValue) : stageValue;
    }

    /** Converts stage size/coordinates to centimetres.
     * @param stageValue The value in stage coordinates to convert.
     * @returns The result in centimetres.
     */
    stageToCm(stageValue: number) {
      return stageValue != null ? this.$ctrl.context.stageToCm(stageValue) : stageValue;
    }

    /** Converts pixels to normalised stage size/coordinates.
     * @param pixels The value in pixels to convert.
     * @returns The result in normalised stage coordinates.
     */
    pixelsToStage(pixels: number) {
      return pixels != null ? this.$ctrl.context.pixelsToStage(pixels) : pixels;
    }

    /** Converts points to normalised stage size/coordinates.
     * @param points The value in points to convert.
     * @returns The result in normalised stage coordinates.
     */
    pointsToStage(points: number) {
      return points != null ? this.$ctrl.context.pointsToStage(points) : points;
    }

    /** Converts centimetres to normalised stage size/coordinates.
     * @param cm The value in centimetres to convert.
     * @returns The result in normalised stage coordinates.
     */
    cmToStage(cm: number) {
      return cm != null ? this.$ctrl.context.cmToStage(cm) : cm;
    }

    protected abstract $createViewModel(dataModel: TDataModel): TViewModel;

    /** Converts a point like object into a short string representation. */
    protected $traceFormatPoint(p: IPointLike, precision?: number): string {
      return p ? `{ ${p.x.toPrecision(precision)}, ${p.y.toPrecision(precision)} }` : "" + p
    }

    /** Converts a data model into a short string representation. */
    protected $traceFormatDataModel(dataModel: TDataModel): string {
      return dataModel ? `{ type: ${dataModel.type}, ... }` : "" + dataModel;
    }

    /** Copies this tool's trace settings to the provided maybe tracable, if it actually is
     * traceable. Used to enable identical tracing as this tool for shapes created by this
     * tool. */
    protected $copyTraceSettingsTo(traceable: IMaybeTraceable) {
      if (traceable && typeof traceable.setTracing === "function") {
        traceable.setTracing({ error: this.$error, warn: this.$warn, trace: this.$trace });
      }
    }
  }