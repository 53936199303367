import { module, IPromise } from "angular";
import { IStateService } from "angular-ui-router";
import { BusinessModelService, Study } from "../../../businessModels";
import "../../../../node_modules/nvd3/build/nv.d3.min.css";
import ExamTypeNameConfigurationService from "../../../admin/institute/examTypeNameConfigurationService";

declare var d3: any;

interface IDashboardUser {
  personId: number,
  userName: string
}

interface IChart {
  data: any[],
  options: { chart: any },
  api: any
}

interface AdminDashboardController extends angular.IController {}
class AdminDashboardController implements angular.IController {

  startDate: Date;
  endDate: Date;

  startDateChart1: Date;
  endDateChart1: Date;
  currentPersonId: number;

  startDateChart2: Date;
  endDateChart2: Date;

  startDateChart3: Date;
  endDateChart3: Date;

  chart1 = {} as IChart;
  chart2 = {} as IChart;
  chart3 = {} as IChart;

  users = [{ personId: -1, userName: "All Users" } as IDashboardUser];  
  studies = new Array<Study>();

  filteredByTypeStudies = new Array<Study>();
  titleTypeExtention: string;
  showStudyTypeTable: boolean = false;
  filteredByStatusStudies = new Array<Study>();  
  titleStatusExtention: string;
  showStudyStatusTable: boolean = false;
  
  static $inject = ["businessModels", "$timeout", "$state", "$window", "examTypeNameConfigurationService"];
  constructor( 
    private readonly models: BusinessModelService,
    private readonly $timeout: angular.ITimeoutService,
    private readonly $state: IStateService,
    private readonly $window: angular.IWindowService,
    private readonly examTypeNameService: ExamTypeNameConfigurationService
  ) { } 

  $onInit() {
    var self = this;

    self.chart1.data = [];
    self.chart2.data = [];
    self.chart3.data = [];

    self.chart1.options = {
      chart: {
        type: 'pieChart',
        height: 300,
        x: function(d){return d.key},
        y: function(d){return d.y},
        valueFormat: function(d){
            return d3.format(',f')(d);
        },
        showLabels: false,
        legend: {
          padding: 50
        },
        legendPosition: 'right',
        pie: {
          dispatch: {
            elementClick: function(e) {
              self.$timeout (function () {
                self.studiesByType(e.data.key);
              });                      
            }
          }
        },
        dispatch: {
          renderEnd: function(e) {
            d3.selectAll(".nv-legend text")[0].forEach(function(d){
              var t = d3.select(d).data()[0];
              d3.select(d).html(t.key + " " + t.y);
            });
          }
        }
      }
    };

    self.chart2.options = {
      chart: {
        type: 'pieChart',
        height: 300,
        x: function(d){return d.key},
        y: function(d){return d.y},
        valueFormat: function(d){
          return d3.format(',f')(d);
        },
        showLabels: false,
        legend: {
          padding: 50
        },
        legendPosition: 'right',
        pie: {
          dispatch: {
            elementClick: function(e) {
              self.$timeout (function () {
                self.studiesByStatus(e.data.key);
              });                      
            }
          }
        },
        dispatch: {
          renderEnd: function(e) {
            d3.selectAll(".nv-legend text")[0].forEach(function(d){
              var t = d3.select(d).data()[0];
              d3.select(d).html(t.key + " " + t.y);
            });
          }
        }
      }
    };

    self.chart3.options = {  
      chart: {   
        type: 'multiBarHorizontalChart',
        height: 150,
        x: function (d){return d.label},
        y: function (d){return d.value},
        showControls: false,
        stacked: false,
        margin: {
          left: 200
        },
        xAxis: {
          axisLabel: 'Study Type',
          rotateYLabel: false
        },
        yAxis: {
          axisLabel: 'Number of Studies',
          tickFormat: function(d){
            return d3.format('d')(d);
          }
        }
      }
    }

    self.startDate = new Date();
    self.startDate.setMonth(self.startDate.getMonth()-1);
    self.endDate = new Date();
    self.currentPersonId = -1;
    self.getUsers();    
    self.reload();
  }

  getUsers(): void {
    this.models.User.list()
    .then(results => {
      (results.map((u) => { 
        return { 
          personId: u.person.id,
          userName: u.firstName + " " + u.lastName
        } as IDashboardUser
      }) as Array<IDashboardUser>)
      .forEach(d => this.users.push(d));
    })
  }

  private setAllDates() {
    this.startDateChart1 = this.startDate;
    this.startDateChart2 = this.startDate;
    this.startDateChart3 = this.startDate;
    this.endDateChart1 = this.endDate;
    this.endDateChart2 = this.endDate;
    this.endDateChart3 = this.endDate;
  }

  private reload(): IPromise<void> {
    var self = this;
    return self.models.Study.list({ "isDeleted": false }, ["institute", "exams", "physician", "technician", "patient.person"])
    .then(results => {        
      self.studies = results;
      self.setAllDates();
      self.currentPersonId = -1;
      self.getChart1();
      self.getChart2();
      self.getChart3();
      self.titleTypeExtention = "";
      self.filteredByTypeStudies = [];
      self.showStudyTypeTable = false;
      self.titleStatusExtention = "";
      self.filteredByStatusStudies = [];
      self.showStudyStatusTable = false;
    })      
  }

  private getChart1(): void {
    var self = this;
    self.chart1.data = [];

    var arr1 = _.countBy(self.studies.filter(s => 
      s.modality != null &&
      s.exam != null && 
      s.exam.type != null &&
      s.exam.performedAt >= self.startDateChart1 && 
      s.exam.performedAt <= self.endDateChart1)
      .map((s) => { 
        return { examType: self.examTypeNameService.getCustomName(s.exam.type.key).examType }
      }), 'examType');

    var unknowns = self.studies.filter(s => 
      s.exam != null &&
      (s.modality == null || s.exam.type == null) &&
      s.exam.performedAt >= self.startDateChart1 && 
      s.exam.performedAt <= self.endDateChart1).length;

    Object.keys(arr1).forEach(function(key) {
      self.chart1.data.push({
        key: key,
        y: arr1[key]
      });
    });

    if (unknowns > 0) { self.chart1.data.push({ key: 'Unknown', y: unknowns })}

    this.titleTypeExtention = "";
    this.filteredByTypeStudies = [];
    this.showStudyTypeTable = false;
  }

  private getChart2(): void {
    var self = this;
    self.chart2.data = [];
    var arr2 = _.countBy(self.studies.filter(s => 
      s.exam != null && 
      s.exam.performedAt >= self.startDateChart2 && 
      s.exam.performedAt <= self.endDateChart2)
      .map((s) => { return { status: s.status.key }}), 'status');
    
    Object.keys(arr2).forEach(function(key) {
      self.chart2.data.push({
        key: key,
        y: arr2[key]
      });
    });
    this.titleStatusExtention = "";
    this.filteredByStatusStudies = [];
    this.showStudyStatusTable = false;
  }
  
  private getChart3(): void {
    var self = this;
    self.chart3.data = [];

    var phys = _.countBy(self.studies.filter( s => 
      s.modality != null &&
      s.exam != null && 
      s.exam.type != null && 
      s.exam.performedAt >= self.startDateChart3 && 
      s.exam.performedAt <= self.endDateChart3 && 
      s.physician != null && 
      (this.currentPersonId === -1 ? true : s.physician.personId === this.currentPersonId ))
      .map((s) => { 
        return { examType: self.examTypeNameService.getCustomName(s.exam.type.key).examType }
      }), 'examType');

    var tech = _.countBy(self.studies.filter( s => 
      s.modality != null &&
      s.exam != null && 
      s.exam.type != null && 
      s.exam.performedAt >= self.startDateChart3 && 
      s.exam.performedAt <= self.endDateChart3 &&  
      s.technician != null && 
      (this.currentPersonId === -1 ? true : s.technician.personId === this.currentPersonId ))
      .map((s) => { 
        return { examType: self.examTypeNameService.getCustomName(s.exam.type.key).examType }
      }), 'examType');

    var examTypes = [];
    Object.keys(phys).forEach(key => { examTypes.push(key)});
    Object.keys(tech).forEach(key => { examTypes.push(key)});
    var refinedExamTypes = _.uniq(_.sortBy(examTypes), true);

    if (refinedExamTypes.length > 0) {
      self.chart3.options.chart.height = 150 + refinedExamTypes.length * 40;
    } else {
      self.chart3.options.chart.height = 150;
    }

    var values1 = [];
    var values2 = [];
    refinedExamTypes.forEach(key => {
      values1.push({label: key, value: (phys[key] == null || phys[key] == undefined ? 0 : phys[key])});
      values2.push({label: key, value: (tech[key] == null || tech[key] == undefined ? 0 : tech[key])});
    });

    self.chart3.data.push({key: 'Physician', values: values1});
    self.chart3.data.push({key: 'Sonographer', values: values2});

    self.$timeout(function() {
      self.chart3.api.refresh();
    });
  }

  private studiesByType(type: string): void {
    var self = this;
    self.filteredByTypeStudies = self.studies.filter( s =>
      s.exam != null && 
      s.exam.performedAt >= self.startDateChart1 && 
      s.exam.performedAt <= self.endDateChart1 && 
      ((s.exam.type != null && self.examTypeNameService.getCustomName(s.exam.type.key).examType == type) || 
        s.exam.type == null && type == 'Unknown'));

    this.titleTypeExtention = type;
    this.showStudyTypeTable = true;
  }

  private studiesByStatus(status: string): void {
    this.filteredByStatusStudies = this.studies.filter( s => 
      s.exam != null && 
      s.exam.performedAt >= this.startDateChart2 && 
      s.exam.performedAt <= this.endDateChart2 && 
      s.status.key == status);
    this.titleStatusExtention = status;
    this.showStudyStatusTable = true;
  }

  goToStudy = (id: number, status: number) => {
    var href;
    if (status === 1) { href = "midas.studies.pending"} else { href = "midas.studies.view.details" }
    this.$window.open(this.$state.href ( href, { 
      studyId: id,
      inst: this.models.User.current.institute.key
    }), '_blank');
  };
}

export default module("midas.admin.adminDashboard", ['nvd3'])
  .component("adminDashboard", {
    controller: AdminDashboardController,
    templateUrl: require('./adminDashboard.html')
});