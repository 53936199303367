import { MeasurementController, IMeasurementScope } from "../MeasurementController";
import * as angular from "angular";
class DXBoneAgeController extends MeasurementController {
  static $inject = ["$scope"];
  constructor(scope: IMeasurementScope) {
    super(scope);
  }

  clickOK() {
    this.setMeasurementValue("DXBoneAgeConclusion", "Normal bone age. ");
    console.log("All normal clicked")
  }

}

angular.module("midas.utility.measurement.views").controller("DXBoneAgeCtrl", DXBoneAgeController);
