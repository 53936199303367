import {
  module as ngModule,
  IScope,
  IAttributes
} from "angular";
import { MeasurementType } from "../../../businessModels";
import { Editor, Settings, ui } from "tinymce";
import reportEditorModule, {
  TinymceConfigurationController,
  tinymceConfigurationRequire,
  ToolbarAttributes
} from "../mds-rich-text-editor.component";
import markerModule, {
  serviceName as markerServiceName,
  MarkerService
} from "../../reportFormatter/widgets/marker.service";
import planelModule, {
  serviceName as blueprintPanelsName,
  BlueprintPanelsService
} from '../../../admin/reportFormatters/dialogs/blueprint-panel.service';
import isEditableModule, {
  serviceName as isEditableServiceName,
  EditableService
} from './editable.service';

/** TinyMCE plugin to provide a button which puts a marker into the document that can later be
 * referenced. */
class InsertMarkerController extends TinymceConfigurationController {
  measurementTypes: MeasurementType[];
  panelId: string;

  static $inject = [
    "$scope",
    "$attrs",
    markerServiceName,
    isEditableServiceName,
    blueprintPanelsName
  ];
  constructor(
    private readonly $scope: IScope,
    private readonly $attrs: IAttributes,
    private readonly markerService: MarkerService,
    private readonly editable: EditableService,
    private readonly panels: BlueprintPanelsService) {
    super();
  }

  /** Add marker insert button to toolbar. */
  configure(options: Settings) {
    const toolbar = ToolbarAttributes.FromAttributes(this.$attrs);
    this.extendToolbar(toolbar.id, "insert-marker", options);
  }

  /** Define marker insert button and handle click. */
  setup(editor: Editor) {
    const me = this;
    editor.addButton("insert-marker", {
      tooltip: "Insert a marker into this report",
      text: "Insert marker",
      onPostRender() { me.disableOnInvalidSelection(editor, <any> this); },
      onclick: () => this.$scope.$apply(() => this.insert(editor))
    });
  }

  private disableOnInvalidSelection(editor: Editor, button: ui.Control): void {
    editor.on("NodeChange", () => {
      button.disabled(!this.editable.canEdit(editor.selection.getRng(true)));
    });
  }

  private insert(editor: Editor): void {
    const panel = this.panels.get(this.panelId);
    if (panel) {
      const widget = this.markerService.create();
      panel.show(widget).then(result => {
        if (result === "accept") {
          widget.insert(editor);
        }
      });
    }
  }
}

export default ngModule("midas.utility.tinymce.insert-marker", [
  reportEditorModule.name,
  planelModule.name,
  markerModule.name,
  isEditableModule.name
])
  .component("insertMarkerButton", {
    require: {
      ...tinymceConfigurationRequire
    },
    controller: InsertMarkerController,
    bindings: {
      panelId: "@"
    }
  });