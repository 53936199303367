// PDFJS is a special cookie because it actually loads in a separate iframe. None of the files
// imported from the /pdf folder here are actually included in the webpack bundle. Instead they're
// copied to the output directory by Webpack. Add or remove them here if they need to be updated.

// I'm not including any of the CMAP files because there's heaps of them, and they're only really
// used for East Asian fonts when the PDF was built by old technology. I don't think that's us at
// this point, so I really want to avoid including them.

import { module as ngModule } from "angular";

import "../pdf/build/pdf.js";
import "../pdf/build/pdf.worker.js";
import "../pdf/web/compatibility.js";
import "../pdf/web/l10n.js";
import * as viewerPath from "../pdf/web/viewer.html";
import "../pdf/web/viewer.css";
import "../pdf/web/viewer.js";
import "../pdf/midas-skin.css";
import "../pdf/web/locale/locale.properties";
// Include all english locales in the build.
const requireSkins = require["context"](
    '../pdf/web/locale', true, /\ben(?:-\w+)?[\\/]viewer.properties$/);
requireSkins.keys().forEach(requireSkins);
// Include all images in the build.
const requireImages = require["context"](
    '../pdf/web/images', true, /./);
    requireImages.keys().forEach(requireImages);

/** Exports a module which contains the getPdfViewerUrl service for getting a URL to open a file
 * in the PDFJS viewer. */
export default ngModule("midas.pdfjs", [])
    .constant("getPdfViewerUrl", (file: string) =>
        `${viewerPath}?file=../../../../${file}`);

/** A service for getting the URL to open a file in the PDFJS viewer. */
export type GetPdfViewerUrl = (file: string) => string;