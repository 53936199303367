/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import * as angular from "angular";
import "./tileControl.css";
import "Scripts/angular-ui/ui-bootstrap-tpls.js";


export default angular.module('midas.utility.tile-control', ["ui.bootstrap.buttons"])
  .directive("mdsTiles", [
    () =>
      ({
        restrict: "E",
        scope: true,
        transclude: true,
        templateUrl: require("./tileControl.html"),
        link(scope, element) {

          scope.hide =
            {buttons: true};

          element.addClass("tile-control");

          const children = angular.element(element[0].querySelectorAll(".tile-container > *"));
          scope.tileCount = children.length;

          let currentTileClass = null;
          scope.display = function(newTileClass) {
            if (currentTileClass != null) { children.removeClass(currentTileClass); }
            if (newTileClass != null) { children.addClass(newTileClass); }
            currentTileClass = newTileClass;
            scope.hide.buttons = true;
          };
          scope.display("single");

        }
      })
    
    ]);
