import {
  module as ngModule,
  IComponentController,
  IComponentOptions
} from "angular";
import serviceModule, { SessionSiteService, serviceName } from "./session-site.service";
import { Site, BusinessModelService } from "../../businessModels";
import { Disposable, createDisposable } from "../../utility/utils";

class SessionSiteComponentController implements IComponentController {
  selectedSite: Site;
  sites: Site[];
  dispose: Disposable;

  static $inject = ["businessModels", serviceName];
  constructor(
    private readonly models: BusinessModelService,
    private readonly siteService: SessionSiteService) { }

  $onInit() {
    this.sites = this.models.Institute.current.sites;
    this.selectedSite = this.siteService.getCurrentSite();
    this.dispose = createDisposable(
      this.siteService.on("change", (site) => this.selectedSite = site)
    );
  }

  $onDestroy() {
    this.dispose();
  }

  select(site: Site): void {
    this.siteService.select(site);
  }

  clear(): void {
    this.siteService.clear();
  }
}

const component: IComponentOptions = {
  controller: SessionSiteComponentController,
  templateUrl: require("./session-site.component.html")
};

export default ngModule("midas.users.site.sessionSiteComponent", [serviceModule.name])
  .component("sessionSite", component);