import { module as ngModule } from "angular";
import { Settings } from "tinymce";
import reportEditorModule, {
    TinymceConfigurationController,
    tinymceConfigurationRequire
} from "../mds-rich-text-editor.component";

/** Adds the [paste](https://www.tiny.cloud/docs/plugins/paste/) plugin to a tinymce instance, with
 * control over whether to merge formats on paste. */
class PastePlugin extends TinymceConfigurationController {
    /* From "paste" plugin: https://goo.gl/icJSG4
     * The default behaviour is to be smart and merge similar tags into one.
     * In blueprint expressions, this causes spans to be merged where the structure is actually
     * significant, so it needs to be disabled when editing blueprints. */
    mergeFormats?: boolean;

    configure(options: Settings) {
        this.extendPlugins(options, "paste");

        if (this.mergeFormats != null) {
            options["paste_merge_formats"] = this.mergeFormats;
        }
    }
}

export default ngModule("midas.utility.tinymce.paste-plugin", [reportEditorModule.name])
.component("pastePlugin", {
    require: {
        ...tinymceConfigurationRequire
    },
    controller: PastePlugin,
    bindings: {
        mergeFormats: "<?"
    }
});