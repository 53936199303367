import {
  module as ngModule, element as ngElement
} from "angular";
import { WidgetController, WidgetService, IWidgetCompilationContext } from "./widget";

export const serviceName = "reportMarker";
export const querySelector = "[data-marker]";

/** The most basic raw DOM string for creating report VARIABLES. */
const rawDom = "<span data-marker>";

/** A service to mark a section of the blueprint so that it can later be determined whether that
 * part was rendered. */
export class MarkerService extends WidgetService<MarkerController> {
  constructor() {
    super(querySelector);
  }

  /** Creates a new detached marker object. The result can be attached by access the $element
   * member. */
  create(): MarkerController {
    return new MarkerController(ngElement(rawDom));
  }

  /** Gets a controller object for the provided DOM. It is not checked first. */
  get(element: JQuery): MarkerController {
    return new MarkerController(element);
  }
}

/** A widget controller to store whether a marker was output in the report context, depending on the
 * surrounding conditions. */
export class MarkerController extends WidgetController {

  constructor(readonly $element: angular.IAugmentedJQuery) {
    super();
  }

  /** Gets the variable name text from the DOM. */
  get nameText(): string {
    return this.$element.text();
  }

  /** Sets the variable name text into the DOM. */
  set nameText(name: string) {
    this.$element.text(name);
  }

  /** Adds this marker to the context. */
  refresh(context: IWidgetCompilationContext) {
    context.markers.add(this.nameText);
  }

  /** Strips the entire node from the DOM as markers are purely compile time constructs. */
  strip() { this.remove(); }

  /** Gets whether this object was created with an appropriate element. */
  get isValid() {
    return this.$element != null && this.$element.length === 1 && this.$element.is(querySelector);
  }
}

export default ngModule("midas.blueprint.marker", []).service(serviceName, MarkerService);