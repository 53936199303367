import { ObservableCallback, notify, observe } from "./rawEvents";
import { ITimeoutService, IPromise, module as ngModule } from 'angular';

/** A class which receives events and forwards the most recently received one after some sample
 * period. similar to the Sample() operator from Rx, although this doesn't publish if no new
 * events have been received. */
export class Throttle<T> {
    static DefaultSamplePeriod = 15;
    static $timeout: ITimeoutService;
    private $observers: ObservableCallback<T>[] = [];
    private $last: T = undefined;
    private throttleRegistration: IPromise<void> = undefined;
    /** The amount of time to wait before publishing the most recent value. */
    samplePeriod: number;
    /** Whether to invoke the callbacks within an angular digest. */
    invokeApply: boolean;
    /** Gets whether an event is throttled and awaiting release. */
    get isPending() {
        return this.throttleRegistration != null;
    }

    /** Listen for throttled values published by this class.
     * @param callback Called when the throttle timeout completes, with the most recent argument
     * to this.onNext(). */
    observe(callback: ObservableCallback<T>) {
        return observe(this.$observers, callback);
    }

    /** Sends a value to be throttled.
     * @param value The value to throttle. The most recent argument to this function will be
     * published to all observers once the throttle timeout expires. */
    onNext(value: T): void {
        this.$last = value;
        if (this.throttleRegistration == null) {
            this.throttleRegistration = Throttle.$timeout(this.samplePeriod, this.invokeApply);
            this.throttleRegistration.then(() => {
                const value = this.$last;
                this.throttleRegistration = undefined;
                this.$last = undefined;
                notify(this.$observers, value);
            });
        }
    }

    /** Cancels any waiting throttle, without publishing any values. */
    cancel(): void {
        if (this.throttleRegistration) {
            Throttle.$timeout.cancel(this.throttleRegistration);
            this.throttleRegistration = undefined;
            this.$last = undefined;
        }
    }

    /** If a value is currently being throttled, immediately publish it, and cancel the associated
     * throttle timeout.
     * @return True if there was a value waiting to be published, false otherwise. */
    flush(): boolean;
    /** Sends a new value, and immediately publishes it, cancelling any existing throttle timeout.
     * @param value The value to send immediately.
     * @return True */
    flush(value: T): boolean;
    flush(value?: T): boolean {
        if (value !== undefined) {
            this.$last = value;
        } else {
            value = this.$last;
        }

        if (this.throttleRegistration) {
            this.cancel();
            notify(this.$observers, value);
            return true;
        } else if (value !== undefined) {
            notify(this.$observers, value);
            return true;
        }
        return false;
    }

    /** Creates a new object which throttles events.
     * @param samplePeriod The amount of time to wait after receiving an event before publishing
     * the most recent value.
     * @param invokeApply Whether to invoke the callbacks within an angular digest. */
    constructor(samplePeriod: number = Throttle.DefaultSamplePeriod, invokeApply = true) {
        this.samplePeriod = samplePeriod;
        this.invokeApply = invokeApply;
        if (Throttle.$timeout == null) {
            throw Error("Throttle.$timeout is not set. Ensure the 'midas.utility.events.throttle' module has been required.");
        }
    }
}

export default ngModule("midas.utility.events.throttle", [])
    .run(["$timeout", (timeout: ITimeoutService) => Throttle.$timeout = timeout])
    .value("throttle", Throttle);