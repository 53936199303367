import * as angular from "angular";
import { SearchExecutorProvider, ISearchTerm } from "./searchExecutorProviders";
import { createDisposable } from "../../utility/utils";
import { SearchTermProvider } from "./searchTermProviders";
import { Events } from "../events/events";
import { ObservableCallback } from "../events/rawEvents";
import { IDisposable } from "../disposable";
import * as _ from "lodash";
import { resizeSnapContent } from "../../utility/mdsUtils";

export class SearchTermService {
  events = new Events();

  onReady(fn: any): IDisposable {
    return this.events.observe("ready", fn);
  }

  onLoad(fn: any): IDisposable {
    return this.events.observe("load", fn);
  }

  onRefresh(fn: any): IDisposable {
    return this.events.observe("refresh", fn);
  }

  onReplace(fn: ObservableCallback<ISearchTerm>): IDisposable {
    return this.events.observe("replace", fn);
  }

  load(items: any) {
    this.events.notify("load", items);
  }

  replace(item: any) {
    this.events.notify("replace", item);
  }

  ready() {
    this.events.notify("ready");
  }

  refresh() {
    this.events.notify("refresh");
  }
}

interface ISearchProviderScope extends angular.IScope {
  results: any[];
  terms: any;
  executors: any;
  placeholder: string;
  loading: boolean;
  tags: ISearchTerm[];
  openDrawer: any;
}

export const mdsSearchProvider =
  ["$rootScope", "searchTermProvider", "searchExecutorProvider", "snapRemote", "searchTermService",
    ($rootScope: angular.IRootScopeService,
     searchTermProvider: SearchTermProvider,
     searchExecutorProvider: SearchExecutorProvider,
     snapRemote,
     searchTermService: SearchTermService) =>

      ({
        restrict: "E",
        replace: true,
        templateUrl: require("./mdsSearchProvider.html"),
        scope: {
          results: "=?",
          terms: "@",
          executors: "@",
          placeholder: "@",
          loading: "=?",
          tags: "=?",
          openDrawer: "=?"
        },
        compile() {
          return {
            pre($scope: ISearchProviderScope) {
              const cleanUpFunctions = createDisposable();
              cleanUpFunctions.disposeWith($scope);
              const currentChanged = angular.noop;
              cleanUpFunctions.add(searchExecutorProvider.record(currentChanged));

              $scope.tags = $scope.tags || [];
              $scope.results = [];
              $scope.loading = false;

              /* Need to put options on the scope in pre function as the ui-select2 directive will require
              it to be there before the linking function gets called */

              const termProvider = searchTermProvider.get($scope.terms);
              const searchExecutor = searchExecutorProvider.get($scope.executors);
              searchExecutor.observe("start", () => {
                $scope.loading = true;
                $scope.results = [];
              });
              searchExecutor.observe("result", vm => $scope.results.push(vm));
              searchExecutor.observe("end", vms => $scope.loading = false);

              $scope.placeholder = $scope.placeholder != null ? $scope.placeholder : "";

              $scope.select2Options = {
                placeholder: $scope.placeholder,
                tags: true,
                multiple: true,
                query(query) {
                  if ($scope.openDrawer === true) {
                    snapRemote.open("left");
                  }
                  return termProvider.getTerms(query);
                }
              };

              const executeSearch = () => searchExecutor.search($scope.tags);

              $scope.$watchCollection("tags", executeSearch);

              const onLoad = function(search) {
                $scope.tags.length = 0;
                for (const tag of search.tags) { $scope.tags.push(tag); }
                if (search.apply !== false) { $scope.$apply(); }
                if (search.toggle) {
                  snapRemote.getSnapper().then(snapper => {
                    if (snapper.state().state === "left") {
                      snapper.close();
                      resizeSnapContent($scope.openDrawer === false);
                    } else {
                      snapper.open('left');
                      resizeSnapContent($scope.openDrawer === true);
                    }
                  });
                }
              };

              searchTermService.onLoad(onLoad);
              searchTermService.onRefresh(executeSearch);
              searchTermService.onReplace((searchTerm => {
                _.remove($scope.tags, (tag) => tag.type === searchTerm.type);
                $scope.tags.push(searchTerm);
                snapRemote.open("left");
                resizeSnapContent(true);
              }));

              cleanUpFunctions.add(
                $rootScope.$on("SearchLoadRequested", function(event, search) {
                  onLoad(search);
                })
              );
              searchTermService.ready();
            }
          };
        }
      })

  ];

export const mdsLoadSearchTerms = ["snapRemote", snapRemote =>
  ({
    restrict: "A",
    link($scope, $element, $attrs) {
      return $element.bind("click", function() {
        const terms = angular.fromJson($attrs.mdsLoadSearchTerms);
        const toggle = $attrs.mdsToggle !== "false";
        return $scope.$emit("SearchLoadRequested", { tags: terms, toggle });
      });
    }
  })
];