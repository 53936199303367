import * as angular from "angular";
import { LoDashStatic } from "lodash";
import { Modality, ExamType, Study, BusinessModelService } from "../../businessModels";

interface StudyDetailsModalityController extends angular.IController { }
class StudyDetailsModalityController implements angular.IController {

  modalities: Modality[];
  study: Study;

  viewModel: {
    modality: Modality,
    examType: ExamType
  }

  static $inject = ["$mdDialog", "lodash", "businessModels", "study_id"];
  constructor(
    private readonly $mdDialog: angular.material.IDialogService,
    private readonly _: LoDashStatic,
    private readonly models: BusinessModelService,
    study_id: number
  ) {
    this.models.Study.find({ id: study_id })
      .then(x => {
        this.study = x;
        this.viewModel = {          
          modality: x.modality,
          examType: x.exams[0].type
        };      
      }
    ),
    this.models.Modality.list().then(y => this.modalities = y)
  }

  save(): void {
    this.study.modality = this.viewModel.modality;
    this.study.exams[0].type = this.viewModel.examType;
    this.$mdDialog.hide();
  }
    
  cancel(): void {
    this.$mdDialog.cancel();
  }

  moduleExamTypes(): ExamType[] {
    if (this.viewModel && this.viewModel.modality) {
      return this._.filter(
        this.models.ExamType.listActive(), et => et.modality === this.viewModel.modality);
    } else { return []; }
  }

  resetExamType() {
    this.viewModel.examType = null;
  }

}

export function editModalityDialogOptions(study_id: number)
  : angular.material.IDialogOptions {
  return {
    templateUrl: require('./studyDetailsModality.html'),
    controller: StudyDetailsModalityController,
    openFrom: document.querySelector('[data-id="modality"]'),
    locals: {
      study_id: study_id
    },
    controllerAs: "$ctrl",
    bindToController: true
  }
}