///<reference path="./mdsOn.d.ts"/>

import * as angular from "angular";
import HasPendingJobsService from "./watchers/hasPendingJobsService";
import Vector2 from "./geometry/vector2";
import * as lodash from "lodash";
import momentModule from "../moment.module";
import throttleModule from "./events/throttle";
import revertible from "./revertible";
import loadingStatusModule from "./loadingStatus";
import localStorage from "./localStorage.service";
import variantCache from "./variantCache";
import mdsRecompile from "./mdsRecompile";
import mdsIfAuthenticated from "./mdsIfAuthenticated";
import { Crypto } from "./crypto";
import panZoomModule from './panZoom';
import {
  mdsBlurInside,
  mdsFocusInside,
  mdsFocusWhen,
  mdsStopPropagation
} from "./mdsEventDirectives";
import appUpdatePrompt from "./appUpdatePrompt";
import userPassword from "./userPassword";

export default angular.module("midas.utility", [
    momentModule.name,
    throttleModule.name,
    panZoomModule.name,
    appUpdatePrompt.name,
    userPassword.name,
    loadingStatusModule.name
])
    .constant("lodash", lodash)
    .service("hasPendingJobs", HasPendingJobsService)
    .service("variantCache", variantCache)
    .service("localStorage", localStorage)
    .constant('Vector2', Vector2)
    .constant('revertible', revertible)
    .constant("crypto", new Crypto())
    .directive("mdsIfAuthenticated", mdsIfAuthenticated)
    .directive("mdsBlurInside", mdsBlurInside)
    .directive("mdsFocusInside", mdsFocusInside)
    .directive("mdsFocusWhen", mdsFocusWhen)
    .directive("mdsStopPropagation", mdsStopPropagation)
    .directive("mdsRecompile", mdsRecompile);

export { MomentStatic as Moment } from "moment";
export * from "./geometry/circle";
export * from "./geometry/vector2";
export * from "./geometry/rect";
export * from "./geometry/triangle";
export * from "./events/events";
export * from "./events/rawEvents";
export { Throttle } from "./events/throttle";
export * from "./disposable";
export * from "./watchers/hasPendingJobsService";
export { PanZoom } from "./panZoom";
export * from "./tracing";
export { LoadingStatus } from "./loadingStatus";
export { default as LocalStorage } from "./localStorage.service";
export { Crypto } from "./crypto";
export { VariantCache } from "./variantCache";
export * from "./mdsUtils";
export { LocalContext } from "./localContext";