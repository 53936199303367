import { module as ngModule } from "angular";
import { IStateProvider, IStateParamsService } from "angular-ui-router";
import PatientDetailsController from "./detailsCtrl";
import patientStudyListModule from "./patientStudyList.component";
import NewPatientController from "./newCtrl";
import DuplicatePatientService from "./duplicates/duplicateDetection.service";
import DuplicatePatientList from "./duplicates/duplicatePatientList.component";
import MergedPatientList from "./duplicates/mergedPatientList.component";
import config from "../../config/config";
import inlineHelp from "../../utility/inlineHelp/inlineHelp.component";
import expansionPanel from "../../utility/expansionPanel/expansionPanel";
import "angular-material";
import * as ngMessageFormat from "angular-message-format";
import { BusinessModelService } from "../../businessModels";

export default ngModule("midas.midas.patients", [
    config.name,
    inlineHelp.name,
    ngMessageFormat,
    expansionPanel.name,
    "ngMaterial",
    patientStudyListModule.name
])
.controller("patientDetailsCtrl", PatientDetailsController)
.controller("patientNewCtrl", NewPatientController)
.service("duplicatePatients", DuplicatePatientService)
.component("duplicatePatientList", DuplicatePatientList)
.component("mergedPatientList", MergedPatientList)
.config(["$stateProvider", (stateProvider: IStateProvider) => stateProvider
    .state("midas.patients", {
        url: "/patients",
        abstract: true
    })
    .state("midas.patients.new", {
        url: "/new",
        views: {
            "work@midas": {
                templateUrl: require("./new.html"),
                controller: "patientNewCtrl as $ctrl"
            }
        }
    })
    .state("midas.patients.view", {
        url: "/:patientId",
        resolve: {
            "patient": ["businessModels", "$stateParams", "$mdToast", "$q",
                (models: BusinessModelService, $stateParams: IStateParamsService, $mdToast: angular.material.IToastService, $q: angular.IQService) =>
                    models.Patient.find({ id: $stateParams.patientId }).then(patient => {
                        if (patient && !patient.isDeleted) { return patient; }
                        $mdToast.showSimple("Couldn't find patient. Were they recently merged or deleted?");
                        return $q.reject("Couldn't find patient " + $stateParams.patientId);
                    }, reason => {
                        $mdToast.showSimple("Error while finding patient.");
                        return $q.reject(reason);
                    })]
        },
        views: {
            "work@midas": {
                templateUrl: require("./details.html"),
                controller: "patientDetailsCtrl as $ctrl"
            },
            "reference@midas": {
                template: "<patient-study-list patient='patient'></patient-study-list>",
                controller: ["$scope", "patient", ($scope, patient) => $scope.patient = patient]
            }
        }
    })
]);
