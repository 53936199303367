import {
  module as ngModule,
  IComponentController,
  INgModelController,
  IFormController
} from "angular";
import {
  BlueprintExpression,
  ExpressionOperatorType,
  IBinaryExpression,
  LiteralOperandType,
  IMeasurementTypeExpression,
  ILiteralNumber,
  ILiteralString,
  ICheckMarkerExpression,
  isUnaryOp,
  isBinaryOp,
  ExpressionResultType,
  getResultType,
  typesMatch
} from "../../../utility/reportFormatter/widgets/condition-expression.models";
import filterModule, { allOperators } from "./expression-editor.filters";
import "./expression-editor.component.scss";
import { BindingOf, IChangesObject } from "../../../utility/componentBindings";
import reportFormatterMeasurementTypeDialogModule from "../dialogs/report-formatter-measurement-type.dialog";
import chooseValueMenuModule from "./choose-value-menu.component";
import opSelectModule from "./operator-select-menu.component";
import { directiveName as reportContextDirective, BlueprintContextController } from '../../../utility/reportFormatter/blueprintContext.directive';
import { MarkerController } from '../../../utility/reportFormatter/widgets/marker.service';
import { grammaticalJoin } from '../../../utility/mdsUtils';

/** A component to allow the editing of blueprint expression of any kind. It displays the expression
 * as a tree of nested expression editors for each branch. */
class ExpressionEditorController implements IComponentController, IBindings {
  /**
   * The ng-model controller to allow utility of validation, etc.
   */
  ngModel: INgModelController;

  /** Bound callback triggered when user wishes to remove the current Expression. */
  onRemove: () => void;

  /** Bound callback triggered when a property of the Expression, or its children, have been changed. */
  onUpdate: () => void;

  onExpand: (args: { $type: ExpressionOperatorType }) => void;

  /** The context controller for this component. Bound via a directive require. */
  context: BlueprintContextController;

  /** The currently bound Expression to modify. */
  expression: BlueprintExpression;

  /** Options for operand 1 when it is a measurement. Will be null if not a measurement or a
   * measurement with no options. */
  op1Options?: ReadonlyArray<string>;

  /** Literal strings can either be free text, or a set of options when this property is set. This
   * is set via component bindings and is generally provided by an outer expression editor's
   * `op1Options` property. */
  options?: ReadonlyArray<string>;

  /** A cache of marker controllers so that we only refresh them when needed. */
  private _cachedMarkers: MarkerController[];

  /** The form used to get local inputs that need validating. There's a bit of a funny dance to
   * combine the errors from the bound expression with any local inputs so we can display all the
   * errors together. */
  localForm: IFormController & { localInput: INgModelController };

  /** Gets a list of available marker widget controllers from the report context. The result is
   * cached after the first call, and will remain valid until another marker is inserted, which
   * can't happen without destroying this directive and invalidating this cache again. */
  get availableMarkers() {
    if (this._cachedMarkers == null) {
      this.context.refreshMarkers();
      this._cachedMarkers = this.context.markers;
    }
    return this._cachedMarkers;
  }

  /** Valid operators for the current operands. */
  operators: ReadonlyArray<ExpressionOperatorType>;

  /** The type expected by the user of the expression editor. For instance when editing a condition,
   * the result will need to be a boolean, whereas a value being written out to the report would be
   * a number or string. May have multiple values. */
  type?: ExpressionResultType | ExpressionResultType[];

  static $inject = ["$mdDialog"];
  constructor(
    private readonly $mdDialog: angular.material.IDialogService) {
  }

  $onInit(): void {
    const model = this.ngModel;
    model.$render = () => {
      this.expression = this.ngModel.$viewValue;
      this.operators = this.getAppropriateOperators(this.expression);
      this.op1Options = this.getMeasurementOptions(this.expression);
    };

    // This is distint from the individual [required] validators in the HTML, because they apply to
    // the values and measurement names and stuff. This applies to an actual expression editor
    // that's bound to a null expression.
    model.$validators["null-expr"] = function(_modelValue, viewValue: BlueprintExpression) {
      return viewValue != null;
    };

    // Ensures that the result type expected of this expression (passed in bia component bindings),
    // and the actual result type of the expression are matching. For instance, we may expect the
    // type ["number", "string"], and get "boolean" because this expression is an exists check. That
    // would not be valid.
    model.$validators["result-type"] = (_modelValue, viewValue: BlueprintExpression) => {
      const expectedType = this.type;
      if (expectedType == null) {
        return true;
      }
      const resultType = getResultType(viewValue);
      const matches = typesMatch(expectedType, resultType);
      // We only care about an exact false return, which indicates the types definitely didn't
      // match. An undefined return indicates there wasn't enough info, and true means they match.
      return matches !== false;
    };

    // The exists and doesNotExist operator are a bit odd and need more explanation than the
    // result-type validator is able to give, since they only apply to very specific child
    // expressions rather than types.
    model.$validators["existential-op"] = function(_modelValue, viewValue: BlueprintExpression) {
      if (!viewValue || viewValue.type !== "unary-expression") {
        return true;
      }
      const op = viewValue.operator;
      const op1 = viewValue.operand1;

      const isExistentialOperator = op === "| exists" || op === "| doesNotExist";
      return !isExistentialOperator
        || op1.type === "measurement-type"
        || op1.type === "check-marker";
    };

    // Checks that the result types of the operands of a binary expression are the same type. This
    // only really applies to equality operators, since the others come with their own requirements
    // (like you can't use < or + with a string).
    model.$validators["binary-operand-types"] = function(_modelValue, viewValue: BlueprintExpression) {
      if (!viewValue || viewValue.type !== "binary-expression") {
        return true;
      }
      const op = viewValue.operator;
      if (op !== "===" && op !== "!==") {
        return true;
      }

      const leftType = getResultType(viewValue.operand1);
      const rightType = getResultType(viewValue.operand2);
      // We only care about an exact false return, which indicates the types definitely didn't
      // match. An undefined return indicates there wasn't enough info, and true means they match.
      return typesMatch(leftType, rightType) !== false;
    };

    // Ensures that any measurement expressions have a known measurement type name assigned.
    model.$validators["known-measurement-type"] = (_modelValue, viewValue: BlueprintExpression) => {
      return !viewValue
        || viewValue.type !== "measurement-type"
        || this.context.measurementTypes[viewValue.value] != null;
    };
  }

  $onChanges(changes: IChangesObject<IBindings>) {
    if (changes.type != null) {
      // Force revalidation by the ng-model controller whenever the expected types change, as that
      // can make something that wasn't valid now valid and visa-versa.
      this.ngModel.$validate();
    }
  }

  changed() {
    // Need to validate whenever we're informed of a change, whether from children or whatever.
    // They can all impact the validation.
    this.ngModel.$validate();
    this.operators = this.getAppropriateOperators(this.expression);
    this.op1Options = this.getMeasurementOptions(this.expression);

    if (this.onUpdate) {
      this.onUpdate();
    }
  }

  /** Gets a list of operators that are appropriate for the current operands, or null. */
  private getAppropriateOperators(expression: BlueprintExpression) {
    if (expression != null) {
      return allOperators;
      // if (expression.type === "binary-expression" || expression.type === "unary-expression") {
      //   return getOperators(expression, this.context);
      // }
    }
    return null;
  }

  /** Gets the dropdown options to display for the second operand if the first is a measurement
   * with dropdown options, or null. */
  private getMeasurementOptions(expr: BlueprintExpression) {
    if (expr != null && expr.type === "binary-expression" && expr.operand1 != null) {
      const op1 = expr.operand1;
      if (op1.type === "measurement-type") {
        const mt = this.context.measurementTypes[op1.value];
        // Populate options for this measurement type for the user to choose from.
        if (mt && mt.dropdownOptions != null && mt.dropdownOptions.length > 0) {
          return mt.dropdownOptions;
        }
      }
    }
    return null;
  }

  /** Replace current expression and publish change through ngModel */
  private replaceCurrentExpression(expression: BlueprintExpression): void {
    this.expression = expression;
    this.ngModel.$setViewValue(expression);
    this.changed();
  }

  /**
   * Handle user choice to change the current expression to a chosen value type.
   * @param type The type of operand to switch to.
   */
  changeExpressionType(type: LiteralOperandType | "binary-expression"): void {
    const exp = this.createOp(type);

    this.replaceCurrentExpression(exp);
  }

  /** Gets whether the expression or the local input has any errors. This is used to manually set
   * the error state (class) of md-input-containers so that they can take into account errors from
   * the outer expression as well as any nested input. */
  hasErrors(): boolean {
    const form = this.localForm;
    return this.ngModel.$invalid || (form && form.localInput && form.localInput.$invalid) === true;
  }

  /** Gets an error object representing all of the errors on either the bound expression and any
   * input (text/number/measurement key/etc.). We display all of those errors together, so we
   * need to be able to use a single ng-messages, which requires a single object. */
  mergedErrors(): INgModelController["$error"] {
    const expr = this.ngModel;
    const input = (this.localForm && this.localForm.localInput);
    if (expr.$invalid) {
      if (input && input.$invalid) {
        return { ...expr.$error, ...input.$error };
      }
      return expr.$error;
    }
    if (input && input.$invalid) {
      return input.$error;
    }
  }

  /**
   * Create an Operand by optionally presenting the user with choices to populate it.
   * @param type The type of operand to create.
   * @returns A promise to the created Operand, when the user has completed the triggered dialogs, etc.
   */
  private createOp(type: LiteralOperandType | "binary-expression"): BlueprintExpression {
    switch (type) {
      case "measurement-type":
        return <IMeasurementTypeExpression> {
          type: "measurement-type",
          value: ""
        };
      case "check-marker":
        return <ICheckMarkerExpression> {
          type: "check-marker",
          value: ""
        };
      case "literal-number":
      case "literal-string":
        return <ILiteralString | ILiteralNumber> { type, value: null };
      case "binary-expression":
        return <IBinaryExpression> {
          type: "binary-expression",
          operator: "===",
          operand1: null,
          operand2: null
        };
    }
  }


  changeOperator(op: ExpressionOperatorType | null) {
    const expr = this.expression;

    if (expr == null) {
      return;
    }

    if (expr.type === "binary-expression") {
      if (op == null) { // Remove the operand for whatever the value is.
        this.replaceCurrentExpression(expr.operand1 || expr.operand2);
      } else if (isUnaryOp(op)) {
        this.replaceCurrentExpression({
          type: "unary-expression",
          operator: op,
          operand1: expr.operand1
        });
      } else {
        expr.operator = op;
      }
      this.changed();
    } else if (expr.type === "unary-expression") {
      if (op == null) { // Remove the operand for whatever the value is.
        this.replaceCurrentExpression(expr.operand1);
      } else if (isBinaryOp(op)) {
        this.replaceCurrentExpression({
          type: "binary-expression",
          operator: op,
          operand1: expr.operand1,
          operand2: null
        });
      } else {
        expr.operator = op;
      }
      this.changed();
    }
  }

  /** User wishes to remove the current expression. The parent Expression is responsible for
   * removing this operand from the expression in which it is contained. */
  remove(): void {
    if (this.onRemove != null) {
      this.onRemove();
    }
  }

  /** Respond to user request to remove operand 1.  */
  removeOp1(): void {
    if (this.expression.type === "binary-expression" || this.expression.type === "unary-expression") {
      this.expression.operand1 = null;
      this.changed();
    }
  }

  /** Respond to user request to remove operand 2.  */
  removeOp2(): void {
    if (this.expression.type === "binary-expression") {
      this.expression.operand2 = null;
      this.changed();
    }
  }

  /** Gets whether to show the remove button in the HTML. This should only be shown for the topmost
   * of any nested expressions. */
  showRemoveBtn() {
    const expression = this.expression;

    if (expression.type === "binary-expression") {
      const op1 = expression.operand1;
      return op1 == null || (op1.type !== "binary-expression" && op1.type !== "unary-expression");
    }

    return expression.type === "unary-expression";
  }

  /** Gets user land displayable text for the set of expected types. */
  getExpectedTypesText() {
    const type = this.type;
    if (typeof type === "string") {
      return ExpressionEditorController.getDisplayType(type);
    } else {
      return grammaticalJoin(type.map(ExpressionEditorController.getDisplayType), ", ", ", or ");
    }
  }

  /** Gets user land displayable text for the actual type of an expression. */
  getActualTypeText(expr: BlueprintExpression = this.expression) {
    const type = getResultType(expr);
    return ExpressionEditorController.getDisplayType(type);
  }

  /** Converts expression result types into text versions that can be displayed to the user. */
  static getDisplayType(type: ExpressionResultType) {
    switch(type) {
      case "boolean": return "true/false";
      case "number": return "a number";
      case "string": return "text";
    }
    return type;
  }

  openSearchMeasurementTypesDialog(expression: IMeasurementTypeExpression) {
    this.$mdDialog.showMeasurementTypePrompt(this.context.measurementTypes)
        .then(mmt => {
          expression.value = mmt.key;
          this.changed();
        });
  }
}

interface IBindings {
  onRemove: () => void;
  onUpdate: () => void;
  options?: ReadonlyArray<string>;
  type?: ExpressionResultType | ExpressionResultType[];
}

const bindings: BindingOf<IBindings> = {
  onRemove: "&?",
  onUpdate: "&?",
  options: "<?",
  type: "<?"
};

const componentName = "expressionEditor";

export default ngModule("midas.admin.blueprint.expression.expressionEditor", [
  filterModule.name,
  reportFormatterMeasurementTypeDialogModule.name,
  opSelectModule.name,
  chooseValueMenuModule.name
]).component(componentName, {
    controller: ExpressionEditorController,
    templateUrl: require("./expression-editor.component.html"),
    require: {
      ngModel: "ngModel",
      context: "^" + reportContextDirective
    },
    bindings
  });