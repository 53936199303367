/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import * as angular from "angular";
angular.module("midas.utility.measurement.views").controller("STDCTACtrl", ["$scope", function($scope) {
  $scope.popups = [];
  const addPopup = (segmentNo, id, text) =>
    $scope.popups.push({
      segmentNo,
      id,
      text
    })
  ;
  addPopup(1, "rcaProx", "RCA Proximal");
  addPopup(2, "rcaMid", "RCA Mid");
  addPopup(3, "rcaDist", "RCA Distal");
  addPopup(4, "pda", "PDA");
  addPopup(5, "lm", "LM");
  addPopup(6, "ladProx", "LAD Proximal");
  addPopup(7, "ladMid", "LAD Mid");
  addPopup(8, "ladDist", "LAD Distal");
  addPopup(9, "d1", "D1");
  addPopup(10, "d2", "D2");
  addPopup(11, "lcxProx", "LCx Proximal");
  addPopup(12, "lcxDist", "LCx Distal");
  addPopup(13, "om1", "OM1");
  addPopup(14, "om2", "OM2");
  addPopup(16, "plb", "PLB");
  $scope.measurementEquals = function() {
      console.log('checking');
      return true;
    };
}
]).filter("ctaStenosis", () => function(str) { 
  switch (str) {
    case "minimal": return "1 - 25%";
    case "mild": return "26 - 60%";
    case "moderate": return "51 - 70%";
    case "severe": return "71 - 99%";
    case "occluded": return "100%";
    case "patent": return "Patent";
    default: return " ";
  }
}
 );