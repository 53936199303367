import { fromJson, toJson } from "angular";
import { InstituteSettingsMetadataService } from "../../config/instituteSettingsMetadata";
import { BusinessModelService } from "../../businessModels";
import { LoDashStatic } from "lodash";

// Used to create an object for serialization
export interface IExamTypeModel {
    masterName: string; // Master exam type name, stored in DB
    customName: string; // Customized exam type name, shown in UI
}

// Used to create an object containing the current ExamTypeMapping deserialized settings
// Singleton across the examTypeMapping component members
export interface IExamTypeMappingModel {
    masterName: string; // Master exam type name, stored in DB
    customName: string; // Customized exam type name, shown in UI
    selected: boolean;  // Selected item
}

// Used to create a dropdown list of available exam type names.
// Mapped types will be disabled in UI
// Singleton across the examTypeMapping component members
export interface IExamList {
    typeName: string;   // Master exam type name, stored in DB
    isMapped: boolean;  // Flag marking mapped types
}

export interface ISearchResult {
    examType: string;
    matchFound: boolean;    
}

export default class ExamTypeNameConfigurationService {

    settingKey: string;
    examTypeList: IExamList[];
    examTypeMappings: IExamTypeMappingModel[];

    static $inject = [
        "lodash",
        "businessModels",
        "instituteSettingsMetadata"];
    constructor(
        private readonly _: LoDashStatic,
        private readonly models: BusinessModelService,
        private readonly instSettings: InstituteSettingsMetadataService
    ) {
        this.init();
    }

    private init(): void {
        this.settingKey = this.instSettings.ExamTypeNameConfiguration.key;
        this.examTypeList = new Array<IExamList>();
        this.examTypeMappings = new Array<IExamTypeMappingModel>();
        this.reloadSettings();
    }

    reloadSettings() {
        this.getSettingsFromJSON();
        this.createAvailableExamTypeList(this.examTypeMappings.map(key => key.masterName));        
    }

    setSettingsToJSON = (mappingArray: IExamTypeMappingModel[]): string => {
        return toJson(mappingArray != null ? this.mapToExportShape(mappingArray) : null);
    }    

    getCustomName(masterName: string): ISearchResult {
        if (masterName && masterName.length > 0) {
            let customExamType = this.examTypeMappings.filter(
                f => f.masterName === masterName)[0];
            if (customExamType)
                return {
                    examType: customExamType.customName,
                    matchFound: true
                } as ISearchResult;
            else
                return {
                    examType: masterName,
                    matchFound: false
                } as ISearchResult;
        }
        else return null;
    }

    private getSettingsFromJSON = (): void => {
        this.examTypeMappings.length = 0;
        let data = fromJson(this.models.Institute.current.getSetting(this.settingKey) ||
            this.instSettings.ExamTypeNameConfiguration.defaultValue);
        if (data.length > 0) {
            this.examTypeMappings = this._.sortBy(data
                .map((x: IExamTypeMappingModel) => {
                    return {
                        masterName: x.masterName,
                        customName: x.customName,
                        selected: false
                    } as IExamTypeMappingModel
                }) as IExamTypeMappingModel[], (examType) => {
                    return examType.masterName.toLowerCase()
                });
        }
    }

    private createAvailableExamTypeList = (existingTypes : string[]): void => {
        this.examTypeList.length = 0;
        this.examTypeList = this._.sortBy(this.models.ExamType.listActive().map((x) => {
            return {
                typeName: x.key,
                isMapped: (existingTypes.indexOf(x.key) === -1) ? false : true
            }
        }), (examType) => { return examType.typeName.toLowerCase() });
    }

    private mapToExportShape(mappingArray: IExamTypeMappingModel[]): IExamTypeModel[] {
        return mappingArray.map((x) => {
            return {
                masterName: x.masterName,
                customName: x.customName
            }
        });
    }

}
