import { MeasurementController, IMeasurementScope } from "../MeasurementController";
import * as angular from "angular";
class DXSkullController extends MeasurementController {
  static $inject = ["$scope"];
  constructor(scope: IMeasurementScope) {
    super(scope);
  }

  clickRightOK() {
    this.setMeasurementValue("DXSkullConclusion", "No fracture or other significant abnormality is seen. The calvaria and skull base are normal.");
    console.log("Right All normal clicked")
  }

  clickLeftOK() {
    this.setMeasurementValue("DXSkullConclusion", "No fracture or other significant abnormality is seen. The calvaria and skull base are normal.");

    console.log("Left All normal clicked")
  }
}

angular.module("midas.utility.measurement.views").controller("DXSkullCtrl", DXSkullController);