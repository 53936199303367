import { LoDashStatic } from "lodash";
import { BusinessModelService } from "../../businessModels";
import * as angular from "angular";
import "angular-ui-router"
import { SearchTermService } from "../../utility/search/mdsSearchDirectives";
import { SearchExecutorProvider, ISearchTerm } from "../search/search";
import "Content/NonNuget/feedback/angular-send-feedback.min.css";
import "Scripts/NonNuget/feedback/angular-send-feedback.min.js";
import { Throttle, createDisposable } from '../../utility/utils';
import { serviceName as pushServiceName, PushService } from '../../utility/push/push.service';
import { IAuthService } from '../../authService';
import { resizeSnapContent } from "../../utility/mdsUtils";

interface Scope extends angular.IScope {
  savedSearches: {
    terms: ISearchTerm[],
    text: string,
    fav: boolean,
    setFav(): angular.IPromise<boolean>
  }[];
  snapOptions: {
    maxPosition: number,
    minPosition: number,
    touchToDrag: boolean
  };
  beginTour: () => void;
  user: {
    logout(): angular.IPromise<any>,
    isAuthenticated(): boolean,
    userName(): string
  };
  layoutStates: {
    "fullScreenSlideLeft": {
      key: "fullScreenSlideRight",
      getRegionClasses: GetRegionClasses
    },
    "fullScreenSlideRight": {
      key: "fullScreenSlideRight",
      getRegionClasses: GetRegionClasses;
    }
  };
  studySelected(vm): boolean;
  feedbackOptions: {
      html2canvasURL: string;
      feedbackButton: string;
      ajaxURL: string;
      tpl: {
        description: string;
        highlighter: string;
        overview: string;
        submitSuccess: string;
        submitError: string;
      }
    }
}

type GetRegionClasses = (args: string) => {
  details: string;
  list: string;
  summary: string;
  [key: string]: string;
};

export default [
  "$scope",
  "$state",
  "lodash",
  "businessModels",
  "authService",
  pushServiceName,
  "searchExecutorProvider",
  "searchTermService",
  "snapRemote",
  "$window",
  function (
    $scope: Scope,
    $state: angular.ui.IStateService,
    lodash: LoDashStatic,
    models: BusinessModelService,
    authService: IAuthService,
    pushService: PushService,
    searchExecutorProvider: SearchExecutorProvider,
    searchTermService: SearchTermService,
    snapRemote: any,
    $window: angular.IWindowService) {

    // Refresh the search list when the server indicates there are any study changes.
    const refreshTrottle = new Throttle<null>(2500);
    refreshTrottle.observe(() => searchTermService.refresh());
    const stopListening = pushService.onStudyChanged(() => refreshTrottle.onNext(null));
    $scope.$on("$destroy", createDisposable(stopListening));

    angular.element($window).on("resize", () => {
      snapRemote.getSnapper().then(snapper => {
        resizeSnapContent(snapper.state().info.opening != null && snapper.state().state=="left");
      })
    });

    $scope.listToggle = () => {
      snapRemote.getSnapper().then(snapper => {
        if (snapper.state().state === "left") {
          snapper.close();
          resizeSnapContent(false);
        } else {
          snapper.open('left');
          resizeSnapContent(true);
        }
      }); 
    };

    let tourKey = "IsTourCompleted";

    let saved = null;
    let provisional = null;
    $scope.savedSearches = [];

    let ready = false;
    let loaded = false;
    searchTermService.onReady(function() {
      ready = true;
      if ($scope.studyFilter) {
          if ($state.is("midas")) { searchTermService.load({ tags: $scope.studyFilter, apply: false, toggle: true}); }
        return loaded = true;
      }
    });

    let setupSearches = function() {
      $scope.studyFilter = angular.fromJson(models.User.current.settings["studyFilter"]);
      if (!$scope.studyFilter) {
      $scope.studyFilter = [{"id": "Studies_recordType", "text": "Studies", "value": "Studies", "type": "recordType"}]; }
      if ($state.is("midas") && ready && !loaded) { searchTermService.load({ tags: $scope.studyFilter, toggle: true }); }

      let statuses = models.Status.listNow();
      let addSearch = function (text: string, terms: ISearchTerm[]) {
        let fav = lodash.isEqual(terms, $scope.studyFilter);
        return $scope.savedSearches.push({
          terms,
          text,
          fav,
          setFav() {
            models.User.current.setSetting("studyFilter", this.fav ? null : angular.toJson(this.terms));
            return models.save((function() {}), false, false)
            .then(() => {
              let previousFavStatus = this.fav;
              $scope.studyFilter = previousFavStatus ? null : this.terms;
              for (let x of $scope.savedSearches) { x.fav = false; }
              return this.fav = !previousFavStatus;
            });
          }
        });
      };

      const newStatus = lodash.find(statuses, x => x.key === "New");
      saved = lodash.find(statuses, x => x.key === "Saved");
      provisional = lodash.find(statuses, x => x.key === "Provisional");
      const final = lodash.find(statuses, x => x.key === "Final");

      const studiesTag: ISearchTerm = { "id": "Studies_recordType", "text": "Studies", "value": "Studies", "type": "recordType" };
      const newTag: ISearchTerm = {"id": `${newStatus.id}_status`, "text": "New", "value": newStatus.id, "type": "status"};
      const savedTag: ISearchTerm = {"id": `${saved.id}_status`, "text": "Saved", "value": saved.id, "type": "status"};
      const provTag: ISearchTerm = {"id": `${provisional.id}_status`, "text": "Provisional", "value": provisional.id, "type": "status"};
      const finalTag: ISearchTerm = {"id": `${final.id}_status`, "text": "Final", "value": final.id, "type": "status"};

      const currentUser = models.User.current;
      if (currentUser.technician || currentUser.physician) {
      addSearch("My Studies", [{"id": "myStudies", "text": "My Studies", "value": "myStudies", "type": "myStudies"}]); }

      addSearch("New Studies", [studiesTag, newTag]);
      addSearch("Saved Studies", [studiesTag, savedTag]);
      addSearch("Provisional Studies", [studiesTag, provTag]);
      addSearch("Final Studies", [studiesTag, finalTag]);
    };

    setupSearches();

    const goToAStudy = function() {
      if ($state.current.name === "midas.studies.view.details") {
         $scope.$broadcast("beginTour");
      } else {
        const p = models.Study.find();
        p.then(study => $state.go("midas.studies.view.details", {studyId: study.id}));
        models.load.track(p);
      }
    };

    $scope.beginTour = function() {
      if (models.User.current != null) {
        models.User.current.setSetting(tourKey, "false");
      }
      const p = models.save((function() {}), false, false);
      p.then(() => goToAStudy());
      models.load.track(p);
    };

    $scope.snapOptions = {
     maxPosition: 600,
     minPosition: -600,
     touchToDrag: false
   };

    $scope.studySelected = function(vm) {
      searchExecutorProvider.setCurrent(vm);
      snapRemote.close();
      resizeSnapContent(false);
      return true;
    };

    $scope.user = {
      logout() {
        authService.logout();
        return $state.go("login");
      },
      isAuthenticated() { return authService.isAuthenticated(); },
      userName() {
        const user = authService.getUser();
        return user != null ? user.userName : undefined;
      }
    };

    const fullScreenSlideLeft = {
      key: "fullScreenSlideLeft",
      getRegionClasses(args: string) {
        const classes = {
          "details": "mds-animate-fadeout",
          "list": "mds-animate-fadeout",
          "summary": "mds-animate-fadeout"
        };

        classes[args] = "mds-animate-fullScreenSlideLeft";

        return classes;
      }
    };

    const fullScreenSlideRight = {
      key: "fullScreenSlideRight",
      getRegionClasses(args: string) {
        const classes = {
          "details": "mds-animate-fadeout",
          "list": "mds-animate-fadeout",
          "summary": "mds-animate-fadeout"
        };

        classes[args] = "mds-animate-fullScreenSlideRight";

        return classes;
      }
    };


    $scope.layoutStates = <any>{};
    $scope.layoutStates[fullScreenSlideLeft.key] = fullScreenSlideLeft;
    $scope.layoutStates[fullScreenSlideRight.key] = fullScreenSlideRight;

    $scope.feedbackOptions = {
      // We're overriding the Webpack config here with a file-loader override rule. Since this
      // plugin needs a URL to the js file, we can't let Webpack inline it, but we've also got other
      // code which uses this file, which does need it inlined. So in this case we're just making
      // an exception and using an inline file-loader to make the file itself be deployed as well.
      html2canvasURL: require('!file-loader!html2canvas'),
      feedbackButton: '.btn-feedback',
      ajaxURL: 'api/midas/feedback',
      tpl: {
        description: `\
<div id="feedback-welcome"> \
  <div class="feedback-logo">Feedback</div> \
  <p>Feedback lets you send us suggestions about our products. We welcome problem reports, feature ideas and general comments.</p><p>Start by writing a brief description:</p> \
  <textarea id="feedback-note-tmp"></textarea> \
  <p>Next we\'ll let you identify areas of the page related to your description.</p> \
  <button id="feedback-welcome-next" class="feedback-next-btn feedback-btn-gray">Next</button> \
  <div id="feedback-welcome-error">Please enter a description.</div> \
  <div class="feedback-wizard-close"></div> \
</div>`,
        highlighter: `\
<div id="feedback-highlighter"> \
  <div class="feedback-logo">Feedback</div> \
  <p>Click and drag on the page to help us better understand your feedback. You can move this dialog if it\'s in the way.</p> \
  <button class="feedback-sethighlight feedback-active"> \
    <div class="ico"></div> \
    <span>Highlight</span> \
  </button> \
  <label>Highlight areas relevant to your feedback.</label> \
  <button class="feedback-setblackout"> \
    <div class="ico"></div> \
    <span>Black out</span>
  </button> \
  <label class="lower">Black out any personal information.</label> \
  <div class="feedback-buttons"> \
    <button id="feedback-highlighter-next" class="feedback-next-btn feedback-btn-gray">Next</button> \
    <button id="feedback-highlighter-back" class="feedback-back-btn feedback-btn-gray">Back</button> \
  </div> \
  <div class="feedback-wizard-close"></div> \
</div>`,
        overview: `\
<div id="feedback-overview"> \
  <div class="feedback-logo"> \
  Feedback \
  </div> \
  <div id="feedback-overview-description"> \
    <div id="feedback-overview-description-text"> \
      <h3>Type</h3> \
      <div class="radio"> \
        <label> \
        <i class="fa fa-exclamation" style="width: 20px;"></i> \
        <input type="radio" name="optradio"> \
        Problem \
        </label> \
      </div> \
      <div class="radio"> \
        <label> \
        <i class="fa fa-question" style="width: 20px;"></i> \
        <input type="radio" name="optradio"> \
        Question \
        </label> \
      </div> \
      <div class="radio"> \
        <label> \
        <i class="fa fa-comment" style="width: 20px;"></i> \
        <input type="radio" name="optradio"> \
        Improvement \
        </label> \
      </div> \
      <div class="radio"> \
        <label> \
        <i class="fa fa-smile-o" style="width: 20px;"></i> \
        <input type="radio" name="optradio"> \
        Compliment \
        </label> \
      </div> \
      <h3 id="feedback-overview-description-header">Description</h3> \
    </div> \
  </div> \
  <div id="feedback-overview-screenshot"> \
    <h3>Screenshot</h3> \
  </div> \
  <div class="feedback-buttons"> \
    <button id="feedback-submit" class="feedback-submit-btn feedback-btn-blue">Submit</button> \
    <button id="feedback-overview-back" class="feedback-back-btn feedback-btn-gray">Back</button> \
  </div> \
  <div id="feedback-overview-error"> \
    Please enter a description. \
  </div> \
  <div class="feedback-wizard-close"></div> \
</div>`,
        submitSuccess: `\
<div id="feedback-submit-success"> \
  <div class="feedback-logo"> \
    Feedback \
  </div> \
  <p>Thank you for your feedback. We value every piece of feedback we receive.</p> \
  <p>A MIDAS team member will be in touch with you soon.</p> \
  <button class="feedback-close-btn feedback-btn-blue"> \
    OK \
  </button> \
  <div class="feedback-wizard-close"></div> \
</div>`,
        submitError: `\
<div id="feedback-submit-error"> \
  <div class="feedback-logo"> \
    Feedback \
  </div> \
  <p>Sadly an error occurred while sending your feedback. Please try again.</p> \
  <button class="feedback-close-btn feedback-btn-blue"> \
    OK \
  </button> \
  <div class="feedback-wizard-close"></div> \
</div>`
      }
    };
  }
];