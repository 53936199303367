import * as angular from "angular";
import { Events } from "../events/events";
import { IDisposable } from "../disposable";

export interface HasPendingJobsEvent {
    readonly type: "has-pending-jobs";
    readonly studyId: number;
    readonly value: boolean;
}

export interface HasPendingJobsErrorEvent {
    readonly type: "error";
    readonly studyId: number;
    readonly value: any;
}

/** This has no real use once the latest MIDAS.Connect changes go out as we've moved away from
 * using the job system for processing incoming studies, so this should always return false.
 * TODO: Remove this service. */
export class HasPendingJobsService {
    private $jobEvents: Events;
    /** Holds polling registrations from $interval. */
    private $pollRegistrations: { [key: string]: angular.IPromise<any> } = {};

    static $inject = ["$interval", "$q", "$http"];
    constructor(
        private $interval: angular.IIntervalService,
        private $q: angular.IQService,
        private $http: angular.IHttpService) {
        this.$jobEvents = new Events();
    }

    /** Checks whether there are any observers for a study, and stops polling if so.
     * @returns True if there are no observers, false otherwise.
     */
    private stopWatchingWhenNoObservers(studyId: string): boolean {
        if (!this.$jobEvents.hasObservers(studyId)) {
            const registration = this.$pollRegistrations[studyId];
            if (registration) {
                this.$interval.cancel(registration);
            }
            this.$pollRegistrations[studyId] = undefined;
            return true;
        }
        return false;
    }

    /** Starts polling for a study id if not started already.
     * @returns true if this service was already polling for the provided study id, false
     * otherwise. */
    private ensurePolling(studyId: number): boolean {
        const key = `${studyId}`;
        if (!this.$pollRegistrations[key]) {
            this.$pollRegistrations[key] =
                this.$interval(() => this.doPoll(studyId), this.jobPollPeriod);
            return false;
        }
        return true;
    }

    /** Handles actually issuing a poll request on each timer interval. */
    private doPoll(studyId: number) {
        if (!this.stopWatchingWhenNoObservers(`${studyId}`)) {
            this.hasPendingJobs(studyId);
        }
    }

    /** Gets or sets the number of milliseconds between polls to the server when waiting for no
         * pending jobs. */
    jobPollPeriod = 15000;

    /** Subscribes for events indicating whether a study has jobs running on the server.
         * @param callback The callback to get notification. The result can be either a value or
        * an error, depending on the value of the type property.
        * @returns A disposable registration token which can be called to stop watching.
        */
    watchHasPendingJobs(studyId: number, next: (value: HasPendingJobsEvent | HasPendingJobsErrorEvent) => void): IDisposable {
        if (typeof next !== "function") {
            throw Error("next must be a function");
        }
        this.ensurePolling(studyId);
        const registration = this.$jobEvents.observe(`${studyId}`, next);
        this.hasPendingJobs(studyId);
        return registration;
    }

    private createEvent(studyId: number, hasPendingJobs: boolean): HasPendingJobsEvent {
        return {
            type: "has-pending-jobs",
            studyId,
            value: hasPendingJobs
        };
    }

    private createErrorEvent(studyId: number, error: any): HasPendingJobsErrorEvent {
        return {
            type: "error",
            studyId,
            value: error
        };
    }

    /** Queries the server to determine whether this study has jobs that have yet to complete. */
    hasPendingJobs(studyId: number): angular.IPromise<boolean> {
        const key = `${studyId}`;
        return this.$http.get(`api/study/HasPendingJobs/${key}`)
            .then(response => {
                var hasJobs = response.data === "True";
                if (this.$jobEvents.hasObservers(key)) {
                    this.$jobEvents.notify(key, this.createEvent(studyId, hasJobs));
                }
                return hasJobs;
            }, err => {
                if (this.$jobEvents.hasObservers(key)) {
                    this.$jobEvents.notify(key, this.createErrorEvent(studyId, err));
                }
                return this.$q.reject(err);
            });
    }

    /** Provides a hint to this service that we expect a study to have jobs and it should do
     * work to quickly resolve whether there are pending jobs and notify observers. This is
     * useful to be able to provide timely and accurate feedback across the system in response
     * to things like `study.reloadImages()`.
     * @param studyId The id of the study to hint at. */
    hintExpectingJobs(studyId: number): void {
        const key = `${studyId}`;
        if (this.$jobEvents.hasObservers(key)) {
            this.$jobEvents.notify(key, this.createEvent(studyId, true));
            this.hasPendingJobs(studyId);
        }
    }
}

export default HasPendingJobsService;