import { MeasurementController, IMeasurementScope } from "../MeasurementController";
import * as angular from "angular";

class CalfController extends MeasurementController {
  static $inject = ["$scope"];
  constructor(scope: IMeasurementScope) {
    super(scope);
  }

  clickRightOK() {
    this.setMeasurementValue("MSKAnkleRtPosteriorNormal", "ALL NORMAL");
    this.setMeasurementValue("MSKAnkleRtAnteriorNormal", "ALL NORMAL");
    this.setMeasurementValue("MSKAnkleRtMedialNormal", "ALL NORMAL");
    this.setMeasurementValue("MSKAnkleRtLateralNormal", "ALL NORMAL");
    this.setMeasurementValue("MSKAnkleRtLigamentsNormal", "ALL NORMAL");
    this.setMeasurementValue("MSKAnkleRtVasculatureNormal", "ALL NORMAL");
    console.log("Right All normal clicked")
  }

  clickLeftOK() {
    this.setMeasurementValue("MSKAnkleLtPosteriorNormal", "ALL NORMAL");
    this.setMeasurementValue("MSKAnkleLtAnteriorNormal", "ALL NORMAL");
    this.setMeasurementValue("MSKAnkleLtMedialNormal", "ALL NORMAL");
    this.setMeasurementValue("MSKAnkleLtLateralNormal", "ALL NORMAL");
    this.setMeasurementValue("MSKAnkleLtLigamentsNormal", "ALL NORMAL");
    this.setMeasurementValue("MSKAnkleLtVasculatureNormal", "ALL NORMAL");
    console.log("Left All normal clicked")
  }
}

angular.module("midas.utility.measurement.views").controller("CalfCtrl", CalfController);