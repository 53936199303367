import * as angular from "angular";
import "angular-material";

interface MultiToast extends angular.IController { }
/** The controller for creating toasts which display a single message, or for multiple messages,
 * a short summary with the option to open a dialog to view all of the messages. */
class MultiToast implements angular.IController {
    static $inject = ["messages", "multiMessage", "joinMessages", "$mdToast", "$mdDialog"];
    constructor(
        private readonly messages: string[],
        private readonly multiMessage: string,
        joinMessages: "div" | "ul" | ((messages: string[]) => string),
        private readonly $mdToast: angular.material.IToastService,
        private readonly $mdDialog: angular.material.IDialogService) {
            //Allow override of the join messages function.
            switch (joinMessages) {
                case "ul":
                    this.joinMessages = MultiToast.joinMessagesWithUl;
                    break;
                case "div": //Div is the default, so no need to override.
                default:
                    if (typeof joinMessages === "function") {
                        this.joinMessages = joinMessages;
                    } else {
                        this.joinMessages = MultiToast.joinMessagesWithDiv;
                    }
            }
    }

    static singleTemplate = '<md-toast>' +
        '<span class="md-toast-text" flex>{{::$ctrl.messages[0]}}</span>' +
    '</md-toast>';

    static multiTemplate = '<md-toast>' +
      '<span class="md-toast-text" flex>{{::$ctrl.multiMessage}}</span>' +
      '<md-button class="md-accent" ng-click="$ctrl.showErrorsDialog($event)">' +
        'Show' +
      '</md-button>' +
    '</md-toast>';

    /** Creates toast options for displaying one or more messages as a toast such that multiple
     * messages require the user to interact with the toast to open a modal for more details.
     * This is great for times when we technically support multiple items, such as for file upload,
     * but when the majority of cases will be a single toast.
     * @param messages One or more messages to display.
     * @param multiMessage The message to display when there are more than one message in messages.
     * @param joinMessages An optional string or function which defines how multiple messages are
     * joined into a html string for display in a modal. "div" wraps each message in a <div> and
     * "ul" displays each message as a list item of a <ul> element.
     */
    static toastOptions(messages: string[], multiMessage = "Multiple messages", joinMessages: "div" | "ul" | ((messages: string[]) => string) = "div"): angular.material.IToastOptions {
        return {
            controller: MultiToast,
            template: messages.length > 1 ? MultiToast.multiTemplate : MultiToast.singleTemplate,
            bindToController: true,
            controllerAs: "$ctrl",
            locals: { messages, multiMessage, joinMessages }
        };
    }

    joinMessages: (messages: string[]) => string;

    static joinMessagesWithDiv(messages: string[]): string {
        return messages.map(m => `<div>${m}</div>`).join("");
    }

    static joinMessagesWithUl(messages: string[]): string {
        return `<ul>${messages.map(m => `<li>${m}</li>`).join("")}</ul>`;
    }

    showErrorsDialog(e?: MouseEvent) {
        this.$mdDialog.show(this.$mdDialog
            .alert()
            .title(this.multiMessage)
            .htmlContent(this.joinMessages(this.messages))
            .ariaLabel(this.multiMessage)
            .ok('Dismiss')
            .escapeToClose(true)
            .targetEvent(e));
        this.$mdToast.hide();
    }
}

export default MultiToast;