import {
  module as ngModule,
  IScope,
  IAttributes
} from "angular";
import { Editor, Settings, ui } from "tinymce";
import { TinymceButtonOptions } from "../../../utility/tinymce/Plugins/toolbarButton";
import reportEditorModule, {
  TinymceConfigurationController,
  tinymceConfigurationRequire,
  ToolbarAttributes
} from "../mds-rich-text-editor.component";
import expressionModule, {
  serviceName as expressionServiceName,
  ExpressionService
} from "../../../utility/reportFormatter/widgets/expression.service";
import panelModule, {
  serviceName as blueprintPanelsName,
  BlueprintPanelsService
} from "../../../admin/reportFormatters/dialogs/blueprint-panel.service";
import isEditableModule, {
  serviceName as isEditableServiceName,
  EditableService
} from './editable.service';
import { IDisposable, safeDispose } from '../../../utility/disposable';

const zwnbs = String.fromCharCode(65279);

/** TinyMCE plugin to provide a button which will prompt the user for an expression. The
 * blueprint-panel-plugin is responsible for handling the accept events and interacting with the
 * template. */
class InsertExpressionController extends TinymceConfigurationController {
  panelId: string;

  static $inject = [
    "$scope",
    "$attrs",
    expressionServiceName,
    isEditableServiceName,
    blueprintPanelsName
  ];
  constructor(
    private readonly $scope: IScope,
    private readonly $attrs: IAttributes,
    private readonly exprService: ExpressionService,
    private readonly editable: EditableService,
    private readonly panels: BlueprintPanelsService) {
    super();
  }

  configure(options: Settings) {
    const toolbar = ToolbarAttributes.FromAttributes(this.$attrs);
    this.extendToolbar(toolbar.id, "insert-expression", options);
  }

  setup(editor: Editor) {
    const me = this;
    const buttonOptions: TinymceButtonOptions = {
      type: "button",
      tooltip: "Insert an output value into report",
      text: "Insert Value",
      onPostRender() { me.disableOnInvalidSelection(editor, this); },
      onclick: () => this.$scope.$apply(() => this.insert(editor))
    };
    editor.addButton("insert-expression", buttonOptions);
  }

  private disableOnInvalidSelection(editor: Editor, button: ui.Control): void {
    editor.on("NodeChange", () => {
      const selected = editor.selection.getRng(true);
      button.disabled(!this.editable.canEdit(selected));
    });
  }

  private insert(editor: Editor): void {
    const panel = this.panels.get(this.panelId);
    if (panel) {
      const range = editor.selection.getRng(true).cloneRange();
      const widget = this.exprService.create();
      panel.show(widget).then(result => {
        if (result === "accept") {
          const cleanup = this.makeRangeSafe(range);
          try {
            widget.insert(editor);
          } finally {
            safeDispose(cleanup);
          }
        }
      });
    }
  }

  /** Ensures a range is safe by inserting some bogus HTML in some cases, so that operations which
   * extract or edit the DOM don't go backtracking past the start of the range looking for a text
   * element. That can lead to stuff ending up in places it shouldn't. */
  private makeRangeSafe(range: Range): IDisposable {
    if (range.startOffset === 0) {
      // A bogus node we sometimes insert to ensure tinymce/browser stops here, rather than
      // tracking back to the previous text node, which could be outside the selection.
      let bogusNode: Text = null;
      bogusNode = document.createTextNode(zwnbs);
      if (range.startContainer.nodeType === 3) {
        range.startContainer.parentNode.insertBefore(bogusNode, range.startContainer);
      } else {
        range.startContainer.insertBefore(bogusNode, range.startContainer.firstChild);
      }
      if (range.startContainer.nodeType !== 3) {
        // If the selection is not a text node then move it after the bogus node,
        // otherwise no change is needed.
        range.setStart(range.startContainer, 1);
      }
      return () => {
        if (bogusNode != null && bogusNode.nodeValue === zwnbs) {
          bogusNode.remove();
        }
      };
    }

    return null;
  }
}

export default ngModule("midas.utility.tinymce.insertExpression", [
  reportEditorModule.name,
  expressionModule.name,
  panelModule.name,
  isEditableModule.name
])
.component("insertExpressionButton", {
  require: {
    ...tinymceConfigurationRequire
  },
  controller: InsertExpressionController,
  bindings: {
    panelId: "@"
  }
});