import {
  module as ngModule,
  element as $,
  IPromise as ngPromise,
  IHttpService,
  IQService
} from "angular";
import { Exam, ReportFormatter } from "../../businessModels";
import "angular-sanitize";
import measurementContextModule, {
  serviceName as measurementContextServiceName,
  MeasurementContextService
} from "../../utility/measurement/directives/measurementContext.service";
import expressionModule, {
  serviceName as expressionServiceName,
  ExpressionService
} from "./widgets/expression.service";
import markerModule, {
  serviceName as markerServiceName,
  MarkerService
} from "./widgets/marker.service";
import conditionModule, {
  serviceName as conditionServiceName,
  ConditionalService
} from "./widgets/conditional.service";
import { IWidgetCompilationContext } from './widgets/widget';

/** A service for getting both self serve report templates and older ones which are
 * compiled on the server.  */
export class ReportFormatterTemplateService {

  static $inject = [
    "$http",
    "$q",
    measurementContextServiceName,
    expressionServiceName,
    markerServiceName,
    conditionServiceName];
  constructor(
    private readonly $http: IHttpService,
    private readonly $q: IQService,
    private readonly measurementService: MeasurementContextService,
    private readonly exprService: ExpressionService,
    private readonly markerService: MarkerService,
    private readonly condService: ConditionalService) { }

  /** Gets the compiled report result for an Exam. If the `ReportFormatter` is a newer self serve
   * Blueprint (which stores HTML directly in the DB), then it can be retrieved and compiled
   * locally. Otherwise a request will be made to the server to generate a report using the legacy
   * Report Formatters. */
  getCompiledReport(exam: Exam): ngPromise<string> {

    /** Checks whether a local report formatter can be used to generate a report. */
    function isValidSelfServe(formatter: ReportFormatter) {
      return formatter != null
        && formatter.user == null
        && formatter.isDeleted !== true
        && formatter.type === "SelfServe";
    }

    /** Load from the server. Compile the result just in case it is a Blueprint. */
    const loadFromServer = (examId: number): ngPromise<string> => {
        return this.$http
          .get<string>(`api/midas/RegenerateReport/${examId}`)
          .then(response => response.data)
          .then(template => this.compile(exam, template));
    }

    /** Gets the locally cached self serve formatter for an exam type if it exists, or null. */
    if (exam.type == null || exam.type.reportFormatters == null)
      return loadFromServer(exam.id);

    const localFormatters = exam.type.reportFormatters.filter(isValidSelfServe);

    if (localFormatters.length !== 1)
      return loadFromServer(exam.id);

    return localFormatters[0].loadTemplate()
      .then(result => {
        if (result) {
          return this.$q.when(this.compile(exam, result));
        } else {
          return loadFromServer(exam.id);
        }
      });
  }

  /** Compiles a report from an exam and blueprint.  */
  compile(exam: Exam, blueprint: string | JQuery | Element): string {
    if (blueprint == null) {
      return null;
    }
    /* After stripping, the blueprint element may be an array of elements such as paragraphs and
     * text. Calling html() on the JQuery object will return nothing in this case. However if
     * appended to a parent element, the InnerHtml can be calculated correctly. Additionally if the
     * blueprint string does not have any html tags, it will treat it like a CSS selector, and won't
     * load the content. By appending it to a parent, it will force it to be treated as HTML. */
    const parent = "<div></div>";
    blueprint = $(parent).append(blueprint)[0];

    const measurements = this.measurementService.create(exam);
    const context = <IWidgetCompilationContext> {
      values: this.measurementService.getValueLookup(measurements),
      markers: new Set<string>()
    };

    // These will be used a lot in the hot path. Making them local allows the compiler to optimise
    // them much better, rather than having to do several object lookups each time.
    const condQs = this.condService.querySelector;
    const exprQs = this.exprService.querySelector;
    const markQs = this.markerService.querySelector;

    // Stores the ancestor iterators so that we can go back to each one in the same state it was
    // before we recursed. This allows us to walk the tree in a way that makes sense to us, rather
    // than exactly as the DOM is laid out.
    const ancestors: Iterator<Element>[] = [];
    // Start the iterator with the first child of the blueprint node. Since we added a <div> at the
    // root, we know we can go straight to it's children. It is converted to an array first so that
    // we can update the DOM during iteration.
    let currentIterator: Iterator<Element> = Array.from(blueprint.children)[Symbol.iterator]();

    // This loop traverses down the elements in the blueprint tree, checking whether each is one of
    // the known widgets and refreshing them if so. It walks the tree intelligently, with knowledge
    // of the way each widget behaves so that it can skip large parts of the tree such as false
    // conditions, and the nodes which make up expression text (not expression widgets). This could
    // have been abstracted more, but I think it's actually clearer to do all of this here rather
    // than scatter it across other files, and this code is fast.
    while (true)
    {
      const next = currentIterator.next();
      if (!next.done)
      {
        const node = next.value;

        if (node.matches(condQs)) {
          const widget = this.condService.get($(node));
          widget.refresh(context);
          const isInReport = widget.result;
          if (isInReport) {
            // If the condition is true then we need to check it's content for nested widgets, but
            // we can skip the expression and anything else. If the condition is not true, the
            // entire node will be removed later when stripped, so we can ignore it.
            const content = widget.getContentNodes();
            if (content != null && content.length > 0) {
              ancestors.push(currentIterator);
              currentIterator = content.filter((i, el) => el.nodeType === 1)
                                       .toArray()[Symbol.iterator]();
            }
          }
          widget.strip();
        } else if (node.matches(exprQs)) {
          const widget = this.exprService.get($(node));
          widget.refresh(context);
          widget.strip();
          // Don't recurse, as we know that expressions can't have widgets nested inside them.
        } else if (node.matches(markQs)) {
          const widget = this.markerService.get($(node));
          widget.refresh(context);
          widget.strip();
          // Don't recurse, as we know that markers can't have widgets nested inside them.
        } else if (node.childElementCount > 0) {
          // If this is a non-widget node with children then keep processing down the tree.
          ancestors.push(currentIterator);
          currentIterator = Array.from(node.children)[Symbol.iterator]();
        }
        // Otherwise just move on to the next sibling.

      } else if (ancestors.length > 0) {
        // If the current node iterator is finished then move up to the previous ancestor.
        currentIterator = ancestors.pop();
      } else {
        // If the current node iterator is finished and there are no ancestors then we're done.
        break;
      }
    }

    return blueprint.innerHTML;
  }
}

export const serviceName = "reportFormatter";

export default ngModule(
  "midas.utility.reportFormatter.reportFormatterService", [
    "ngSanitize",
    measurementContextModule.name,
    expressionModule.name,
    markerModule.name,
    conditionModule.name
  ]
).service(serviceName, ReportFormatterTemplateService);