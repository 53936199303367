import { module as ngModule } from "angular";
import { IBlueprintPanel } from './blueprint-panel.component';
import { find } from "lodash";

/** Provides a central location from which to get named blueprint panel controllers. We may want to
 * place the panel distant from the actual editor, which can otherwise make it hard to find. */
export class BlueprintPanelsService {

    /** Cached controllers by panel id. */
    private readonly services: IBlueprintPanel[] = [];

    /** Provides panel controller by id.
     * @param panelId The panel id to provide a controller for. */
    public get(panelId: string): IBlueprintPanel {
        return find(this.services, x => x.panelId === panelId);
    }

    /** Registers a panel with the panel provider. */
    public register(controller: IBlueprintPanel) {
      if (controller == null) {
        return;
      }
      if (this.services.indexOf(controller) >= 0) {
        return;
      }

      this.services.push(controller);
    }

    /** Unregisters a panel with the panel provider. */
    public unregister(controller: IBlueprintPanel) {
      if (controller == null) {
        return;
      }
      const index = this.services.indexOf(controller);
      if (index >= 0) {
        this.services.splice(index, 1);
      }
    }
}

export const serviceName = "blueprintPanels";
export default ngModule("midas.admin.blueprint.blueprintPanels", [])
  .service(serviceName, BlueprintPanelsService);