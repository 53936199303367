import { IDirective } from 'angular';

//Shamelessly stolen from https://kentcdodds.github.io/kcd-angular/#/kcd-recompile
//Just recompiles the contents whenever the attribute property changes. If there is a use-boolean
//attribute then it will recompile whenever the boolean is true, then set it back to false.
export default ['$parse', $parse => (<IDirective>{
    transclude: true,
    restrict: "A",
    link(scope, $el, attrs, ctrls, transclude) {
        let previousElements = undefined;
        const compile = function() {
            transclude(scope, function(clone, clonedScope) {
                // transclude creates a clone containing all children elements;
                // as we assign the current scope as first parameter, the clonedScope is the same
                previousElements = clone;
                $el.append(clone);
            });
        };

        const recompile = function() {
            if (previousElements) {
                previousElements.remove();
                previousElements = null;
                $el.empty();
            }
            compile();
        };

        compile();

        const trigger = $parse(attrs.mdsRecompile);

        scope.$watch(attrs.mdsRecompile, (function(val) {
            if (attrs.hasOwnProperty('useBoolean')) {
                if (!val || (val === 'false')) {
                    return;
                }
                trigger.assign(scope, false);
            }
            recompile();
        }), typeof trigger(scope) === 'object');
    }
})];