import * as Utils from "../../utility/utils";
import { coordExtent, defaultCanvasWidth, DefaultDotsPerCentimetre } from "./imageEditorVars";
import { PatternService } from "./patterns";
import * as angular from "angular";
import { HistoryManager } from "./historyManager";
import ToolManager from "./tools/toolManager";
import { InputManager } from "./inputManager";
import * as createjs from "createjs";
import { IPointLike, Rect, Unit, Throttle, PanZoom, Traceable, TraceableManager } from "../../utility/utils";

export interface IImageEditorScope extends angular.IScope {
  /** The colour of the stage when nothing is in front of it. */
  stageBackgroundColour: string;
  canvasStyle: any;
  /** Contains the names of all tools in the editor. */
  toolList: string[];
  /** The shape currently being edited. */
  editShape: IShape;
  /** Gets an image from the canvas, as a base64 encoded string.
   * @param format The format of the image to get.
   * @param dpcm Required dots per cm.
   * @param width Required width of the full cached content in cm.
   */
  getImageFunction(format: "jpg" | "png", dpcm?: number, width?: number): string;
  /** Whether the image editor has made changes to the diagram. */
  isDirty: boolean;
  /** Where to put shapes the user creates. */
  history: IShape[];
  /** Gets or sets the url to the background image for the editor. */
  stageBackgroundImage: string;
  /** The current configuration object for the tools. */
  toolConfig: {};
  /** The name of the currently active tool. */
  activeToolName: string;
  /** If provided this is the number of ms between automatic updates of the canvas space to
   * match the layout space of the canvas element in the HTML. Defaults to 1000ms. */
  autoResize?: number;
}

/** Base shape interface which the image editor needs to function. */
export interface IShape {
  type: string;
  points: IPointLike[];
}

class ImageEditorDirective implements angular.IDirective {
  restrict = "E";
  transclude = true;
  template =
  //Overflow and position mean that absolutely positioned children (like createjs.DOMElements)
  //are clipped properly to the canvas.
`<div style="overflow: hidden; position: relative;">
<div ng-transclude></div>
<canvas class='mds-image-editor' ng-style='canvasStyle'></canvas>
</div>`;

  /** Set up isolated scope for all children so they can't mess with the surrounding scope. */
  scope: { [boundProperty: string]: string } = {
    /** background image to be loaded. */
    stageBackgroundImage: "=?",
    /** The colour of the stage when nothing is in front of it. */
    stageBackgroundColour: "=?",
    /** Where to put actions the user takes. */
    history: "=",
    /** The name of the currently active tool. */
    activeToolName: "=?",
    /** List of strings of tool names. */
    toolList: "=?",
    /** Canvas height. */
    height: "=?",
    /** Canvas width. */
    width: "=?",
    /** A number defining the number of milliseconds between size checks when automatically
     * updating the size of the canvas space to match the layout space of the canvas in the HTML.
     * Defaults to 1000 ms. */
    autoResize: "=?",
    /** When called, returns a print quality snapshot of the canvas as a base 64 encoded image. */
    getImageFunction: "=?",
    isDirty: "=?",
    /** The shape currently being edited. */
    editShape: "=?",
    /** The current configuration object for the tools. */
    toolConfig: "=?",
    /** If provided, the tool control layer will follow this position. Otherwise it will sit at
     * the top in the middle.
     * Currently seems to have been partially stripped from the source, so don't rely on this. */
    controlsLocation: "=?"
  };

  controller = ImageEditorController;
}

/** Manager responsible for handling display related data and operations. Display layers,
 * coordinate conversion, resizing, etc. can all be found here. */
export class DisplayContext extends Traceable {
  /** Cached version of the module coordExtent variable. */
  readonly coordExtent = coordExtent;
  /** Cached version of (1 / this.coordExtent). */
  private readonly $invCoordExtent = 1 / coordExtent;

  private $updateThrottle: Throttle<Unit>;
  /** The image used to load and display a background image from URI. */
  private $backImage = new Image();
  /** The easeljs representation of the background image. */
  private $easelBackBitmap: createjs.Bitmap = null;

  /** The root for all other layers. */
  user: createjs.Container;
  /** A layer used for rendering background images and other things which the
   * user can't interact with. */
  background: createjs.Container;
  /** A layer for tool controls with constant displayed size, no matter the zoom. */
  control: createjs.Container;
  /** A layer for tools to draw to while the user is actually interacting with the tool. */
  scratch: createjs.Container;
  /** A layer for cached content. Everything in the history is rendered once to this for
   * performance. */
  cache: createjs.Container;
  /** An object which tracks the current pan and zoom position. */
  panZoom: PanZoom;
  /** Defines the mouse cursor when the mouse is over the canvas. */
  cursor: string;

  /** The width we expect to be displaying this image at, in centimetres. */
  widthCm: number = defaultCanvasWidth;
  /** The number of dots (pixels) per cm we want. */
  dpcm: number = DefaultDotsPerCentimetre;

  /** The height of the current background image divided by it's width. */
  aspectRatio: number = 1;
  /** Sets the background image to the image at the provided URI.
    * @param imageUri The URI to the image. */
  setBackgroundImage(imageUri: string) {
    if (imageUri != null && imageUri !== "") {
      this.$backImage.src = imageUri;
    }
  }

  /** Convert a point from normalised stage coordinates to normalised user coordinates.
   * @param stagePoint Point to convert.
   * @param setPoint If provided, the result is written to this point, otherwise a new one
   * is created.
   */
  stageToUser(stagePoint: IPointLike, setPoint?: IPointLike): IPointLike {
    return this.$stage.localToLocal(stagePoint.x, stagePoint.y, this.user, setPoint);
  }

  /** Convert a point from normalised user coordinates to normalised stage coordinates.
   * @param userPoint Point to convert.
   * @param setPoint If provided, the result is written to this point, otherwise a new one
   * is created.
   */
  userToStage(userPoint: IPointLike, setPoint?: IPointLike): IPointLike {
    return this.user.localToLocal(userPoint.x, userPoint.y, this.$stage, setPoint);
  }

  /** Convert a point from raw canvas coordinates (pixels) to normalised stage coordinates.
   * @param canvasPoint Point to convert.
   * @param setPoint If provided, the result is written to this point, otherwise a new one
   * is created.
   */
  canvasToStage(canvasPoint: IPointLike, setPoint?: IPointLike): IPointLike {
    return this.$stage.globalToLocal(canvasPoint.x, canvasPoint.y, setPoint);
  }

  /** Converts the provided page coordinates into coordinates local to the provided canvas.
   * The resulting point is CANVAS local, not STAGE local. IE the units are still px.
   * Converted from easeljs so that we can simulate their page to stage code for touches.
   * @param pagePoint The page point to convert
   * @param setPoint The point to assign into. If not provided a new one is created.
   */
  pageToCanvas(pagePoint: IPointLike, setPoint?: IPointLike): IPointLike {
    const canvas = <HTMLCanvasElement>this.$stage.canvas;
    var rect = this.$getElementRect(canvas);
    let pageX = pagePoint.x;
    let pageY = pagePoint.y;
    pageX -= rect.x;
    pageY -= rect.y;

    var w = canvas.width;
    var h = canvas.height;
    pageX *= w / rect.width;
    pageY *= h / rect.height;
    pageX = pageX < 0 ? 0 : (pageX > w - 1 ? w - 1 : pageX);
    pageY = pageY < 0 ? 0 : (pageY > h - 1 ? h - 1 : pageY);

    if (setPoint) {
      setPoint.x = pageX;
      setPoint.y = pageY;
      return setPoint;
    }
    return { x: pageX, y: pageY };
  };

  /** Gets the bounding rect of an element. Modified from easeljs so that we can simulate their
   * page to stage code for touches.
   * @param e The element to get the bounding rect for. */
  private $getElementRect(e: HTMLElement): Rect {
    var bounds;
    try { bounds = e.getBoundingClientRect(); } // this can fail on disconnected DOM elements in IE9
    catch (err) {
      bounds = {
        top: e.offsetTop,
        left: e.offsetLeft,
        width: e.offsetWidth,
        height: e.offsetHeight
      };
    }

    const rect = new Rect(bounds.left, bounds.top, bounds.width, bounds.height);

    const offset = {
      x: (window.pageXOffset || document["scrollLeft"] || 0) - (document["clientLeft"] || document.body.clientLeft || 0),
      y: (window.pageYOffset || document["scrollTop"] || 0) - (document["clientTop"] || document.body.clientTop || 0)
    }
    rect.translate(offset);

    var styles = window.getComputedStyle ? getComputedStyle(e, null) : e["currentStyle"]; // IE <9 compatibility.
    var padL = parseInt(styles.paddingLeft) + parseInt(styles.borderLeftWidth);
    var padT = parseInt(styles.paddingTop) + parseInt(styles.borderTopWidth);
    var padR = parseInt(styles.paddingRight) + parseInt(styles.borderRightWidth);
    var padB = parseInt(styles.paddingBottom) + parseInt(styles.borderBottomWidth);

    rect.x += padL;
    rect.width -= padL + padR;
    rect.y += padT;
    rect.height -= padT + padB;
    return rect;
  };

  /** Converts stage size/coordinates to pixels.
   * @param stageValue The value in stage coordinates to convert.
   * @returns The result in CSS pixels.
   */
  stageToPixels(stageValue: number): number {
    const cssPxPerCm = 96 / 2.54; // 1 / 96 px per inch, 2.54 cm per inch.
    return this.stageToCm(stageValue) * cssPxPerCm;
  }

  /** Converts stage size/coordinates to points.
   * @param stageValue The value in stage coordinates to convert.
   * @returns The result in points.
   */
  stageToPoints(stageValue: number): number {
    const ptsPerCm = 72 / 2.54; // 1 / 72 pts per inch, 2.54 cm per inch.
    return this.stageToCm(stageValue) * ptsPerCm;
  }

  /** Converts stage size/coordinates to centimetres.
   * @param stageValue The value in stage coordinates to convert.
   * @returns The result in centimetres. */
  stageToCm(stageValue: number): number {
    return this.widthCm * stageValue * this.$invCoordExtent;
  }

  /** Converts pixels to normalised stage size/coordinates.
   * @param pixels The value in pixels to convert.
   * @returns The result in normalised stage coordinates.
   */
  pixelsToStage(pixels: number): number {
    const cmPerPx = 2.54 / 96; // 1 / 96 px per inch, 2.54 cm per inch.
    return this.cmToStage(pixels * cmPerPx);
  }

  /** Converts points to normalised stage size/coordinates.
   * @param points The value in points to convert.
   * @returns The result in normalised stage coordinates.
   */
  pointsToStage(points: number): number {
    const cmPerPt = 2.54 / 72; // 1 / 72 pts per inch, 2.54 cm per inch.
    return this.cmToStage(points * cmPerPt);
  }

  /** Converts centimetres to normalised stage size/coordinates.
   * @param points The value in centimetres to convert.
   * @returns The result in normalised stage coordinates. */
  cmToStage(cm: number): number {
    return (cm / this.widthCm) * this.coordExtent;
  }

  /** Removes all children from the scratch later. */
  clearScratch() {
    this.scratch.removeAllChildren();
    this.update();
  }

  /** Sets the scale of an object to the inverse of the current scale of the user layer. This
   * allows objects to be kept the same size regardless of the current zoom level.
   * @param scalable An object which has x and y scale.
   */
  undoUserZoomOn(scalable: { scaleX: number, scaleY: number }) {
    if (scalable != null) {
      scalable.scaleX = 1.0 / this.user.scaleX;
      scalable.scaleY = 1.0 / this.user.scaleY;
    }
  }

  /** If the zoom is currently un-zoomed then zoom in on the centroid. Otherwise reset to the
   * unzoomed state.
   * @param centroid The point around which to zoom.
   */
  zoomOrReset(centroid: IPointLike): void {
    if (this.panZoom.scale.equals(1, 1, 0.05)) {
      this.panZoom.zoom(centroid, 4);
    } else {
      this.panZoom.reset();
    }
    this.update();
  };

  /** Rebuilds the cached image for the current cache layer based on the desired quality
   * settings.
   * @param dpcm Required dots per cm.
   * @param widthCm Required width of the full cached content in cm.
   */
  recache(dpcm: number = this.dpcm, widthCm: number = this.widthCm) {
    this.cache.cache(0, 0, this.coordExtent, this.coordExtent * this.aspectRatio, dpcm * widthCm * this.$invCoordExtent);
    this.update();
  }

  /** Resizes the editor (canvas, stage, images, and recaches previous drawings to the new size).
   * If 'rescale' is truthy, everything is rescaled to ensure the view remains similar to what it
   * was before the resize. */
  resize(width: number, height: number, dpcm: number = this.dpcm, widthCm: number = this.widthCm): void {
    if (width <= 0 || height <= 0) {
      return;
    }
    const canvas = <HTMLCanvasElement>this.$stage.canvas;

    const within1 = (a: number, b: number) => a - 1 <= b && a + 1 >= b;

    //Don't resize if we're within 1 pixel of the size to avoid differences in rounding on
    //different browsers.
    if (!within1(canvas.width, width) || !within1(canvas.height, height)) {
      this.panZoom.endPanZoom();
      //Ensure background image always fills the canvas area.
      this.$stage.scaleX = this.$stage.scaleY = width * this.$invCoordExtent;
      canvas.width = width;
      canvas.height = height;
      this.recache(dpcm, widthCm);
      //Settings width/height completely clears the canvas, so update immediately to avoid
      //screen flickering.
      this.update(true);
    }
  }

  /**
   * Resizes the canvas internal pixel dimensions to the actual number of pixels the canvas is
   * taking up on the screen after CSS and layout are applied. This can help simplify the
   * positioning of HTML elements in canvas space, such as the text tool requires.
   * @param dpcm The desired dots per centimetre quality.
   * @param widthCm The desired width of the result in centimetres.
   */
  resizeToLayoutWidth(dpcm: number = this.dpcm, widthCm: number = this.widthCm): void {
    const style = window.getComputedStyle(<HTMLCanvasElement>this.$stage.canvas);
    //Gets the first number from a string and rounds it to an integer. Used to grab numbers out
    //of strings like "100px".
    const pixelsToInt = (input: string | number) => {
      if (typeof input === "string") {
        const match = /[\d]+(?:\.[\d]*)?/.exec(<string>input);
        if (match == null || match.length === 0) {
          throw Error(`"${input}" doesn't contain a number`);
        }
        input = parseFloat(match[0]);
      }
      return Math.round(<number>input);
    };
    const currentWidth = pixelsToInt(style.width);
    const currentHeight = currentWidth * this.aspectRatio;
    this.resize(currentWidth, currentHeight, dpcm, widthCm);
  }

  /**
   * Resizes the number of pixels to get the desired image quality.
   * @param dpcm Required dots per cm.
   * @param width Required width of the image in cm.
   */
  resizeToQuality(dpcm: number = this.dpcm, widthCm: number = this.widthCm) {
    //Resize to a width that gets us the desired pixels per cm density,
    //given a printed width of desiredWidthCm.
    const desiredWidthPx = widthCm * dpcm;
    this.resize(desiredWidthPx, desiredWidthPx * this.aspectRatio);
  };

  /** Takes a snapshot of the exact contents of the canvas. This will include any scratch layer
   * content if not cleared first. */
  getSnapshot(format: "jpg" | "png" = "png", dpcm: number = this.dpcm, widthCm: number = this.widthCm): string {
    const view = this.createRestorePoint();
    try {
      this.resizeToQuality(dpcm, widthCm);
      this.panZoom.reset();
      this.update(true);
      const canvas = <HTMLCanvasElement>this.$stage.canvas;
      return canvas.toDataURL(format).replace(/^data:image\/(png|jpg);base64,/, "");
    } finally {
      view();
      this.update(true);
    }
  };

  getSnapshotAndOpenInNewTab(format: "jpg" | "png" = "png", dpcm: number = this.dpcm, widthCm: number = this.widthCm) {
    window.open(`data:image/${format};base64,${this.getSnapshot(format, dpcm, widthCm)}`, "_blank");
  }

  /** Returns a function which reverts the view to how the user was seeing it when this
   * function was called. */
  createRestorePoint(): () => void {
    const scale = this.panZoom.scale.clone();
    const pos = this.panZoom.pos.clone();
    const canvas = <HTMLCanvasElement>this.$stage.canvas;
    const width = canvas.width;
    const height = canvas.height;
    return () => {
      this.panZoom.goto(pos, scale);
      this.resize(width, height);
      this.update();
    };
  }

  /** Causes the stage to update, and tracks the current pan and zoom position.
   * @param immediately By default the update is delayed until a later digest, thereby
   * condensing multiple upateStage() calls into a single update for performance. If this
   * argument is true, the update happens immediately (before this function returns),
   * flushing any pending updates.
   */
  update(immediately?: boolean): void {
    const ul = this.user;
    const pz = this.panZoom;
    ul.scaleX = pz.scale.x;
    ul.scaleY = pz.scale.y;
    pz.pos.writeTo(ul);

    if (immediately) {
      this.$updateThrottle.flush(Unit.instance);
    } else {
      this.$updateThrottle.onNext(Unit.instance);
    }
  }

  /** Logs the display object tree, rooted at the stage. */
  logStage(): void {
    function print(obj: createjs.DisplayObject): void {
      console.group(obj.name);
      console.log(obj.name, { x: obj.x, y: obj.y, scaleX: obj.scaleX, scaleY: obj.scaleY, object: obj });
      if (obj["children"] != null) {
        for (let container of obj["children"]) {
          print(container);
        }
      }
      console.groupEnd();
    }
    print(this.$stage);
  };

  /** Overrides Traceable.setTracing() to also allow enabling or disable event tracing for the
   * pan-zoom object it contains. Log functions are also passed to the pan-zoom object. */
  setTracing(options: Traceable.Options & { "displayContext.panZoom.traceEvents"?: boolean }): void {
    super.setTracing(options);
    if ("displayContext.panZoom.traceEvents" in options) {
      var panZoomTracing = {
        trace: options.trace,
        "panZoom.traceEvents": options["displayContext.panZoom.traceEvents"]
      };
      this.panZoom.setTracing(panZoomTracing);
    }
  }

  static $inject = ["imageEditor.stage"];
  constructor(private $stage: createjs.Stage) {
    super();
    this.panZoom = new PanZoom();
    //Create the layer which the user will do most of their interaction with.
    this.user = new createjs.Container();
    this.user.name = "User";
    //Set up the container to keep all drawing content which has been finished. These
    //can be removed, undone, and erased.
    this.cache = new createjs.Container();
    this.cache.name = "Cache";
    //Set up another container for items which are currently being drawn/erased.
    this.scratch = new createjs.Container();
    this.scratch.name = "Scratch";
    //Set up another container for controls with sizes which don't change with zoom.
    this.control = new createjs.Container();
    this.control.name = "Tools Control";

    this.user.addChild(this.cache);
    this.user.addChild(this.scratch);
    this.user.addChild(this.control);

    //Set up the container to hold any background content which the user hasn't added
    //themselves. This won't participate in other user events, like erase.
    this.background = new createjs.Container();
    this.background.name = "Background";
    this.background.compositeOperation = "destination-over";
    //It seems counter intuitive that we draw the background last, however we set the composite
    //operation such that it is only drawn where the existing pixels are transparent, so we
    //end up with the same effect as if we had drawn it first, plus we get the added bonus of
    //filling in the holes the eraser leaves on user shapes so the background doesn't also
    //appear erased.
    this.user.addChild(this.background);

    //Allows many stage updates to be grouped on a later digest. 15ms equates to about 60fps.
    this.$updateThrottle = new Throttle<Unit>(30, false);
    this.$updateThrottle.observe(() => {
      this.$stage.update();
      this.$trace && this.$trace("Stage updated");
    });

    this.$backImage.onload = e => {
      if (this.$easelBackBitmap != null) {
        this.background.removeChild(this.$easelBackBitmap);
      }
      this.$easelBackBitmap = new createjs.Bitmap(e.target);
      this.$easelBackBitmap.name = "Background bitmap";
      this.background.addChild(this.$easelBackBitmap);
      this.background.scaleX = this.background.scaleY = this.coordExtent / this.$backImage.width;
      this.aspectRatio = this.$backImage.height / this.$backImage.width;
      this.$trace && this.$trace("Background image loaded");
      this.update();
    };

    this.panZoom.observe("pan-zoom", () => this.update());
    this.panZoom.observe("goto-pan-zoom", () => this.update());
  }
}

/** The interface for a class constructor function, with optional injector attribute. */
export interface InjectableConstructor<T> {
  /** The injection list for this constructor. */
  $inject: string[];
  /** The actual constructor function. */
  new (...args): T;
}

export interface ImageEditorController extends angular.IController {}
export class ImageEditorController extends Traceable {
  stage: createjs.Stage;
  context: DisplayContext;
  history: HistoryManager;
  tools: ToolManager;
  input: InputManager;
  /** Allows centralised control of tracing and debugging for the image editor and it's managers.
   * Calling debug.enableAll() will enable detailed trace output for all managers, or individual
   * managers can be configured using debug.set("name" or /regex/, { options }).*/
  debug: Debug;
  /** News up a class from a constructor which an injection list. The class can request image
   * editor specific dependencies such as 'imageEditor.input', 'imageEditor.stage', etc.
   * @param ctor The constructor to new up.
   * @param addAsInjectable If provided, the new instance will be added to the local injector
   * under the provided name.
   * @param extraLocals If provided, these are temporarily added to the injector locals from this
   * class before this ctor is constructed.
   */
  $injectorInstantiate: <TConstructed>(ctor: InjectableConstructor<TConstructed>, addAsInjectable?: string, extraLocals?: object) => TConstructed;

  static $inject = ["$scope", "$element", "$interval", "patternService", "$injector"];
  constructor($scope: IImageEditorScope, $element: angular.IAugmentedJQuery, $interval: angular.IIntervalService, patterns: PatternService, $injector: angular.auto.IInjectorService) {
    super();
    const self = $scope["$controller"] = this;
    if ($scope.stageBackgroundColour == null) {
      $scope.stageBackgroundColour = "transparent";
    }
    $scope.toolList = [];
    $scope.canvasStyle = {
      get cursor() {
        let cursor = self.context.cursor;
        if (cursor == null) {
          if (self.context.panZoom.isActive) {
            cursor = "move";
          } else if ($scope.editShape != null) {
            cursor = "pointer";
          } else {
            cursor = "crosshair";
          }
        }
        return cursor;
      }
    };

    //Set up the stage for all of the tools to draw on.
    const canvasElement = <HTMLCanvasElement>$element[0].querySelector("canvas");
    this.stage = new createjs.Stage(canvasElement);
    this.stage.snapToPixel = this.stage.snapToPixelEnabled = false;
    this.stage.enableDOMEvents(true);
    this.stage.name = "Stage";

    /** Defines local overrides for the injector. */
    const injectorLocals: { [serviceName: string]: any } = {
      "$scope": $scope,
      "imageEditor": this,
      "imageEditor.stage": this.stage,
      "imageEditor.canvas": canvasElement
    };
    /** Instantiates a class with an injectable attribute.
     * @param ctor The class definition.
     * @param name If provided, the result is added to the injectorLocals object under this name.
     * @param extraLocals If provided, these are temporarily added to the injector locals from this
     * class before this ctor is constructed. */
    this.$injectorInstantiate = <TConstructed>(ctor: InjectableConstructor<TConstructed>, name?: string, extraLocals?: object): TConstructed => {
      let locals = extraLocals ? { ...injectorLocals, ...extraLocals } : injectorLocals;
      const constructed = <TConstructed>$injector.instantiate(ctor, locals);
      if (name) {
        injectorLocals[name] = constructed;
      }
      return constructed;
    };

    this.context = this.$injectorInstantiate(DisplayContext, "imageEditor.displayContext");
    this.stage.addChild(this.context.user);
    patterns.dotsPerCm = this.context.dpcm;
    patterns.pageWidthCm = this.context.widthCm;
    patterns.coordExtent = this.context.coordExtent;

    this.input = this.$injectorInstantiate(InputManager, "imageEditor.input");
    $scope.$watch((scope: IImageEditorScope) => scope.editShape, shape => this.input.select(shape));
    this.input.observe("select", shape => {
      //Set all of the shape properties over the config object. This relies on the event
      //notification order being stable so it happens before any other observers. This is true
      //for the Events class in utils.
      const toolConfig = $scope.toolConfig;
      for (let k of Object.keys(shape)) if (toolConfig.hasOwnProperty(k)) {
        toolConfig[k] = shape[k];
      }
      $scope.editShape = shape;
      this.tools.activateForShape(shape);
    });
    this.input.observe("deselect", () => $scope.editShape = null);

    this.tools = this.$injectorInstantiate(ToolManager, "imageEditor.tools");
    this.history = this.$injectorInstantiate(HistoryManager, "imageEditor.history");
    this.debug = new Debug(this, this.context, this.input, this.tools, this.history);

    $scope.getImageFunction = (format?: "jpg" | "png", dpcm?: number, widthCm?: number) => {
      // Force the current tool to cancel any ongoing operation before taking the snapshot. This
      // will ensure there's no scratch content or controls on the resulting image.
      this.tools.reactivate();
      return this.context.getSnapshot(format, dpcm, widthCm);
    }

    this.input.observe("start-pan-zoom", p => this.context.panZoom.startPanZoom(p));
    this.input.observe("pan-zooming", pz => this.context.panZoom.panZooming(pz.centroid, pz.scale));
    this.input.observe("end-pan-zoom", () => this.context.panZoom.endPanZoom());
    this.input.observe("cancel-pan-zoom", () => this.context.panZoom.cancelPanZoom());
    this.input.observe("zoom-or-reset", p => this.context.zoomOrReset(p));

    $scope.$watch((s: IImageEditorScope) => s.activeToolName, tool => this.tools.activate(tool));
    this.tools.observe("activated", tool => {
      $scope.activeToolName = tool.id;
      if (typeof tool.isValid !== "function" || !tool.isValid($scope.editShape)) {
        $scope.editShape = null;
      }
    });
    this.tools.observe("deactivated", _tool => $scope.activeToolName = null);
    this.tools.observe("registered", tool => {
      if (!angular.isArray($scope.toolList)) {
        $scope.toolList = [];
      }
      $scope.toolList.push(tool.id);
      if (this.tools.activeTool == null) {
        this.tools.activate(tool);
      }
    });
    this.tools.observe("removed", tool => {
      if (angular.isArray($scope.toolList)) {
        const index = $scope.toolList.indexOf(tool.id);
        if (index >= 0) {
          $scope.toolList.splice(index, 1);
        }
      }
    });

    this.history.observe("history-item-removed", shape => {
      if (shape != null && shape === $scope.editShape) {
        this.input.deselect();
      }
    });
    let isInitialChange = true;
    this.history.observe("history-list-changed", () => {
       if (isInitialChange) {
         isInitialChange = false;
       } else {
         $scope.isDirty = true;
       }
    });
    this.history.observe("history-item-changed", () => $scope.isDirty = true);

    //Fit the canvas periodically when autoResize is specified.
    let autoResizeSubscription = null;
    $scope.$watch((scope: IImageEditorScope) => scope.autoResize,
      (newValue: number) => {
        let interval = angular.isNumber(newValue) && !isNaN(newValue) ? newValue : 1000;
        interval = interval <= 0 ? 1000 : interval;
        if (autoResizeSubscription != null) {
          $interval.cancel(autoResizeSubscription);
          autoResizeSubscription = null;
        }
        autoResizeSubscription = $interval(() => this.context.resizeToLayoutWidth(), interval);
      });

    this.context.resizeToLayoutWidth();

    this.input.addStandardEventListeners(canvasElement).disposeWith($scope);

    $scope.$on("$destroy", () => {
      if (autoResizeSubscription != null) {
        $interval.cancel(autoResizeSubscription);
        autoResizeSubscription = null;
      }
    });

    $scope.$watch((scope: IImageEditorScope) => scope.stageBackgroundImage,
      uri => this.context.setBackgroundImage(uri));

    this.context.update();
  }
}

/** Provides heaps of debug and tracing options for the image editor. Enable everything by running
 * `angular.element('canvas.mds-image-editor').scope().$controller.debug.enableAll()` in the chrome
 * console. There are plenty of other options for more fine grained trace output. */
class Debug extends TraceableManager {
  constructor(protected $ctrl: ImageEditorController, context: DisplayContext, input: InputManager, tools: ToolManager, history: HistoryManager) {
    super();
    this.register("controller", $ctrl);

    this.register("displayContext", context);
    this.addBooleanOption("displayContext.panZoom.traceEvents");

    this.register("inputManager", input);
    this.addBooleanOption("inputManager.traceEvents");

    this.register("toolManager", tools);
    this.addBooleanOption("toolManager.traceEvents");
    this.addBooleanOption("toolManager.tools.text.renderBoxModel");
    this.addBooleanOption("toolManager.tools.renderBoundingBoxes");
    tools.observe("registered", tool => this.register(`toolManager.tools.${tool.id}`, tool));
    tools.observe("removed", tool => this.remove(`toolManager.tools.${tool.id}`));

    this.register("historyManager", history);
    this.addBooleanOption("historyManager.traceEvents");
  }

  /** As TraceableManager.set(), but forces a recache and updates the stage. */
  set(match: string | RegExp, options: Traceable.Options | { [name: string]: any[] }): void {
    super.set(match, options);
    this.$ctrl.history.rebuildCache();
  }

  enableAll() {
    //The control manager is created and destroyed with tool activation contexts, so if we're
    //enabling all tracing, we need to add an entry to enable debugging on it whenever it's
    //registered to this debug object.
    this.enableOnRegister.push("controlManager");
    this.enableOnRegister.push("toolActivationContext");
    super.enableAll();
  }

  disableAll() {
    let i = this.enableOnRegister.indexOf("controlManager");
    if (i >= 0) {
      this.enableOnRegister.splice(i, 1);
    }
    i = this.enableOnRegister.indexOf("toolActivationContext");
    if (i >= 0) {
      this.enableOnRegister.splice(i, 1);
    }
    super.disableAll();
  }

  /** Enables or disables debug mode rending for the text tool where shapes are rendered with
   * separate colours showing padding, border, content, etc.
   * @param enable Whether to enable or disable. */
  showTextToolBoxModel(enable: boolean = true) {
    this.set("toolManager.tools.text", this.pluckOptions(enable, "toolManager.tools.text.renderBoxModel"));
  }

  /** Enables or disables debug mode rendering for all tools if their shapes provide them.
   * @param enable Whether to enable or disable. */
  showShapeBoundingBoxes(enable: boolean = true) {
    this.set(/^toolManager\.tools\..*/, this.pluckOptions(enable, "toolManager.tools.renderBoundingBoxes"));
  }

  /** Enables or disables event tracing for the input manager.
   * @param enable Whether to enable or disable event tracing. */
  traceInputEvents(enable: boolean = true) {
    this.set("inputManager", this.pluckOptions(enable, "inputManager.traceEvents"));
  }

  /** Enables or disables event tracing for the pan-zoom object on the display context.
   * @param enable Whether to enable or disable event tracing. */
  tracePanZoomEvents(enable: boolean = true) {
    this.set("displayContext", this.pluckOptions(enable, "displayContext.panZoom.traceEvents", "trace"));
  }

  /** Enables or disables event tracing for the history manager.
   * @param enable Whether to enable or disable event tracing. */
  traceHistoryEvents(enable: boolean = true) {
    this.set("historyManager", this.pluckOptions(enable, "inputManager.traceEvents"));
  }

  /** Enables or disables event tracing for the tool manager.
   * @param enable Whether to enable or disable event tracing. */
  traceToolEvents(enable: boolean = true) {
    this.set("toolManager", this.pluckOptions(enable, "inputManager.traceEvents"));
  }

  /** Logs the current stage to the console for debugging. */
  logStage() {
    this.$ctrl.context.logStage();
  }

  /** Creates a new options hash by plucking selected named options from the enable or disable
   * options.
   * @param enable Whether to pull options from the enable option hash or the disabled one.
   * @param optionNames The names of options to pluck;
   */
  protected pluckOptions(enable: boolean, ...optionNames: string[]) {
    const source = enable ? this.enableAllOptions : this.disableAllOptions;
    const result = {};
    for (var name of optionNames) if (name) {
      result[name] = source[name];
    }
    return result;
  }
}

export default [() => new ImageEditorDirective()];