/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */

export default ["$rootScope", "$state", "$window",
  function($rootScope, $state, $window) {

    const layoutScopes = [];

    const isLayoutState = function(state) {
      const layoutStateName = ".layout";
      return state.name.indexOf(layoutStateName, state.name.length - layoutStateName.length) !== -1;
    };

    const api = {

      current: null,

      requestStateChange(newState) {

        console.log(newState);
        //if we are in a layout state and the new state is empty, then just go up one state to clear
        if ((newState.state === "") && isLayoutState($state.current)) {
          $state.go("^");
          return;
        }

        let stateName = $state.current.name;
        const currentParams = $state.params;
        currentParams.layoutState = newState.state;
        currentParams.layoutArgs = newState.args;

        if (!isLayoutState($state.current)) {
          stateName = `${$state.current.name}.layout`;
        }

        return $state.go(stateName, currentParams);
      },

      registerScope(scope) { return layoutScopes.push(scope); }
    };

    $rootScope.$on("$stateChangeStart", function(event, toState, toParams, fromState, fromParams, options) {});
  //    console.log 'start', event, toState, toParams, fromState, fromParams, options

    $rootScope.$on("$stateChangeSuccess", function(event, state, params) {
  //    console.log 'state change', state, params
  //    params.layoutState = "fullScreenSlideRight"
  //    params.layoutArgs = "work"

      if (!params.layoutState && state.defaultLayout) {
        params.layoutState = state.defaultLayout.state;
        params.layoutArgs = state.defaultLayout.args;
      }
      const info = { state: params.layoutState, args: params.layoutArgs };
      for (let scope of layoutScopes) { scope.notifyStateChanged(info); }
      return api.current = info;
    });

    return api;
  }
  ];
