import { clone, isArray, pick, isFunction, assign, ObjectIterator } from "lodash";

type picker = ObjectIterator<any, boolean> | Array<string|number|boolean|Array<string|number|boolean>> | object;

/** Provides a function which can be used to save some or all of the state of an object, and return
 * another function to revert the saved state in the source when called.
 * @param source The context object which may need to be reverted.
 * @param toSave A picker argument (valid for _.pick()), or an object to revert source back to when
 * required.
 * @param reverter A function for reverting the changes. Defaults to assigning all of the saved
 * properties onto source.
 * @returns A function which will apply the revert when called. */
export default function revertible(source: object, toSave?: picker, reverter = assign) {
    if (source == null) { throw new Error("source must be defined"); }
    const saved =
      (toSave == null)
        ? clone(source)
        : (isArray(toSave)) || isFunction(toSave)
            ? pick(source, <any>toSave)
            : toSave;

    return () => reverter(source, saved);
  }