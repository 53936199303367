/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS104: Avoid inline assignments
 * DS205: Consider reworking code to avoid use of IIFEs
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
let module;
import * as angular from "angular";
import businessModelsModule, { BusinessModelService } from "../../businessModels";
import utilsModule, { cssQuery, nonWhitespaceOrNull, startsWith, denormaliseAttr, LocalContext } from "../../utility/utils";
import "./inlineEditor.css";
import mdsDateTimePicker from "./dateTimePicker";
import { IAugmentedJQuery, IParseService, ICompileService, ITimeoutService } from 'angular';

export default (module = angular.module('midas.utility.inline-editor', [
  businessModelsModule.name,
  utilsModule.name
]));


module.directive("mdsDateTimePicker", mdsDateTimePicker);

module.directive("mdsInlineEditor", ["$timeout", ($timeout: ITimeoutService) =>
  ({
    restrict: "E",
    transclude: true,
    replace: true,
    templateUrl: require("./inlineEditor.html"),
    scope: true,

    link(scope, element, attrs, noController, transclude) {
      let editSpan: IAugmentedJQuery = <any>cssQuery(element, ".edit"); //The span which contains edit mode controls.
      if (editSpan == null) { throw new Error("mdsInlineEditor must contain a child with the 'edit' class"); }
      editSpan = angular.element(editSpan);

      let focusLater = null; //If non-null, a promise which will set focus appropriately later.
      let isEditing = false; //Whether this is in edit momds-revertiblede.

      //Emits <eventName>Requested and <eventName>Done, and broadcasts <eventName> at appropriate
      //times, updating isEditing if the transition is not cancelled by an observer. Returns true if
      //this successfuly transitioned to the requested mode.
      const transitionMode = function(eventName, targetEditMode) {
        if (isEditing !== targetEditMode) {
          const result = scope.$emit(`${eventName}Requested`);
          if (!result.defaultPrevented) {
            scope.editor.cancelFocusDefault();
            scope.$broadcast(eventName);
            scope.$emit(`${eventName}Done`);
            true;
          }
        }
        return false;
      };

      scope.editor = {};

      //Asks this directive to focus the first editable element within its edit section after the
      //provided delay (default 25ms). Calling cancelFocusDefault() within that time will cancel.
      scope.editor.focusDefault = function(delay = 25) {
        if ((focusLater == null)) {
          focusLater = $timeout((function() {
            const focusable = <HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement | HTMLButtonElement>
              cssQuery(editSpan, "input,textarea,select,button");
            if (focusable != null) { focusable.focus(); }
            return focusLater = null;}), delay);
        }
        return focusLater;
      };

      //Cancels a previously started focusDefault() action if it hasn't executed already.
      scope.editor.cancelFocusDefault = function() {
        if (focusLater != null) {
          $timeout.cancel(focusLater);
          focusLater = null;
          true;
        }
        return false;
      };

      //Asks this directive to enter edit mode.
      scope.editor.beginEdit = () => transitionMode("beginEdit", true);
      //Asks this directive to accept any edits and transition to non-editing mode.
      scope.editor.acceptEdit = () => transitionMode("acceptEdit", false);
      //Asks this directive to cancel/revert any edits and transition to non-editing mode.
      scope.editor.cancelEdit = () => transitionMode("cancelEdit", false);

      //Handles the enter and escape keys on the edit part for accept/reject changes.
      scope.editor.handleFinishKeys = function(event) {
        switch (event.keyCode) {
          case 13: scope.editor.acceptEdit(); break; //Enter key
          case 27: scope.editor.cancelEdit(); break; //Escape key
        }
      };

      //Handles the enter and space keys on the view part to enter edit mode.
      scope.editor.handleBeginKeys = function(event) {
        switch (event.keyCode) {
          case 13: case 32: scope.editor.beginEdit(); break; //Begin edit on enter or space key on read part.
        }
      };

      scope.$on("beginEdit", () => isEditing = true);
      scope.$on("acceptEdit", () => isEditing = false);
      scope.$on("cancelEdit", () => isEditing = false);
      //Cancel timers in case dudes are watching for it to finish.
      scope.$on("$destroy", () => scope.editor.cancelFocusDefault());

      //Define readonly getters for the view text which the control will display in view mode, and
      //the isEditing state.
      Object.defineProperties(scope.editor, {
        viewText: { get() {
          let left;
          return (left = nonWhitespaceOrNull(attrs.viewText)) != null ? left : ".....";
        }
      },
        isEditing: { get() { return isEditing; }
      }
      }
      );

      transclude(scope, function(clone) {
        editSpan.append(clone);
      });

    }
  })
]);

// Provides the ability to revert changes made to an input back to an earlier value in response to
// events sent by other directives. In its default mode all changes are isolated from the bound
// model and are only written when the accept edit event is received. If mds-revertible="live-edit"
// is used then changes made to the input are reflected immediately in the bound model, but are set
// back to the original value at the beginning of the edit when the cancel event is received.
// This directive listens for broadcast '(begin/accept/cancel)Edit' events to determine when and how
// to accept or reject changes.
module.directive("mdsRevertible", ["$parse", "businessModels",
  function($parse: IParseService, models: BusinessModelService) {
    const tmpModelName = "_tempModel";
    return {
      restrict: 'A',
      require: "ngModel",
      //This directive must run above priority 100 so that we can do a quick swap on the ng-model
      //attribute value with our own internally scoped temporary model before anything realises.
      //The attributes parser runs at priority 100, so if we get in before that, nothing realises
      //we switched the goods.
      priority: 200,
      scope: true,
      //As per the priority comment, we need to get in before the attributes and ngModel are
      //compiled, so we need to do so in our own compile function.
      compile(_, attrs) {
        const modelName = attrs.ngModel;
        const modelExpression = $parse(modelName);
        //Accept no attribute, 'auto-save', or 'auto-save="<bool expression>"'.
        const autoSave = $parse(attrs.autoSave === "" ? "true" : (attrs.autoSave != null ? attrs.autoSave : "false"));
        let isEditing = false;
        const trySetEditing = function(editOrNot) {
          if (editOrNot === isEditing) { return false; }
          isEditing = editOrNot;
          return true;
        };
        const bindEvents = function(scope, begin, accept, cancel) {
          scope.$on("beginEdit", begin);
          scope.$on("acceptEdit", accept);
          return scope.$on("cancelEdit", cancel);
        };

        const setValue = function(scope, value) {
          if (autoSave(scope)) {
            models.save(() => modelExpression.assign(scope, value));
          } else {
            modelExpression.assign(scope, value);
          }
        };

        //In live edit mode we let the input bind directly to the ngModel expression, but we save
        //the mode state on begin edit and revert on cancel edit. This means changes show up in
        //other places in the system as they're made. It doesn't necessarily play well with
        //auto-save if there is more than one edit to the same editor queued, as earlier saves may
        //be overriden on cancel.
        if (attrs.mdsRevertible.toLowerCase() === "live-edit") {
          return function(scope) {
            let pristineModel = null;
            return bindEvents(scope, //scope, begin, accept, cancel
              function() { if (trySetEditing(true)) { pristineModel = modelExpression(scope); } },
              function() { if (trySetEditing(false)) { setValue(scope, modelExpression(scope)); } },
              function() { if (trySetEditing(false)) { setValue(scope, pristineModel); } });
          };

        //In default mode we change the ngModel binding to a variable on the local scope so that
        //changes are isolated from the bound model until they're accepted. The original binding is
        //preserved on 'ng-model-original' so that others can still check it if desired. The
        //isolation of the model variable means that this mode nicely best with auto-save.
        } else {
          if (attrs.ngModelOriginal != null) { throw new Error("ng-model-original already defined on element"); }
          attrs.$set("ngModelOriginal", modelName);
          attrs.$set("ngModel", tmpModelName);

          return scope =>
            bindEvents(scope, //scope, begin, accept, cancel
              function() { if (trySetEditing(true)) { scope[tmpModelName] = modelExpression(scope); } },
              function() { if (trySetEditing(false)) { setValue(scope, scope[tmpModelName]); } },
              function() { trySetEditing(false); })
          ;
        }
      }
    };
  }
]);

(function() {
  const getDirectiveName = eventName => `mdsOn${eventName[0].toUpperCase()}${eventName.slice(1)}`;

  const events = [
    "beginEditRequested",
    "beginEdit",
    "beginEditDone",
    "acceptEditRequested",
    "acceptEdit",
    "acceptEditDone",
    "cancelEditRequested",
    "cancelEdit",
    "cancelEditDone"
  ];

  // Registers a directive with angular for the provided scope event name. The resulting directive
  // takes the form
  // mds-on-<event-name>="<expression to execute on event>"
  const registerDirective = (directiveName, events) =>
    module.directive(directiveName, ["$parse",
      ($parse) =>
        ({
          restrict: "A",
          link(scope, element, attrs) {
            const expression = $parse(attrs[directiveName]);
            return events.map((eventName) =>
              scope.$on(eventName, event => expression(scope, new LocalContext(event, element))));
          }
        })
      
    ])
  ;

  //eg:  mds-on-begin-edit-requested="expression to execute"
  for (let event of events) { registerDirective(getDirectiveName(event), [event]); }
  //eg:  mds-on-any-edit-event="expression to execute when any editor event fires"
  return registerDirective(getDirectiveName("anyEditEvent"), events);
})();

//Transcludes the default bootstrap buttons which work with the inline editor directive. Handy to
//avoid writing them yourself every time. It accepts can-accept="" and can-cancel="" to control
//disabled status of the buttons. Other methods of accept and cancel such as keyboard or lose focus
//will have to be dealt with elsewhere, these only effect these buttons.
module.directive("mdsEditorDefaultButtons", [ "$parse", ($parse: IParseService) =>
  ({
    restrict: "AEC",
    transclude: true,
    replace: true,
    templateUrl: require("./editorDefaultButtons.html"),
    scope: true,
    link(scope, element, attrs) {
      scope.canAccept = () => true;
      scope.canCancel = () => true;

      return (() => {
        const result = [];
        for (let attr of ["canAccept", "canCancel"]) {
          if (attrs[attr] != null) {
            var expr = $parse(attrs[attr]);
            result.push(scope[attr] = () => expr(scope));
          }
        }
        return result;
      })();
    }
  })

]);


//An instance of the inline editor which contains a single input, plus the default bootstrap editor
//buttons. All input related attributes are copied onto the input when defined on this directive,
//and any other attributes are copied to the mds-inline-editor which replaces this.
module.directive("mdsInputEditor", ["$compile", "$parse", function($compile: ICompileService, $parse: IParseService) {
  //Attributes to pass from the mds-input-editor directly onto the input.
  const inputAttrs = {
    accept: "accept",
    align: "align",
    alt: "alt",
    autocomplete: "autocomplete",
    autofocus: "autofocus",
    checked: "checked",
    disabled: "disabled",
    form: "form",
    formaction: "formaction",
    formenctype: "formenctype",
    formmethod: "formmethod",
    formnovalidate: "formnovalidate",
    formtarget: "formtarget",
    height: "height",
    list: "list",
    max: "max",
    mdsRevertible: "mds-revertible",
    autoSave: "auto-save",
    min: "min",
    multiple: "multiple",
    ngChange: "ng-change",
    ngFalseValue: "ng-false-value",
    ngMaxlength: "ng-maxlen",
    ngMinlength: "ng-minlength",
    ngModel: "ng-model",
    ngPattern: "ng-pattern",
    ngRequired: "ng-required",
    ngTrim: "ng-trim",
    ngTrueValue: "ng-true-value",
    ngValue: "ng-value",
    pattern: "pattern",
    placeholder: "placeholder",
    readonly: "readonly",
    required: "required",
    size: "size",
    src: "src",
    step: "step",
    type: "type",
    value: "value",
    width: "width",
    zValidate: "z-validate"
  };

  const templateString = `<mds-inline-editor> \
<input mds-revertible/> \
<mds-editor-default-buttons /> \
</mds-inline-editor>`;

  return {
    restrict: "E",
    //We want to get in really early and prevent any other directives from running. We're going to
    //move attributes around and generally cut things up ourselves and compile it all back together
    //later. Hence high priority and terminal set to yes.
    priority: 1000,
    terminal: true,
    link(scope, element, attrs) {
      if (attrs.ngModel == null) { throw new Error("ng-model attribute is required by mdsInputEditor"); }

      //Get the whole template we'll replace this directive with.
      const template = angular.element(templateString);
      //Get the editor directive element in the above template.
      const editor = template;
      //Get the sole input in the above template.
      const input = angular.element(cssQuery(editor, "input"));
      //Lazily find the buttons only if we need to.
      let buttons = null;

      //Handles setting up the buttons to disable, and also event handlers to prevent the
      //appropriate state transition when either can-accept or can-cancel attributes are found.
      const canAcceptOrCancel = function(acceptOrCancel, value) {
        if (buttons == null) { buttons = angular.element(cssQuery(template, "mds-editor-default-buttons")); }
        buttons.attr(`can-${acceptOrCancel}`, value);
        return editor.attr(`mds-on-${acceptOrCancel}-edit-requested`,
                      `!(${value}) ? $event.preventDefault() : null`);
      };

      //Copy the input specific attributes over to the input, others to the editor. If canAccept
      //#or canCancel are present forward them to mds-editor-default-buttons, and also add event
      //handles to prevent the associated transitions when the check fails.
      for (let key in attrs) {
        const value = attrs[key];
        if (!startsWith(key, "$")) {
          if (inputAttrs.hasOwnProperty(key)) {
            input.attr(inputAttrs[key], value);
          } else if (key === "canAccept") {
            canAcceptOrCancel("accept", value);
          } else if (key === "canCancel") {
            canAcceptOrCancel("cancel", value);
          } else if (/^input[A-Z]/.test(key)) {
            input.attr(denormaliseAttr(key.slice(5)), value);
          } else {
            editor.attr(denormaliseAttr(key), value);
          }
        }
      }

      //If view-text attribute wasn't specified then default to ng-model. When the result of
      //ngModel expression is null or empty then use the placeholder text on the input. Otherwise
      //let the  mds-inline-editor work it out. We only create a new child scope when we need to
      //here, and if we do then we compile with it later.
      if ((attrs.viewText == null)) {
        const childScope = scope.$new();
        const model = $parse(attrs.ngModel);
        childScope._$defaultViewText = function() {
          //Execute against parent scope since that was the users intent.
          let left;
          return (left = nonWhitespaceOrNull(model(scope))) != null ? left : input.attr("placeholder");
        };
        editor.attr("view-text", "{{_$defaultViewText()}}");
        template.on("$destroy", () => childScope.$destroy());
        scope = childScope;
      }

      //Note that we're not actually compiling the element this directive was defined on, just the
      //template we've created here. We then replace this element with the compiled template, so
      //the element this directive is originally defined on doesn't exist in the final result!
      const compiled = $compile(template);
      element.replaceWith(template);
      compiled(scope);
    }
  };
}]);

//An instance of the inline editor which contains a select. All select related attributes are copied
//onto the select when defined on this directive, and any other attributes are copied to the
//mds-inline-editor which replaces this.
module.directive("mdsSelectEditor", ["$compile", "$parse", function($compile: ICompileService, $parse: IParseService) {
  //Attributes to pass from the mds-select-editor directly onto the select.
  const selectAttrs = {
    autofocus: "autofocus",
    disabled: "disabled",
    form: "form",
    formaction: "formaction",
    formenctype: "formenctype",
    formmethod: "formmethod",
    formnovalidate: "formnovalidate",
    formtarget: "formtarget",
    mdsRevertible: "mds-revertible",
    autoSave: "auto-save",
    name: "name",
    ngBlur: "ng-blur",
    ngChange: "ng-change",
    ngFocus: "ng-focus",
    ngModel: "ng-model",
    ngOptions: "ng-options",
    ngRequired: "ng-required",
    readonly: "readonly",
    required: "required",
    zValidate: "z-validate"
  };

  var templateString = `<mds-inline-editor> \
    <select mds-revertible ng-change="editor.acceptEdit()"> \
    <option value="" ng-if="_$showBlankOption"></option> \
    </select> \
    </mds-inline-editor>`;

  return {
    restrict: "E",
    //We want to get in really early and prevent any other directives from running. We're going to
    //move attributes around and generally cut things up ourselves and compile it all back together
    //later. Hence high priority and terminal set to yes.
    priority: 1000,
    terminal: true,
    link(scope, element, attrs) {
      if (attrs.ngModel == null) { throw new Error("ng-model attribute is required by mdsSelectEditor"); }

      if (attrs.hideBlankOption === "true") {
        templateString = `<mds-inline-editor> \
        <select mds-revertible ng-change="editor.acceptEdit()"> \
        </select> \
        </mds-inline-editor>`}

      //Get the whole template we'll replace this directive with.
      const template = angular.element(templateString);
      //Get the editor directive element in the above template.
      const editor = template; //angular.element utils.cssQuery(template, "mds-inline-editor")
      //Get the sole select in the above template.
      const select = angular.element(cssQuery(editor, "select"));

      //Copy the select specific attributes over to the select, any which start with select- (which
      //already have been normalised at this point) over without the prefix, and others to the
      //editor.
      for (let key in attrs) {
        const value = attrs[key];
        if (!startsWith(key, "$")) {
          if (selectAttrs.hasOwnProperty(key)) {
            select.attr(selectAttrs[key], value);
          } else if (/^select[A-Z]/.test(key)) {
            select.attr(denormaliseAttr(key.slice(6)), value);
          } else {
            editor.attr(denormaliseAttr(key), value);
          }
        }
      }

      //If view-text attribute wasn't specified then default to ng-model. When the result of
      //ngModel expression is null or empty then use the data-placeholder text on the select
      //Otherwise let the  mds-inline-editor work it out. We only create a new child scope when
      //we need to here, and if we do then we compile with it later.
      if ((attrs.viewText == null)) {
        const childScope = scope.$new();
        const model = $parse(attrs.ngModel);
        childScope._$defaultViewText = function() {
          //Execute against parent scope since that was the users intent.
          let left;
          return (left = nonWhitespaceOrNull(model(scope))) != null ? left : editor.attr("placeholder");
        };
        editor.attr("view-text", "{{_$defaultViewText()}}");
        template.on("$destroy", () => childScope.$destroy());
        scope = childScope;
      }

      scope._$showBlankOption = true;

      //Note that we're not actually compiling the element this directive was defined on, just the
      //template we've created here. We then replace this element with the compiled template, so
      //the element this directive is originally defined on doesn't exist in the final result!
      const compiled = $compile(template);
      element.replaceWith(template);
      compiled(scope);
    }
  };
}]);
