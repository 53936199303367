import { module, IScope, IAttributes, ITranscludeFunction } from "angular";
import { ExamType, Exam } from "../../../businessModels";
import serviceModule, { MeasurementLayoutService, serviceName } from "./mds-measurement-layout.service";
import { MeasurementUIElement } from "./mds-measurement-layout";

interface ICustomLayoutProviderScope extends IScope {
  exam: Exam | ExamType;
  $layout: MeasurementUIElement[];
}

/** Directive that provides custom layout model onto scope as $layout, given exam or exam type. */
const directoryFactory = (layoutService: MeasurementLayoutService) => {
  return {
    transclude: true,
    link: (
      $scope: ICustomLayoutProviderScope,
      element: JQuery,
      _attrs: IAttributes,
      _controllers: any,
      transclude: ITranscludeFunction) => {
      const examType = $scope.exam instanceof ExamType ? $scope.exam : $scope.exam.type;
      layoutService.get(examType).then(x => {
        $scope.$layout = x;
        transclude($scope, (child) => {
          element.append(child);
        });
      });
    },
    scope: {
      exam: "<",
      measurementVariant: "@?", //readonly
      options: "=?"
    }
  };
};
directoryFactory.$inject = [serviceName];

export default module("midas.utility.measurement.layout.measurementLayoutProvider", [serviceModule.name])
  .directive("mdsCustomLayoutProvider", directoryFactory);