import ReferenceGroup from "./ReferenceGroup";
import { IQService } from "angular";
import { Study } from "../../../businessModels";
import * as thumbnailUrl from "../templates/previewReportThumbnail.html";
import * as fullUrl from "../templates/previewReportFull.html";

export default class FinalReportGroup extends ReferenceGroup {
    constructor(public study: Study, private $q: IQService) {
        super("Preview", thumbnailUrl, fullUrl);

        this.iconClass = "fa fa-file-text";
    }

    // It's a bit of a hack that we're assigning a study to something called 'thumbnails'. I'd like to change the name of this method and 
    // some stuff on the scope but my typescript tools are a bit messed up right now whilst Microsoft gets Visual Studio working cleanly with typescript.
    // So I'll leave it like it is right now and next time I change this area I'll clean it up a bit
    loadThumbnails() {
        this.thumbnails = [this.study];
        return this.$q.when(this.thumbnails);
    }
}