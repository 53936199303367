import { module as ngModule } from "angular";
import { Editor } from "tinymce";
import reportEditorModule, {
    TinymceConfigurationController,
    tinymceConfigurationRequire
} from "../mds-rich-text-editor.component";

/** Forces the focus into the editor as it initialises. */
class AutoFocusPlugin extends TinymceConfigurationController {
    init_instance_callback(editor: Editor) {
        // This will trick the editor into thinking it was focused
        // without actually focusing it (causing the toolbar to appear). We
        // don't want to fire it when in readonly mode since we don't want a
        // toolbar then.
        editor.fire("focus");
    }
}

export default ngModule("midas.utility.tinymce.auto-focus-plugin", [reportEditorModule.name])
.component("autoFocus", {
    require: tinymceConfigurationRequire,
    controller: AutoFocusPlugin
});