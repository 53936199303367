/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */

export default ["$scope", "loadingStatus", "businessModels",
  function($scope, loadingStatus, models) {
  
    $scope.status = new loadingStatus();
    
    $scope.emailPattern = /^([a-zA-Z0-9])+([a-zA-Z0-9._%+-])+@([a-zA-Z0-9_.-])+\.(([a-zA-Z]){2,6})$/;
    $scope.isValidEmail = function() {
      return $scope.emailPattern.test($scope.user.email);
    }

    $scope.onEmailChange = function() {  
      $scope.userDetails.$setValidity($scope.userDetail, $scope.isValidEmail());
    };

    models.save.status
      .onChanged(status => $scope.isBusy = status.isLoading)
      .disposeWith($scope);

    $scope.title = "My Details";
    $scope.user = models.User.current;

    $scope.save = function() {
      const saving = models.save((function() {}), false, false);
      $scope.status.andTrack(saving);
      return saving.then(() => $scope.userDetails.$setPristine());
    };

    return $scope.$on("$destroy", () => models.breeze.rejectChanges());
  }
];
