import ReferenceGroup from "./ReferenceGroup";
import "../templates/image.css";
import { Study, BusinessModelService } from "../../../businessModels";
import { LoDashStatic } from "lodash";
import * as thumbnailUrl from "../templates/imageThumbnail.html";
import * as fullUrl from "../templates/imageFull.html";

export default class ImageGroup extends ReferenceGroup {
    iconClass = "glyphicon glyphicon-picture";
    orderBy = "createdAt";

    constructor(
        public readonly study: Study,
        private readonly businessModels: BusinessModelService,
        private readonly lodash: LoDashStatic) {
        super("Images", thumbnailUrl, fullUrl);
    }

    loadThumbnails() {
        return this.businessModels.Study.find({ id: this.study.id }, "exams.imageLinks.image")
            .then(() => {
                this.thumbnails = this.lodash(this.study.exams)
                    .pluck("images")
                    .flatten()
                    .sortBy("createdAt")
                    .value();
                return this.thumbnails;
            });
    }
}