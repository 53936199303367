import { BindingOf } from "../../../utility/componentBindings";
import { PatientDuplicate as Duplicate } from "./duplicateDetection.service";
import "./duplicateLists.scss";

interface Bindings {
    /** Fired when the user clicks view on an item.  */
    onViewItem: (locals: { duplicate: Duplicate, $event: MouseEvent }) => void;
    /** Fired when the user clicks the duplicate button.  */
    onMarkDuplicate: (locals: { duplicate: Duplicate, $event: MouseEvent }) => void;
    /** The duplicate patients. */
    duplicates: Duplicate[]
}

interface DuplicatePatientListComponent extends angular.IController, Bindings {}
class DuplicatePatientListComponent implements angular.IController, Bindings {}

export default <angular.IComponentOptions>{
    controller: DuplicatePatientListComponent,
    bindings: <BindingOf<Bindings>>{
        onViewItem: "&",
        onMarkDuplicate: "&?",
        duplicates: "<"
    },
    templateUrl: require("./duplicatePatientList.component.html")
};