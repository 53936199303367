/**
 * Any object which looks like a point or a vector.
 *
 * @export
 * @interface IPointLike
 */
export interface IPointLike {
  x: number;
  y: number;
}

const clamp = function (v: number, min ? : number, max ? : number) {
  if ((min != null) && v < min) {
    return min;
  } else if ((max != null) && v > max) {
    return max;
  } else {
    return v;
  }
};

/** A 2 dimensional vector or point. */
export class Vector2 implements IPointLike {
  /** Value in the x dimension. */
  x: number;
  /** Value in the y dimension. */
  y: number;

  /** * Creates a new vector at the origin. */
  constructor();
  /**
   * Creates a new vector at (xAnyY, xAndY).
   * @param xAndY Value assigned to both x and y.
   */
  constructor(xAndY: number);
  /**
   * Clones a point shaped object into a new Vector2.
   * @param vec Object who's x and y properties are copied to the new vector.
   */
  constructor(vec: IPointLike);
  /**
   * Creates a new Vector2 from 2 points.
   * @param x Value assigned to this.x
   * @param y Value assigned to this.y
   */
  constructor(x: number, y: number);
  constructor(xOrVec?: number | IPointLike, y?: number);
  constructor(xOrVec ? , y ? ) {
    switch (arguments.length) {
      case 0:
        this.x = this.y = 0;
        break;
      case 1:
        if (typeof xOrVec === "number") {
          this.x = xOrVec;
          this.y = xOrVec;
        } else {
          this.x = xOrVec.x;
          this.y = xOrVec.y;
        }
        break;
      case 2:
        this.x = xOrVec;
        this.y = y;
        break;
      default:
        throw new Error("Too many arguments for Vector2 ctor, expected 2 or less");
    }
  }

  /** Copies the current value of this vector into a new instance. */
  clone() {
    return new Vector2(this.x, this.y);
  }

  /**
   * Translate a Vector2 in place.
   *
   * @param xAndY Amount to translate both dimensions.
   * @returns The modified calling instance.
   */
  add(xAndY: number): this;
  /**
   * Translate a Vector2 in place.
   *
   * @param x Amount to translate the x dimension.
   * * @param y Amount to translate the y dimension.
   * @returns The modified calling instance.
   */
  add(x: number, y: number): this;
  /**
   * Translate a Vector2 in place.
   *
   * @param vec Object whose x and y values are added to this vectors x and y values.
   * @returns The modified calling instance.
   */
  add(vec: IPointLike): this;
  add(xOrVec: number | IPointLike, y ? : number): this;
  add(xOrVec: number | IPointLike, y ? : number): this {
    switch (arguments.length) {
      case 1:
        if (typeof xOrVec === "number") {
          this.x += xOrVec;
          this.y += xOrVec;
        } else {
          this.x += xOrVec.x;
          this.y += xOrVec.y;
        }
        break;
      case 2:
        this.x += < number > xOrVec;
        this.y += y;
        break;
      default:
        throw new Error("Incorrect number of arguments for Vector2.add, expected (number) or (Vector2) or (x, y)");
    }
    return this;
  }

  /**
   * Translate a Vector2 in place.
   *
   * @param xAndY Amount to translate both dimensions.
   * @returns The modified calling instance.
   */
  subtract(xAndY: number): this;
  /**
   * Translate a Vector2 in place.
   *
   * @param x Amount to translate the x dimension.
   * * @param y Amount to translate the y dimension.
   * @returns The modified calling instance.
   */
  subtract(x: number, y: number): this;
  /**
   * Translate a Vector2 in place.
   *
   * @param vec Object whose x and y values are subtracted from this vectors x and y values.
   * @returns The modified calling instance.
   */
  subtract(vec: IPointLike): this;
  subtract(xOrVec: number | IPointLike, y ? : number): this;
  subtract(xOrVec: number | IPointLike, y ? : number): this {
    switch (arguments.length) {
      case 1:
        if (typeof xOrVec === "number") {
          this.x -= xOrVec;
          this.y -= xOrVec;
        } else {
          this.x -= xOrVec.x;
          this.y -= xOrVec.y;
        }
        break;
      case 2:
        this.x -= < number > xOrVec;
        this.y -= y;
        break;
      default:
        throw new Error("Incorrect number of arguments for Vector2.subtract, expected (number) or (Vector2) or (x, y)");
    }
    return this;
  }

  /**
   * Multiply a Vector2 in place.
   *
   * @param xAndY Value to multiply both dimensions.
   * @returns The modified calling instance.
   */
  multiply(xAndY: number): this;
  /**
   * Multiply a Vector2 in place.
   *
   * @param x Value to multiply the x dimension.
   * * @param y Value to multiply the y dimension.
   * @returns The modified calling instance.
   */
  multiply(x: number, y: number): this;
  /**
   * Multiply a Vector2 in place.
   *
   * @param vec Components of this are multiplied by the associated components of vec.
   * @returns The modified calling instance.
   */
  multiply(vec: IPointLike): this;
  multiply(xOrVec: number | IPointLike, y ? : number): this;
  multiply(xOrVec: number | IPointLike, y ? : number): this {
    switch (arguments.length) {
      case 1:
        if (typeof xOrVec === "number") {
          this.x *= xOrVec;
          this.y *= xOrVec;
        } else {
          this.x *= xOrVec.x;
          this.y *= xOrVec.y;
        }
        break;
      case 2:
        this.x *= < number > xOrVec;
        this.y *= y;
        break;
      default:
        throw new Error("Incorrect number of arguments for Vector2.multiply, expected (number) or (Vector2) or (x, y)");
    }
    return this;
  }

  /**
   * Divide a Vector2 in place.
   *
   * @param xAndY Value to divide both dimensions.
   * @returns The modified calling instance.
   *
   * @memberOf Vector2
   */
  divide(xAndY: number): this;
  /**
   * Divide a Vector2 in place.
   *
   * @param x Value to divide the x dimension.
   * * @param y Value to divide the y dimension.
   * @returns The modified calling instance.
   */
  divide(x: number, y: number): this;
  /**
   * Divide a Vector2 in place.
   *
   * @param vec Components of this are divided by the associated components of vec.
   * @returns The modified calling instance.
   */
  divide(vec: IPointLike): this;
  divide(xOrVec: number | IPointLike, y ? : number): this;
  divide(xOrVec: number | IPointLike, y ? : number): this {
    switch (arguments.length) {
      case 1:
        if (typeof xOrVec === "number") {
          this.x /= xOrVec;
          this.y /= xOrVec;
        } else {
          this.x /= xOrVec.x;
          this.y /= xOrVec.y;
        }
        break;
      case 2:
        this.x /= < number > xOrVec;
        this.y /= y;
        break;
      default:
        throw new Error("Incorrect number of arguments for Vector2.divide, expected (number) or (Vector2) or (x, y)");
    }
    return this;
  }

  /**
   * Set both dimensions to a number.
   *
   * @param xAndY Value assigned to both dimensions.
   * @returns The modified calling instance.
   */
  set(xAndY: number): this;
  /**
   * Set each dimension of this vector.
   *
   * @param x Value to assign to this.x
   * @param y Value to assign to this.y
   * @returns The modified calling instance.
   */
  set(x: number, y: number): this;
  /**
   * Set the components of this vector to equal the components of another point like object.
   *
   * @param vec Object to copy components from.
   * @returns The modified calling instance.
   */
  set(vec: IPointLike): this;
  set(xOrVec: number | IPointLike, y?: number): this;
  set(xOrVec: number | IPointLike, y?: number): this {
    switch (arguments.length) {
      case 1:
        if (typeof xOrVec === "number") {
          this.x = this.y = xOrVec;
        } else {
          this.x = xOrVec.x;
          this.y = xOrVec.y;
        }
        break;
      case 2:
        this.x = < number > xOrVec;
        this.y = y;
        break;
      default:
        throw new Error("Incorrect number of arguments for Vector2.set, expected (number) or (Vector2) or (x, y)");
    }
    return this;
  }

  /**
   * Writes the x and y values of this Vector2 into another object's x and y. Makes interfacing
   * with non Vector2 points much easier. Returns this object for consistency, not the argument.
   *
   * @param p Object to write the components of this into.
   * @returns The modified calling instance.
   */
  writeTo(p: IPointLike): this {
    if (arguments.length !== 1) {
      throw new Error("Vector2.writeTo, expected single point argument to write to");
    }
    p.x = this.x;
    p.y = this.y;
    return this;
  }

  clampX(min, max) {
    this.x = clamp(this.x, min, max);
    return this;
  }

  clampY(min, max) {
    this.y = clamp(this.y, min, max);
    return this;
  }

  /**
   * Gets whether a vector's dimensions equal the provided numbers
   * respectively. If tolerance is provided, the euclidean distance between
   * the vector and (x, y) must be less the tolerance.
   *
   * @param xAndY Checks against the point (xAndY, xAndY).
   * @param tolerance If provided, the maximum euclidean distance
   * within which they're considered equal.
   * @returns Whether the vectors are equal.
   */
  equals(x: number, y: number, tolerance ? : number): boolean;
  /**
   * Gets whether a vector equals another vector. If tolerance is provided,
   * the euclidean distance between the 2 vectors must be less than or equal
   * to the tolerance.
   *
   * @param xAndY Checks against the point (xAndY, xAndY).
   * @param tolerance If provided, the maximum euclidean distance
   * within which they're considered equal.
   * @returns Whether the vectors are equal.
   */
  equals(vec: IPointLike, tolerance ? : number): boolean;
  equals(xOrVec: number | IPointLike, y ? : number, tolerance ? : number): boolean;
  equals(xOrVec: number | IPointLike, y ? : number, tolerance ? : number): boolean {
    switch (arguments.length) {
      case 1:
        if (typeof xOrVec === "number") {
          y = xOrVec;
        } else {
          ({
            y
          } = xOrVec);
          xOrVec = xOrVec.x;
        }
        break;
      case 2:
        if (typeof xOrVec !== "number") {
          tolerance = y;
          ({
            y
          } = xOrVec);
          xOrVec = xOrVec.x;
        }
        break;
      default:
        if (arguments.length !== 3) {
          throw new Error("Incorrect number of arguments for Vector2.equals, expected (number, [tolerance]) or (Vector2, [tolerance]) or (x, y, [tolerance])");
        }
    }
    if (tolerance != null) {
      let dx = this.x - < number > xOrVec;
      let dy = this.y - y;
      return ((dx * dx) + (dy * dy)) <= (tolerance * tolerance);
    }
    return this.x === xOrVec && this.y === y;
  }

  /**
   * Gets the scalar magnitude of this vector. This is quite slow due to the square root, so use
   * sparingly.
   *
   * @returns Euclidean distance of this vector from the origin.
   */
  length() {
    return Math.sqrt((this.x * this.x) + (this.y * this.y));
  }

  /**
   * Normalises this vector so that its length is 1. In the case that this is a zero vector it
   * will remain unchanged.
   *
   * @returns Original modified vector.
   */
  normalise(): this {
    let len = Math.sqrt((this.x * this.x) + (this.y * this.y));
    if (len === 0) {
      return this;
    }
    this.x /= len;
    this.y /= len;
    return this;
  }


  /** Swaps the x and y dimensions. */
  swap(): this {
    let tmp = this.x;
    this.x = this.y;
    this.y = tmp;
    return this;
  }

  /**
   * Converts this vector into a string of the form "(x:1,y:2)", with ann
   * optional floating point precision specified.
   *
   * @param precision If provided, each dimension is rounded to
   * this precision when converted to text.
   * @returns String of the form "(x:1,y:2)"
   */
  toString(precision: number = 6): string {
    //Convert to significant digits and remove zero padding by converting back to num.
    let x: string, y: string;
    try {
      x = `${Number(this.x.toPrecision(precision))}`;
    } catch (error) {
      x = "NaN";
    }
    try {
      y = `${Number(this.y.toPrecision(precision))}`;
    } catch (error) {
      y = "NaN";
    }
    return `{x:${x},y:${y}}`;
  }

  /**
   * Applies the inverse of the scale/move transform defined by the arguments to
   * 'vec', returning a new vector.
   *
   * @param vec The point shaped thing to apply the inverse translation to.
   * @param pos The amount to translate the vector.
   * @param scale The amount to scale the vector.
   * @returns A new vector with the applied inverse transform.
   */
  static invTransform(vec: IPointLike, pos: number | IPointLike, scale: number | IPointLike): Vector2 {
    return new Vector2(vec).subtract(pos).divide(scale);
  }

  /**
   * Applies the scale/move transform defined by the arguments to 'vec', returning a new vector.
   *
   * @param vec The point shaped thing to apply the transform to
   * @param pos The amount to translate the vector.
   * @param scale The amount to scale the vector.
   * @returns A new vector with the applied inverse transform.
   */
  static transform(vec: IPointLike, pos: number | IPointLike, scale: number | IPointLike): Vector2 {
    return new Vector2(vec).multiply(scale).add(pos);
  }

  /**
   * Calculates the middle point of 1 or more points by taking the average of their dimensions.
   *
   * @param points List of points to find the centroid for.
   * @returns New vector at the centroid of the provided arguments.
   */
  static centroid(...points: IPointLike[]): Vector2 {
    switch (arguments.length) {
      case 0:
        return;
      case 1:
        return new Vector2(arguments[0]);
      case 2:
        let a = arguments[0];
        let b = arguments[1];
        return new Vector2((a.x + b.x) / 2, (a.y + b.y) / 2);
      default:
        a = new Vector2();
        for (let i = 0; i < arguments.length; i++) {
          let touch = arguments[i];
          a.x += touch.x;
          a.y += touch.y;
        }

        return a.divide(arguments.length);
    }
  }

  /**
   * Gets the euclidean distance between 2 point like objects. Always a slow
   * operation due to the square root.
   *
   * @param a The first point to measure from.
   * @param b The second point to measure to.
   * @returns The euclidean distance between a and b.
   */
  static distance(a: IPointLike, b: IPointLike): number {
    let dx = a.x - b.x;
    let dy = a.y - b.y;
    return Math.sqrt((dx * dx) + (dy * dy));
  }

  /**
   * Creates a new vector from polar coordinates.
   * @param r Radius (length) of resulting vector.
   * @param theta Angle in radians.
   */
  static fromPolar(r: number, theta: number): Vector2 {
    return new Vector2(r * Math.cos(theta), r * Math.sin(theta));
  }

  /** Check whether the euclidean distance between 2 point like objects is less than a tolerance.
   * @param a First point.
   * @param b Second point.
   * @param tolerance The euclidean distance between the two points, within which they're considered equal.
   */
  static equals(a: IPointLike, b: IPointLike, tolerance: number = 0): boolean {
    let dx = a.x - b.x;
    let dy = a.y - b.y;
    return ((dx * dx) + (dy * dy)) <= (tolerance * tolerance);
  }
}

export default Vector2;