import { module as ngModule } from "angular";
import { ReportFormatter } from "../../../businessModels";

const createOptions =
  (reportFormatter: ReportFormatter, $mdDialog: angular.material.IDialogService): angular.material.IConfirmDialog => {

  return $mdDialog
    .confirm()
    .title(`Are you sure you wish to convert this report?`)
    .textContent("The existing report format will be replaced with an empty self-service formatter.")
    .ariaLabel("Confirm self-serve conversion.")
    .clickOutsideToClose(true)
    .ok("Replace formatter with self-service")
    .cancel("Cancel");
};

export default ngModule("midas.admin.reportFormatters.confirmConversionDialog", [])
  .decorator("$mdDialog", ["$delegate", ($mdDialog: angular.material.IDialogService) => {
    $mdDialog.confirmSelfServeConversion =
      (formatter) => $mdDialog.show(createOptions(formatter, $mdDialog));
    return $mdDialog;
  }
]);

declare module "angular" {
    export namespace material {
        interface IDialogService {
            /**
             * Confirms with the user that by converting a legacy formatter, the current functionality will be lost.
             * The prompt will not perform the conversion, instead it will return a boolean to indicate acceptance.
             * @param reportFormatter The report formatter that will be converted.
             * @returns A promise of a value indicating if the user accepts the conversion.
             */
            confirmSelfServeConversion(reportFormatter: ReportFormatter): IPromise<boolean>;
        }
    }
}