import * as tinymce from "tinymce";
import {
    IAttributes,
    ICompileService,
    module as ngModule,
    IController,
    isArray,
    isString,
    element as ngElement,
    IScope,
    IQService,
    IPromise,
    ILogService,
} from "angular";
import "./mds-rich-text-editor.component.scss";
import angularTinyMceModule from "./angular-tinymce";
import stickyModule from "../../utility/layout/mdsSticky";
import { BindingOf } from "../../utility/componentBindings";

export interface ExtraTinymceSettings {
    /** https://www.tinymce.com/docs/configure/content-filtering/#forced_root_block */
    forced_root_block?: boolean | string;
    /** http://archive.tinymce.com/wiki.php/Configuration3x:inline_styles */
    inline_styles?: boolean;
    /** I'm not sure whether this is still supported? Unsure. */
    debounce?: boolean;

    /** Just handle any other options. Tinymce is fully crazy and it's very hard to find an
     * exhaustive list. */
    [key: string]: any;
}

/** The root level controller for the component which handles configuring and creating a tinymce
 * editor. The order of operation of this controller is: create this controller, all child plugins
 * compile and register themselves with this controller, run all of the registered child plugins to
 * configure the tinymce options object, compile the tinymce directive with the now configured
 * options object and add it to the DOM.*/
interface RichTextEditorController extends IController, ITinymceConfigWrapper {}
class RichTextEditorController implements IController {
    /** Child plugin directives are added to this list. */
    children: ITinymceConfigurationListeners[] = [];

    options: tinymce.Settings & ExtraTinymceSettings;

    static $inject = ["$compile", "$element", "$scope", "$q", "$log"];
    constructor(
        private readonly $compile: ICompileService,
        private readonly $element: JQuery,
        private readonly $scope: IScope,
        private readonly $q: IQService,
        private readonly $log: ILogService) { }

    /** On init is called before any child controllers. */
    $onInit() {
        this.options = {
            menubar: false,
            statusbar: false,
            inline: true,
            browser_spellcheck: true,
            invalid_elements: "font",
            formats: {
                underline: { inline: "u", exact: true }
            },
            // By default Tinymce adds inline style for underlines, but Stimulsoft
            // doesn't work with that. This forces tinymce to add <u></u> tags, which
            // Stimulsoft is happy about.
            inline_styles: false
        };
    }

    /** Runs all registered child configurations and waits for them to finish (if they return
     * promises). The result is a promise that will resolve once configuration is complete. */
    configure(): IPromise<void> {
        const options = this.options;
        const children = this.children;

        const configureChildren = (start: number) => {
            // While the configuration functions don't return promises, just process them as fast as
            // we can. If one does return a promise then wait for it to resolve before continuing
            // to process the rest of the children.
            for (let i = start; i < children.length; ++i) {
                const child = children[i];

                // if there's a setup function then set it up to be called, in order, by the tinymce
                // setup function.
                if (typeof child.setup === "function") {
                    const oldSetup = options.setup;
                    options.setup = function(editor: tinymce.Editor) {
                        if (oldSetup) {
                            oldSetup(editor);
                        }
                        child.setup(editor);
                    };
                }

                // if there's an init_instance_callback function then set it up to be called, in order,
                // by the tinymce init_instance_callback function.
                if (typeof child.init_instance_callback === "function") {
                    const old_init_instance_callback = options.init_instance_callback;
                    options.init_instance_callback = function(editor: tinymce.Editor) {
                        if (old_init_instance_callback) {
                            old_init_instance_callback(editor);
                        }
                        child.init_instance_callback(editor);
                    };
                }

                if (typeof child.configure === "function") {
                    const configureResponse = child.configure(options);
                    // console.log(`Configured editor plugin ${child.constructor["name"]}`);
                    if (configureResponse) {
                        if (i < (children.length - 1)) {
                            return this.$q.when(configureResponse).then(() => configureChildren(i + 1));
                        } else {
                            return this.$q.when(configureResponse);
                        }
                    }
                }
            }
        };

        return this.$q.when(configureChildren(0));
    }

    /** Called after the tree beneath this directive has been compiled and linked (except any
     * directives which have been delayed, like ng-include, those are compiled and linked once their
     * dependencies are available and we have no way of knowing when that might be, so don't make
     * plugins that do that). This is our chance to run all of the registered configuration children
     * and create the actual tinymce directive. */
    $postLink() {
        this.configure().then(() => {
            try {
                const editorElement = ngElement('<div ui-tinymce="$ctrl.options">');
                this.$element.append(editorElement);
                this.$compile(editorElement)(this.$scope);
            } catch (err) {
                this.$log.error("Error while initialising tinymce directive", err);
            }
        },
        // This will fire if anything in this.configure() throws.
        err => this.$log.error("Error during tinymce plugin configuration", err));
    }
}

export class ToolbarAttributes {
    constructor(public id: string, public definition: string) {
    }

    public static FromAttributes($attrs: IAttributes): ToolbarAttributes {
        const keyStart = "mceToolbar";
        let number = 1;
        const regex = /^mceToolbar\d?$/;
        for (const key in $attrs) {
          if ($attrs.hasOwnProperty(key) && regex.test(key)) {
            const value = $attrs[key];
            const num = Number(key.substring(keyStart.length));
            if (num > 0) {
                number = num;
            }
            return new ToolbarAttributes("toolbar" + number, value);
          }
        }
        return new ToolbarAttributes("toolbar" + number, null);
    }
}

export interface TinymceConfigurationController extends IController, ITinymceConfigurationListeners { }
/** Interface for controllers which register themselves with `TinymceInitialiserWrapper`, and
 * provide hooks for configuring tinymce. */
export class TinymceConfigurationController implements IController {
    /** The controller of the wrapper component which allows communication with the editor instance.
     */
    wrapper: RichTextEditorController;

    /** Adds more button definitions to the toolbar. */
    extendToolbar(toolbarId: string, toolbarDefinition: string, options: tinymce.Settings) {
      if (options[toolbarId] !== false) { // False indicates we do not want a toolbar so don't add.
          if (typeof options[toolbarId] === "string") {
              options[toolbarId] = `${options[toolbarId]} ${toolbarDefinition}`;
          } else if (isArray(options[toolbarId])) {
              options[toolbarId].push(...toolbarDefinition.split(" ").filter(x => x === ""));
          } else if (options[toolbarId] == null || options[toolbarId] === true) {
              options[toolbarId] = toolbarDefinition;
          } else {
              throw Error("Unsupported type for options.toolbar" + options[toolbarId]);
          }
      }
    }

    extendToolbarFromAttributes(toolbar: ToolbarAttributes, options: tinymce.Settings) {
        this.extendToolbar(toolbar.id, toolbar.definition, options);
    }

    /** Adds extra elements to tinymce's allowed elements. If not added here, they'll be stripped
     * by tinymce. */
    extendValidElements(elements: string | string[], options: tinymce.Settings) {
        if (elements == null) {
            return;
        }
        if (isArray(elements)) {
            elements = elements.join(",");
        }
        if (options.extended_valid_elements == null) {
            options.extended_valid_elements = elements;
        } else if (typeof options.extended_valid_elements === "string") {
            options.extended_valid_elements += "," + elements;
        } else {
            throw Error(`Expected options.extended_valid_elements to be a string but was ${typeof options.extended_valid_elements}.`);
        }
    }

    /** Add extra plugins to tinyMCE. */
    extendPlugins(options: tinymce.Settings & ExtraTinymceSettings, name: string) {
        const plugins = [] as string[];
        plugins.push(name);
        if (isString(options.plugins)) {
            plugins.push(options.plugins.toString());
        }
        if (isArray(options.plugins)) {
            options.plugins.forEach(p => plugins.push(p));
        }
        options.plugins = plugins;
    }

    /** Default controller initialisation function which registers with the nearest wrapper. */
    $onInit() {
        this.wrapper.children.push(this);
    }

    /** Default controller destruction function which removes itself from the nearest wrapper. */
    $onDestroy() {
        const registeredWith = this.wrapper.children;
        const index = registeredWith.indexOf(this);
        if (index >= 0) {
            registeredWith.splice(index, 1);
        }
    }
}

/** Low level object for receiving configuration hooks from a `ITinymceConfigWrapper` object. You
 * probably want `TinymceConfigurationController` instead, unless you're creating a super low
 * level directive or something.
 */
export interface ITinymceConfigurationListeners {
    /** Called before tinymce initialises to set up the options object. */
    configure?(options: tinymce.Settings): void | IPromise<any>;
    /** If provided, this is called in the tinymce setup function, along with any other such
     * functions from other controllers.
     * See https://www.tinymce.com/docs/configure/integration-and-setup/#setup
     */
    setup?(editor: tinymce.Editor): void;
    /** If provided, this is called in the tinymce init_instance_callback, which happens after the
     * setup callback. I believe this happens AFTER the editor is rendered.
     * See https://www.tinymce.com/docs/configure/integration-and-setup/#init_instance_callback
     */
    init_instance_callback?(editor: tinymce.Editor): void;
}

/** The low level tinymce wrapper which handles forwarding config and setup calls to registrants.
 * You pretty much never need to use this yourself, just inherit from
 * `TinymceConfigurationController` instead. */
export interface ITinymceConfigWrapper {
    children: ITinymceConfigurationListeners[];
}

interface TinymceConfigureController extends TinymceConfigurationController { }
/** Controller for the `<mce-configure>` component which adds some configuration logic to the
 * editor. */
class TinymceConfigureController extends TinymceConfigurationController {
    /** If present, this is called in the tinymce setup callback, which happens before anything is
     * rendered.
     */
    mceSetup?(args: { $editor: tinymce.Editor }): void;
    /** If present, this is called in the tinymce init_instance_callback callback, which happens
     * after the editor has been rendered. This can be useful if you need to communicate with parts
     * of the editor that may not exist before it is actually drawn.
     */
    mceInitInstanceCallback?(args: { $editor: tinymce.Editor }): void;
    /** Called before the tinymce editor is initialised to provide a chance to update the options
     * object it will be initialised with.
     */
    mceConfigure?(args: { $options: tinymce.Settings });
    /** Fires when the editor is clicked. */
    mceClick?(args: { $editor: tinymce.Editor, $event: MouseEvent } ): void;
    /** Fires when the editor is double clicked. */
    mceDblClick?(args: { $editor: tinymce.Editor, $event: MouseEvent } ): void;
    /** Fires when a mouse button is pressed down inside the editor. */
    mceMouseDown?(args: { $editor: tinymce.Editor, $event: MouseEvent } ): void;
    /** Fires when a mouse button is released inside the editor. */
    mceMouseUp?(args: { $editor: tinymce.Editor, $event: MouseEvent } ): void;
    /** Fires when the mouse is moved within the editor. */
    mceMouseMove?(args: { $editor: tinymce.Editor, $event: MouseEvent } ): void;
    /** Fires when a new element is being hovered within the editor. */
    mceMouseOver?(args: { $editor: tinymce.Editor, $event: MouseEvent } ): void;
    /** Fires when a element is no longer being hovered within the editor. */
    mceMouseOut?(args: { $editor: tinymce.Editor, $event: MouseEvent } ): void;
    /** Fires when mouse enters the editor. */
    mceMouseEnter?(args: { $editor: tinymce.Editor, $event: MouseEvent } ): void;
    /** Fires when mouse leaves the editor. */
    mceMouseLeave?(args: { $editor: tinymce.Editor, $event: MouseEvent } ): void;
    /** Fires when a key is pressed within the the editor. */
    mceKeyDown?(args: { $editor: tinymce.Editor, $event: KeyboardEvent } ): void;
    /** Fires when a key is pressed within the the editor. */
    mceKeyPress?(args: { $editor: tinymce.Editor, $event: KeyboardEvent } ): void;
    /** Fires when a key is released within the the editor. */
    mceKeyUp?(args: { $editor: tinymce.Editor, $event: KeyboardEvent } ): void;
    /** Fires when the context menu is invoked within the editor. */
    mceContextMenu?(args: { $editor: tinymce.Editor, $event: MouseEvent } ): void;
    /** Fires when a paste is done within within the the editor. */
    mcePaste?(args: { $editor: tinymce.Editor, $event: any } ): void;
    /** Fires when the editor is initialized. */
    mceInit?(args: { $editor: tinymce.Editor, $event: any } ): void;
    /** Fires when the editor is focused. */
    mceFocus?(args: { $editor: tinymce.Editor, $event: FocusEvent } ): void;
    /** Fires when the editor is blurred. */
    mceBlur?(args: { $editor: tinymce.Editor, $event: FocusEvent } ): void;
    /** Fires before contents being set to the editor. */
    mceBeforeSetContent?(args: { $editor: tinymce.Editor, $event: { content: string, selection: boolean } } ): void;
    /** Fires after contents been set to the editor. */
    mceSetContent?(args: { $editor: tinymce.Editor, $event: { content: string, selection: boolean } } ): void;
    /** Fires after the contents been extracted from the editor. */
    mceGetContent?(args: { $editor: tinymce.Editor, $event: { content: string } } ): void;
    /** Fires when the contents in the editor is being serialized. */
    mcePreProcess?(args: { $editor: tinymce.Editor, $event: { node: HTMLElement } } ): void;
    /** Fires when the contents in the editor is being serialized. */
    mcePostProcess?(args: { $editor: tinymce.Editor, $event: { content: string } } ): void;
    /** Fires when selection inside the editor is changed. */
    mceNodeChange?(args: { $editor: tinymce.Editor, $event: { element: HTMLElement, parents: HTMLElement[] } } ): void;
    /** Fires when the contents has been undo:ed to a previous state. */
    mceUndo?(args: { $editor: tinymce.Editor, $event: { level: any } } ): void;
    /** Fires when the contents has been redo:ed to a previous state. */
    mceRedo?(args: { $editor: tinymce.Editor, $event: { level: any } } ): void;
    /** Fires when undo level is added to the editor. */
    mceChange?(args: { $editor: tinymce.Editor, $event: any } ): void;
    /** Fires when editor contents is being considered dirty. */
    mceDirty?(args: { $editor: tinymce.Editor, $event: any } ): void;
    /** Fires when the editor is removed. */
    mceRemove?(args: { $editor: tinymce.Editor, $event: any } ): void;
    /** Fires after a command has been executed. */
    mceExecCommand?(args: { $editor: tinymce.Editor, $event: any } ): void;
    /** Fires when contents gets pasted into the editor. */
    mcePastePreProcess?(args: { $editor: tinymce.Editor, $event: { content: string } } ): void;
    /** Fires when contents gets pasted into the editor. */
    mcePastePostProcess?(args: { $editor: tinymce.Editor, $event: { node: HTMLElement } } ): void;

    static $inject = ["$attrs"];
    constructor(private readonly attrs: IAttributes) {
        super();
    }

    configure(options: tinymce.Settings & ExtraTinymceSettings) {
        this.extendToolbarFromAttributes(ToolbarAttributes.FromAttributes(this.attrs), options);
        if (typeof this.mceConfigure === "function") {
            this.mceConfigure({ $options: options });
        }
    }

    setup(editor: tinymce.Editor) {
        if (typeof this.mceSetup === "function") {
            this.mceSetup({ $editor: editor });
        }

        // Bind any other events which have been provided to tinymce.
        for (const event of TinymceConfigureController.events) {
            if (typeof this[event] === "function") {
                editor.on(
                    event.substr(3), // strip the leading "mce".
                    eventArg => { this[event]({ editor, event: eventArg }); });
            }
        }
    }

    init_instance_callback(editor: tinymce.Editor) {
        if (typeof this.mceInitInstanceCallback === "function") {
            this.mceInitInstanceCallback({ $editor: editor });
        }
    }

    /** List of all event bindings for this component except mceSetup and mceInitInstanceCallback
     * which are bound directly to the initialisation object.
     */
    static events: string[] = [
        "mceClick",
        "mceDblClick",
        "mceMouseDown",
        "mceMouseUp",
        "mceMouseMove",
        "mceMouseOver",
        "mceMouseOut",
        "mceMouseEnter",
        "mceMouseLeave",
        "mceKeyDown",
        "mceKeyPress",
        "mceKeyUp",
        "mceContextMenu",
        "mcePaste",
        "mceInit",
        "mceFocus",
        "mceBlur",
        "mceBeforeSetContent",
        "mceSetContent",
        "mceGetContent",
        "mcePreProcess",
        "mcePostProcess",
        "mceNodeChange",
        "mceUndo",
        "mceRedo",
        "mceChange",
        "mceDirty",
        "mceRemove",
        "mceExecCommand",
        "mcePastePreProcess",
        "mcePastePostProcess"
    ];
}

/** The controller for <mce-plugin> directive for adding simple Tinymce plugins by name. If they
 * need more complex setup then consider creating a plugin component instead so all of the
 * appropriate settings can be configured. */
class TinymcePluginController extends TinymceConfigurationController {
    /** The name of the plugin to include. Set from bindings. */
    name: string;
    configure(options: tinymce.Settings & ExtraTinymceSettings) {
        this.extendPlugins(options, this.name);
    }
}

const directiveName = "mdsRichTextEditor";

/** The require part of components which want to implement a `TinymceConfigurationController` based
 * component for configuring tinymce. */
export const tinymceConfigurationRequire = {
    wrapper: `^^${directiveName}`
};

export default ngModule("midas.tinymce.directive", [angularTinyMceModule.name, stickyModule.name])
    .component(directiveName, {
        controller: RichTextEditorController
    })
    // A component for adding named tinymce plugins to a tinymce instance. If there are any settings
    // for the plugin, consider creating a separate plugin component for configuring those settings.
    .component("mcePlugin", {
        require: tinymceConfigurationRequire,
        controller: TinymcePluginController,
        bindings: <BindingOf<TinymcePluginController>> {
            name: "@"
        }
    })
    // Used to add some custom initialisation to the editor in a declarative and angular friendly
    // way. This is a fairly general component that allows you to run arbitrary code at various
    // stages of the initialisation or runtime of the editor instance, with some specialisations for
    // common tasks such as adding to the toolbar.
    // EG to add some built-in buttons and a separator to the toolbar:
    //    `<mce-configure mce-toolbar="bold italic | fontselect"></mce-configure>`
    // EG to get a callback whenever the cursor changes to a new DOM element:
    //    `<mce-configure mce-node-change="$ctrl.selectedNode = $event.element"></mce-configure>`
    // EG to run arbitrary setup code:
    //    `<mce-configure mce-setup="$editor.addButton($ctrl.name, $ctrl.buttonOptions)"></mce-configure>`
    .component("mceConfigure", {
        require: tinymceConfigurationRequire,
        bindings:
            // Build bindings from a combination of the events lists and a few others.
            TinymceConfigureController.events.reduce((obj: { }, eventName: string) => {
                obj[eventName] = "&?";
                return obj;
            }, {
                // Three special cased event callbacks which are bound directly to the options
                // object rather than being registered during setup.
                mceSetup: "&?",
                mceInitInstanceCallback: "&?",
                mceConfigure: "&?"
            }),
        controller: TinymceConfigureController
    });
