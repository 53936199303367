/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import * as angular from "angular";
angular.module("midas.utility.measurement.views").controller("EchoLevel1Ctrl", ["$scope", function($scope) {
 const { values } = $scope.measurements;
 const setAllNormal = function()  {
   values.EELVKinesis.value = "normal";
   values.EELVSize.value = "normal";
   values.EELVHypertrophy.value = "no";
   values.EERVKinesis.value = "normal";
   values.EERVSize.value = "normal";
   values.EERVHypertrophy.value = "no";
   values.EEValves.value = "normal";
   values.EEPericardium.value = "normal";
   console.log("All Normal");
 };
 $scope.clickAllNormal = () => setAllNormal();
}
]);
// CoffeeScript