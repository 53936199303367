import { Vector2, IPointLike } from './geometry/vector2';
import { Disposable } from './disposable';
import { notify, observe, ObservableCallback } from "./events/rawEvents";
import { IMaybeTraceable, Traceable, LogFunction, traceIgnore, trace } from './tracing';
import { module as ngModule } from "angular";

export namespace PanZoom {
    /** The types of events published by the PanZoom class. */
    export type Events = "start-pan-zoom" | "pan-zoom" | "end-pan-zoom" | "goto-pan-zoom" | "cancel-pan-zoom";
}

export class PanZoom implements IMaybeTraceable {
    protected $trace?: LogFunction = traceIgnore;
    private $debugRegistrations: any[];
    private normInteractionStart: Vector2;
    private _observers: { [event: string]: ObservableCallback<PanZoom>[] } = {};
    /** Current interaction point provided via panZ. */
    interaction: Vector2 & {
        /**
         * Initial interaction point provided by startPanZoom().
         *
         * @type {Vector2}
         */
        start: Vector2;
    };

    /** Current position or translation of the object being panned and zoomed.
     *  This can be used in the translate property of a 2D transform. */
    pos: Vector2 & {
        /**
         * The initial position of the object when startPanZoom() was called.
         *
         * @type {Vector2}
         */
        start: Vector2;
    };

    /** Current scale or zoom of the object being panned and zoomed. This can be
     *  used in the scale property of a 2D transform. */
    scale: Vector2 & {
        /**
         * The initial scale of the object when startPanZoom() was called.
         *
         * @type {Vector2}
         */
        start: Vector2;
    };

    minScale: Vector2;
    maxScale: Vector2;

    /** Gets whether startPanZoom() has been called, but a matching endPanZoom() has not yet. */
    get isActive(): boolean { return (this.normInteractionStart != null); }
    /** Registers an observer for a named callback, returning a deregistration
     *  function. Calling the returned function removes the callback from the
     *  observer list and returns it. The order of observers is not guaranteed
     *  to remain stable. */

    constructor() {
        this.interaction = <any>new Vector2();
        //Initial interaction point provided by startPanZoom().
        this.interaction.start = new Vector2(this.interaction);

        //Current position or translation of the object being panned and zoomed. This
        //can be used in the translate property of a 2D transform.
        this.pos = <any>new Vector2();
        //The initial position of the object when startPanZoom() was called.
        this.pos.start = new Vector2(this.pos);

        //Current scale or zoom of the object being panned and zoomed. This can be
        //used in the scale property of a 2D transform.
        this.scale = <any>new Vector2(1, 1);
        //The initial scale of the object when startPanZoom() was called.
        this.scale.start = new Vector2(this.scale);

        this.minScale = <any>new Vector2(0.1, 0.1);
        this.maxScale = <any>new Vector2(5, 5);
    }

    observe(eventName: PanZoom.Events, callback: (pz: PanZoom) => void): Disposable
    { return observe(this._observers, eventName, callback); }
    /** Notifies all observers of a named event by producing this object to them. */
    notify(eventName: PanZoom.Events): void { return notify(this._observers, eventName, this); }
    /** Handles starting a pan/zoom operation. */
    startPanZoom(interactionPos: number | IPointLike): boolean {
        if (!this.isActive) {
          this.pos.start.set(this.pos);
          this.scale.start.set(this.scale);
          this.interaction.set(interactionPos);
          this.interaction.start.set(interactionPos);
          this.normInteractionStart = Vector2.invTransform(this.interaction.start, this.pos.start, this.scale.start);
          this.notify("start-pan-zoom");
          return true;
        }
        return false;
    }
    /** Handles continuing a pan/ zoom operation. scaleAmount can be a number
     *  which applies to both dimensions, or an object with x and y for each
     *  dimension. */
    panZooming(interactionPos: number | IPointLike, scaleAmount?: number | IPointLike): boolean {
        if (this.isActive) {

            if (this.scale.start.x < 0.1) {
                this.scale.start.set(this.minScale);
                scaleAmount = (typeof scaleAmount === "number") ? 1 : {x: 1, y: 1} as IPointLike
            }

            if (this.scale.start.x > 5) {
                this.scale.start.set(this.maxScale);
                scaleAmount = (typeof scaleAmount === "number") ? 1 : {x: 1, y: 1} as IPointLike
            }

            if (scaleAmount != null) {
                this.scale.set(this.scale.start).multiply(scaleAmount);
            }

            this.interaction.set(interactionPos);

            const normInteraction = Vector2.invTransform(this.interaction, this.pos.start, this.scale);
            this.pos.set(Vector2.transform(normInteraction.subtract(this.normInteractionStart), this.pos.start, this.scale));

            this.notify("pan-zoom");
            return true;
        }
        return false;
    }

    /** Ends a pan/zoom operation. */
    endPanZoom(): boolean {
        if (this.isActive) {
          this.normInteractionStart = undefined;
          this.notify("end-pan-zoom");
          return true;
        }
        return false;
    }
    /** Cancel a pan/zoom operation, returning to the start position. */
    cancelPanZoom(): boolean {
        if (this.isActive) {
          this.normInteractionStart = undefined;
          this.goto(this.pos.start, this.scale.start);
          return true;
        }
        return false;
    }
    /** Resets to no translation or scale.If currently pan/ zooming then
     *  'end-pan-zoom' is generated first, then 'goto-pan-zoom'. */
    reset(): void { return this.goto(new Vector2(0), new Vector2(1)); }
    /** Go directly to a specified translation and/or scale. If currently
     *  pan/zooming then 'end-pan-zoom' is generated first, then
     *  'goto-pan-zoom'. */
    goto(pos: number | IPointLike, scale: number | IPointLike): void {
        if (this.isActive) {
          this.endPanZoom();
        }

        this.interaction.set(0);
        this.interaction.start.set(0);
        if (pos != null) {
          this.pos.set(pos);
          this.pos.start.set(pos);
        }
        if (scale != null) {
          this.scale.set(scale);
          this.scale.start.set(scale);
        }

        this.notify("goto-pan-zoom");
    }

    /** Zoom into or out of a point by a certain amount.If currently pan/
     *  zooming then 'end-pan-zoom' is generated first, then 'goto-pan-zoom'. */
    zoom(centroid: IPointLike, scale: number | IPointLike): void {
        const normCentroid = Vector2.invTransform(centroid, this.pos, this.scale);
        scale = this.scale.clone().multiply(scale);
        const normNewCentroid = Vector2.invTransform(centroid, this.pos, scale);
        const pos = Vector2.transform(normNewCentroid.subtract(normCentroid), this.pos, scale);
        return this.goto(pos, scale);
    }

    /** Pans by a certain amount relative to the existing position, irrespective
     *  of the current scale. If currently pan/zooming then 'end-pan-zoom' is
     *  generated first, then 'goto-pan-zoom'. */
    pan(offset: IPointLike): void {
        const pos = new Vector2(offset).add(this.pos);
        return this.goto(pos, this.scale);
    }

    toString(precision?: number): string {
        const p = precision;
        return `pos, scale, interaction: ${this.pos.toString(p)}, ${this.scale.toString(p)}, ${this.interaction.toString(p)}, \
start: ${this.pos.start.toString(p)}, ${this.scale.start.toString(p)}, ${this.interaction.start.toString(p)}`;
      }

    /** Allows the trace log function and event tracing to be configured for this object. */
    setTracing(options: { trace?: Traceable.Options["trace"], "panZoom.traceEvents"?: boolean }): void {
        if (options == null) { return; }

        if (options.trace) {
          const traceOption = options["trace"];
          this.$trace = (traceOption != null) ? (typeof traceOption === "function" ? traceOption : trace) : traceIgnore;
        }

        if (options["panZoom.traceEvents"] === true) {
          if (this.$debugRegistrations == null) {
            this.$debugRegistrations = [
              this.observe("start-pan-zoom", () => this.$trace && this.$trace("start-pan-zoom", this.toString(4))),
              this.observe("pan-zoom", () => this.$trace && this.$trace("pan-zoom", this.toString(4))),
              this.observe("end-pan-zoom", () => this.$trace && this.$trace("end-pan-zoom", this.toString(4))),
              this.observe("cancel-pan-zoom", () => this.$trace && this.$trace("cancel-pan-zoom", this.toString(4))),
              this.observe("goto-pan-zoom", () => this.$trace && this.$trace("goto-pan-zoom", this.toString(4)))
            ];
          }
        } else if (options["panZoom.traceEvents"] === false) {
          this.$trace = null;
          if (this.$debugRegistrations) {
            for (let dispose of this.$debugRegistrations) {
                dispose();
            }
            this.$debugRegistrations = [];
          }
        }
      }
}

export default ngModule("midas.utility.panZoom", []).constant("PanZoom", PanZoom);