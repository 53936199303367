/**
 * A raw expand directive purely responsible for enacting and animating the expansion based on the
 * expression inside the directive attribute value.
 */

import "angular-animate";
import * as angular from "angular";
import { IChangesObject } from "../componentBindings";

//$animateCss (and by extension $animate which uses it) have an open issue regarding animation
//easing from CSS, so I've just copied the animation easing function function here so we can ask
//$animateCss to apply it explicitly.
//See: https://github.com/angular/angular.js/issues/12656
//See: https://github.com/angular/angular.js/pull/13488
const materialExpansionEasing = "cubic-bezier(0.4,0.0,0.2,1)";

class StateBasedAnimation {
    /** Gets whether the animation is currently running. */
    get isAnimating() : boolean { return this.continuation != null; }
    /** The current state. Don't set this externally, or things may get strange. */
    state: boolean;
    /** $animate has some strange behaviour with it's promises where it seems to resolve them out
     * of order when they're being cancelled frequently. I didn't investigate too deeply, but I
     * did enough to see it happening. This deferred lets us normalise the promise behaviour to
     * what's expected. */
    private continuation: angular.IDeferred<boolean>;

    private runner: angular.animate.IAnimateCssRunner;

    constructor(
        initialState: boolean,
        private readonly element: angular.IAugmentedJQuery,
        /** Called when an animation starts to get the animation parameters. */
        private readonly getAnimationParams: (state: boolean) =>
          { final: JQueryCssProperties } & angular.animate.IAnimationOptions,
        private readonly $q: angular.IQService,
        private readonly $animateCss: angular.animate.IAnimateCssService
    ) {
        this.state = initialState;
    }

    /** Toggles the current state and runs the animation. */
    toggle(): angular.IPromise<boolean> {
        if (this.continuation) {
            this.continuation.reject("cancelled");
            this.continuation = null;
        }
        const localContinuation = this.continuation = this.$q.defer<boolean>();

        //Get animation params before we cancel the existing animation, so that getAnimationParams()
        //is able to read current state in the case of a mid-animation toggle. 
        const state = this.state = !this.state;
        const animationParams = this.getAnimationParams(state);

        if (this.runner) {
            this.runner.end();
            this.runner = null;
        }
        if (animationParams) {
            this.runner = this.$animateCss(this.element, animationParams);
            this.runner.start().then(
                () => localContinuation.resolve(state),
                err => localContinuation.reject(err));
        } else {
            localContinuation.resolve(state);
        }
        localContinuation.promise.then(() => {
            if (animationParams && animationParams.final) {
                this.element.css(animationParams.final);
            }
            this.continuation = null;
            this.runner = null;
        });

        return this.continuation.promise;
    }

    /** Sets the state of this object, and runs the animation if required. */
    setState(newState: boolean) {
        if (this.state !== newState) {
            return this.toggle();
        }
        return this.$q.resolve(newState);
    }
}

export const directiveName = "mdsSlideOpen";

function setInitialCss(element: angular.IAugmentedJQuery, expanded: boolean) {
    element.css({
        "height": expanded ? "" :  0,
        "visibility": expanded ? "visible" : "hidden"
    });
}

/** Gets the from/to/final animation params depending on the current expanded state. */
function getAnimationParams(this: angular.IAugmentedJQuery, isExpanding: boolean) {
    return {
        from: {
            "height": this.height(),
            "visibility": "visible"
        },
        to: {
            "height": isExpanding ? this[0].scrollHeight : 0
        },
        final: {
            "height": isExpanding ? "" : undefined,
            "visibility": isExpanding ? "visible" : "hidden"
        },
        easing: materialExpansionEasing
    }
}

export default ["$q", "$animateCss", <angular.IDirectiveFactory>((
        $q: angular.IQService,
        $animateCss: angular.animate.IAnimateCssService) => {
    return {
        restrict: "A",
        
        link(this: angular.IDirective, $scope: angular.IScope, $element: angular.IAugmentedJQuery, $attr: angular.IAttributes) {

            let animationState : StateBasedAnimation;
            let firstWatch = true;

            $scope.$watch($attr[directiveName], function mdsSlideOpenWatch(expanded: boolean) {
                if (firstWatch) {
                    setInitialCss($element, expanded);
                    animationState = new StateBasedAnimation(expanded, $element,
                        getAnimationParams.bind($element), $q, $animateCss);
                    firstWatch = false;
                } else {
                    animationState.setState(expanded);
                }
            });
        }
    };
})];