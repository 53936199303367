import { IControllerConstructor, IScope, IQService } from 'angular';
import { Study, BusinessModelService } from "../../businessModels";
import { IStudyTitle } from './StudyTitle';
import { LoDashStatic } from 'lodash';
import ReferenceService from '../reference/referencesService';
import "angular-material";

/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */

export default [
  "$scope", "study", "title", "lodash", "businessModels", "references", "Upload", "$q", "$mdToast",
  <IControllerConstructor>function($scope: IScope, study: Study, title: IStudyTitle, _: LoDashStatic, models: BusinessModelService, references: ReferenceService, Upload, $q: IQService, $mdToast: angular.material.IToastService ) {

    let refresh;
    title.text = "Pin images to include in report";
    title.clearButtons();
    title.backButton.show = false;
    title.acceptButton.show = false;

    $scope.study = study;

    $scope.refresh = (refresh = () =>
      models.Study.find({ id: study.id }, "exams.imageLinks.image")
      .then(x => {
        return $scope.images = _.flatten(x.exams.map(exam => exam.images));
      })
    );

    $scope.toggleInReport = function(image) {
      if (study.userCanEdit() && !models.User.current.hasRole('Read-Only Mode')) {
        if (image.isMovie) { 
            return $mdToast.showSimple("Cines cannot be pinned to the final report");
        } else {
            const valueToSave = !image.isIncludedInReport;
            return models.save(() => image.isIncludedInReport = valueToSave);
          }
      }
    };

    $scope.getTooltip = function(image) {
      if (study.userCanEdit()) {
        if (image.isIncludedInReport) { return "Click to exclude from report"; } else { return "Click to include in report"; }
      } else {
        return undefined;
      }
    };

    refresh();

    const pollInterval = setInterval((() => $scope.refresh()), 5000);

    $scope.$on("$destroy", () => clearInterval(pollInterval));

    $scope.view = img => references.select("Images", img.id);
    $scope.delete = function(img) {
      const isUploadPending = true;
      return models.save(() => img.delete())
      .then(function() {
        $scope.isUploadPending = false;
        return refresh();
      });
    };

    $scope.isUploadPending = false;
    $scope.uploadFiles = function(files, errFiles) {
      if (!files) { return; }
      $scope.isUploadPending = true;
      const uploads = [];
      for (let file of files) {
        (function(file) {
         const upload = (file.upload = Upload.upload({
           url: `api/midas/UploadImage/${study.id}`,
           data: {
             file
           }
         }));
         return uploads.push(upload);
       })(file);
      }
      $q.all(uploads)
      .then(function() {
        $scope.isUploadPending = false;
        return $scope.refresh();
      });
    };
  }
];
