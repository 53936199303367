import RenderableShape, { shadowDefaultExtraThickness, shadowDefaultColour } from "./renderableShape";
import { Vector2, IPointLike, Traceable, traceIgnore, trace, Rect, LogFunction } from "../../../utility/utils";
import { Container, Shape, Text as CjsText } from "createjs";
import Rectangle from "./rectangle";
import { coordExtent } from "../imageEditorVars";

export default class Text extends RenderableShape {
    /** The handle to the graphical representation of this shape. */
    ink: Container;
    /** The top left corner of the text box. */
    position: Vector2;
    /** The size of the text box in normalised user coordinates. */
    size: Vector2 = new Vector2(0.2, 0.1).multiply(coordExtent);
    /** The text to draw. */
    text: string = undefined;
    /** The font to use to render the text. */
    font: string = "Arial"
    /** The font size to use to render the text, in points (strange I know). */
    fontSize: number = null;
    /** The unitless line height to use, which then bases line height on font size. */
    lineHeight: number = 1.25;
    /** The colour to use to draw text. */
    colour: string = "black";
    /** The colour to use to draw the shadow box. */
    shadowColour: string = shadowDefaultColour;
    /** How much thicker the shadow should be compared to the shape. */
    shadowExtraThickness: number = shadowDefaultExtraThickness * coordExtent;
    /** Horizontal and vertical padding in normalised user coordinates, in between the border and
     * content area. */
    padding: Vector2 = null;
    /** The background colour, or null for no background. */
    backgroundColour: string = null;
    /** The border thickness in normalised user coordinates, or 0 for no border. */
    borderThickness: number = 0;
    /** The colour to draw the border. */
    borderColour: string = "black";
    /** Whether to display the shape with debug guides, such as visual indicators of border and
     * padding. */
    debug: boolean = false;
  
    /** Does a sanity check on the provided arguments to the render function, printing information
     * to trace if that's provided, doing nothing otherwise.
     * @param size Argument to render().
     * @param padding Argument to render().
     * @param borderThickness Argument to render().
     * @param fontSize Argument to render().
     */
    static sanityCheck(logger: LogFunction, size: IPointLike, padding: IPointLike, borderThickness: number, fontSize: number) {
      if (logger) {
        Rectangle.sanityCheck(logger, size.x, size.y, borderThickness, padding.x, padding.y);
  
        if (fontSize < 5) {
          logger("Fonts rendered at small sizes may cause bugs, as some browsers round font sizes to the nearest pixel. Fonts rendered less than 1px high may not be rendered at all.");
        }
      }
    }
  
    /** Gets the ink objects from a container or creates the container and any objects that are
     * missing. */
    private static $getInk(container?: Container): [Container, Shape, CjsText] {
      let textShape: CjsText;
      let background: Shape;
      if (container) {
        background = <Shape>container.getChildByName("Text background");
        if (background instanceof Shape === false) {
          background = null;
          container.removeChild(background)
        }
        textShape = <CjsText>container.getChildByName("Text");
        if (textShape instanceof CjsText === false) {
          textShape = null;
          container.removeChild(textShape);
        }
      } else {
        container = new Container();
        container.name = "Text group";
      }
  
      if (background == null) {
        background = new Shape();
        background.name = "Text background";
        container.addChild(background);
        container.setChildIndex(background, 0);
      }
  
      if (textShape == null) {
        textShape = new CjsText();
        textShape.name = "Text";
        container.addChild(textShape);
        container.setChildIndex(textShape, 1);
      }
  
      return [container, background, textShape];
    }
  
    static render(background: Shape, textShape: CjsText, pos: IPointLike, size: IPointLike, text: string, font: string, fontSize: number, colour: string = "black", padding: IPointLike = { x: 0, y: 0 }, secondaryColour?: string, lineHeight: number = 1.25, borderThickness: number = 0, borderColour: string = "black", debug: boolean = false): void {
      const ptToPx = 96 / 72; //96px/in, 72pt/in
      const xPadding = padding.x;
      const yPadding = padding.y;
      const width = size.x;
      const height = size.y;
  
      if (background) {
        Rectangle.render(background, new Rect(pos.x, pos.y, size.x, size.y), borderThickness, borderColour, secondaryColour, undefined, undefined, debug);
      }
  
      if (textShape) {
        textShape.x = pos.x + xPadding + borderThickness;
        textShape.y = pos.y + yPadding + borderThickness;
        textShape.font = `${fontSize}pt '${font}'`;
        textShape.text = text;
        textShape.color = colour;
        textShape.lineWidth = width - (2 * (xPadding + borderThickness));
        textShape.lineHeight = fontSize * lineHeight * ptToPx;
  
        const mask = textShape.mask || (textShape.mask = new Shape());
        mask.name = "Text mask";
        mask.graphics
          .clear()
          .beginFill("black")
          .drawRect(
            pos.x + xPadding + borderThickness,
            pos.y + yPadding + borderThickness,
            width - (2 * (xPadding + borderThickness)),
            height - (2 * (yPadding + borderThickness)));
      }
    }
  
    static renderShadow(ink: Shape, pos: IPointLike, size: IPointLike, thickness: number, colour: string): void {
      const x = pos.x - thickness;
      const y = pos.y - thickness;
      const width = size.x + (2 * thickness);
      const height = size.y + (2 * thickness);
      Rectangle.renderBorderBox(ink, x, y, width, height, thickness, colour);
    }
  
    render(drawShadow: boolean = false, target: Container = this.ink): Container {
      if (this.$trace) {
        Text.sanityCheck(this.$trace, this.size, this.padding, this.borderThickness, this.fontSize);
      }
      let textShape: CjsText;
      let background: Shape;
      [target, background, textShape] = Text.$getInk(target);
      background.graphics.clear();
      if (drawShadow) {
        Text.renderShadow(background, this.position, this.size, this.shadowExtraThickness, this.shadowColour);
      }
      Text.render(background, textShape, this.position, this.size, this.text, this.font, this.fontSize, this.colour, this.padding, this.backgroundColour, this.lineHeight, this.borderThickness, this.borderColour, this.debug);
      return target;
    }
  
    bakeTransform(): void {
      this.$trace && this.$trace("Text.bakeTransform() - only bakes position");
      this.position.add(this.ink);
      this.ink.x = this.ink.y = 0;
    }
  
    protected getBounds() {
      const pos = this.position;
      const size = this.size;
      return new Rect(pos.x, pos.y, size.x, size.y);
    }
  
    hitTest(p: IPointLike): boolean {
      if (this.ink.parent != null) {
        p = this.ink
          .getMatrix(RenderableShape.ScratchMatrix)
          .invert()
          .transformPoint(p.x, p.y, RenderableShape.ScratchPoint);
        return this.getBounds().contains(p);
      }
      return false;
    }
  
    /** Override of the base renderBounds() to pass the correct shape for drawing the bounds to. */
    renderBounds(ink: Shape = <any>this.ink, thickness?: number, colour?: string) {
      if (ink && ink.graphics == null && ink["addChild"]) {
        //If we're passed a container, get the background shape.
        ink = Text.$getInk(this.ink)[1];
      }
      super.renderBounds(ink, thickness, colour);
    }
  
    /** Allows trace logging to be enabled or disabled for this object. */
    setTracing(options: Traceable.Options) {
      if ("trace" in options) {
        const traceOption = options["trace"];
        this.$trace = traceOption ? (typeof traceOption === "function" ? traceOption : trace) : traceIgnore;
      }
    }
  
    constructor(text: string, position: IPointLike) {
      super();
      this.ink = new Container();
      this.ink.name = "Text group";
      this.text = text;
      this.position = new Vector2(position);
    }
  }
  