/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS205: Consider reworking code to avoid use of IIFEs
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import * as angular from "angular";
import "./measurementDiagram.css";
import { IDirective, IHttpService, IQService, ILocationService } from 'angular';


export default ["$document", "$location", "$http", "$anchorScroll", "$q",
function($document, $location: ILocationService, $http: IHttpService, $anchorScroll, $q: IQService) {
  let id = 1;
  return <IDirective>{
    restrict: "ECA",
    scope: {
      measurements: "=",
      options: "=",
      variant: "=?",
      register: "=?",
      exam: "=?"
    },
    link($scope, $element, $attrs) {
      const myId = id++;
      $element.addClass("mds-diagram-container");
      $element.prepend(`<a id='diagram${myId}' />`);
      if ($scope.variant) { $element.addClass($scope.variant.toLowerCase()); }
      $document.on("mousedown", function(e) {
        const element = angular.element(e.target);
        const className = element.attr('class');
        if (className && (className.indexOf("mds-diagram-area") !== -1)) { return; }
        $scope.hideAll();
        return $scope.$apply();
      });
      $scope.$on("$destroy", () => $document.off("click"));
      const captureVisual = function() {
        if (!$attrs.key || !$scope.exam) { return $q.when([]); }
        $location.hash(`diagram${myId}`);
        $anchorScroll();
        const body = $element;
        const params = {
         height: body.height(),
         width: body.width(),
         logging: false
       };
        return $q.when<any>(import(/* webpackChunkName: "html2canvas" */ "html2canvas")
                 .then(html2canvas => html2canvas.default(body, params)))
          .then(canvas => {
            const data = canvas.toDataURL().replace(/^data:image\/(png|jpg);base64,/, "");
            return $http.post("api/midas/UpdateMeasurementDiagram", {
              encodedImage: data,
              format: 'image/png',
              key: $attrs.key,
              examId: $scope.exam.id
            });
          });
      };

      return (typeof $scope.register === 'function' ? $scope.register({ captureVisual }) : undefined);
    },
    controller: ["$scope", function($scope) {
      let hideAll;
      const subs = {};
      const visibility = {};
      var publish = function(key, visibility) {
        if (key !== "") { publish("", visibility); }
        if (!subs[key]) { return; }
        for (let fn of subs[key]) { fn(visibility); }
      };
      $scope.hideAll = (hideAll = () =>
        (() => {
          const result = [];
          for (let key in visibility) {
            visibility[key] = false;
            result.push(publish(key, false));
          }
          return result;
        })()
      );
      const api = {
        hideAll: $scope.hideAll,
        setMeasurement(key, value) {
          const mmt = ($scope.measurements.values[key] != null ? $scope.measurements.values[key].value = value : undefined);
          return mmt.value = value;
        },
        cycleMeasurement(key) {
          const mmt = $scope.measurements.values[key];
          if (!mmt || !mmt.options) { return; }
          const options = ((() => {
            const result = [];
            for (let option of mmt.options) {               if (option.trim().length > 0) {
                result.push(option);
              }
            }
            return result;
          })());
          const index = options.indexOf(mmt.value);
          let newIndex = -1;
          if (index === -1) { newIndex = 0;
          } else if (index === (options.length - 1)) { newIndex = -1;
          } else { newIndex = index + 1; }

          if (newIndex === -1) { return mmt.value = " ";
          } else { return mmt.value = options[newIndex]; }
        },
        getVariant() { return $scope.variant; },
        getVisibility(key) { return visibility[key] === true; },
        setVisibility(key, value, closeOthers = true) {
          hideAll();
          const newValue = value === true;
          visibility[key] = newValue;
          return publish(key, newValue);
        },
        toggleVisibility(key, closeOthers = true) {
          hideAll();
          const newValue = !visibility[key];
          visibility[key] = newValue;
          return publish(key, newValue);
        },
        listen(key, fn) {
          if (!key) { key = ""; }
          if (!subs[key]) { subs[key] = []; }
          subs[key].push(fn);
          return function() {
            if (!subs[key]) { return; }
            const index = subs[key].indexOf(fn);
            if (index === -1) { return; }
            return subs[key].splice(index, 1);
          };
        }
      };
      return api;
    }
    ]
  };
}
];
