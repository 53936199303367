import { module as ngModule, IController } from "angular";
import filterModule from "./expression-editor.filters";
import {
  LiteralOperandType, ExpressionResultType,
} from '../../../utility/reportFormatter/widgets/condition-expression.models';

/** The controller for a directive which allows the user to choose a value from a number of sources.
 * Sources include literal number/string, a measurement, a calculation, etc. The kinds of values
 * available can be restricted by passing in a string or array to the type binding. */
interface ChooseValueMenuController extends IController { }
class ChooseValueMenuController implements IController {

  $onChanges() {
    if (this.type) {

      this.showBool = this.showNum = this.showString = false;

      if (typeof this.type === "string") {
        this.enable(this.type);
      } else {
        for (const type of this.type) {
          this.enable(type);
        }
      }
    } else {
      // Default to show all.
      this.showBool = this.showNum = this.showString = true;
    }
  }

  private enable(type: ExpressionResultType) {
    switch (type) {
      case "boolean":
        this.showBool = true;
        break;
      case "number":
        this.showNum = true;
        break;
      case "string":
        this.showString = true;
        break;
    }
  }

  /** Whether to show options for creating a bool value. */
  showBool: boolean = true;
  /** Whether to show options for creating a numeric value. */
  showNum: boolean = true;
  /** Whether to show options for creating a string value. */
  showString: boolean = true;

  /** The allowed types which the user can choose from. From bindings. */
  type: ExpressionResultType | ExpressionResultType[];

  /** From bindings. */
  onItemChosen: (locals: { $type: LiteralOperandType }) => void;

  /** From bindings. */
  mdButtonClass: string;
}

export default ngModule("midas.admin.blueprint.expression.choose-measurement-or-user-value-menu", [
  filterModule.name
]).component("chooseValueMenu", {
  templateUrl: require("./choose-value-menu.component.html"),
  transclude: true,
  bindings: {
    onItemChosen: "&",
    mdButtonClass: "@?",
    type:"<?"
  },
  controller: ChooseValueMenuController
});