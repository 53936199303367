import "angular-material";
import { IController, module, ILogService, IQService, IPromise } from "angular";
import {
  BusinessModelService,
  DicomDevice as Device,
  DicomDeviceModel as Model,
  Site
} from "../../businessModels";
import { find, sortBy } from 'lodash';
import "./dicom-device-administration.component.scss";
import * as jss from "js-search";
import { isNullOrWhitespace } from '../../utility/utils';

/** A dumb view model to avoid breaking our HTML if the business model is refactored. */
class DeviceViewModel {
  id: number;
  serial: string;
  constructor(
    public readonly entity: Device,
    public model: ModelViewModel,
    public site: SiteViewModel) {
    this.id = entity.id;
    this.serial = entity.serial;
  }
}

/** A dumb view model to avoid breaking our HTML if the business model is refactored. */
class ModelViewModel {
  id: number;
  name: string;
  constructor(public readonly entity: Model) {
    this.id = entity.id;
    this.name = entity.name;
  }
}

/** A dumb view model to avoid breaking our HTML if the business model is refactored. */
class SiteViewModel {
  id: number;
  name: string;
  constructor(public readonly entity: Site) {
    this.id = entity.id;
    this.name = entity.name;
  }
}

class DicomDeviceAdminController implements IController {
  /** All devices available. */
  public devices: DeviceViewModel[];
  /** Devices, filtered by the search box. */
  public filteredDevices: DeviceViewModel[]
  /** All sites. */
  public sites: SiteViewModel[];
  /** All models. */
  public models: ModelViewModel[];
  /** The text in the search box. */
  public searchText: string;
  /** The search function to use to actually do the search. This is reassigned every time the device
   * search index is rebuilt. */
  private doSearch: (search: string) => DeviceViewModel[];

  static $inject = [
    "businessModels",
    "$q",
    "$log",
    "$mdToast"
  ];

  constructor(
    private readonly businessModels: BusinessModelService,
    private readonly $q: IQService,
    private readonly $log: ILogService,
    private readonly $mdToast: angular.material.IToastService) {}

  $onInit(): void {
    const loading: [IPromise<Model[]>, IPromise<Site[]>, IPromise<Device[]>] = [
      this.businessModels.DicomDeviceModel.list(undefined, 1000),
      this.businessModels.Site.list(),
      this.businessModels.DicomDevice.list(undefined, 10000)
    ];

    // Convert all entities into view models and tie them together. I'm making the assumption that
    // there won't be so many models/sites/devices that a linear search on view load will be slow.
    this.$q.all(loading).then(([ models, sites, devices ]) => {
      this.models = models.map(model => new ModelViewModel(model));
      this.sites = sites.map(site => new SiteViewModel(site));
      this.devices = devices.map(device => {
        let model: ModelViewModel = null;
        if (device.model) {
          model = find(this.models, m => m.entity === device.model);
        }

        let site: SiteViewModel = null;
        if (device.site) {
          site = find(this.sites, m => m.entity === device.site);
        }

        return new DeviceViewModel(device, model, site);
      });

      // Order the arrays for display to avoid extra work using the orderBy filter.
      this.models = sortBy(this.models, x => x.name);
      this.sites = sortBy(this.sites, x => x.name);
      this.devices = this.filteredDevices = sortBy(this.devices, x => x.serial);

      // Build the initial search index.
      this.buildDeviceSearch();
    }).catch(err =>
      this.logAndDisplayError(err, "There was an error loading the required information. Please reload the page and try again."));
  }

  /** Update the filtered device list by searching using the current search text. */
  search() {
    if (isNullOrWhitespace(this.searchText) || this.doSearch == null) {
      this.filteredDevices = this.devices;
    } else {
      this.filteredDevices = this.doSearch(this.searchText);
    }
  }

  /** Clears the current search text and displays all devices again. */
  clearSearch() {
    this.searchText = "";
    this.search();
  }

  /** (Re)Builds the device search index. */
  private buildDeviceSearch() {
    const search = new jss.Search("id");
    search.addIndex("serial");
    search.addIndex(["model", "name"]);
    search.addIndex(["site", "name"]);
    search.addDocuments(this.devices);
    this.doSearch = search.search.bind(search);
  }

  /** Updates a device entity and saves it. */
  saveChanges(device: DeviceViewModel) {
    device.entity.model = device.model == null ? null : device.model.entity;
    device.entity.site = device.site == null ? null : device.site.entity;
    this.businessModels.saveEntities([device.entity]).then(() => {
      // We've got to rebuild the device search whenever the devices change, or the search will
      // return bad information.
      this.buildDeviceSearch();
      this.search();
    });
  }

  private logAndDisplayError(error: any, text: string): void {
    this.$log.error(text, error);
    this.$mdToast.showSimple(text);
  }
}

export default module("midas.admin.dicomDevice", [])
.component("dicomDeviceAdministration", {
  controller: DicomDeviceAdminController,
  templateUrl: require("./dicom-device-administration.component.html")
});