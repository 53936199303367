import { ILogService, IQService, IPromise, module as ngModule } from 'angular';
import { ObservableCallback, observe, notify } from './events/rawEvents';
import { Disposable } from './disposable';
import { isNullOrWhitespace } from './mdsUtils';
import revertible from "./revertible";
import { flatten } from "lodash";

let $log: ILogService;
let $q: IQService;

/** A class which wraps up common status tracking code for working with promises. It is designed
 * to work well in angular controllers and in HTML when bound to a scope. */
export class LoadingStatus {
    promise: IPromise<any>;
    private _observers: ObservableCallback<this>[];
    /** Gets whether this class is currently loading. */
    isLoading: boolean = false;
    title: string = null;
    /** Gets the error message currently assigned to this class. */
    error: string = null;
    /** Gets whether this class is in a success state. */
    get isLoaded(): boolean { return !(this.isLoading || (this.error != null)); }
    /** Gets whether this class is in a failed state, in which case 'error' will be set. */
    get isFailed(): boolean { return !this.isLoading && (this.error != null); }

    /** Creates a new instance in the isLoaded state. */
    constructor();
    /** Creates a new LoadingStatus class which tracks all of the provided promises.
     * @param promises The promises to track. */
    constructor(...promises: IPromise<any>[]);
    constructor(...promises: IPromise<any>[]) {
      if ((promises != null) && (promises.length > 0)) {
        this.andTrack(...promises);
      }
    }

    /** Registers for the changed event on this class. Notified whenever this instance changes
     * state between loading, loaded, and failed.
     * @param callback The callback to regsiter.
     * @returns A deregistration object.
     */
    onChanged(callback: ObservableCallback<this>): Disposable {
      let obs = this._observers;
      if ((obs == null)) { obs = (this._observers = []); }
      const deregister = observe(obs, callback);
      callback(this);
      return deregister;
    }

    /** Transitions this class to a loading state, clearing any errors. */
    setLoading(): void {
      this.error = undefined;
      this.isLoading = true;
      const obs = this._observers;
      if (obs != null) { notify(obs, this); }
    }

    /** Transitions this class to an error state with the specified message or exception.
      * @param err The error the report. */
    setError(err: string | Error): void {
      //Do our best to resolve a meaningful error message from a string or Error, but
      //never use null, undefined, or an empty or whitespace string. Instead replace those
      //with "An error occurred".
      this.error = (err != null ? err["message"] : undefined) != null ? (err != null ? err["message"] : undefined) : err;
      if (isNullOrWhitespace(this.error)) { this.error = "An error occured"; }
      this.title = undefined;
      this.isLoading = false;
      $log.warn(err);
      const obs = this._observers;
      if (obs != null) { notify(obs, this); }
    }

    /** Transitions this class to a success state, clearing any errors. */
    setSuccess(): void {
      this.error = undefined;
      this.title = undefined;
      this.isLoading = false;
      const obs = this._observers;
      if (obs != null) { notify(obs, this); }
    }

    /** Sets this class to an isLoading state until the provided promise resolves, at which time
      * the state transitions to isLoaded or isFailed depending on whether the promise succeeds.
      * This can be called as many times as you like with new promises, and each new call will
      * replace any previous promise as the success or failure trigger.
      * @param newPromise The new promise, which replaces any existing promise(s).
      * @param title The new title for this object. */
    track(newPromise: IPromise<any>, title?: string): this {
      //If there's a problem setting up the promise for some reason (like they don't pass
      //something shaped like a promise) then roll back to the state before this call.
      //Doesn't roll back if the promise reports an error, instead that transitions to
      //an error state.
      const revert = revertible(this, ["isLoading", "error", "promise"]);
      try {
        this.title = title;
        this.promise = newPromise;
        this.setLoading();
        newPromise.then((data => {
          if (newPromise === this.promise) { this.setSuccess(); }
          return data;
        }),
        (reason => {
          if (newPromise === this.promise) {
            this.setError(reason);
          }
          return reason;
      }))
        .finally(() => {
          if (newPromise === this.promise) { this.promise = undefined; }
        });
      } catch (error) {
        revert();
      }
      return this;
    }

    /** Sets this class to an isLoading state until both the provided promise and any existing
      * promises resolve, at which time the state transitions to isLoaded if all promises succeed,
      * or isFailed if any fail. */
    andTrack(...promises: IPromise<any>[]): this {
      promises = flatten(promises);
      if (promises.length < 1) { return this; }
      if (this.promise != null) { promises.unshift(this.promise); }
      this.track($q.all(promises));
      return this;
    }
  }

export default ngModule("midas.utility.loading-status", [])
  .run(["$log", "$q", (log: ILogService, q: IQService) => {
    $log = log;
    $q = q;
  }])
  .value("loadingStatus", LoadingStatus);