import "angular-material";
import { module, IController, IPromise } from "angular";
import { BusinessModelService } from "../../businessModels";

interface IRevertModel {
  newStatus: string;
  studyId: number;
  reason: string;
}

class RevertStudyStatusController implements IController {
  revertModel: IRevertModel;

  static $inject = ["businessModels", "$mdToast", "$http"];
  constructor(
    private readonly models: BusinessModelService,
    private readonly $mdToast: angular.material.IToastService,
    private readonly $http: angular.IHttpService) {}

  $onInit(): void {}

  updateStudyStatus(): IPromise<void> {
    const updateStatus = true, newStatus = this.revertModel.newStatus, reason = this.revertModel.reason;
    return this.models.Study.find({ id: this.revertModel.studyId }, "status")
    .then(study => {
      if (study == null) {
        return this.$mdToast.showSimple("Study not found");
      } else {
        const oldStatus = study.status.key;
        if (oldStatus !== "Final")
          return this.$mdToast.showSimple(`Only a study in Final status can be reverted`);
        if (newStatus === "Provisional" || newStatus === "Saved") {
          return this.$http.post(`api/midas/LogStudyStatusUpdate/${this.revertModel.studyId}`, { updateStatus, newStatus, oldStatus, reason })
            .then(() => {
              return this.$mdToast.showSimple("Study status updated");
            })
            .catch(() => this.$mdToast.showSimple("There was a problem reverting the study status."));
        } else return this.$mdToast.showSimple("Study status cannot be reverted to the selected status");
      }
    });
  }
}

export default module("midas.admin.revertStudyStatus", [])
  .component("revertStudyStatus", {
    controller: RevertStudyStatusController,
    templateUrl: require("./revertStudyStatus.html")  
});