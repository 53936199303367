
import { MeasurementController, IMeasurementScope } from "../MeasurementController";
import * as angular from "angular";

class CTCervicalSpineController extends MeasurementController {
  static $inject = ["$scope"];
  constructor(scope: IMeasurementScope) {
    super(scope);
  }

  clickOK() {
    this.setMeasurementValue("CTCervicalSpineRtFracture", "None. ");
    this.setMeasurementValue("CTCervicalSpineRtVerticalAlignment", "Normal, including the craniocervical junction and cervicothoracic junction. ");
    this.setMeasurementValue("CTCervicalSpineRtNeckSoftTissue", "Normal.");
    this.setMeasurementValue("CTCervicalSpineRtRtOtherComment", "None. ");
    this.setMeasurementValue("CTCervicalSpineConclusion", "Negative cervical spine. ");
    console.log("All normal clicked")
  }

}

angular.module("midas.utility.measurement.views").controller("CTCervicalSpineCtrl", CTCervicalSpineController);