import { module as ngModule } from "angular";
import ImageEditorDirective from "./imageEditorCore";
import { PatternService, PatternDirective } from "./patterns";
import LineToolDirective from "./tools/lineTool";
import ArrowToolDirective from "./tools/arrowTool";
import RectToolDirective from "./tools/rectTool";
import PenToolDirective from "./tools/penTool";
import EraseToolDirective from "./tools/eraseTool";
import PathToolDirective from "./tools/pathTool";
import TextToolDirective from "./tools/textTool";
import SelectToolDirective from "./tools/selectTool";
import ArrowCapsControl from "./directives/arrowCapsControl.component";
import ColourSelectControl from "./directives/colourSelectControl.component";
import utilsModule from "../../utility/utils";

export default ngModule("midas.utility.imageEditor", [utilsModule.name])
    .directive("mdsImageEditor", ImageEditorDirective)
    .directive("mdsPattern", PatternDirective)
    .component("mdsArrowCapsControl", ArrowCapsControl)
    .component("mdsColourSelectControl", ColourSelectControl)
    .service("patternService", PatternService)

    //Tools
    .directive("lineTool", LineToolDirective)
    .directive("arrowTool", ArrowToolDirective)
    .directive("rectTool", RectToolDirective)
    .directive("penTool", PenToolDirective)
    .directive("eraseTool", EraseToolDirective)
    .directive("pathTool", PathToolDirective)
    .directive("textTool", TextToolDirective)
    .directive("selectTool", SelectToolDirective)
    //.directive("panZoomTool", PanZoomToolDirective)
    ;