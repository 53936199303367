import { MeasurementController, IMeasurementScope } from "../MeasurementController";
import * as angular from "angular";
class DXCervicalSpineController extends MeasurementController {
  static $inject = ["$scope"];
  constructor(scope: IMeasurementScope) {
    super(scope);
  }

  clickOK() {
    this.setMeasurementValue("DXCervicalSpineRtOtherComment", "There is no fracture or destructive lesion. The vertebral bodies and posterior elements are normal. The alignment, discs, and discovertebral relationships are normal. There is no evidence of instability. ");
    this.setMeasurementValue("DXCervicalSpineConclusion", "Normal cervical spine. ");
    console.log("All normal clicked")
  }

}

angular.module("midas.utility.measurement.views").controller("DXCervicalSpineCtrl", DXCervicalSpineController);
