import * as angular from "angular";
import "Content/normalize.css";
import "Content/font-awesome.css";
import "Content/animate.css";

import "Content/loading.scss";

import "Content/bootstrap.css";
import "Scripts/bootstrap.js";
import "Scripts/angular-ui/ui-bootstrap-tpls.js";

import "Scripts/matchmedia/matchMedia.js";
import "Scripts/matchmedia-ng/matchmedia-ng.js";

import "Scripts/NonNuget/svgToDataUrl.js";

import "Scripts/NonNuget/angular-scroll.min.js";

import "Scripts/NonNuget/Sortable.min.js";
import "Scripts/NonNuget/ng-sortable.js";

import "Scripts/eonasdan-bootstrap-datetimepicker/build/css/bootstrap-datetimepicker.css";
import "Scripts/eonasdan-bootstrap-datetimepicker/src/js/bootstrap-datetimepicker.js";

import "Content/NonNuget/slider/bootstrap-slider.min.css";
import "Scripts/NonNuget/slider/bootstrap-slider.min.js";
import "Scripts/NonNuget/slider/slider.js"

import "Scripts/select2.js";
import "Scripts/NonNuget/ui.select2.js";
import "Content/css/select2.css";
import "Content/select2-bootstrap.css";

import "angular-ui-router";
import "angular-material/angular-material.css";
import "angular-material";
import "ng-file-upload";
import "angular-sanitize";
import "angular-cookies";
import "angular-animate";
import "angular-aria";
import "Scripts/angular-ui/ui-utils.js";
import "Scripts/NonNuget/roundProgress.min.js";
import "angular-nvd3";

export default {
  midasOld: angular.module("midas-old", [
    "ui.router", "ui.bootstrap", "ui.keypress", "ngAnimate", "ngSanitize",
    "duScroll", "ui.select2", "matchmedia-ng",
    "ui.validate", "angular-intro", "ui.bootstrap-slider",
    "angular-send-feedback", "angular-svg-round-progress", "ng-sortable", "ngFileUpload", "ngMaterial"
  ])
};