import {IModule, module} from "angular";
import listComponent from "./dicomPropertyMappingList.component";
import formComponent from "./dicomPropertyMappingForm.component";
import "./dicomPropertyMapping.scss";
import expansionPanel from "../../../utility/expansionPanel/expansionPanel";

const dicomPropertyMappingModule: IModule =
  module("midas.admin.dicomPropertyMapping", [expansionPanel.name])
    .component("mdsDicomPropertyMappings", listComponent)
    .component("mdsDicomPropertyMappingForm", formComponent);
export default dicomPropertyMappingModule;