import { MeasurementController, IMeasurementScope } from "../MeasurementController";
import * as angular from "angular";
class DXHandController extends MeasurementController {
  static $inject = ["$scope"];
  constructor(scope: IMeasurementScope) {
    super(scope);
  }

  clickRightOK() {
    this.setMeasurementValue("DXHandRtBones", "Normal. No fractures are present. ");
    this.setMeasurementValue("DXHandRtSoftTissue", "Normal. ");
    this.setMeasurementValue("DXHandRtJoints", "The joint spaces are normal. ");
    this.setMeasurementValue("DXHandRtOtherComment", "None. ");
    console.log("Right All normal clicked")
  }

  clickLeftOK() {
    this.setMeasurementValue("DXHandLtBones", "Normal. No fractures are present. ");
    this.setMeasurementValue("DXHandLtSoftTissue", "Normal. ");
    this.setMeasurementValue("DXHandLtJoints", "The joint spaces are normal. ");
    this.setMeasurementValue("DXHandLtOtherComment", "None. ");
    console.log("Left All normal clicked")
  }
}

angular.module("midas.utility.measurement.views").controller("DXHandCtrl", DXHandController);
