import { Shape, Point } from "createjs";
import { IPointLike, Triangle as TriangleGeometry } from "../../../utility/utils";
import { coordExtent } from "../imageEditorVars";
import RenderableShape, { shadowDefaultExtraThickness, shadowDefaultColour } from "./renderableShape";

/** A triangle which can render itself to a canvas. */
export default class Triangle extends RenderableShape {
  /** The handle to the graphical representation of this shape. */
  ink: Shape;
  /** The dimensions of the triangle. */
  dimensions: TriangleGeometry;
  /** Stroke thickness. */
  thickness: number;
  /** The fill colour. */
  backgroundColour: string;
  /** The colour to use to draw the shape. */
  colour: string;
  /** The colour to use to draw the shadow. */
  shadowColour: string = shadowDefaultColour;
  /** How much thicker the shadow should be compared to the shape. */
  shadowExtraThickness: number = shadowDefaultExtraThickness * coordExtent;

  /** Renders a triangle.
   * @param ink The graphics object to render to.
   * @param triangle: the triangle to render.
   * @param thickness Stroke thickness.
   * @param colour Stroke colour.
   * @param fill Fill colour.
   */
  static render(ink: Shape, triangle: TriangleGeometry, thickness?: number, colour?: string, fill?: string): Shape {
    const g = ink.graphics;
    if (fill) {
      g.beginFill(fill);
    } else {
      g.endFill();
    }
    if (thickness > 0 && colour) {
      g.beginStroke(colour).setStrokeStyle(thickness);
    } else {
      g.endStroke();
    }
    g.moveTo(triangle.x0, triangle.y0)
      .lineTo(triangle.x1, triangle.y1)
      .lineTo(triangle.x2, triangle.y2)
      .lineTo(triangle.x0, triangle.y0);
    if (thickness > 0 && colour) {
      g.endStroke();
    }
    if (fill) {
      g.endFill();
    }
    return ink;
  }

  /** Clears the graphics object and redraws this shape to it. Should be called whenever
   * changes are made to one of the properties which isn't yet reflected in the graphics object.
   */
  render(drawShadow: boolean = false, target: Shape = this.ink): Shape {
    target.graphics.clear();
    if (drawShadow) {
        const shadow = this.dimensions.clone().expand((this.thickness + this.shadowExtraThickness));
        Triangle.render(target, shadow, this.shadowExtraThickness, this.shadowColour);
    }
    return Triangle.render(target, this.dimensions, this.thickness, this.colour, this.backgroundColour);
  }

  /** Checks hit detection against this triangle. By default it takes into account both the draw
   * position and the current ink transform.
   * @param p The point to hit test against this square.
   */
  hitTest(p: IPointLike): boolean {
    if (this.ink.parent != null) {
      if (this.thickness > 0) {
        //If there is thickness then we need to fall back on pixel perfect hit detection to deal
        //with acute angle corners possibly having long points.
        return this.ink.hitTest(p.x, p.y);
      } else {
        p = this.ink
            .getMatrix(RenderableShape.ScratchMatrix)
            .invert()
            .transformPoint(p.x, p.y, RenderableShape.ScratchPoint);
        return this.dimensions.contains(p);
      }
    }
    return false;
  }

  /** Bakes the current ink position into the points and re-renders. Essentially shifts any offset
   * from the ink position to the actual pixel positions. */
  bakeTransform(): void {
    this.$trace && this.$trace("Triangle.bakeTransform()");
    const m = this.ink.getMatrix(RenderableShape.ScratchMatrix);
    this.dimensions.setFromVerts(
      // Array.map() doesn't preserve "tuppleness". :'-(
      <[Point, Point, Point]>this.dimensions.toVerts().map(p => m.transformPoint(p.x, p.y, p)));
    RenderableShape.resetInkTransform(this.ink);
  }

  /** Creates a new triangle, including a graphics object.
   * @param dimensions The triangle.
   * @param colour The colour of the square. */
  constructor(dimensions: TriangleGeometry, thickness?: number, colour?: string, fill?: string) {
    super();
    if (thickness) {
      this.thickness = thickness;
    }
    if (colour) {
      this.colour = colour;
    }
    if (fill) {
      this.backgroundColour = fill;
    }
    this.ink = new Shape();
    this.ink.name = "Triangle";
    this.dimensions = dimensions;
    this.render();
  }
}
