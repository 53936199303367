import { IComponentController, IComponentOptions } from "angular";
import { IDicomTagViewModel } from "./dicomPropertyMapping.models";
import { BindingOf } from "../../../utility/componentBindings";
import { IEntityProperty } from "./studyProperties";
import { IMappingViewModel } from "./dicomPropertyMapping.models";

/** The input bindings for the mapping edit form */
interface IDicomPropertyMappingFormFormBindings {
  /** The mapping to be edited */
  mapping: IMappingViewModel;
  /** The list of DICOM tags to present to the user, assignable to the mapping. */
  dicomTags: IDicomTagViewModel[];
  /** The list of MIDAS study properties to present to the user, assignable to the mapping. */
  studyProperties: IEntityProperty[];
}

interface DicomPropertyMappingFormController extends IComponentController, IDicomPropertyMappingFormFormBindings {
}

class DicomPropertyMappingFormController {
  /** Filter the list of DICOM tags for the Autocomplete component. */
  filterDicomTags(text: string): Array<IDicomTagViewModel> {
    return this.dicomTags.filter(tag => tag.display.toUpperCase().indexOf(text.toUpperCase()) !== -1);
  }
}

const componentOptions: IComponentOptions = {
  controller: DicomPropertyMappingFormController,
  templateUrl: require("./dicomPropertyMappingForm.component.html"),
  bindings: <BindingOf<IDicomPropertyMappingFormFormBindings>>{
    mapping: "<",
    dicomTags: "<",
    studyProperties: "<"
  }
};
export default componentOptions;