import { MeasurementController, IMeasurementScope } from "../MeasurementController";
import * as angular from "angular";

class FootController extends MeasurementController {
  static $inject = ["$scope"];
  constructor(scope: IMeasurementScope) {
    super(scope);
  }

  clickRightOK() {
    this.setMeasurementValue("MSKFootRtPlantarSurfaceNormal", "ALL NORMAL");
    this.setMeasurementValue("MSKFootRtDorsalSurfaceNormal", "ALL NORMAL");
    this.setMeasurementValue("MSKFootRtToesNormal", "ALL NORMAL");
    console.log("Right All normal clicked")
  }

  clickLeftOK() {
    this.setMeasurementValue("MSKFootLtPlantarSurfaceNormal", "ALL NORMAL");
    this.setMeasurementValue("MSKFootLtDorsalSurfaceNormal", "ALL NORMAL");
    this.setMeasurementValue("MSKFootLtToesNormal", "ALL NORMAL");
    console.log("Left All normal clicked")
  }
}

angular.module("midas.utility.measurement.views").controller("FootCtrl", FootController);