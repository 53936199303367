import { IScope, IController } from "angular";
import { IStudyTitle } from "../StudyTitle";
import NoteService from "./noteService";
import * as BusinessModels from "../../../businessModels";

export interface INotesControllerScope extends IScope {
  study: BusinessModels.Study;
}

interface NotesController extends IController { };
class NotesController implements IController {
  static $inject = ["$scope", "title", "study", "noteService"];

  constructor(
    private scope: INotesControllerScope,
    private title: IStudyTitle,
    private study: BusinessModels.Study,
    private noteService: NoteService
  ) {
    this.setupTitle();
    this.scope.study = study;
  }

  private setupTitle() {
    this.title.text = "Notes";
    this.title.clearButtons();
    this.title.backButton.show = false;
    this.title.acceptButton.show = false;
  }

  add(): void {
    this.noteService.promptAdd(this.study, "addNoteFromList", "noteSection");
  }

  edit(note: BusinessModels.StudyNote): void {
    this.noteService.promptEdit(note, this.createIdForNote(note), this.createIdForNote(note));
  }

  delete(note: BusinessModels.StudyNote): void {
    this.noteService.delete(note);
  }

  createIdForNote(note: BusinessModels.StudyNote) {
    return `study-note-${note.id}`;
  }
}

export default NotesController;