import { IController} from "angular";
import { createDialogOptions } from "./examTypeNameConfigurationNewCtrl";
import { BusinessModelService } from "../../businessModels";
import examTypeNameConfigurationService, { IExamTypeMappingModel }
    from "./examTypeNameConfigurationService";

class examTypeNameConfigurationController implements IController {

    static $inject = [
        "$log",
        "$mdDialog",
        "$mdToast",
        "businessModels",
        "examTypeNameConfigurationService"];
    constructor(
        private readonly $log: angular.ILogService,
        private readonly $mdDialog: angular.material.IDialogService,
        private readonly $mdToast: angular.material.IToastService,
        private readonly models: BusinessModelService,
        private readonly mappingService: examTypeNameConfigurationService
    ) { }

    $onInit(): void {
        this.mappingService.reloadSettings();
    }

    private onNew(): void {
        const opt: angular.material.IDialogOptions = createDialogOptions(
            { masterName: null, customName: null } as IExamTypeMappingModel);
        this.$mdDialog.show(opt).then((x: IExamTypeMappingModel) => {
            this.mappingService.examTypeMappings.push(x);
            let jsonOutput = this.mappingService.setSettingsToJSON(
                this.mappingService.examTypeMappings);
            this.saveSettings(jsonOutput, "creating");
        });
    }

    private onDelete(mapping: IExamTypeMappingModel): void {
        let reducedArray = this.mappingService.examTypeMappings.filter(
            m => m.masterName !== mapping.masterName);
        let jsonOutput = this.mappingService.setSettingsToJSON(reducedArray);
        this.saveSettings(jsonOutput, "deleting");
    }

    private onCancel(mapping: IExamTypeMappingModel): void {
        this.mappingService.reloadSettings();
    }

    private onSave(mapping: IExamTypeMappingModel): void {
        let jsonOutput = this.mappingService.setSettingsToJSON(
            this.mappingService.examTypeMappings);
        this.saveSettings(jsonOutput, "saving");
    }

    private saveSettings = (jsonOutput: string, msg: string) => {
        this.models.Institute.current.setSetting(this.mappingService.settingKey, jsonOutput);
        return this.models.saveEntities(
            this.models.Institute.current.settings.filter(
                s => s.key === this.mappingService.settingKey))
            .then(() => {
                this.$mdToast.showSimple("Settings saved.");
                this.mappingService.reloadSettings();
            })
            .catch(e => {
                this.$log.error("Exeption: " + e);
                this.$mdDialog.show(this.$mdDialog.alert()
                    .textContent("There was a problem " + msg + " the mapping. Please try again.")
                    .ok("OK"));
            });
    }
}

export default <angular.IComponentOptions>{
    controller: examTypeNameConfigurationController,
    templateUrl: require('./examTypeNameConfigurationList.html')
}