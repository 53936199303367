import * as angular from "angular";

class Controller implements angular.IComponentController {
    showToolbar: boolean;
    showButton: boolean;

    static $inject = ["$transclude"];
    constructor(private transclude: any) { }

    $onInit = () => {
        this.showButton = this.transclude.isSlotFilled("primaryButton");
        this.showToolbar = this.showButton || this.transclude.isSlotFilled("header");
    };
}

export default <angular.IComponentOptions>{
    templateUrl: require("./adminSection.html"),
    transclude: {
        "header": "?adminHeader",
        "primaryButton": "?adminPrimaryButton"
    },
    controller: Controller
}