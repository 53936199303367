import {
  IParseService, module as ngModule, element as ngElement
} from "angular";
import filterModule from "./condition-expression.filters";
import expressionSerialiserModule, {
  serviceName as expressionSerialiserName,
  IBlueprintExpressionSerialiser
} from "./condition-expression-serialiser.service";
import { BlueprintExpression } from './condition-expression.models';
import { WidgetController, WidgetService, IWidgetCompilationContext } from "./widget";

/** The name of the service used to manage expressions in the report/blueprint. */
export const serviceName = "reportExpression";
/** The query selector used to find widgets of this type. */
export const querySelector = "[data-expression]";

/** The most basic raw DOM string for creating report expressions. */
const rawDom = "<span data-expression>";

/** A service to evaluate an expression and output the result as text into the Blueprint. */
export class ExpressionService extends WidgetService<ExpressionController> {
  static $inject = ["$parse", expressionSerialiserName];
  constructor(
    private readonly $parse: IParseService,
    private readonly exprSer: IBlueprintExpressionSerialiser) {
      super(querySelector);
  }

  /** Creates a new detached expression object. The result can be attached by access thing $element
   * member. */
  create(): ExpressionController {
    return new ExpressionController(ngElement(rawDom), this.$parse, this.exprSer);
  }

  /** Gets a controller object for the provided DOM. It is not checked first. */
  get(element: JQuery): ExpressionController {
    return new ExpressionController(element, this.$parse, this.exprSer);
  }
}

/** An object representing some DOM which displays an expression and optionally a value in the
 * blueprint. */
export class ExpressionController extends WidgetController {

  /** The value of the expression. Updated when `this.refresh()` is called. */
  result: any;

  constructor(
    readonly $element: angular.IAugmentedJQuery,
    private readonly $parse: IParseService,
    private readonly exprSer: IBlueprintExpressionSerialiser) {
      super();
  }

  /** Gets the expression from the DOM. */
  get expression(): BlueprintExpression {
    const exprNode = this.getExpressionNode().children();
    if (exprNode.length > 0) {
      return this.exprSer.deserialise(exprNode);
    }
    return undefined;
  }

  /** Sets the expression into the DOM. */
  set expression(expression: BlueprintExpression) {
    const exprNode = this.getExpressionNode(true);
    if (exprNode.length > 0) {
      exprNode.children().remove();
    }
    if (expression) {
      const serialisedExpression = this.exprSer.serialise(expression);
      exprNode.append(serialisedExpression);
    }
  }

  /** Refreshes `this.result` from the current expression and context, then updates the text
   * displayed in the DOM. */
  refresh(context: IWidgetCompilationContext) {
    const expressionText = this.getExpressionNode().text();
    const compiledExpression = this.$parse(expressionText);
    const result = this.result = compiledExpression(context.values, { $marker: (name) => context.markers[name] });

    if (result != null) {
      this.getExpressionValueNode(true).text("" + result);
    } else {
      this.getExpressionValueNode().remove();
    }
  }

  /** Gets whether this object was created with an appropriate element. */
  get isValid() {
    return this.$element != null && this.$element.length === 1 && this.$element.is(querySelector);
  }

  /** Replaces this expression node with the value of it's expression, or removes it if there is no
   * value. This node should have been refreshed by the caller prior to this call. */
  strip() {
    const valueNode = this.getExpressionValueNode();
    if (valueNode != null && valueNode.length > 0) {
      this.$element.replaceWith(Array.from(valueNode[0].childNodes));
      return;
    }
    // If there's no expression value then remove the entire thing.
    this.remove();
  }

  /** Gets the expression node. The child can be passed directly to the expression serialiser.
   * @param createIfNotExists Whether to create the node and attach it to the DOM if it doesn't
   * already exist. */
  getExpressionNode(createIfNotExists: boolean = false) {
    let condition = this.$element.children(".expression-text");
    if (condition.length === 0 && createIfNotExists) {
        condition = ngElement('<span class="expression-text">');
        condition.prependTo(this.$element);
    }
    return condition;
  }

  /** Gets the node which holds the expression value.
   * @param createIfNotExists Whether to create the node and attach it to the DOM if it doesn't
   * already exist. */
  getExpressionValueNode(createIfNotExists: boolean = false) {
    let condition = this.$element.children(".expression-value");
    if (condition.length === 0 && createIfNotExists) {
        condition = ngElement('<span class="expression-value">');
        condition.appendTo(this.$element);
    }
    return condition;
  }
}

export default ngModule("midas.blueprint.expression", [
    filterModule.name,
    expressionSerialiserModule.name
]).service(serviceName, ExpressionService);