import { MeasurementController, IMeasurementScope } from "../MeasurementController";
import * as angular from "angular";

class HipController extends MeasurementController {
  static $inject = ["$scope"];
  constructor(scope: IMeasurementScope) {
    super(scope);
  }

  clickRightOK() {
    this.setMeasurementValue("MSKHipRtAnteriorNormal", "ALL NORMAL");
    this.setMeasurementValue("MSKHipRtLateralNormal", "ALL NORMAL");
    this.setMeasurementValue("MSKHipRtMedialNormal", "ALL NORMAL");
    this.setMeasurementValue("MSKHipRtPosteriorNormal", "ALL NORMAL");
    this.setMeasurementValue("MSKHipRtBursaeNormal", "ALL NORMAL");
    this.setMeasurementValue("MSKHipRtIncidentalNormal", "ALL NORMAL");
    console.log("Right All normal clicked")
  }

  clickLeftOK() {
    this.setMeasurementValue("MSKHipLtAnteriorNormal", "ALL NORMAL");
    this.setMeasurementValue("MSKHipLtLateralNormal", "ALL NORMAL");
    this.setMeasurementValue("MSKHipLtMedialNormal", "ALL NORMAL");
    this.setMeasurementValue("MSKHipLtPosteriorNormal", "ALL NORMAL");
    this.setMeasurementValue("MSKHipLtBursaeNormal", "ALL NORMAL");
    this.setMeasurementValue("MSKHipLtIncidentalNormal", "ALL NORMAL");
    console.log("Left All normal clicked")
  }
}

angular.module("midas.utility.measurement.views").controller("HipCtrl", HipController);