import { MeasurementController, IMeasurementScope } from "../MeasurementController";
import * as angular from "angular";
class MRTMJointsController extends MeasurementController {
  static $inject = ["$scope"];
  constructor(scope: IMeasurementScope) {
    super(scope);
  }

  clickOK() {
    this.setMeasurementValue("MRTMJointsRtOtherComment", "Normal skull. ");
    this.setMeasurementValue("MRTMJointsConclusion", "No fracture or other significant abnormality is seen. The calvaria and skull base are normal. ");
    console.log("All normal clicked")
  }

}

angular.module("midas.utility.measurement.views").controller("MRTMJointsCtrl", MRTMJointsController);
