import * as angular from 'angular';

import videogularModule from "videogular/dist/videogular/videogular.js";
import videogularControlsModule from "videogular/dist/controls/vg-controls.js";
import videogularOverlayModule from "videogular/dist/overlay-play/vg-overlay-play.js";
import videogularPosterModule from  "videogular/dist/poster/vg-poster.js";
import "videogular/dist/themes/default/videogular.css";

export default angular.module("videogular", [
    videogularModule,
    videogularControlsModule,
    videogularOverlayModule,
    videogularPosterModule
]);