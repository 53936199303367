import { IDisposable } from "../../../utility/utils";

export interface IReferenceGroupAction {
    htmlIdSuffix: string;
    text?: string;
    tooltip: string;
    execute(): void;
    iconClasses?: string;
}

export default  class ReferenceGroup {
    constructor(public name: string, public thumbnailTemplateUrl: string, public itemTemplateUrl: string) {
        this.thumbnails = [];
        this.actions = [];
        this.selectedItem = null;
        this.id = new Date().getMilliseconds();
        this.iconClass = "glyphicon glyphicon-cog";
        this.orderBy = "noMatter";
    }

    thumbnails: Array<any>;
    actions: Array<IReferenceGroupAction>;
    selectedItem: any;
    id: number;
    iconClass: string;
    orderBy: string;
    polling: boolean;

    loadThumbnails(): angular.IPromise<any> {
        return null;
    }

    /** Adds an action to this group.
     * @param action The action to add.
     * @returns A disposable for removing the action.
     */
    addAction(action: IReferenceGroupAction): IDisposable {
        this.actions.push(action);
        return () => {
            const index = this.actions.indexOf(action);
            if (index >= 0) {
                this.actions.splice(index, 1);
            }
        }
    }

    addThumbnail(thumbnail) {
        this.thumbnails.push(thumbnail);
        return thumbnail;
    }

    clearThumbnails() {
        return this.thumbnails = [];
    }

    setItem(item) {
        return this.selectedItem = item;
    }

    startPolling(timespan = 5000, initial = true) {
        var repoll;
        if (!initial && !this.polling) {
            return;
        }
        repoll = (function (_this) {
            return function () {
                var me;
                me = _this;
                return setTimeout((function () {
                    return me.startPolling(timespan, false);
                }), timespan);
            };
        })(this);
        if (initial) {
            this.polling = true;
        }
        return this.loadThumbnails().then((function (_this) {
            return function () {
                return repoll();
            };
        })(this))["catch"]((function (_this) {
            return function () {
                return repoll();
            };
        })(this));
    }

    stopPolling() {
        return this.polling = false;
    }

    next() {
        var currentIndex;
        if (!this.thumbnails || this.thumbnails.length <= 1) {
            return;
        }
        if (!this.selectedItem) {
            return this.selectedItem = this.thumbnails[0];
        }
        currentIndex = this.thumbnails.indexOf(this.selectedItem);
        if (currentIndex === this.thumbnails.length - 1) {
            return this.selectedItem = this.thumbnails[0];
        }
        return this.selectedItem = this.thumbnails[currentIndex + 1];
    }

    previous() {
        var currentIndex;
        if (!this.thumbnails || this.thumbnails.length <= 1) {
            return;
        }
        if (!this.selectedItem) {
            return this.selectedItem = this.thumbnails[0];
        }
        currentIndex = this.thumbnails.indexOf(this.selectedItem);
        if (currentIndex === 0) {
            return this.selectedItem = this.thumbnails[this.thumbnails.length - 1];
        }
        return this.selectedItem = this.thumbnails[currentIndex - 1];
    }
}