import { MeasurementController, IMeasurementScope } from "../MeasurementController";
import * as angular from "angular";
class DXBoneSurveyController extends MeasurementController {
  static $inject = ["$scope"];
  constructor(scope: IMeasurementScope) {
    super(scope);
  }

  clickOK() {
    this.setMeasurementValue("DXBoneSurveyRtLyticLesions", "None. ");
    this.setMeasurementValue("DXBoneSurveyRtAlignment", "The bones are in normal anatomic alignment. There is no evidence of fracture or dislocation. ");
    this.setMeasurementValue("DXBoneSurveyRtJoints", "Normal. No arthritis is present. ");
    this.setMeasurementValue("DXBoneSurveyRtMineralization", "Normal. ");
    this.setMeasurementValue("DXBoneSurveyRtDegenerativeDisc", "None. ");
    this.setMeasurementValue("DXBoneSurveyRtOtherComment", "None. ");
    this.setMeasurementValue("DXBoneSurveyConclusion", "Negative examination. No evidence of multiple myeloma. ");
    console.log("All normal clicked")
  }

}

angular.module("midas.utility.measurement.views").controller("DXBoneSurveyCtrl", DXBoneSurveyController);
