import * as angular from "angular";
import {  
  module,
  IComponentController,
  IDirective,
  IScope,
  IDocumentService,
  IAugmentedJQuery, 
  material
} from "angular";

export class NewLayoutItemController implements IComponentController {

  static $inject = ["$scope", "$element", "$document", "$mdToast"];
  constructor(
    private readonly scope: IScope,
    private readonly newElement: IAugmentedJQuery,
    private readonly documentService: IDocumentService,
    private readonly mdToast: material.IToastService) { }

  $onInit() {
    this.newElement.css(this.elementStyle);
    this.newElement.on('mousedown', (event) => this.mousedown(event));
  }

  elementShift = {
    startX : 0,
    startY : 0,
    deltaX : 0,
    deltaY : 0
  } 

  elementStyle = {
    position: "relative",
    cursor: "pointer",
    left: "0px",
    top: "0px"
  };

  /** Tried to find a balance between performance and precision. 
   * Therefore, the increment on the x-axis is calculated as x = x + ++increment/x - ++increment 
   * to increase the speed with every step while y-axis has just the increment y++/y-- 
   * to not to miss a closest sibling. **/

  searchDown = (xPoint: number, yPoint: number, rightLimit: number, bottomLimit: number): Element => {
    var elem;
    do {
      var x = xPoint;
      var increment = 0;
      do {
          elem = document.elementFromPoint(x, yPoint);
          if (elem && elem.getAttribute("gridster-item") === "element.layout") {
            return elem;
          } else {
            x = x + ++increment;
          }
        } while(x <= rightLimit);
        yPoint++;
    } while(yPoint <= bottomLimit);
    return elem;
  }

  searchUp = (xPoint: number, yPoint: number, leftLimit: number, topLimit: number): Element => {
    var elem;
    do {
      var x = xPoint;
      var increment = 0;
      do {
        elem = document.elementFromPoint(x, yPoint);
        if (elem && elem.getAttribute("gridster-item") === "element.layout") {
          return elem;
        } else {
          x = x - ++increment;
        }
      } while(x >= leftLimit);
      yPoint--;
    } while(yPoint >= topLimit);
    return elem;
  }

  mousedown = (event) => {
    event.preventDefault();
    this.elementShift.startX = event.pageX - this.elementShift.deltaX;
    this.elementShift.startY = event.pageY - this.elementShift.deltaY;
    this.documentService.on('mousemove', this.mousemove);
    this.documentService.on('mouseup', this.mouseup);
  }

  mousemove = (event) => {
    this.elementShift.deltaX = event.pageX - this.elementShift.startX;
    this.elementShift.deltaY = event.pageY - this.elementShift.startY;
    this.elementStyle.left = this.elementShift.deltaX + 'px';
    this.elementStyle.top = this.elementShift.deltaY + 'px';
    this.newElement.css(this.elementStyle);
  }

  mouseup = (event) => {
    const scrollContainer = document.getElementById("layout-scroll");
    const parentContainer = document.getElementsByClassName("mds-content")[0];
    const parentContainerRect = parentContainer.getBoundingClientRect();

    const containerLeftLimit = scrollContainer.scrollLeft + parentContainerRect.left;
    const containerTopLimit = scrollContainer.scrollTop + parentContainerRect.top;
    const containerRightLimit = scrollContainer.scrollLeft + parentContainerRect.left + parentContainerRect.width;
    const containerBottomLimit = scrollContainer.scrollTop + parentContainerRect.top + parentContainerRect.height;

    //Find the gridster-item closest to the new element's dropping point and
    //place the new element next to it, shifting the rest of the elements down.
    const sibling = this.findClosestSibling(
      event.pageX,
      event.pageY,
      containerLeftLimit, 
      containerTopLimit, 
      containerRightLimit,
      containerBottomLimit);

    if (sibling != null) {
      //Find closest mds-measurement-layout container's id.
      const parentScopeId = angular.element(sibling.parentElement.parentElement).scope().$parent.$id;
      //Find position of the sibling element in the layout template.
      const index = angular.element(sibling.parentElement).scope().$index; 
      this.scope.$broadcast("addLayoutElement", [this.newElement.attr("name"), index, parentScopeId]);
    }
    else {
      this.mdToast.showSimple("Invalid element placement.");
    }

    //Reset to the initial state
    this.detach();
    this.elementStyle.left = '0px';
    this.elementStyle.top = '0px';
    this.newElement.css(this.elementStyle);
    this.elementShift = {startX : 0, startY : 0, deltaX : 0, deltaY : 0};
  }

  findClosestSibling = (
    x: number, 
    y: number, 
    leftLimit: number, 
    topLimit: number, 
    rightLimit: number, 
    bottomLimit: number): Element => {
      var elem = document.elementFromPoint(x, y);
      if (elem != null) {
        if (elem.getAttribute("gridster-item") === "element.layout")
          return elem;
        else {
          return elem.hasAttribute("gridster") === true
            ? this.searchDown(x, y, rightLimit, bottomLimit)
            : this.searchUp(x, y, leftLimit, topLimit)
        }
      }
      return elem;
  }

  detach(): void {
    this.documentService.off('mousemove', this.mousemove);
    this.documentService.off('mouseup', this.mouseup);
    this.newElement.off('mousedown', this.mousedown);
  }

  $onDestroy(){
    this.detach();
  }

}

const mdsNewLayoutItemDirective: IDirective = {
  controller: NewLayoutItemController
};

export default module("midas.admin.measurementLayout.newLayoutItemComponent", [])
.directive("mdsNewLayoutItem", () => mdsNewLayoutItemDirective);