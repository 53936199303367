import {
  module,
  gridster,
  IComponentController,
  IDirective,
  IAttributes,
  IScope, 
  material,
  IPromise
} from "angular";
import "angular-gridster";
import "angular-gridster/dist/angular-gridster.css";
import "./mds-measurement-layout.component.scss";
import itemComponent from "./mds-measurement-layout-item.component";
import {
  IMeasurementLayoutController,
  MeasurementUIElement,
  IExtendedGridsterConfig,
  GridsterItemExtended,
  IMeasurementElement,
  ILabelElement
} from "./mds-measurement-layout";

/** Displays a list of measurement layout elements in a grid. */
export class SectionSettingsController implements IComponentController, IMeasurementLayoutController {
  /** The list of elements to display. */
  template: MeasurementUIElement[];

  /** The gridster configuration for 1st level grid items. */
  gridsterConfig: IExtendedGridsterConfig = {
    rowHeight: "45",
    margins: [5, 5],
    mobileModeEnabled: false,
    draggable: {
      enabled: false
    },
    resizable: {
      enabled: false,
      handles: ["se"]
    },
    sparse: true,
    maxRows: 250,
    columns: 8
  };

  static $inject = ["$scope", "$attrs", "$mdDialog", "$mdToast"];
  constructor(
    private readonly $scope: IScope,
    private readonly $attrs: IAttributes,
    private readonly $mdDialog: material.IDialogService,
    private readonly $mdToast: material.IToastService) { }

  $onInit() {
    this.template = this.$scope.$eval(this.$attrs.mdsItems);
    this.$scope.$watch(() => this.$scope.$eval(this.$attrs.mdsMode), (mode) => this.updateGridsterSettings(mode));
    this.$scope.$on("addLayoutElement", (evt, data) => {      
      if (this.$scope.$id === data[2]) { this.add(data[0], data[1]) }
    });
  }

  updateGridsterSettings(mode: "view" | "edit") {
    this.$scope.mode = mode;
    const editable = mode === "edit";

    this.gridsterConfig.resizable.enabled = editable;
    this.gridsterConfig.draggable.enabled = editable;
  }

  remove(element: MeasurementUIElement) {
    this.template.splice(
      this.template.indexOf(element),
      1
    );
  }

  createNewElement(name: string, index: number): IPromise<MeasurementUIElement> {
    switch(name) {
       case 'measurement':
        return this.$mdDialog.showMeasurementTypePrompt(
          this.$scope.modality.getMeasurementTypes())
          .then(mt => {
            return { 
              type: "measurement",
              key: mt.key,            
              layout: {
                col: this.template[index].layout.col,
                row: this.template[index].layout.row,
                sizeX: 1,
                sizeY: 1
              }
            } as IMeasurementElement;        
          });
        case 'label':
          return this.$mdDialog.show(
            this.$mdDialog.prompt()
              .title("Label")
              .textContent("Enter text for your label below")
              .placeholder("Label text")
              .ariaLabel("Label text")
              .initialValue("New Label")
              .clickOutsideToClose(true)
              .ok("OK")
              .cancel("Cancel")
          ).then(text => {
            return { 
              type: "label",
              text: text,
              layout: {
                col: this.template[index].layout.col,
                row: this.template[index].layout.row,
                sizeX: 1,
                sizeY: 1
              }
            } as ILabelElement;
          });
        default:  
          this.$mdToast.showSimple("Cannot create the element at the specified locaton.");
      }
  }

  add(name: string, index: number) {
    this.createNewElement(name, index)
      .then(element => this.template.splice(index, 0, element))    
  }
}

const sectionSettingsDirective: IDirective = {
  controller: SectionSettingsController,
  controllerAs: "$ctrl",
  templateUrl: require("./mds-measurement-layout.component.html"),
  scope: false
};

/** A directive that monitors the height of the container and dynamically updates the sizeY of the gridster element.
 * This is useful for expansion panels that dynamically grow in size.
*/
const gridsterDynamicHeightDirective: IDirective = {
  restrict: "A",
  require: {
    gridsterItem: "^",
    gridster: "^"
  },
  link: (scope, element, _attrs, controllers) => {
    // tslint:disable-next-line:no-string-literal
    const config: gridster.GridsterConfig = controllers["gridster"];
    const item: GridsterItemExtended = controllers["gridsterItem"];
    scope.$watch(
      () => element[0].scrollHeight,
      (newValue: number) => {
        newValue = newValue + (config.margins[1] * 2);
        const rowHeight = Number(config.rowHeight);
        const targetHeight = Math.floor(newValue / rowHeight) + 1;
        item.setSizeY(targetHeight);
      });
  }
};

export default module("midas.admin.measurementLayout.sectionSettingsComponent", ["gridster", itemComponent.name])
.directive("mdsMeasurementLayout", () => sectionSettingsDirective)
.directive("gridsterDynamicHeight", () => gridsterDynamicHeightDirective);