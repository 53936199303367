import * as angular from "angular";
import "angular-material";
import { User, BusinessModelService } from "../businessModels";
import { Crypto } from "../utility/utils";

class UserPasswordController implements angular.IController {

    newPassword: string;
    repeat: string;

    static $inject = ["$log", "$mdDialog", "$mdToast", "businessModels", "crypto", "user"];
    constructor(
        private readonly $log: angular.ILogService,
        private readonly $mdDialog: angular.material.IDialogService,
        private readonly $mdToast: angular.material.IToastService,
        private readonly models: BusinessModelService,
        private readonly crypto: Crypto,
        private readonly user: User
    ) { }

    $onInit(): void {
        this.newPassword = null;
        this.repeat = null;
    }

    accept(): angular.IPromise<void> {

        if (!this.newPassword) return;

        this.user.password = this.crypto.sha1(this.newPassword, true);
        return this.models.save()
            .then(() => {
                this.$mdToast.showSimple("Password updated.");
                this.$mdDialog.hide();
            })
            .catch(e => {
                this.$log.error("Exeption: ", e);
                this.$mdToast.showSimple("There was a problem updating the password. Please try again.");
            });
    }

    cancel() {
        this.$mdDialog.cancel();
    };

    validatePassword(scope: angular.IScope): void {
        if (scope.editUserPassword.password.$modelValue && scope.editUserPassword.repeat.$modelValue) {
            if (scope.editUserPassword.password.$modelValue === scope.editUserPassword.repeat.$modelValue) {
                scope.editUserPassword.password.$setValidity("match", true)
            }
            else {
                scope.editUserPassword.password.$setValidity("match", false)
            }
        }
    }
}

function passwordPrompt(user: User)
    : angular.material.IDialogOptions {
    return {
        templateUrl: require('./userPassword.html'),
        controller: UserPasswordController,
        locals: {
            user: user
        },
        controllerAs: "$ctrl",
        bindToController: true
    }
}
export type IPasswordPromptType = typeof passwordPrompt;

export default angular.module("midas.utility.userPassword", [])
    .value("passwordPrompt", passwordPrompt);