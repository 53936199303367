import {
  module,
  IComponentController,
  material,
  IScope,
  copy,
  toJson,
  fromJson,
  FileSaver
} from "angular";
import { BusinessModelService, ExamType } from "../../businessModels";
import sectionSettings from "../../utility/measurement/config/mds-measurement-layout.component";
import "./measurement-layout-section.component.scss";

import { debounce } from "lodash";

/** These imports put types on "angular" */
import "angular-file-saver";
import "ng-file-upload";
import serviceModule, {
  MeasurementLayoutService, serviceName
} from "../../utility/measurement/config/mds-measurement-layout.service";
import { MeasurementUIElement } from "../../utility/measurement/config/mds-measurement-layout";

const uploadErrorMessage = "There was an issue uploading the layout. Please reload the page and try again.";
const uploadSuccessMessage = "Layout uploaded.";

const restoreErrorMessage = "There was an issue restoring the layout. Please reload the page and try again.";
const restoreSuccessMessage = "Layout restored.";

const saveErrorMessage = "There was an issue saving the layout. Please try again.";
const saveSuccessMessage = "Layout saved.";

/** Admin section to customise measurement layouts. */
export class MeasurementLayoutSectionComponentController implements IComponentController {
  loadedExamType: ExamType;
  editable: boolean;
  loading: boolean;
  debounceChange = debounce(this.change, 1000);

  static $inject = ["$scope", "businessModels", "$mdToast", "FileSaver", serviceName];
  constructor(
    private readonly $scope: IScope,
    private readonly models: BusinessModelService,
    private readonly $mdToast: material.IToastService,
    private readonly fileSaver: FileSaver,
    private readonly layoutService: MeasurementLayoutService) { }

  $onInit() {
    this.$scope.$watch("template", () => this.debounceChange(), true);
  }

  change() {
    this.loading = false;
  }

  /** Search for exam type to populate autocomplete. */
  querySearch(searchText: string): ExamType[] {
    return this.models.ExamType.listActive().filter(x => x.key.toUpperCase().indexOf(searchText.toUpperCase()) !== -1);
  }

  reload(examType: ExamType) {
    this.loadedExamType = null;
    if (!examType) return;
    this.loading = true;
    return examType.modality.load().then(() => {
      this.layoutService.get(examType).then(template => {
        if (template == null && this.$scope.template == null) this.debounceChange();
        else this.$scope.template = template;
        this.loadedExamType = examType;
      });
    });
  }

  upload(file: File): void {
    if (file == null) {
      return;
    }
    this.loading = true;
    const reader = new FileReader();
    reader.onload = () => {
      const template = fromJson(<string> reader.result);
      this.$scope.template = template;
      this.$mdToast.showSimple(uploadSuccessMessage);
    };
    reader.onerror = () => this.$mdToast.showSimple(uploadErrorMessage);
    reader.readAsText(file);
  }

  download() {
    this.fileSaver.saveAs(
      new Blob(
        [toJson(this.stripExplicitLayout(this.$scope.template), true)], {
          type: "text/html;charset=utf-8"
        }),
      `measurement-layout-${this.loadedExamType.key}.json`
    );
  }

  /** Strip explicit columns and rows from configuration so it is easier to reorder in a text editor.
   * When re-uploaded it will calculate the rows and columns implicitly from the order of elements.
   */
  private stripExplicitLayout(layout: MeasurementUIElement[]): MeasurementUIElement[] {
    const cloned = new Array<MeasurementUIElement>();
    copy(layout, cloned);
    cloned.forEach(x => {
      delete x.layout.col;
      delete x.layout.row;
    });
    return cloned;
  }

  /** Restore default by removing the custom layout. */
  restoreDefault(): void {
    this.layoutService
      .update(this.loadedExamType, null)
      .then(x => this.reload(this.loadedExamType))
      .then(x => this.$mdToast.showSimple(restoreSuccessMessage))
      .catch(x => this.$mdToast.showSimple(restoreErrorMessage));
  }

  save(template: MeasurementUIElement[]) {
    template = template == null ? this.$scope.template : template;
    return this.layoutService
            .update(this.loadedExamType, template)
            .then(() => this.$mdToast.showSimple(saveSuccessMessage))
            .catch(() => this.$mdToast.showSimple(saveErrorMessage));
  }
}

export default module(
  "midas.admin.measurementLayout.sectionController",
  [sectionSettings.name, serviceModule.name]
)
.component("measurementLayoutSection", {
  controller: MeasurementLayoutSectionComponentController,
  templateUrl: require("./measurement-layout-section.component.html")
});