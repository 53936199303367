import { IScope } from 'angular';
import { IStateService, IStateParamsService } from 'angular-ui-router';
import { BusinessModelService } from "../../businessModels";
import { LoadingStatus } from '../../utility/utils';
import { IAuthService } from '../../authService';

export default [
  "$scope",
  "$state",
  "$stateParams",
  "businessModels",
  "authService",
  "loadingStatus",
  function(
    $scope: IScope,
    $state: IStateService,
    $stateParams: IStateParamsService,
    models: BusinessModelService,
    authService: IAuthService,
    loadingStatus: typeof LoadingStatus
  ) {
      const autoLogin = models.User.autoLoginEnabled
          // only auto-login if it's enabled and the institute name is a GUID.
          && /\b[A-F0-9]{8}(?:-?[A-F0-9]{4}){3}-?[A-F0-9]{12}\b/i.test($stateParams.inst);

    if (!autoLogin && $stateParams.expired) {
      $scope.expired = true;
    } else if (authService.isAuthenticated()) {
      $state.go("midas", { inst: $stateParams.inst });
    }

    $scope.instCheck = new loadingStatus();

    if ($stateParams.inst) {
      const checkIsFree = models.Institute.checkKeyIsFree($stateParams.inst);
      $scope.instCheck.track(checkIsFree);
      checkIsFree.then(function(available) {
        if (available) {
          return $state.go("loginGuide");
        }
      });
    } else {
      $state.go("loginGuide");
    }

    $scope.user = {
      userName: autoLogin ? "Admin1" : "",
      password: autoLogin ? "ILikeCats" : "",
      remember: true,
      error: null,
      login() {
        this.busy = true;
        this.error = null;
        return authService
          .authenticate(
            this.userName,
            $stateParams.inst,
            this.password,
            this.remember
          )
          .then(function() {
            if ($stateParams.redirect) {
              const paramsArray = $stateParams.params.split("+");
              const params: { [key: string]: any } = {};
              for (let item of paramsArray) {
                const x = item.split("=");
                if (x.length !== 2) {
                  continue;
                }
                params[x[0]] = x[1];
              }
              params.inst = $stateParams.inst;
              return $state.go($stateParams.redirect, params);
            } else {
              return $state.go("midas", { inst: $stateParams.inst });
            }
          })
          .catch(error => {
            if (
              !(this.error =
                error.data != null ? error.data.error_description : undefined)
            ) {
              this.error = error.statusText;
            }
            return (this.busy = false);
          });
      }
    };

    if (autoLogin) {
      $scope.user.login();
    }
  }
];