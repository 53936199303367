import * as angular from "angular";
import {
    GlossaryPromptDialogOptions,
    IGlossaryPromptResult
} from "./glossaryPrompt";
import {
    ExamSelections,
    GlossaryRestrictPromptDialogOptions,
    IGlossaryRestrictPromptResult,
    GlossaryRoleVisibility
} from "./glossaryRestrictPrompt";
import {
    AdminProvider
} from "../../adminProvider";
import {
    BindingOf,
    IChangesObject
} from "../../../utility/componentBindings";
import {
    ExamType,
    GlossaryCategory,
    Glossary,
    BusinessModelService,
    IBusinessModel
} from "../../../businessModels";
import { LoadingStatus } from '../../../utility/utils';

class Controller implements angular.IComponentController {
    examTypes: Array < ExamType > ;
    categories: Array < GlossaryCategory > ;
    selectedCategory: GlossaryCategory;
    selectedEntry: Glossary;

    loadingStatus: LoadingStatus;
    saveStatus: LoadingStatus;

    breadcrumbs: Array < string > ;

    selectedVisualStackLevel: "list" | "category" | "entry";

    static $inject = ["$log", "$mdDialog", "$mdToast", "businessModels", "loadingStatus", "$mdSidenav"];
    constructor(private readonly $log: angular.ILogService,
        private readonly mdDialog: angular.material.IDialogService,
        private readonly mdToast: angular.material.IToastService,
        private readonly models: BusinessModelService,
        LoadingStatusCtor: typeof LoadingStatus,
        private readonly mdSideNav: angular.material.ISidenavService) {
        this.loadingStatus = new LoadingStatusCtor();
        this.saveStatus = new LoadingStatusCtor();
    }

    $onInit(): void {
        this.updateBreadcrumbs();
        this.loadExamTypes();
        this.reload();
    }

    private loadExamTypes() {
        this.examTypes = this.models.ExamType.listActive();
    }

    private reload(): angular.IPromise < void > {
        this.$log.debug("Reloading glossary categories...");
        const wait = this.models.GlossaryCategory.list().then((categories) => {
            this.categories = categories;
            this.$log.debug("Loaded glossary categories.", categories);
        });
        this.loadingStatus.track(wait);
        return wait;
    }

    selectCategory(category: GlossaryCategory) {
        this.$log.debug("Category selected", category);
        this.selectedCategory = category;
        this.updateBreadcrumbs();
        if (category)
            this.selectedVisualStackLevel = "category";
        else
            this.selectedVisualStackLevel = "list";
    }

    deleteCategory(category: GlossaryCategory) {
        this.$log.debug("Category to be deleted", category);
        this.saveStatus.track(this.deleteCommitAndReload(category).then(() => this.mdToast.showSimple("Category deleted")));
        if (category === this.selectedCategory)
            this.selectCategory(null);
    }

    selectEntry(entry: Glossary) {
        this.$log.debug("Entry selected", entry);
        if (entry) {
            this.selectCategory(entry.category);
        }
        this.selectedEntry = entry;
        this.updateBreadcrumbs();

        if (entry)
            this.selectedVisualStackLevel = "entry";
        else
            this.selectedVisualStackLevel = "category";
    }

    deleteEntry(entry: Glossary) {
        this.$log.debug("Entry to be deleted", entry);
        this.saveStatus.track(this.deleteCommitAndReload(entry).then(() => this.mdToast.showSimple("Entry deleted")));
        this.selectedVisualStackLevel = "category";
        if (entry === this.selectedEntry)
            this.selectEntry(null);
    }

    private deleteCommitAndReload(model: IBusinessModel): angular.IPromise < void > {
        let markDeleted = () => model.isDeleted = true;
        return this.models.save(markDeleted).then(() => this.reload());
    }

    entryUpdated() {
        this.models.save()
            .then(() => this.updateBreadcrumbs())
            .catch(() => this.mdToast.showSimple("There was an error saving"));
    }

    promptEntry(category ? : GlossaryCategory): angular.IPromise < void > {
        return this.mdDialog.show(new GlossaryPromptDialogOptions(this.examTypes, this.categories, category))
            .then((result: IGlossaryPromptResult) => {
                this.$log.debug("Got dialog result", result);
                const save = this.models.save();
                save.then(() => this.reload().then(() => this.selectEntry(result.entry)));
                save.then(() => this.mdToast.showSimple("Entry created"));
                return save;
            })
            .catch((error) => {
                // user cancelled.
                if (!error) {
                    return;
                }
                const message: string = "There was an error saving the new entry.";
                this.mdToast.showSimple(message);
                this.$log.error(message, error, error.entityErrors);
                this.models.breeze.rejectChanges();
            });
    }

    promptRestrict(category: GlossaryCategory) {
        const examSelections = new ExamSelections(this.examTypes, category.examTypes);
        const visibiilty = this.getRoleVisibility(category);
        this.mdDialog.show(new GlossaryRestrictPromptDialogOptions(examSelections, visibiilty))
            .then((result: IGlossaryRestrictPromptResult) => {
                const saveWait = this.models.save(() => {
                    this.setRoleVisibility(category, result.roleVisibility);
                    this.setExamRestrictions(category, result);
                }).then(() => this.mdToast.showSimple("Restrictions updated"));
                this.saveStatus.track(saveWait);
                return saveWait;
            });
    }

    categoryChanged(category: GlossaryCategory) {
        this.saveStatus.track(this.models.save().then(() => this.updateBreadcrumbs()));
    }

    private getRoleVisibility(category: GlossaryCategory): GlossaryRoleVisibility {
        let roleVisibility = GlossaryRoleVisibility.All;
        if (category.isPhysicianOnly && !category.isTechnicianOnly) {
            roleVisibility = GlossaryRoleVisibility.Physician;
        } else if (category.isTechnicianOnly && !category.isPhysicianOnly) {
            roleVisibility = GlossaryRoleVisibility.Sonographer;
        }
        return roleVisibility;
    }

    private setRoleVisibility(category: GlossaryCategory, result: GlossaryRoleVisibility): void {
        switch (result.toString()) {
            case GlossaryRoleVisibility.All.toString():
                category.isTechnicianOnly = category.isPhysicianOnly = false;
                break;
            case GlossaryRoleVisibility.Sonographer.toString():
                category.isTechnicianOnly = true;
                category.isPhysicianOnly = false;
                break;
            case GlossaryRoleVisibility.Physician.toString():
                category.isTechnicianOnly = false;
                category.isPhysicianOnly = true;
                break;
        }
    }

    private setExamRestrictions(category: GlossaryCategory, result: IGlossaryRestrictPromptResult): void {
        const existingSelections = category.examTypes.map(x => x.key);
        for (var selection of result.examSelections.selections) {
            const alreadySelected = existingSelections.indexOf(selection.examType.key) !== -1;
            if (result.examSelections.selectAllOverride) {
                category.removeExamType(selection.examType);
            } else if (selection.selected && !alreadySelected) {
                category.addExamType(selection.examType);
            } else if (!selection.selected && alreadySelected) {
                category.removeExamType(selection.examType);
            }
        }
    }

    private updateBreadcrumbs() {
        this.breadcrumbs = [];
        if (this.selectedCategory) {
            this.breadcrumbs.push(this.selectedCategory.name);
        }
        if (this.selectedEntry) {
            this.breadcrumbs.push(this.selectedEntry.abbreviation);
        }
    }
}

export default < angular.IComponentOptions > {
    templateUrl: require('./glossarySection.html'),
    controller: Controller
}