import { module as ngModule } from "angular";
import "angular-messages";
import { IStateProvider } from "angular-ui-router";
import AdminController from "./adminCtrl";
import { AdminProvider } from "./adminProvider";
import adminSectionComponent from "./directives/adminSection";
import mdsInstituteSettingsPanel from "./institute/settings-panel.component";
import configModule from "../config/config";
import glossaryModule from "./study/glossary/glossary";
import dicomPropertyMappings from "./institute/dicomPropertyMapping/dicomPropertyMapping.module";
import "./admin.scss";
import adminReportTemplateModule from "./study/reportTemplateCtrl";
import diagramTemplateComponent from "./study/diagramTemplates";
import uploadStudyComponent from "./study/uploadStudy";
import examTypeNameConfigurationComponent from "./institute/examTypeNameConfiguration";
import userManagementComponent from "./institute/userManagement";
import blueprintSectionComponent from "./reportFormatters/blueprint-section.component";
import measurementTypeOptionsComponent from "./institute/measurementTypeOptions/measurement-type-options.component";
import sitesModule from "./institute/site-administration.component";
import dicomDevicesModule from "./dicomDevice/dicom-device-administration.component";
import measurementLayout from "./measurementLayout/measurement-layout-section.component";
import billingInformation from "./institute/billingInformation.component";
import adminDashboardModule from "./institute/adminDashboard/adminDashboard";
import revertStudyStatusModule from "./study/revertStudyStatus";

export default
    ngModule("midas.admin", [
        "ui.router",
        "ngMessages",
        glossaryModule.name,
        configModule.name,
        dicomPropertyMappings.name,
        adminReportTemplateModule.name,
        diagramTemplateComponent.name,
        uploadStudyComponent.name,
        examTypeNameConfigurationComponent.name,
        userManagementComponent.name,
        blueprintSectionComponent.name,
        measurementTypeOptionsComponent.name,
        sitesModule.name,
        dicomDevicesModule.name,
        measurementLayout.name,
        billingInformation.name,
        adminDashboardModule.name,
        revertStudyStatusModule.name
    ])
    .provider("admin", AdminProvider)
    .component("adminSection", adminSectionComponent)
    .component("mdsInstituteSettingsPanel", mdsInstituteSettingsPanel)
    .config(["$stateProvider", (stateProvider: IStateProvider) => {
        stateProvider.state("midas.admin", {
            url: "/admin",
            views: {
                "layout@": {
                    templateUrl: require("./admin.html"),
                    controller: AdminController,
                    controllerAs: "$ctrl"
                }
            }
        });
    }])
    .config(["adminProvider", (adminProvider: AdminProvider) => adminProvider
        .registerSection({
            category: "Study",
            title: "Institute Settings",
            state: "instituteSettings",
            template: "<mds-institute-settings-panel></mds-institute-settings-panel>"
        })
        .registerSection({
            category: "Study",
            title: "Exam Type Name Configuration",
            state: "examTypeNameConfiguration",
            template: "<exam-type-name-configuration-list></exam-type-name-configuration-list>"
        })
        .registerSection({
            category: "Study",
            title: "Glossary",
            state: "glossary",
            template: "<glossary-section></glossary-section>"
        })
        .registerSection({
            category: "Study",
            title: "DICOM Property Mapping",
            state: "dicomPropertyMapping",
            template: "<mds-dicom-property-mappings></mds-dicom-property-mappings>"
        })
        .registerSection({
            category: "Study",
            title: "Report Template",
            state: "reportTemplate",
            template: "<report-template layout='column'></report-template>"
        })
        .registerSection({
            category: "Study",
            title: "Diagram Templates",
            state: "diagramTemplate",
            template: "<diagram-template-list></diagram-template-list>"
        })
        .registerSection({
            category: "Study",
            title: "Study Upload",
            state: "uploadStudy",
            template: "<upload-study layout='column'></upload-study>"
        })
        .registerSection({
            category: "User",
            title: "User Management",
            state: "userManagement",
            template: "<user-management-list></user-management-list>"
        })
        .registerSection({
            category: "Report",
            title: "Report Blueprints",
            state: "blueprints",
            template: "<blueprint-admin-section></blueprint-admin-section>"
        })
        .registerSection({
            category: "Study",
            title: "Measurement Dropdown Options",
            state: "measurementTypeOptions",
            template: "<measurement-type-options></measurement-type-options>"
        })
        .registerSection({
            category: "Study",
            title: "Sites",
            state: "sites",
            template: "<site-administration></site-administration>"
        })
        .registerSection({
            category: "DICOM",
            title: "DICOM Devices",
            state: "dicomDeviceAdmin",
            template: "<dicom-device-administration></dicom-device-administration>"
        })
        .registerSection({
          category: "Study",
          title: "Measurement Layout",
          state: "measurementLayout",
          template: "<measurement-layout-section></measurement-layout-section>"
        })
        .registerSection({
          category: "Institute",
          title: "Admin Dashboard",
          state: "adminDashboard",
          template: "<admin-dashboard></admin-dashboard>"
        })
        .registerSection({
          category: "Study",
          title: "Revert Study Status",
          state: "revertStudyStatus",
          template: "<revert-study-status></revert-study-status>"
        })
        //.registerSection({
        //  category: "Study",
        //  title: "Billing Information",
        //  state: "billingInformation",
        //  template: "<billing-information></billing-information>"
        //})
    ]);