import * as angular from "angular";
import { BindingOf, IChangesObject } from "../../../utility/componentBindings";
import { Glossary, GlossaryCategory } from "../../../businessModels";

interface IGlossaryEntrySelected {
    $entry: Glossary;
}

interface ComponentBindings {
    category: GlossaryCategory;
    onEntrySelected: (obj: IGlossaryEntrySelected) => void;
    onRestrictRequested: () => void;
    onDeleteRequested: () => void;
    onUpdated: () => void;
}

class Controller implements angular.IComponentController, ComponentBindings {
    
    onEntrySelected: (obj: IGlossaryEntrySelected) => void;
    onRestrictRequested: () => void;
    onDeleteRequested: () => void;
    onUpdated: () => void;

    category: GlossaryCategory;

    $onChanges(obj: IChangesObject<ComponentBindings>): void {
        if (obj.category) {
            this.category = obj.category.currentValue;
        }
    }

    selectEntry(entry: Glossary) {
        this.onEntrySelected({ $entry: entry });
    }

    restrict() {
        this.onRestrictRequested();
    }

    delete() {
        this.onDeleteRequested();
    }
}

export default <angular.IComponentOptions> {
    templateUrl: require('./glossaryCategory.html'),
    controller: Controller,
    bindings: {
        category: '<',
        onEntrySelected: '&',
        onRestrictRequested: '&',
        onDeleteRequested: '&',
        onUpdated: '&'
    } as BindingOf<ComponentBindings>
}