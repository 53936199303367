/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import * as angular from "angular";
angular.module("midas.utility.measurement.views").controller("ElbowCtrl", ["$scope", function($scope) {
 const { values } = $scope.measurements;
 const setRightOK = function() {
   values.MSKElbowRtCEONormal.value = "ALL NORMAL";
   values.MSKElbowRtPINNormal.value = "ALL NORMAL";
   values.MSKElbowRtRadialLigamentNormal.value = "ALL NORMAL";
   values.MSKElbowRtAnnularLigamentNormal.value = "ALL NORMAL";
   values.MSKElbowRtElbowJointNormal.value = "ALL NORMAL";
   values.MSKElbowRtBicepsBrachiiNormal.value = "ALL NORMAL";
   values.MSKElbowRtMedianNerveNormal.value = "ALL NORMAL";
   values.MSKElbowRtCFONormal.value = "ALL NORMAL";
   values.MSKElbowRtUlnarLigamentNormal.value = "ALL NORMAL";
   values.MSKElbowRtUlnarNerveNormal.value = "ALL NORMAL";
   values.MSKElbowRtTricepsTendonNormal.value = "ALL NORMAL";
   values.MSKElbowRtOlecranonBursaNormal.value = "ALL NORMAL";
   values.MSKElbowRtOlecranonFossaNormal.value = "ALL NORMAL";
   values.MSKElbowRtPosteriorOlecranonRecessNormal.value = "ALL NORMAL";
   values.MSKElbowRtForeignBodyNormal.value = "ALL NORMAL";
   values.MSKElbowRtSoftTissueMassNormal.value = "ALL NORMAL";
   values.MSKElbowRtSynovialCystNormal.value = "ALL NORMAL";
   values.MSKElbowRtBrachialVesselsNormal.value = "ALL NORMAL";
   console.log("Right All normal clicked");
 };
 $scope.clickRightOK = () => setRightOK();
 const setLeftOK = function() {
   values.MSKElbowLtCEONormal.value = "ALL NORMAL";
   values.MSKElbowLtPINNormal.value = "ALL NORMAL";
   values.MSKElbowLtRadialLigamentNormal.value = "ALL NORMAL";
   values.MSKElbowLtAnnularLigamentNormal.value = "ALL NORMAL";
   values.MSKElbowLtElbowJointNormal.value = "ALL NORMAL";
   values.MSKElbowLtBicepsBrachiiNormal.value = "ALL NORMAL";
   values.MSKElbowLtMedianNerveNormal.value = "ALL NORMAL";
   values.MSKElbowLtCFONormal.value = "ALL NORMAL";
   values.MSKElbowLtUlnarLigamentNormal.value = "ALL NORMAL";
   values.MSKElbowLtUlnarNerveNormal.value = "ALL NORMAL";
   values.MSKElbowLtTricepsTendonNormal.value = "ALL NORMAL";
   values.MSKElbowLtOlecranonBursaNormal.value = "ALL NORMAL";
   values.MSKElbowLtOlecranonFossaNormal.value = "ALL NORMAL";
   values.MSKElbowLtPosteriorOlecranonRecessNormal.value = "ALL NORMAL";
   values.MSKElbowLtForeignBodyNormal.value = "ALL NORMAL";
   values.MSKElbowLtSoftTissueMassNormal.value = "ALL NORMAL";
   values.MSKElbowLtSynovialCystNormal.value = "ALL NORMAL";
   values.MSKElbowLtBrachialVesselsNormal.value = "ALL NORMAL";
   console.log("Left All normal clicked");
 };
 $scope.clickLeftOK = () => setLeftOK();
}
 ]);
// CoffeeScript