import { module as ngModule, element as $, IAttributes, ILogService } from "angular";
import reportEditorModule, {
  TinymceConfigurationController,
  tinymceConfigurationRequire,
  ToolbarAttributes} from "../mds-rich-text-editor.component";
import { Editor, Settings, ui } from "tinymce";
import widgetsModule, {
  serviceName as widgetsServiceName,
  WidgetsService
} from '../../../utility/reportFormatter/widgets/widgets.service';
import isEditableModule, {
  serviceName as isEditableServiceName,
  EditableService
} from './editable.service';

const enum InsertPosition {
  Before,
  After
}

/**
 * A TinyMCE plugin which will display buttons for moving the cursor before or after an element.
 */
class InsertLocationController extends TinymceConfigurationController {

  static $inject = ["$attrs", widgetsServiceName, isEditableServiceName, "$log"];
  constructor(
    private readonly $attrs: IAttributes,
    private readonly widgets: WidgetsService,
    private readonly editable: EditableService) {
    super();
  }

  setup(editor: Editor) {
    const _this = this;

    const insertLeftButtonId = "insert-left";
    editor.addButton(insertLeftButtonId, {
      tooltip: "Insert before",
      icon: "fa fa-chevron-left",
      onPostRender() { _this.disableOnInvalidSelection(editor, <any>this, InsertPosition.Before); },
      onclick() {
        _this.editable.selectPrevEditPoint(editor.selection.getRng(true));
        // Signal the selection change to other plugins. For instance the cursor characters plugin
        // will add and remove characters to make sure we can actually type into the selected spot.
        editor.nodeChanged();
      }
    });

    const insertRightButtonId = "insert-right";
    editor.addButton(insertRightButtonId, {
      tooltip: "Insert after",
      icon: "fa fa-chevron-right",
      onPostRender() { _this.disableOnInvalidSelection(editor, <any>this, InsertPosition.After); },
      onclick() {
        _this.editable.selectNextEditPoint(editor.selection.getRng(true));
        // Signal the selection change to other plugins. For instance the cursor characters plugin
        // will add and remove characters to make sure we can actually type into the selected spot.
        editor.nodeChanged();
      }
    });
  }

  configure(options: Settings) {
    const toolbar = ToolbarAttributes.FromAttributes(this.$attrs);
    this.extendToolbar(toolbar.id, "insert-left", options);
    this.extendToolbar(toolbar.id, "insert-right", options);
  }

  private disableOnInvalidSelection(editor: Editor, button: ui.Control, direction: InsertPosition): void {
    editor.on("NodeChange", () => {
      // Don't collapse the original range because it writes back to the editor.
      const range = editor.selection.getRng(true);
      const location = range.collapsed || direction === InsertPosition.Before
        ? range.startContainer
        : range.endContainer;
      const widget = this.widgets.closest($(location));
      button.disabled(widget == null);
    });
  }
}

export default ngModule("midas.utility.tinymce.insert-location", [
  reportEditorModule.name,
  widgetsModule.name,
  isEditableModule.name
])
  .component("mceInsertLocation", {
    require: {
        ...tinymceConfigurationRequire
    },
    controller: InsertLocationController
  });