import {
  module as ngModule,
  IComponentController,
  IScope
} from "angular";
import "./mds-measurement-actions.component.scss";
import angularClipboard from "angular-clipboard";
import { MeasurementViewModel } from "../directives/MeasurementViewModel";
import { BindingOf } from "../../componentBindings";

/**
 * A component that will surround a measurement input and provide a menu which can provide additional actions.
 * Copy to Clipboard functionality is provided by the "angular-clipboard" library which provides directives.
 */
class MeasurementActionsComponentController implements IComponentController, IBindings {
  measurement: MeasurementViewModel;
  showActions: boolean;

  static $inject = ["$scope", "$mdToast"];
  constructor(
    private readonly $scope: IScope,
    private readonly $mdToast: angular.material.IToastService) {
  }

  $onInit(): void {
    return;
  }

  /** Copy to clipboard success callback provided by angular-clipboard directive. */
  copyKeySuccess(): void {
    this.$mdToast.showSimple(`"${this.measurement.key}" copied to clipboard.`);
  }

  /** Copy to clipboard error callback provided by angular-clipboard directive. */
  copyKeyError(): void {
    this.$mdToast.showSimple(`There was a problem copying the key "${this.measurement.key}" to the clipboard.`);
  }
}

interface IBindings {
  measurement: MeasurementViewModel;
  showActions: boolean;
}

const bindings: BindingOf<IBindings> = {
  measurement: "<",
  showActions: "<?"
};

export default ngModule("midas.utility.measurements.measurement-actions", [angularClipboard.name])
  .component("mdsMeasurementActions", {
    controller: MeasurementActionsComponentController,
    bindings,
    templateUrl: require("./mds-measurement-actions.component.html"),
    transclude: true
  });