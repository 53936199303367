import { module as ngModule, IController } from "angular";
import "./allNormalButton.scss";
import  { MeasurementsController } from "../../directives/mdsMeasurements";

export interface AllNormalButtonController extends IController { }
export class AllNormalButtonController implements IController {

  group: string;
  measurementsCtrl: MeasurementsController;
  measurementVariant: string;

  setMeasurementDefault() {
    this.measurementsCtrl.setMeasurementDefault(this.group);
  } 
}

export default ngModule("midas.utility.measurements.all-normal-button", [])
  .component("allNormalButton", {
    controller: AllNormalButtonController,
    templateUrl: require("./allNormalButton.html"),
    bindings: {
      group: "@",
      measurementVariant: "@?"
    },
    require: {
      measurementsCtrl : '?^^mdsMeasurements'
    },
    transclude: true
  });