/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import "./measurementDiagram.css";


export default [() =>
  ({
    restrict: "A",
    require: "^mdsDiagramContainer",
    link($scope, $element, $attrs, $ctrl) {
      $element.attr("class", $element.attr("class") + " mds-diagram-area");
      const key = $attrs.mdsDiagramArea;
      $ctrl.listen(key, function(visible) { 
        if (visible) { return $element.addClass('selected'); } else { return $element.removeClass('selected'); }
      });
      if ($ctrl.getVariant() !== "Readonly") {
        return $element.on('mousedown', function(e) { 
          $ctrl.toggleVisibility(key);
          return $scope.$apply();
        });
      }
    }
  })

];
