import { ObservableCallback } from "./rawEvents";
import { IDisposable, safeDispose } from "../disposable";
import { LogFunction, trace } from "../tracing";

/**
 * Manages named event registrations. Observers are always notified in the order they're
 * registered, and the order is stable across removal. It is safe to observe an event from
 * within a handler for the same event without risk of infinite recursion. Doesn't need to be
 * injected by angular.
 */
export class Events {
    static trace: LogFunction = trace;
    private $observers: { [event: string]: Array<ObservableCallback<any>> } = {};
    private $notifyCount: { [event: string]: number } = {};

    /**
     * Registers an observer for a named event, returning a deregistration function.
     * Calling the returned function removes the callback from the observer list.
     * @param eventName The name of the event on this object to observe.
     * @param callback The callback to register to the event.
     * @returns A deregistration function for this observer.
     */
    observe(eventName: string, callback: ObservableCallback<any>): IDisposable {
        if (eventName == null) {
            throw Error("eventName must have a value");
        }
        if (typeof callback !== "function") {
            throw Error("callback must be a function");
        }
        const obs = this.$observers[eventName];
        if (obs == null) {
            this.$observers[eventName] = [callback];
        } else if (this.$notifyCount[eventName] > 0) {
            // If we're currently notifying on this observable, create a new array so we don't risk
            // entering any recursive loops.
            this.$observers[eventName] = [...obs, callback];
        } else {
            // Otherwise update in place.
            obs.push(callback);
        }

        return this.$remove.bind(this, eventName, callback);
    }

    /**
     * Registers an observer for a named event and will automatically deregister after the first
     * event. Calling the returned function removes the callback from the observer list.
     * @param eventName The name of the event on this object to observe.
     * @param callback The callback to register to the event.
     * @returns A deregistration function for this observer.
     */
    observeOnce(eventName: string, callback: ObservableCallback<any>): IDisposable {
        const registration = this.observe(eventName, callback != null
            ? function() { safeDispose(registration); callback.apply(undefined, arguments); }
            : callback);
        return registration;
    }

    /**
     * Removes a callback from a named event.
     * @param eventName The name of the event.
     * @param callback The callback to remove.
     * @returns True if the callback was found and removed.
     */
    private $remove(eventName: string, callback: ObservableCallback<any>): boolean {
        const obs = this.$observers[eventName];
        if (obs) {
            const found = obs.indexOf(callback);
            if (found >= 0) {
                if (this.$notifyCount[eventName] > 0) {
                    // If we're currently notifying on this observable, create a new array so we
                    // don't risk entering any recursive loops.
                    const newObs = new Array(obs.length - 1);
                    let i;
                    for (i = 0; i < found; ++i) {
                        newObs[i] = obs[i];
                    }
                    for (; i < newObs.length; ++i) {
                        newObs[i] = obs[i + 1];
                    }
                    this.$observers[eventName] = newObs;
                } else {
                    // Otherwise update in place.
                    obs.splice(found, 1);
                }
                return true;
            }
        }
        return false;
    }

    /**
     * Notifies all observers of an event by producing a value to them.
     * @param eventName The name of the event to register for.
     * @param value Value to send to all observers.
     */
    notify(eventName: string, value?: any): void {
        const obs = this.$observers[eventName];
        if (obs) {
            this.$notifyCount[eventName] = (this.$notifyCount[eventName] || 0) + 1;
            try {
                for (let callback of obs) {
                    try {
                        if (value === undefined) { callback(); } else { callback(value); }
                    } catch (ex) {
                        Events.trace && Events.trace(
                            `Error in event callback ${eventName}(${value === undefined ? "" : "" + value}): `, ex);
                    }
                }
            } finally {
                this.$notifyCount[eventName]--;
            }
        }
    }

    /**
     * Gets whether a named event has observers
     * @param eventName The name of the event to check for observers.
     */
    hasObservers(eventName: string): boolean {
        const obs = this.$observers[eventName];
        return obs != null && obs.length > 0;
    }

    /** Removes all observers. */
    clear();
    /**
     * Removes all observers of a specific event.
     * @param eventName The name of the event to clear observers for.
     */
    clear(eventName?: string) {
        if (typeof eventName === "string") {
            this.$observers[eventName] = undefined;
        } else {
            for (const eventKey in this.$observers) {
                this.$observers[eventKey] = undefined;
            }
        }
    }
}

export default Events;