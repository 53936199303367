import { BindingOf, ParentOf } from "../../../utility/componentBindings";
import { VisualStack as VisualStackController } from "./mdsVisualStack.component";
import { IComponentOptions, IComponentController } from "angular";

interface ComponentParentRequrements {
    mdsVisualStack: VisualStackController;
}

interface IComponentBindings {
    levelId?: string;
    onSelected: () => void;
    onDeselected: () => void;
    onActivated: () => void;
    onDeactivated: () => void;
}

class VisalStackLevel implements IComponentController, IComponentBindings, VisualStackController.ILevel, ComponentParentRequrements {

    levelId?: string;
    selected: boolean;
    mdsVisualStack: VisualStackController;
    registration: VisualStackController.IRegistration;

    properties: { level: number, depth: number };

    onActivated: () => void;
    onDeactivated: () => void; 

    onSelected: () => void;
    onDeselected: () => void; 

    $onInit = () => {
        this.registration = this.mdsVisualStack.register(this);
    };

    select() {
        if (this.selected)
            return;
        this.selected = true;
        this.onSelected();
    }

    deselect() {
        if (!this.selected)
            return;
        this.selected = false;
        this.onDeselected();
    }

    update(level: number, depth: number) {
        const oldProps = this.properties;
        this.properties = { level, depth };

        if (!oldProps)
            return;

        if (oldProps.depth < 0 && depth >= 0) {
            this.onActivated();
        }
        else if (oldProps.depth >= 0 && depth < 0) {
            this.onDeactivated();
        }
    }

    onBackdropClicked() {
        if (this.properties.depth < 0)
            return;
        this.registration.select();
    }

    $onDestroy() {
        this.registration.deregister();
    }
}

export default {
    template: `<div class="mds-visual-stack-level-container" ng-class="{'mds-visual-stack-level-selected': $ctrl.selected, 
                                                                            'mds-visual-stack-level-active': $ctrl.properties.depth >= 0}">
                    <div class="mds-visual-stack-level-{{$ctrl.properties.level}}">
                        <div class="mds-visual-stack-depth-{{$ctrl.properties.depth}}">
                            <mds-visual-stack-backdrop ng-show="$ctrl.properties.depth > 0" ng-click="$ctrl.onBackdropClicked()"></mds-visual-stack-backdrop>
                            <div class="mds-visual-stack-level-content" ng-transclude></div>
                        </div<
                    </div>
                </div>`,
    transclude: true,
    require: {
        mdsVisualStack: "^"
    } as ParentOf<ComponentParentRequrements>,
    bindings: {
        levelId: "@?",
        onSelected: "&",
        onDeselected: "&",
        onActivated: "&",
        onDeactivated: "&"
    } as BindingOf<IComponentBindings>,
    controller: VisalStackLevel
} as IComponentOptions;