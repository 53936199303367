import { MeasurementController, IMeasurementScope } from "../MeasurementController";
import * as angular from "angular";
class DXLumbosacralSpineController extends MeasurementController {
  static $inject = ["$scope"];
  constructor(scope: IMeasurementScope) {
    super(scope);
  }

  clickOK() {
    this.setMeasurementValue("DXLumbosacralSpineRtOtherComment", "The alignment, development and bony structures are normal. There is no fracture or destructive lesion. The disk spaces are well-preserved. The sacrum and sacroiliac joints are normal. ");
    this.setMeasurementValue("DXLumbosacralSpineConclusion", "Negative lumbosacral spine. ");
    console.log("All normal clicked")
  }

}

angular.module("midas.utility.measurement.views").controller("DXLumbosacralSpineCtrl", DXLumbosacralSpineController);
