import { module as ngModule, IController } from "angular";
import { ExamType } from "../../../businessModels";
import { SearchResultViewModel } from "../../../utility/search/search";
import { ISearchTerm } from "../../../utility/search/searchExecutorProviders";
import { ISearchTermProvider } from "../../../utility/search/searchTermProviders";
import ExamTypeNameConfigurationService from "../../../admin/institute/examTypeNameConfigurationService";

interface ChooseStudyDialogController extends IController {}
/** A dialog for the user to choose a study. This is used by the "Report Mode" in self-serve formatters. */
class ChooseStudyDialogController {

  /** Populated by the locals property on the mdDialog options object. */
  examType: ExamType;

  /** The tags to search for the study, such as exam type or patient. */
  searchTags = new Array<ISearchTerm>();

  static $inject = ["$mdDialog", "examTypeTermProvider", "examTypeNameConfigurationService"];
  constructor(
    private readonly $mdDialog: angular.material.IDialogService,
    private readonly examTypeTermProvider: ISearchTermProvider,
    private readonly examTypeNameService: ExamTypeNameConfigurationService) {

      if (this.examType) {
        const examTypeTerm = this.examTypeTermProvider.transform(this.examType);
        const customName = this.examTypeNameService.getCustomName(this.examType.key);
        if (customName.matchFound) {
          examTypeTerm.text = customName.examType;
        }
        this.searchTags.push(examTypeTerm);
      }

    }

  cancel() {
    this.$mdDialog.cancel();
  }

  studySelected(viewModel: SearchResultViewModel): boolean {
    this.$mdDialog.hide(viewModel.id);
    return false;
  }
}

const createOptions = (examType: ExamType): angular.material.IDialogOptions => {
  return {
    controller: ChooseStudyDialogController,
    templateUrl: require("./report-formatter-choose-study.dialog.html"),
    controllerAs: "$ctrl",
    bindToController: true,
    fullscreen: true,
    clickOutsideToClose: true,
    locals: {
      examType
    }
  };
};

export default ngModule("midas.admin.reportFormatters.chooseStudyDialog", [])
  .decorator("$mdDialog", ["$delegate", ($mdDialog: angular.material.IDialogService) => {
    $mdDialog.showStudyPrompt =
      (examType) => $mdDialog.show(createOptions(examType));
    return $mdDialog;
  }
]);

declare module "angular" {
  export namespace material {
    interface IDialogService {
      /**
       * Prompt user to select a study, filtered to the give exam type.
       * @param examType The exam type to preset the search to.
       * @returns A promise of a study id that the user has selected.
       */
      showStudyPrompt(examType: ExamType): IPromise<number>;
    }
  }
}