import * as angular from "angular";
angular.module("midas.utility.measurement.views").controller("AnkleCtrl", ["$scope", function ($scope) {
  const { values } = $scope.measurements;
  const setRightOK = function () {
    values.MSKAnkleRtPosteriorNormal.value = "ALL NORMAL";
    values.MSKAnkleRtAnteriorNormal.value = "ALL NORMAL";
    values.MSKAnkleRtMedialNormal.value = "ALL NORMAL";
    values.MSKAnkleRtLateralNormal.value = "ALL NORMAL";
    values.MSKAnkleRtLigamentsNormal.value = "ALL NORMAL";
    values.MSKAnkleRtVasculatureNormal.value = "ALL NORMAL";
    console.log("Right All normal clicked");
  };
  $scope.clickRightOK = () => setRightOK();
  const setLeftOK = function () {
    values.MSKAnkleLtPosteriorNormal.value = "ALL NORMAL";
    values.MSKAnkleLtAnteriorNormal.value = "ALL NORMAL";
    values.MSKAnkleLtMedialNormal.value = "ALL NORMAL";
    values.MSKAnkleLtLateralNormal.value = "ALL NORMAL";
    values.MSKAnkleLtLigamentsNormal.value = "ALL NORMAL";
    values.MSKAnkleLtVasculatureNormal.value = "ALL NORMAL";
    console.log("Left All normal clicked");
  };
  $scope.clickLeftOK = () => setLeftOK();
}
]);
