import * as angular from "angular";
import { IStateService, IStateParamsService } from "angular-ui-router";
import { Moment, MomentStatic } from "moment";
import { Patient, BusinessModelService } from "../../businessModels";

interface NewPatient extends angular.IController {}
class NewPatient implements angular.IController {
    readonly invalidDate: Moment;
    readonly dobPickerOptions: { pickTime: boolean, defaultDate: Moment };
    readonly newPatient = {
        isBusy: false,
        isReady: false,
        errors: [],
        entity: <Patient>undefined,
        dob: <Date>undefined
    };
    static $inject = ["$scope", "$state", "$stateParams", "moment", "businessModels"];
    constructor(
        $scope: angular.IScope,
        private readonly $state: IStateService,
        private readonly $stateParams: IStateParamsService,
        moment: MomentStatic,
        private readonly models: BusinessModelService) {
            this.invalidDate = moment("1900-01-01");
            this.dobPickerOptions = {
                pickTime: false,
                defaultDate: this.invalidDate
            };
            $scope.$on("$destroy", () => this.models.breeze.rejectChanges());
            this.models.Patient.create().then((entity) => {
                this.newPatient.dob = this.invalidDate.toDate();
                this.newPatient.entity = entity;
                this.newPatient.isReady = true;
             });
    }

    preventDefaultAndStop(evt: Event) {
        evt.preventDefault();
        evt.stopPropagation();
    }

    close() {
        this.newPatient.isBusy = false;
        this.newPatient.errors = [];
        this.newPatient.entity = null;
    }

    accept() {
        this.newPatient.isBusy = true;
        this.newPatient.entity.dob = this.newPatient.dob;
        this.models.save((function() {}), false, false).then(() => {
            this.$state.go("midas.patients.view", {
                inst: this.$stateParams.inst,
                patientId: this.newPatient.entity.id
            });
            this.close();
        }).catch((error) => {
            this.newPatient.errors = error.entityErrors.map(err => err.errorMessage);
            this.newPatient.isBusy = false;
        });
    }

    cancel() {
        this.models.breeze.rejectChanges();
        this.close();
        this.$state.go("midas");
    }
}

export default NewPatient;