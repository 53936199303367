import "../../utility/drawing/shapes/shapes";
import * as angular from "angular";
import "angular-ui-router";
import "./diagramEdit.css";
import {
  Exam,
  Diagram,
  DiagramElement,
  RuleDiagramElement,
  DiagramTemplate,
  BusinessModelService,
  User
} from "../../businessModels";
import { IShape } from "../../utility/drawing/imageEditorCore";
import { LoadingStatus, startsWith } from "../../utility/utils";
import { LoDashStatic } from "lodash";
import { IStudyTitle } from "./StudyTitle";

const defaultPathAngleDamping = 0.75;
const defaultPathTension = 0.5;

interface DiagramEditScope extends angular.IScope {
  isMobile: boolean;
  uiDisableScroll: boolean;
  contentHeight: number;
  /** The distance from surrounding points that a point must be in the pen tool to be considered an
   * outlier and removed. Does nothing if not provided. */
  outlierTolerance?: number;
  drawing: {
    /** The current shape being edited. Both watched for changes, and set by the image editor. */
    editShape?: IShape,
    /** List of shapes drawn to the image editor. The image editor watches this list for changes. */
    history: IShape[],
    /** List of the names of tools the iage editor has registered. */
    tools: string[],
    /** The name of the active tool, or null if none are active. */
    activeTool?: string,
    /** URL to the image which should be drawn on the background of the image editor. */
    backgroundImage?: string,
    /** See IImageEditorScope.getImageFunction() */
    getImage?(format: "jpg" | "png", dpcm?: number, width?: number): string;
    isDirty?: boolean
    /** Config object for the image editor tools. */
    toolConfig: {
      /** The primary foreground draw colour. */
      primaryColour?: string
      /** The secondary colour for a shape. */
      secondaryColour?: string,
      /** A pattern name from the PatternService, for filling splines. */
      pattern?: string,
      /** Text font family. */
      font?: string,
      /** Text fonst size in pts. */
      fontSize?: string,
      /** Text border colour. */
      borderColour?: string,
      /** Text border thickness in cm. */
      borderThickness?: number,
      /** Text horizontal padding in cm. */
      paddingX?: number,
      /** Text vertical padding in cm. */
      paddingY?: number,
      /** Stroke thickness in cm.*/
      thickness?: number,
      /** A number controlling spline tension. */
      tension?: number,
      /** A number controlling spline eccentricity near corners. */
      angleDamping?: number,
      /** Whether an arrow or spline should draw a start arrow cap. */
      arrowStart: boolean,
      /** Whether an arrow or spline should draw an end arrow cap. */
      arrowEnd: boolean
    };
  };
  exam: Exam;
  diagram: Diagram;
  cut(): void;
  copy(): void;
  paste(): void;
  clipboardFull(): boolean;
  status: LoadingStatus;
  saveDiagramStatus: LoadingStatus;
  /** A bag of information for rendering the UI. */
  ui: { [key: string]: any };
  getShapeStyle(shape: IShape): { [key: string]: string };
  deleteHistory(shapeOrIndex: number | IShape): void;
  bringToFront(shape: IShape): void;
  isTextBorderOn(): boolean;
  toggleTextBorder(): void;
}

export default class DiagramEdit {
  static $inject = ["$scope", "lodash", "businessModels", "loadingStatus", "$state",
    "$stateParams", "layoutStateService", "title", "localStorage", "$parse", "$timeout",
    "promptLoseChangesAndNavigate", "$q"];
  constructor(
    $scope: DiagramEditScope,
    _: LoDashStatic,
    models: BusinessModelService,
    loadingStatus: typeof LoadingStatus,
    $state: angular.ui.IStateService,
    $stateParams: angular.ui.IStateParamsService,
    layoutStateService,
    title: IStudyTitle,
    localStorage,
    $parse: angular.IParseService,
    $timeout: angular.ITimeoutService,
    promptLoseChangesAndNavigate,
    $q: angular.IQService
  ) {
    title.text = "Loading...";
    title.clearButtons();
    title.backButton.show = true;
    title.backButton.url = `#/${$stateParams.inst}/studies/${$stateParams.studyId}/diagrams`;
    title.acceptButton.loading();
    models.save.status.onChanged(status => title.acceptButton.isBusy = status.isLoading)
      .disposeWith($scope);

    $scope.isMobile = $state.current.data != null ? $state.current.data.isMobile : false;

    $scope.uiDisableScroll = true;

    if (layoutStateService.contentHeight) { $scope.contentHeight = layoutStateService.contentHeight; }

    let saveDiagramPromise: angular.IPromise<any>;

    let saveDiagram = function (
        diagram: Diagram, toState?: string, toArgs?, redirect?: boolean, basicSave = false): angular.IPromise<any> {

        if (toState == null) { toState = "midas.studies.view.diagrams"; }
        if (toArgs == null) { toArgs = { studyId: $state.params.studyId }; }
        if (redirect == null) { redirect = true; }
        let saveDefer = $q.defer();
        $scope.drawing.editShape = null;

        //set timeout so that the digest has a chance complete the above statement and trigger watchers
        //the watchers will write the edit state back to the history object
        $timeout(() => {
            let update = () => {
                try {
                    diagram.clearElements();
                    let result: DiagramElement[] = [];
                    let i = 0;
                    for (var shape of $scope.drawing.history) {
                        let item: DiagramElement;
                        const isAutoGenerated = shape["isAutoGenerated"] === true;
                        switch (shape.type.toLowerCase()) {
                        case "line": item = diagram.addElement("Line", line.encode(shape), isAutoGenerated, i); break;
                        case "text": item = diagram.addElement("Text", text.encode(shape), isAutoGenerated, i); break;
                        case "drawing": item = diagram.addElement("Drawing", drawing.encode(shape), isAutoGenerated, i); break;
                        case "eraser": item = diagram.addElement("Eraser", eraser.encode(shape), isAutoGenerated, i); break;
                        case "path": item = diagram.addElement("Path", path.encode(shape), isAutoGenerated, i); break;
                        case "rect": item = diagram.addElement("Rect", rectangle.encode(shape), isAutoGenerated, i); break;
                        }
                        result.push(item);
                        ++i;
                    }
                    return result;
                } catch (error) {
                    console.error("Error saving diagram", error);
                    //TODO: Notify user of the error here with our fantastic universal error thingy.
                    models.breeze.rejectChanges();
                    saveDefer.reject("Error applying shapes to diagram");
                    return;
                }
            };

            const format = "png";

            if (basicSave === false) {
                return saveDiagramPromise = models.save(update, false, false)
                    .then(() => diagram.updateSnapshot($scope.drawing.getImage(format), format))
                    .then(function () {
                        $scope.drawing.isDirty = false;
                        if (redirect) { return $state.go(toState, toArgs); }
                    })
                    .then(() => saveDefer.resolve())
                    .catch(function (reason) {
                        //TODO: Notify the user that we failed to save their diagram.
                        console.log("Failed to save diagram", reason);
                        return saveDefer.reject("Failed to save diagram: " + reason);
                    });
            }
            else {
                return saveDiagramPromise = models.save(update, false, false)
                    .then(() => diagram.updateSnapshot($scope.drawing.getImage(format), format))
                    .then(() => saveDefer.resolve())
            }
        });
        return saveDefer.promise;
    };

    let getDiagramAndSave = function (redirectAllowed = true, toState?, toArgs?, basicSave = false): angular.IPromise<any> {
        let wait = null;
        if (diagram != null) {
            wait = saveDiagram(diagram, toState, toArgs, redirectAllowed, basicSave);
        } else {
            wait = examFuture.then(function (exam) {
                const diagram = template.createDiagram(exam);
                return saveDiagram(diagram, toState, toArgs, redirectAllowed, basicSave);
            });
        }
        return wait;
    };

    //saveRequested comes from save, provisional or final buttons
    //If it is the P or F buttons then they want to handle the navigation
    //to the next study, so redirection is not allowed by child
    $scope.$on("saveRequested", function (e, args) {
      //Prevent caller from continuing until we have finished saving.
      e.preventDefault();
      //Emit event for caller on completion
      return getDiagramAndSave(args != null ? args.redirectAllowed : undefined).then(() => $scope.$emit("saveComplete"));
    });

    //Removes an item from the shape history.
    $scope.deleteHistory = function (element: number | IShape) {
      if (element == null) { return; }
      const i = typeof element === "number" ? element : $scope.drawing.history.indexOf(element);
      if (i >= 0 && i < $scope.drawing.history.length) {
        $scope.drawing.history.splice(i, 1);
      }
    };

    //If the current user has a custom setting for the outlier tolerance, then we should use
    //that for removing outlier point in the pen tool. We've found that some tablets can send
    //some pretty dirty points to us.
    const outlierTolerance = User.current.settings["diagram.outlierTolerance"];
    if (outlierTolerance != null) {
      const toleranceNum = parseFloat(outlierTolerance);
      if (!isNaN(toleranceNum)) {
        $scope.outlierTolerance = toleranceNum;
      }
    }

    //Brings an element to the front of the view stack so it's rendered on top of everything else.
    $scope.bringToFront = function (element: IShape) {
      if (element != null) {
        $scope.deleteHistory(element);
        $scope.drawing.history.push(element);
      }
    };

    $scope.isTextBorderOn = () => $scope.drawing.toolConfig.borderThickness > 0;

    $scope.toggleTextBorder = () =>
      // Toggle between 0mm and 1mm thickness.
      $scope.drawing.toolConfig.borderThickness = $scope.isTextBorderOn() ? 0 : 0.1;

    $scope.ui = {
      toolIconClasses: {
        "pan-zoom": ["fa", "fa-hand-rock-o"],
        "select": ["fa", "fa-mouse-pointer"],
        "pen": ["fa", "fa-pencil"],
        "line": ["fa", "fa-minus"],
        "arrow": ["fa", "fa-long-arrow-right"],
        "text": ["fa", "fa-font"],
        "eraser": ["fa", "fa-eraser"],
        "rect": ["fa", "fa-square-o"],
        "path": ["fa", "fa-share-alt"]
      },
      getShapeIconClasses(shape: IShape) {
        const type = shape.type;
        const icons = $scope.ui.shapeIconClasses;
        if (type === "line") {
          if (shape["arrowStart"]) {
            if (shape["arrowEnd"]) {
              return icons["line+both"];
            }
            return icons["line+start"];
          }
          if (shape["arrowEnd"]) {
            return icons["line+end"];
          }
        }
        return icons[type];
      },
      shapeIconClasses: {
        "drawing": ["fa", "fa-pencil"],
        "line": ["fa", "fa-minus"],
        "line+start": ["fa", "fa-long-arrow-left"],
        "line+end": ["fa", "fa-long-arrow-right"],
        "line+both": ["fa", "fa-arrows-h"],
        "text": ["fa", "fa-font"],
        "eraser": ["fa", "fa-eraser"],
        "rect": ["fa", "fa-square-o"],
        "path": ["fa", "fa-share-alt"]
      },
      colours: ["black", "white", "red", "green", "yellow", "blue", "gray", "orange", "indigo", "violet", "transparent"],
      patterns: ["None", "GraniteFill", "SphereFill", "Horizontal", "Vertical", "ForwardDiagonal",
        "BackwardDiagonal", "LargeGrid", "DiagonalCross", "Percent05", "Percent10", "Percent20",
        "Percent25", "Percent30", "Percent40", "Percent50", "Percent60", "Percent70", "Percent75",
        "Percent80", "Percent90", "Alpha25", "Alpha50", "Alpha75"],
      fonts: ['Arial', 'Arial Black', 'Book Antiqua', 'Comic Sans MS', 'Courier New',
        'Impact', 'Lucida Console', 'Sans-Serif', 'Serif', 'Tahoma', 'Times New Roman',
        'Trebuchet MS', 'Verdana'],
      fontSizes: ["10pt", "11pt", "12pt", "14pt", "16pt", "18pt", "22pt", "30pt"],
      brushSizes: [0.025, 0.05, 0.1, 0.2, 0.3, 0.4, 0.5, 0.7, 1],
      sortConfig: {
        animation: 150,
      }
    };

    const chooseMiddle = <T>(arr: T[]): T =>
      arr != null && arr.length > 0 ? arr[Math.floor(arr.length / 2)] : undefined;
    const chooseFirst = <T>(arr: T[]): T =>
      arr != null && arr.length > 0 ? arr[0] : undefined;

    $scope.drawing = {
      toolConfig: {
        primaryColour: chooseFirst<string>($scope.ui["colours"]),
        secondaryColour: "white",
        pattern: chooseFirst<string>($scope.ui["patterns"]),
        font: chooseFirst<string>($scope.ui["fonts"]),
        fontSize: chooseMiddle<string>($scope.ui["fontSizes"]),
        borderColour: "black",
        borderThickness: 0.1, // 1mm
        paddingX: 0.1, // 1mm
        paddingY: 0.1, // 1mm
        thickness: chooseMiddle<number>($scope.ui["brushSizes"]),
        tension: defaultPathTension,
        angleDamping: defaultPathAngleDamping,
        arrowStart: false,
        arrowEnd: true
      },
      editShape: null,
      history: [],
      tools: [],
      activeTool: undefined
    };

    let setupLastUsed = function (key: string) {
      let model = $parse(key);
      let storedValue = localStorage.get(key);
      if (storedValue != null) { model.assign($scope, storedValue); }
      return $scope.$watch(key, (newValue) => localStorage.set(key, newValue));
    };

    const migrateLocalConfig = () => {
      if (localStorage.get("drawing.version") == null) {
        console.log("Drawing tool update detected. Removing stored config.");
        for (var entry of localStorage.list()) {
          if (startsWith(entry, "drawing.toolConfig")) {
            localStorage.remove(entry);
          }
        }
        localStorage.set("drawing.version", 1);
      }
    };
    migrateLocalConfig();

    for (var key of (["drawing.toolConfig.primaryColour",
      "drawing.toolConfig.secondaryColour",
      "drawing.toolConfig.pattern",
      "drawing.toolConfig.font",
      "drawing.toolConfig.fontSize",
      "drawing.toolConfig.thickness",
      "drawing.toolConfig.borderColour",
      "drawing.toolConfig.borderThickness"])) { setupLastUsed(key); }

    function interpolate(text: string) {
      let pattern = /(=[a-zA-z0-9].*?=)/g;
      let matches = pattern.exec(text);
      if (!matches) { return text; }
      for (let match of matches) {
        let regex = new RegExp('=', 'g');
        key = match.replace(regex, "");
        let mv = _.find($scope.exam.getMeasurements(), x => (x.type.key === key) && !x.isDeleted);
        //return null if can't replace token
        if (!mv || ((mv.value != null ? mv.value.trim().length : undefined) === 0)) { return null; }
        text = text.replace(match, mv.value);
      }
      return text;
    };

    const line = {
      decode(line: DiagramElement | RuleDiagramElement) {
        if (line.type.toLowerCase() !== "line") {
          throw new Error("line.decode must be given a line business model");
        }
        return {
          ...angular.fromJson(line.properties),
          type: "line"
        };
      },

      encode(line: IShape) {
        if (line.type !== "line") {
          throw new Error("line.encode must be given a line shape");
        }
        return angular.toJson({
          ...line,
          type: undefined,
          isAutoGenerated: undefined
        });
      }
    };

    const eraser = {
      decode(eraser: DiagramElement | RuleDiagramElement) {
        if (eraser.type.toLowerCase() !== "eraser") {
          throw new Error("eraser.decode must be given an eraser business model");
        }
        return {
          ...angular.fromJson(eraser.properties),
          type: "eraser"
        };
      },

      encode(eraser: IShape) {
        if (eraser.type !== "eraser") {
          throw new Error("eraser.encode must be given an eraser shape");
        }
        return angular.toJson({
          ...eraser,
          type: undefined,
          isAutoGenerated: undefined
        });
      }
    };

    const rectangle = {
        decode(rect: DiagramElement | RuleDiagramElement) {
            if (rect.type.toLowerCase() !== "rect") {
                throw new Error("rectangle.decode must be given a rect business model");
            }
            return {
                ...angular.fromJson(rect.properties),
                type: "rect"
            };
        },

        encode(rect: IShape) {
            if (rect.type !== "rect") {
                throw new Error("rectangle.encode must be given a rect shape");
            }
            return angular.toJson({
                ...rect,
                type: undefined,
                isAutoGenerated: undefined
            });
        }
    };

    const text = {
      decode(text: DiagramElement | RuleDiagramElement) {
        if (text.type.toLowerCase() !== "text") {
          throw new Error("text.decode must be given a text business model");
        }
        const shape = {
          ...angular.fromJson(text.properties),
          type: "text"
        };
        if (shape["text"]) {
          shape["text"] = interpolate(shape["text"]);
        }
        return shape;
      },

      encode(text: IShape) {
        if (text.type !== "text") {
          throw new Error("text.encode must be given a text shape");
        }
        return angular.toJson({
          ...text,
          type: undefined,
          isAutoGenerated: undefined
        });
      }
    };

    const drawing = {
      decode(drawing: DiagramElement | RuleDiagramElement) {
        if (drawing.type.toLowerCase() !== "drawing") {
          throw new Error("drawing.decode must be given a drawing business model");
        }
        return {
          ...angular.fromJson(drawing.properties),
          type: "drawing"
        };
      },

      encode(drawing: IShape) {
        if (drawing.type !== "drawing") {
          throw new Error("drawing.encode must be given a drawing shape");
        }
        return angular.toJson({
          ...drawing,
          type: undefined,
          isAutoGenerated: undefined
        });
      }
    };

    const path = {
      decode(path: DiagramElement | RuleDiagramElement) {
        if (path.type.toLowerCase() !== "path") {
          throw new Error("path.decode must be given a path business model");
        }
        return {
          angleDamping: defaultPathAngleDamping,
          tension: defaultPathTension,
          ...angular.fromJson(path.properties),
          type: "path"
        };
      },

      encode(path: IShape) {
        if (path.type !== "path") {
          throw new Error("path.encode must be given a path shape");
        }
        return angular.toJson({
          ...path,
          type: undefined,
          isAutoGenerated: undefined
        });
      }
    };

    $scope.status = new loadingStatus();
    $scope.saveDiagramStatus = new loadingStatus(saveDiagramPromise);

    const applyRuleElements = function (t: DiagramTemplate) {
      for (let el of t.ruleElements) {
        let shape: IShape = null;
        switch (el.type.toLowerCase()) {
          case "line": shape = line.decode(el); break;
          case "text":
            let decoded = text.decode(el);
            if (decoded.text) {
              shape = decoded;
            }
            break;
          case "drawing": shape = drawing.decode(el); break;
          case "eraser": shape = eraser.decode(el); break;
          case "path": shape = path.decode(el); break;
          case "rect": shape = rectangle.decode(el); break;
          default:
            console.log("Unknown drawing element found", el);
        }
        if (shape != null) {
          shape["isAutoGenerated"] = true;
          $scope.drawing.history.push(shape);
        }

      }
    };

    $scope.$on("applyDiagramElement", function (_event, args) {
      if ((`${args.templateId}`) !== $stateParams.templateId) { return; }
        const history = $scope.drawing.history;
        let sortedElements = _.sortBy(args.elements, "orderIndex") as DiagramElement[];
        sortedElements
            .filter((el: DiagramElement) => !el.isAutoGenerated)
            .map(el => {
            switch (el.type.toLowerCase()) {
              case "line": history.push(line.decode(el)); break;
              case "text": history.push(text.decode(el)); break;
              case "drawing": history.push(drawing.decode(el)); break;
              case "path": history.push(path.decode(el)); break;
              case "eraser": history.push(eraser.decode(el)); break;
              case "rect": history.push(rectangle.decode(el)); break;
              default:
                console.log("Unknown drawing element found", el);
                break;
            }
        });
    });

    var diagram: Diagram = null;
    var template: DiagramTemplate = null;
    var examFuture: angular.IPromise<Exam> = null;
    let loadingPromise =
      (() => {
        if ($state.current.name === "midas.studies.view.diagrams.edit") {
          return models.Diagram.find({ "templateId": $stateParams.templateId, "exam.study.id": $stateParams.studyId }, "elements, template.studyType, template.ruleElements, exam.measurementValues")
            .then(function (d) {
              diagram = d;
              title.text = (d.exam.study.userCanEdit() ? "Edit" : "View") + " Diagram";
              title.acceptButton.ready();
              $scope.diagram = d;
              $scope.exam = d.exam;
              if (diagram.isRuleElementsDirty) { applyRuleElements(diagram.template); }
              $scope.drawing.backgroundImage = d.template.imagePath;
              let sorted = _.sortBy(diagram.elements, "orderIndex");
              for (let el of sorted) {
                if (diagram.isRuleElementsDirty && el.isAutoGenerated) {
                  el.isDeleted = true;
                } else {
                  switch (el.type.toLowerCase()) {
                    case "line": $scope.drawing.history.push(line.decode(el)); break;
                    case "text": $scope.drawing.history.push(text.decode(el)); break;
                    case "drawing": $scope.drawing.history.push(drawing.decode(el)); break;
                    case "path": $scope.drawing.history.push(path.decode(el)); break;
                    case "eraser": $scope.drawing.history.push(eraser.decode(el)); break;
                    case "rect": $scope.drawing.history.push(rectangle.decode(el)); break;
                    default:
                      console.log("Unknown drawing element found", el);
                  }
                }
              }
              return diagram.isRuleElementsDirty = false;
            });
        } else if ($state.current.name === "midas.studies.view.diagrams.new") {
          return models.DiagramTemplate.list().then(function (allTemplates) {
            template = _.find(allTemplates, x => (x.id + "") === $stateParams.templateId);

            $scope.drawing.backgroundImage = template.imagePath;
            title.text = "Edit Diagram";
            title.acceptButton.ready();
            examFuture = models.Exam.find({
              studyId: $stateParams.studyId,
              studyTypeId: template.examType.id
            }, "measurementValues");
            return examFuture.then(function (ex) {
              $scope.exam = ex;
              return applyRuleElements(template);
            });
          });
        } else {
          throw new Error("Requries either new or edit state");
        }
      })();
    $scope.status.track(loadingPromise);

    const shiftShape = function (shape: IShape, x: number = 1, y: number = 1) {
      if (!shape || !shape["points"]) { return; }
      shape["points"] = (shape["points"].map((p) => ({ x: p.x + x, y: p.y + y })));
      return shape;
    };

    const storeShape = function (shape: IShape, key: string = "diagramElement"): void {
      if (!shape || !sessionStorage) { return; }
      let json = angular.toJson(shape);
      sessionStorage.setItem(key, json);
    };

    const retrieveShape = function (key: string = "diagramElement"): IShape {
      if (!key || !sessionStorage) { return; }
      const json = sessionStorage.getItem(key);
      if (!json) { return; }
      try {
        return angular.fromJson(json);
      } catch (ex) {
        console.warn("Couldn't deserialise shape from clipboard", ex);
      }
    };

    $scope.copy = function () {
      const shape = $scope.drawing.editShape;
      if (shape) {
        storeShape(shape);
      }
    };

    $scope.cut = function () {
      $scope.copy();
      const i = $scope.drawing.history.indexOf($scope.drawing.editShape);
      $scope.deleteHistory(i);
    };

    $scope.clipboardFull = () => sessionStorage != null && sessionStorage.getItem('diagramElement') != null;

    $scope.paste = function () {
      if (sessionStorage == null) { return; }

      const shape = retrieveShape();
      shiftShape(shape);
      $scope.drawing.history.unshift(shape);
      //store shifted element so multiple pastes don't overlap
      storeShape(shape);
      $scope.drawing.editShape = shape;
    };

    const stopWatchingForNavigate = promptLoseChangesAndNavigate.subscribe({
        isDirty() {
            return $scope.drawing.isDirty;
        },
        save(state, params, basicSave): angular.IPromise<any> {
            return getDiagramAndSave(true, state, params, basicSave)
        }
    });
    $scope.$on("$destroy", stopWatchingForNavigate);

  }
}