import { IScope, IAugmentedJQuery, IAttributes, Injectable, IDirective, IRootScopeService } from "angular";

/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */

export const mdsLayoutTrigger = [() =>
  ({
    restrict: "A",

    link(scope: IScope, element: IAugmentedJQuery, attrs: IAttributes) {

      element.bind('click', function() {
        scope.$emit('layoutStateChangeRequested', { state: attrs.state, args: attrs.args });
      });

    }
  })

];

export const mdsGridContainer = [() =>
    <IDirective>({
      restrict: "A",
      link($scope: IScope, $element: IAugmentedJQuery, _$attrs: IAttributes) {
        for (let fn of $scope.columns) { fn($scope.columns.length); }
      },
      controller: ["$scope", function($scope) {
        $scope.columns = [];
        return {
          register(fn) {
            $scope.columns.push(fn);
            return $scope.columns.length;
          }
        };
      }
      ]
    })

];

export const mdsGridLayout = [ "$rootScope", "layoutStateService", ($rootScope: IRootScopeService, layoutStateService) =>
  ({
    restrict: "A",
    require: "^mdsGridContainer",
    link($scope: IScope, $element: IAugmentedJQuery, _$attrs: IAttributes, $ctrl) {
      let isFullscreen;
      let siblings = 0;
      const position = $ctrl.register(count => setColumns(siblings = count));

      $scope.isFullscreen = (isFullscreen = () => (layoutStateService.current != null ? layoutStateService.current.state : undefined) === "fullScreenSlideRight");
      $scope.hideClasses = function() {
        const classes = [];
        classes.push('hidden-lg');
        if (isFullscreen()) { classes.push('hidden-md'); }
        return classes;
      };

      var setColumns = function(count) {

        const full = [];
        const compact = [];

        //Default regardless of full screen
        full.push("col-xs-12");
        compact.push("col-xs-12");

        if (count >= 2) {
          compact.push("col-lg-6");
        }

        if (count === 2) {
          full.push("col-md-6");
        } else if (count === 3) {
          full.push("col-md-4");
        } else if (count >= 4) {
          full.push("col-md-4");
          full.push("col-lg-3");
        }

        if (isFullscreen()) {
          $element.attr('class', full.join(' '));
        } else {
          $element.attr('class', compact.join(' '));
        }

      };

      const dispose = $scope.$on("$stateChangeSuccess", () => setColumns(siblings));
      $scope.$on("$destroy", dispose);
      return $element.css("padding", "0");
    }
  })

];
