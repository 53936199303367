import "angular-file-saver";
import * as angular from "angular";
import { IStudyTitle, IStudyTitleButton } from "./StudyTitle";
import { NoteService } from "./notes/notes";
import { Study, Status, BusinessModelService, Institute } from "../../businessModels";
import { LoDashStatic } from "lodash";
import { LoadingStatus, HasPendingJobsService } from "../../utility/utils";
import StudyOutputTypeData from "../../typings/models/StudyOutputTypeData";
import { SearchTermService } from "../../utility/search/mdsSearchDirectives";
import { SearchExecutorProvider } from "../../utility/search/search";
import { resizeSnapContent } from "../../utility/mdsUtils";
import * as htmldiff from "../../../Scripts/htmldiff.js";
import "./studySummary.css"

export interface IStudySummaryScope extends angular.IScope {
    study: Study;
    status: LoadingStatus;
    introOptions: IIntroOptions;
    actionLoading: LoadingStatus;
    title: IStudyTitle;
    // this function will be injected onto the scope by the "ng-intro-method" directive in studySummary.html
    triggerIntro(): void;
    downloadTypes: DownloadType[];
    hasPendingJobs: boolean;
    editPatient(): void;
}

export interface IIntroOptions {
    steps: IIntroStep[];
    showStepNumbers: boolean;
    showBullets: boolean;
    exitOnOverlayClick: boolean;
    exitOnEsc: boolean;
    nextLabel: string;
    prevLabel: string;
    skipLabel: string;
    doneLabel: string;
}

export interface IIntroStep {
    id?: string;
    element?: string;
    intro: string;
    position?: string;
}

export class DownloadType {
    constructor(public key: StudyOutputTypeData, public title?: string) {
        if (!title) {
            this.title = key;
        }
    }
}

// Fixes TS2559 in TS 2.4: https://github.com/ag-grid/ag-grid/issues/1708#issuecomment-311489303
interface StudySummaryController extends angular.IController { }
class StudySummaryController implements angular.IController {
    private readonly tourKey = "IsTourCompleted";
    private readonly downloadTypes = [
        new DownloadType("Json", "JSON"),
        new DownloadType("ZippedImages", "Images (ZIP)"),
        new DownloadType("RtfStimulsoftReport", "Report Preview (RTF)")
    ];

    static $inject =
    [
        "$scope", "$timeout", "$q", "businessModels", "loadingStatus", "$state",
        "layoutStateService", "study", "title", "searchExecutorProvider", "lodash",
        "FileSaver", "Blob", "noteService", "searchTermService",
        "hasPendingJobs", "snapRemote", "$mdToast", "$mdDialog"
    ];
    constructor(
        private readonly $scope: IStudySummaryScope,
        private readonly $timeout: angular.ITimeoutService,
        private readonly $q: angular.IQService,
        private readonly models: BusinessModelService,
        loadingStatus: typeof LoadingStatus,
        private readonly $state: angular.ui.IStateService,
        private readonly layoutStateService: any,
        private readonly study: Study,
        private readonly title: IStudyTitle,
        private readonly searchExecutorProvider: SearchExecutorProvider,
        private readonly lodash: LoDashStatic,
        private readonly fileSaver: angular.FileSaver,
        private readonly blob: any,
        private readonly noteService: NoteService,
        private readonly searchTermService: SearchTermService,
        private readonly hasPendingJobs: HasPendingJobsService,
        private readonly snapRemote: any,
        private readonly $mdToast: angular.material.IToastService,
        private readonly $mdDialog: angular.material.IDialogService
        ) {

            $scope.$on("beginTour", this.startIntro.bind(this));
            $scope.title = title;
            $scope.downloadTypes = this.downloadTypes;
            $scope.editPatient = this.editPatient.bind(this);

            this.startIntro();


            function warnAboutPendingJobsError(error?) {
                console.warn("Error while checking study job status. Assuming no pending jobs.", error);
            }

            if (study && study.dicomDataSourceId) {
                this.$scope.hasPendingJobs = true;
                const registration = this.hasPendingJobs.watchHasPendingJobs(study.id, event => {
                    switch (event.type) {
                        case "has-pending-jobs":
                            self.$scope.hasPendingJobs = event.value;
                            break;
                        case "error":
                            warnAboutPendingJobsError(event.value);
                            self.$scope.hasPendingJobs = false;
                            break;
                    }
                });
                this.$scope.$on("$destroy", registration);
            }

            this.$scope.introOptions = {
                steps: [
                    {
                        intro:
                            `<span style='white-space: nowrap;'>Welcome to the <strong>MIDAS Universal</strong> demonstration.</span> \
    <br/><br/> \
    This tour will step you through the main features of MIDAS.`
                    }, {
                        element: '#btnToggleList',
                        intro: "This button displays your <strong>work list</strong>",
                        position: 'right'
                    }, {
                        element: '#mainSearchBar',
                        intro:
                            'This <strong>search bar</strong> allows you to search for <strong>studies</strong> and <strong>patients</strong> by name, date, or exam type',
                        position: 'bottom-middle-aligned'
                    }, {
                        element: '#btnListPatients',
                        intro: "This button displays your <strong>most recent patients</strong>.",
                        position: 'bottom-middle-aligned'
                    }, {
                        element: '#btnListStudies',
                        intro: 'This button displays <strong>current studies</strong>.',
                        position: 'bottom-middle-aligned'
                    }
                ],
                showStepNumbers: false,
                showBullets: false,
                exitOnOverlayClick: true,
                exitOnEsc: true,
                nextLabel: 'Next',
                prevLabel: 'Previous',
                skipLabel: 'Exit',
                doneLabel: 'Finish'
            };

            if (!(this.models.Institute.current.hasSetting('ManualPatientCreationDisabled') &&
                this.models.Institute.current.getSetting('ManualPatientCreationDisabled') === "True")) {
                this.$scope.introOptions.steps.push({
                    element: '#btnAddPatient',
                    intro: 'This button <strong>adds</strong> a new <strong>patient</strong>.',
                    position: 'bottom-middle-aligned'
                });
            }

            if (!(this.models.Institute.current.hasSetting('ManualStudyCreationDisabled') &&
                this.models.Institute.current.getSetting('ManualStudyCreationDisabled') === "True")) {
                this.$scope.introOptions.steps.push({
                    element: '#btnAddStudy',
                    intro: 'This button <strong>adds</strong> a new <strong>study</strong>.',
                    position: 'bottom-middle-aligned'
                });
            }

            this.$scope.introOptions.steps = this.$scope.introOptions.steps.concat([
                {
                    element: '#workArea',
                    intro:
                        'This is the <strong>study screen</strong>. The <strong>left</strong> side of the screen is the <strong>Work region</strong>. The tabs along the left of the screen step you through the workflow for a study.',
                    position: 'right'
                }, {
                    id: 'details',
                    element: '#tabStudyDetails',
                    intro: 'This <strong>tab</strong> displays the study <strong>details</strong>.',
                    position: 'right'
                }, {
                    id: 'documents',
                    element: '#tabDocuments',
                    intro: 'This <strong>tab</strong> displays the study <strong>documents</strong>.',
                    position: 'right'
                }, {
                    id: 'measurements',
                    element: '#tabStudyMeasurements',
                    intro: "This <strong>tab</strong> displays the study <strong>measurements</strong>.",
                    position: 'right'
                }, {
                    id: "pinImages",
                    element: "#tabStudyPinImages",
                    intro:
                        'This <strong>tab</strong> displays the study <strong>images</strong> and allows you to <strong>pin</strong> images to include in the final report.',
                    position: 'right'
                }, {
                    id: 'diagram',
                    element: '#tabStudyDiagram',
                    intro: 'This <strong>tab</strong> displays the study <strong>work sheet</strong>.',
                    position: 'right'
                }, {
                    id: 'report',
                    element: '#tabStudyReport',
                    intro: 'This <strong>tab</strong> displays the study <strong>report</strong>.',
                    position: 'right'
                }, {
                    id: 'notes',
                    element: '#tabNotes',
                    intro: 'This <strong>tab</strong> displays the study <strong>notes</strong>.',
                    position: 'right'
                }, {
                    id: 'preview',
                    element: '#tabStudyPreviewOutput',
                    intro:
                        'This <strong>tab</strong> displays the <strong>preview</strong> of the study <strong>output</strong>.',
                    position: 'right'
                }, {
                    element: '#btnProvisional',
                    intro:
                        'The <strong>provisional</strong> button marks this step as <strong>done</strong>, to be reviewed by the <strong>reporting doctor</strong>.',
                    position: 'bottom-middle-aligned'
                }, {
                    element: '#btnFinal',
                    intro:
                        'The <strong>final</strong> button marks this study as <strong>done</strong> and <strong>dispatches</strong> the study.',
                    position: 'bottom-middle-aligned'
                }, {
                    element: '#btnAddNote',
                    intro:
                        'The <strong>Add Note</strong> button adds a note to the study that can be viewed on the <strong>Notes</strong> tab.',
                    position: 'bottom-middle-aligned'
                }, {
                    element: '#btnDelete',
                    intro: 'The <strong>delete</strong> button removes this study from MIDAS.',
                    position: 'bottom-middle-aligned'
                }, {
                    element: '#btnFullscreen',
                    intro:
                        'The <strong>fullscreen</strong> button allows you to view the work area in full giving you more space to work with.',
                    position: 'bottom-middle-aligned'
                }, {
                    id: "fullscreen",
                    intro:
                        "When in <strong>fullscreen</strong> mode <strong>press</strong> the button again to <strong>leave</strong>.",
                    position: "left"
                }, {
                    id: "reference",
                    element: '#referenceArea',
                    intro: `The right hand side of the screen is the <strong>reference</strong> area. \
    <br/><br/> \
    It allows you to view <strong>related information</strong> about the study from current and past studies along side your work.`,
                    position: 'left'
                }, {
                    element: '#referenceGroups',
                    intro: "These buttons allow you to choose the type of related information you wish to view",
                    position: 'bottom-middle-aligned'
                }, {
                    element: '#btnTileLayout',
                    intro:
                        `This button allows you to change the <strong>layout</strong> of the reference area. <br/> \
    It can allow you to view any type of reference item in <strong>single</strong>, <strong>split</strong> or <strong>quad</strong> views. <br/> Additionally this button allows you to put the reference area into <strong>full screen</strong> mode.`,
                    position: 'left'
                }, {
                    element: '#btnFeedback',
                    intro:
                        "If you wish to send any feedback or requests to the MIDAS team, you can use the feedback button. All feedback is reviewed by a real-life human member of the team!",
                    position: 'bottom-middle-aligned'
                }, {
                    element: '#btnHelp',
                    intro:
                        "Finally, if you want to view this <strong>tour</strong> again, press the <strong>help</strong> button to view it again.",
                    position: 'left'
                }
            ]);

            const self = this;

            $scope.actionLoading = new loadingStatus();

            if (!self.models.User.current.hasRole('Read-Only Mode')) {

                this.title.addButton({
                    id: "btnSaved",
                    tooltip: "Revert back to Saved",
                    text: "S",
                    execute() {
                        return self.triggerSaveAndChangeStatus(self.study.saveAsSaved);
                    },
                    keep: true,
                    show() {
                        return self.study.status.key === "Provisional";
                    }
                });

                this.title.addButton({
                    id: "btnProvisional",
                    tooltip: "Mark as Provisional",
                    text: "P",
                    execute() {
                        return self.triggerSaveAndChangeStatus(self.study.saveAsProvisional)
                            .then(() => {
                                self.$mdToast.showSimple("Study output success");
                                // Navigate if we successfully marked provisional.
                                if (self.study.status.value === Status.Enum.Provisional) {
                                    self.navigateOnMarked(Status.Enum.Provisional);
                                }
                            })
                            .catch(() => self.$mdToast.showSimple("Study output failure - contact MIDAS Support"));
                    },
                    keep: true,
                    show() {
                        return self.study.status.key === "Saved";
                    }
                });

                this.title.addButton({
                    id: "btnFinal",
                    tooltip: "Mark as Final",
                    text: "F",
                    execute() {
                        return self.triggerSaveAndChangeStatus(self.study.saveAsFinal)
                            .then(() => {
                                self.$mdToast.showSimple("Study output success");
                                // Navigate if we successfully marked final.
                                if (self.study.status.value === Status.Enum.Final) {
                                    self.navigateOnMarked(Status.Enum.Final);
                                }
                            })
                            .catch(() => self.$mdToast.showSimple("Study output failure - contact MIDAS Support"));
                    },
                    keep: true,
                    show() {
                        return self.lodash.any(["Saved", "Provisional"], (key) => self.study.status.key === key) &&
                            ((self.models.User.current != null ? self.models.User.current.hasRole("Is Administrator") : undefined) ||
                            ((self.models.User.current != null ? self.models.User.current.physician : undefined) !== null));
                    },
                    disabled() {
                        const disabled = self.models.Institute.current != null ? self.models.Institute.current.getSetting("ReportCongruenceDisabled") : undefined;
                        const mandatory = self.models.Institute.current != null ? self.models.Institute.current.getSetting("ReportCongruenceMandatory") : undefined;
                        const isDisabled = (typeof disabled === "string") ? disabled.toLowerCase() === "true" : false;
                        const isMandatory = (typeof mandatory === "string") ? mandatory.toLowerCase() === "true" : false;
                        if (!(isDisabled) && isMandatory && !(self.study.hasCongruence)) return true; else return false;
                    }
                });

                this.title.addButton({
                    id: "btnResend",
                    tooltip: "Resend report",
                    icon: "fa fa-paper-plane",
                    execute: () => self.changeStatusOrRevert(self.study.saveAsFinal),
                    keep: true,
                    show() {
                        return self.study.status.key === "Final";
                    }
                });

                this.title.addButton({
                    id: "btnDiff",
                    tooltip: "Compare Report Versions",
                    icon: "fa fa-balance-scale",
                    keep: true,
                    show() {
                        return self.study.status.key === "Final";
                    },
                    execute() {
                        return self.$q.all({
                            saved: self.study.exam.loadLatestStatusConclusion(2),
                            provisional: self.study.exam.loadLatestStatusConclusion(3),
                            final: self.study.exam.loadLatestStatusConclusion(4)
                        })
                        .then(result => {
                            var reportDiff;
                            if (result.saved) {
                                if (result.final) { reportDiff = htmldiff(result.saved.replace(/&nbsp;/g, " "), result.final.replace(/&nbsp;/g, " "));}
                                else if (result.provisional) { reportDiff = htmldiff(result.saved.replace(/&nbsp;/g, " "), result.provisional.replace(/&nbsp;/g, " ")) }
                                    else return self.$mdToast.showSimple("Only Saved report found");
                            } else return self.$mdToast.showSimple("No report found");
                            return self.$mdDialog.show({
                                clickOutsideToClose: true,
                                template: `<md-dialog>
                                    <md-toolbar><div class="md-toolbar-tools"><h2>Provisional and Final report comparison</h2></div></md-toolbar>
                                    <md-dialog-content><div class="md-dialog-content">${reportDiff}</div></md-dialog-content>
                                    <md-dialog-actions><md-button class="md-primary" ng-click="closeDialog()">Close</md-button></md-dialog-actions>
                                    </md-dialog>`,
                                controller: ["$scope", "$mdDialog", ($scope, $mdDialog) => {
                                    $scope.closeDialog = () => $mdDialog.hide();
                                }]
                            });
                        });
                    }
                });

                this.title.addButton({
                    id: "btnAddNote",
                    tooltip: "Add note",
                    icon: "fa fa-sticky-note",
                    keep: true,
                    show() {
                        return self.study.userCanEdit();
                    },
                    execute() {
                        return self.noteService.promptAdd(self.study);
                    }
                });

                this.title.addButton({
                    id: "btnDelete",
                    tooltip: "Delete",
                    icon: "glyphicon glyphicon-trash",
                    confirmText: "Delete this study?",
                    keep: true,
                    show() {
                        return self.study.userCanEdit();
                    },
                    execute() {
                        return self.models.save((() => self.study.delete()), true, false)
                            .then(() => self.$state.go("midas"));
                    }
                });

                if (study && study.dicomDataSourceId && lodash.any(study.exams, exam => exam.uID)) {
                    this.title.addButton({
                        id: "reloadImages",
                        tooltip: "Reload Images from Archive",
                        icon: "glyphicon glyphicon-refresh",
                        confirmText: "Reload images from archive?",
                        keep: true,
                        show() {
                            return true;
                        },
                        execute() {
                            const wait = study.reloadImages()
                                .then(() => self.hasPendingJobs.hasPendingJobs(study.id)
                                    .then(result => self.$scope.hasPendingJobs = result))
                                .catch(err => console.warn("Couldn't reload images from PACS.", err));
                            self.models.load.track(wait);
                            return wait;
                        },
                        disabled() {
                            return self.$scope.hasPendingJobs;
                        }
                    });
                }
            }

            $scope.study = study;

            $scope.status = new loadingStatus;
        }

    startIntro(): void {
        this.$timeout(() => {
            if ((this.models.User.current != null ? this.models.User.current.settings[this.tourKey] : undefined) !== "true") {
                this.$scope.triggerIntro();
            }
        });
    }

    /*These functions needs to be declared as an instance functions (=>) as they are passed to the TourJS service
        and the definition of 'this' needs to be preserved. */
    userExitIntro = (): angular.IPromise<any> => {
        if (this.models.User.current == null) {
            return this.$q.when(false);
        }
        return this.models.save((() => this.models.User.current.setSetting(this.tourKey, "true")), false, false);
    };
    beforeIntroChange = (_toElement, _scope, stepIndex): angular.IPromise<any> => {
        const step: IIntroStep = this.$scope.introOptions.steps[stepIndex];
        switch (step.id) {
        case "details":
            return this.$state.go("midas.studies.view.details",
                    { studyId: this.study.id });
        case "documents":
            return this.$state.go("midas.studies.view.documents",
                { studyId: this.study.id });
        case "measurements":
            return this.$state.go("midas.studies.view.measurements",
                {
                    studyId: this.study.id,
                    examId: this.study.exams[0].id
                }
            );
        case "pinImages":
            return this.$state.go("midas.studies.view.images",
                { studyId: this.study.id });
        case "diagram":
            return this.$state.go("midas.studies.view.diagrams.new",
                {
                    studyId: this.study.id,
                    templateId: this.models.DiagramTemplate.listCached()[0].id
                }
            );
        case "report":
            return this.$state.go("midas.studies.view.reports",
                {
                    studyId: this.study.id,
                    examId: this.study.exams[0].id
                }
            );
        case "notes":
            return this.$state.go("midas.studies.view.notes",
                { studyId: this.study.id });
        case "preview":
            return this.$state.go("midas.studies.view.preview",
                { studyId: this.study.id });
        case "fullscreen":
            return this.$state.go("midas.studies.view.layout",
                {
                    studyId: this.study.id,
                    layoutState: "fullScreenSlideRight",
                    layoutArgs: "work"
                }
            );
        case "reference":
            return this.$state.go("midas.studies.view.details",
                { studyId: this.study.id });
        default:
            return this.$q.when(false);
        }
    };
    // End IntroJS callback functions

    editPatient() {
        this.$state.go("midas.patients.view", { patientId: this.study.patient.id });
    }

    changeStatusOrRevert(changePromiseFn: Function): angular.IPromise<any> {
        const previousStatus = this.$scope.study.status;
        const revert = (error) => {
            this.$scope.study.status = previousStatus;
            return this.$q.reject(error);
        };
        const promise = this.models.save(changePromiseFn()).catch(revert);
        this.$scope.status.andTrack(promise);
        return promise;
    }

    /** Triggers the navigation specified in the institute settings when the user marks a study
     * final or provisional. */
    navigateOnMarked(status: Status.Enum.Provisional | Status.Enum.Final) {
        let setting;
        switch (status) {
            case Status.Enum.Provisional: setting = "NavigateOnMarkedProvisional"; break;
            case Status.Enum.Final: setting = "NavigateOnMarkedFinal"; break;
            default: return;
        }
        const inst = Institute.current;
        const navigate = inst.getSetting(setting as string);
        switch (navigate) {
            case "stay": break;
            case "search-list":
                this.snapRemote.open("left");
                resizeSnapContent(true);
                break;
            case "next-study":
            default:
                this.searchExecutorProvider.navigateNext();
                break;
        }
    }

    triggerSaveAndChangeStatus(statusFn: Function): angular.IPromise<any> {
        const onSaved = () => {
            const promise = this.changeStatusOrRevert(statusFn);
            return promise.then(() => {
                this.searchTermService.refresh();
            });
        };

        // Ask page to save, we will handle redirection to next study
        const broadcast = this.$scope.$broadcast("saveRequested", { redirectAllowed: false });
        // If default is prevented (someone needs to save) wait for save complete event
        if (broadcast.defaultPrevented) {
            return this.$q(resolve => {
                const stop = this.$scope.$on("saveComplete", () => {
                    stop();
                    resolve(onSaved());
                });
            });
        } else {
            return onSaved();
        }
    }

    isFullscreen() : boolean {
        return (this.layoutStateService.current != null
                ? this.layoutStateService.current.state
                : undefined) ===
            "fullScreenSlideRight";
    }

    saveRequested() : void {
        this.$scope.$broadcast("saveRequested");
    }

    execute(button: IStudyTitleButton) {
      const exec = button.execute();
      if (typeof exec !== "boolean") {
        this.$scope.actionLoading.track(exec);
      }
    }

    download(type: DownloadType): void {
        this.study.download(type.key).then((download) => {
            const defaultMime = "application/octet-stream";
            const headers = download.headers();
            const filename = this.getFileNameFromContentDispositionHeader(headers["content-disposition"]) || "download.bin";
            const contentType = headers["content-type"] || defaultMime;
            const blob = new this.blob([download.data], { type: contentType });
            this.fileSaver.saveAs(blob, filename);
        });
    }

    getFileNameFromContentDispositionHeader(cd: string): string {
        if (cd === null || cd.length === 0) {
            return null;
        }

        // Shamelessly stolen from http://stackoverflow.com/a/40940790
        const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
        const matches = filenameRegex.exec(cd);
        if (matches === null || matches[1] === null) {
            return null;
        }

        const filename = matches[1].replace(/['"]/g, "");
        return filename;
    }
}

export default StudySummaryController;