/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS103: Rewrite code to no longer use __guard__
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */

export default [
  "$scope", "businessModels", "patientSearchExecutor", "duplicatePatients", "$q",
  ($scope, models, search, duplicates, $q) => {
    let api = null;
    $scope.$on("duplicateCheckRequested", (e, args) => {
      api = args;
      if (api.studyId) {
        models.Study.find({ id: args.studyId })
          .then(study => {
            $scope.study = study;
            duplicates.search(study.patient).then(dups => {
              const promiseArray = dups.map(dup => {
                return models.Patient.find({ id: dup.patientId });
              });
              return $q.all(promiseArray).then(patients => {
                $scope.duplicates = patients.map(p => search.toViewModel(p));
                $scope.hasCheckedPatientDupes = false;
                return (typeof api.dupesFound === "function" ? api.dupesFound($scope.duplicates) : undefined);
              });
            });
          });
      }
      return null;
    });

    $scope.selected = vm => {
      $scope.hasCheckedPatientDupes = true;
      __guardMethod__(api, "dupeSelected", o => o.dupeSelected(vm));
      return false;
    };

    $scope.noMatch = () => {
      $scope.hasCheckedPatientDupes = true;
      return __guardMethod__(api, "dupeSelected", o => o.dupeSelected(null));
    };
  }
];

function __guardMethod__(obj, methodName, transform) {
  if (typeof obj !== 'undefined' && obj !== null && typeof obj[methodName] === 'function') {
    return transform(obj, methodName);
  } else {
    return undefined;
  }
}