import {
  IController,
  module as ngModule,
  IQService,
  ILogService,
  isArray,
  IRootScopeService,
  IScope,
  IDirective,
  IAttributes,
  ITranscludeFunction} from "angular";
import { MeasurementType, Modality } from "../../../businessModels";
import { IChangesObject, BindingOf } from "../../../utility/componentBindings";
import searchModule, {
  serviceName as searchServiceName,
  MeasurementSearchService
} from "./measurement-type-search.service";
import "./measurement-selector.component.scss";
import { flatten } from "lodash";

interface IMeasurementSelectorComponentBindings {
  measurements: Modality | MeasurementType[];
  onSelectionChanged: ({$measurementType: MeasurementType}) => void;
}

/**
 * Displays to the user a list of measurement types to select from.
 * It does this by allowing the user to filter by choosing search tags that
 * categorise the measurement types.
 */
class MeasurementSelectorComponentController implements IController, IMeasurementSelectorComponentBindings {
  /** Search results presented to the user. */
  searchResults = [] as MeasurementType[];

  /** The currently selected search result. */
  selectedResult: MeasurementType;

  /** The search tags or measurement types the user has chosen to filter the search. */
  selectedTags = [] as string[];

  /** List of all measurement types available for searching by this component. */
  allTypes = [] as MeasurementType[];

  /** Gets a list of autocomplete options for the measurement search, including measurement tags and
   * keys.
   * @param query The text entered by the user to filter the tags.
   * @returns The tags and measurement keys which match the search, sorted by relevance. */
  private tagSearcher: (query: string) => string[] = null;

  /** Gets a list of `MeasurementType`s which match a search string.
   * @param query The text entered by the user to filter the types.
   * @returns The measurement types which match the query, sorted by relevance. */
  private typeSearcher: (searchText: string) => MeasurementType[] = null;

  /** The bound input value. If a list of measurement types, just those measurement types can be
   * searched. The measurement types may be from more than one modality. If a modality, the whole
   * modality is loaded and all of it's measurement types will be available for search. */
  measurements: Modality | MeasurementType[];
  /** Callback binding for when a list item is selected. */
  // tslint:disable-next-line:variable-name
  onSelectionChanged: ({$measurementType: MeasurementType}) => void;

  static $inject = ["$q", "$log", "$rootScope", searchServiceName];
  constructor(
    private readonly $q: IQService,
    private readonly $log: ILogService,
    /** For  */
    private readonly $rootScope: IRootScopeService,
    private readonly measurementSearch: MeasurementSearchService) {}

  /** Handle changes to the module by setting up the search. */
  $onChanges(obj: IChangesObject<IMeasurementSelectorComponentBindings>): void {
    if (obj.measurements) {
      // We get better apparent performance if we don't do everything on the exact frame it happens.
      // Without this, if everything is already client side and cached, then we end up doing a whole
      // lot of work in the initial setup frame. This way we give the system a chance to render, and
      // do all of our own updates later.
      this.$rootScope.$applyAsync(() => this.setup());
    }
  }

  /** Rebuilds the search indexes for the new module or measurement types.
   * @param models The module or measurement types to search over. */
  setup(): void {
    const boundValue = this.measurements;

    this.selectedTags = [];
    this.searchResults = [];
    this.allTypes = [];
    this.selectedResult = null;
    this.typeSearcher = null;
    this.tagSearcher = null;

    if (!boundValue) {
      return;
    }

    const modalities = [] as Modality[];
    let types: MeasurementType[];

    if (boundValue instanceof Modality) {
      modalities.push(boundValue);
    } else if (isArray(boundValue)) {
      // If it's an array, we need to find all possible modalities.
      types = boundValue;
      for (const mt of boundValue) {
        const modality = mt.modality;
        if (modalities.indexOf(modality) < 0) {
          modalities.push(modality);
        }
      }
    } else {
      this.$log.info("Unsupported model type. Expected a Modality or array of MeasurementTypes, but got ", boundValue);
      return;
    }

    this.$q.all(modalities.map(m => this.measurementSearch.loadTags(m)))
      .then(() => {
        if (boundValue !== this.measurements) { // Only if the bound value hasn't changed in the meantime.
          return;
        }

        // If the bound value was a modality, not a type list, then get the measurements from the
        // modality.
        if (types == null) {
          if (modalities.length === 1) {
            types = modalities[0].getMeasurementTypes();
          } else {
            types = flatten(modalities.map(m => m.getMeasurementTypes()));
          }
        }
        this.searchResults = this.allTypes = types;

        this.measurementSearch.buildTypeSearch(types).then(search => {
        if (boundValue === this.measurements) { // Only if the bound value hasn't changed in the meantime.
            this.typeSearcher = search;
            this.performSearch();
          }
        }).catch(err => this.$log.error("Error building type search", err));

        this.measurementSearch.buildTagSearch(types).then(search => {
          if (boundValue === this.measurements) { // Only if the bound value hasn't changed in the meantime.
            this.tagSearcher = search;
          }
        }).catch(err => this.$log.error("Error building tag search", err));
    }).catch(err => this.$log.error("Error loading measurement types/tags", err));
  }

  /** Gets a list of autocomplete options for the measurement search, including measurement tags and
   * keys.
   * @param query The text entered by the user to filter the tags.
   * @returns The tags and measurement keys which match the search, sorted by relevance. */
  searchTags(query: string): string[] {
    if (this.tagSearcher) {
      return this.tagSearcher(query);
    }
    return [];
  }

  /** Perform search using the user's selected tags and present the results to the user. */
  performSearch(): void {
    if (this.typeSearcher && this.selectedTags != null && this.selectedTags.length > 0) {
      this.searchResults = this.typeSearcher(this.selectedTags.join(" "));
    } else {
      this.searchResults = this.allTypes;
    }
  }

  /** Handle UI change to the selected tags by reloading the search. */
  onTagsChanged(): void {
    if (!this.autoSelectIfMeasurementType()) {
      this.performSearch();
    }
  }

  /** If the suggestion the user chose is a measurement type, select that result immediately. */
  private autoSelectIfMeasurementType(): boolean {
    if (this.selectedTags == null || this.selectedTags.length !== 1) {
      return false;
    }
    const tag = this.selectedTags[0];
    for (const mt of this.allTypes) {
      if (mt.key === tag) {
        this.searchResults = [mt];
        this.selectResult(mt);
        return true;
      }
    }
    return false;
  }

  /**
   * Triggered when the user selects a measurement type. This notifies the binding the selection has
   * changed.
   * @param result The chosen measurement type.
   */
  selectResult(result: MeasurementType): void {
    this.selectedResult = result;
    if (this.onSelectionChanged) {
      this.onSelectionChanged({ $measurementType: result });
    }
  }
}

const mainComponent = "measurementSelector";
const transcludeComponent = `${mainComponent}ResultTemplate`;

interface IMeasurementSelectorTranscludeScope extends IScope {
  $results: MeasurementType[];
  $selected: MeasurementType;
  $select: (arg: MeasurementType) => void;
}

export default ngModule("midas.admin.reportFormatter.measurementSelectorComponent", [searchModule.name])
  .component(mainComponent, {
    controller: MeasurementSelectorComponentController,
    templateUrl: require("./measurement-selector.component.html"),
    transclude: {
      // If this transclusion slot is provided, it has some extra scope bindings explained below.
      resultTemplate: `?${transcludeComponent}`
    },
    bindings: {
      measurements: "<",
      onSelectionChanged: "&?"
    } as BindingOf<IMeasurementSelectorComponentBindings>
  })

  // Adds `$results`, `$selected` and `$select` to the transclusion scope, which bind to
  // `$ctrl.searchResults`, `$ctrl.selectedResult`, and `$ctrl.selectResult()` respectively on the
  // `<measurement-selector>` controller.
  .directive(transcludeComponent, function() {
    return <IDirective> {
      require: [`^^${mainComponent}`],
      transclude: true,
      $scope: true,
      link(
        $scope: IMeasurementSelectorTranscludeScope,
        instanceElement: JQuery,
        _instanceAttributes: IAttributes,
        controllers: [MeasurementSelectorComponentController],
        transclude: ITranscludeFunction) {
          const main = controllers[0];

          // Provide some scope bound values as if we're in an ng-repeat or something else awesome.
          Object.defineProperties($scope, {
            $results: { get: () => main.searchResults, enumerable: true },
            $selected: { get: () => main.selectedResult, enumerable: true },
          });
          $scope.$select = (args) => main.selectResult(args);
          transclude(function(x) {
            instanceElement.append(x);
          });
      }
    };
  });