import { IDirective } from 'angular';

/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */

export default [ () =>
  (<IDirective>{
    restrict: "E",
    transclude: true,
    replace: true,
    template: 
      `<div ng-transclude> \
</div>`,
    scope: { 
      states: "="
    },
    controller: ["$scope", function($scope) {
      const regions = {};
    
      const api = {
        registerRegion(region) { return regions[region.key] = region; },
        regions
      };
    
      let appliedState = null;
      let appliedArgs = null;
    
      $scope.$on("layoutStateChanged", function(event, layoutState) {
    
        let classNames, regionName;
        if (appliedState) {
          const object = appliedState.getRegionClasses(appliedArgs);
          for (regionName in object) { classNames = object[regionName]; if (regions[regionName] != null) {
            regions[regionName].removeClass(classNames);
          } }
          appliedState = null;
          appliedArgs = null;
        }
    
        if (!$scope.states || !layoutState.state) { return; } 

        const foundState = $scope.states[layoutState.state];
      
        if (!foundState) { return; }
      
        const object1 = foundState.getRegionClasses(layoutState.args);
        for (regionName in object1) { classNames = object1[regionName]; if (regions[regionName] != null) {
          regions[regionName].addClass(classNames);
        } }
      
        appliedState = foundState;
        appliedArgs = layoutState.args;
      
      });
          
    
      return api;
    }
    ]
  })

];
