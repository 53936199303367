import {
  module as ngModule,
  element as ngElement
} from "angular";
import { WidgetController, WidgetService } from './widget';
import expressionModule, {
  serviceName as expressionServiceName,
  ExpressionService} from '../../reportFormatter/widgets/expression.service';
import variableModule, {
  serviceName as variableServiceName,
  VariableService
} from '../../reportFormatter/widgets/variable.service';
import conditionModule, {
  serviceName as conditionServiceName,
  ConditionalService
} from '../../reportFormatter/widgets/conditional.service';
import markerModule, {
  MarkerService,
  serviceName as markerServiceName
} from './marker.service';

export const serviceName = "widgetsService";

/** A service for dealing with non-specific widgets in the system. For dealing with widgets of a
* known type, use the concrete service related to that widget. For working widgets in aggregate,
* use this service. */
export class WidgetsService {
  readonly services:  ReadonlyArray<WidgetService<WidgetController>>;
  readonly querySelectorAll: string
  constructor(...widgetServices: WidgetService<WidgetController>[]) {
    this.services = widgetServices;
    this.querySelectorAll = widgetServices.map(s => s.querySelector).join(",");
  }

  /** Gets the widget controller object of any type known to this service for the provided DOM.
  * The result IS checked first to ensure it is valid. */
  get(element: JQuery): WidgetController {
    for (const widgetService of this.services) {
      const widget = widgetService.get(element);
      if (widget.isValid) {
        return widget;
      }
    }
    return null;
  }

  /** Gets whether the provided element is editable according to all widgets known to this service.
   */
  isEditable(element: JQuery): boolean {
    return element != null && element.length > 0 && this.services.every(x => x.isEditable(element));
  }

  /** Finds the closest known widget up the DOM hierarchy, starting with the provided node. */
  closest(element: JQuery): WidgetController {
    if (element != null || element.length > 0) {
      const closest = element.closest(this.querySelectorAll);
      if (closest.length === 1) {
        return this.get(closest);
      }
    }
    return null;
  }

  /** Finds all widgets known by this service beneath the provided element. */
  descendants(element: JQuery): WidgetController[] {
    const result: WidgetController[] = [];
    if (element != null || element.length > 0) {
      element.find(this.querySelectorAll).each((_, el) => {
        result.push(this.get(ngElement(el)));
      });
    }
    return result;
  }
}

export default ngModule("midas.blueprint.widgetsService", [
  expressionModule.name,
  variableModule.name,
  conditionModule.name,
  markerModule.name,
]).factory(serviceName, [
  conditionServiceName, expressionServiceName, variableServiceName, markerServiceName,
  (conditionService: ConditionalService,
   expressionService: ExpressionService,
   variableService: VariableService,
   markerService: MarkerService,
   ) => {
    return new WidgetsService(conditionService, expressionService, variableService, markerService);
   }]);