import * as angular from "angular";

var module = angular.module("midas.utility.measurement.views");

export interface IGestationalAgeScope extends angular.IScope {
    gestationalAgeWeek: number;
    gestationalAgeDay: number;
    key: string;
    measurement: any;
    measurements: any;
}

export interface IGestationalAgeAttributes extends angular.IAttributes {
    key: string;
}

export class GestationalAgeDirective implements angular.IDirective {
    restrict = "E";
    templateUrl = require("./GestationalAgeMeasurement.html");
    replace = true;
    scope = true;
    
    link = (scope: IGestationalAgeScope, element: angular.IAugmentedJQuery, attrs: IGestationalAgeAttributes) => {
        if (!angular.isString(attrs.key)) {
            throw new Error("gestational-age requires a 'key' attribute");
        }
        
        scope.key = attrs.key;
        scope.measurement = scope.measurements.values[scope.key];
        scope.show = scope.measurements.measurementVariant != 'Readonly';

        if (scope.measurement == null) {
            scope.measurements.addNotFound(scope.key);
            return;
        } else {
            scope.measurement.isMapped = true;
        }
        
        // unpack the values from the server
        if (scope.measurement.value) {
            const days = Number(scope.measurement.value);
            const decimalWeeks = days / 7;
            const wholeWeeks = Math.floor(decimalWeeks);
            const remainderDays = days % 7;
            scope.gestationalAgeWeek = wholeWeeks;
            scope.gestationalAgeDay = remainderDays;
        }
        
        scope.$watchGroup(["gestationalAgeWeek", "gestationalAgeDay"], (newValues, oldValues, scope: IGestationalAgeScope) => {
            // upon change in the UI, construct the gestational age from the weeks and days
            if (scope.gestationalAgeWeek || scope.gestationalAgeDay) {
                const weekDays = scope.gestationalAgeWeek ? scope.gestationalAgeWeek * 7 : 0;
                const daysRemainder = scope.gestationalAgeDay ? scope.gestationalAgeDay : 0;
                const daysTotal = weekDays + daysRemainder;
                scope.measurement.value = daysTotal;
            } else {
                scope.measurement.value = "";
            }
        });
    }

    static factory(): angular.IDirectiveFactory {
        const directive = () => new GestationalAgeDirective();
        directive.$inject = [];
        return directive;
    }
}

module.directive("gestationalAge", GestationalAgeDirective.factory());