import { MeasurementController, IMeasurementScope } from "../MeasurementController";
import { LoDashStatic } from "lodash";
import * as angular from "angular";
var module = angular.module("midas.utility.measurement.views");

export class ObstetricsMeasurementsControllerBase extends MeasurementController {
    normalValue = "normal";
    fetusCountKey = "FetusCount";
    measurementVariant = "";
  
    static $inject = ["$scope", "lodash"]; 

    constructor(scope: IMeasurementScope, private lodash: LoDashStatic) {
        super(scope);
        this.measurementVariant = scope.measurements.variant;
    }

    protected setNormal(fetusNumber: number, ...keys: string[]) {
        for (let key of keys) {
            this.setMeasurementValue(`F${fetusNumber}${key}`, this.normalValue);
        }
    }

    hasMultipleFetusCount() : boolean {
        const count = this.getFetusCount();
        return count && count > 1;
    }

    getFetusCount(): number {
        const fetusCount = this.getMeasurementValue(this.fetusCountKey);
        if (!fetusCount)
            return undefined;

        return parseInt(fetusCount);
    }

    getFetusCountRangeArray() : Array<number> {
        const count = this.getFetusCount();
        //Show one fetus UI at least as I do not think there should ever be a case where no fetus UI is displayed.
        if (!count)
            return [1];
        return this.lodash.range(1, count + 1);
    }
};

module.controller("STDOBSBaseCtrl", ObstetricsMeasurementsControllerBase);