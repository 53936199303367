import * as angular from "angular";
import loginCtrl from "./loginCtrl";
import loginGuideCtrl from "./loginGuideCtrl";
import userEditCtrl from "./userEditCtrl";
import mdsLogoutDirective from "./mdsLogout";
import changePasswordCtrl from "./changePasswordCtrl"

export default angular.module("midas.users", [])
    .controller("loginCtrl", loginCtrl)
    .controller("loginGuideCtrl", loginGuideCtrl)
    .controller("changePasswordCtrl", changePasswordCtrl)
    .controller("userEditCtrl", userEditCtrl)
    .directive("mdsLogout", mdsLogoutDirective);