/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import layoutStateModule from "./utility/layout/layout";
import studiesModule from "./midas/studies/studies";
import * as angular from "angular";
import { IStateService } from 'angular-ui-router';
import { BusinessModelService } from "./businessModels";
import { IAuthService } from './authService';


export default angular.module("midas.state.config", [
    layoutStateModule.name,
    studiesModule.name
  ]).config(["$urlRouterProvider", "layoutStateProvider", function($urlRouterProvider, layoutStateProvider) {

  //Only need to apply the resolver at the root state level that you want to secure
  const authCheck = ["authService", function(authService: IAuthService) {
      if (!authService.isAuthenticated()) { throw new Error("Authentication Required"); }
    }
  ];

  const initLoadCheck = ["businessModels", businessModels => businessModels.whenLoaded ];
  const userLoadCheck = ["businessModels", businessModels => businessModels.userLoaded ];

  const instituteCheck = ["userLoad", "$state", "$stateParams", "businessModels", function(userLoad, $state, $stateParams, businessModels) {
    const currentInst = businessModels.User.current != null ? businessModels.User.current.institute : undefined;
    if (currentInst && ($stateParams.inst !== currentInst.key)) {
      console.log($stateParams.inst);
      console.log(currentInst.key);
      $state.go("midas", {inst: currentInst.key});
    }
    return currentInst;
  }
  ];

  const defaultLoadCheck = {
    loadCheck: initLoadCheck,
    authCheck,
    userLoad: userLoadCheck,
    inst: instituteCheck
  };

  const examResolver = {
    exam: ["lodash", "$stateParams", "study", (lodash, $stateParams, study) => lodash.find(study.exams, exam => (exam.id + "") === ($stateParams.examId + ""))]
  };

  layoutStateProvider
  .state("splash", {
    url: "/",
    views: {
        "layout": {
            templateUrl: require("./anon/splash.html")
          }
      },
    resolve: {
      redirect: ["authService", "$state", "businessModels", function(authService: IAuthService, $state, businessModels) {
        if (authService.isAuthenticated()) {
          return businessModels.userLoaded
          .then(() => $state.go("midas", { inst: businessModels.User.current.institute.key }));
        }
      }
      ]
    }
  })

  .state("demo", {
    url: "/demo",
    views: {
      "layout": {
        template: '<demo></demo>'
      }
    }
  }).state("loginGuide", {
    url: "/guide",
    views: {
      "layout": {
        templateUrl: require("./midas/users/loginGuide.html"),
        controller: "loginGuideCtrl"
      }
    }
  }).state("login", {
    url: "/:inst/login/:expired",
    views: {
      "layout": {
        templateUrl: require("./midas/users/login.html"),
        controller: "loginCtrl"
      }
    }
  }).state("midas", {
    url: "/:inst",
    resolve: defaultLoadCheck,
    views: {
      "layout": {
        templateUrl: require("./midas/app.html"),
        controller: "layoutCtrl"
      }
    }
  }).state("midas.myDetails", {
    url: "/mydetails",
    views: {
      "work@midas": {
        templateUrl: require("./midas/users/userEdit.html"),
        controller: "userEditCtrl"
      }
    }
  }).state("midas.changePassword", {
    url: "/changepassword",
    views: {
      "work@midas": {
        templateUrl: require("./midas/users/changePassword.html"),
        controller: "changePasswordCtrl"
      }
    }
  }
  );

  $urlRouterProvider.otherwise("/");

}
  ])
  .config(['matchmediaProvider', function(matchmediaProvider) {
    matchmediaProvider.rules.tablet = "(min-width: 768px) and (max-width: 1199px)";
    matchmediaProvider.rules.desktop = "(min-width: 1200px)";
  }

  ])
  .config(["$compileProvider", $compileProvider =>
    //needed to download files from client side with data href
    $compileProvider.aHrefSanitizationWhitelist(/^\s*(https?|ftp|mailto|tel|file|blob|data):/)

  ])
  .config(["$mdThemingProvider", function(themeProvider) {
    //Generated using the material design palette tool found here: http://mcg.mbitson.com/
    //Using the seed colour: #406c95
    themeProvider.definePalette("midas", {
        '50': 'e8edf2',
        '100': 'c6d3df',
        '200': 'a0b6ca',
        '300': '7998b5',
        '400': '5d82a5',
        '500': '406c95',
        '600': '3a648d',
        '700': '325982',
        '800': '2a4f78',
        '900': '1c3d67',
        'A100': 'a4c9ff',
        'A200': '71abff',
        'A400': '3e8dff',
        'A700': '257eff',
        'contrastDefaultColor': 'light',
        'contrastDarkColors': [
          '50',
          '100',
          '200',
          '300',
          'A100',
          'A200',
          'A400'
        ],
        'contrastLightColors': [
          '400',
          '500',
          '600',
          '700',
          '800',
          '900',
          'A700'
        ]
      });

    themeProvider.theme('default').primaryPalette('midas');

    themeProvider.definePalette('dark', {
      '50': 'e7e9eb',
      '100': 'c4c8cc',
      '200': '9ca4ab',
      '300': '747f89',
      '400': '57636f',
      '500': '394856',
      '600': '33414f',
      '700': '2c3845',
      '800': '24303c',
      '900': '17212b',
      'A100': '6fb3ff',
      'A200': '3c98ff',
      'A400': '097dff',
      'A700': '0070ef',
      'contrastDefaultColor': 'light',
      'contrastDarkColors': [
        '50',
        '100',
        '200',
        'A100',
        'A200'
      ],
      'contrastLightColors': [
        '300',
        '400',
        '500',
        '600',
        '700',
        '800',
        '900',
        'A400',
        'A700'
      ]
    });

    themeProvider.theme('dark').primaryPalette('dark');

    themeProvider.theme('patient')
      .primaryPalette('green')
      .accentPalette('teal')
      .warnPalette('amber')
      .backgroundPalette('light-green')
      .dark();
    return themeProvider.theme('study')
      .primaryPalette('blue')
      .accentPalette('teal')
      .warnPalette('amber')
      .backgroundPalette('blue-grey')
      .dark();
  }
  ])
  .run(["$rootScope", "$state", "businessModels", function($rootScope: angular.IRootScopeService, $state: IStateService, businessModels: BusinessModelService) {
    $rootScope.$on('$stateChangeError', function(event, toState, toParams, fromState, fromParams, error) {
      let inst;
      console.log(`error changing state: ${toState.name}`);
      console.error(error);
      if (error.message === "Authentication Required") {
        ({ inst } = toParams);
        const paramArray = [];
        delete toParams.inst;
        for (let prop in toParams) { const value = toParams[prop]; paramArray.push(`${prop}=${value}`); }
        const params = paramArray.join("+");
        return $state.go("login", {
          inst,
          redirect: toState.name,
          params
        }
        );
      } else {
        return $state.go("midas",
          {inst: toParams.inst});
      }
    });

    $rootScope.$on("$stateChangeSuccess", function(event, toState, toParams, fromState, fromParams) {
      //console.log "#{fromState.name} -> #{toState.name}"
      if (!businessModels.save.status.isLoading && businessModels.breeze.hasChanges()) { return businessModels.breeze.rejectChanges(); }
    });
  }
  ]);
