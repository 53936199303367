import * as angular from "angular";
import "angular-material";
import "ng-file-upload";
import { Exam, Status, Physician, Technician, MeasurementType, Modality, ExamType, BusinessModelService } from "../../businessModels";
import { EntityQuery, Predicate, QueryResult } from "breeze";

class UploadStudyController implements angular.IController {
    static $inject = ["$q", "$mdToast", "$mdDialog", "$log", "businessModels"];
    constructor(
        private $q: angular.IQService,
        private mdToast: angular.material.IToastService,
        private mdDialog: angular.material.IDialogService,
        private $log: angular.ILogService,
        private models: BusinessModelService,
    ) { }

    $onInit() { }

    uploadStudy (file: File) {
        if (file === null) return;

        var r = new FileReader();
        r.readAsText(file[0]);

        // Callback function called when file is loaded
        r.onload = () => {

            let data = null;
            try {
                data = JSON.parse(<string> r.result); // result is string because we used readAsText
            }
            catch (err) {
                this.$log.error("exeption:" + err);
                this.mdDialog.show(this.mdDialog.alert()
                    .textContent("Invalid JSON file.")
                    .ok("OK"));
                return;
            }

            // Functions' declaration for loading not cached data from the server
            const getMeasurementTypes = (modalityId: number): angular.IPromise<MeasurementType[]> => {
                var query = new EntityQuery("MeasurementTypes")
                    .where(new Predicate("modalityId", "==", modalityId));

                return this.$q.when<QueryResult>(<any>this.models.breeze.executeQuery(query))
                    .then(mtl => {
                        return mtl.results.map(
                            r => this.models.asBusinessModel<MeasurementType>(<any>r));
                    });
            }
            const getPhysician = (): angular.IPromise<Physician> => {
                return this.models.Physician.list()
                    .then(physicians => {
                        return physicians.filter(function (obj) {
                            return obj.id === data.physician.id
                        })[0]
                    });
            }
            const getTechnician = (): angular.IPromise<Technician> => {
                return this.models.Technician.list()
                    .then(technicians => {
                        return technicians.filter(function (obj) {
                            return obj.id === data.technician.id
                        })[0]
                    });
            }

            // Upload the study
            const uploadExam = (): angular.IPromise<void> => {
                // Get cached data required for creating a new study
                const examTypeList: ExamType[] = this.models.ExamType.listActive();
                const modality: Modality = this.models.Modality.listNow().filter(function (obj) {
                    return obj.id === data.modality.id
                })[0];
                const status: Status = this.models.Status.listNow().filter(function (obj) {
                    return obj.id === data.status.id
                })[0];

                // First load the data required for creating a new study from the server
                // since it is not available on the client side as a cached data
                return this.$q.all({ mts: getMeasurementTypes(modality.id), phys: getPhysician(), tech: getTechnician() })
                    .then(result => {
                        // Create the study using the information ptovided in the JSON file
                        return this.models.Patient.find({ 'id': data.patient.id })
                            .then(patient => {
                                return this.models.Study.create(data.reportedAt, patient, modality, status, result.phys, result.tech)
                                    .then(study => {
                                        data.exams.forEach(ex => {
                                            var et = examTypeList.filter(function (obj) {
                                                return obj.key === ex.type
                                            })[0];
                                            if (et) {
                                                var exam: Exam = study.createExam(et, ex.performedAt, ex.uid);
                                                ex.measurements.forEach(m => {
                                                    var ms = result.mts.filter(function (obj) {
                                                        return obj.key === m.key
                                                    })[0];
                                                    exam.newMeasurement(ms, m.value);
                                                })
                                            }
                                        });
                                        return this.models.save();
                                    })
                            });
                    })
                    .then(() => this.mdToast.showSimple("Study successfully uploaded."))
                    .catch(e => {
                        this.$log.error("exeption:" + e);
                        this.mdDialog.show(this.mdDialog.alert()
                            .textContent("There was a problem uploading the study. Please try again.")
                            .ok("OK"));
                    });
            }

            // Check if study with the same UID exists already.
            // If yes - give a warning and cancel the operation
            if (data.exams[0].uid && data.exams[0].uid.replace(/\s/g, "").length > 0) {
                this.models.Exam.find({ 'uID': data.exams[0].uid }, "study.patient.person")
                    .then(exam => {
                        if (exam) {
                            this.mdDialog.show(
                                {
                                    templateUrl: require('./uploadStudyDialog.html'),
                                    clickOutsideToClose: true,
                                    locals: {
                                        exam: exam,
                                        mDialog: this.mdDialog
                                    },
                                    controller: DialogController,
                                    controllerAs: "$ctrl"
                                }
                            )
                            return;
                        } else {
                            uploadExam();
                        }
                    })
            } else {
                uploadExam();
            }
        }
    }
}


class DialogController implements angular.IController {
    exam: Exam;
    mDialog: angular.material.IDialogService;

    static $inject = ["exam", "mDialog"];
    constructor(exam: Exam, mDialog: angular.material.IDialogService) {
        this.exam = exam;
        this.mDialog = mDialog;
    }

    $onInit() { }

    cancel() {
        this.mDialog.hide();
    }
}

export default <angular.IComponentOptions>{
    controller: UploadStudyController,
    templateUrl: require('./uploadStudy.html')
}