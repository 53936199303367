import { blur, focus } from './mdsUtils';


export class LocalContext {
    $log(...args): void {}

    constructor(event, element) {
      const ctorArgs = arguments;

      //provide access to the focus() and blur() events of an element if it was passed in.
      if (element != null) {
        this["$focus"] = () => focus(element);
        this["$blur"] = () => blur(element);
      }

      //Provide access to an event object if it was passed in.
      if (event != null) { this["$event"] = event; }

      //Provides access to the console.log function for debugging events. If no arguments are
      //passed, then the arguments to this instance of the local context constructor are printed.
      this.$log = function(...args) {
          if (args.length === 0) {
              if (ctorArgs.length > 0) { console.log.apply(console, <any>(ctorArgs || [])); }
        } else {
              console.log.apply(console, <any>(args || []))
        }
      };
    }
  }

/**
 * An object suitable for passing to a compiled expression from the $parse service. These
 * expressions take a scope, but also a local context where they look for variables. This object
 * supplies extra variables which are relevant to the arguments it is provided. For example, if
 * the 'event' arg is supplied, then the compiled expression may contain $event to access it.
 * Doesn't need to be injected via angular.
 */
export interface LocalContext {
  /** Creates a LocalContext instance with a $log() function. */
  new (): LocalContext;
  /** Creates a LocalContext instance with a $log(), and $event. */
  new <TEvent>(event: TEvent): LocalContextWithEvent<TEvent>;
  /** Creates a LocalContext instance with a $log(), $event, &focus(), and $blur(). */
  new <TEvent>(event: TEvent, element: HTMLElement, ...others): LocalContextWithElement<TEvent>;

  /**
   * Prints the arguments to console.log.
   *
   * @param {any} args Arguments to print to the log. If no arguments are
   * passed, the arguments this instance was constructed with are printed.
   */
  $log(...args): void;
}

interface LocalContextWithEvent<TEvent> extends LocalContext {
  /**
   * Event object passed in to the LocalContext constructor.
   *
   * @type {TEvent} Type of event.
   */
  $event: TEvent;
}

interface LocalContextWithElement<TEvent> extends LocalContextWithEvent<TEvent> {
  /** Causes the element the LocalContext was constructed with to lose focus. */
  $blur(): void;
  /** Causes the element the LocalContext was constructed with to gain focus. */
  $focus(): void;
}