import reportEditorModule, { ToolbarAttributes, TinymceConfigurationController, tinymceConfigurationRequire } from "../mds-rich-text-editor.component";
import * as tinymce from "tinymce";
import { module as ngModule, IScope, ILogService, IAttributes } from "angular";

let uniqueId = 0;

interface TinymceToolbarButton extends TinymceConfigurationController { }
/** Controller for adding a new custom button or dropdown menu to the toolbar. These directives
 * can be nested to create menus. */
class TinymceToolbarButton extends TinymceConfigurationController {
    /** Each button needs a unique ID so it can be referenced when configuring the toolbar. We don't
     * really care what the ID actually is since we're configuring all of that automatically.
     */
    readonly id = "auto-button-id-" + uniqueId++;
    /** If supplied, this will will be displayed on the button. */
    mceText?: TinymceButtonOptions["text"];
    /** If supplied, sets the type of button. */
    mceType?: TinymceButtonOptions["type"];
    /** If supplied, this icon will be displayed on the button. */
    mceIcon?: TinymceButtonOptions["icon"];
    /** If supplied, this image will be displayed on the button. */
    mceImage?: TinymceButtonOptions["image"];
    /** If supplied, this tooltip will be displayed when the mouse hovers over the button. */
    mceTooltip?: TinymceButtonOptions["tooltip"];
    /** Optional custom classes to apply to the button. */
    mceClasses?: TinymceButtonOptions["classes"];
    /** Whether to enable or disable the button. Defaults to enabled. */
    mceDisabled?: TinymceButtonOptions["disabled"];
    /** Tinymce onclick event, with the tinymce editor instance and click event automatically bound
     * as locals.
     */
    mceClick?(locals: { editor: tinymce.Editor, event: Event }): void;
    /** The tinymce onpostrender callback, with the editor instance automatically bound as a local.
     */
    mcePostRender?(locals: { editor: tinymce.Editor }): void;
    /** Rather than onclick, this can be used to specify any in-built tinymce command to execute
     * when this button is clicked. This is most useful if you want to redefine the style of a
     * built-in button.
     */
    mceCmd?: TinymceButtonOptions["cmd"];

    /** buttons nested under this component will register themselves with this list to create a
     * dropdown menu.
     */
    private children: TinymceToolbarButton[] = [];
    /** If present then this button is nested under another button. It will register itself with
     * that button's `children` list to form a dropdown menu, rather than registering with the
     * wrapper directly.
     */
    private parent?: TinymceToolbarButton;
    private editor: tinymce.Editor;
    private buttonOptions: TinymceButtonOptions;
    private buttonControl: any;

    static $inject = ["$scope", "$log", "$attrs"];
    constructor($scope: IScope, private readonly $log: ILogService, private readonly $attrs: IAttributes) {
        super();

        const self = this;
        // Wrapper functions which bind the editor to the click and post render functions. They're
        // pretty much useless without that since you can't insert or alter anything.
        function onclick(event: Event) {
            if (typeof self.mceClick === "function") {
                self.mceClick({ editor: self.editor, event });
                $scope.$apply();
            }
        }
        function onPostRender(this: any, e: tinymce.Events.Event & { control: any }) {
            let needsApply = false;
            if (self.buttonControl == null) {
                self.buttonControl = e.control;
                needsApply = true;
            }
            if (typeof self.mcePostRender === "function") {
                self.mcePostRender({ editor: self.editor });
                needsApply = true;
            }
            if (needsApply) {
                $scope.$apply();
            }
        }

        this.buttonOptions = {
            type: this.mceType,
            text: this.mceText,
            icon: this.mceIcon,
            image: this.mceImage,
            tooltip: this.mceTooltip,
            classes: this.mceClasses,
            disabled: this.mceDisabled,
            cmd: this.mceCmd,
            menu: [],
            onclick,
            onPostRender
        };
    }

    $onChanges() {
        const options = this.buttonOptions;
        options.classes = this.mceClasses;
        options.disabled = this.mceDisabled;
        options.image = this.mceImage;
        options.icon = this.mceIcon;
        options.cmd = this.mceCmd;
        options.text = this.mceText;
        options.tooltip = this.mceTooltip;

        if (this.buttonControl) {
            this.buttonControl.menu = null;
            this.buttonControl.type = this.mceType != null
                ? this.mceType
                : ((this.children.length > 0) ? "menubutton" : undefined);
            this.buttonControl.disabled(this.mceDisabled);
            this.buttonControl.icon(this.mceIcon);
            this.buttonControl.text(this.mceText);
            this.buttonControl.tooltip().text(this.mceTooltip);
        }
    }

    /** At this point we can set which buttons should appear on the toolbar, but we don't actually
     * have a handle on the editor object yet.
     */
    configure(options: tinymce.Settings) {
        const toolbar = ToolbarAttributes.FromAttributes(this.$attrs);
        this.extendToolbar(toolbar.id, this.id, options);
    }

    /** This is called as tinymce is initialising. At this point we can actually add the buttons
     * definitions and get a handle on the editor instance, which is required in order to do much
     * of interest with buttons.
     */
    setup(editor: tinymce.Editor) {
        if (this.editor != null) {
            this.$log.error("<mce-toolbar-button> setup() called more than once.");
            return;
        }
        this.editor = editor;
        editor.addButton(this.id, this.buttonOptions);
    }

    /** Override the default intialisation function to support nested button registrations. */
    $onInit() {
        if (this.parent) {
            this.parent.addChild(this);
        } else {
            this.wrapper.children.push(this);
        }
    }

    /** Override the default destruction function to support nested button registrations. */
    $onDestroy() {
        if (this.parent) {
            this.parent.removeChild(this);
        } else {
            const index = this.wrapper.children.indexOf(this);
            if (index >= 0) {
                this.wrapper.children.splice(index, 1);
            }
        }
    }

    /** Adds a nested toolbar button to create a menu. */
    addChild(child: TinymceToolbarButton) {
        if (child) {
            this.children.push(child);
            this.buttonOptions.menu.push(child.buttonOptions);
        }
    }

    /** Removes a nested toolbar button from the menu. */
    removeChild(child: TinymceToolbarButton) {
        if (child) {
            let index = this.children.indexOf(child);
            if (index >= 0) {
                this.parent.children.splice(index, 1);
            }

            index = this.buttonOptions.menu.indexOf(child.buttonOptions);
            if (index >= 0) {
                this.buttonOptions.menu.splice(index, 1);
            }
        }
    }
}

/** My best effort at creating a type for the button definition object that tinymce expects when
 * calling `editor.addButton(id, button-definition)`. The documentation is very thin, sometimes
 * conflicting, and spread across a fair number of articles depending on what exactly you google.
 * Tinymce is pretty annoying like that.
 */
export interface TinymceButtonOptions {
    /** There are others like "menuitem", but "menubutton" is the only one I've had to explicitly
     * set in certain cases to get tinymce working.
     */
    type: undefined | string | "menubutton";
    /** Text to display on the button. */
    text?: string;
    /** Icon to display on the button. */
    icon?: boolean | string;
    /** Image to display on the button. */
    image?: string;
    /** Tooltip to display when the mouse is over the button. */
    tooltip?: string;
    /** Custom classes to add to the button. */
    classes?: string;
    /** Whether the button is enabled or disabled. */
    disabled?: boolean;
    /** I think this is a raw JS binding on the button in DOM, which is pretty annoying because it
     * means you have to keep a handle on the editor instance to actually do much interesting. Our
     * directive binds the editor instance as an argument automatically, so you don't need to worry
     * about it unless you're no using the directive.
     */
    onclick?(event?: Event): void;
    /** This seems to be called whenever the button is rendered, just after rendering is finished.
     * As with onclick, our directive binds the editor instance as an argument automatically, so you
     * don't need to worry about it unless you're no using the directive
     */
    onPostRender?(this: any, e: tinymce.Events.Event & { control: any }): void;
    /** Rather than onclick, this can be used to specify any in-built tinymce command to execute
     * when this button is clicked. This is most useful if you want to redefine the style of a
     * built-in button.
     */
    cmd?: string;
    /** If this button should be a dropdown menu instead, this should contain the child buttons. In
     * that case you also need to set `this.type = "menubutton"` or tinymce will ignore these.
     */
    menu?: TinymceButtonOptions[];
}

export default ngModule("midas.tinymce.toolbarButton", [reportEditorModule.name])
// Adds a custom button to the toolbar. Callbacks are real angular callbacks which receive an
// 'editor' argument, and an 'event' argument for mce-click. These can also be nested to create a
// toolbar menu.
// EG:
// `<mce-toolbar-button mce-text="I like it" mce-click="editor.insertContent('I like it!')"></mce-toolbar-button>`
// Nested EG:
// <mce-toolbar-button text="My sweet menu">
//     <mce-toolbar-button mce-text="I like it" mce-click="editor.insertContent('I like it!')"></mce-toolbar-button>
//     <mce-toolbar-button mce-text="You like it" mce-click="editor.insertContent('You like it!')"></mce-toolbar-button>
// </mce-toolbar-button>
.component("mceToolbarButton", {
    require: {
        ...tinymceConfigurationRequire,
        parent: "^^?mceToolbarButton"
    },
    bindings: {
        mceText: "<?",
        mceType: "<?",
        mceTooltip: "<?",
        mceIcon: "<?",
        mceImage: "<?",
        mceClasses: "<?",
        mceDisabled: "<?",
        mceClick: "&?",
        mcePostRender: "&?",
        mceCmd: "<?"
    },
    controller: TinymceToolbarButton
});