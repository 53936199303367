import { MeasurementController, IMeasurementScope } from "../MeasurementController";
import * as angular from "angular";
class MRElbowController extends MeasurementController {
  static $inject = ["$scope"];
  constructor(scope: IMeasurementScope) {
    super(scope);
  }

  clickRightOK() {
    this.setMeasurementValue("MRElbowRtMedialNormal", "There is no complete or bursal/articular sided partial rotator cuff tear. The subscapularis constituent of the rotator cuff is intact. ");
    this.setMeasurementValue("MRElbowRtLateralNormal", "No bursal effusion or thickening is seen. ");
    this.setMeasurementValue("MRElbowRtPosteriorNormal", "There is no muscular tear, contusion, or atrophy. ");
    this.setMeasurementValue("MRElbowRtArticulationsNormal", "There are mild degenerative changes of the acromioclavicular joint. A type 2 acromion configuration is noted. There is no anterior or lateral acromial downsloping. ");
    this.setMeasurementValue("MRElbowRtAnteriorNormal", "There are no fractures or regions of abnormal bone marrow signal intensity. ");
    this.setMeasurementValue("MRElbowRtGeneralNormal", "The biceps tendon is normally situated within the bicipital groove. No complete or partial biceps tendon tear is present. ");
    console.log("Right All normal clicked");
  }

  clickLeftOK() {
    this.setMeasurementValue("MRShoulderLtRotatorCuff", "There is no complete or bursal/articular sided partial rotator cuff tear. The subscapularis constituent of the rotator cuff is intact. ");
    this.setMeasurementValue("MRShoulderLtBursa", "No bursal effusion or thickening is seen. ");
    this.setMeasurementValue("MRShoulderLtMusculature", "There is no muscular tear, contusion, or atrophy. ");
    this.setMeasurementValue("MRShoulderLtAcromioclavicularJoint", "There are mild degenerative changes of the acromioclavicular joint. A type 2 acromion configuration is noted. There is no anterior or lateral acromial downsloping. ");
    this.setMeasurementValue("MRShoulderLtOsseousStructures", "There are no fractures or regions of abnormal bone marrow signal intensity. ");
    this.setMeasurementValue("MRShoulderLtLongBiciptalTendon", "The biceps tendon is normally situated within the bicipital groove. No complete or partial biceps tendon tear is present. ");
    this.setMeasurementValue("MRShoulderLtGlenohumeralJoint", "There is no glenohumeral joint effusion.");
    this.setMeasurementValue("MRShoulderLtCartilageAndBone", "No focal hyaline cartilage defects are noted. No Hill-Sachs, reverse Hill-Sachs, or bony Bankart lesions are seen.");
    this.setMeasurementValue("MRShoulderLtLabrum", "There are no SLAP or soft tissue Bankart lesions. No paralabral cysts are seen.");
    this.setMeasurementValue("MRShoulderLtOtherStructures", "No capsular or ligamentous abnormality is seen.L");
    this.setMeasurementValue("MRShoulderLtOtherComment", "None.");
  }
}

angular.module("midas.utility.measurement.views").controller("MRElbowCtrl", MRElbowController);
