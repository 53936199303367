import { ISearchTermProvider, ISelect2Query } from "./searchTermProviders";
import { ISearchTerm } from "./search";
import { Site, BusinessModelService } from "../../businessModels";
import { IPromise, module as ngModule } from "angular";

class SiteSearchTermProvider implements ISearchTermProvider {
  minimumTermLength = 2;
  category = "Site";

  static $inject = ["businessModels"];
  constructor(private readonly models: BusinessModelService) {

  }

  getTerms(query: ISelect2Query): IPromise<any[]> {
    return this.models.Site.list();
  }

  transform(term: Site): ISearchTerm {
    return {
      id: `${term.id}_site`,
      text: term.name,
      type: "site",
      value: term.id
    };
  }
}

export default ngModule(
  "midas.utility.search.siteSearchTermProvider", [])
  .service("siteTermProvider", SiteSearchTermProvider);