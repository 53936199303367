import ReferenceGroup from "./ReferenceGroup";
import { IQService } from "angular";
import { Study, StudyDocument, PatientDocument } from "../../../businessModels";
import * as thumbnailUrl from "../templates/documentThumbnail.html";
import * as fullUrl from "../templates/documentFull.html";

export default class DocumentGroup extends ReferenceGroup {
    constructor(public study: Study, private $q: IQService) {
        super("Documents", thumbnailUrl, fullUrl);

        this.iconClass = "glyphicon glyphicon-inbox";
    }

    loadThumbnails() {
        for (let doc of this.study.patient.documents) {
            doc["subCategory"] = "patient";
        }
        this.thumbnails =
            new Array<StudyDocument | PatientDocument>().concat(
                this.study.documents,
                this.study.patient.documents);
        return this.$q.when(this.thumbnails);
    }
}