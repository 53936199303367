import { MeasurementController, IMeasurementScope } from "../MeasurementController";
import * as angular from "angular";
class DXLumbarPunctureController extends MeasurementController {
  static $inject = ["$scope"];
  constructor(scope: IMeasurementScope) {
    super(scope);
  }

  clickOK() {
    this.setMeasurementValue("DXLumbarPunctureOtherComment", "Lidocaine 1% was used for local anesthesia. Lumbar puncture was performed under fluoroscopic guidance with strict aseptic precautions at L3-L4 via right oblique sublaminar approach using a 3.5 inch 20-gauge spinal needle. Retrograde free flow of clear CSF was obtained. 3 cc of CSF was collected and sent to lab for investigations. ");
    this.setMeasurementValue("DXLumbarPunctureConclusion", "Fluoroscopically guided lumbar puncture. No immediate complications. ");
    console.log("All normal clicked")
  }

}

angular.module("midas.utility.measurement.views").controller("DXLumbarPunctureCtrl", DXLumbarPunctureController);