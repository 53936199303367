import { IPointLike, Vector2, traceIgnore, trace, Traceable, Rect } from "../../../utility/utils";
import { Shape, Graphics } from "createjs";
import RenderableShape, { shadowDefaultExtraThickness, shadowDefaultColour } from "./renderableShape";
import Circle from "./circle";
import { coordExtent } from "../imageEditorVars";

/** A free form drawing from a list of points, such as a pen or an eraser. */
export default class Drawing extends RenderableShape {
    /** The handle to the graphical representation of this shape. */
    ink: Shape;
    /** The path segment delimiters. */
    points: Vector2[];
    /** The thickness of the drawing. */
    thickness: number;
    /** The colour to use to draw the path. */
    colour: string;
    /** The colour to use to draw the shadow. */
    shadowColour: string = shadowDefaultColour;
    /** How much thicker the shadow should be compared to the shape. */
    shadowExtraThickness: number = shadowDefaultExtraThickness * coordExtent;
  
    static render(ink: Shape, pts: IPointLike[], thickness: number, colour: string) {
      let g: Graphics;
      switch (pts.length) {
        case 0: break;
        case 1: Circle.render(ink, pts[0], thickness / 2, colour); break;
        default:
          const mid = Vector2.centroid;
          const start = mid(pts[0], pts[1]);
          g = ink.graphics
            .setStrokeStyle(thickness, "round", "round")
            .beginStroke(colour)
            .moveTo(pts[0].x, pts[0].y)
            .lineTo(start.x, start.y);
          for (let i = 2, end = pts.length; i < end; i++) {
            const ctrlPoint = pts[i - 1];
            const end = mid(ctrlPoint, pts[i]);
            g.curveTo(ctrlPoint.x, ctrlPoint.y, end.x, end.y);
          }
      }
      return ink;
    }
  
    render(drawShadow: boolean = false, target: Shape = this.ink) {
      this.$trace && this.$trace("Drawing.render");
      target.graphics.clear();
      if (drawShadow) {
        Drawing.render(target, this.points, this.thickness + this.shadowExtraThickness, this.shadowColour);
      }
      return Drawing.render(target, this.points, this.thickness, this.colour);
    }
  
    protected getBounds(): Rect {
        return Rect.boundingRect(this.points)
            .expand(this.thickness * 0.5);
    }
  
    addPoint(p: IPointLike, progressiveRender = true, drawShadow: boolean = false) {
      const pts = this.points;
      //Throttle out duplicate points.
      if ((pts.length === 0) || !pts[pts.length - 1].equals(p, 0.0001)) {
        pts.push(new Vector2(p));
        if (progressiveRender) {
          this.$trace && this.$trace("Drawing.addPoint with progressive render", p);
          const len = pts.length;
          if (len < 4) {
            this.render(drawShadow); //First couple of points are drawn differently, so re-render each time.
          } else {
            const mid = Vector2.centroid;
            const ctrlPoint = pts[len - 2];
            const start = mid(pts[len - 3], ctrlPoint);
            const end = mid(ctrlPoint, pts[len - 1]);
  
            if (drawShadow) {
              this.ink.graphics
                .setStrokeStyle(this.thickness + this.shadowExtraThickness)
                .beginStroke(this.shadowColour)
                .moveTo(start.x, start.y)
                .curveTo(ctrlPoint.x, ctrlPoint.y, end.x, end.y)
                .setStrokeStyle(this.thickness)
                .beginStroke(this.colour);
            }
            this.ink.graphics
              .moveTo(start.x, start.y)
              .curveTo(ctrlPoint.x, ctrlPoint.y, end.x, end.y);
          }
        } else if (this.$trace) {
          this.$trace("Drawing.addPoint", p);
        }
      }
    }
  
    //Bakes the current ink position into the points and re-renders. Essentially shifts any offset from
    //the ink position to the actual pixel positions.
    bakeTransform() {
      this.$trace && this.$trace("Drawing.bakeTransform()");
      const m = this.ink.getMatrix(RenderableShape.ScratchMatrix);
      if (!m.isIdentity()) {
        for (var point of this.points) {
          m.transformPoint(point.x, point.y, point);
        }
        RenderableShape.resetInkTransform(this.ink);
      }
    }
  
    /** Allows trace logging to be enabled or disabled for this object. */
    setTracing(options: Traceable.Options) {
      if ("trace" in options) {
        const traceOption = options["trace"];
        this.$trace = traceOption ? (typeof traceOption === "function" ? traceOption : trace) : traceIgnore;
      }
    }
  
    constructor(points: IPointLike[], thickness: number, colour: string) {
      super();
      this.thickness = thickness;
      this.colour = colour;
      this.ink = new Shape();
      this.ink.name = "Drawing";
      this.points = points.map((p) => new Vector2(p));
  
      this.render();
    }
  }