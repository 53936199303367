import { module, IScope } from "angular";
import { BusinessModelService, Study, ICongruenceEntity, ICongruenceVariationEntity 
} from "../../businessModels";

interface ReportsCongruenceController extends angular.IController {}
class ReportsCongruenceController implements angular.IController {

  private readonly study: Study

  reportCongruence: ICongruenceEntity;
  variations: ICongruenceVariationEntity[];
  isReadOnlyCongruence: boolean = true;
  showCongruenceSection: boolean = false;
  congruenceOpen: boolean = false;
  
  static $inject = ["businessModels", "$scope"];
  constructor( 
    private readonly models: BusinessModelService,
    private readonly $scope: IScope
  ) {      
    var self = this;
    self.$scope.$on("showCongruence", function(_e) { self.init() });
    self.init();
  }

    init() {
      var self = this;

      const sonSet = self.models.Institute.current.getSetting("ReportCongruenceForSonographer");
      const sonographerSetting = (typeof sonSet === "string") ? sonSet.toLowerCase() === "true" : false;

      if ( (self.study.status.key === "Provisional" || self.study.status.key === "Final" ) 
        && (
          self.models.User.current.hasRole("Is Administrator")
          || (self.study.physician != null && self.models.User.current.physician != null && self.study.physician.id === self.models.User.current.physician.id) 
          || (self.study.technician != null
              && self.models.User.current.technician != null
              && self.study.technician.id === self.models.User.current.technician.id
              && sonographerSetting)         
        ) 
      ) {
        self.showCongruenceSection = true;
        const mandatory = self.models.Institute.current.getSetting("ReportCongruenceMandatory");
        self.congruenceOpen = (typeof mandatory === "string") ? mandatory.toLowerCase() === "true" : false;
        self.study.loadCongruenceVariations().then(x => self.variations = x);
        self.loadCongruence();
      }  
    }

  clearCongruence() {
    this.reportCongruence = null;
    this.$scope.reportForm.$setPristine(); 
    this.$scope.reportForm.$setUntouched();
  }

  loadCongruence() {
    var self = this;
    this.study.loadReportCongruence().then(x => {            
      self.reportCongruence = x;
      if (x) { self.isReadOnlyCongruence = true }
      else { 
        self.isReadOnlyCongruence = false;
        if (self.study.technician != null && self.models.User.current.technician != null && self.study.technician.id === self.models.User.current.technician.id) {
          self.showCongruenceSection = false;
        }      
      };
    });
  }

  saveCongruence() {
    if (this.reportCongruence && this.$scope.reportForm.$valid) 
      this.study.addReportCongruence(
        this.reportCongruence.variation.id, this.reportCongruence.comment)
        .then(() => {
          this.clearCongruence()
          this.loadCongruence();          
        });
  }
}

export default module("midas.admin.reportCongruence", [])
  .component("reportCongruence", {
    controller: ReportsCongruenceController,
    templateUrl: require('./reportCongruence.html'),
    bindings: { study: '<' }
});