import { MeasurementController, IMeasurementScope } from "../MeasurementController";
import * as angular from "angular";
class DXLumbarSpineController extends MeasurementController {
  static $inject = ["$scope"];
  constructor(scope: IMeasurementScope) {
    super(scope);
  }

  clickOK() {
    this.setMeasurementValue("DXLumbarSpineRtVertebralBodies", "Normal in height. No vertebral fracture. ");
    this.setMeasurementValue("DXLumbarSpineRtDiskSpaces", "Normal. ");
    this.setMeasurementValue("DXLumbarSpineRtSoftTissue", "Normal. ");
    this.setMeasurementValue("DXLumbarSpineRtAlignment", "Normal. No scoliosis or kyphosis. ");
    this.setMeasurementValue("DXLumbarSpineConclusion", "Normal examination. ");
    console.log("All normal clicked")
  }

}

angular.module("midas.utility.measurement.views").controller("DXLumbarSpineCtrl", DXLumbarSpineController);
