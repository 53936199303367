import { element as $, module as ngModule, ILogService } from "angular";
import widgetsModule, {
  serviceName as widgetsServiceName,
  WidgetsService
} from '../../reportFormatter/widgets/widgets.service';

export const serviceName = "isEditableSelection";

/** A node filter which selects only significant whitespace (not purely whitespace), or <br>s. */
const filterToTextAndBrs = <NodeFilter> {
  acceptNode(n) {
    if ((n.nodeType === 3 && !isCollapsibleWs(<Text>n)) || (<Element>n).tagName === "BR") {
      return NodeFilter.FILTER_ACCEPT;
    } else {
      return NodeFilter.FILTER_SKIP;
    }
  }
};

  /** Tests whether a text node contains only whitespace that is collapsible in the DOM. This allows
   * us to skip whitespace that the browser doesn't display, such as whitespace between nodes. */
function isCollapsibleWs(node: Text) {
  return !(/[^\t\n\r ]/.test(node.textContent));
}

/** A service for determining whether a range or selection is editable according to the blueprint
 * widgets. */
export class EditableService {

  static $inject = ["$log", widgetsServiceName];
  constructor(
    private readonly $log: ILogService,
    private readonly widgets: WidgetsService)
    { }

  /** Checks whether a range can be edited. For a collapsed range, this determines whether the
   * cursor is currently within an editable node. For a non-collapsed selection, it ensures the
   * start and end are editable. Anything in between should be safe to destroy.
   * @param range The selection range to check. The range may be collapsed to just position. */
  public canEdit(range: Range) {
    const startContainer = range.startContainer;
    if (range.collapsed) {
      const start = $(startContainer);
      return this.widgets.isEditable(start);
    } else {
      const start = $(startContainer);
      const end = $(range.endContainer);
      return this.widgets.isEditable(start) && this.widgets.isEditable(end);
    }
  }

  /** Checks whether a node can be edited. */
  public canEditNode(node: Node | JQuery) {
    if (node) {
      const start = $(node);
      return this.widgets.isEditable(start);
    }
    return false;
  }

  /** Checks whether it's safe to delete the current selection, or the next character if the
   * selection is collapsed.
   * @param range The selection range to check. The range may be collapsed to just position.
   * @param root Provides an optional container for the edit range. Searches through the DOM will
   * not ascend past this node. */
  public canDeleteForward(range: Range, root?: Node) {
    if (range.collapsed) {
      const container = range.startContainer;
      if (container.nodeType !== 3 || range.startOffset === (<Text>container).length) {
        return this.widgets.isEditable($(this.findNext(container, root)));
      }
    }

    // When delete is pressed with a non-collapsed selection, the selection is deleted rather than
    // deleting the next character.
    return this.canEdit(range);
  }

  /** Checks whether it's safe to delete the current selection, or the previous character if the
   * selection is collapsed.
   * @param range The selection range to check. The range may be collapsed to just position.
   * @param root Provides an optional container for the edit range. Searches through the DOM will
   * not ascend past this node. */
  public canDeleteBackward(range: Range, root?: Node) {
    if (range.collapsed) {
      const container = range.startContainer;
      if (container.nodeType !== 3 || range.startOffset === 0) {
        return this.widgets.isEditable($(this.findPrev(<Text> container, root)));
      }
    }

    // When backspace is pressed with a non-collapsed selection, the selection is deleted rather
    // than deleting the previous character.
    return this.canEdit(range);
  }

  /** If the provided range is within a widget it is updated to a position after the widget that
   * can be edited. This may involve inserting an empty text node which can be selected, if there
   * is no appropriate node immediately after. This assumes that widgets cannot be inserted within
   * other widgets (although inside the content part of a conditional widget is OK). */
  selectNextEditPoint(selection: Range): void {
    // Clone so that changes here don't change the editor unless we assign them back.
    const end = selection.endContainer;

    const widget = this.widgets.closest($(end));

    if (widget == null) {
      return;
    } else {
      const widgetNode = widget.$element[0];
      const parent = widgetNode.parentNode;
      if (parent == null) {
        this.$log.error("Cannot move insertion point for a detached widget.");
        return;
      }

      // I tried avoiding creating a new text node if there's already one there, but it freaks out
      // in outline mode and either inserts in the wrong place, or does something else stupid.
      // This seems to work in all cases. We'll likely need a "cleanup" pass later anyway.
      const select = document.createTextNode("");
      parent.insertBefore(select, widgetNode.nextSibling);

      if (select != null) {
        selection.setStart(select, 0);
        selection.collapse(true);
      }
    }
  }

  /** If the provided range is within a widget it is updated to a position before the widget that
   * can be edited. This may involve inserting an empty text node which can be selected, if there
   * is no appropriate node immediately before. This assumes that widgets cannot be inserted within
   * other widgets (although inside the content part of a conditional widget is OK). */
  selectPrevEditPoint(selection: Range): void {
    const start = selection.startContainer;

    const widget = this.widgets.closest($(start));

    if (widget == null) {
      return;
    } else {
      const widgetNode = widget.$element[0];
      const parent = widgetNode.parentNode;
      if (parent == null) {
        this.$log.error("Cannot move insertion point for a detached widget.");
        return;
      }

      // I tried avoiding creating a new text node if there's already one there, but it freaks out
      // in outline mode and either inserts in the wrong place, or does something else stupid.
      // This seems to work in all cases. We'll likely need a "cleanup" pass later anyway.
      const select = document.createTextNode("");
      parent.insertBefore(select, widgetNode);

      if (select != null) {
        selection.setStart(select, select.length);
        selection.collapse(true);
      }
    }
  }

  /** Finds the next possibly editable node such as a significant text node, or a <br>.
   * Does not actually check for editability, just finds the next node of an editable type.
   * @param node The node to search from. It is not checked, but all nodes afterwards (in document
   * order) are.
   * @param root If provided, the search will stop when it ascends to this node.
   * @returns The next significant editable node, or null if none was found. */
  findNext(node: Node, root?: Node): Text | HTMLBRElement {
    while (node != null && node !== root) {
      while (node.nextSibling != null) {
        node = node.nextSibling;
        switch(node.nodeType) {
          case 1: // ELEMENT -> get first child text node or <br>
            const childText = <Text | HTMLBRElement> document.createTreeWalker(node,
              NodeFilter.SHOW_TEXT | NodeFilter.SHOW_ELEMENT,
              filterToTextAndBrs).nextNode();
            if (childText != null) {
              return childText;
            }
            break;
          case 3: // TEXT
            if (!isCollapsibleWs(<Text> node)) {
              return <Text> node;
            }
        }
      }
      node = node.parentElement;
    }

    return null;
  }

  /** Finds the previous possibly editable node such as a significant text node, or a <br>.
   * Does not actually check for editability, just finds the next node of an editable type.
   * @param node The node to search from. It is not checked, but all nodes before it (in document
   * order) are.
   * @param root If provided, the search will stop when it ascends to this node.
   * @returns The previous significant editable node, or null if none was found. */
  findPrev(node: Node, root?: Node): Text | HTMLBRElement {
    while (node != null && node !== root) {
        while (node.previousSibling != null) {
            node = node.previousSibling;
            switch(node.nodeType) {
                case 1: // ELEMENT -> get previous child text node or <br> (last of previous sibling)
                    const childText = <Text | HTMLBRElement> document.createTreeWalker(
                        node,
                        NodeFilter.SHOW_TEXT | NodeFilter.SHOW_ELEMENT,
                        filterToTextAndBrs).lastChild();
                    if (childText != null) {
                        return childText;
                    }
                    break;
                case 3: // TEXT
                    if (!isCollapsibleWs(<Text> node)) {
                        return <Text> node;
                    }
            }
        }
        node = node.parentElement;
    }

    return null;
  }
}

export default ngModule("midas.utility.tinymce.is-editable", [widgetsModule.name])
  .service(serviceName, EditableService);