import { IScope, IControllerConstructor } from 'angular';
import { Study } from "../../businessModels";
import { IStudyTitle } from './StudyTitle';
import { GetPdfViewerUrl } from "../../pdfjs.module";
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */

export default [
  "$scope",  "study", "title", "getPdfViewerUrl",
  <IControllerConstructor>function($scope: IScope, study: Study, title: IStudyTitle,
    getPdfViewerUrl: GetPdfViewerUrl) {

    title.text = "Preview Report";
    title.clearButtons();
    title.backButton.show = false;
    title.acceptButton.disable();
    const viewUrl = getPdfViewerUrl(`api/midas/reportpreview/${study.id}`);
    return $scope.getUrl = () => viewUrl;
  }
];
