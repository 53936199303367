import { MeasurementController, IMeasurementScope } from "../MeasurementController";
import * as angular from "angular";

export class LungController extends MeasurementController {
    static $inject = ["$scope"];

    constructor(scope: IMeasurementScope) {
        super(scope);
    }

    setValue() : void {
        this.setMeasurementValue("AKey", "A value");
    }
};

angular.module("midas.utility.measurement.views").controller("LungCtrl", LungController);