/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import * as angular from "angular";
angular.module("midas.utility.measurement.views").controller("EFASTCtrl", ["$scope", function($scope) {
 const { values } = $scope.measurements;
 const setAllNormal = function()  {
   values.POCEFASTRUQFindings.value = "Normal";
   values.POCEFASTRtHaemothoraxFindings.value = "Normal";
   values.POCEFASTLUQFindings.value = "Normal";
   values.POCEFASTLtHaemothoraxFindings.value = "Normal";
   values.POCEFASTSubcostalFindings.value = "Normal";
   values.POCEFASTPelvisFindings.value = "Normal";
   values.POCEFASTRtLungPneumothoraxFindings.value = "Normal";
   values.POCEFASTLtLungPneumothoraxFindings.value = "Normal";
   console.log("All Normal");
 };
 $scope.clickAllNormal = () => setAllNormal();
}
]);
// CoffeeScript
