/** An axis aligned rectangle. */
import { IPointLike } from "./vector2";

export class Rect {
  constructor(
    public x: number = 0,
    public y: number = 0,
    public width: number = 0,
    public height: number = 0) {
  }
  
  /** Gets this.x + this.width. */
  get xMax() { return this.x + this.width; }
  /** Sets the width so that this.width + this.x = the provided value. */
  set xMax(value: number) { this.width = value - this.x; }
  
  /** Gets this.y + this.height. */
  get yMax() { return this.y + this.height; }
  /** Sets the height so that this.height + this.y = the provided value. */
  set yMax(value: number) { this.height = value - this.y; }
  
  /** Gets the area of the rectangle */
  get area() { return this.width * this.height; }
  
  /** Gets the point at the middle of this rectangle. */
  get middle(): IPointLike {
    return { x: this.x + (this.width / 2), y: this.y + (this.height / 2)  };
  }
  
  /** Sets the size of this rectangle in both dimensions at once.
  * @param p The new size of the rectangle, in both x and y dimensions. */
  setSize(p: IPointLike): this {
    this.width = p.x;
    this.height = p.y;
    return this;
  }
  
  /** Sets the location of the top left corner of this rectangle.
  * @param p The new top-left corner of the rectangle. */
  setLocation(p: IPointLike): this {
    this.x = p.x;
    this.y = p.y;
    return this;
  }
  
  /** Gets the cartesian length of the rectangle from one corner to the diagonally opposite
  * corner. */
  getDiagonalLength() {
    const w = this.width;
    const h = this.height;
    return Math.sqrt(w * w + h * h);
  }
  
  /** Moves this rectangle by the provided amount, without changing it's size. */
  translate(p: IPointLike): this {
    this.x += p.x;
    this.y += p.y;
    return this;
  }
  
  /** Expands the rect evenly in all directions by the provided amount.
  * @param amount The amount to move each edge by. */
  expand(amount: number): this {
    this.x -= amount;
    this.y -= amount;
    this.width += 2 * amount;
    this.height += 2 * amount;
    return this;
  }
  
  /** Gets whether this rectangle contains a point. A point exactly on the
  * edge is considered contained.
  * @param p The point to test against this rectangle. */
  contains(p: IPointLike) {
    return this.x <= p.x && (this.x + this.width) >= p.x
    && this.y <= p.y && (this.y + this.height) >= p.y;
  }
  
  /** Get a minimal bounding rectangle for a number of points.
  * @param points The points to calculate a bounding rectangle for.
  */
  static boundingRect(points: IPointLike[]) {
    if (points.length === 0) {
      return new Rect();
    }
    const first = points[0];
    let minX = first.x, maxX = first.x,
    minY = first.y, maxY = first.y;
    for (let i = 1, len = points.length; i < len; ++i) {
      const p = points[i];
      if (p.x < minX) {
        minX = p.x;
      } else if (p.x > maxX) {
        maxX = p.x;
      }
      if (p.y < minY) {
        minY = p.y;
      } else if (p.y > maxY) {
        maxY = p.y;
      }
    }
    return new Rect(minX, minY, maxX - minX, maxY - minY);
  }
  
  /** Creates an exact copy of this rectangle. */
  clone(): Rect {
    return new Rect(this.x, this.y, this.width, this.height);
  }
};

export default Rect;