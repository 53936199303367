import * as angular from "angular";

interface IReportPhysician {
  studyId: number;
  isPhysicianConfirmed: boolean;
}

export default class ReportService {
    
  physicianConfirmed = new Array<IReportPhysician>();
  
  static $inject = ["$rootScope"];
  constructor( private readonly $rootScope: angular.IRootScopeService ) {  
    var self = this;
    this.$rootScope.$on("physicianConfirmed", function(_e, args) { 
      let items = self.physicianConfirmed.filter(x => x.studyId === args.studyId);
      if (items.length > 0) items[0].isPhysicianConfirmed = true;
      else self.physicianConfirmed.push({studyId: args.studyId, isPhysicianConfirmed: true} as IReportPhysician);
    });
  }

  getPhysicianConfirmed = (studyId: number): boolean => {
    let items = this.physicianConfirmed.filter(x => x.studyId === studyId);
    if (items.length > 0) return items[0].isPhysicianConfirmed;
    else return false;
  };  
}

