import { module as ngModule } from "angular";
import { Editor } from "tinymce";
import reportEditorModule, {
  TinymceConfigurationController,
  tinymceConfigurationRequire
} from "../mds-rich-text-editor.component";
import isEditableModule, {
  serviceName as isEditableServiceName,
  EditableService
} from './editable.service';

/** A TinyMCE plugin which prevents the user from destroying widgets while typing or editing text
 * in the editor. */
class ProtectWidgetsPluginController extends TinymceConfigurationController {

  static $inject = [isEditableServiceName];
  constructor(
    private readonly editable: EditableService) {
    super();
  }

  /** Define condition insert button and handle click. */
  setup(editor: Editor) {
    // Key down handles any kind of key event, including DEL and Backspace keys
    editor.on("keydown", (e: KeyboardEvent) => !this.onKeyDownPreventDeletion(editor, e));
    // Key press events are specifically for keys which produce a character into the input.
    editor.on("keypress", (e: KeyboardEvent) => !this.onKeyPressPreventDeletion(editor, e));
    editor.on("paste", (e: Event) => !this.onPastePreventDeletion(editor, e));
  }

  /**
   * A key press event occurs when the user enters a character into the editor,
   * as distinguishable from the "KeyDown" event which handles all keyboard events.
   * @param editor The tinymce editor.
   * @param e The KeyPress event.
   * @returns a flag indicating if the keyboard event was prevented.
   */
  private onKeyPressPreventDeletion(editor, e: KeyboardEvent): boolean {
    if (!this.editable.canEdit(editor.selection.getRng(true))) {
      e.preventDefault();
      return true;
    }
    return false;
  }

  /**
   * A KeyDown event occurs when any keyboard button is pressed. This is used to capture
   * non-character keyboard keys such as delete or backspace.
   * The "onKeyPressPreventDeletion" function handles the typing of any character based key presses.
   * @param editor The tinymce editor.
   * @param e the KeyDown event.
   * @returns a flag indicating if the keyboard event was prevented.
   */
  private onKeyDownPreventDeletion(editor, e: KeyboardEvent): boolean {
    let requirePrevention = false;
    switch (e.keyCode) {
      case 13: // enter/return key
        requirePrevention = !this.editable.canEdit(editor.selection.getRng(true));
        break;
      case 8: // backspace
        requirePrevention = !this.editable.canDeleteBackward(editor.selection.getRng(true));
        break;
      case 46:// delete
        requirePrevention = !this.editable.canDeleteForward(editor.selection.getRng(true));
        break;
    }
    if (requirePrevention) {
      e.preventDefault();
      return true;
    }
    return false;
  }

  /**
   * Capture a text paste event and determine if it should be prevented.
   * This will cover CTRL+V and any other context menu and toolbar methods for pasting text.
   * @param editor The tinymce editor.
   * @param e the Paste event.
   * @returns a flag indicating if the keyboard event was prevented.
   */
  private onPastePreventDeletion(editor: Editor, e: Event): boolean {
    if (!this.editable.canEdit(editor.selection.getRng(true))) {
      e.preventDefault();
      return true;
    }
    return false;
  }
}

export default ngModule("midas.utility.tinymce.disableEdit", [
  reportEditorModule.name,
  isEditableModule.name
]).component("protectWidgets", {
  require: {
    ...tinymceConfigurationRequire
  },
  controller: ProtectWidgetsPluginController
});