
import { MeasurementController, IMeasurementScope } from "../MeasurementController";
import * as angular from "angular";

class RMHTOEController extends MeasurementController {
    private readonly preChamberKeys = ["STPreApicalAnt", "STPreMidInf", "STPreMidAnt", "STPreBasalInf",
        "STPreBasalAnt", "STPreApicalInf", "STPreApicalSept", "STPreMidInfLat", "STPreMidAntSept",
        "STPreBasalInfLat", "STPreBasalAntSept", "STPreApicalLat", "STPreMidInfSept",
        "STPreMidAntLat", "STPreBasalInfSept", "STPreBasalAntLat"];

    static $inject = ["$scope"];
    constructor(scope: IMeasurementScope) {
        super(scope);
        this.setDefaultStudyType();
        this.watch();
    }

    setDefaultStudyType(): void {
        if (!this.getMeasurementValue("ECDEMStudyType")) {
            this.setMeasurementValue("ECDEMStudyType", "Transoesophageal Echo");
        }
    }

    watch(): void {
        this.watchMeasurement("ECDEMWeight", () => { this.recalcBSA(); this.recalcBMI() });
        this.watchMeasurement("ECDEMHeight", () => { this.recalcBSA(); this.recalcBMI() });
        this.watchMeasurement("ECDEMBSA", () => this.recalcIndexes());
        this.watchMeasurement("ECLVIVSeptum", () => this.recalcRWT());
        this.watchMeasurement("ECLVPostWall", () => this.recalcRWT());
        this.watchMeasurement("ECLVPostWallM", () => this.recalcRWT());
        this.watchMeasurement("ECLVDiastole", () => this.recalcRWT());
        this.watchMeasurement("ECLVDiastoleM", () => this.recalcRWT());
        this.recalcIndexes();

        // recalculate M-Mode
        this.watchMeasurement("ECLVDiastoleM", () => this.recalcLvMassMMode());
        this.watchMeasurement("ECLVPostWallM", () => this.recalcLvMassMMode());
        this.watchMeasurement("ECLVIVSeptumM", () => this.recalcLvMassMMode());
        // recalculate 2d
        this.watchMeasurement("ECLVDiastole", () => this.recalcLvMass2d());
        this.watchMeasurement("ECLVPostWall", () => this.recalcLvMass2d());
        this.watchMeasurement("ECLVIVSeptum", () => this.recalcLvMass2d());

        // Individual indexed values
        this.watchMeasurement("ECLVDiastole", () => this.recalcIndexed("ECLVDiastole", "ECLVDiastole2DIndex"));
        this.watchMeasurement("ECLVDiastoleM", () => this.recalcIndexed("ECLVDiastoleM", "ECLVDiastoleIndex"));
        this.watchMeasurement("ECLVSystole", () => this.recalcIndexed("ECLVSystole", "ECLVSystoleIndexBSA"));
        this.watchMeasurement("ECLVSystoleM", () => this.recalcIndexed("ECLVSystoleM", "ECLVSystoleMIndexBSA"));
        this.watchMeasurement("ECLVEDV4C", () => this.recalcIndexed("ECLVEDV4C", "ECLVEDV4CIndexBSA"));
        this.watchMeasurement("ECLVESV4C", () => this.recalcIndexed("ECLVESV4C", "ECLVESV4CIndexBSA"));
        this.watchMeasurement("ECLVEDV2C", () => this.recalcIndexed("ECLVEDV2C", "ECLVEDV2CIdxBSA"));
        this.watchMeasurement("ECLVESV2C", () => this.recalcIndexed("ECLVESV2C", "ECLVESVIndexBSA"));
        this.watchMeasurement("ECLVEDVBiplane", () => this.recalcIndexed("ECLVEDVBiplane", "ECLVEDVBiplaneIdxBSA"));
        this.watchMeasurement("ECLVESVBiplane", () => this.recalcIndexed("ECLVESVBiplane", "ECLVESVBiplaneIndexBSA"));
        this.watchMeasurement("ECATLAPLAX", () => this.recalcIndexed("ECATLAPLAX", "ECATLAIndexPLAXBSA"));
        this.watchMeasurement("ECATLAVolMod2C", () => this.recalcIndexed("ECATLAVolMod2C", "ECATLAVolMod2CBSA"));
        this.watchMeasurement("ECATRAVolMod4C", () => this.recalcIndexed("ECATRAVolMod4C", "ECATRAVolMod4CIdx"));
        this.watchMeasurement("ECASAorticRoot", () => this.recalcIndexed("ECASAorticRoot", "ECLVDiastoleIndex"));
        this.watchMeasurement("ECASAorticRootM", () => this.recalcIndexed("ECASAorticRootM", "ECASAorticRootMIndex"));
        this.watchMeasurement("ECATLAVolMod4C", () => this.recalcIndexed("ECATLAVolMod4C", "ECATLAVolMod4CIdx"));
        this.watchMeasurement("ECATLAVolMod2C", () => this.recalcIndexed("ECATLAVolMod2C", "ECATLAVolMod2CBSA"));
        this.watchMeasurement("ECATRAVolMod4C", () => this.recalcIndexed("ECATRAVolMod4C", "ECATRAVolMod4CIdx"));
        this.watchMeasurement("ECASAorticRoot", () => this.recalcIndexed("ECASAorticRoot", "ECASAorticRootIndex"));
        this.watchMeasurement("ECASAorticRootM", () => this.recalcIndexed("ECASAorticRoot", "ECASAorticRootMIndex"));
        this.watchMeasurement("ECASAAscRPA", () => this.recalcIndexed("ECASAAscRPA", "ECASAortaAscIdx"));

        // Recalculate PASP / RSVP if TriPG changes
        this.watchMeasurement("ECRVTriRegurgPeakGradient", () => this.recalcPASP());
        this.watchMeasurement("ECRVRAPressure", () => this.recalcPASP());
    }


    recalcLvMassMMode(): void {
        this.recalcLvMass("ECLVMass", "ECLVDiastoleM", "ECLVPostWallM", "ECLVIVSeptumM");
        this.recalcIndexed("ECLVMass", "ECLVMassIndexBSA");
    }

    recalcLvMass2d(): void {
        this.recalcLvMass("ECLVMass2D", "ECLVDiastole", "ECLVPostWall", "ECLVIVSeptum");
        this.recalcIndexed("ECLVMass2D", "ECLVMassIndexBSA2D");
    }

    recalcLvMass(lvMassKey: string, lvDiastoleKey: string, lvPostWallKey: string, lvIvSeptumKey: string): void {
        console.log("recalc lv mass", lvMassKey);
        const lvDiastole: number = Number(this.getMeasurementValue(lvDiastoleKey));
        const lvPostWall: number = Number(this.getMeasurementValue(lvPostWallKey));
        const lvIvSeptum: number = Number(this.getMeasurementValue(lvIvSeptumKey));
        if (isNaN(lvDiastole) || isNaN(lvPostWall) || isNaN(lvIvSeptum)) {
            return;
        }
        this.setMeasurementValue(lvMassKey,
            0.8 * (1.04 * (Math.pow(lvDiastole + lvPostWall + lvIvSeptum, 3) - Math.pow(lvDiastole, 3))) + 0.6);
    }

    recalcPASP(): void {
        const trPG: number = Number(this.getMeasurementValue("ECRVTriRegurgPeakGradient"));
        const raPressure: number = Number(this.getMeasurementValue("ECRVRAPressure"));
        console.log("recalc PASP", trPG, raPressure);
        if (isNaN(trPG) || isNaN(raPressure)) { return; }
        this.setMeasurementValue("ECAMPASP", trPG + raPressure);
        this.setMeasurementValue("ECRVRVSP", trPG + raPressure);
    }

    setupDefaultEntries(): void {
        this.setMeasurementValue("ECMDMitralValveCondition", "Normal leaflets with normal motion");
        this.setMeasurementValue("ECMDMRSeverity", "trivial");
        this.setMeasurementValue("ECATInteratrialSeptum", "Appears intact");
        this.setMeasurementValue("ECRVFunction", "Normal systolic function");
        this.setMeasurementValue("ECRVSize", "normal size");
        this.setMeasurementValue("ECRVIVCCondition", "Normal IVC with normal collapse");
        this.setMeasurementValue("ECRVTricuspidValve", "Normal leaflet structure with normal motion");
        this.setMeasurementValue("ECRVTRSeverity", "trivial");
        this.setMeasurementValue("ECADAorticValveCondition", "trileaflet with normal leaflets and normal motion");
        this.setMeasurementValue("ECARARSeverity", "trivial");
        this.setMeasurementValue("ECAMPericardium", "No echo free space visualised");
        this.setMeasurementValue("ECPDPulmonaryValve", "Normal");
        this.setMeasurementValue("ECPDPRseverity", "trivial");
        this.setMeasurementValue("TTEDefault", "Patient all normal");
    }

    preAllNormal(): void {
        this.setPreValues("1");
    }

    preClear(): void {
        this.setPreValues(null);
    }

    private setPreValues(value: string): void {
        for (var key of this.preChamberKeys) {
            this.setMeasurementValue(key, value);
        }
    }
    recalcRWT(): void {
        const IVS: number = Number(this.getMeasurementValue("ECLVIVSeptum"));
        const PostWall2D: number = Number(this.getMeasurementValue("ECLVPostWall"));
        const PostWallM: number = Number(this.getMeasurementValue("ECLVPostWallM"));
        const LVDiameter2D: number = Number(this.getMeasurementValue("ECLVDiastole"));
        const LVDiameterM: number = Number(this.getMeasurementValue("ECLVDiastoleM"));
        if (isNaN(IVS)) {
            return;
        }
        if (isNaN(PostWall2D) && isNaN(PostWallM)) {
            return;
        }
        if (isNaN(LVDiameter2D) && isNaN(LVDiameterM)) {
            return;
        }
        // use 2d value or if not present use M value
        let Diameter: number = isNaN(LVDiameter2D) ? LVDiameterM : LVDiameter2D;
        let PostWall: number = isNaN(PostWall2D) ? PostWallM : PostWall2D;
        // let RWT = Math.round((IVS + PostWall) / Diameter *100)/100;
        let RWT: number = (IVS + PostWall) / Diameter;
        this.setMeasurementValue("ECLVRelativeWallThickness", RWT);
    }

    recalcBMI(): void {
        const weight: number = Number(this.getMeasurementValue("ECDEMWeight"));
        const height: number = Number(this.getMeasurementValue("ECDEMHeight"));
        if (isNaN(weight) || isNaN(height)) {
            return;
        }
        let bmi: number = weight / ((height / 100) * (height / 100));
        bmi = this.round(bmi, 2);
        this.setMeasurementValue("ECDEMBMI", bmi);
    }

    recalcBSA(): void {
        const weight: number = Number(this.getMeasurementValue("ECDEMWeight"));
        const height: number = Number(this.getMeasurementValue("ECDEMHeight"));
        if (isNaN(weight) || isNaN(height)) {
            return;
        }
        let heightMetres: number = height / 100;
        let bsa: number = 0.20247 * Math.pow(heightMetres, 0.725) * Math.pow(weight, 0.425);
        bsa = this.round(bsa, 2);
        this.setMeasurementValue("ECDEMBSA", bsa);
    }

    recalcIndexes(): void {
        this.recalcIndexed("ECLVDiastole", "ECLVDiastole2DIndex");
        this.recalcIndexed("ECLVDiastoleM", "ECLVDiastoleIndex");
        this.recalcIndexed("ECLVSystole", "ECLVSystoleIndexBSA");
        this.recalcIndexed("ECLVSystoleM", "ECLVSystoleMIndexBSA");
        this.recalcIndexed("ECLVMass2D", "ECLVMassIndexBSA2D");
        this.recalcIndexed("ECLVMass", "ECLVMassIndexBSA");
        this.recalcIndexed("ECLVEDV4C", "ECLVEDV4CIndexBSA");
        this.recalcIndexed("ECLVESV4C", "ECLVESV4CIndexBSA");
        this.recalcIndexed("ECLVEDV2C", "ECLVEDV2CIndexBSA");
        this.recalcIndexed("ECLVESV2C", "ECLVESV2CIndexBSA");
        this.recalcIndexed("ECLVEDVBiplane", "ECLVEDVBiplaneIdxBSA");
        this.recalcIndexed("ECLVESVBiplane", "ECLVESVBiplaneIdxBSA");
        this.recalcIndexed("ECATLAPLAX", "ECATLAIndexPLAXBSA");
        this.recalcIndexed("ECATLAVolMod2C", "ECATLAVolMod2CBSA");
        this.recalcIndexed("ECATRAVolMod4C", "ECATRAVolMod4CIdx");
        this.recalcIndexed("ECASAorticRoot", "ECASAorticRootIndex");
        this.recalcIndexed("ECASAorticRootM", "ECASAorticRootMIndex");
        this.recalcIndexed("ECASAAscRPA", "ECASAortaAscIdx");
        //
    }

    recalcIndexed(rawKey: string, indexedKey: string): void {
        const rawValue: number = Number(this.getMeasurementValue(rawKey));
        const bsa: number = Number(this.getMeasurementValue("ECDEMBSA"));
        console.log("recalc", rawKey, indexedKey, rawValue, bsa);
        if (isNaN(rawValue) || isNaN(bsa)) {
            return;
        }
        let valueIndexed: number = rawValue / bsa;
        valueIndexed = this.round(valueIndexed, 2);
        this.setMeasurementValue(indexedKey, valueIndexed);
    }
}

angular.module("midas.utility.measurement.views").controller("RMHTOECtrl", RMHTOEController);