import { MeasurementController, IMeasurementScope } from "../MeasurementController";
import * as angular from "angular";
class DXKneeController extends MeasurementController {
  static $inject = ["$scope"];
  constructor(scope: IMeasurementScope) {
    super(scope);
  }

  clickRightOK() {
    this.setMeasurementValue("DXKneeRtBones", "No fracture or dislocation is present. ");
    this.setMeasurementValue("DXKneeRtSoftTissue", "Normal. ");
    this.setMeasurementValue("DXKneeRtJoints", "The joint spaces are normal. ");
    this.setMeasurementValue("DXKneeRtOtherComment", "None. ");
    console.log("Right All normal clicked")
  }

  clickLeftOK() {
    this.setMeasurementValue("DXKneeLtBones", "No fracture or dislocation is present. ");
    this.setMeasurementValue("DXKneeLtSoftTissue", "Normal. ");
    this.setMeasurementValue("DXKneeLtJoints", "The joint spaces are normal. ");
    this.setMeasurementValue("DXKneeLtOtherComment", "None. ");
    console.log("Left All normal clicked")
  }
}

angular.module("midas.utility.measurement.views").controller("DXKneeCtrl", DXKneeController);
