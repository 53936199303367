/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import * as angular from "angular";
angular.module("midas.utility.measurement.views").controller("STDPediatricEchoCtrl", ["$scope", function($scope) {
 console.log("hi");
 $scope.findings =
  {open: true};
 const { values } = $scope.measurements;
 if (!values.ECDEMStudyType.value) {
   values.ECDEMStudyType.value = "Transthoracic Echo";
 }

 const canCalc = () => values.ECDEMWeight.value && values.ECDEMHeight.value;
      
 const recalcBMI = function() { 
   if (!canCalc()) { return values.ECDEMBMI.value = null;
   } else { return values.ECDEMBMI.value = values.ECDEMWeight.value/ ((values.ECDEMHeight.value/100)*(values.ECDEMHeight.value/100)); }
 };
   
 const recalcBSA = function() { 
   if (!canCalc()) { return values.ECDEMBSA.value = null;
   } else { return values.ECDEMBSA.value = Math.sqrt((values.ECDEMWeight.value * values.ECDEMHeight.value) / 3600); }
 };
 
 const recalcHaycock = function() {
  if (!canCalc()) { return values.ECDEMBSAHaycock.value = null;
  } else { return values.ECDEMBSAHaycock.value = 0.024265 * Math.pow(values.ECDEMHeight.value, 0.3964) * Math.pow(values.ECDEMWeight.value, 0.5378); }
};
 
 const recalcAll = function() {
   recalcBMI();
   recalcBSA();
   return recalcHaycock();
 };
 
 $scope.$watch("measurements.values.ECDEMHeight.value", recalcAll);
 $scope.$watch("measurements.values.ECDEMWeight.value", recalcAll);
 
 if (!values.ECDEMStudyType.value) {
   values.ECDEMStudyType.value = "Transthoracic Echo";
 }
    
 $scope.clickMe = function() {
   values.PCSitusComment.value = "Usual atrial arrangement";
   values.PCSystemicVeinsComment.value = "Normal systemic venous connections";
   values.PCRASize.value = "Normal";
   values.PCLASize.value = "Normal";
   values.PCIASComment.value = "Intact atrial septum";
   values.PCAVConnections.value = "Atrioventricular concordance";
   values.PCMitralValveDescription.value = "Normal mitral valve";
   values.PCMitralValveRegurgitation.value = "no";
   values.PCTricuspidValveDescription.value = "Normal tricuspid valve";
   values.PCTricuspidValveRegurgitation.value = "no";
   console.log(values.PCVentricleSizeSeverity.value);
   values.PCVentricleSizeSeverity.value = "Normal";
   console.log("Size Severity ", values.PCVentricleSizeSeverity.value);
   values.PCVentricleFunctionSeverity.value = "Normal";
   values.PCIVS.value = "Intact ventricular septum";
   values.PCVAConnections.value = "Ventriculoarterial concordance";
   values.PCOutletsComment.value = "Unobstructed outflow tracts";
   values.PCPulmonaryArteryComment2.value = "Normal branch pulmonary arteries";
   values.PCAorticValve.value = "Trileaflet aortic valve";
   values.PCPulmonaryValve.value = "Trileaflet pulmonary valve";
   values.PCLeftAorticArchComment1.value = "Unobstructed left sided aortic arch";
   values.PCLeftAorticArchComment2.value = "with normal branching";
   values.PCPDASize.value = "No";
   values.PCPulmonaryVeinComment1.value = "Normal pulmonary venous connection";
   values.PCCoronaryArteriesComment.value = "Normal proximal coronary origins";
   console.log("Button clicked"); 
 };
   
 const addZScoreWatch = function(calc) {
  const recalculate = function() {
    if (!calc.canCalculate(values)) { return; }
    const value = calc.calculate(values).toFixed(2);
    console.log(`calculated ${calc.outputKey}: ${value}`);
    return values[calc.outputKey].value = value;
  };
   
  for (let key of calc.requiredKeys) { $scope.$watch(`measurements.values.${key}.value`, recalculate); }
  if (calc.ageRanges.length !== 0) { return $scope.$watch("measurements.values.ECDEMReportAge.value", recalculate); }
};
      
 //Create calculation class: new ZScoreAgeRangeCalculation "Name of measurement to calculate", ["Required measurements"], (mmts, range) -> Perform calculation here
 const ECLVHRCalc = new ZScoreAgeRangeCalculation("ECLVHRZScore", ["ECLVHR"], function(mmts, range) { return (mmts.ECLVHR.value - range.ave) / range.std; });
 //Add age ranges, if required  .addRange min, max, average, standard deviation
 ECLVHRCalc.addRange(0,   1,  124, 16.0); 
 ECLVHRCalc.addRange(1,   6,  105, 17.0); 
 ECLVHRCalc.addRange(6,   10, 80, 11.0);
 ECLVHRCalc.addRange(10,  14, 75, 12.0);
 ECLVHRCalc.addRange(14,  19, 69, 16.0); 
 //Hook up watch of dependant measurement changes
 addZScoreWatch(ECLVHRCalc);
 
 //Create calculation class: new ZScoreAgeRangeCalculation "Name of measurement to calculate", ["Required measurements"], (mmts, range) -> Perform calculation here
 const ECMDDecelerationCalc = new ZScoreAgeRangeCalculation("ECMDDecelerationZScore", ["ECMDDeceleration"], function(mmts, range) { return (mmts.ECMDDeceleration.value - range.ave) / range.std; });
 //Add age ranges, if required  .addRange min, max, average, standard deviation
 ECMDDecelerationCalc.addRange(3,   9,  145, 18); 
 ECMDDecelerationCalc.addRange(9,   13, 157, 19);
 ECMDDecelerationCalc.addRange(13,  19, 172, 22); 
 //Hook up watch of dependant measurement changes
 addZScoreWatch(ECMDDecelerationCalc);
 
 //Create calculation class: new ZScoreAgeRangeCalculation "Name of measurement to calculate", ["Required measurements"], (mmts, range) -> Perform calculation here
 const ECMDEVelocityCalc = new ZScoreAgeRangeCalculation("ECMDEVelocityZScore", ["ECMDEVelocity"], function(mmts, range) { return ((mmts.ECMDEVelocity.value*100) - range.ave) / range.std; });
 //Add age ranges, if required  .addRange min, max, average, standard deviation
 ECMDEVelocityCalc.addRange(0,   1,  79.7, 18.8); 
 ECMDEVelocityCalc.addRange(1,   6,  95.2, 19.5); 
 ECMDEVelocityCalc.addRange(6,   10,  94.4, 14.8);
 ECMDEVelocityCalc.addRange(10,  14, 94.5, 16);
 ECMDEVelocityCalc.addRange(14,  19, 90.3, 17.8); 
 //Hook up watch of dependant measurement changes
 addZScoreWatch(ECMDEVelocityCalc);
 
 
 //Create calculation class: new ZScoreAgeRangeCalculation "Name of measurement to calculate", ["Required measurements"], (mmts, range) -> Perform calculation here
 const ECMDAVelocityCalc = new ZScoreAgeRangeCalculation("ECMDAVelocityZScore", ["ECMDAVelocity"], function(mmts, range) { return ((mmts.ECMDAVelocity.value*100) - range.ave) / range.std; });
 //Add age ranges, if required  .addRange min, max, average, standard deviation
 ECMDAVelocityCalc.addRange(0,   1,  65.3, 13.3); 
 ECMDAVelocityCalc.addRange(1,   6,  61.3, 12.1);
 ECMDAVelocityCalc.addRange(6,   10,  49.4, 12.5);
 ECMDAVelocityCalc.addRange(10,  14, 49.5, 13.8);
 ECMDAVelocityCalc.addRange(14,  19, 45.5, 13.2); 
 //Hook up watch of dependant measurement changes
 addZScoreWatch(ECMDAVelocityCalc);
 
 //Create calculation class: new ZScoreAgeRangeCalculation "Name of measurement to calculate", ["Required measurements"], (mmts, range) -> Perform calculation here
 const ECMDEARatioCalc = new ZScoreAgeRangeCalculation("ECMDEARatioZScore", ["ECMDEARatio"], function(mmts, range) { return (mmts.ECMDEARatio.value - range.ave) / range.std; });
 //Add age ranges, if required  .addRange min, max, average, standard deviation
 ECMDEARatioCalc.addRange(0,   1,  1.24, 0.3); 
 ECMDEARatioCalc.addRange(1,   6,  1.6, 0.46);
 ECMDEARatioCalc.addRange(6,   10,  1.99, 0.51);
 ECMDEARatioCalc.addRange(10,  14, 2.02, 0.58);
 ECMDEARatioCalc.addRange(14,  19, 2.13, 0.65); 
 //Hook up watch of dependant measurement changes
 addZScoreWatch(ECMDEARatioCalc);
 
 //Create calculation class: new ZScoreAgeRangeCalculation "Name of measurement to calculate", ["Required measurements"], (mmts, range) -> Perform calculation here
 const ECMDLateralECalc = new ZScoreAgeRangeCalculation("ECMDLateralEZScore", ["ECMDLateralE"], function(mmts, range) { return (mmts.ECMDLateralE.value - range.ave) / range.std; });
 //Add age ranges, if required  .addRange min, max, average, standard deviation
 ECMDLateralECalc.addRange(0,   1, 9.7, 3.3); 
 ECMDLateralECalc.addRange(1,   6, 15.1, 3.4);
 ECMDLateralECalc.addRange(6,   10, 17.2, 3.7);
 ECMDLateralECalc.addRange(10,  14, 19.6, 3.4);
 ECMDLateralECalc.addRange(14,  19, 20.6, 3.8); 
 //Hook up watch of dependant measurement changes
 addZScoreWatch(ECMDLateralECalc);
 
 //Create calculation class: new ZScoreAgeRangeCalculation "Name of measurement to calculate", ["Required measurements"], (mmts, range) -> Perform calculation here
 const ECMDECalc = new ZScoreAgeRangeCalculation("ECMDEZScore", ["ECMDE"], function(mmts, range) { return (mmts.ECMDE.value - range.ave) / range.std; });
 //Add age ranges, if required  .addRange min, max, average, standard deviation
 ECMDECalc.addRange(0,   1, 8.1, 2.5); 
 ECMDECalc.addRange(1,   6, 11.8, 2);
 ECMDECalc.addRange(6,   10, 13.4, 1.9);
 ECMDECalc.addRange(10,  14, 14.5, 2.6);
 ECMDECalc.addRange(14,  19, 14.9, 2.4); 
 //Hook up watch of dependant measurement changes
 addZScoreWatch(ECMDECalc);
 
 //Create calculation class: new ZScoreAgeRangeCalculation "Name of measurement to calculate", ["Required measurements"], (mmts, range) -> Perform calculation here
 const ECMDIVRTCalc = new ZScoreAgeRangeCalculation("ECMDIVRTZScore", ["ECMDIVRT"], function(mmts, range) { return (mmts.ECMDIVRT.value - range.ave) / range.std; });
 //Add age ranges, if required  .addRange min, max, average, standard deviation
 ECMDIVRTCalc.addRange(3,   9, 62, 10);
 ECMDIVRTCalc.addRange(9,  13, 67, 10);
 ECMDIVRTCalc.addRange(13,  19, 74, 13);
 //Hook up watch of dependant measurement changes
 addZScoreWatch(ECMDIVRTCalc);
 
 //Create calculation class: new ZScoreAgeRangeCalculation "Name of measurement to calculate", ["Required measurements"], (mmts, range) -> Perform calculation here
 const ECLVMassIndexBSACalc = new ZScoreAgeRangeCalculation("ECLVMassIndexBSAZScore", ["ECLVMassIndexBSA"], function(mmts, range) { return (mmts.ECLVMassIndexBSA.value - range.ave) / range.std; });
 //Add age ranges, if required  .addRange min, max, average, standard deviation
 ECLVMassIndexBSACalc.addRange(0,   1, 18.9, 6.5); 
 ECLVMassIndexBSACalc.addRange(1,   8, 43.6, 16.4);
 ECLVMassIndexBSACalc.addRange(6,   10, 82.3, 28.3);
 ECLVMassIndexBSACalc.addRange(10,  14, 110.1, 32.9);
 ECLVMassIndexBSACalc.addRange(14,  19, 158.4, 48.5); 
 //Hook up watch of dependant measurement changes
 addZScoreWatch(ECLVMassIndexBSACalc);
 
 //Create calculation class: new ZScoreAgeRangeCalculation "Name of measurement to calculate", ["Required measurements"], (mmts, range) -> Perform calculation here
 const ECLVMassIndexBSA2DCalc = new ZScoreAgeRangeCalculation("ECLVMassIndexBSA2DZScore", ["ECLVMassIndexBSA2D"], function(mmts, range) { return (mmts.ECLVMassIndexBSA2D.value - range.ave) / range.std; });
 //Add age ranges, if required  .addRange min, max, average, standard deviation
 ECLVMassIndexBSA2DCalc.addRange(0,   1, 18.9, 6.5); 
 ECLVMassIndexBSA2DCalc.addRange(1,   6, 43.6, 16.4);
 ECLVMassIndexBSA2DCalc.addRange(6,   10, 82.3, 28.3);
 ECLVMassIndexBSA2DCalc.addRange(10,  14, 110.1, 32.9);
 ECLVMassIndexBSA2DCalc.addRange(14,  19, 158.4, 2.4); 
 //Hook up watch of dependant measurement changes
 addZScoreWatch(ECLVMassIndexBSA2DCalc);
 
 //Create calculation class: new ZScoreAgeRangeCalculation "Name of measurement to calculate", ["Required measurements"], (mmts, range) -> Perform calculation here
 const ECMDPVSystolicVelCalc = new ZScoreAgeRangeCalculation("ECMDPVSystolicVelZScore", ["ECMDPVSystolicVel"], function(mmts, range) { return ((mmts.ECMDPVSystolicVel.value*100) - range.ave) / range.std; });
 //Add age ranges, if required  .addRange min, max, average, standard deviation
 ECMDPVSystolicVelCalc.addRange(0,   1, 44.6, 10.3);
 ECMDPVSystolicVelCalc.addRange(1,   6, 48, 8.9);
 ECMDPVSystolicVelCalc.addRange(6,   10, 50.7, 11.3);
 ECMDPVSystolicVelCalc.addRange(10,  14, 49, 11.1);
 ECMDPVSystolicVelCalc.addRange(14,  19, 47.7, 7.3); 
 //Hook up watch of dependant measurement changes
 addZScoreWatch(ECMDPVSystolicVelCalc);
 
 //Create calculation class: new ZScoreAgeRangeCalculation "Name of measurement to calculate", ["Required measurements"], (mmts, range) -> Perform calculation here
 const ECMDPVDiastolicVelCalc = new ZScoreAgeRangeCalculation("ECMDPVDiastolicVelZScore", ["ECMDPVDiastolicVel"], function(mmts, range) { return ((mmts.ECMDPVDiastolicVel.value*100) - range.ave) / range.std; });
 //Add age ranges, if required  .addRange min, max, average, standard deviation
 ECMDPVDiastolicVelCalc.addRange(0,   1, 46, 9.5);
 ECMDPVDiastolicVelCalc.addRange(1,   6, 54.5, 11);
 ECMDPVDiastolicVelCalc.addRange(6,   10, 53.3, 11.4);
 ECMDPVDiastolicVelCalc.addRange(10,  14, 58.4, 12.1);
 ECMDPVDiastolicVelCalc.addRange(14,  19, 57.9, 15); 
 //Hook up watch of dependant measurement changes
 addZScoreWatch(ECMDPVDiastolicVelCalc);
 
 //Create calculation class: new ZScoreAgeRangeCalculation "Name of measurement to calculate", ["Required measurements"], (mmts, range) -> Perform calculation here
 const ECMDPVaVelocityCalc = new ZScoreAgeRangeCalculation("ECMDPVaVelocityZScore", ["ECMDPVaVelocity"], function(mmts, range) { return ((mmts.ECMDPVaVelocity.value*100) - range.ave) / range.std; });
 //Add age ranges, if required  .addRange min, max, average, standard deviation
 ECMDPVaVelocityCalc.addRange(0,   1, 16.4, 6.3);
 ECMDPVaVelocityCalc.addRange(1,   6, 20.6, 4.3);
 ECMDPVaVelocityCalc.addRange(6,   10, 20.2, 3.8);
 ECMDPVaVelocityCalc.addRange(10,  14, 21.2, 4.9);
 ECMDPVaVelocityCalc.addRange(14,  19, 20, 5.2); 
 //Hook up watch of dependant measurement changes
 addZScoreWatch(ECMDPVaVelocityCalc);
 
 const ECLVDiastoleMCalc = new ZScoreAgeRangeCalculation("ECLVDiastoleMZScore", ["ECLVDiastoleM", "ECDEMBSAHaycock"], 
 function(mmts) {
  const bsa = mmts.ECDEMBSAHaycock.value;
  const B0 = 0.105;
  const B1 = 2.859;
  const B2 = -2.119;
  const B3 = 0.552;
  const Err = 0.010;
  const MeanY = B0 + (B1*bsa) + (B2*bsa*bsa) + (B3*bsa*bsa*bsa);
  return (Math.log(mmts.ECLVDiastoleM.value) - MeanY) / Math.sqrt(Err);
 });
 addZScoreWatch(ECLVDiastoleMCalc);
 
 const ECLVDiastoleCalc = new ZScoreAgeRangeCalculation("ECLVDiastoleZScore", ["ECLVDiastole", "ECDEMBSAHaycock"], 
 function(mmts) {
  const bsa = mmts.ECDEMBSAHaycock.value;
  const B0 = 0.105;
  const B1 = 2.859;
  const B2 = -2.119;
  const B3 = 0.552;
  const Err = 0.010;
  const MeanY = B0 + (B1*bsa) + (B2*bsa*bsa) + (B3*bsa*bsa*bsa);
  return (Math.log(mmts.ECLVDiastole.value) - MeanY) / Math.sqrt(Err);
 });
 addZScoreWatch(ECLVDiastoleCalc); 
 
 //Create calculation class: new ZScoreAgeRangeCalculation "Name of measurement to calculate", ["Required measurements"], (mmts, range) -> Perform calculation here
 const ECRVRVEpVelCalc = new ZScoreAgeRangeCalculation("ECRVRVEpVelZScore", ["ECRVRVEpVel"], function(mmts, range) { return (mmts.ECRVRVEpVel.value - range.ave) / range.std; });
 //Add age ranges, if required  .addRange min, max, average, standard deviation
 ECRVRVEpVelCalc.addRange(0,   1, 13.8, 8.2); 
 ECRVRVEpVelCalc.addRange(1,   6, 17.1, 4);
 ECRVRVEpVelCalc.addRange(6,   10, 16.5, 3);
 ECRVRVEpVelCalc.addRange(10,  14, 16.5, 3.1);
 ECRVRVEpVelCalc.addRange(14,  19, 16.7, 2.8); 
 //Hook up watch of dependant measurement changes
 addZScoreWatch(ECRVRVEpVelCalc);
 
 //Create calculation class: new ZScoreAgeRangeCalculation "Name of measurement to calculate", ["Required measurements"], (mmts, range) -> Perform calculation here
 const ECLVIVSeptumMCalc = new ZScoreAgeRangeCalculation("ECLVIVSeptumMZScore", ["ECLVIVSeptumM", "ECDEMBSAHaycock"], 
 function(mmts) {
  const bsa = mmts.ECDEMBSAHaycock.value;
  const B0 = -1.242;
  const B1 = 1.272;
  const B2 = -0.762;
  const B3 = 0.208;
  const Err = 0.046;
  const MeanY = B0 + (B1*bsa) + (B2*bsa*bsa) + (B3*bsa*bsa*bsa);
  return (Math.log(mmts.ECLVIVSeptumM.value) - MeanY) / Math.sqrt(Err);
 });
 addZScoreWatch(ECLVIVSeptumMCalc); 
 
 const ECLVIVSeptumSystolicMCalc = new ZScoreAgeRangeCalculation("ECLVIVSeptumSystolicMZScore", ["ECLVIVSeptumSystolicM", "ECDEMBSAHaycock"], 
 function(mmts) {
  const bsa = mmts.ECDEMBSAHaycock.value;
  const B0 = -1.048;
  const B1 = 1.751;
  const B2 = -1.177;
  const B3 = 0.318;
  const Err = 0.034;
  const MeanY = B0 + (B1*bsa) + (B2*bsa*bsa) + (B3*bsa*bsa*bsa);
  return (Math.log(mmts.ECLVIVSeptumSystolicM.value) - MeanY) / Math.sqrt(Err);
 });
 addZScoreWatch(ECLVIVSeptumSystolicMCalc); 
 
 const ECLVPostWallMCalc = new ZScoreAgeRangeCalculation("ECLVPostWallMZScore", ["ECLVPostWallM", "ECDEMBSAHaycock"], 
 function(mmts) {
  const bsa = mmts.ECDEMBSAHaycock.value;
  const B0 = -1.586;
  const B1 = 1.849;
  const B2 = -1.188;
  const B3 = 0.313;
  const Err = 0.037;
  const MeanY = B0 + (B1*bsa) + (B2*bsa*bsa) + (B3*bsa*bsa*bsa);
  return (Math.log(mmts.ECLVPostWallM.value) - MeanY) / Math.sqrt(Err);
 });
 addZScoreWatch(ECLVPostWallMCalc); 
 
 const ECLVPostWallSystolicMCalc = new ZScoreAgeRangeCalculation("ECLVPostWallSystolicMZScore", ["ECLVPostWallSystolicM", "ECDEMBSAHaycock"], 
 function(mmts) {
  const bsa = mmts.ECDEMBSAHaycock.value;
  const B0 = -0.947;
  const B1 = 1.907;
  const B2 = -1.259;
  const B3 = 0.33;
  const Err = 0.023;
  const MeanY = B0 + (B1*bsa) + (B2*bsa*bsa) + (B3*bsa*bsa*bsa);
  return (Math.log(mmts.ECLVPostWallSystolicM.value) - MeanY) / Math.sqrt(Err);
 });
 addZScoreWatch(ECLVPostWallSystolicMCalc); 
 
 const ECLVSystoleMCalc = new ZScoreAgeRangeCalculation("ECLVSystoleMZScore", ["ECLVSystoleM", "ECDEMBSAHaycock"], 
 function(mmts) {
  const bsa = mmts.ECDEMBSAHaycock.value;
  const B0 = -0.371;
  const B1 = 2.833;
  const B2 = -2.081;
  const B3 = 0.538;
  const Err = 0.016;
  const MeanY = B0 + (B1*bsa) + (B2*bsa*bsa) + (B3*bsa*bsa*bsa);
  return (Math.log(mmts.ECLVSystoleM.value) - MeanY) / Math.sqrt(Err);
 });
 addZScoreWatch(ECLVSystoleMCalc); 
 
 const ECASAorticAnnulus2DCalc = new ZScoreAgeRangeCalculation("ECASAorticAnnulus2DZScore", ["ECASAorticAnnulus2D", "ECDEMBSAHaycock"], 
 function(mmts) {
  const bsa = mmts.ECDEMBSAHaycock.value;
  const B0 = -0.874;
  const B1 = 2.708;
  const B2 = -1.841;
  const B3 = 0.4452;
  const Err = 0.01;
  const MeanY = B0 + (B1*bsa) + (B2*bsa*bsa) + (B3*bsa*bsa*bsa);
  return (Math.log(mmts.ECASAorticAnnulus2D.value) - MeanY) / Math.sqrt(Err);
 });
 addZScoreWatch(ECASAorticAnnulus2DCalc); 
 
 const ECPDPVAnnulusCalc = new ZScoreAgeRangeCalculation("ECPDPVAnnulusZScore", ["ECPDPVAnnulus", "ECDEMBSAHaycock"], 
 function(mmts) {
  const bsa = mmts.ECDEMBSAHaycock.value;
  const B0 = -0.761;
  const B1 = 2.774;
  const B2 = -1.808;
  const B3 = 0.436;
  const Err = 0.023;
  const MeanY = B0 + (B1*bsa) + (B2*bsa*bsa) + (B3*bsa*bsa*bsa);
  return (Math.log(mmts.ECPDPVAnnulus.value) - MeanY) / Math.sqrt(Err);
 });
 addZScoreWatch(ECPDPVAnnulusCalc); 
 
 const ECMDMVDiameterCalc = new ZScoreAgeRangeCalculation("ECMDMVDiameterZScore", ["ECMDMVDiameter", "ECDEMBSAHaycock"], 
 function(mmts) {
  const bsa = mmts.ECDEMBSAHaycock.value;
  const B0 = -0.271;
  const B1 = 2.446;
  const B2 = -1.700;
  const B3 = 0.425;
  const Err = 0.022;
  const MeanY = B0 + (B1*bsa) + (B2*bsa*bsa) + (B3*bsa*bsa*bsa);
  return (Math.log(mmts.ECMDMVDiameter.value) - MeanY) / Math.sqrt(Err);
 });
 addZScoreWatch(ECMDMVDiameterCalc); 
 
 const ECRVTriAnnularDiamCalc = new ZScoreAgeRangeCalculation("ECRVTriAnnularDiamZScore", ["ECRVTriAnnularDiam", "ECDEMBSAHaycock"], 
 function(mmts) {
  const bsa = mmts.ECDEMBSAHaycock.value;
  const B0 = -0.164;
  const B1 = 2.341;
  const B2 = -1.596;
  const B3 = 0.387;
  const Err = 0.036;
  const MeanY = B0 + (B1*bsa) + (B2*bsa*bsa) + (B3*bsa*bsa*bsa);
  return (Math.log(mmts.ECRVTriAnnularDiam.value) - MeanY) / Math.sqrt(Err);
 });
 addZScoreWatch(ECRVTriAnnularDiamCalc); 
 
 const ECASAortaAtSinusesValsalvaCalc = new ZScoreAgeRangeCalculation("ECASAortaAtSinusesValsalvaZScore", ["ECASAortaAtSinusesValsalva", "ECDEMBSAHaycock"], 
 function(mmts) {
  const bsa = mmts.ECDEMBSAHaycock.value;
  const B0 = -0.5;
  const B1 = 2.537;
  const B2 = -1.707;
  const B3 = 0.42;
  const Err = 0.012;
  const MeanY = B0 + (B1*bsa) + (B2*bsa*bsa) + (B3*bsa*bsa*bsa);
  return (Math.log(mmts.ECASAortaAtSinusesValsalva.value) - MeanY) / Math.sqrt(Err);
 });
 addZScoreWatch(ECASAortaAtSinusesValsalvaCalc); 
 
 const ECASASinotubularCalc = new ZScoreAgeRangeCalculation("ECASASinotubularZScore", ["ECASASinotubular", "ECDEMBSAHaycock"], 
 function(mmts) {
  const bsa = mmts.ECDEMBSAHaycock.value;
  const B0 = -0.759;
  const B1 = 2.643;
  const B2 = -1.797;
  const B3 = 0.442;
  const Err = 0.018;
  const MeanY = B0 + (B1*bsa) + (B2*bsa*bsa) + (B3*bsa*bsa*bsa);
  return (Math.log(mmts.ECASASinotubular.value) - MeanY) / Math.sqrt(Err);
 });
 addZScoreWatch(ECASASinotubularCalc); 
 
 const ECASAProximalAscCalc = new ZScoreAgeRangeCalculation("ECASAProximalAscZScore", ["ECASAProximalAsc", "ECDEMBSAHaycock"], 
 function(mmts) {
  const bsa = mmts.ECDEMBSAHaycock.value;
  const B0 = 0.421;
  const B1 = 2.898;
  const Err = 0.09111;
  const MeanY = B1+(Math.log(bsa) * B0);
  return (Math.log(mmts.ECASAProximalAsc.value * 10) - MeanY) /Err;
 });
 addZScoreWatch(ECASAProximalAscCalc); 
 
 const ECASAArchTransDiamCalc = new ZScoreAgeRangeCalculation("ECASAArchTransDiamZScore", ["ECASAArchTransDiam", "ECDEMBSAHaycock"], 
 function(mmts) {
  const bsa = mmts.ECDEMBSAHaycock.value;
  const B0 = -0.79;
  const B1 = 3.02;
  const B2 = -2.484;
  const B3 = 0.712;
  const Err = 0.023;
  const MeanY = B0 + (B1*bsa) + (B2*bsa*bsa) + (B3*bsa*bsa*bsa);
  return (Math.log(mmts.ECASAArchTransDiam.value) - MeanY) / Math.sqrt(Err);
 });
 addZScoreWatch(ECASAArchTransDiamCalc); 
 
 const ECASAArchDistalDiamCalc = new ZScoreAgeRangeCalculation("ECASAArchDistalDiamZScore", ["ECASAArchDistalDiam", "ECDEMBSAHaycock"], 
 function(mmts) {
  const bsa = mmts.ECDEMBSAHaycock.value;
  const B0 = -0.976;
  const B1 = 2.469;
  const B2 = -1.746;
  const B3 = 0.445;
  const Err = 0.026;
  const MeanY = B0 + (B1*bsa) + (B2*bsa*bsa) + (B3*bsa*bsa*bsa);
  return (Math.log(mmts.ECASAArchDistalDiam.value) - MeanY) / Math.sqrt(Err);
 });
 addZScoreWatch(ECASAArchDistalDiamCalc); 
 
 const ECASAorticIsthmusDiamCalc = new ZScoreAgeRangeCalculation("ECASAorticIsthmusDiamZScore", ["ECASAorticIsthmusDiam", "ECDEMBSAHaycock"], 
 function(mmts) {
  const bsa = mmts.ECDEMBSAHaycock.value;
  const B0 = -1.072;
  const B1 = 2.539;
  const B2 = -1.627;
  const B3 = 0.368;
  const Err = 0.027;
  const MeanY = B0 + (B1*bsa) + (B2*bsa*bsa) + (B3*bsa*bsa*bsa);
  return (Math.log(mmts.ECASAorticIsthmusDiam.value) - MeanY) / Math.sqrt(Err);
 });
 addZScoreWatch(ECASAorticIsthmusDiamCalc); 
 
 const ECPDMainPACalc = new ZScoreAgeRangeCalculation("ECPDMainPAZScore", ["ECPDMainPA", "ECDEMBSAHaycock"], 
 function(mmts) {
  const bsa = mmts.ECDEMBSAHaycock.value;
  const B0 = -0.707;
  const B1 = 2.746;
  const B2 = -1.807;
  const B3 = 0.424;
  const Err = 0.0224;
  const MeanY = B0 + (B1*bsa) + (B2*bsa*bsa) + (B3*bsa*bsa*bsa);
  return (Math.log(mmts.ECPDMainPA.value) - MeanY) / Math.sqrt(Err);
 });
 addZScoreWatch(ECPDMainPACalc); 
 
 const ECPDRightPACalc = new ZScoreAgeRangeCalculation("ECPDRightPAZScore", ["ECPDRightPA", "ECDEMBSAHaycock"], 
 function(mmts) {
  const bsa = mmts.ECDEMBSAHaycock.value;
  const B0 = -1.36;
  const B1 = 3.394;
  const B2 = -2.508;
  const B3 = 0.66;
  const Err = 0.027;
  const MeanY = B0 + (B1*bsa) + (B2*bsa*bsa) + (B3*bsa*bsa*bsa);
  return (Math.log(mmts.ECPDRightPA.value) - MeanY) / Math.sqrt(Err);
 });
 addZScoreWatch(ECPDRightPACalc); 
 
 const ECPDLeftPACalc = new ZScoreAgeRangeCalculation("ECPDLeftPAZScore", ["ECPDLeftPA", "ECDEMBSAHaycock"], 
 function(mmts) {
  const bsa = mmts.ECDEMBSAHaycock.value;
  const B0 = -1.348;
  const B1 = 2.884;
  const B2 = -1.954;
  const B3 = 0.466;
  const Err = 0.028;
  const MeanY = B0 + (B1*bsa) + (B2*bsa*bsa) + (B3*bsa*bsa*bsa);
  return (Math.log(mmts.ECPDLeftPA.value) - MeanY) / Math.sqrt(Err);
 });
 addZScoreWatch(ECPDLeftPACalc);
 
 const PCLMCACalc = new ZScoreAgeRangeCalculation("PCLMCAZScore", ["PCLMCA", "ECDEMBSAHaycock"], 
 function(mmts) {
  const bsa = mmts.ECDEMBSAHaycock.value;
  const MeanY = (0.31747*Math.pow(bsa,0.36008))-0.02887;
  const sd = 0.03040+(0.01514*bsa);
  return (mmts.PCLMCA.value - MeanY) / sd;
 });
 addZScoreWatch(PCLMCACalc); 
 
 const PCLADCACalc = new ZScoreAgeRangeCalculation("PCLADCAZScore", ["PCLADCA", "ECDEMBSAHaycock"], 
 function(mmts) {
  const bsa = mmts.ECDEMBSAHaycock.value;
  const MeanY = (0.26108*Math.pow(bsa,0.37893))-0.02852;
  const sd = 0.01465+(0.01996*bsa);
  return (mmts.PCLADCA.value - MeanY) / sd;
 });
 addZScoreWatch(PCLADCACalc);
 
 const PCRCACalc = new ZScoreAgeRangeCalculation("PCRCAZScore", ["PCRCA", "ECDEMBSAHaycock"], 
 function(mmts) {
  const bsa = mmts.ECDEMBSAHaycock.value;
  const MeanY = (0.2617*Math.pow(bsa,0.39992))-0.02756;
  const sd = 0.02407+(0.01597*bsa);
  return (mmts.PCRCA.value - MeanY) / sd;
 });
 return addZScoreWatch(PCRCACalc);
}  
]);

class ZScoreAgeRangeCalculation {
  ageRanges: { start: number, end: number, ave: number, std: number }[] = [];
  constructor(public outputKey, public requiredKeys, public fn) {
  }
  
  canCalculate(measurements) {
    let calc = true;
    if ((this.ageRanges.length !== 0) && !measurements["ECDEMReportAge"].value) { calc = false; }
    for (let key of this.requiredKeys) {
      if (!(measurements[key] != null ? measurements[key].value : undefined)) { calc = false; }
    }
    return calc;
  }
    
  calculate(measurements) {
    let match = null;
    if (this.ageRanges.length !== 0) {
      const age = measurements["ECDEMReportAge"].value;
      for (let range of this.ageRanges) {
        if ((range.start <= age) && (range.end > age)) {
          match = range;
          break;
        }
      }
    }
    return this.fn(measurements, match);
  }
    
  addRange(start: number, end: number, ave: number, std: number) {
    return this.ageRanges.push({start, end, ave, std});
  }
}