import "angular-material";
import { IController } from "angular";
import { IDicomTagViewModel, IMappingViewModel } from "./dicomPropertyMapping.models";
import { IEntityProperty } from "./studyProperties";

interface DicomPropertyMappingPrompt extends IController {}
class DicomPropertyMappingPrompt {
  mapping: IMappingViewModel;
  dicomTags: IDicomTagViewModel[];
  studyProperties: IEntityProperty[];

  static $inject = ["$mdDialog"];
  constructor(private readonly mdDialog: angular.material.IDialogService) {
    this.mapping = {
      dicomTag: null,
      dicomValue: null,
      studyProperty: null,
      studyValue: null,
      entity: null
    };
  }

  accept(): void {
    this.mdDialog.hide(this.mapping);
  }

  cancel(): void {
    this.mdDialog.cancel();
  }
}

export function createDialogOptions(
  dicomTags: IDicomTagViewModel[], 
  studyProperties: IEntityProperty[]): angular.material.IDialogOptions {
  return {
    controller: DicomPropertyMappingPrompt,
    controllerAs: "$ctrl",
    bindToController: true,
    templateUrl: require("./dicomPropertyMappingPrompt.html"),
    openFrom: "#promptNewMapping",
    locals: {
      "dicomTags": dicomTags,
      "studyProperties": studyProperties
    }
  };
}