import {
  BusinessModelService,
  ExamType,
  Study,
  Job,
  Modality,
  Physician,
  Technician,
  Site,
  Patient,
  Institute
} from "../../businessModels";
import {
  IScope,
  IQService,
  noop,
  IRootScopeService,
  IController,
  module as ngModule
} from "angular";
import { IStateService, IStateParamsService } from "angular-ui-router";
import { LoDashStatic } from "lodash";

interface IPendingScope extends IScope {
  pending: IPendingStudy;
  study: Study & IStudyExtensions;
  modules: Modality[];
  examTypes: ExamType[];
  physicians: Physician[];
  technicians: Technician[];
  sites: Site[];
  srJob: Job;

  physicianRequired: boolean;
  waitLoadTick: number;
  redirecting: boolean;
  paused: boolean;

  pauseCountdown: () => void;
  resetDupe: () => void;
  goToStudy: (write?: boolean) => void;
  readyToGo: () => void;
}

interface IStudyExtensions {
  hasCheckedPatientDupes?: boolean;
  hasDuplicates?: boolean;
  dupeMessage?: string;
  dupe?: Patient;
}

interface IPendingStudy {
  examType?: ExamType;
  module?: Modality;
  physician?: Physician;
  technician?: Technician;
  site?: Site;
  physicianRequired?: boolean;
}

class PendingController implements IController {
  static $inject = [
    "$scope",
    "$q",
    "$state",
    "$stateParams",
    "businessModels",
    "lodash",
    "inst",
    "$rootScope",
    "$http"];
  constructor(
    $scope: IPendingScope,
    $q: IQService,
    $state: IStateService,
    $stateParams: IStateParamsService,
    models: BusinessModelService,
    lodash: LoDashStatic,
    inst: Institute,
    $rootScope: IRootScopeService,
    $http: angular.IHttpService) {

    $scope.waitLoadTick = 100.0;
      $scope.redirecting = false;
      $scope.paused = false;
      $scope.pending = {};
      let originalPatient: Patient;

      const allExamTypes = models.ExamType.listActive();

      const filterExamTypes = function() {
        $scope.examTypes = [];
        $scope.pending.examType = null;
        if ($scope.pending.module == null) {
          return;
        }
        const selectedModule = $scope.pending.module;
        $scope.examTypes =
          lodash.filter(
            allExamTypes,
            (et) => et.modality === selectedModule && !et.isDeleted);
        // Take first exam type if there is only 1
        if ($scope.examTypes.length === 1) {
          $scope.pending.examType = $scope.examTypes[0];
        // Take already saved exam type if it matches the module
        } else if ($scope.study.exam.type !== null && $scope.study.exam.type.modality === selectedModule) {
          $scope.pending.examType = $scope.study.exam.type;
        // Check to see if there is an Institute setting for default exam type
        } else if (models.Institute.current.hasSetting("DefaultExamType")) {
            $scope.pending.examType =
              lodash.find($scope.examTypes, et => et.key === models.Institute.current.getSetting("DefaultExamType"));
        }
      };

      models.Study.find({ id: $stateParams.studyId }, "exams.jobs.job, technician, physician")
      .then(function(study) {
        if (!study) {
          $state.go("midas");
          return;
        }

        originalPatient = study.patient;
        $scope.study = study;
        $scope.srJob =
          lodash.find(study.exam.jobs, job => job.type === "StructuredReportJob" && !job.isDeleted !== true);
        if ($scope.srJob) {
          startPolling();
        }

        if (study.status.key !== "New") {
          $scope.goToStudy(false);
          return;
        }

        $scope.modules = lodash.filter(models.Modality.listNow(), x => x.isDeleted !== true);
        if ($scope.modules.length === 1) {
          $scope.pending.module = $scope.modules[0];
        } else if (study.modality) {
          $scope.pending.module = study.modality;
        } else if (study.exam.type) {
          $scope.pending.module = study.exam.type.modality;
        } else if (models.Institute.current.hasSetting("DefaultExamType")) {
          var settingVal = models.Institute.current.getSetting("DefaultExamType");
          if (settingVal != null && settingVal.length > 0)
            $scope.pending.module = lodash.find(allExamTypes, et => 
              et.key === models.Institute.current.getSetting("DefaultExamType") && !et.isDeleted).modality;
        }
      
        $scope.sites = lodash.filter(models.Site.listNow(), x => !x.isDeleted);
        // Take already saved site if any
        if ($scope.study.siteEntity !== null) {
          $scope.pending.site = $scope.study.siteEntity;
        } else
        // Take first site if there is only 1
        if ($scope.sites.length === 1) {
          $scope.pending.site = $scope.sites[0];
        }  
        
        filterExamTypes();

        $scope.pending.physicianRequired =
          models.Institute.current.getSetting("PhysicianRequiredForStudies") !== "false";

        $rootScope.$broadcast("duplicateCheckRequested", {
          studyId: study.id,
          dupesFound,
          dupeSelected
        });

        models.Physician.list().then(function(p) {
          $scope.physicians = p;
          if (study.physician != null) {
            $scope.pending.physician = study.physician;
          } else if ($scope.physicians.length === 1) {
            $scope.pending.physician = $scope.physicians[0];
          } else if (models.User.current.physician != null) {
            $scope.pending.physician = models.User.current.physician;
          }
        });

        return models.Technician.list().then(function(t) {
          $scope.technicians = t;
          if (study.technician != null) {
            $scope.pending.technician = study.technician;
          } else if ($scope.technicians.length === 1) {
            $scope.pending.technician = $scope.technicians[0];
          } else if (models.User.current.technician != null) {
            $scope.pending.technician = models.User.current.technician;
          }
        });
      });

      $scope.$watch("pending.module", () => filterExamTypes());

      const writeStudy = function() {
        $scope.study.modality = $scope.pending.module;
        $scope.study.exam.type = $scope.pending.examType;
        $scope.study.exam.title =
          $scope.pending.examType.display != null ? $scope.pending.examType.display : $scope.pending.examType.key;
        $scope.study.physician = $scope.pending.physicianRequired ? $scope.pending.physician : null;
        $scope.study.technician = $scope.pending.technician;
        $scope.study.status = lodash.find(models.Status.listNow(), x => x.key === "Saved");
        $scope.study.siteEntity = $scope.pending.site;
        if ($scope.study.dupe && ($scope.study.patient.id !== $scope.study.dupe.id)) {
          $scope.study.patient.isDeleted = true;
          $scope.study.patient = $scope.study.dupe;
        }
        const updateStatus = false, newStatus = "Saved", oldStatus = "New", reason = "New study saved";
        return models.save(noop, false, false)
          .then(() => $http.post(`api/midas/LogStudyStatusUpdate/${$scope.study.id}`, { updateStatus, newStatus, oldStatus, reason }))
          // Create charts straight away so user doesn't have to edit a measurement to get them to appear
          .then(() => $scope.study.recreateCharts(false));
      };

      $scope.goToStudy = function(write: boolean = true) {
        let wait = $q.when(null);
        if (write) {
          wait = writeStudy();
        }
        wait.then(() =>
          $state.go("midas.studies.view.details", {
            studyId: $scope.study.id,
            inst: inst.key
          })
        );
      };

      $scope.resetDupe = function () {
        $scope.study.hasCheckedPatientDupes = false;
        $rootScope.$broadcast("duplicateCheckRequested", {
          studyId: $scope.study.id,
          dupesFound,
          dupeSelected
        });
        return $scope.study.patient = originalPatient;
      };

      const dupesFound = function(dupes) {
        $scope.study.hasDuplicates = dupes != null ? dupes.length : undefined;
        $scope.study.dupeMessage = `${dupes.length} potential duplicates found`;
        if (dupes.length === 0) { return $scope.study.hasCheckedPatientDupes = true; }
      };

      const dupeSelected = function(dupe) {
        $scope.study.hasCheckedPatientDupes = true;
        if (dupe) {
          $scope.study.dupeMessage = "Duplicate selected";
          return models.Patient.find({
            id: dupe.stateArgs.patientId})
          .then(function(x) {
            return $scope.study.dupe = x;
          });
        } else {
          return $scope.study.dupeMessage = "No match selected";
        }
      };

      let countdownInterval = null;

      const startCountdown = function() {
        $scope.redirecting = true;
        const tick = function() {
          $scope.waitLoadTick = $scope.waitLoadTick - 1;
          $scope.$apply();

          if ($scope.waitLoadTick === 0) {
            clearInterval(countdownInterval);
            return $scope.goToStudy();
          }
        };

        return countdownInterval = setInterval(tick, 500);
      };

      $scope.pauseCountdown = function() {
        $scope.paused = true;
        return stopCountdown();
      };

      const stopCountdown = function() {
        clearInterval(countdownInterval);
        $scope.waitLoadTick = 10;
        return $scope.redirecting = false;
      };

      let pollingInterval = null;
      const startPolling = function() {
        const tick = () => models.Study.find({ id: $stateParams.studyId }, "exams.jobs.job");
        return pollingInterval = setInterval(tick, 10000);
      };

      const stopPolling = function() { if (pollingInterval) { return clearInterval(pollingInterval); } };

      const siteReady = () => !$scope.sites || ($scope.sites.length === 0) || ($scope.pending.site != null);

      $scope.readyToGo = function() {
        const isReady = siteReady() && !$scope.paused
            && (!$scope.srJob || !$scope.srJob.isEnabled || $scope.srJob.isDeleted)
            && $scope.pending.module && $scope.pending.examType
            && (($scope.physicians.length === 0) || !$scope.pending.physicianRequired || $scope.pending.physician)
            && (($scope.technicians.length === 0) || $scope.pending.technician) && $scope.study.hasCheckedPatientDupes;
        if (isReady && !$scope.redirecting) { startCountdown(); }
        if (!isReady && $scope.redirecting) { stopCountdown(); }
        return isReady;
      };

      return $scope.$on("$destroy", function() {
        stopCountdown();
        return stopPolling();
      });
  }
}

export default ngModule(
  "midas.study.pending.pendingController",
  []
).controller("pendingCtrl", PendingController);