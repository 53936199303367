import * as angular from "angular";
import "angular-material";
import "ng-file-upload";
import * as _ from "lodash";
import { DiagramTemplate, BusinessModelService, IBusinessModel } from "../../businessModels";

interface ITemplateModel {
    examType: string,
    diagrams: Array<IDiagramModel>
    selected: boolean;
}
interface IDiagramModel {
    name: string,
    imagePath: string,
    diagramTemplate: DiagramTemplate
}

class DiagamTemplateListController implements angular.IComponentController {
    selectedExamType: string;
    templateModels: Array<ITemplateModel>;

    static $inject = ["$scope", "$log", "$mdDialog", "$mdToast", "businessModels", "Upload"];
    constructor(
        private readonly $scope: angular.IScope,  
        private readonly $log: angular.ILogService,
        private readonly mdDialog: angular.material.IDialogService,
        private readonly mdToast: angular.material.IToastService,
        private readonly models: BusinessModelService,
        private readonly upload: angular.angularFileUpload.IUploadService
    ){
        this.selectedExamType = '';
    }

    $onInit(): void {
        this.reload();
    }

    private reload(): angular.IPromise<void> {
        var self = this;

        if (this.templateModels && this.templateModels.filter(f => f.selected)) {
            this.selectedExamType = this.templateModels.filter(f => f.selected)[0].examType;
        }

        this.$log.debug("Reloading diagram templates...");
        const wait = this.models.DiagramTemplate.list().then((templates) => {

            //Load all exam types
            var arrayOfExamTypes = this.models.ExamType.listActive()
                .map(function (e) {
                    return {
                        examType: e.display ? e.display : e.key,
                        diagrams: Array<IDiagramModel>()
                    } as ITemplateModel
                }) as Array<ITemplateModel>

            //Load diagram templates and inverse the list to group by exam type
            var arrayOfTemplateModels = _(templates)
                .filter(function (item) { return !item.isDeleted; })
                .groupBy(o => o.examType.display ? o.examType.display : o.examType.key)
                .map((value, key) => ({ examType: key, diagrams: value }))
                .value()
                .map(function (o) {
                    return {
                        examType: o.examType,
                        diagrams: o.diagrams.map(function (d) {
                            return {
                                name: d.name,
                                imagePath: d.imagePath,
                                diagramTemplate: d
                            } as IDiagramModel
                        })
                    } as ITemplateModel
                });

            //Compile a complete list of all exam types with diagram templates associated
            this.templateModels = _(arrayOfExamTypes.concat(arrayOfTemplateModels))
                .groupBy(function (item) { return item.examType; })
                .map((value, key) => ({ examType: key, diagrams: value }))
                .map(function (o) {
                    return {
                        examType: o.examType,
                        diagrams: _.flatten(o.diagrams.map(function (d) {
                            return d.diagrams
                        })),
                        selected: false
                    }
                })
                .sortBy((et) => { return et.examType.toLowerCase() })
                .value() as Array<ITemplateModel>

            if (this.selectedExamType.length > 0 && this.templateModels) {
                this.templateModels.forEach(function(item) {
                    item.selected = (item.examType === self.selectedExamType);
                })
            }
            this.$log.debug("Loaded diagram templates.", templates);
        });             
        return wait;
    }

    deleteEntry(entry: DiagramTemplate) {

        var confirm = this.mdDialog.confirm()
            .title('Are you sure you want to delete this template?')
            .textContent('The template will be deleted, you can\'t undo this action.')
            .ok('Yes')
            .cancel('No');

        this.mdDialog.show(confirm).then(() => {
            this.$log.debug("Entry to be deleted", entry);
            this.deleteCommitAndReload(entry)
                .then(() => this.mdToast.showSimple("Template deleted"));
        })
    }

    private deleteCommitAndReload(model: IBusinessModel): angular.IPromise<void> {
        let markDeleted = () => model.isDeleted = true;
        return this.models.save(markDeleted).then(() => this.reload());
    }

    private ErrorTypeMessage(invalidFile: File): string {
        if (invalidFile["$error"]) {
            switch (invalidFile["$error"]) {
                case "maxSize":
                    return `${invalidFile.name} is too large, it must be smaller than ${invalidFile["$errorParam"]}`;
                case "pattern":
                    return `${invalidFile.name} must be an image file`;
                default:
                    return `${invalidFile.name} had an unspecified error`;
            }
        } else {
            return `${invalidFile.name} had an unspecified error`;
        }
    }

    uploadFile(files: File[], invalidFiles: File[], examKeyName: string) {

        if (invalidFiles && invalidFiles.length > 0) {
            this.mdToast.showSimple(this.ErrorTypeMessage(invalidFiles[0]));
            return;
        }
        if (!files || files.length !== 1) {
            return;
        }

        let config: angular.angularFileUpload.IFileUploadConfigFile = {
            url: "api/midas/UploadDiagramTemplate/" + examKeyName,
            data: files,
            method: "POST"
        };
        let wait = this.upload.upload(config);
        wait
            .then(() => this.reload())
            .catch(() => {
                this.mdToast.showSimple("There was a problem uploading the template. Please try again.");
            });
    }

    updateName(diagram: IDiagramModel) {
        if (this.$scope.diagramTemplateForm.$valid) {
            diagram.diagramTemplate.name = diagram.name;
            this.models.save();
        }
    }
}

export default <angular.IComponentOptions>{
    controller: DiagamTemplateListController,
    templateUrl: require('./diagramTemplateList.html')
}