import { IController, IComponentOptions, module as ngModule, IPromise, IQService, IScope } from "angular";
import { ReportFormatter, BusinessModelService, ExamType, User } from "../../businessModels";
import filters from "./blueprint-section.filters";
import "angular-material";
import "./blueprint-section.component.scss";
import editor from "./editor/blueprint-editor.component";
import convertDialog from "./dialogs/confirm-conversion.dialog";
import createDialog from "./dialogs/report-formatter-create.dialog";


interface IReportFormatterViewModel {
  reportFormatter: ReportFormatter,
  template: string;
  hasChanged: boolean;
}

interface ReportFormatterAdminSectionComponentController extends IController { }

/**
 * The administration section that provides report formatter self-service functionality to the user.
 * The component functions as the place where formatters and loaded and saved.
 */
class ReportFormatterAdminSectionComponentController {

  /** All exam types. */
  allExamTypes = new Array<ExamType>();

  /** Exam types that have been used in a formatter and are currently being shown to the user. */
  visibleExamTypes: ExamType[];

  /** Exam types that have not been used in a formatter or are filtered from display. */
  unusedOrInvisibleExamTypes: ExamType[];

  /** All formatters for this institute. */
  allFormatters: IReportFormatterViewModel[] = [];

  /** Formatters to be displayed to the user after filtering. */
  visibleFormatters: IReportFormatterViewModel[];

  /** Indicates if removed formatters are being displayed to the user. */
  showRemoved: boolean;

  static $inject = ["$q", "businessModels", "$mdToast", "$mdDialog", "$rootScope"];
  constructor(
    private readonly $q: IQService,
    private readonly models: BusinessModelService,
    private readonly $mdToast: angular.material.IToastService,
    private readonly $mdDialog: angular.material.IDialogService,
    $rootScope: IScope
  ) {
    this.preventDataLoss($rootScope);
  }

  /** Load formatters and exam types and then filter them. */
  $onInit(): void {
    this.$q.all([
      this.models.ReportFormatter
        .list({ "user.id": User.current.id }, "user", 1000)
        .then(x => this.allFormatters.push(...x.map(
            f => {
              return {
                reportFormatter: f,
                template: null,
                hasChanged: false
              } as IReportFormatterViewModel
            }
        )))
        .catch(() =>
          this.$mdToast.showSimple("There was a problem loading the report formatters. Please reload and try again.")),
      this.models.ReportFormatter
        .list({ "user.id": null }, 1000)
        .then(y => this.allFormatters.push(...y.map(
            f => {
              return {
                reportFormatter: f,
                template: null,
                hasChanged: false
              } as IReportFormatterViewModel
            }
        )))
        .catch(() =>
          this.$mdToast.showSimple("There was a problem loading the report formatters. Please reload and try again.")),
      this.models.ExamType
        .list()
        .then(x => this.allExamTypes = x)
        .catch(() =>
          this.$mdToast.showSimple("There was a problem loading the exam types. Please reload and try again."))
    ])
    .then(() => this.createAndUpdateDrafts());
  }

  private preventDataLoss(scope: angular.IScope) {
    let isDirty = (): boolean => {
      return this.visibleFormatters ?
        (this.visibleFormatters).some(f => f.hasChanged === true) : false;
    };
    scope.$on("$stateChangeStart", (event) => {
      if (!isDirty() || event.defaultPrevented === true) return;
      if (confirm("The blueprint has not been saved, do you wish to leave the page?")) {
        if (this.visibleFormatters) {
          (this.visibleFormatters).forEach(f => {
            if (f.hasChanged) {
              f.hasChanged = false
            }
          })
        };
        this.models.breeze.rejectChanges();
        } else {
          event.preventDefault();
      }
    });
    window.onbeforeunload = function () {
      if (isDirty()) {
        return "The blueprint has not been saved, do you wish to close the window?";
      }
    }
  }

  loadTemplate(formatter: IReportFormatterViewModel) {
    formatter.reportFormatter.loadTemplate()
      .then(result => {
        formatter.template = result;
      });
  }

  /** Create and save template drafts to database for the current user if they do not exists. */
  private createAndUpdateDrafts = (): IPromise<void> => {

    let publishedFormatters = this.allFormatters.filter(f => f.reportFormatter.user == null);
    let draftFormatters = this.allFormatters.filter(f => f.reportFormatter.user != null && f.reportFormatter.user.id === User.current.id);

    // Create draft formatters if they do not exist
    let promiseArray = publishedFormatters.map(pf => {
        if (draftFormatters.filter(df => df.reportFormatter.examType.id === pf.reportFormatter.examType.id).length === 0) {
          let draftFormatter = this.models.ReportFormatter.create(pf.reportFormatter.examType, pf.reportFormatter.type);
          return this.models.saveEntities([draftFormatter.breezeEntity()])
            .then(() => pf.reportFormatter.loadTemplate())
            .then(result => draftFormatter.saveTemplate(result))
            .then(result => this.allFormatters.push({
                reportFormatter: draftFormatter,
                template: result,
                hasChanged: false
            } as IReportFormatterViewModel))
        }
     });
    return this.$q.all(promiseArray).then(() => this.filter());
  }

  /** Publish the draft: update the published template with the draft's template. */
  onPublish(formatter: IReportFormatterViewModel): IPromise<void> {
    let publishedFormatter = this.allFormatters.filter(f =>
      f.reportFormatter.examType.id === formatter.reportFormatter.examType.id &&
      f.reportFormatter.user == null)[0];
    return publishedFormatter.reportFormatter
      .saveTemplate(formatter.template)
      .then(() => {
        formatter.hasChanged = false;
        this.$mdToast.showSimple("Formatter saved");
      })
      .catch(() => {
        this.$mdToast.showSimple("There was a problem saving the report template.");
      })
  }

  /** Save the draft to the database. */
  onSaveDraft(formatter: IReportFormatterViewModel): IPromise<void> {
    let draftFormatter = this.allFormatters.filter(f =>
      f.reportFormatter.examType.id === formatter.reportFormatter.examType.id &&
      f.reportFormatter.user != null &&
      f.reportFormatter.user.id === User.current.id)[0];
    return draftFormatter.reportFormatter
      .saveTemplate(formatter.template)
      .then(() => {
        formatter.hasChanged = false;
        this.$mdToast.showSimple("Draft saved");
      })
      .catch(() => {
        this.$mdToast.showSimple("There was a problem saving the draft.");
      })
  }

  /** Discard the draft's template and populate it with the published template. */
  onRevertDraft (formatter: IReportFormatterViewModel): IPromise<void> {
    let publishedFormatter = this.allFormatters.filter(f =>
      f.reportFormatter.examType.id === formatter.reportFormatter.examType.id &&
      f.reportFormatter.user == null)[0];
    return publishedFormatter.reportFormatter.loadTemplate()
      .then(result => formatter.reportFormatter.saveTemplate(result))
      .then(template => {
        formatter.template = template;
        formatter.hasChanged = false;
        this.$mdToast.showSimple("Draft updated to the published version");
      })
      .catch(() => {
        this.$mdToast.showSimple("There was a problem saving the draft.");
      })
  }

  /**
   * Mark formatter as removed and commit.
   * @returns A promise that will be resolved when the remove operation has been committed.
   */
  onRemove(formatter: IReportFormatterViewModel): IPromise<void> {
    let defaultFormatter = this.allFormatters.filter(f =>
      f.reportFormatter.examType.id === formatter.reportFormatter.examType.id &&
      f.reportFormatter.user == null)[0];
    return this.models
      .save(() => {
        formatter.reportFormatter.isDeleted = true;
        defaultFormatter.reportFormatter.isDeleted = true;
      })
      .then(() => {
        this.$mdToast.showSimple("Formatter removed");
        this.filter();
      })
      .catch(() =>
        this.$mdToast.showSimple("There was a problem removing the report template. Please reload and try again."));
  }

  /**
   * Mark formatter as active and commit.
   * @returns A promise that will be resolved when the restore operation has been committed.
   */
  onRestore(formatter: IReportFormatterViewModel): IPromise<void> {
    let defaultFormatter = this.allFormatters.filter(f =>
      f.reportFormatter.examType.id === formatter.reportFormatter.examType.id &&
      f.reportFormatter.user == null)[0];
    return this.models
      .save(() => {
        formatter.reportFormatter.isDeleted = false;
        defaultFormatter.reportFormatter.isDeleted = false;
      })
      .then(() => formatter.reportFormatter.loadTemplate())
      .then(template => {
        formatter.template = template;
        formatter.hasChanged = false;
        this.$mdToast.showSimple("Formatter restored");
        this.filter();
      })
      .catch(() =>
        this.$mdToast.showSimple("There was a problem restoring the report template. Please reload and try again."));
  }

  /**
   * Prompt the user to accept the conversion of a legacy formatter and then commit.
   * @returns A promise indicating if the user accepted the conversion operation.
   */
  onConvert(formatter: IReportFormatterViewModel): IPromise<boolean> {
    return this.$mdDialog
      .confirmSelfServeConversion(formatter.reportFormatter)
      .then(accept => {
        if (!accept) {
          return false;
        }
        let defaultFormatter = this.allFormatters.filter(f =>
          f.reportFormatter.examType.id === formatter.reportFormatter.examType.id &&
          f.reportFormatter.user == null)[0];
        return this.models.save(() => {
          defaultFormatter.reportFormatter.type = "SelfServe";
          formatter.reportFormatter.type = "SelfServe";
        })
        .then(() => defaultFormatter.reportFormatter.saveTemplate(formatter.reportFormatter.examType.key))
        .then(() => formatter.reportFormatter.saveTemplate(formatter.reportFormatter.examType.key))
        .then(() => true)
        .catch(() =>
          this.$mdToast.showSimple("There was a problem converting the report template. Please reload and try again."));
      });
  }

  /**
   * Prompt the user to choose required properties for a new formatter.
   * Present the user with only unused exam types.
   * Commit the new formatter on accept.
   *
   * @returns A promise that will be resolved when the user has completed the prompt
   * and the value has been committed.
   */
  onCreate(): IPromise<void> {
    return this.$mdDialog.showCreateFormatterPrompt(this.unusedOrInvisibleExamTypes)
      .then(result => {
        const potentials = this.allFormatters.filter(x => x.reportFormatter.examType.key === result.examType.key);
        let newFormatter: IReportFormatterViewModel;
        let newDefaultFormatter: IReportFormatterViewModel;
        const existing = potentials.length === 0 ? null : potentials[0];
        if (existing) {
          return this.onRestore(existing).then(() => this.filter());
        } else {
          return this.models.save(() => {
            newDefaultFormatter = {
              reportFormatter: this.models.ReportFormatter.create(result.examType),
              template: null,
              hasChanged: false
            } as IReportFormatterViewModel;
            newDefaultFormatter.reportFormatter.user = null;
            newFormatter = {
              reportFormatter: this.models.ReportFormatter.create(result.examType),
              template: null,
              hasChanged: false
            } as IReportFormatterViewModel;
          })
          .then(() => {
            newDefaultFormatter.reportFormatter.saveTemplate(newDefaultFormatter.reportFormatter.examType.key);
            newFormatter.reportFormatter.saveTemplate(newFormatter.reportFormatter.examType.key);
            this.allFormatters.push(newFormatter);
            this.allFormatters.push(newDefaultFormatter);
            this.filter();
          })
          .catch(() =>
            this.$mdToast.showSimple("There was a problem creating the report template. Please reload and try again."));
        }
      });
  }

  /** Filter when the "show removed" flag is toggled. */
  onShowRemovedChanged(): void {
    this.filter();
  }

  /**
   * Filter formatters based on visibility.
   * Exam types are then filtered based on used and visible formatter types.
   */
  private filter(): void {
    this.filterFormatters();
    this.filterExamTypes();
  }

  /** Filter formatters based on if removed formatters should be visible. */
  private filterFormatters() {
    this.visibleFormatters = this.showRemoved
      ? this.allFormatters.filter(x =>
        x.reportFormatter.user != null &&
        x.reportFormatter.user.id === User.current.id)
      : this.allFormatters.filter(x =>
        x.reportFormatter.user != null &&
        x.reportFormatter.user.id === User.current.id &&
        !x.reportFormatter.isDeleted);
  }

  /** Filter used and unused exam types when there has been a change to formatters. */
  filterExamTypes(): void {
    this.visibleExamTypes =
      this.allFormatters ?
        this.allFormatters
          .filter(x => this.showRemoved || !x.reportFormatter.isDeleted)
          .map(x => x.reportFormatter.examType) : null;

    this.unusedOrInvisibleExamTypes =
      this.allExamTypes && this.visibleExamTypes ?
        this.allExamTypes.filter(x => this.visibleExamTypes.indexOf(x) === -1) : null;
  }
}

const componentOptions: IComponentOptions = {
  controller: ReportFormatterAdminSectionComponentController,
  templateUrl: require("./blueprint-section.component.html")
};

export default ngModule("midas.admin.blueprint.section",
  [filters.name, editor.name, convertDialog.name, createDialog.name])
  .component("blueprintAdminSection", componentOptions);