/** A representation of a circle. */
import { IPointLike } from "./vector2";

export class Circle {
  constructor(public x: number, public y: number, public radius: number) { }

  area() {
    const r = this.radius;
    return Math.PI * r * r;
  }

  circumference() {
    return 2 * Math.PI * this.radius;
  }

  /** Gets whether this circle contains a point. A point exactly on the
   * edge is considered contained.
   * @param p The point to test against this shape. */
  contains(p: IPointLike) {
    const dx = this.x - p.x;
    const dy = this.y - p.y;
    const r = this.radius;
    return (dx * dx + dy * dy) <= (r * r);
  }

  /** Gets the center of this circle. */
  middle(): IPointLike {
    return { x: this.x, y: this.y };
  }

  /** Moves this circle by the provided amount, without changing it's size. */
  translate(p: IPointLike): this {
    this.x += p.x;
    this.y += p.y;
    return this;
  }

  clone() {
    return new Circle(this.x, this.y, this.radius);
  }
}

export default Circle;