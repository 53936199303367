import * as angular from "angular";
import * as moment from "moment";
import { LoadingStatus, HasPendingJobsService, isNullOrWhitespace } from "./utility/utils";
import { find, filter } from "lodash";
import * as breeze from "breeze";
import { GetPdfViewerUrl } from "./pdfjs.module";
import { IRootScopeService, ILogService } from "angular";
import { BreezeStatic } from "./breeze.module";
import { errorsServiceName, IErrorNotificationService, LogLevel } from './utility/errors/errorNotificationService';

// Modules required by the business models module.
import utilityModule from "./utility/utils";
import pdfJsModule from "./pdfjs.module";
import breezeModule from "./breeze.module";
import momentModule from "./moment.module";
import authServiceModule, { IAuthService } from "./authService";

  /** Indicates that the result we're after should be a business model and
   * automatically converts any breeze entities or arrays thereof into business
   * models.
   */
  export function asBusinessModel<T extends Entity>(value: T): BusinessModel<T>;
  /** Indicates that the result we're after should be a business model and
   * automatically converts any breeze entities or arrays thereof into business
   * models.
   */
  export function asBusinessModel<T extends Entity>(value: T[]): BusinessModel<T>[];
  /** Indicates that the result we're after should be a business model and
   * automatically converts any breeze entities or arrays thereof into business
   * models.
   */
  export function asBusinessModel<T>(value: T): T;

  export function asBusinessModel(value: any): any {
    if (value && value.businessModel) {
      return value.businessModel();
    } else if (angular.isArray(value)) {
      return value.map(asBusinessModel);
    }
    return value;
  }

    /** Indicates that the result we're after should be a breeze entity and
   *  automatically converts any business models or arrays thereof into breeze
   *  entities. */
  export function asBreezeEntity<TEntity extends Entity>(value: BusinessModel<TEntity>): TEntity;
  /** Indicates that the result we're after should be a breeze entity and
   *  automatically converts any business models or arrays thereof into breeze
   *  entities. */
  export function asBreezeEntity<TEntity extends Entity>(value: BusinessModel<TEntity>[]): TEntity[];
  /** Indicates that the result we're after should be a breeze entity and
   *  automatically converts any business models or arrays thereof into breeze
   *  entities. */
  export function asBreezeEntity(value: IBusinessModel): Entity;
  /** Indicates that the result we're after should be a breeze entity and
   *  automatically converts any business models or arrays thereof into breeze
   *  entities. */
  export function asBreezeEntity(values: IBusinessModel[]): Entity[];
  /** Indicates that the result we're after should be a breeze entity and
   *  automatically converts any business models or arrays thereof into breeze
   *  entities. */
  export function asBreezeEntity<T>(value: T): T;
  export function asBreezeEntity(value: any): any {
    if (value && value.breezeEntity) {
      return value.breezeEntity();
    } else if (angular.isArray(value)) {
      return value.map(asBreezeEntity);
    }
    return value
  }

type LoadingStatusCtor = typeof LoadingStatus;
type IPromise<T> = angular.IPromise<T>;

export interface Entity extends breeze.Entity {
  id?: number;
  isDeleted?: boolean;
  businessModel?: () => BusinessModel<this>;
}

/** Set of allowed role strings. */
export type RoleName = "Is Administrator" | "Can Edit Final Reports" | "Can See Measurement Keys" | "Read-Only Mode";

/** Base interface for a business model when you know nothing about what it contains. */
export interface IBusinessModel {
  /** Gets the id of this model. */
  id: number;

  /** Gets or sets whether the entity is soft deleted. The setter is obsolete, please remove
   * where it's found in files, and don't add new ones. */
  isDeleted: boolean;
}

/** Base interface for an enum business model when you know nothing about what it contains. */
export interface IEnum extends IBusinessModel {
  key: string;
  orderIndex: number;
}

/** Base class for all business models, providing
 *
 */
export abstract class BusinessModel<TEntity extends Entity> implements IBusinessModel {
  /** Gets the breeze entity backing this model. */
  breezeEntity: () => TEntity;

  /** Gets the id of this model. */
  get id() {
    return this.breezeEntity().id;
  }

  //** Gets if the entity is soft deleted  */
  get isDeleted(): boolean {
      return this.breezeEntity().isDeleted;
  }

  /*  Sets if the entity is soft deleted.
      It would be preferable if we had delete() functions for entities instead of allowing a setter.
      This would allow complex deletion of foreign entities.
      However a lot of the IsDeleted setting is done in CoffeeScript and therefore is not type checked.
      Please remove them as you TypeScript them and do not add any more in the future.
  */
  set isDeleted(value: boolean) {
      this.breezeEntity().isDeleted = value;
  }

  /** Replaces the provided member functions with versions of themselves bound to this.
    * This lets us maintain compatibility with member functions which used fat arrow
    * functions in CoffeeScript.
    * @param members The names of the members to bind. */
  protected bindMembers(...members: (keyof this)[]) {
    for (var member of members) if (typeof this[member] === "function") {
      this[member] = (<Function><any>this[member]).bind(this);
    }
  }

  constructor(breezeEntity: TEntity) {
    if (breezeEntity == null) { throw new Error("breezeEntity is null"); }
    this.breezeEntity = () => breezeEntity;
    breezeEntity.businessModel = () => this;
    this.bindMembers("markForHardDeletion");

    if (breezeEntity.isDeleted == null) {
      breezeEntity.isDeleted = false;
    }
  }

  /** The default entity manager to use for business models. */
  static $defaultManager: breeze.EntityManager;
  /** Gets the manager this entity is attached to. */
  protected get $manager(): breeze.EntityManager {
    const aspect = this.breezeEntity().entityAspect;
    return aspect && aspect.entityManager;
  }

  clearFromCache() { return this.$manager.detachEntity(this.breezeEntity()); }

  protected static getLocalCached<T extends BusinessModel<Entity>>(typeName: string): T[] {
    return <T[]>asBusinessModel(BusinessModel.$defaultManager.getEntities(typeName));
  }

  /** Marks entity to be deleted completely from the database on next saveChanges. */
  markForHardDeletion() { return this.breezeEntity().entityAspect.setDeleted(); }
}

interface IEnumEntity extends Entity { //TODO: Remove when we have real breeze models.
  /** Mapped to key on the business model. */
  masterName: string;
  orderIndex: number;
};

class Enum<TEntity extends IEnumEntity> extends BusinessModel<TEntity> implements IEnum {
  get key() : string { return this.breezeEntity().masterName; }
  get orderIndex() : number { return this.breezeEntity().orderIndex; }
}

//Enums:

export interface IStatusEntity extends IEnumEntity { }; //TODO: Remove when we have real breeze models.
export class Status extends Enum<IStatusEntity> {
  static $cached: Status[];
  static listNow(): Status[] { return Status.$cached; }
  static list(): IPromise<Status[]> { return User.waitForLoaded().then(Status.listNow); }

  get value() {
    switch (this.key) {
      case "New": return Status.Enum.New;
      case "Saved": return Status.Enum.Saved;
      case "Provisional": return Status.Enum.Provisional;
      case "Final": return Status.Enum.Final;
      default: throw Error("Invalid status key: " + (this.key || "<null>"));
    }
  }
}
export namespace Status {
  export enum Enum {
    New,
    Saved,
    Provisional,
    Final
  }
}

export interface IModalityEntity extends IEnumEntity {
  measurementTypes: IMeasurementTypeEntity[];
};
export class Modality extends Enum<IModalityEntity> {

  static $inject = ["breezeEntity", "$q"];
  constructor(breezeEntity: IModalityEntity, private readonly $q: angular.IQService) {
    super(breezeEntity);
  }

  //Hide measurement types behind a function. We don't ever want to change track them by accident
  //as there are likely to be hundred of them!
  getMeasurementTypes = () =>
    asBusinessModel(this.breezeEntity().measurementTypes) as MeasurementType[];


  static listNow(): Modality[] {
    return BusinessModel.getLocalCached<Modality>("Modality");
  }
  static list(): IPromise<Modality[]> {
    return User.waitForLoaded().then(() => Modality.listNow());
  }
  private static cached: null | { [key: string]: boolean; };

  isLoaded = () => Modality.cached && Modality.cached[this.key];

  load = (force: boolean = false): angular.IPromise<void> => {
    //TODO: If we clear the metadata cache, this class will still think the values are cached. Needs fixing.
    if (!force && this.isLoaded()) { return this.$q.when(); }
    //Make sure this is converted to an angular promise.
    return this.$q.when(<any>this.$manager.executeQuery(breeze.EntityQuery.from(`ModuleData/${this.id}`)))
      .then(() => {
        Modality.cached = Modality.cached || {};
        Modality.cached[this.key] = true;
      });
  }
}

/** Maps to the StudyType table on the server side, but ExamType is more accurate so we renamed it here. */
export interface IStudyTypeEntity extends IEnumEntity { //TODO: Remove when we have real breeze models.
  displayName: string;
  reportFormatters: IReportFormatterEntity[];
  modality: IModalityEntity;
  templates: IDiagramTemplateEntity[];
};
export class ExamType extends Enum<IStudyTypeEntity> {
  get display(): string { return this.breezeEntity().displayName; }
  get modality(): Modality {
    return asBusinessModel(this.breezeEntity().modality) as Modality;
  }
  get templates(): DiagramTemplate[] {
    return asBusinessModel(this.breezeEntity().templates) as DiagramTemplate[];
  }
  static find: BusinessModelCtorWithQueries<IStudyTypeEntity, ExamType>["find"];
  static list: BusinessModelCtorWithQueries<IStudyTypeEntity, ExamType>["list"];
  static count: BusinessModelCtorWithQueries<IStudyTypeEntity, ExamType>["count"];

  get reportFormatters(): ReportFormatter[] {
    return asBusinessModel(this.breezeEntity().reportFormatters) as ReportFormatter[];
  }

  static $init(initialiser: ModelInitialiser) {
    initialiser.defineBasicQueries(ExamType, "StudyTypes");
  }

  /** Gets all `ExamType`s with at least one report formatter. (We actually consider multiple
   * formatters to be bad data, but this is still tolerant of that case.) */
  static listActive(): ExamType[] {
    const typeEntities = <IStudyTypeEntity[]>BusinessModel.$defaultManager.getEntities("StudyType");
    return typeEntities
      .filter(x => x.reportFormatters != null && x.reportFormatters.some(x => !x.isDeleted))
      .map(x => x.businessModel() as ExamType);
  }
}


//Domain Models:

interface IPersonTitleTypeEntity extends Entity {}
export class PersonTitleType extends BusinessModel<IPersonTitleTypeEntity> {
  masterName: string;
  displayName: string;  
  
  static find: BusinessModelCtorWithQueries<IPersonTitleTypeEntity, PersonTitleType>["find"];
  static list: BusinessModelCtorWithQueries<IPersonTitleTypeEntity, PersonTitleType>["list"];
  static count: BusinessModelCtorWithQueries<IPersonTitleTypeEntity, PersonTitleType>["count"];

  static $init = ["modelInitialiser", (initialiser: ModelInitialiser) => {
    initialiser.mapGetter(PersonTitleType.prototype, "masterName");
    initialiser.mapGetter(PersonTitleType.prototype, "displayName");
    initialiser.defineBasicQueries(PersonTitleType, "PersonTitleTypes");
  }];
}

interface IPersonEntity extends Entity {
  institute: IInstituteEntity;
  personTitleType: IPersonTitleTypeEntity;
  firstName: string;
  lastName: string;
  dob: Date;
  homePhone: string;
  businessPhone: string;
  mobilePhone: string;
  technicians: ITechnicianEntity[];
  physicians: IPhysicianEntity[];
  users: IUserEntity[];
}

interface IPatientEntity extends Entity { //TODO: Remove when we have real breeze models.
  urNumber: string;
  person: IPersonEntity;
  personId: number;
  documents: any;
  studies: IStudyEntity[];
  duplicateHistory: IPatientDuplicateHistoryEntity[];
}
export class Patient extends BusinessModel<IPatientEntity> {
  urNumber: string;
  institute: Institute;
  personTitleType: PersonTitleType;
  firstName: string;
  lastName: string;
  dob: Date;
  homePhone: string;
  businessPhone: string;
  mobilePhone: string;
  documents: PatientDocument[];

  static find: BusinessModelCtorWithQueries<IPatientEntity, Patient>["find"];
  static list: BusinessModelCtorWithQueries<IPatientEntity, Patient>["list"];
  static count: BusinessModelCtorWithQueries<IPatientEntity, Patient>["count"];

  static $init = ["modelInitialiser", "moment", (initialiser: ModelInitialiser, moment) => {
    initialiser.mapGetterSetter(Patient.prototype, "urNumber");
    initialiser.mapGetterSetter(Patient.prototype, "institute", "person.institute");
    initialiser.mapGetterSetter(Patient.prototype, "personTitleType", "person.personTitleType");
    initialiser.mapGetterSetter(Patient.prototype, "firstName", "person.firstName");
    initialiser.mapGetterSetter(Patient.prototype, "lastName", "person.lastName");
    initialiser.mapGetterSetter(Patient.prototype, "homePhone", "person.homePhone");
    initialiser.mapGetterSetter(Patient.prototype, "businessPhone", "person.businessPhone");
    initialiser.mapGetterSetter(Patient.prototype, "mobilePhone", "person.mobilePhone");
    initialiser.mapGetter(Patient.prototype, "documents");

    initialiser.manualGetterSetter(Patient.prototype, "dob",
          (breezeEntity: IPatientEntity) => breezeEntity.person.dob,
          (breezeEntity, value: Date) => {
              if (value == null)
                  return null;
              const momentLocal = moment(value);
              //This mirrors the native Date parameters, months, hours, minutes, seconds, and milliseconds are all zero indexed. Years and days of the month are 1 indexed.
              //Also, day() returns what day of the week it is (0 = Sunday). date() will return the day of the month.
              //JS dates, man 😨.
              //See: https://momentjs.com/docs/#/parsing/array/
              //Strip off time element of input date
              const momentUtc = moment.utc([momentLocal.year(), momentLocal.month(), momentLocal.date(), 0, 0, 0, 0]);
              const dateUtc = momentUtc.toDate();
              breezeEntity.person.dob = dateUtc;
          });

    initialiser.defineBasicQueries(Patient, "Patients", 'person');
  }];

  static $inject = ["breezeEntity", "$q"];
  constructor(breezeEntity: IPatientEntity, private readonly $q: angular.IQService) {
    super(breezeEntity);
    this.bindMembers("delete", "getStudies");
  }

  delete() {
    const entity = this.breezeEntity();
    entity.isDeleted = true;
    entity.person.isDeleted = true;
  }

  /** Queries the server for the entire duplicate history of this patient. This provides
   * information on other patients which have been merged into this one, and functionality to
   * undo those merges. By default this loads the duplicated (and deleted) patient, and links
   * to the studies which were moved, but not the actual studies. */
  getDuplicateHistory(expand: string[] = ["primary.person", "duplicate.person", "studies"]): IPromise<PatientDuplicateHistory[]> {
    const query = breeze.EntityQuery.from("PatientDuplicateHistory")
        .where('primaryId', '==', this.id)
        .expand(expand);
    return new this.$q<breeze.QueryResult>((resolve, reject) =>
      this.$manager.executeQuery(query, resolve, reject))
          .then(data => <PatientDuplicateHistory[]>asBusinessModel(
              <IPatientDuplicateHistoryEntity[]>data.results));
  }

  /** Queries the server for all studies of this patient. */
  getStudies(): IPromise<Study[]> {
      const query = breeze.EntityQuery.from("Studies")
          .where(breeze.Predicate.create('patientId', '==', this.id).and('isDeleted', '==', 0))
          .expand("patient, modality");
      return new this.$q<breeze.QueryResult>((resolve, reject) =>
      this.$manager.executeQuery(query, resolve, reject))
          .then(data => <Study[]>asBusinessModel(data.results));
  }

  /** Gets the locally cached studies for this patient. */
  getLocalStudies(): Study[] {
      const query = breeze.EntityQuery.from("Studies")
          .where(breeze.Predicate.create('patientId', '==', this.id).and('isDeleted', '==', 0));
      const studyEntities = this.$manager.executeQueryLocally(query);
      return <Study[]>asBusinessModel(studyEntities);
  }

  static create(): IPromise<Patient> {
    return User.waitForLoaded().then(function () {
      let newPatient = <IPatientEntity>BusinessModel.$defaultManager.createEntity('Patient');
      let newPerson = <IPersonEntity>BusinessModel.$defaultManager.createEntity('Person');
      newPerson.institute = asBreezeEntity(User.current.institute);
      newPatient.person = newPerson;
      return <Patient>asBusinessModel(newPatient);
    });
  }
}

export interface IPatientDuplicateHistoryEntity extends Entity {
  id: number;
  /// <summary>The patient which was assigned all of the studies from the duplicate.</summary>
  primary: IPatientEntity;
  primaryId: number;
  /// <summary>The patient which was marked as duplicate and deleted.</summary>
  duplicate: IPatientEntity;
  duplicateId: number;
  /// <summary>The user who took the action which caused this entry to be created.</summary>
  mergedBy: IUserEntity;
  mergedById: number;
  /// <summary>When this entry was created.</summary>
  timestamp: Date;
  /// <summary>The list of studies affected by this duplicate merge.</summary>
  studies: IPatientDuplicateHistoryStudyListEntity[];
}

export interface IPatientDuplicateHistoryStudyListEntity extends Entity {
  id: number;
  /// <summary>The history item this relates to.</summary>
  duplicateHistory: IPatientDuplicateHistoryEntity;
  duplicateHistoryId: number;
  /// <summary>A study that was moved during a merge operation.</summary>
  study: IStudyEntity;
  studyId: number;
}

export class PatientDuplicateHistory extends BusinessModel<IPatientDuplicateHistoryEntity> {
    /// <summary>The patient which was assigned all of the studies from the duplicate.</summary>
    primary: Patient;
    /// <summary>The patient which was marked as duplicate and deleted.</summary>
    duplicate: Patient;
    /// <summary>The user who took the action which caused this entry to be created.</summary>
    mergedBy: User;
    /// <summary>When this entry was created.</summary>
    timestamp: Date;
    /// <summary>Gets the list of studies affected by this duplicate merge.</summary>
    studies: Study[];

    static $init = ["modelInitialiser", (initialiser: ModelInitialiser) => {
        initialiser.mapGetter(PatientDuplicateHistory.prototype, "primary");
        initialiser.mapGetter(PatientDuplicateHistory.prototype, "duplicate");
        initialiser.mapGetter(PatientDuplicateHistory.prototype, "mergedBy");
        initialiser.mapGetter(PatientDuplicateHistory.prototype, "timestamp");
        initialiser.manualGetterSetter(PatientDuplicateHistory.prototype, "studies",
            (entity: IPatientDuplicateHistoryEntity) => entity.studies.map(link => link.study))
    }];

    static $inject = ["breezeEntity", "$injector"];
    /** We do some super late binding here, only getting the duplicate service once somebody
     * actually calls this.undo(). That means these business models can be created without
     * necessarily having a reference to the patient duplicate service. Otherwise pulling in
     * this file means you absolutely have to take dependencies on pretty much every other
     * part of the system. */
    constructor(breezeEntity: IPatientDuplicateHistoryEntity, private readonly $injector: angular.auto.IInjectorService) {
        super(breezeEntity);
    }

    /** Un-deletes the patient which was deleted during this merge, and reassigns all of it's
     * original studies back to it.
     * @returns The un-deleted patient, after it is saved to the database. */
    undo(): IPromise<Patient> {
      //TODO: Type the duplicate service properly once this is a harmony module.
      const duplicateService = this.$injector.get<any>("duplicatePatients");
      if (!duplicateService) {
        throw Error("The patient duplicate service isn't available.");
      }
      return duplicateService.reinstateDuplicate(this);
    }
}

export type ReportFormatterType = "LegacySeederTypeName" | "LegacyJson" | "SelfServe";

export interface IReportFormatterEntity extends Entity { //TODO: Remove when we have real breeze models.
  examType: IStudyTypeEntity;
  examTypeId: number;
  type: ReportFormatterType;
  institute: IInstituteEntity;
  user: IUserEntity;
}

export class ReportFormatter extends BusinessModel<IReportFormatterEntity> {

  get type(): ReportFormatterType { return this.breezeEntity().type; }
  set type(type: ReportFormatterType) { this.breezeEntity().type = type; }

  get examType() {
    return asBusinessModel(this.breezeEntity().examType) as ExamType;
  }
  get user() { return this.breezeEntity().user };

  set user(user: IUserEntity) { this.breezeEntity().user = user; }

  get institute() {
    return asBusinessModel(this.breezeEntity().institute) as Institute;
  }

  static find: BusinessModelCtorWithQueries<IReportFormatterEntity, ReportFormatter>["find"];
  static list: BusinessModelCtorWithQueries<IReportFormatterEntity, ReportFormatter>["list"];
  static count: BusinessModelCtorWithQueries<IReportFormatterEntity, ReportFormatter>["count"];

  static $init = ["modelInitialiser",
    (initialiser: ModelInitialiser) => {
      initialiser.defineBasicQueries(ReportFormatter, "ReportFormatters", "user");
    }];


  static $inject = ["breezeEntity", "$q", "$http"];
  constructor(breezeEntity: IReportFormatterEntity,
    private readonly $q: angular.IQService,
    private readonly $http: angular.IHttpService) {
    super(breezeEntity);
    this.bindMembers("delete", "execute", "loadTemplate");
  }

  execute(template, array) {
    let message = {
      template,
      examTypeId: this.examType.id,
      testDataJson: array
    };
    return this.$http.post("api/midas/ExecuteFormatReport/", message);
  }

  delete() { (asBreezeEntity(this)).entityAspect.setDeleted(); }

  static listNow(): ReportFormatter[] {
    return BusinessModel.getLocalCached<ReportFormatter>("ReportFormatter");
  }

  static create(examType: ExamType, type: ReportFormatterType = "SelfServe"): ReportFormatter {
    const entity = BusinessModel.$defaultManager.createEntity("ReportFormatter") as IReportFormatterEntity;
    entity.examType = examType.breezeEntity();
    entity.institute = Institute.current.breezeEntity();
    entity.type = type;
    entity.user = User.current.breezeEntity();
    return BusinessModelService.asBusinessModel(entity) as ReportFormatter;
  }

  private _template: string = undefined;

  /** Gets the actual template string for this formatter. Once loaded, a cached version is stored
   * and returned on subsequent calls. */
  loadTemplate(forceRefresh: boolean = false): IPromise<string> {
    if (this._template != null && !forceRefresh) {
      return this.$q.when(this._template);
    } else {
      return this.$http.get(`api/midas/ReportFormatterTemplates/${this.id}`)
        .then(result => {
          this._template = result.data as string;
          return this._template;
        })
    }
  }

  /** Makes a server call to store the provided string as the template of this report formatter. The
   * locally cached version is also updated if the call succeeds. */
  saveTemplate(reportTemplate: string): IPromise<string> {
    return this.$http.post(`api/midas/upsertReportTemplate/${this.id}`, { reportTemplate })
      .then(result => {
          this._template = result.data as string;
          return this._template;
        })
  }

  /** Clears the cache of this formatter, forcing it to be reloaded from the server when next it is
   * needed. */
  uncacheTemplate(): void {
    this._template = null;
  }
}

export interface ICongruenceEntity extends Entity {
  variation: ICongruenceVariationEntity;
  comment: string;
};

export interface ICongruenceVariationEntity extends Entity {
  masterName: string;
  displayName: string;
};

export interface IStudyEntity extends Entity { //TODO: Remove when we have real breeze models.
  patient: IPatientEntity;
  patientId: number;
  exams: Array<IExamEntity>;
  institute: IInstituteEntity;
  charts: Array<IStudyChartEntity>;
  documents: Array<any>;
  modality: IModalityEntity;
  physician: IPhysicianEntity;
  technician: ITechnicianEntity;
  secondaryTechnician: ITechnicianEntity;
  reportedAt: Date;
  status: IStatusEntity;
  notes: Array<IStudyNoteEntity>;
}

export class Study extends BusinessModel<IStudyEntity> {
  patient: Patient;
  exams: Array<Exam>;
  institute: Institute;
  charts: Array<StudyChart>;
  documents: StudyDocument[];
  jsonDownloadPath: string;
  downloadPath: string;
  modality: Modality;
  physician: Physician;
  technician: Technician;
  secondaryTechnician: Technician;
  referrer: string;
  referrerAddress: string;
  referrerProviderNo: string;
  copyTo: string;
  site: string;
  siteEntity: Site;
  status: Status;
  reportedAt: Date;
  hasDirtyReport: boolean;
  hasDirtyDiagram: boolean;
  notes: Array<StudyNote>;
  dicomDataSourceId: number;

  /**
   * Accessor for the first exam. In the system currently (Apr 2018), this reflects the practical cardinality.
   */
  get exam(): Exam {
    return this.exams.length === 0 ? null : this.exams[0];
  }

  static find: BusinessModelCtorWithQueries<IStudyEntity, Study>["find"];
  static list: BusinessModelCtorWithQueries<IStudyEntity, Study>["list"];
  static count: BusinessModelCtorWithQueries<IStudyEntity, Study>["count"];
  private static $http: angular.IHttpService;
  private static $q: angular.IQService;

  static $init = ["modelInitialiser", "$http", "$q",
    (initialiser: ModelInitialiser, $http: angular.IHttpService, $q: angular.IQService) => {
    Study.$http = $http;
    Study.$q = $q;

    initialiser.manualCachedGetter(Study.prototype, "jsonDownloadPath", breezeEntity => `api/midas/studyJson/${breezeEntity.id}`);
    initialiser.manualCachedGetter(Study.prototype, "downloadPath", breezeEntity => "api/midas/studyDownload");

    initialiser.mapGetterSetter(Study.prototype, "patient");
    initialiser.mapGetterSetter(Study.prototype, "modality");
    initialiser.mapGetterSetter(Study.prototype, "physician");
    initialiser.mapGetterSetter(Study.prototype, "technician");
    initialiser.mapGetterSetter(Study.prototype, "secondaryTechnician");
    initialiser.mapGetter(Study.prototype, "exams");
    initialiser.mapGetter(Study.prototype, "documents");
    initialiser.mapGetter(Study.prototype, "charts");
    initialiser.mapGetterSetter(Study.prototype, "institute");
    initialiser.mapGetterSetter(Study.prototype, "referrer");
    initialiser.mapGetterSetter(Study.prototype, "referrerAddress");
    initialiser.mapGetterSetter(Study.prototype, "referrerProviderNo");
    initialiser.mapGetterSetter(Study.prototype, "copyTo");
    initialiser.mapGetterSetter(Study.prototype, "site");
    initialiser.mapGetterSetter(Study.prototype, "siteEntity");
    initialiser.mapGetterSetter(Study.prototype, "status");
    initialiser.mapGetter(Study.prototype, "dicomDataSourceId");

    initialiser.manualGetterSetter(Study.prototype, "reportedAt", (breezeEntity: IStudyEntity) => breezeEntity.reportedAt,
      (breezeEntity, value) => breezeEntity.reportedAt = moment(value).toDate());
    initialiser.manualGetterSetter(Study.prototype, "hasDirtyReport", (breezeEntity: IStudyEntity) =>
      breezeEntity.exams.some(exam => (<Exam>asBusinessModel(exam)).hasDirtyReport));

    initialiser.manualGetterSetter(Study.prototype, "hasDirtyDiagram", (breezeEntity: IStudyEntity) =>
      breezeEntity.exams.some(exam => (<Exam>asBusinessModel(exam)).hasDirtyDiagram));

    initialiser.manualGetterSetter(Study.prototype, "notes", (breezeEntity: IStudyEntity) =>
      breezeEntity.notes.filter(x => !x.isDeleted));

    initialiser.defineBasicQueries(Study, "Studies", "patient.person, modality, exams.studyType.modality, status");
  }];

  static $inject = ["breezeEntity", "$q", "saveQueue", "hasPendingJobs", "getPdfViewerUrl"]; 
  constructor(
    breezeEntity: IStudyEntity,
    private $q: angular.IQService,
    private $saveQueue: SaveQueue,
    private $hasPendingJobs: HasPendingJobsService,
    private getPdfViewerUrl: GetPdfViewerUrl
  ) {
    super(breezeEntity);
    this.bindMembers("delete", "saveAsSaved", "saveAsProvisional", "saveAsFinal",
      "changeStatusAndOutput", "changeStatus", "userCanEdit", "reimport", "createExam",
      "recreateCharts", "getReportPreview");
  }

  reportPreviewUrl() { return this.getPdfViewerUrl(`api/midas/reportpreview/${this.id}`); }

  //Reverting to 'Saved' from Provisional should not trigger a study output as it is a backwards step in the workflow.
  saveAsSaved() { return this.changeStatusAndOutput("Saved", false); }
  saveAsProvisional(output: boolean = true) { return this.changeStatusAndOutput("Provisional", output); }
  saveAsFinal(output: boolean = true) { return this.changeStatusAndOutput("Final", output); }

  changeStatusAndOutput(statusKey, output: boolean = true) {
    let currentStatusKey = this.status.key;
    const updateStatus = false, newStatus = statusKey, oldStatus = currentStatusKey, reason = "Updated from study interface";
    return this.$saveQueue.save(() => this.changeStatus(statusKey))
      .then(() => Study.$http.post(`api/midas/LogStudyStatusUpdate/${this.id}`, { updateStatus, newStatus, oldStatus, reason }))
      .then(() => {
        if (output) {
          const query = breeze.EntityQuery.from(`sendstudyoutputs/${this.id}`);
          query.queryOptions = <breeze.QueryOptions>{
            ...breeze.QueryOptions.defaultInstance,
            includeDeleted: true
          };
          return BusinessModel.$defaultManager.executeQuery(query);
        }
      })
      .catch(e => {
        this.$saveQueue.save(() => this.changeStatus(currentStatusKey));
        throw e;
      });
  }

  changeStatus(statusKey: string): IPromise<{ newValue: Status, oldValue: Status }> {
    return Status.list().then(statuses => {
      let previousStatus = this.status;
      let foundStatus = find(statuses, status => status.key === statusKey);
      if (foundStatus != null) {
        this.status = foundStatus;
        return this.$q.when({ newValue: foundStatus, oldValue: previousStatus });
      } else {
        return <IPromise<{ newValue: Status, oldValue: Status }>>
          this.$q.reject(`Cannot find '${statusKey}' status object in cache`);
      }
    });
  }

  //Static function to get the report preview for a modality or modality id.
  static getReportPreview(study: Study | IStudyEntity | number) {
    let id = angular.isNumber(study) ? study : study.id;
    return Study.$http.get(`api/midas/ReportPreview/${id}`).then(result => result.data);
  }
  getReportPreview() { return Study.getReportPreview(this); }

  userCanEdit() {
    if (this.status.key !== "Final") { return true; }
    let hasRole = false;
    if (User.current == null) return hasRole;
    for (let role of User.current.roles) {
      if (role.name === "Can Edit Final Reports") {
        hasRole = true;
        break;
      }
    }
    return hasRole;
  }

  delete() {
    let be = this.breezeEntity();
    be.isDeleted = true;
    for (let exam of be.exams) { exam.isDeleted = true; }
    return undefined;
  }

  reimport(force: boolean = false): IPromise<any> {
    if (this.dicomDataSourceId == null && !force) {
      return Study.$q.when();
    }
    return Study.$http.post(`api/midas/reimport/${this.id}`, {});
  }

  createExam(examType: ExamType, performedAt: Date, uID: string = null): Exam {
      if (!performedAt) { throw Error("Exam Date is not supplied.")}
      let entity = <IExamEntity>this.$manager.createEntity("Exam");
      entity.uID = uID;
      let newExam = <Exam>asBusinessModel(entity);
      newExam.type = examType;
      newExam.title = examType.key;
      newExam.study = this;
      newExam.performedAt = performedAt;
      this.breezeEntity().exams.push(asBreezeEntity(newExam));
    return newExam;
  }

  recreateCharts(reload = true) {
    //Mark and save all existing charts as deleted, as recreate will make new ones
    return this.$saveQueue.save(() => this.charts.map((chart) => chart.markForHardDeletion()))
      .then(() => Study.$http.post(`api/midas/recreateCharts/${this.id}`, {}))
      //Reload if asked, as some places will be reloading anyway
      .then(() => { if (reload) { return Study.find({ id: this.id }, "charts.image"); } });
  }

  createDocument(localPath, contentType): StudyDocument {
    let doc = this.$manager.createEntity('StudyDocument', { localPath, contentType, study: this.breezeEntity() });
    return <StudyDocument>asBusinessModel(doc);
  }

  static create(reportedAt, patient, modality, status, physician, technician) {
    return User.waitForLoaded().then(function () {
      let newStudy = <Study>asBusinessModel(BusinessModel.$defaultManager.createEntity('Study'));
      if (reportedAt != null) { newStudy.reportedAt = reportedAt; }
      if (patient != null) { newStudy.patient = patient; }
      if (modality != null) { newStudy.modality = modality; }
      if (physician != null) { newStudy.physician = physician; }
      if (technician != null) { newStudy.technician = technician; }
      if (status != null) { newStudy.status = status; }
      newStudy.institute = User.current.institute;
      return newStudy;
    });
  }

  reloadImages(): angular.IPromise<any> {
      this.$hasPendingJobs.hintExpectingJobs(this.id);
      const query = breeze.EntityQuery.from(`ReloadImages/${this.breezeEntity().id}`);
      return this.$q
          .when<{ results: Entity[] }>(BusinessModel.$defaultManager.executeQuery(query) as any)
          .then(response => {
              response.results.forEach(r => r.entityAspect.setDetached())
          });
  }

  download(contentType: string): angular.IHttpPromise<any[]> {
    //We cannot just provide the URL to the file, we must use $http and return the content as we
    //rely on http auth headers to keep things secure
    return Study.$http.get(`api/midas/StudyDownload/${this.id}/?studyOutputType=${contentType}`,
      { //To receive and write to a blob on the client side, it must set the responseType to
        //"arraybuffer"
      "responseType": "arraybuffer"
            });
    }

  createNote(content?: string): angular.IPromise<StudyNote> {
      return StudyNote.create(this).then((note) => {
          note.content = content;
          return note;
      });
  }

  private _hasCogruence: boolean = false;

  get hasCongruence(): boolean {
    return this._hasCogruence;
  }
  
  addReportCongruence(id: number, comment: string): IPromise<any> {
    return Study.$http.post(`api/midas/upsertReportCongruence/${this.id}`, { id, comment });
  }

  loadReportCongruence(): IPromise<ICongruenceEntity> {
    const query = breeze.EntityQuery
      .from("ReportCongruences")
      .where(breeze.Predicate
        .create("studyId", "==", this.breezeEntity().id)
        .and("isDeleted", "==", 0))
      .take(1);
    return new this.$q<breeze.QueryResult>((resolve, reject) =>
      Study.$defaultManager.executeQuery(query, resolve, reject))
      .then(data => {
        if(data == null || data.results.length == 0) {
         this._hasCogruence = false;
          return null;
        }
        this._hasCogruence = true;
        return <ICongruenceEntity> {
          variation: <ICongruenceVariationEntity>{
            id: (<any>data.results[0]).congruenceVariation.id,
            masterName: (<any>data.results[0]).congruenceVariation.masterName,
            displayName: (<any>data.results[0]).congruenceVariation.displayName
          },
          comment: (<any>data.results[0]).comment
        }
      });
  }

  loadCongruenceVariations(): IPromise<ICongruenceVariationEntity[]> {
    const query = breeze.EntityQuery.from("CongruenceVariations");
    return new this.$q<breeze.QueryResult>((resolve, reject) =>
      Study.$defaultManager.executeQuery(query, resolve, reject))
      .then(data => {
        if(data == null || data.results.length == 0) { return null }
        return data.results.map(x => { 
          return {
            id: (<any>x).id,
            masterName: (<any>x).masterName,
            displayName: (<any>x).displayName
          } as ICongruenceVariationEntity })
      });
  }
}

export interface IExamEntity extends Entity { //TODO: Remove when we have real breeze models.
  performingPractice: string;
  title: string;
  performedAt: Date;
  accessionNumber: string;
  diagrams: IDiagramEntity[];
  studyType: IStudyTypeEntity;
  preferredTitle: string;
  uID: string;
  reportFormatter: IReportFormatterEntity;
  imageLinks: { exam: IExamEntity, image: IImageEntity }[];
  jobs: { exam: IExamEntity, job: IJobEntity }[];
  study: IStudyEntity;
  measurementValues: IMeasurementValueEntity[];
  isDirty: boolean;
}
export class Exam extends BusinessModel<IExamEntity> {
  get type(): ExamType {
    return BusinessModelService.asBusinessModel(this.breezeEntity().studyType) as ExamType;
  }
  set type(value: ExamType) {
    const breezeEntity = this.breezeEntity();
    breezeEntity.studyType = value == null ? null : value.breezeEntity();
    if (breezeEntity.studyType != null) {
      breezeEntity.title = breezeEntity.studyType.masterName;
    }
  }
  get uID(): string { return this.breezeEntity().uID; }

  get diagrams(): Diagram[] { return asBusinessModel(this.breezeEntity().diagrams) as Diagram[]; }

  get reportFormatter(): ReportFormatter {
    return asBusinessModel(this.breezeEntity().reportFormatter) as ReportFormatter;
  }
  set reportFormatter(value: ReportFormatter) {
    this.breezeEntity().reportFormatter = BusinessModelService.asBreezeEntity(value);
  }

  get performingPractice(): string { return this.breezeEntity().performingPractice; }
  set performingPractice(value: string) { this.breezeEntity().performingPractice = value; }
  get title(): string { return this.breezeEntity().title; }
  set title(value: string) { this.breezeEntity().title = value; }
  get performedAt(): Date { return this.breezeEntity().performedAt; }
  set performedAt(value: Date) { this.breezeEntity().performedAt = value; }
  get accessionNumber(): string { return this.breezeEntity().accessionNumber; }
  set accessionNumber(value: string) { this.breezeEntity().accessionNumber = value; }
  get preferredTitle(): string { return this.breezeEntity().preferredTitle; }
  set preferredTitle(value: string) { this.breezeEntity().preferredTitle = value; }
  get images(): Image[] {
    return asBusinessModel(this.breezeEntity().imageLinks.map(link => link.image)) as Image[];
  }
  get jobs(): Job[] {
    return asBusinessModel(this.breezeEntity().jobs.map(link => link.job)) as Job[];
  }
  get study(): Study { return asBusinessModel(this.breezeEntity().study) as Study; }
  set study(value: Study) { this.breezeEntity().study = asBreezeEntity(value); }

  get hasDirtyReport(): boolean {
    return this.breezeEntity().isDirty;
  }
  get hasDirtyDiagram(): boolean {
    const diagrams = this.breezeEntity().diagrams;
    return diagrams && diagrams.some(diagram => !diagram.isDeleted && diagram.isRuleElementsDirty);
  }
  get hasPendingImages(): boolean {
    const images = this.breezeEntity().imageLinks;
    return images && images.some(link => {
      const img = link.image;
      return img && !img.isDeleted && img.isPending;
    });
  }

  get isDirty(): boolean { return this.breezeEntity().isDirty; }
  set isDirty(value: boolean) { this.breezeEntity().isDirty = value; }

  static find: BusinessModelCtorWithQueries<IExamEntity, Exam>["find"];
  static list: BusinessModelCtorWithQueries<IExamEntity, Exam>["list"];
  static count: BusinessModelCtorWithQueries<IExamEntity, Exam>["count"];

  static $init = ["modelInitialiser", function (initialiser: ModelInitialiser) {
    initialiser.defineBasicQueries(Exam, "Exams", 'studyType');
  }];

  static $inject = ["breezeEntity", "$http", "$q"];
  constructor(
    breezeEntity: IExamEntity,
    private readonly $http: angular.IHttpService,
    private readonly $q: angular.IQService
  ) {
    super(breezeEntity);
    this.bindMembers("getMeasurements", "createDiagram", "newMeasurement");
  }

  //Hide measurements behind a function. We don't ever want to change track them by accident
  //as there are likely to be quite a number, and they're not needed by most areas.
  getMeasurements() {
    return <MeasurementValue[]>asBusinessModel(this.breezeEntity().measurementValues);
  }

  /**Creates a new conclusion object for this exam with the provided report text and adds it to the
   * list of conclusions for this exam, making it the current conclusion. */
  addConclusion(reportText: string): IPromise<any> {
    return this.$http.post(`api/midas/addConclusion/${this.id}`, { reportText })
      .then(() => { Study.find({ id: this.study.id })});
  }

  /** Creates a new diagram for this exam from the provided template. */
  createDiagram(template: DiagramTemplate) { return template.createDiagram(this); }

  /** Creates a new measurement value bound to this exam. Doesn't validate for
   * duplicates though!!! Should add that now that we always load the entire study. */
  newMeasurement(type: MeasurementType, value: string) {
    if (value == null) { value = null; }
    let entity = <IMeasurementValueEntity>this.$manager.createEntity('MeasurementValue');
    entity.measurementType = asBreezeEntity(type);
    entity.exam = this.breezeEntity();
    if (value != null) { entity.value = value; }
    return <MeasurementValue>asBusinessModel(entity);
  }

  loadLatestConclusion(): IPromise<string> {
    const query = breeze.EntityQuery
      .from("Conclusions")
      .where(breeze.Predicate.create("examId", "==", this.breezeEntity().id).and("isDeleted", "==", 0))
      .orderByDesc("createdAt")
      .take(1);
    return new this.$q<breeze.QueryResult>((resolve, reject) =>
      Exam.$defaultManager.executeQuery(query, resolve, reject))
      .then(data => {
        if(data == null || data.results.length == 0) {
          return null;
        }
        return <string>(<any>data.results[0]).report;
      });
  }

  loadLatestStatusConclusion(status: number): IPromise<string> {
    const query = breeze.EntityQuery
      .from("Conclusions")
      .where(breeze.Predicate.create("examId", "==", this.breezeEntity().id)
        .and("isDeleted", "==", 0)
        .and("statusId", "==", status))
      .orderByDesc("createdAt")
      .take(1);
    return new this.$q<breeze.QueryResult>((resolve, reject) =>
        Exam.$defaultManager.executeQuery(query, resolve, reject))
      .then(data => {
        if (data == null || data.results.length == 0) {
          return null;
        }
        return <string>(<any>data.results[0]).report;
      });
  }

}

interface IStudyChartEntity extends Entity { //TODO: Remove when we have real breeze models.
  chartKey: string;
  image: IImageEntity;
}

export class StudyChart extends BusinessModel<IStudyChartEntity> {
  key: string;
  image: Image;
  static $init(initialiser: ModelInitialiser) {
    initialiser.mapGetter(StudyChart.prototype, "key", "chartKey");
    initialiser.mapGetter(StudyChart.prototype, "image");
  }
}

interface IDiagramEntity extends Entity { //TODO: Remove when we have real breeze models.
  isRuleElementsDirty: boolean;
  elements: IDiagramElementEntity[];
  template: IDiagramTemplateEntity;
  exam: IExamEntity;
}
export class Diagram extends BusinessModel<IDiagramEntity> {
  private _snapshotTimestamp: string = moment().format();
  isRuleElementsDirty: boolean;
  elements: DiagramElement[];
  name: string;
  height: string;
  width: number;
  orderIndex: number;
  template: DiagramTemplate;
  exam: Exam;
  imagePath: string;
  thumbnailPath: string;

  static find: BusinessModelCtorWithQueries<IDiagramEntity, Diagram>["find"];
  static list: BusinessModelCtorWithQueries<IDiagramEntity, Diagram>["list"];
  static count: BusinessModelCtorWithQueries<IDiagramEntity, Diagram>["count"];

  static $init(initialiser: ModelInitialiser) {
    initialiser.mapGetterSetter(Diagram.prototype, "isRuleElementsDirty");
    initialiser.mapGetter(Diagram.prototype, "elements");
    initialiser.mapGetter(Diagram.prototype, "name", "template.name");
    initialiser.mapGetter(Diagram.prototype, "height", "template.height");
    initialiser.mapGetter(Diagram.prototype, "width", "template.width");
    initialiser.mapGetter(Diagram.prototype, "orderIndex", "template.orderIndex");
    initialiser.mapGetter(Diagram.prototype, "template");
    initialiser.mapGetter(Diagram.prototype, "exam");
    initialiser.manualGetterSetter(Diagram.prototype, "imagePath", function (this: Diagram, breezeEntity: IDiagramEntity) {
      return `api/midas/diagramsnapshot/${breezeEntity.id}?${this._snapshotTimestamp}`;
    });
    initialiser.manualGetterSetter(Diagram.prototype, "thumbnailPath", function (this: Diagram, breezeEntity: IDiagramEntity) {
      return `api/midas/diagramsnapshot/${breezeEntity.id}?${this._snapshotTimestamp}`;
    });
    initialiser.defineBasicQueries(Diagram, "Diagrams", "template.studyType, exam.study");
  }

  static $inject = ["breezeEntity", "$http"];
  constructor(breezeEntity: IDiagramEntity, private $http: angular.IHttpService) {
    super(breezeEntity);
    this.bindMembers("updateSnapshot", "clearElements", "addElement");
  }

  updateSnapshot(encodedImage, format) {
    let message = {
      id: this.id,
      encodedImage,
      format
    };
    return this.$http.post("api/midas/UpdateExamDiagramSnapshot/", message)
      .then(() => this._snapshotTimestamp = moment().format());
  }

  clearElements() {
    for (let element of this.elements) { element.delete(); }
  }
  addElement(type: string, properties: string, isAutoGenerated = false, orderIndex = 0) {
    let element = <IDiagramElementEntity>this.$manager.createEntity("DiagramElement", { type, properties, isAutoGenerated, orderIndex });
    (asBreezeEntity(this)).elements.push(element);
    return <DiagramElement>asBusinessModel(element);
  }
}

interface IInstituteDiagramTemplateLink extends Entity { //TODO: Remove when we have real breeze models.
  instituteId: number;
  institute: IInstituteEntity;
  diagramTemplateId: number;
  diagramTemplate: IDiagramTemplateEntity;
}

interface IDiagramTemplateEntity extends Entity { //TODO: Remove when we have real breeze models.
  name: string;
  height: number;
  width: number;
  orderIndex: number;
  studyType: IStudyTypeEntity;
  ruleElements: IRuleDiagramElementEntity[];
  institutes: IInstituteDiagramTemplateLink[];
}
export class DiagramTemplate extends BusinessModel<IDiagramTemplateEntity> {
  name: string;
  height: number;
  width: number;
  orderIndex: number;
  examType: ExamType;
  ruleElements: RuleDiagramElement[];
  imagePath: string;

  static find: BusinessModelCtorWithQueries<IDiagramTemplateEntity, DiagramTemplate>["find"];
  static list: BusinessModelCtorWithQueries<IDiagramTemplateEntity, DiagramTemplate>["list"];
  static count: BusinessModelCtorWithQueries<IDiagramTemplateEntity, DiagramTemplate>["count"];
  private static $log: angular.ILogService;
    static $init = ["modelInitialiser", "$log", (initialiser: ModelInitialiser, $log: angular.ILogService) => {
    DiagramTemplate.$log = $log;
    initialiser.mapGetterSetter(DiagramTemplate.prototype, "name");
    initialiser.mapGetter(DiagramTemplate.prototype, "height");
    initialiser.mapGetter(DiagramTemplate.prototype, "width");
    initialiser.mapGetter(DiagramTemplate.prototype, "orderIndex");
    initialiser.mapGetter(DiagramTemplate.prototype, "examType", "studyType");
    initialiser.mapGetter(DiagramTemplate.prototype, "ruleElements");
    initialiser.manualGetterSetter(DiagramTemplate.prototype, "imagePath",
      (breezeEntity: IDiagramTemplateEntity) => `api/midas/diagramtemplate/${breezeEntity.id}`);
    initialiser.defineBasicQueries(DiagramTemplate, "DiagramTemplates", "institutes");
  }];

  static $inject = ["breezeEntity"];
  constructor(breezeEntity: IDiagramTemplateEntity) {
    super(breezeEntity);
    this.bindMembers("createDiagram", "deleteInstituteLink");
  }

  //Creates a new diagram from this template for the provided exam.
  createDiagram(exam: Exam) {
    let diagram = <IDiagramEntity>this.$manager.createEntity("Diagram", { template: asBreezeEntity(this) });
    diagram.exam = asBreezeEntity(exam);
    return <Diagram>asBusinessModel(diagram);
  }

  static listCached() {
    if (arguments.length !== 0) {
      DiagramTemplate.$log.error("DiagramTemplate.list does not accept filter arguments. Consider doing filtering on caller's side");
    }

    return BusinessModel.getLocalCached<DiagramTemplate>("DiagramTemplate");
  }

  deleteInstituteLink() {
    let institutesForTemplate = (asBreezeEntity(this)).institutes;
    let instituteTemplateLink = find(institutesForTemplate, inst => inst.instituteId === Institute.current.id);
    return instituteTemplateLink.entityAspect.setDeleted();
  }
}

interface IDiagramElementEntity extends Entity { //TODO: Remove when we have real breeze models.
  id: number;
  properties: string;
  type: string;
  isAutoGenerated: boolean;
  orderIndex: number;
}
export class DiagramElement extends BusinessModel<IDiagramElementEntity> {
  id: number;
  properties: string;
  type: string;
  isAutoGenerated: boolean;
  orderIndex: number;

  static $init(initialiser: ModelInitialiser) {
    initialiser.mapGetter(DiagramElement.prototype, "id");
    initialiser.mapGetterSetter(DiagramElement.prototype, "properties");
    initialiser.mapGetterSetter(DiagramElement.prototype, "type");
    initialiser.mapGetterSetter(DiagramElement.prototype, "isAutoGenerated");
    initialiser.mapGetterSetter(DiagramElement.prototype, "orderIndex");
  }
  constructor(breezeEntity: IDiagramElementEntity) {
    super(breezeEntity);
    this.bindMembers("delete");
  }
  delete() { return (asBreezeEntity(this)).entityAspect.setDeleted(); }
}

type ImageStreamType = "video/mp4";
interface IImageEntity extends Entity { //TODO: Remove when we have real breeze models.
  title: string;
  isMovie: boolean;
  isPending: boolean;
  createdAt: Date;
  thumbnailPath: string;
  links: any[];
  canPurge: boolean;
}
export class Image extends BusinessModel<IImageEntity> {
  title: string;
  isMovie: boolean;
  isPending: boolean;
  createdAt: Date;
  canPurge: boolean;
  streamSources: {
    src: any, //Not a string due to using the strict contextual escaping service.
    type: ImageStreamType;
  }[];
  isIncludedInReport: boolean;
  imageKey: string;

  static $init = ["modelInitialiser", "$sce", (initialiser: ModelInitialiser, $sce: angular.ISCEService) => {
    initialiser.mapGetterSetter(Image.prototype, "title");
    initialiser.mapGetter(Image.prototype, "isMovie");
    initialiser.mapGetter(Image.prototype, "isPending");
    initialiser.mapGetter(Image.prototype, "createdAt");
    initialiser.mapGetterSetter(Image.prototype, "canPurge");
    initialiser.manualCachedGetter(Image.prototype, "streamSources", (breezeEntity: IImageEntity) =>
      [
        {
          src: <any>$sce.trustAsResourceUrl(`api/stream/video/${breezeEntity.id}`),
          type: <ImageStreamType>"video/mp4"
        }
      ]
    );

    initialiser.mapGetterSetter(Image.prototype, "isIncludedInReport");
    initialiser.mapGetterSetter(Image.prototype, "imageKey");

  }];

  get imagePath(): string {
    if (this.authService.getUser())
      return `api/midas/imagedata/${this.id}?access_token=${this.authService.getUser().bearerToken}`;
  }

  get thumbnailPath(): string {
    if (this.authService.getUser())
      return `api/midas/thumbnaildata/${this.id}?access_token=${this.authService.getUser().bearerToken}`;
  }

  static $inject = ["breezeEntity", "authService"];
  constructor(breezeEntity: IImageEntity, private readonly authService: IAuthService) {
    super(breezeEntity);
    this.bindMembers("delete");
  }

  delete() {
    const entity = asBreezeEntity(this);
    for (let link of entity.links) { link.entityAspect.setDeleted(); }
    entity.entityAspect.setDeleted();
  }
}

interface IPhysicianEntity extends Entity { //TODO: Remove when we have real breeze models.
  providerNumber: string;
  position: string;
  qualifications: string;
  person: IPersonEntity;
  studies: IStudyEntity[];
}
export class Physician extends BusinessModel<IPhysicianEntity> {
  providerNumber: string;
  position: string;
  qualifications: string;
  personTitleType: PersonTitleType;
  firstName: string;
  lastName: string;
  studies: Study[];
  personId: number;
  users: User[];

  static $init(initialiser: ModelInitialiser) {
    initialiser.mapGetterSetter(Physician.prototype, "providerNumber");
    initialiser.mapGetterSetter(Physician.prototype, "position");
    initialiser.mapGetterSetter(Physician.prototype, "qualifications");
    initialiser.mapGetterSetter(Physician.prototype, "personTitleType", "person.personTitleType");
    initialiser.mapGetterSetter(Physician.prototype, "firstName", "person.firstName");
    initialiser.mapGetterSetter(Physician.prototype, "lastName", "person.lastName");
    initialiser.mapGetter(Physician.prototype, "personId", "person.id");
    initialiser.mapGetter(Physician.prototype, "studies");
    initialiser.mapGetter(Physician.prototype, "users", "person.users");
  };

  static list(includeDeleted = false) {
    return User.waitForLoaded().then(() =>
      BusinessModel.getLocalCached<Physician>("Physician")
        .filter((p) => includeDeleted || (p.isDeleted !== true)).map((p) => p));
  }
  static create(person: IPersonEntity): Physician {
    let invalidParamError = new Error("Must pass a person to link with new Physician when creating");
    if (!person) { throw invalidParamError; }
    let physician: IPhysicianEntity = null;
    //may be a soft-deleted physician in there
    if (person.physicians.length > 0) {
      physician = person.physicians[0];
      physician.isDeleted = false;
    } else {
      physician = <IPhysicianEntity>BusinessModel.$defaultManager.createEntity("Physician");
      physician.person = person;
    }
    return <Physician>asBusinessModel(physician);
  }

  static listFilteredBySite(site: Site, includeDeleted = false) {
    if (site == null || site.users.length == 0) return this.list();
    return User.waitForLoaded()
      .then(() => {
        var physList = BusinessModel.getLocalCached<Physician>("Physician")
        .filter(p => includeDeleted || p.isDeleted !== true)
        .filter(p => p.users.some(u => u.sites.some(s => s.id === site.id)) || p.users.every(u => u.sites.every(s => s == null)))
        .map(p => p);
        return physList.length > 0 ? physList : this.list();
      });
  }
}

interface ITechnicianEntity extends Entity { //TODO: Remove when we have real breeze models.
  qualifications: string;
  person: IPersonEntity;
  studies: IStudyEntity[];
}
export class Technician extends BusinessModel<ITechnicianEntity> {
  qualifications: string;
  personTitleType: PersonTitleType;
  firstName: string;
  lastName: string;
  studies: Study[];
  personId: number;
  users: User[];

  static $init(initialiser: ModelInitialiser) {
    initialiser.mapGetterSetter(Technician.prototype, "qualifications");
    initialiser.mapGetterSetter(Technician.prototype, "personTitleType", "person.personTitleType");
    initialiser.mapGetterSetter(Technician.prototype, "firstName", "person.firstName");
    initialiser.mapGetterSetter(Technician.prototype, "lastName", "person.lastName");
    initialiser.mapGetter(Technician.prototype, "personId", "person.id");
    initialiser.mapGetter(Technician.prototype, "studies");
    initialiser.mapGetter(Technician.prototype, "users", "person.users");
  }

  static list(includeDeleted = false): IPromise<Technician[]> {
    return User.waitForLoaded().then(() => BusinessModel.getLocalCached<Technician>("Technician")
      .filter((t: Technician) => includeDeleted || (t.isDeleted !== true)));
  }

  static create(person: IPersonEntity): Technician {
    let invalidParamError = new Error("Must pass a person to link with new Technician when creating");
    if (!person) { throw invalidParamError; }

    let technician: ITechnicianEntity = null;
    //may be a soft-deleted technician in there
    if (person.technicians.length > 0) {
      technician = person.technicians[0];
      technician.isDeleted = false;
    } else {
      technician = <ITechnicianEntity>BusinessModel.$defaultManager.createEntity("Technician");
      technician.person = person;
    }
    return <Technician>asBusinessModel(technician);
  }

  static listFilteredBySite(site: Site, includeDeleted = false) {
    if (site == null || site.users.length == 0) return this.list();
    return User.waitForLoaded()
      .then(() => {
        var techList = BusinessModel.getLocalCached<Technician>("Technician")
        .filter(p => includeDeleted || p.isDeleted !== true)
        .filter(p => p.users.some(u => u.sites.some(s => s.id === site.id)) || p.users.every(u => u.sites.every(s => s == null)))
        .map(p => p);
        return techList.length > 0 ? techList : this.list();
      });
  }
}

export interface IMeasurementListTypeEntity extends Entity {
    measurementListId: number;
    measurementTypeId: number;
    measurementList: IMeasurementListEntity;
    measurementType: IMeasurementTypeEntity;
}

interface IMeasurementListEntity extends Entity {
  modalityId: number;
  modality: IModalityEntity;
  contents: string;
  instituteId: number;
  institute: IInstituteEntity;
  measurementTypes: IMeasurementListTypeEntity[];
}

export interface IMeasurementTypeEntity extends Entity {
  key: string;
  modalityId: number;
  modality: IModalityEntity;
  dataType: "System.String" | "System.Int32" | "System.Single";
  unitDescription: string;
  description: string;
  measurementLists: IMeasurementListTypeEntity[];
  measurementValues: IMeasurementValueEntity[];
}

export class MeasurementType extends BusinessModel<IMeasurementTypeEntity> {
   /** If set, this contains a set of tags related to this measurement type. This is useful for
   * describing and searching for specific measurement types. It can be loaded using the
   * `measurement-type-search.service.ts` file, which provides a `"measurementTypeSearch"` service.
   */

  static find: BusinessModelCtorWithQueries<IMeasurementTypeEntity, MeasurementType>["find"];
  static list: BusinessModelCtorWithQueries<IMeasurementTypeEntity, MeasurementType>["list"];
  static count: BusinessModelCtorWithQueries<IMeasurementTypeEntity, MeasurementType>["count"];

static $init (initialiser: ModelInitialiser) {
      initialiser.defineBasicQueries(MeasurementType, "MeasurementTypes");
  }
  static fromCache() { return BusinessModel.getLocalCached<MeasurementType>("MeasurementType"); }

  tags?: ReadonlyArray<string>;

  deleteCustomizedMeasurementList(): boolean {
    let isFound = false;
    let foundLink = this.breezeEntity().measurementLists.filter(l =>
      l.measurementTypeId === this.breezeEntity().id &&
      l.measurementList.instituteId === User.current.institute.id)[0];
    if (foundLink != null) {
      let foundList = foundLink.measurementList;
      if (foundList != null) {
        isFound = true;
        foundLink.entityAspect.setDeleted();
        foundList.entityAspect.setDeleted();
      }
    }
    return isFound;
  }

  get measurementList(): IMeasurementListEntity {
    let mlt = filter(this.breezeEntity().measurementLists, f => {
        if (f.measurementList.institute !== null) {
          return f.measurementList.institute.id === User.current.institute.id;
        }
        });
    if (mlt[0]) {
      return mlt[0].measurementList;
    } else {
      mlt = filter(this.breezeEntity().measurementLists, f => {
          return f.measurementList.institute === null;
        });
      if (mlt[0]) {
        return mlt[0].measurementList;
      } else { return null; }
    }
  }

  /** Add a new measurement list for the current institute. */
  private addMeasurementList(msmList: IMeasurementListEntity): void {
    if (msmList.instituteId === null) {
      let newMeasurementList = <IMeasurementListEntity>BusinessModel.$defaultManager
        .createEntity("MeasurementList");
        newMeasurementList.isDeleted = false;
        newMeasurementList.modalityId = msmList.modalityId;
        newMeasurementList.instituteId = User.current.institute.id;
        newMeasurementList.contents = msmList.contents;

        let newMeasurementListType = <IMeasurementListTypeEntity>BusinessModel.$defaultManager
          .createEntity("MeasurementListType");
        newMeasurementListType.isDeleted = false;
        newMeasurementListType.measurementListId = newMeasurementList.id;
        newMeasurementListType.measurementTypeId = this.id;

        this.breezeEntity().measurementLists.push(newMeasurementListType);
    }
  }

  get key(): string { return this.breezeEntity().key; }
  get modalityKey(): string {
    const entity = this.breezeEntity();
    const modality = entity.modality;
    if (modality != null) {
      return modality.masterName;
    }
  }
  get dataType(): "System.String" | "System.Int32" | "System.Single" {
    return this.breezeEntity().dataType;
  }
  get units(): string { return this.breezeEntity().unitDescription; }
  get description(): string { return this.breezeEntity().description; }

  /** Gets the modality of this measurement type. */
  get modality(): Modality { return asBusinessModel(this.breezeEntity().modality) as Modality; }

  private _options: string[] = undefined;
  /** Get a cached version of the options list, split into array form. */
  get dropdownOptions(): ReadonlyArray<string> {
    if (this._options != null) {
      return this._options;
    }
    const ml = this.measurementList;

    if(ml) {
      if (ml.contents) {
        return this._options = _
          .uniq(ml.contents.replace(/^[,\s]+|[,\s]+$/g, '')
          .split(/,/g), true);
      } else {
        return [] as string[]
      }
    }
  }

  set dropdownOptions(opts: ReadonlyArray<string>) {
    this._options = null;
    if (this.measurementList.instituteId === null) {
      this.addMeasurementList(this.measurementList);
    }
    this.measurementList.contents = opts.join(",");
  }
}

export interface IMeasurementValueEntity extends Entity { //TODO: Remove when we have real breeze models.
  value: string;
  measurementType: IMeasurementTypeEntity;
  exam: IExamEntity;
}

export class MeasurementValue extends BusinessModel<IMeasurementValueEntity> {
  get value(): string { return this.breezeEntity().value; }
  set value(value: string) { this.breezeEntity().value = value; }
  get type(): MeasurementType {
    return asBusinessModel(this.breezeEntity().measurementType) as MeasurementType;
  }
  get exam(): Exam {
    return asBusinessModel(this.breezeEntity().exam) as Exam;
  }
}

interface IUserSiteEntity extends Entity {
  site: ISiteEntity;
  user: IUserEntity;
}

interface IUserRoleLinkEntity extends Entity { //TODO: Remove when we have real breeze models.
  role: IRoleEntity;
  user: IUserEntity;
}

interface IUserEntity extends Entity { //TODO: Remove when we have real breeze models.
  userName: string;
  password: string;
  person: IPersonEntity;
  email: string;
  favouriteSearches: string;
  userRoles: IUserRoleLinkEntity[];
  userSites: IUserSiteEntity[];
  settings: IUserSettingEntity[];
}
export class User extends BusinessModel<IUserEntity> {
  userName: string;
  password: string;
  personTitleType: PersonTitleType;
  firstName: string;
  lastName: string;
  person: IPersonEntity;
  email: string;
  institute: Institute;
  roles: Role[];
  sites: Site[];
  technician: Technician;
  physician: Physician;
  settings: { [key: string]: string; };

  dateParseFormats: string[];
  shortDateFormat: string;
  dateFormat: string;
  longDateFormat: string;
  shortDateTimeFormat: string;
  longDateTimeFormat: string;
  shortTimeFormat: string;
  language: string;

  static autoLoginEnabled: boolean = false;
  static current: User;
  static find: BusinessModelCtorWithQueries<IUserEntity, User>["find"];
  static list: BusinessModelCtorWithQueries<IUserEntity, User>["list"];
  static count: BusinessModelCtorWithQueries<IUserEntity, User>["count"];
  private static loadedSource: angular.IDeferred<User>;

  static $init = ["modelInitialiser", "authService", "$log", "$q", "$rootScope",
    (initialiser: ModelInitialiser, authService: IAuthService, $log: angular.ILogService, $q: angular.IQService, $rootScope: IRootScopeService) => {
    initialiser.defineBasicQueries(User, "Users");

    initialiser.mapGetter(User.prototype, "id");
    initialiser.mapGetterSetter(User.prototype, "userName");
    initialiser.mapGetterSetter(User.prototype, "password");
    initialiser.mapGetterSetter(User.prototype, "personTitleType", "person.personTitleType");
    initialiser.mapGetterSetter(User.prototype, "firstName", "person.firstName");
    initialiser.mapGetterSetter(User.prototype, "lastName", "person.lastName");
    initialiser.mapGetterSetter(User.prototype, "email");
    initialiser.mapGetterSetter(User.prototype, "institute", "person.institute");
    initialiser.mapGetter(User.prototype, "person");
    initialiser.manualGetterSetter(User.prototype, "roles", (breezeEntity: IUserEntity) => breezeEntity.userRoles.map((link) => <Role>asBusinessModel(link.role)));
    initialiser.manualGetterSetter(User.prototype, "sites", (breezeEntity: IUserEntity) => breezeEntity.userSites.map((link) => <Site>asBusinessModel(link.site)));

    initialiser.manualGetterSetter(User.prototype, "technician", function (breezeEntity: IUserEntity) {
        if (breezeEntity && breezeEntity.person && breezeEntity.person.technicians.length > 0) {
            const tech = breezeEntity.person.technicians[0];
            return tech && (tech.isDeleted !== true) ? <Technician>asBusinessModel(tech) : null;
        }
        return null;
    });

    initialiser.manualGetterSetter(User.prototype, "physician", function (breezeEntity: IUserEntity) {
      if (breezeEntity && breezeEntity.person && breezeEntity.person.physicians.length > 0) {
          const phys = breezeEntity.person.physicians[0];
          return phys && (phys.isDeleted !== true) ? <Physician>asBusinessModel(phys) : null;
      }
      return null;
    });

    initialiser.manualGetterSetter(User.prototype, "settings", function (breezeEntity: IUserEntity) {
      let obj = {};
      for (let x of breezeEntity.settings) {
        obj[x.key] = x.value;
      }
      return obj;
    });

    //Formats to try parsing dates for this user. Uses the moment.js library.
    User.prototype.dateParseFormats = ["D-M-YY", "D-M-YYYY", "D MMM", "MMM D", "D MMM YY", "D MMM YYYY",
      "MMM D YY", "MMM D YYYY"];
    //Display format for dates for this user. Uses the moment.js library.
    User.prototype.shortDateFormat = 'D/M/YYYY';    // 1/2/03
    User.prototype.dateFormat = 'D MMM YYYY';        // Feb 1 2003
    User.prototype.longDateFormat = 'MMMM D YYYY';    // February 1 2003
    User.prototype.shortDateTimeFormat = 'D/M/YYYY h:mm:ss a';
    User.prototype.longDateTimeFormat = 'D MMM YYYY h:mm a'; // 1 Feb 2003 12:06 pm
    User.prototype.shortTimeFormat = 'h:mm a';

    User.prototype.language = "en-AU";

    User.loadedSource = $q.defer<User>();
    //Do some external work to keep User.current up to date with the currently logged on user. There
    //will be a short delay after a user is changed to when the current user is set, but that should
    //only be on log out and log in, so I don't think it'll be a problem.
    authService.onUserChanged(function (newUser) {
      if (newUser != null) {
        User.handleUserChanged(authService, $log, $q).then(
          user => $rootScope.$emit("userChanged", user),
          err => $rootScope.$emit("userChanged", null));
      } else {
        User.current = null;
        Institute.current = null;
        $rootScope.$emit("userChanged", null);
      }
    });
  }];

  static $inject = ["breezeEntity", "$http", "moment"];

    constructor(breezeEntity: IUserEntity, private $http: angular.IHttpService, private $moment: moment.MomentStatic) {
    super(breezeEntity);
    this.bindMembers("delete", "hasRole", "addRole", "removeRole", "setSetting",
      "formatShortDate", "formatDate", "formatLongDate", "parseDate", "changePassword",
      "changeUserName");
  }

  hasRole(roleName: string) {
    for (let role of this.roles) {
      if (role.name === roleName) {
        return true;
      }
    }
    return false;
  }

  addRole(roleName: RoleName) {
    let roles = Role.listNow();
    let entity = find(roles, x => x.name === roleName);
    if (!entity) { return; }
    let user = this.breezeEntity();
    let role = asBreezeEntity(entity);
    return this.$manager.createEntity("UserRole", { role, user });
  }

  removeRole(roleName: RoleName) {
    return this.breezeEntity().userRoles
      .filter((userRole) => userRole.role.name === roleName)
      .map((userRole) => userRole.entityAspect.setDeleted());
  }

  addSite(newSite: Site) {
    if (!newSite) { return; }
    let user = this.breezeEntity();
    let site = asBreezeEntity(newSite);
    return this.$manager.createEntity("UserSite", { site, user });
  }

  removeSite(site: Site) {
    return this.breezeEntity().userSites
      .filter((userSite) => userSite.site.id === site.id)
      .map(userSite => userSite.entityAspect.setDeleted());
  }

  setSetting(key: string, value: string) {
    let entity = this.breezeEntity();
    let setting = find(entity.settings, x => x.key === key);
    if (!setting) {
      setting = <IUserSettingEntity>this.$manager.createEntity("UserSetting");
      setting.user = entity;
    }
    setting.key = key;
    setting.value = value;

    return setting;
  }

  private getMoment(date: string, lang: string): moment.Moment;
  private getMoment(date: number | number[] | Date | moment.Moment): moment.Moment;
  private getMoment(date: moment.MomentComparable, lang?: string): moment.Moment;
  private getMoment(date: moment.MomentComparable, lang?: string): moment.Moment {
    if (this.$moment.isMoment(date)) {
      return <moment.Moment>date; //Our moment typings don't have proper type guards.
    } else if (angular.isString(date)) {
      return this.$moment(date, null, lang);
    } else {
      return this.$moment(date);
    }
  };


  /** Converts a date to a string using the user's date and language preferences. */
  formatShortDate(date: moment.MomentComparable) { return this.getMoment(date, this.language).format(this.shortDateFormat); }
  formatDate(date: moment.MomentComparable) { return this.getMoment(date, this.language).format(this.dateFormat); }
  formatLongDate(date: moment.MomentComparable) { return this.getMoment(date, this.language).format(this.longDateFormat); }
  formatShortDateTime(date: moment.MomentComparable) { return this.getMoment(date, this.language).format(this.shortDateTimeFormat); }
  formatLongDateTime(date: moment.MomentComparable) { return this.getMoment(date, this.language).format(this.longDateTimeFormat); }

  /**
    * Parse a string into a moment date using the user's date and language preferences.
    * @param dateString The date or string to parse.
    * @param local If true then the date is parsed in the local timezone, otherwise it is
    * parsed as a UTC time (with 0 offset). This is useful for things like birthdays which
    * don't represent an instant in time in a particular timezone (ie. you don't change
    * your birthday when you move).
    * @returns A moment object.
    */
  parseDate(dateString: Date | string, local = true) {
    if (angular.isDate(dateString)) { return moment(dateString); }
    let parse = local === true ? moment : moment.utc;
    let parsed = parse(dateString, this.dateParseFormats, this.language);
    if (parsed.year() === 0) { parsed.year(parse().year()); }
    return parsed;
  }

  changePassword(currentPassword: string, newPassword: string) {
    return this.$http.post("api/midas/changepassword/", { id: this.id, currentPassword, newPassword });
  }

  changeUserName(userName: string) {
    return this.$http.post("api/midas/changeUserName/", { id: this.id, userName });
  }

  delete() {
    const entity = this.breezeEntity();
    entity.isDeleted = true;
    entity.person.physicians.forEach(p => p.isDeleted = true);
    entity.person.technicians.forEach(t => t.isDeleted = true);
  }

  static create(): User {
    let user = <IUserEntity>BusinessModel.$defaultManager.createEntity("User");
    user.person = <IPersonEntity>BusinessModel.$defaultManager.createEntity("Person");
    return <User>asBusinessModel(user);
  }

  static waitForLoaded(): angular.IPromise<User> {
      return User.loadedSource.promise;
  }

  private static handleUserChanged(authService: IAuthService, $log: angular.ILogService, $q: angular.IQService) {
    //TODO: We should clear the current user here and then set it in the promise. I don't
    //think User.current should ever be a different user to what's in the auth service,
    //even for a very short time.
    User.loadedSource = $q.defer();
    return BusinessModel.$defaultManager.executeQuery(breeze.EntityQuery.from("UserData"))
      .then(function (data) {
        let authUserName = authService.getUser().userName;
        User.current = find(
          <User[]>asBusinessModel(BusinessModel.$defaultManager.getEntities("User")),
          u => u.userName === authUserName);
        Institute.current = User.current != null ? User.current.institute : undefined;
        User.loadedSource.resolve(User.current);
        return User.current;
      }).catch(function (error) {
        $log.error(error.message);
        //the user is trying to authenticate with a institute that doesn't exist, so kill auth tokens
        authService.logout();
        return null;
      });
  }
}

interface IUserSettingEntity extends Entity { //TODO: Remove when we have real breeze models.
  key: string;
  value: string;
  user: IUserEntity;
}
export class UserSetting extends BusinessModel<IUserSettingEntity> {
  key: string;
  value: string;
  static $init(initialiser: ModelInitialiser) {
    initialiser.mapGetter(UserSetting.prototype, "key");
    initialiser.mapGetterSetter(UserSetting.prototype, "value");
  }
}

interface IInstituteEntity extends Entity { //TODO: Remove when we have real breeze models.

  name: string;
  key: string;
  email: string;
  practices: IPracticeEntity[];
  reportFormatters: IReportFormatterEntity[];
  sites: ISiteEntity[];
  settings: IInstituteSettingEntity[];
  people: IPersonEntity[];
  diagramTemplates: { institute: IInstituteEntity, diagramTemplate: IDiagramTemplateEntity }[];
  studyPropertiesDerivedFromDicom: IStudyPropertyDerivedFromDicomEntity[];
}
export class Institute extends BusinessModel<IInstituteEntity> {
  name: string;
  key: string;
  email: string;
  practices: Practice[];
  readonly reportFormatters: ReportFormatter[];
  readonly sites: Site[];
  readonly settings: InstituteSetting[];
  readonly users: ReadonlyArray<User>;
  readonly diagramTemplates: ReadonlyArray<DiagramTemplate>;
  readonly studyPropertiesDerivedFromDicom: ReadonlyArray<StudyPropertyDerivedFromDicom>;

  static current: Institute;
  static find: BusinessModelCtorWithQueries<IInstituteEntity, Institute>["find"];
  static list: BusinessModelCtorWithQueries<IInstituteEntity, Institute>["list"];
  static count: BusinessModelCtorWithQueries<IInstituteEntity, Institute>["count"];

  private static $http: angular.IHttpService;

  static $init = ["modelInitialiser", "$http", (initialiser: ModelInitialiser, $http: angular.IHttpService) => {
    Institute.$http = $http;
    initialiser.mapGetterSetter(Institute.prototype, "name");
    initialiser.mapGetterSetter(Institute.prototype, "key");
    initialiser.mapGetterSetter(Institute.prototype, "email");
    initialiser.mapGetter(Institute.prototype, "practices");
    initialiser.mapGetter(Institute.prototype, "reportFormatters");
    initialiser.mapGetter(Institute.prototype, "sites");
    initialiser.mapGetter(Institute.prototype, "settings");
    initialiser.mapGetter(Institute.prototype, "studyPropertiesDerivedFromDicom");

    initialiser.manualGetterSetter(Institute.prototype, "users", function (breezeEntity: IInstituteEntity) {
      const users = [];
      for (let person of breezeEntity.people) {
        for (let user of person.users) { users.push(user); }
      }
      return users;
    });

    initialiser.manualGetterSetter(Institute.prototype, "diagramTemplates", function (breezeEntity: IInstituteEntity) {
      return breezeEntity.diagramTemplates.map((link) => <DiagramTemplate>asBusinessModel(link.diagramTemplate));
    });

    initialiser.defineBasicQueries(Institute, "Institutes");
  }];
  static $inject = ["breezeEntity"];
  constructor(breezeEntity: IInstituteEntity) {
    super(breezeEntity);
    this.bindMembers("addFormatter", "addSite", "addSetting", "hasSetting", "getSetting");
  }

  addFormatter(examType: ExamType, template: string): ReportFormatter {
    let formatter = <IReportFormatterEntity>this.$manager.createEntity("ReportFormatter", {
      template,
      examType: asBreezeEntity(examType)
    });
    this.breezeEntity().reportFormatters.push(formatter);
    return <ReportFormatter>asBusinessModel(formatter);
  }

  addSite(name: string): Site {
    let site = <ISiteEntity>this.$manager.createEntity("Site", { name });
    this.breezeEntity().sites.push(site);
    return <Site>asBusinessModel(site);
  }

  addSetting(key: string, value: string): InstituteSetting {
    let setting = <IInstituteSettingEntity>this.$manager.createEntity("InstituteSetting", { key, value });
    this.breezeEntity().settings.push(setting);
    return <InstituteSetting>setting.businessModel();
  }

  /** Sets an institute setting (or adds it if it doesn't exist).
   * @param key The key of the institute setting.
   * @param value The value to assign. */
  setSetting(key: "DefaultPersonSortOrder", value: "LNFN" | "FNLN"): InstituteSetting;
  setSetting(key: "DefaultPersonDisplayFormat", value: "LNB_Comma_Space_FN" | "LN_Comma_Space_FN" | "FN_Space_LN" | "FN_Space_LNB"): InstituteSetting;
  setSetting(key: string, value: string): InstituteSetting
  setSetting(key: string, value: string): InstituteSetting {
    const settings = this.breezeEntity().settings;
    const found = find(settings, x => x.key === key);
    if (found) {
      found.value = value;
      return <InstituteSetting>found.businessModel();
    }
    return this.addSetting(key, value);
  }

  hasSetting(key: string) {
    const settings = this.breezeEntity().settings;
    return find(settings, x => x.key === key) != null;
  }

  getSetting(key: "DefaultPersonSortOrder"): "LNFN" | "FNLN";
  getSetting(key: "DefaultPersonDisplayFormat"): "LNB_Comma_Space_FN" | "LN_Comma_Space_FN" | "FN_Space_LN" | "FN_Space_LNB";
  getSetting(key: string): string;
  getSetting(key: string): string {
    const settings = this.breezeEntity().settings;
    const found = find(settings, x => x.key === key);
    const value = found ? found.value : undefined;
    if (isNullOrWhitespace(value)) {
      return null;
    } else {
      return value;
    }
  }

  static checkKeyIsFree(key) {
    return Institute.$http.get(`api/midas/isinstitutekeyfree/${key}`)
      .then(response => response.data === true);
  }

  static signup(info) { return Institute.$http.post("api/midas/demosignup/", info); }

  static listAvailable() {
      return Institute.$http.get("api/midas/Seeders/")
      .then(response => <{
        name: string,
        examType: string,
        type: string
      }[]>response.data);
  }

  static getSingleKey() {
    return Institute.$http.get("api/midas/getsingleinstitutekey/")
      .then(resp => (<string>resp.data).replace(/["']/g, ""));
  }

  static create(): Institute {
    return <Institute>asBusinessModel(<IInstituteEntity>BusinessModel.$defaultManager.createEntity("Institute"));
  }
}

  interface IStudyPropertyDerivedFromDicomEntity extends Entity {
    dicomTag: string;
    studyProperty: string;
    matchValue: string;
    derivedValue: string;
    institute: IInstituteEntity;
  }
  export class StudyPropertyDerivedFromDicom extends BusinessModel<IStudyPropertyDerivedFromDicomEntity> {
    dicomTag: string;
    studyProperty: string;
    matchValue: string;
    derivedValue: string;

    static $init = ["modelInitialiser", (initialiser: ModelInitialiser) => {
      initialiser.mapGetterSetter(StudyPropertyDerivedFromDicom.prototype, "dicomTag");
      initialiser.mapGetterSetter(StudyPropertyDerivedFromDicom.prototype, "studyProperty");
      initialiser.mapGetterSetter(StudyPropertyDerivedFromDicom.prototype, "matchValue");
      initialiser.mapGetterSetter(StudyPropertyDerivedFromDicom.prototype, "derivedValue");
    }];
    static $inject = ["breezeEntity"];
    constructor(breezeEntity: IStudyPropertyDerivedFromDicomEntity) {
      super(breezeEntity);
    }

    static create(): StudyPropertyDerivedFromDicom {
      const entity = <IStudyPropertyDerivedFromDicomEntity>BusinessModel.$defaultManager.createEntity(
        "StudyPropertyDerivedFromDicom",
        { institute: Institute.current.breezeEntity() }
      );
      return <StudyPropertyDerivedFromDicom>asBusinessModel(entity);
    }
  }

  interface IPracticeEntity extends Entity { //TODO: Remove when we have real breeze models.
    institute: IInstituteEntity;
    reportTemplate: string;
  }
  export class Practice extends BusinessModel<IPracticeEntity> {
    institute: Institute;
    reportTemplate: string;
    private static $http: angular.IHttpService;

  static $init = ["modelInitialiser", "$http",
      (initialiser: ModelInitialiser, $http: angular.IHttpService) => {
            Practice.$http = $http;
            initialiser.mapGetterSetter(Practice.prototype, "institute");
            initialiser.mapGetterSetter(Practice.prototype, "reportTemplate");
        }];

    static downloadReportTemplate(): angular.IHttpPromise<Blob> {
      //We cannot just provide the URL to the file, we must use $http and return the content as we
      //rely on http auth headers to keep things secure
      return Practice.$http.get("api/midas/DownloadReportTemplate/",
          { //To receive and write to a blob on the client side, it must set the responseType to
            //"arraybuffer"
            "responseType": "blob"
          });
  }
}

interface IRoleEntity extends Entity { //TODO: Remove when we have real breeze models.
  name: RoleName;
  userRoles: { user: IUserEntity, role: IRoleEntity }[];
}
export class Role extends BusinessModel<IRoleEntity> {
  name: RoleName;
  readonly users: ReadonlyArray<User>;

  static find: BusinessModelCtorWithQueries<IRoleEntity, Role>["find"];
  static list: BusinessModelCtorWithQueries<IRoleEntity, Role>["list"];
  static count: BusinessModelCtorWithQueries<IRoleEntity, Role>["count"];

  static $init(initialiser: ModelInitialiser) {
    initialiser.mapGetterSetter(Role.prototype, "name");
    initialiser.manualGetterSetter(Role.prototype, "users", function (breezeEntity: IRoleEntity) {
      return breezeEntity.userRoles.map((link) => <User>asBusinessModel(link.user));
    });
    initialiser.defineBasicQueries(Role, "Roles", "userRoles.role");
  }
  static listNow(): Role[] { return <Role[]>BusinessModel.getLocalCached<Role>("Role"); }
}

interface IGlossaryCategoryEntity extends Entity { //TODO: Remove when we have real breeze models.
  id: number;
  masterName: string;
  glossary: IGlossaryItemEntity[];
  modules: IGlossaryStudyTypeLinkEntity[];
  studyTypes: IGlossaryCategoryStudyTypeLinkEntity[];
  institute: IInstituteEntity;
  isPhysicianOnly: boolean;
  isTechnicianOnly: boolean;
}
interface IGlossaryStudyTypeLinkEntity extends Entity { //TODO: Remove when we have real breeze models.
  glossaryCategory: IGlossaryCategoryEntity;
  modality: IModalityEntity;
}
interface IGlossaryCategoryStudyTypeLinkEntity extends Entity { //TODO: Remove when we have real breeze models.
  glossaryCategory: IGlossaryCategoryEntity;
  studyType: IStudyTypeEntity;
}
export class GlossaryCategory extends BusinessModel<IGlossaryCategoryEntity> {
  id: number;
  name: string;
  readonly glossary: Glossary[];
  readonly modules: ReadonlyArray<Modality>;
  readonly examTypes: ReadonlyArray<ExamType>;
  institute: Institute;
  isPhysicianOnly: boolean;
  isTechnicianOnly: boolean;
  canView: boolean;

  static $init(initialiser: ModelInitialiser) {
    initialiser.mapGetterSetter(GlossaryCategory.prototype, "id");
    initialiser.mapGetterSetter(GlossaryCategory.prototype, "name", "masterName");
    initialiser.mapGetter(GlossaryCategory.prototype, "glossary");
    initialiser.manualGetterSetter(GlossaryCategory.prototype, "modules", function (breezeEntity: IGlossaryCategoryEntity) {
      return breezeEntity.modules.map((link) => <Modality>asBusinessModel(link.modality));
    });
    initialiser.manualGetterSetter(GlossaryCategory.prototype, "examTypes", function (breezeEntity: IGlossaryCategoryEntity) {
      return breezeEntity.studyTypes.map(link => <ExamType>asBusinessModel(link.studyType));
    });
    initialiser.mapGetterSetter(GlossaryCategory.prototype, "institute");

    initialiser.mapGetterSetter(GlossaryCategory.prototype, "isPhysicianOnly");
    initialiser.mapGetterSetter(GlossaryCategory.prototype, "isTechnicianOnly");
    initialiser.manualGetterSetter(GlossaryCategory.prototype, "canView", function (breezeEntity: IGlossaryCategoryEntity) {
      return User.current.hasRole("Is Administrator")
        || (!this.isPhysicianOnly && !this.isTechnicianOnly)
        || (this.isPhysicianOnly && User.current.physician != null)
        || (this.isTechnicianOnly && User.current.technician != null);
    });
  }
  constructor(breezeEntity: IGlossaryCategoryEntity) {
    super(breezeEntity);
    this.bindMembers("delete", "createItem", "removeExamType", "addExamType");
  }

  static list() {
    return User.waitForLoaded().then(() => BusinessModel.getLocalCached<GlossaryCategory>("GlossaryCategory"));
  }

  //TODO: Should this be returned, or was it a holdover from an accidental return in
  //CoffeeScript. We don't usually return these internal entities.
  addExamType(examType: ExamType): IGlossaryCategoryStudyTypeLinkEntity {
    return <IGlossaryCategoryStudyTypeLinkEntity>this.$manager.createEntity("GlossaryCategoryStudyTypeLink", {
      glossaryCategory: this.breezeEntity(),
      studyType: asBreezeEntity(examType)
    });
  }

  removeExamType(examType: ExamType) {
    let found;
    for (let link of this.breezeEntity().studyTypes) {
      if (link.studyType.masterName === examType.key) { found = link; }
    }

    if (found) { return found.entityAspect.setDeleted(); }
  }

  static create(name: string, examType?: ExamType): GlossaryCategory {
      const entity = <IGlossaryCategoryEntity>BusinessModel.$defaultManager.createEntity("GlossaryCategory");
      const bo = <GlossaryCategory>asBusinessModel(entity);
      bo.name = name;
      bo.institute = Institute.current;
      if (examType) {
          bo.addExamType(examType);
      }
      return bo;
  }

  createItem(name: string, content: string): Glossary {
    let entity = this.$manager.createEntity("Glossary",
      { glossaryCategory: this.breezeEntity() });

    let bo = <Glossary>asBusinessModel(entity);
    bo.abbreviation = name;
    bo.entry = content;
    return bo;
  }

  delete() {
    let be = this.breezeEntity();
    for (let link of be.studyTypes) { link.entityAspect.setDeleted(); }
    return be.entityAspect.setDeleted();
  }
};

interface IGlossaryItemEntity extends Entity { //TODO: Remove when we have real breeze models.
  id: number;
  abbreviation: string;
  entry: string;
  glossaryCategory: IGlossaryCategoryEntity;
  isPhysicianOnly: boolean;
  isTechnicianOnly: boolean;
}
export class Glossary extends BusinessModel<IGlossaryItemEntity> {
  id: number;
  abbreviation: string;
  entry: string;
  category: GlossaryCategory;
  isPhysicianOnly: boolean;
  isTechnicianOnly: boolean;
  canView: boolean;

  static $init(initialiser: ModelInitialiser) {
    initialiser.mapGetterSetter(Glossary.prototype, "id");
    initialiser.mapGetterSetter(Glossary.prototype, "abbreviation");
    initialiser.mapGetterSetter(Glossary.prototype, "entry");
    initialiser.mapGetterSetter(Glossary.prototype, "category", "glossaryCategory");

    initialiser.mapGetterSetter(Glossary.prototype, "isPhysicianOnly");
    initialiser.mapGetterSetter(Glossary.prototype, "isTechnicianOnly");
    initialiser.manualGetterSetter(Glossary.prototype, "canView", function () {
      return User.current.hasRole("Is Administrator")
        || (!this.isPhysicianOnly && !this.isTechnicianOnly)
        || (this.isPhysicianOnly && User.current.physician != null)
        || (this.isTechnicianOnly && User.current.technician != null);
    });
  }
  constructor(breezeEntity: IGlossaryItemEntity) {
    super(breezeEntity);
    this.bindMembers("delete");
  }

  delete() { return this.breezeEntity().entityAspect.setDeleted(); }

  static create(abbreviation: string, content: string, category?: GlossaryCategory) : Glossary {
      let entity = <IGlossaryItemEntity>BusinessModel.$defaultManager.createEntity("Glossary");
      let bo = <Glossary>asBusinessModel(entity);
      bo.abbreviation = abbreviation;
      bo.entry = content;
      bo.category = category;
      return bo;
  }
}

interface IRuleDiagramElementEntity extends Entity { //TODO: Remove when we have real breeze models.
  properties: string;
  type: string;
  template: IDiagramTemplateEntity;
  institute: IInstituteEntity;
}
export class RuleDiagramElement extends BusinessModel<IRuleDiagramElementEntity> {
  properties: string;
  type: string;
  template: DiagramTemplate;

  static $init(initialiser: ModelInitialiser) {
    initialiser.mapGetterSetter(RuleDiagramElement.prototype, "properties");
    initialiser.mapGetterSetter(RuleDiagramElement.prototype, "type");
    initialiser.mapGetterSetter(RuleDiagramElement.prototype, "template");
  }
  constructor(breezeEntity: IRuleDiagramElementEntity) {
    super(breezeEntity);
    this.bindMembers("delete");
  }

  static create() {
    let entity = <IRuleDiagramElementEntity>BusinessModel.$defaultManager.createEntity('RuleDiagramElement');
    entity.institute = asBreezeEntity(Institute.current);
    return asBusinessModel(entity);
  }

  static list(): IPromise<RuleDiagramElement[]> {
    return User.waitForLoaded().then(() => BusinessModel.getLocalCached<RuleDiagramElement>("RuleDiagramElement"));
  }

  delete() { return (asBreezeEntity(this)).entityAspect.setDeleted(); }
}

interface IAgentEntity extends Entity { //TODO: Remove when we have real breeze models.
  id: number;
  name: string;
  description: string;
  isEnabled: boolean;
  jobs: IJobEntity[];
}
export class Agent extends BusinessModel<IAgentEntity> {
  name: string;
  description: string;
  isEnabled: boolean;
  readonly jobs: Job[];

  static find: BusinessModelCtorWithQueries<IAgentEntity, Agent>["find"];
  static list: BusinessModelCtorWithQueries<IAgentEntity, Agent>["list"];
  static count: BusinessModelCtorWithQueries<IAgentEntity, Agent>["count"];

  static $init(initialiser: ModelInitialiser) {
    initialiser.mapGetterSetter(Agent.prototype, "name");
    initialiser.mapGetterSetter(Agent.prototype, "description");
    initialiser.mapGetterSetter(Agent.prototype, "isEnabled");
    initialiser.mapGetter(Agent.prototype, "jobs");
    initialiser.defineBasicQueries(Agent, "Agents");
  }

  static listCached(): IPromise<Agent[]> {
    return User.waitForLoaded().then(() => <Agent[]>BusinessModel.getLocalCached<Agent>("Agent"));
  }

  reloadJobs() { return Job.list({ "agentId": this.breezeEntity().id }); }

  createJob(type: string, args: string): Job {
    let entity = <IJobEntity>this.$manager.createEntity('Job', { type, args, agent: this.breezeEntity(), isEnabled: false });
    return <Job>asBusinessModel(entity);
  }
}

interface IJobEntity extends Entity { //TODO: Remove when we have real breeze models.
  agentId: number;
  type: string;
  args: string;
  interval: number;
  isEnabled: boolean;
}
export class Job extends BusinessModel<IJobEntity> {
  type: string;
  readonly args: { key: string, value: any }[];
  interval: number;
  isEnabled: boolean;

  static find: BusinessModelCtorWithQueries<IJobEntity, Job>["find"];
  static list: BusinessModelCtorWithQueries<IJobEntity, Job>["list"];
  static count: BusinessModelCtorWithQueries<IJobEntity, Job>["count"];

  static $init(initialiser: ModelInitialiser) {
    initialiser.mapGetterSetter(Job.prototype, "type");
    initialiser.manualCachedGetter(Job.prototype, "args", function (breezeEntity: IJobEntity) {
      let obj = angular.fromJson(breezeEntity.args);
      return (() => {
        const result: { key: string, value: any }[] = [];
        for (let k in obj) {
          let v = obj[k];
          result.push({ key: k, value: v });
        }
        return result;
      })();
    });
    initialiser.mapGetterSetter(Job.prototype, "interval");
    initialiser.mapGetterSetter(Job.prototype, "isEnabled");
    initialiser.defineBasicQueries(Job, "Jobs");
  }
  constructor(breezeEntity: IJobEntity) {
    super(breezeEntity);
    this.bindMembers("delete", "writeArgs");
  }

  writeArgs() {
    let obj = {};
    for (let arg of this.args) { obj[arg.key] = arg.value; }
    return this.breezeEntity().args = angular.toJson(obj);
  }
  delete() { return this.breezeEntity().entityAspect.setDeleted(); }
}

interface IDocument {
  localPath: string;
  contentType: string;
  downloadPath: string;
  embedPath: string;
}

interface IStudyDocumentEntity extends Entity { //TODO: Remove when we have real breeze models.
  id: number;
  localPath: string;
  contentType: string;
}
export class StudyDocument extends BusinessModel<IStudyDocumentEntity> implements IDocument {
  localPath: string;
  contentType: string;
  downloadPath: string;
  embedPath: string;
  static $init(initialiser: ModelInitialiser) {
    initialiser.mapGetterSetter(StudyDocument.prototype, "localPath");
    initialiser.mapGetterSetter(StudyDocument.prototype, "contentType");
    initialiser.manualGetterSetter(StudyDocument.prototype, "downloadPath", function (breezeEntity: IStudyDocumentEntity) {
      return `api/midas/document/${breezeEntity.id}`;
    });
    initialiser.manualGetterSetter(StudyDocument.prototype, "embedPath", function (breezeEntity: IStudyDocumentEntity) {
      return this.getPdfViewerUrl(`api/midas/document/${breezeEntity.id}`);
    });
  }

  static $inject = ["breezeEntity", "$q",  "getPdfViewerUrl"];
  constructor(
    breezeEntity: IStudyDocumentEntity,
    private readonly $q: angular.IQService,
      private readonly getPdfViewerUrl: GetPdfViewerUrl
  ) {
    super(breezeEntity);
    this.bindMembers("delete");
  }
  delete(): angular.IPromise<any> {
    const query = breeze.EntityQuery.from(`DeleteDocument/${this.breezeEntity().id}`);
    query.queryOptions = <breeze.QueryOptions>{
      ...breeze.QueryOptions.defaultInstance,
      includeDeleted: true
    };
    return this.$q
      .when<{ results: Entity[] }>(BusinessModel.$defaultManager.executeQuery(query) as any)
      .then(response => {
        response.results.forEach(r => r.entityAspect.setDetached());
      });
  }
}

interface IPatientDocumentEntity extends Entity { //TODO: Remove when we have real breeze models.
  id: number;
  localPath: string;
  contentType: string;
}
export class PatientDocument extends BusinessModel<IPatientDocumentEntity> implements IDocument {
  localPath: string;
  contentType: string;
  downloadPath: string;
  embedPath: string;
  static $init = ["modelInitialiser", "getPdfViewerUrl", (initialiser: ModelInitialiser, getPdfViewerUrl: GetPdfViewerUrl) => {
    initialiser.mapGetterSetter(PatientDocument.prototype, "localPath");
    initialiser.mapGetterSetter(PatientDocument.prototype, "contentType");
    initialiser.manualGetterSetter(PatientDocument.prototype, "downloadPath", breezeEntity => `api/midas/patientdocument/${breezeEntity.id}`);
    initialiser.manualGetterSetter(PatientDocument.prototype, "embedPath", breezeEntity => getPdfViewerUrl(`api/midas/patientdocument/${breezeEntity.id}`));
  }]
  static $inject = ["breezeEntity", "$http", "saveQueue"];
  constructor(breezeEntity: IPatientDocumentEntity, private $http: angular.IHttpService, private $saveQueue: SaveQueue) {
    super(breezeEntity);
    this.bindMembers("delete");
  }
  delete() {
    return this.$http.post(`api/midas/DeletePatientDocument/${this.breezeEntity().id}`, undefined)
      .then(() => <any>this.$saveQueue.save(() => this.breezeEntity().entityAspect.setDeleted()));
  }
}

interface IDicomDeviceConfigurationEntity extends Entity { //TODO: Remove when we have real breeze models.
  name: string;
  deviceModels: IDicomDeviceModelEntity[];
  srFieldMappings: IDicomSrFieldMappingEntity[];
}
export class DicomDeviceConfiguration extends BusinessModel<IDicomDeviceConfigurationEntity> {
  /** The name of this mapping configuration. This can be freely set by us for identification
   * purposes, and doesn't need to match any DICOM tags */
  name: string;
  /** The device models which are mapped by this configuration. */
  deviceModels: DicomDeviceModel[];
  srFieldMappings: DicomSrFieldMapping[];
  downloadUrl: string;

  static find: BusinessModelCtorWithQueries<IDicomDeviceConfigurationEntity, DicomDeviceConfiguration>["find"];
  static list: BusinessModelCtorWithQueries<IDicomDeviceConfigurationEntity, DicomDeviceConfiguration>["list"];
  static count: BusinessModelCtorWithQueries<IDicomDeviceConfigurationEntity, DicomDeviceConfiguration>["count"];

  static $init(initialiser: ModelInitialiser) {
    initialiser.mapGetterSetter(DicomDeviceConfiguration.prototype, "name");
    initialiser.mapGetterSetter(DicomDeviceConfiguration.prototype, "deviceModels");
    initialiser.mapGetterSetter(DicomDeviceConfiguration.prototype, "srFieldMappings");
    initialiser.manualCachedGetter(DicomDeviceConfiguration.prototype, "downloadUrl", function (breezeEntity: IDicomDeviceConfigurationEntity) {
      return `api/midas/ExportDicomConfiguration/${breezeEntity.name}`;
    });
    initialiser.defineBasicQueries(DicomDeviceConfiguration, "DicomDeviceConfigurations");
  }
  static $inject = ["breezeEntity", "$http"];
  constructor(breezeEntity: IDicomDeviceConfigurationEntity, private $http: angular.IHttpService) {
    super(breezeEntity);
    this.bindMembers("delete", "clearFromCache");
  }
  static create(props: Partial<IDicomDeviceConfigurationEntity>): DicomDeviceConfiguration {
    return <DicomDeviceConfiguration>asBusinessModel(BusinessModel.$defaultManager.createEntity('DicomDeviceConfiguration', props));
  }
  delete() { this.breezeEntity().isDeleted = true; }
  getTestResults() { return this.$http.get(`api/midas/DeviceTests/${this.name}`).then(result => angular.fromJson(<string>result.data)); }
  clearFromCache() {
    for (let map of this.srFieldMappings) { map.clearFromCache(); }
    return this.$manager.detachEntity(this.breezeEntity());
  }
}

interface IDicomDeviceEntity extends Entity { //TODO: Remove when we have real breeze models.
  serial: string;
  model: IDicomDeviceModelEntity;
  institute: IInstituteEntity;
  site: ISiteEntity;
}

/** A specific DICOM machine. It should be associated with a model and possibly a specific site to
 * allow DICOM files to be mapped correctly. */
export class DicomDevice extends BusinessModel<IDicomDeviceEntity> {
  /** The serial number in the DICOM tags that uniquely identifies this machine. */
  serial: string;
  /** The model of this machine. */
  model: DicomDeviceModel;
  /** The institute that owns this device. */
  institute: Institute;
  /** When assigned, this property indicates the site at which this specific DICOM machine is
   * situated. Studies done by this machine can then be automatically assigned to a site. */
  site: Site;

  static find: BusinessModelCtorWithQueries<IDicomDeviceEntity, DicomDevice>["find"];
  static list: BusinessModelCtorWithQueries<IDicomDeviceEntity, DicomDevice>["list"];
  static count: BusinessModelCtorWithQueries<IDicomDeviceEntity, DicomDevice>["count"];

  static $init(initialiser: ModelInitialiser) {
    initialiser.mapGetterSetter(DicomDevice.prototype, "serial");
    initialiser.mapGetterSetter(DicomDevice.prototype, "model");
    initialiser.mapGetterSetter(DicomDevice.prototype, "institute");
    initialiser.mapGetterSetter(DicomDevice.prototype, "site");
    initialiser.defineBasicQueries(DicomDevice, "DicomDevices", "site,model");
  }
}

interface IDicomDeviceModelEntity extends Entity { //TODO: Remove when we have real breeze models.
    name: string;
    configuration: IDicomDeviceConfigurationEntity;
}

/** Defines a kind of DICOM device, rather than a specific machine. These are not user configurable,
 * as they define the set of devices MIDAS accepts, which only we know right now. */
export class DicomDeviceModel extends BusinessModel<IDicomDeviceModelEntity> {
    /** The model name of the DICOM device, often found in a DICOM tag. */
    name: string;
    /** Link to the SR mapping information. */
    configuration: DicomDeviceConfiguration;

    static find: BusinessModelCtorWithQueries<IDicomDeviceModelEntity, DicomDeviceModel>["find"];
    static list: BusinessModelCtorWithQueries<IDicomDeviceModelEntity, DicomDeviceModel>["list"];
    static count: BusinessModelCtorWithQueries<IDicomDeviceModelEntity, DicomDeviceModel>["count"];

    static $init(initialiser: ModelInitialiser) {
        initialiser.mapGetterSetter(DicomDeviceModel.prototype, "name");
        initialiser.mapGetterSetter(DicomDeviceModel.prototype, "configuration");
        initialiser.defineBasicQueries(DicomDeviceModel, "DicomDeviceModels");
    }
}


interface IDicomSrFieldMappingEntity extends Entity { //TODO: Remove when we have real breeze models.
  measurementType: IMeasurementTypeEntity;
  rootNode: IDicomSrFieldMappingConditionNodeEntity;
  comment: string;
  postProcesses: IDicomMappingPostProcessEntity[];
}
export class DicomSrFieldMapping extends BusinessModel<IDicomSrFieldMappingEntity> {
  key: string;
  root: DicomSrFieldMappingConditionNode;
  comment: string;
  readonly postProcesses: DicomMappingPostProcess[];

  static $init(initialiser: ModelInitialiser) {
    initialiser.mapGetterSetter(DicomSrFieldMapping.prototype, "key", "measurementType.key");
    initialiser.mapGetterSetter(DicomSrFieldMapping.prototype, "root", "rootNode");
    initialiser.mapGetterSetter(DicomSrFieldMapping.prototype, "comment", "comment");
    initialiser.mapGetterSetter(DicomSrFieldMapping.prototype, "postProcesses");
  }
  constructor(breezeEntity: IDicomSrFieldMappingEntity) {
    super(breezeEntity);
    this.bindMembers("delete", "expand", "addPostProcess");
  }
  expand() { return this.$manager.executeQuery(breeze.EntityQuery.from(`ExpandDicomConfigurationMapping/${this.id}`)); }
  static create(config, measurementType: MeasurementType) {
    let entity = BusinessModel.$defaultManager.createEntity('DicomSrFieldMapping', {
      configuration: asBreezeEntity(config),
      measurementType: asBreezeEntity(measurementType)
    });
    return asBusinessModel(entity);
  }
  delete() { return (asBreezeEntity(this)).entityAspect.setDeleted(); }
  addPostProcess(type) {
    let entity = this.$manager.createEntity('DicomMappingPostProcess', {
      dicomSrFieldMapping: this.breezeEntity(),
      type: asBreezeEntity(type)
    }
    );
    return asBusinessModel(entity);
  }
}

interface IDicomSrFieldMappingConditionNodeEntity extends Entity { //TODO: Remove when we have real breeze models.
  name: string;
  value: string;
  nameCode: string;
  valueCode: string;
  children: IDicomSrFieldMappingConditionNodeEntity[];
  parent: IDicomSrFieldMappingConditionNodeEntity;
  type: IDicomSrFieldMappingConditionTypeEntity;
}
export class DicomSrFieldMappingConditionNode extends BusinessModel<IDicomSrFieldMappingConditionNodeEntity> {
  name: string;
  value: string;
  nameCode: string;
  valueCode: string;
  children: DicomSrFieldMappingConditionNode[];
  parent: DicomSrFieldMappingConditionNode;
  type: string;
  static $init(initialiser: ModelInitialiser) {
    initialiser.mapGetterSetter(DicomSrFieldMappingConditionNode.prototype, "name");
    initialiser.mapGetterSetter(DicomSrFieldMappingConditionNode.prototype, "value");
    initialiser.mapGetterSetter(DicomSrFieldMappingConditionNode.prototype, "nameCode");
    initialiser.mapGetterSetter(DicomSrFieldMappingConditionNode.prototype, "valueCode");
    initialiser.mapGetterSetter(DicomSrFieldMappingConditionNode.prototype, "children");
    initialiser.mapGetterSetter(DicomSrFieldMappingConditionNode.prototype, "parent");
    initialiser.mapGetter(DicomSrFieldMappingConditionNode.prototype, "type", "type.masterName");
  }
  constructor(breezeEntity: IDicomSrFieldMappingConditionNodeEntity) {
    super(breezeEntity);
    this.bindMembers("delete", "getPath", "changeType");
  }
  changeType(type: DicomSrFieldMappingConditionType) { return this.breezeEntity().type = asBreezeEntity(type); }

  getPath() {
    const path = this.parent == null ? [] : this.parent.getPath();
    path.push(this);
    return path;
  }

  static create(name, code, type) {
    let entity = BusinessModel.$defaultManager.createEntity('DicomSrFieldMappingConditionNode', {
      name,
      nameCode: code,
      type: asBreezeEntity(type)
    });
    console.log('create', entity);
    return asBusinessModel(entity);
  }
  delete() { return (asBreezeEntity(this)).entityAspect.setDeleted(); }
}

interface IDicomMappingPostProcessEntity extends Entity { //TODO: Remove when we have real breeze models.
  type: IDicomPostProcessTypeEntity;
}
export class DicomMappingPostProcess extends BusinessModel<IDicomMappingPostProcessEntity> {
  type: DicomSrFieldMappingConditionType;
  static $init(initialiser: ModelInitialiser) {
    initialiser.mapGetter(DicomMappingPostProcess.prototype, "type", "type.masterName");
  }
  constructor(breezeEntity: IDicomMappingPostProcessEntity) {
    super(breezeEntity);
    this.bindMembers("delete");
  }
  delete() { return (asBreezeEntity(this)).entityAspect.setDeleted(); }
}

interface IDicomPostProcessTypeEntity extends IEnumEntity { //TODO: Remove when we have real breeze models.
}
export class PostProcessType extends Enum<IDicomPostProcessTypeEntity> {
  static listNow() { return BusinessModel.getLocalCached<PostProcessType>("PostProcessType"); }
}

interface IDicomSrFieldMappingConditionTypeEntity extends IEnumEntity { //TODO: Remove when we have real breeze models.
}
export class DicomSrFieldMappingConditionType extends Enum<IDicomSrFieldMappingConditionTypeEntity> {
  static list() { return BusinessModel.getLocalCached<DicomSrFieldMappingConditionType>("DicomSrFieldMappingConditionType"); }
}

interface ISiteEntity extends Entity { //TODO: Remove when we have real breeze models.
  name: string;
  dicomDevices: IDicomDeviceEntity[];
  userSites: IUserSiteEntity[];
}
export class Site extends BusinessModel<ISiteEntity> {
  name: string;
  dicomDevices: DicomDevice[];
  users: User[];

  static $init(initialiser: ModelInitialiser) {
    initialiser.mapGetterSetter(Site.prototype, "name");
    initialiser.mapGetter(Site.prototype, "dicomDevices");
    initialiser.manualGetterSetter(Site.prototype, "users", (breezeEntity: ISiteEntity) => breezeEntity.userSites.map((link) => <User>asBusinessModel(link.user)));
  }

  constructor(breezeEntity: ISiteEntity) {
    super(breezeEntity);
    this.bindMembers("delete");
  }

  static listNow(): Site[] { return <Site[]>BusinessModel.getLocalCached<Site>("Site"); }

  static list(includeDeleted: boolean = false) : IPromise<Site[]> {
      return Institute.find({ id: Institute.current.id }, "sites")
        .then(x => x ? (includeDeleted ? x.sites : x.sites.filter(y => !y.isDeleted)) : null);
  }

  static create(): Site {
    return <Site>asBusinessModel(BusinessModel.$defaultManager.createEntity("Site", {
      instituteId: Institute.current.id
    }));
  }

  delete() {
    this.isDeleted = true;
  }
}

interface IInstituteSettingEntity extends Entity { //TODO: Remove when we have real breeze models.
  key: string;
  value: string;
}
export class InstituteSetting extends BusinessModel<IInstituteSettingEntity> {
  key: string;
  value: string;
  static $init(initialiser: ModelInitialiser) {
    initialiser.mapGetterSetter(InstituteSetting.prototype, "key");
    initialiser.mapGetterSetter(InstituteSetting.prototype, "value");
  }
}

interface IStudyNoteEntity extends Entity { //TODO: Remove when we have real breeze models.
    study: IStudyEntity;
    studyId: number;

    createdBy: IUserEntity;
    createdById: number;

    content: string;

    createdAt: Date;
    modifiedAt?: Date;
}
export class StudyNote extends BusinessModel<IStudyNoteEntity> {
    study: Study;
    createdBy: User;
    modifiedBy: User;
    content: string;
    createdAt: Date;
    modifiedAt?: Date;

    static $init = [
        "modelInitialiser", (initialiser: ModelInitialiser) => {
            initialiser.mapGetterSetter(StudyNote.prototype, "study");
            initialiser.mapGetterSetter(StudyNote.prototype, "createdBy");
            initialiser.mapGetterSetter(StudyNote.prototype, "modifiedBy");
            initialiser.mapGetterSetter(StudyNote.prototype, "content");
            initialiser.mapGetterSetter(StudyNote.prototype, "createdAt");
            initialiser.mapGetterSetter(StudyNote.prototype, "modifiedAt");
        }
    ];

    static $inject = ["breezeEntity"];

    constructor(breezeEntity: IStudyNoteEntity) {
        super(breezeEntity);
    }

    static create(study?: Study) {
        return User.waitForLoaded().then(() => {
            let note = <StudyNote>asBusinessModel(BusinessModel.$defaultManager.createEntity("StudyNote"));
            note.createdBy = User.current;
            note.createdAt = new Date();
            note.study = study;
            note.content = "";
            return note;
        });
    }
}

export class BusinessModelService {
  static $inject = ["saveQueue", "whenLoaded", "loadingStatus"];
  constructor(saveQueue: SaveQueue, whenLoaded: IPromise<void>, LoadingStatus: LoadingStatusCtor) {
    this.save = saveQueue.save; //Already a bound function.
    this.saveEntities = saveQueue.saveEntities; //Already a bound function.
    this.whenLoaded = whenLoaded;
    this.load = new LoadingStatus();
    this.load.track(whenLoaded);
  }

  Status = Status;
  Modality = Modality;
  ExamType = ExamType;
  Patient = Patient;
  Study = Study;
  Exam = Exam;
  Image = Image;
  Physician = Physician;
  Technician = Technician;
  MeasurementType = MeasurementType;
  MeasurementValue = MeasurementValue;
  User = User;
  UserSetting = UserSetting;
  Role = Role;
  Diagram = Diagram;
  DiagramTemplate = DiagramTemplate;
  DiagramElement = DiagramElement;
  GlossaryCategory = GlossaryCategory;
  Glossary = Glossary;
  Institute = Institute;
  StudyPropertyDerivedFromDicom = StudyPropertyDerivedFromDicom;
  ReportFormatter = ReportFormatter;
  RuleDiagramElement = RuleDiagramElement;
  Agent = Agent;
  Job = Job;
  DicomDeviceConfiguration = DicomDeviceConfiguration;
  DicomDevice = DicomDevice;
  DicomDeviceModel = DicomDeviceModel;
  DicomSrFieldMapping = DicomSrFieldMapping;
  DicomSrFieldMappingConditionType = DicomSrFieldMappingConditionType;
  DicomSrFieldMappingConditionNode = DicomSrFieldMappingConditionNode;
  PostProcessType = PostProcessType;
  Site = Site;
  StudyNote = StudyNote;
  Practice = Practice;
  PersonTitleType = PersonTitleType;
  /** Save a model change to breeze with optional save queueing.
   * @param modelUpdate: A function or promise which makes some model changes which will be applied
   *   before breeze is asked to save changes. If a promise, then the queue will wait on it before
   *   saving. Can also be null, indicating no save action.
   * @param allowQueueing: Whether the update and save should be applied later after any pending
   *   changes have completed. If no and saves are pending, then this call will throw.
   * @param applyUpdateNow: Whether to apply the model update immediately if the save needs to be
   *   queued. This is usually desired so that the user can see the update in the UI, even if it
   *   must then be reapplied and saved later once the queue flushes. Ignored if modelUpdate is
   *   a promise. */
  save: SaveFunction;
  /** Save a set of models, with save queueing. Be careful of using this, as it can be surprising
   * where a change actually takes effect. Only use it for simple entities where you're sure that
   * any changes are purely isolated to non-navigation properties on those entities.
   * @param models: The models to save. */
  saveEntities: SaveQueue["saveEntities"];
  load: LoadingStatus;
  /** Reference to the raw breeze entity manager used by the business models by default. */
  get breeze(): breeze.EntityManager {
    return BusinessModel.$defaultManager;
  }
  /** A promise which resolves once all metadata and initial values have finished loading,
   * indicating the system is ready for normal operation. */
  whenLoaded: IPromise<void>;
  /** A promise which resolves when a user logs in and their information has been retrieved
   * from the server. This currently contains a bug, and won't change when the user logs out.
   * It should not be relied upon. */
  get userLoaded() {
    return User.waitForLoaded();
  }

  /** Indicates that the result we're after should be a business model and
   * automatically converts any breeze entities or arrays thereof into business
   * models.
   */
  asBusinessModel<T extends Entity>(value: T): BusinessModel<T>;
  /** Indicates that the result we're after should be a business model and
   * automatically converts any breeze entities or arrays thereof into business
   * models.
   */
  asBusinessModel<T extends Entity>(value: T[]): BusinessModel<T>[];
  /** Indicates that the result we're after should be a business model and
   * automatically converts any breeze entities or arrays thereof into business
   * models.
   */
  asBusinessModel<T>(value: T): T;

  asBusinessModel(value: any): any {
    return asBusinessModel(value);
  }

  static asBusinessModel = asBusinessModel;

  /** Indicates that the result we're after should be a breeze entity and
   *  automatically converts any business models or arrays thereof into breeze
   *  entities. */
  asBreezeEntity<TEntity extends Entity>(value: BusinessModel<TEntity>): TEntity;
  /** Indicates that the result we're after should be a breeze entity and
   *  automatically converts any business models or arrays thereof into breeze
   *  entities. */
  asBreezeEntity<TEntity extends Entity>(value: BusinessModel<TEntity>[]): TEntity[];
  /** Indicates that the result we're after should be a breeze entity and
   *  automatically converts any business models or arrays thereof into breeze
   *  entities. */
  asBreezeEntity(value: IBusinessModel): Entity;
  /** Indicates that the result we're after should be a breeze entity and
   *  automatically converts any business models or arrays thereof into breeze
   *  entities. */
  asBreezeEntity(values: IBusinessModel[]): Entity[];
  /** Indicates that the result we're after should be a breeze entity and
   *  automatically converts any business models or arrays thereof into breeze
   *  entities. */
  asBreezeEntity<T>(value: T): T;

  asBreezeEntity(value: any): any {
    return asBreezeEntity(value);
  }

  static asBreezeEntity = asBreezeEntity
}

type SaveFunction = {
  /** Track all saves made through this API in the saving status object. Any save errors
   * are printed to the console. */
  status: LoadingStatus
} & ((modelUpdate?: Function | IPromise<any>, allowQueueing?: boolean, applyUpdateNow?: boolean) => IPromise<void>);

/** Handles most saves including queueing. */
export class SaveQueue {
  private $saveQueue: IPromise<any> = null;

  static $inject = ["loadingStatus", "$q", "$log"];
  constructor(
    LoadingStatus: LoadingStatusCtor,
    private readonly $q: angular.IQService,
    private readonly $log: ILogService
  ) {
    this.save.status = new LoadingStatus();
  }

  /** Save a model change to breeze with optional save queueing.
  * @param modelUpdate: A function or promise which makes some model changes which will be applied
  *   before breeze is asked to save changes. If a promise, then the queue will wait on it before
  *   saving. Can also be null, indicating no save action.
  * @param allowQueueing: Whether the update and save should be applied later after any pending
  *   changes have completed. If no and saves are pending, then this call will throw.
  * @param applyUpdateNow: Whether to apply the model update immediately if the save needs to be
  *   queued. This is usually desired so that the user can see the update in the UI, even if it
  *   must then be reapplied and saved later once the queue flushes. Ignored if modelUpdate is
  *   a promise. */
  save = <SaveFunction>((modelUpdate: Function | IPromise<any>, allowQueueing = true, applyUpdateNow = true): IPromise<void> => {
    let sq = this.$saveQueue;
    if (sq != null && !allowQueueing) {
      throw new Error("Save queueing is disallowed, but there is a pending save");
    }

    sq = sq || this.$q.resolve();
    if (applyUpdateNow && typeof modelUpdate === "function") {
      const result = modelUpdate();
      sq = sq.then(() => this.$q.when(result));
    } else {
      sq = sq.then(() => typeof modelUpdate === "function" ? this.$q.when(modelUpdate()) : modelUpdate);
    }

    sq = sq.then(() => <IPromise<breeze.SaveResult>> <unknown> BusinessModel.$defaultManager.saveChanges());
    this.thenLogSaveError(sq);

    this.$saveQueue = sq;
    //After each promise finishes, check whether it was the final promise in the queue, and
    //clear the queue if so to avoid hanging on to garbage.
    const clearEmptyQueue = () => { if (this.$saveQueue === sq) { this.$saveQueue = null; } };
    sq.then(clearEmptyQueue, clearEmptyQueue);

    this.save.status.track(sq);
    return sq;
  });

  private thenLogSaveError(promise: IPromise<any>): void {
    promise.catch(ex => this.$log.error("businessModels.saveChanges() error", ex));
  }

  /** Save a set of models, with save queueing. Be careful of using this, as it can be surprising
   * where a change actually takes effect. Only use it for simple entities where you're sure that
   * any changes are purely isolated to non-navigation properties on those entities.
  * @param models: The models to save. */
  saveEntities = (models: (IBusinessModel | Entity)[]): IPromise<void> => {
    let sq = this.$saveQueue || this.$q.resolve();

    sq = sq.then(() => <any>BusinessModel.$defaultManager.saveChanges(<any>asBreezeEntity(models)));
    this.thenLogSaveError(sq);

    this.$saveQueue = sq;
    //After each promise finishes, check whether it was the final promise in the queue, and
    //clear the queue if so to avoid hanging on to garbage.
    const clearEmptyQueue = () => { if (this.$saveQueue === sq) { this.$saveQueue = null; } };
    sq.then(clearEmptyQueue, clearEmptyQueue);

    this.save.status.track(sq);
    return sq;
  };
}


type GetByArgs<T> = {
  /** Get by args can be a single value to match, or an array of values which are combined so that
   * any of them must match. */
  [Key in keyof T]?: T[Key] | Array<T[Key]>
} & {
    isDeleted?: boolean;
    [nestedPath: string]: any
  }

export interface BusinessModelCtor<TModel extends BusinessModel<Entity>> {
  new (...args: any[]): TModel;
  $init?: any | any[];
  $inject?: string[];
};
export interface BusinessModelCtorWithQueries<TEntity extends Entity, TModel extends BusinessModel<TEntity>> extends BusinessModelCtor<TModel> {
  /** Issues a query to find a single entity of a type. The query can be filtered with an object
   * with values matching the desired object.
   * @param getBy The object from which to build a query. The behaviour is strange in that if not
   * provided then no filtering is done, but if an object is provided and it doesn't set
   * `isDeleted`, then the query is sent with a filter for entities with `isDelete == false` in
   * addition to the other filter values in the object.
   * @param expand The breeze expand string for navigation properties, or a list of them. */
  find(getBy?: GetByArgs<TEntity>, expand?: string | string[]): IPromise<TModel>;
  list(getBy?: GetByArgs<TEntity>, limit?: number): IPromise<TModel[]>;
  /** Issues a query to list all entities of a type. The query can be filtered with an object with
   * values matching the desired objects.
   * @param getBy The object from which to build a query. The behaviour is strange in that if not
   * provided then no filtering is done, but if an object is provided and it doesn't set
   * `isDeleted`, then the query is sent with a filter for entities with `isDelete == false` in
   * addition to the other filter values in the object.
   * @param expand The breeze expand string for navigation properties, or a list of them.
   * @param limit Limits the number of query results returned from the server. This should generally
   * be provided, and if not, a warning is printed to the console. */
  list(getBy?: GetByArgs<TEntity>, expand?: string | string[], limit?: number): IPromise<TModel[]>;
  /** Issues a query to count all entities of a type. The query can be filtered with an object with
   * values matching the desired objects.
   * @param getBy The object from which to build a query. The behaviour is strange in that if not
   * provided then no filtering is done, but if an object is provided and it doesn't set
   * `isDeleted`, then the query is sent with a filter for entities with `isDelete == false` in
   * addition to the other filter values in the object.
   * @param expand The breeze expand string for navigation properties, or a list of them. */
  count(getBy?: GetByArgs<TEntity>): IPromise<number>;
};

/** Provides common functionality for initialising business models. */
export class ModelInitialiser {
  static $inject = ["$parse", "$q", "$log"];
  constructor(private $parse: angular.IParseService, private $q: angular.IQService, private $log: angular.ILogService) { }

  /**
    * Builds a standard query stub for the provided type. Own properties of 'getByArgs' are used
    * to add where clauses, and 'expand' is used as the expand string if provided. Additional
    * query terms can be added after this returns. If a value is an array then its values are
    * combined to form an or statement for that property. Otherwise all specified properties are
    * anded.
    * @param typeName
    * @param getByArgs
    * @param expand
    */
  private buildQuery<TEntity extends Entity>(endPoint: string, getByArgs?: GetByArgs<TEntity>, expand?: string | string[]) {
    let query = breeze.EntityQuery.from(endPoint);
    if (expand != null) {
      query = query.expand(<string>expand); //https://github.com/Microsoft/TypeScript/issues/1805
    }
    if (getByArgs) {
      for (var findWhereProperty of Object.keys(getByArgs)) {
        let hasThisValue = getByArgs[findWhereProperty];
        if (angular.isArray(hasThisValue)) {
          if (hasThisValue.length > 0) {
            let disjunctOptions = (hasThisValue.map((option) => breeze.Predicate.create(findWhereProperty, "==", option)));
            query = query.where(breeze.Predicate.or(disjunctOptions));
          } else {
            // If we specify that a member must match one of an array and the array is empty, then
            // prevent the query being executed as it can't match anything.
            return null;
          }
        } else {
          query = query.where(findWhereProperty, "==", hasThisValue);
        }
      }
    }
    return query;
  }

  /** Defines find(), list(), and count() on the provided object, with various options.
   * @param obj The object to define the functions on.
   * @param debSet The name of the controller action for getting the data.
   * @param expansions Default expansion if none is provided to find(), list(), or count()
   * directly.
   */
  defineBasicQueries<TEntity extends Entity, TModel extends BusinessModel<TEntity>>(
    obj: BusinessModelCtorWithQueries<TEntity, TModel>,
    debSet: string,
    expansions?: string | string[]) {
    obj.find = (getBy?: GetByArgs<TEntity>, expand: string | string[] = expansions) => {
      let query = this.buildQuery<TEntity>(debSet, getBy, expand);
      if (query == null) {
        return this.$q<TModel>(undefined);
      }
      query = query.take(1);
      return new this.$q<TModel>((resolve, reject) =>
        BusinessModel.$defaultManager.executeQuery(query).then(
          data => resolve(<TModel>asBusinessModel(<TEntity>data.results[0])),
          reject));
    };
    obj.list = (getBy?: GetByArgs<TEntity>, expand: number | string | string[] = expansions, limit?: number) => {
      if (typeof expand === "number") {
        limit = expand;
        expand = expansions;
      }
      let query = this.buildQuery<TEntity>(debSet, getBy, expand);
      if (query == null) {
        return this.$q.when<TModel[]>([]);
      }
      if (limit == null) {
        this.$log.warn("BusinessModel.list() called without a limit. Please choose a reasonable limit to avoid unintentionally large query results.", query);
      } else {
        query = query.take(limit);
      }
      return new this.$q<TModel[]>((resolve, reject) =>
        BusinessModel.$defaultManager.executeQuery(query).then(
          data => resolve(<TModel[]>asBusinessModel(<TEntity[]>data.results)),
          reject));
    };
    obj.count = (getBy?: GetByArgs<TEntity>) => {
      let query = this.buildQuery<TEntity>(debSet, getBy);
      if (query == null) {
        return this.$q.when(0);
      }
      query = query.take(0).inlineCount();
      return new this.$q<number>((resolve, reject) =>
        BusinessModel.$defaultManager.executeQuery(query).then(
          data => resolve(data.inlineCount),
          reject));
    };
  }

  /** Creates a mapping for a given path on a model to a getter or setter or both if provided. */
  manualGetterSetter<
    TModel extends BusinessModel<TEntity>,
    TEntity extends Entity,
    TModelKey extends Extract<keyof TModel, string>>(
      obj: TModel,
      modelPath: TModelKey,
      getter?: (this: TModel, entity: TEntity) => TModel[TModelKey] | Entity | Entity[],
      setter?: (this: TModel,entity: TEntity, value: TModel[TModelKey] | Entity | Entity[]) => void): GetterSetterDefinition {
    if ((getter == null) && (setter == null)) { throw new Error("Must have a getter or setter"); }
    let paths = modelPath.split(".");
    let propertyName = paths.pop();
    let propertyDefinition = {
      enumerable: true,
      get: (getter != null) ? function (this: TModel) { return asBusinessModel<TModel[TModelKey]>(getter.call(this, this.breezeEntity())); } : undefined,
      set: (setter != null) ?
          function (this: TModel, value: TModel[TModelKey]) {
              return setter.call(this, this.breezeEntity(), asBreezeEntity(value));
        }
        :
        undefined
    };
    Object.defineProperty(this.getPath(obj, paths), propertyName, propertyDefinition);
    return {
      modelPath,
      getter: (getter != null),
      setter: (setter != null)
    };
  };

  /**
   * Creates a mapping for a given path on a model to a getter. When the getter is called the
   * result is cached if it is not-nullish and returned for subsequent calls instead of calling
   * the getter again. If the result is nullish then the getter is used for subsequent calls
   * until it returns a non-nullish value.
   * See the comment inside the function for ways to refresh a cached value.
   */
  manualCachedGetter<TModel extends BusinessModel<TEntity>, TEntity extends Entity, TModelKey extends Extract<keyof TModel, string>>(obj: TModel, modelPath: TModelKey, getter: (entity: TEntity) => TModel[TModelKey] | Entity | Entity[]): GetterSetterDefinition {
    let paths = modelPath.split(".");
    let propertyName = paths.pop();
    let propertyDefinition = {
      enumerable: true,
      configurable: true,
      get(this: TModel) {
        let result = asBusinessModel(getter.call(this, this.breezeEntity()));
        if (result != null) {
          //Redefine this property as a readonly value using the result on the calling
          //instance so that future calls just use that. If 'this' is the same as 'obj' then
          //the cached value is permanent unless reconfigured. If 'this' is an instance and
          //'obj' is its prototype, then deleting the cached value on 'this' will force it
          //to be regenerated when next called, which is pretty damn neat.
          Object.defineProperty(this, propertyName, {
            enumerable: true,
            configurable: true,
            value: result
          }
          );
        }
        return result;
      }
    };
    Object.defineProperty(this.getPath(obj, paths), propertyName, propertyDefinition);
    return {
      modelPath,
      getter: true,
      caching: true
    };
  };

  /** Creates a mapping from a dotted path on the business model which maps to an angular
   * expression on the breeze entity on each call. The result of the expression is returned. */
  mapGetter<TEntity extends Entity, TModel extends BusinessModel<TEntity>, TModelKey extends Extract<keyof TModel, string>>(obj: TModel, modelPath: TModelKey, breezePath: string = modelPath): GetterSetterDefinition {
    let getter = this.$parse(breezePath);
    let mapping = this.manualGetterSetter(obj, modelPath, getter);
    mapping.breezePath = breezePath;
    return mapping;
  };

  /** Creates a mapping from a dotted path on the business model which maps to an angular
  * expression on the breeze entity. If a non-nullish value is returned then it is cached
  * and returned for future calls without the expression being used again. Otherwise
  * the expression is used until it returns a non-nullish value. */
  mapCachedGetter<TEntity extends Entity, TModel extends BusinessModel<TEntity>, TModelKey extends Extract<keyof TModel, string>>(obj: TModel, modelPath: TModelKey, breezePath: string = modelPath): GetterSetterDefinition {
    let getter = this.$parse(breezePath);
    let mapping = this.manualCachedGetter(obj, modelPath, getter);
    mapping.breezePath = breezePath || modelPath;
    return mapping;
  };

  /** Creates a mapping from a dotted path on the business model which maps to an angular
   * expression on the breeze entity on each call. The expression must be assignable so that
   * assignments to the business model mapping can write through. */
  mapGetterSetter<TEntity extends Entity, TModel extends BusinessModel<TEntity>, TModelKey extends Extract<keyof TModel, string>>(obj: TModel, modelPath: TModelKey, breezePath: string = modelPath): GetterSetterDefinition {
    let getter = this.$parse(breezePath);
    if (!getter.assign) { throw new Error("Expression is not assignable"); }
    let mapping = this.manualGetterSetter(obj, modelPath, getter, getter.assign);
    mapping.breezePath = breezePath;
    return mapping;
  };

  /**
   * Navigates to the very end of the dotted 'modelPath' and returns the final element. If any
   * element in the path is null or undefined then it is assigned an empty object.
   * @param model The model to navigate.
   * @param modelPath The path to navigate to get a value.
   */
  private getPath(model: any, modelPath: string | string[]) {
    const paths = angular.isString(modelPath) ? modelPath.split(".") : modelPath;
    return paths.reduce((tip, path) => tip[path] != null ? tip[path] : (tip[path] = {}), model);
  };
}

/** Return type of many functions in ModelInitialiser, providing information on the property
 * that was defined by the function. */
interface GetterSetterDefinition {
  modelPath: string,
  getter: boolean,
  setter?: boolean,
  caching?: boolean,
  breezePath?: string;
}


interface IInitialData {
  statuses: IStatusEntity[];
  autoLogin: boolean;
  logging: INotificationSettings;
}

interface INotificationSettings {
  enabled: boolean;
  level: LogLevel;
}

const modelTypeMappings = {
  Role: Role,
  User: User,
  UserSetting: UserSetting,
  Physician: Physician,
  Technician: Technician,
  Image: Image,
  StudyType: ExamType,
  Exam: Exam,
  StudyChart: StudyChart,
  Modality: Modality,
  Patient: Patient,
  PatientDuplicateHistory: PatientDuplicateHistory,
  StudyStatus: Status,
  Study: Study,
  MeasurementType: MeasurementType,
  MeasurementValue: MeasurementValue,
  Diagram: Diagram,
  DiagramTemplate: DiagramTemplate,
  DiagramElement: DiagramElement,
  GlossaryCategory: GlossaryCategory,
  Glossary: Glossary,
  ReportFormatter: ReportFormatter,
  RuleDiagramElement: RuleDiagramElement,
  Agent: Agent,
  Job: Job,
  StudyDocument: StudyDocument,
  PatientDocument: PatientDocument,
  DicomDeviceConfiguration: DicomDeviceConfiguration,
  DicomDevice: DicomDevice,
  DicomDeviceModel: DicomDeviceModel,
  Institute: Institute,
  DicomSrFieldMapping: DicomSrFieldMapping,
  DicomSrFieldMappingConditionNode: DicomSrFieldMappingConditionNode,
  DicomMappingPostProcess: DicomMappingPostProcess,
  PostProcessType: PostProcessType,
  DicomSrFieldMappingConditionType: DicomSrFieldMappingConditionType,
  Site: Site,
  InstituteSetting: InstituteSetting,
  StudyPropertyDerivedFromDicom: StudyPropertyDerivedFromDicom,
  StudyNote: StudyNote,
  Practice: Practice,
  PersonTitleType: PersonTitleType
};

export default angular.module("midas.business-models", [
  utilityModule.name,
  authServiceModule.name,
  pdfJsModule.name,
  breezeModule.name,
  momentModule.name
])
  .factory("businessModels",
    ["$q", "breeze", "$injector", "$window", "$log", "moment", errorsServiceName,
      function ($q: angular.IQService, breeze: BreezeStatic, $injector: angular.auto.IInjectorService,
        $window: angular.IWindowService, $log: angular.ILogService, moment: moment.MomentStatic, errors: IErrorNotificationService) {
        //Performs the work of initialising breeze entities with our business models, and getting metadata
        //and initial values from the server.
        function initialiseBreeze(): [SaveQueue, IPromise<void>] {
          breeze.config.initializeAdapterInstance("modelLibrary", "backingStore", true);
          breeze.NamingConvention.camelCase.setAsDefault();
          if (BusinessModel.$defaultManager == null) {
            //For some reason the inheritance of static variables isn't working, so this copies it
            //forward to the registered model types.
            BusinessModel.$defaultManager = new breeze.EntityManager("api/midas");
            for (var typeName of Object.keys(modelTypeMappings)) {
              modelTypeMappings[typeName].$defaultManager = BusinessModel.$defaultManager;
            }
          }
          const modelInitialiser = <ModelInitialiser>$injector.instantiate(ModelInitialiser);
          const saveQueue = <SaveQueue>$injector.instantiate(SaveQueue);

          /** Gets a factory function for a business model constructor, which handles calling the
           * injector if the business model requires it.
           * @param businessModelCtor The business model constructor. */
          function getModelFactory(businessModelCtor: BusinessModelCtor<BusinessModel<Entity>>): (entity: breeze.Entity) => BusinessModel<Entity> {
            if (angular.isArray(businessModelCtor.$inject) && businessModelCtor.$inject.length > 0) {
              return (entity: breeze.Entity) =>
                <BusinessModel<Entity>>$injector.instantiate(businessModelCtor, { breezeEntity: entity, saveQueue });
            } else {
              return (entity: breeze.Entity) => <BusinessModel<Entity>>new businessModelCtor(entity);
            }
          }

          const typeInitLocals = {
            modelInitialiser,
            saveQueue
          };

          const initialisers = [];

          /** Initialises the provided type constructor by calling it's static $init() function if
           * available, including respecting angular injection specs. If it's just a plain function,
           * no injection is performed and the model initialiser is passed.
           * @param businessModelCtor The business model constructor. */
          function initialiseType(businessModelCtor: BusinessModelCtor<BusinessModel<Entity>>): void {
            const init = businessModelCtor.$init;
            //Avoid double initialisation when a base class specifies a type initialiser, but a
            //derived type does not, so it inherits the base's.
            if (initialisers.indexOf(init) >= 0) { return; }
            initialisers.push(init);
            if (angular.isFunction(init)) {
              if (angular.isArray(init.$inject)) {
                $injector.invoke(init, businessModelCtor, typeInitLocals);
              } else {
                init.call(businessModelCtor, modelInitialiser);
              }
            } else if (angular.isArray(init) && init.length > 0) {
              //Angular typings are wrong. This definitely takes an array.
              $injector.invoke(<any>init, businessModelCtor, typeInitLocals);
            }
          }

          //Register models with breeze so that breeze entities are created with attached business models.
          //Creates a new class to house the breeze entity and provide a mapping to a specific business
          //model, then registers it with Breeze.
          function registerBusinessModelWithBreeze(name: string, businessModelCtor: new (entity: breeze.Entity) => BusinessModel<Entity>) {
            initialiseType(businessModelCtor);
            const factory = getModelFactory(businessModelCtor);
            class BreezeEntity implements Entity {
              businessModel: () => BusinessModel<this>;
              entityAspect: breeze.EntityAspect;
              entityType: breeze.EntityType;
              constructor() {
                factory(this); // Sets up both businessModel() & breezeModel()
              }
            }
            return BusinessModel.$defaultManager.metadataStore.registerEntityTypeCtor(name, BreezeEntity);
          };

          for (var entityName of Object.keys(modelTypeMappings)) {
            registerBusinessModelWithBreeze(entityName, modelTypeMappings[entityName]);
          }

          //Use the moment date parser to get the provided date. It parses as local instead of UTC.
          breeze.DataType.parseDateFromServer = source => moment(source).toDate();

          const whenLoaded = new $q<void>((resolve, reject) => {
            BusinessModel.$defaultManager.fetchMetadata()
              // Run some checks to make sure the business model entity names match the server
              // side entities. If we rename a server side entity, these may no longer match, and
              // the business model constructor won't be run by breeze on the client side.
              .then(() => {
                for (var entityName of Object.keys(modelTypeMappings)) {
                  if (BusinessModel.$defaultManager.metadataStore.getEntityType(entityName, true) == null) {
                    $log.error(`Business model entity type constructor name does not match server entity: ${entityName}.`);
                  }
                }
              })
              // Load and insert the initial data dump, containing most of the useful immutable data
              // for the system.
              .then(() => BusinessModel.$defaultManager.executeQuery(breeze.EntityQuery.from("InitialData")).then(function (data: any) {
                let results: IInitialData = data.results[0];
                const statuses: IStatusEntity[] = results != null ? results.statuses : undefined;
                if (statuses == null) {
                  $log.error("No statuses returned in initial data");
                } else {
                  Status.$cached = <Status[]>asBusinessModel(statuses);
                }
                if(results) {
                  User.autoLoginEnabled = results.autoLogin === true;
                  if(results.logging) {
                    errors.enabled = results.logging.enabled === true;
                    errors.notificationLevel = results.logging.level;
                  }
                }

                resolve();
              }, reject));
          });

          return [saveQueue, whenLoaded];
        };

        const [saveQueue, whenLoaded] = initialiseBreeze();

        // attach the Breeze saving status to the window so that it can be examined by our Selenium tests
        // this is important so that our tests can check to see if Breeze has completed operations
        $window["breezeSavingStatus"] = saveQueue.save.status;

        //Return an API with all available business models, and a reference to the breeze entity manager.
        return $injector.instantiate(BusinessModelService, {
          saveQueue,
          whenLoaded
        });
      }]);