import { module as ngModule, IAttributes, IScope } from "angular";
import {
  TinymceConfigurationController, tinymceConfigurationRequire, ToolbarAttributes
} from "../mds-rich-text-editor.component";
import * as tinymce from "tinymce";

const modeButtonId = "outline-mode-toggle";

/** Component to show a button to toggle outline mode which applies a style to show each condition on its own line. */
class OutlineModeController extends TinymceConfigurationController {
  active = true;
  button: tinymce.ui.Control;
  static $inject = ["$scope", "$attrs"];
  constructor(private readonly $scope: IScope, private readonly $attrs: IAttributes) {
    super();
  }

  setup(editor: tinymce.Editor) {
    const _this = this;
    editor.addButton(modeButtonId, {
      icon: "fa fa-reorder",
      tooltip: "Toggle Outline Mode",
      onclick() {
        _this.$scope.$apply(() => {
          _this.active = !_this.active;
          _this.update(editor);
        });
      },
      onPostRender() {
        _this.$scope.$apply(() => {
          _this.button = <any> this;
          _this.update(editor);
        });
      }
    });
  }

  configure(options: tinymce.Settings) {
    const toolbar = ToolbarAttributes.FromAttributes(this.$attrs);
    this.extendToolbar(toolbar.id, modeButtonId, options);
  }

  private update(editor: tinymce.Editor): void {
    const element = editor.getElement();
    if (this.active) {
      element.classList.add("mds-outline");
    } else {
      element.classList.remove("mds-outline");
    }
    this.button.active(this.active);
  }
}

export default ngModule("midas.utility.tinymce.outlineModePlugin", [])
  .component("outlineMode", {
    require: {
      ...tinymceConfigurationRequire
    },
    controller: OutlineModeController
  });