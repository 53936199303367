import * as angular from "angular";
import "angular-material";
import "angular-ui-router";
import { PromptLoseChangesAndNavigateService } from "../midas/studies/promptLoseChangesAndNavigate";
import { BusinessModelService } from "../businessModels";

class AppUpdatePromptController implements angular.IController {

    private currentVersion: string;
    private fistTimeFlag: boolean;
    private timer: any;

    static $inject = [
        "$window",
        "$mdDialog",
        "$http",
        "$interval",
        "$state",
        "promptLoseChangesAndNavigate",
        "businessModels"
    ];
    constructor(
        private readonly $window: angular.IWindowService,
        private readonly $mdDialog: angular.material.IDialogService,
        private readonly $http: angular.IHttpService,
        private readonly $interval: angular.IIntervalService,
        private readonly $state: angular.ui.IStateService,
        private readonly promptLoseChanges: PromptLoseChangesAndNavigateService,
        private readonly models: BusinessModelService
    ) { }

    $onInit(): void {
        this.fistTimeFlag = true;
        this.timer = this.$interval(() => {
            if (this.$state.includes("midas")) {
                this.checkForUpdates();
            }
        }, 60000, 0, false);
    }

    $onDestroy(): void {
        this.$interval.cancel(this.timer);
    }

    private checkForUpdates(): angular.IPromise<void> {
        return this.$http.get("api/about/ServerVersion")
            .then(response => {
                if (this.fistTimeFlag === true) {
                    this.fistTimeFlag = false;
                }
                else {
                    if (this.currentVersion !== response.data) {
                        this.update();
                    }
                }
                this.currentVersion = response.data as string;
            })
    }

    private update = () => {
        this.$interval.cancel(this.timer);
        this.$mdDialog.show(
            {
                templateUrl: require('./appUpdatePrompt.html'),
                controller: DialogController,
                controllerAs: "$ctrl",
                locals: {
                    isChanged: this.promptLoseChanges.hasChanges()
                },
                clickOutsideToClose: false,
                escapeToClose: false
            }
        ).then(opt => this.reload(opt));
    }

    private reload(opt: string) {
        switch (opt) {
            case 'save':
                this.promptLoseChanges.saveOrRejectAll(true, null, null, true)
                    .then(() => this.$window.location.reload());
                break;
            case 'discard':
                this.models.breeze.rejectChanges();
                this.$window.location.reload();
                break;
            case 'reload':
                this.$window.location.reload();
                break;
            default: return;
        }
    }
}

class DialogController implements angular.IController {

    isChanged: boolean;

    static $inject = ["$mdDialog", "isChanged"];
    constructor(
        private readonly $mdDialog: angular.material.IDialogService,
        isChanged: boolean
    ) {
        this.isChanged = isChanged;
    }

    $onInit() { }

    close(opt: string) {
        this.$mdDialog.hide(opt);
    }
}

export default <angular.IComponentOptions>{
    controller: AppUpdatePromptController
}