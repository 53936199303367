import { MeasurementController, IMeasurementScope } from "../MeasurementController";
import * as angular from "angular";
class DXFootController extends MeasurementController {
  static $inject = ["$scope"];
  constructor(scope: IMeasurementScope) {
    super(scope);
  }

  clickRightOK() {
    this.setMeasurementValue("DXFootRtBones", "No fracture or dislocation is present. ");
    this.setMeasurementValue("DXFootRtSoftTissue", "Normal. ");
    this.setMeasurementValue("DXFootRtJoints", "The joint spaces are normal. ");
    this.setMeasurementValue("DXFootRtOtherComment", "None. ");
    console.log("Right All normal clicked")
  }

  clickLeftOK() {
    this.setMeasurementValue("DXFootLtBones", "No fracture or dislocation is present. ");
    this.setMeasurementValue("DXFootLtSoftTissue", "Normal. ");
    this.setMeasurementValue("DXFootLtJoints", "The joint spaces are normal. ");
    this.setMeasurementValue("DXFootLtOtherComment", "None. ");
    console.log("Left All normal clicked")
  }
}

angular.module("midas.utility.measurement.views").controller("DXFootCtrl", DXFootController);
