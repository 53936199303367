/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import * as angular from "angular";
angular.module("midas.utility.measurement.views").controller("STDECGCtrl", ["$scope", function($scope) {
    const { values } = $scope.measurements;
    const selectLocation = function() {
//       currentValue = 0
        console.log("Location clicked");
//       alert('Old value: ' + currentValue)
//       alert('New value: ' + location.value)
//       currentValue = location.value
//       values.ECGLocation.value = location.value
        values.ECGLocation.value= $('input[name=location]:checked').val();
        console.log(`Location = ${values.ECGLocation.value}`);
    };
    const selectRhythm = function() {
        //values.ECGRhythm.value= "Christine's Rhythm"
        values.ECGRhythm.value= $('input[name=rhythm]:checked').val();
        console.log(`Rhythm = ${values.ECGRhythm.value}`);
    };
    $scope.clickMe = () => selectLocation();
    $scope.clickMe1 = () => selectRhythm();
}
]);
// CoffeeScript
