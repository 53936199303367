/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import * as angular from "angular";
angular.module("midas.utility.measurement.views").controller("FCUSCtrl", ["$scope", function($scope) {
 const { values } = $scope.measurements;
 const setAllNormal = function()  {
   values.FCLVSize.value = "normal";
   values.FCLVFunction.value = "normal";
   values.FCRVSize.value = "normal";
   values.FCIVC.value = "normal";
   values.FCPericardium.value = "no fluid present";
   console.log("All Normal");
 };
 $scope.clickAllNormal = () => setAllNormal();
}
]);
// CoffeeScript
