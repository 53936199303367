import * as angular from "angular";
import { ObstetricsMeasurementsControllerBase } from "./STDOBSBaseCtrl";
import { IMeasurementScope } from "../MeasurementController";
import { LoDashStatic } from "lodash";

var module = angular.module("midas.utility.measurement.views");

export class Trimester3Controller extends ObstetricsMeasurementsControllerBase {
    static $inject = ["$scope", "lodash"];

    constructor(scope: IMeasurementScope, lodash: LoDashStatic) {
      super(scope, lodash);
      this.watch();
    }
    watch(): void {
      console.log("Starting");
      this.watchMeasurement("F1FHR", () => this.setFHM("F1"));
      this.watchMeasurement("F2FHR", () => this.setFHM("F2"));
      this.watchMeasurement("F3FHR", () => this.setFHM("F3"));
      this.watchMeasurement("F4FHR", () => this.setFHM("F4"));
      this.watchMeasurement("F5FHR", () => this.setFHM("F5"));
    }
    setFHM(fetusTag: string): void {
      const hr: number = Number(this.getMeasurementValue(fetusTag + "FHR"));
      if (isNaN(hr)) { return; }
      this.setMeasurementValue(fetusTag + "FHM", "Yes");
    }
};

module.controller("STDOBSTrimester3Ctrl", Trimester3Controller);