import ReferenceGroup from "./ReferenceGroup";
import { IQService } from "angular";
import { Study } from "../../../businessModels";
import * as thumbnailUrl from "../templates/reportThumbnail.html";
import * as fullUrl from "../templates/reportFull.html";

export default class StudyReportGroup extends ReferenceGroup {
    constructor(public study: Study, private $q: IQService) {
        super("Reports", thumbnailUrl, fullUrl);

        this.iconClass = "glyphicon glyphicon-pencil";
    }

  loadThumbnails() {
      return this.$q.when(
        this.study.exam.loadLatestConclusion()
          .then(report => {
              this.thumbnails = [report];
              return this.thumbnails;
          })
      );
   }
}