import { ILogService, module as ngModule } from "angular";
import { Settings } from "tinymce";
import reportEditorModule, {
    TinymceConfigurationController,
    tinymceConfigurationRequire
} from "../mds-rich-text-editor.component";

class BlueprintMeasurementPluginController extends TinymceConfigurationController {
    static extendedValidElements: string[] = [
        "report-measurement[key|contenteditable]"
    ];

    static $inject = ["$log"];
    constructor(private readonly $log: ILogService) {
      super();
    }

    configure(options: Settings) {
        try {
            if (typeof options.extended_valid_elements === "string"
                && options.extended_valid_elements.indexOf("report-measurement") >= 0) {
                this.$log.info("<report-measurement> directive already registered with extend_valid_elements.");
            } else {
                this.extendValidElements(
                  BlueprintMeasurementPluginController.extendedValidElements.join(","),
                    options);
            }
        } catch (err) {
            this.$log.error("Couldn't extend tinymce valid elements", err);
        }
    }
}

export default ngModule("midas.utility.tinymce.blueprint-measurement", [reportEditorModule.name])
.component("blueprintMeasurement", {
    require: {
        ...tinymceConfigurationRequire
    },
    controller: BlueprintMeasurementPluginController
});