import ChartGroup from "./groups/ChartGroup";
import DiagramGroup from "./groups/DiagramGroup";
import DocumentGroup from "./groups/DocumentGroup";
import FinalReportGroup from "./groups/FinalReportGroup";
import ImageGroup from "./groups/ImageGroup";
import MeasurementGroup from "./groups/MeasurementGroup";
import NoteGroup from "./groups/NotesGroup";
import StudyReportGroup from "./groups/StudyReportGroup";
import ReferenceGroup from "./groups/ReferenceGroup";
import NoteService from "../studies/notes/noteService"
import { BusinessModelService, Study } from "../../businessModels";
import { HasPendingJobsService } from "../../utility/utils";
import { LoDashStatic } from "lodash";

export default class ReferenceService {
    static $inject = ["$rootScope", "businessModels", "lodash", "$q", "$state", "noteService", "hasPendingJobs"];

    constructor(private $rootScope: angular.IScope,
        private businessModels: BusinessModelService,
        private lodash: LoDashStatic,
        private $q: angular.IQService,
        private $state: angular.ui.IStateService,
        private noteService: NoteService,
        private hasPendingJobs: HasPendingJobsService) {
    }

    startPolling(category: string) {
        this.$rootScope.$broadcast("reference-polling", { category: category });
    };

    select(category: string, id?: any, subCategory?: string) {
        this.$rootScope.$broadcast("reference-select", { category: category, id: id, subCategory: subCategory });
    };

    refresh(ifCategory: string, backToGroup: boolean = false) {
        //Refresh if current given category is being viewed
        this.$rootScope.$broadcast("reference-refresh", { ifCategory: ifCategory, backToGroup: backToGroup });
    };

    reset(id?: any) {
        this.$rootScope.$broadcast("reference-reset", { id: id });
    };

    listGroups(study: Study, currentStudy: Study): Array<ReferenceGroup> {
        return <ReferenceGroup[]>[
            new ImageGroup(study, this.businessModels, this.lodash),
            new MeasurementGroup(study, this.$q),
            new ChartGroup(study, this.businessModels, this.lodash, this.$q),
            new DiagramGroup(study,
                currentStudy,
                this.businessModels,
                this.$rootScope,
                this.lodash,
                this.$q,
                this.$state),
            new StudyReportGroup(study, this.$q),
            new FinalReportGroup(study, this.$q),
            new DocumentGroup(study, this.$q),
            new NoteGroup(study, this.$q, this.noteService)
        ];
    };
}