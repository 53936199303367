import * as CryptoJS from "../../Scripts/NonNuget/sha1";
import "Scripts/NonNuget/enc-base64-min";

export class Crypto {
    sha1(message: string, base64Encode: boolean = false) {
        const hash = CryptoJS.SHA1(message);
        if (base64Encode) {
          return hash.toString(CryptoJS.enc.Base64);
        }
        return hash;
    }
}