import { MeasurementController, IMeasurementScope } from "../MeasurementController";
import * as angular from "angular";
class DXForearmController extends MeasurementController {
  static $inject = ["$scope"];
  constructor(scope: IMeasurementScope) {
    super(scope);
  }

  clickRightOK() {
    this.setMeasurementValue("DXForearmRtOther", "Normal. ");
    this.setMeasurementValue("DXForearmConclusion", "Bones of the forearm show normal mineralization. The wrist and elbow joints are normal. There is no fracture, sclerosis or erosion. No evidence of dislocation or subluxation is seen. The soft tissues are normal. ");


    console.log("Right All normal clicked")
  }

  clickLeftOK() {
    this.setMeasurementValue("DXForearmLtOtherComment", "Normal. ");
    this.setMeasurementValue("DXForearmConclusion", "Bones of the forearm show normal mineralization. The wrist and elbow joints are normal. There is no fracture, sclerosis or erosion. No evidence of dislocation or subluxation is seen. The soft tissues are normal. ");
    console.log("Left All normal clicked")
  }
}

angular.module("midas.utility.measurement.views").controller("DXForearmCtrl", DXForearmController);
