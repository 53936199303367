import { module as ngModule } from "angular";
import { Settings, Editor } from "tinymce";
import reportEditorModule, {
    TinymceConfigurationController,
    tinymceConfigurationRequire
} from "../mds-rich-text-editor.component";
import businessModelsModule, { BusinessModelService } from '../../../businessModels';
import {
    InstituteSettingsMetadataService,
    Setting
} from '../../../config/instituteSettingsMetadata';

class InstituteSettingsPlugin extends TinymceConfigurationController {

    static $inject = ["businessModels", "instituteSettingsMetadata"];
    constructor(
        private readonly businessModels: BusinessModelService,
        private readonly instituteSettings: InstituteSettingsMetadataService) {
        super();
    }

    setup(editor: Editor) {
        this.setEditorDefaultFont(editor);
    }

    configure(options: Settings) {
        options.fontsize_formats = this.getFontSizeFormats();
        options.font_formats = this.getFontFormats();
        if (this.getSetting(this.instituteSettings.ReportEditorBreakType) === "line-break") {
            options.forced_root_block = <string> <any> false;
        }

    }

    getFontSizeFormats(): string {
        return this.getArraySettingOptions(this.instituteSettings.DefaultReportFontSize)
                   .map(x => x.label)
                   .join(" ");
    }

    getFontFormats(): string {
        return this.getArraySettingOptions(this.instituteSettings.DefaultReportFontFamily)
                   .map(x => x.label + "=" + x.value)
                   .join(";");
    }

    /** Gets the options of a setting if they are an array, otherwise an empty array. */
    getArraySettingOptions(setting: Setting) {
        if (setting.options instanceof Array) {
            return setting.options;
        }
        return [];
    }

    setEditorDefaultFont(editor: Editor): void {
        const editorElement = editor.getElement() as HTMLElement;
        const style = editorElement && editorElement.style;
        if (style) {
            style.fontSize = this.getSetting(this.instituteSettings.DefaultReportFontSize);
            style.fontFamily = this.getSetting(this.instituteSettings.DefaultReportFontFamily);
        } else {
            console.log("No style on editor content area container.");
        }
    }

    /** Gets a setting from the provided institute or the current institute if not provided. Falls
     * back to the setting default value if no setting is set for the institute. */
    getSetting(setting: Setting) {
        if (setting != null) {
            const institute = this.businessModels.Institute.current;
            return institute ? institute.getSetting(setting.key) : setting.defaultValue;
        }
        return undefined;
    }
}

export default ngModule("midas.utility.tinymce.institute-settings-plugin", [
    reportEditorModule.name,
    businessModelsModule.name
])
.component("instituteSettings", {
    require: {
        ...tinymceConfigurationRequire
    },
    controller: InstituteSettingsPlugin
});
