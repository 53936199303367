import { DisplayContext, IImageEditorScope, IShape } from "./imageEditorCore";
import * as Utils from "../../utility/utils";
import ToolManager from "./tools/toolManager";
import { ITool } from "./tools/tool";
import { createDisposable, Vector2, Events } from '../../utility/utils';
import { Traceable } from '../../utility/tracing';

export type HistoryEvents = "history-item-added" | "history-item-removed" | "history-list-changed" | "history-item-changed";

/** A class responsible for the history list, and various events from, and operations on it.
 * This class doesn't use any display index properties, instead the position of the shape in
 * the history array dictates the display order. This simplifies everything a great deal. */
export class HistoryManager extends Utils.Traceable {
  private $events: Utils.Events;
  /** The list of items in the history. Tracks changes to $scope.history. */
  items: IShape[];

  /** Fires whenever the history list changes in some way, whether it be due to the order of
   * items or an item being added or removed. */
  observe(event: "history-list-changed", callback: Utils.ObservableCallback<IShape[]>): Utils.IDisposable;
  /** Fires for individual history items when they are added or removed from the history list, or
   * when some of their properties change. */
  observe(event: "history-item-added" | "history-item-removed" | "history-item-changed",
          callback: Utils.ObservableCallback<IShape>): Utils.IDisposable;
  /** Observes any of the history events. */
  observe(event: HistoryEvents, callback: Utils.ObservableCallback<any>): Utils.IDisposable
  observe(event: HistoryEvents, callback: Utils.ObservableCallback<any>): Utils.IDisposable {
    return this.$events.observe(event, callback);
  }

  /** Brings the provided history item to the top of the view stack so that it's the last shape
   * painted to the canvas and will overwrite anything written before it.
   * @param model The element to bring to the front
   * @returns True if the model was moved, false otherwise. */
  moveToTop(model: IShape): boolean {
    if (model) {
      const items = this.items;
      const index = items.indexOf(model);
      if (index >= 0 && index !== (items.length - 1)) {
        items.splice(index, 1);
        items.push(model);
        return true;
      }
      return false
    }
  }

  /** Brings the provided history item to the bottom of the view stack so that it's the first
   * shape painted to the canvas, and will be overwritten by anything painted later.
   * @param model The element to bring to the back.
   * @returns True if the model was moved, false otherwise. */
  moveToBottom(model: IShape): boolean {
    if (model) {
      const items = this.items;
      const index = items.indexOf(model);
      if (index > 0) {
        items.splice(index, 1);
        items.unshift(model);
        return true;
      }
      return false;
    }
  }

  /** Removes the provided shape from the history.
   * @param model The shape to remove.
   * @returns true if the item was removed, false if it wasn't found. */
  remove(model: IShape): boolean {
    if (model) {
      const items = this.items;
      const index = items.indexOf(model);
      if (index >= 0) {
        items.splice(index, 1);
        return true;
      }
      return false
    }
  }

  /** Instruct the history manager that an item it tracks has changed and that it should notify
   * observers of "history-item-changed". This will generally be called automatically by the tool
   * activation context. */
  itemChanged(shape: IShape): boolean {
    if (this.items.indexOf(shape) >= 0) {
      this.$events.notify("history-item-changed", shape);
      return true;
    }
    return false;
  }

  /** Tries to render the shape with the provided tool, and if successful, adds it to the cache
   * layer. Returns whether the shape was rendered and cached, or not. */
  protected tryRenderAndCache(tool: ITool, shape: IShape) {
    const rendered = this.$tools.safeRender(tool, shape);
    if (rendered == null) {
      return false;
    }
    const cache = this.$context.cache;
    if (rendered instanceof Array) {
      for (let child of rendered) {
        cache.addChild(child);
      }
    } else {
      cache.addChild(rendered);
    }
    return true;
  }

  /** Gets an array of the registered tools, sorted alphabetically. */
  protected $getTools() {
    const registered = this.$tools.registered;
    return Object.getOwnPropertyNames(registered)
                 .sort()
                 .map(toolName => registered[toolName]);
  }

  /** Rebuilds the cache layer from the history, and causes the cache to refresh.
   * @param dpcm Dots per centimetre required of the cache.
   * @param widthCm Expected width the image is expected to be rendered at. */
  rebuildCache(dpcm: number = this.$context.dpcm, widthCm: number = this.$context.widthCm) {
    this.$trace && this.$trace("Rebuild cache from history");
    const cache = this.$context.cache;
    cache.uncache();
    cache.removeAllChildren();
    const tools = this.$getTools();
    for (let shape of this.items) {
      if (!this.$tools.isEditing(shape)) {
        if (!tools.some(tool => this.tryRenderAndCache(tool, shape))) {
          this.$error && this.$error(`Couldn't render shape type '${shape.type}'`);
        }
      }
    }
    this.$context.recache(dpcm, widthCm);
  };

  /** Update history from scope, and fire off history change notifications as we detect them. No
   * notifications are fired if either collection is null. This is intended to be called from a
   * $scope.watchCollection() so that both arguments can be owned by this class. If calling from
   * elsewhere, please copy the arrays before calling this. */
  private $updateHistory(newHistory: IShape[], oldHistory: IShape[]) {
    this.items = newHistory;
    if (newHistory && oldHistory) {
      let from = 0;
      for (let item of [].concat(newHistory)) {
        const index = oldHistory.indexOf(item);
        if (index < 0) {
          this.$events.notify("history-item-added", item);
        } else {
          oldHistory[index] = oldHistory[from];
          ++from;
        }
      }
      for (; from < oldHistory.length; ++from) {
        this.$events.notify("history-item-removed", oldHistory[from]);
      }
    }
    this.$events.notify("history-list-changed", newHistory);
  }

  /** Overrides Traceable.setTracing() to also allow enabling or disable event tracing for this
   * class. */
  setTracing(options: Traceable.Options & { "historyManager.traceEvents"?: boolean }): void {
    super.setTracing(options);
    if ("historyManager.traceEvents" in options) {
      this.traceEvents(options["historyManager.traceEvents"]);
    }
  }

  /** Enables or disables event tracing for this class. When enabled, all events will be printed to
    * this class's $trace function. */
  traceEvents(enable: boolean, selectTraceArgs?: (event: string, args: any[]) => any[]) {
    const registrationName = "$traceObservers";
    if (typeof selectTraceArgs !== "function") {
      selectTraceArgs = (event, args) => ["ImageEditor.history", event,
        ...(args.map(arg =>
          arg instanceof Vector2
            ? (<Vector2>arg).toString(3)
            : arg))];
    }
    if (enable) {
      if (this[registrationName] == null) {
        const events: HistoryEvents[] = ["history-item-added", "history-item-removed", "history-list-changed"];
        this[registrationName] = createDisposable(
          events.map(event => this.observe(event, (...args) => {
            this.$trace && this.$trace.apply(this, selectTraceArgs(event, args));
          })));
      }
    } else {
      if (typeof this[registrationName] === "function") {
        this[registrationName]();
        this[registrationName] = undefined;
      }
    }
  }

  static $inject = ["imageEditor.displayContext", "imageEditor.tools", "$scope"];
  constructor(
    private $context: DisplayContext,
    private $tools: ToolManager,
    private $scope: IImageEditorScope) {
    super();
    this.$events = new Events();
    this.items = this.$scope.history;
    this.$scope.$watchCollection(
      (scope: IImageEditorScope) => scope.history,
      (history, old) => this.$updateHistory(history, old));

    this.observe("history-list-changed", () => this.rebuildCache());
  }
}