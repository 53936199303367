import * as angular from "angular";
import { BindingOf, IChangesObject } from "../../../utility/componentBindings";
import { GlossaryCategory } from "../../../businessModels";

interface IGlossaryCategoryCallback {
    $category: GlossaryCategory;
}

interface ComponentBindings {
    categories: Array<GlossaryCategory>;
    onSelected: (obj: IGlossaryCategoryCallback) => void;
    onCreateRequested: () => void;
}

class Controller implements angular.IComponentController, ComponentBindings {
    onSelected: (obj: IGlossaryCategoryCallback) => void;
    onCreateRequested: () => void;

    categories: Array<GlossaryCategory>;

    select(category: GlossaryCategory) {
        this.onSelected({ $category: category });
    }

    $onChanges(obj: IChangesObject<ComponentBindings>): void {
        if (obj.categories) {
            this.categories = obj.categories.currentValue.filter(x => !x.isDeleted);
        }
    }
}

export default <angular.IComponentOptions> {
    templateUrl: require('./glossaryCategoryList.html'),
    bindings: {
        categories: '<',
        onSelected: '&',
        onCreateRequested: '&'
    } as BindingOf<ComponentBindings>,
    controller: Controller
}