import "./expansionPanelHeader.component.scss";
import * as angular from "angular";
import { ExpansionPanel } from "./expansionPanel.component";
import { IChangesObject } from "../componentBindings";

interface Bindings {
    /** Allows the collapsed height of the header to be changed from the default. */
    collapsedHeight: string;
    /** Allows the expanded height of the header to be changed from the default. */
    expandedHeight: string;
}

interface ExpansionPanelHeader extends angular.IController, Bindings {}
class ExpansionPanelHeader implements angular.IController {
    private readonly panel: ExpansionPanel;

    static $inject = ["$scope", "$element", "$mdTheming"];
    constructor(
        $scope: angular.IScope,
        private readonly $element: angular.IAugmentedJQuery,
        private readonly $mdTheming
    ) {
        //Set up css classes & attributes to match those in the angular2 mat-expansion-panel-header.
        //They're applied directly to the directive element in those, and Angular seems to provide
        //functionality for that case. AngularJS expects you to add ng-class and whatnot to an
        //element inside the template, which isn't quite the same.
        $element.addClass("mds-expansion-panel-header");
        $element.attr("role", "button");
        $scope.$watch(() => this.isExpanded(), expanded => {
            $element.attr("aria-expanded", `${!!expanded}`)
            if (expanded) {
                $element.addClass("mds-expanded");
            } else {
                $element.removeClass("mds-expanded");
            }

            if (!expanded && this.collapsedHeight != null) {
                this.$element.css("height", this.collapsedHeight);
            }
            if (expanded && this.expandedHeight != null) {
                this.$element.css("height", this.expandedHeight);
            }
        });
        //$scope.$watch(() => this._getPanelId(), $element.attr("aria-controls", `${expanded}`));
        $scope.$watch(() => this.panel.disabled, disabled => {
            $element.attr("aria-disabled", `${!!disabled}`);
            $element.attr("tabindex", `${disabled ? -1 : 0}`);
        });

        //Set up event binding on the root component element, once again to avoid having to have
        //wrappers in the template and to more closely match the angular 2 version.
        const boundToggle = this.toggle.bind(this);
        const toggleOnEvent = function() {
            if ($scope.$root.$$phase) {
                $scope.$evalAsync(boundToggle);
            } else {
                $scope.$apply(boundToggle);
            }
        }
        $element.on("click", toggleOnEvent);
        $element.on("keyup", toggleOnEvent);
        $scope.$on("$destroy", () => {
            $element.off("click", toggleOnEvent);
            $element.off("keyup", toggleOnEvent);
        });
    }

    $onInit() {
        this.$element.addClass('_md');     // private md component indicator for styling
        this.$mdTheming(this.$element);
    }

    $onChanges(changes: IChangesObject<Bindings>) {
        if (changes.collapsedHeight && !this.isExpanded()) {
            this.$element.css("height", changes.collapsedHeight.currentValue);
        }
        if (changes.expandedHeight && this.isExpanded()) {
            this.$element.css("height", changes.expandedHeight.currentValue);
        }
    }

      /** Toggles the expanded state of the panel. */
    toggle(): void {
        if (!this.panel.disabled) {
            this.panel.toggle();
        }
    }

    /** Gets whether the panel is expanded. */
    isExpanded(): boolean {
        return this.panel.expanded;
    }

    /** Gets the panel id. */
    // _getPanelId(): string {
    //     return this.panel.id;
    // }

    /** Gets whether the expand indicator should be shown. */
    showToggle(): boolean {
        return this.panel.showToggle() && !this.panel.disabled;
    }

    /** Handle keyup event, calling to toggle() if appropriate. */
    keyup(event: KeyboardEvent) {
        switch (event.keyCode) {
        case 32: //SPACE
        case 13: //ENTER
            event.preventDefault();
            this.toggle();
            break;
        default:
            return;
        }
    }
}

export default <angular.IComponentOptions>{
    controller: ExpansionPanelHeader,
    bindings: {
        collapsedHeight: "<?",
        expandedHeight: "<?"
    },
    transclude: {
        mdsPanelTitle: "mdsPanelTitle",
        mdsPanelDescription: "?mdsPanelDescription"
    },
    require: {
        "panel": "^^mdsExpansionPanel"
    },
    templateUrl: require("./expansionPanelHeader.component.html")
};