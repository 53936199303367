import * as Utils from "../../utility/utils";
import { IShape, DisplayContext } from "./imageEditorCore";
import { element as ngElement } from "angular";
import { Stage } from "createjs";
import { Vector2, Rect, Events, createDisposable, Throttle } from '../../utility/utils';

/** An enum suitable for bitwise combination to define which mouse buttons are currently down. */
export const enum MouseButton {
  none = 0,
  left = 1,
  middle = 2,
  right = 4
}

/** Events emitted by the InputManager. */
export type InteractionEvents =
  //Interaction events.
  "start-interact" | "interact" | "end-interact" | "cancel-interact" | "finished-interact" |
  //General events.
  "accept" | "select" | "deselect" | "delete" |
  //Panning and zooming events.
  "start-pan-zoom" | "pan-zooming" | "end-pan-zoom" | "cancel-pan-zoom" | "finished-pan-zoom" | "zoom-or-reset";

/** Event argument for the "*-pan-zoom" events emitted by the InputManager. These are
 * not emitted by a PanZoom object, rather instructions to send to a PanZoom object. */
type PanZoomEventArg = {
  centroid: Utils.Vector2,
  scale: number
};

/** A class which handles gathering of all input events, and re-emitting as domain specific
 * events related to the image editor. It handles input event throttling where appropriate. */
export class InputManager extends Utils.Traceable {
  private $events: Utils.Events;
  private $interactThrottle: Utils.Throttle<Utils.Vector2>;
  private $panZoomThrottle: Utils.Throttle<PanZoomEventArg>;
  private $touchIsCaptured = false;
  private $touchDistance: number = undefined;
  private $startTouchDistance: number = undefined;
  private $mouseScale: number = 1;
  /** Tracks the currently selected shape so we can intelligently issue "select" events. */
  private $editShape: IShape = undefined;
  /** The disposer for interactions. Any disposables registered with this object will be
   * disposed whenever an interaction completes, no matter how it completes. */
  private $interactionDispose: Utils.Disposable;

  /** Flag combination of all buttons which are down right now. */
  mouseButtons: MouseButton;
  /** The current list of touches in page coordinates. */
  touches: Utils.Vector2[] = [];
  /** The centroid of all touches, in normalised stage coordinates. */
  touchCentroid: Utils.IPointLike = undefined;
  /** Whether the user is currently interacting with the editor. */
  isInteracting: boolean = false;
  /** Whether the user is currently panning and/or zooming on the editor. */
  isPanZooming: boolean = false;
  /** Gets the current location during an interaction or pan-zoom, in normalised stage
   * coordinates. */
  location: Utils.Vector2 = null;

  /** Observe the start interaction event, which fires when a user begins drawing or interacting
   * with the stage using the left mouse button or a touch. Positions in stage coordinates.
   * @param event The name of the event to subscribe to.
   * @param callback Gets notified whenever an interaction with the stage begins.
   * @returns A disposable which stops this registration from receiving notifications. */
  observe(event: "start-interact", callback: Utils.ObservableCallback<Utils.Vector2>): Utils.IDisposable;
  /** Observe the interact event, which fires after the 'start-interact' event, every time the user
   * moves their interaction point. This event is throttled to avoid overload. Positions in stage
   * coordinates.
   * @param event The name of the event to subscribe to.
   * @param callback Gets notified whenever an interaction with the stage is updated.
   * @returns A disposable which stops this registration from receiving notifications. */
  observe(event: "interact", callback: Utils.ObservableCallback<Utils.Vector2>): Utils.IDisposable;
  /** Observe the end interaction event, which fires after a 'start-interact' and zero or more
   * 'interact' events, to signify that the interaction has ended successfully. Usually this
   * means the result of the interaction should be saved.
   * with the stage using the left mouse button or a touch.
   * @param event The name of the event to subscribe to.
   * @param callback Gets notified whenever an interaction with the stage ends.
   * @returns A disposable which stops this registration from receiving notifications. */
  observe(event: "end-interact", callback: Utils.ObservableCallback<void>): Utils.IDisposable;
  /** Observe the cancel interaction event, which fires after a 'start-interact' and zero or more
   * 'interact' events, to signify that the interaction has been cancelled. Usually this
   * means the result of the interaction should not be saved, and should be reverted if possible.
   * @param event The name of the event to subscribe to.
   * @param callback Gets notified whenever an interaction with the stage is cancelled.
   * @returns A disposable which stops this registration from receiving notifications. */
  observe(event: "cancel-interact", callback: Utils.ObservableCallback<void>): Utils.IDisposable;
  /** Notifies when either "cancel-interact" or "end-interact" is notified, signifying that
   * an interaction has come to and end either successfully or unsuccessfully. */
  observe(event: "finished-interact", callback: Utils.ObservableCallback<void>): Utils.IDisposable;
  /** Observe the start pan zoom event, which fires when the user begins panning or zooming,
   * such as by right mouse click or two finger touch. Positions in stage coordinates.
   * @param event The name of the event to subscribe to.
   * @param callback Gets notified whenever the user begins panning or zooming.
   * @returns A disposable which stops this registration from receiving notifications. */
  observe(event: "start-pan-zoom", callback: Utils.ObservableCallback<Utils.Vector2>): Utils.IDisposable;
  /** Observe an ongoing pan zoom event, which fires after the 'start-pan-zoom' event, every time
   * the user moves the pan point or changes the zoom amount.
   * @param event The name of the event to subscribe to.
   * @param callback Gets notified whenever the user continues panning or zooming.
   * @returns A disposable which stops this registration from receiving notifications. */
  observe(event: "pan-zooming", callback: Utils.ObservableCallback<PanZoomEventArg>): Utils.IDisposable;
  /** Observe the end of a pan zoom event, which fires after the 'start-pan-zoom' event and zero
   * or more 'pan-zooming' events to indicate the operation ended successfully.
   * @param event The name of the event to subscribe to.
   * @param callback Gets notified whenever the user finished panning or zooming.
   * @returns A disposable which stops this registration from receiving notifications. */
  observe(event: "end-pan-zoom", callback: Utils.ObservableCallback<void>): Utils.IDisposable;
  /** Observe the end of a pan zoom event, which fires after the 'start-pan-zoom' event and zero
   * or more 'pan-zooming' events to indicate the operation was cancelled. Usually this means
   * the view should return to the state before the panning and zooming operation.
   * @param event The name of the event to subscribe to.
   * @param callback Gets notified whenever the user cancelled panning or zooming.
   * @returns A disposable which stops this registration from receiving notifications. */
  observe(event: "cancel-pan-zoom", callback: Utils.ObservableCallback<void>): Utils.IDisposable;
  /** Notifies when either "cancel-pan-zoom" or "end-pan-zoom" is notified. */
  observe(event: "finished-pan-zoom", callback: Utils.ObservableCallback<void>): Utils.IDisposable;
  /** Observe the zoom or reset zoom event, which fires when the user indicates they wish to zoom in
   * to a specific location. This is distinct from an ongoing pan/zoom operation, as might happen
   * with two finger touch. This is more likely triggered by a middle click. Positions in stage
   * coordinates.
   * @param event The name of the event to subscribe to.
   * @param callback Gets notified whenever the user asks to zoom in on something.
   * @returns A disposable which stops this registration from receiving notifications. */
  observe(event: "zoom-or-reset", callback: Utils.ObservableCallback<Utils.Vector2>): Utils.IDisposable;
  /** Observe the accept event, which fires when the user indicates they wish to accept
   * something. This event can be quite tool specific, and is generally only useful when
   * a tool requires more than one interaction while building up a shape.
   * @param event The name of the event to subscribe to.
   * @param callback Gets notified whenever the user asks to accept something.
   * @returns A disposable which stops this registration from receiving notifications. */
  observe(event: "accept", callback: Utils.ObservableCallback<void>): Utils.IDisposable;
  /** Observe the select event, which fires when the user isn't edit a shape, and they indicate
   * they want to edit one. Usually this means any current selection should be updated.
   * @param event The name of the event to subscribe to.
   * @param callback Gets notified whenever the user asks to select something.
   * @returns A disposable which stops this registration from receiving notifications. */
  observe(event: "select", callback: Utils.ObservableCallback<IShape>): Utils.IDisposable;
  /** Observe the deselect event, which fires when the user isn't interacting or panning and
   * zooming, and they indicate they wish to cancel an operation. Usually this means any current
   * selection should be removed.
   * @param event The name of the event to subscribe to.
   * @param callback Gets notified whenever the user asks to deselect something.
   * @returns A disposable which stops this registration from receiving notifications. */
  observe(event: "deselect", callback: Utils.ObservableCallback<void>): Utils.IDisposable;
  /** Observe the delete event, which fires when the user indicates they wish to delete
   * something.
   * @param event The name of the event to subscribe to.
   * @param callback Gets notified whenever the user asks to delete something.
   * @returns A disposable which stops this registration from receiving notifications. */
  observe(event: "delete", callback: Utils.ObservableCallback<void>): Utils.IDisposable;
  observe(event: InteractionEvents, callback: Utils.ObservableCallback<any>): Utils.IDisposable;
  observe(event: InteractionEvents, callback: Utils.ObservableCallback<any>): Utils.IDisposable {
    if (event === "finished-interact") {
      return createDisposable(
        this.$events.observe("end-interact", callback),
        this.$events.observe("cancel-interact", callback));
    } else if (event === "finished-pan-zoom") {
      return createDisposable(
        this.$events.observe("end-pan-zoom", callback),
        this.$events.observe("cancel-pan-zoom", callback));
    } else {
      return this.$events.observe(event, callback);
    }
  }

  /** Updates state and sends the 'start-interact' event.
   * @param pos The position of the interaction.
   */
  startInteract(pos: Utils.IPointLike): void {
    if (!this.isInteracting && !this.isPanZooming) {
      this.isInteracting = true;
      this.location = new Vector2(pos);
      this.$events.notify("start-interact", this.location);
    }
  }

  /** Updates state and sends the 'interact' event.
   * @param pos The position of the interaction.
   */
  interact(pos: Utils.IPointLike): void {
    if (this.isInteracting) {
      this.$interactThrottle.onNext(new Vector2(pos));
    }
  }

  /** Once the interacting event throttle expires, this function is called to send
   * the 'interact' event.
   * @param pos The position of the interaction.
   */
  private $doThrottledInteract(pos: Utils.Vector2): void {
    this.location = new Vector2(pos);
    this.$events.notify("interact", this.location);
  }

  /** Updates state and sends the 'end-interact' event. */
  endInteract(): void {
    if (this.isInteracting) {
      this.$interactThrottle.flush();
      this.isInteracting = false;
      this.location = null;
      this.$events.notify("end-interact");
    }
  }

  /** Updates state and sends the 'cancel-interact' event. */
  cancelInteract(): void {
    if (this.isInteracting) {
      this.$interactThrottle.cancel();
      this.isInteracting = false;
      this.location = null;
      this.$events.notify("cancel-interact");
    }
  }

  /** Updates state and sends the 'start-pan-zoom' event.
   * @param pos The initial pan position
   */
  startPanZoom(pos: Utils.IPointLike): void {
    if (!this.isInteracting && !this.isPanZooming) {
      this.isPanZooming = true;
      this.location = new Vector2(pos);
      this.$events.notify("start-pan-zoom", this.location);
    }
  }

  /** Sends a new panning and zooming event to the panning and zooming event throttle.
   * @param pos The new pan position
   * @param scale The new scale.
   */
  panZooming(pos: Utils.IPointLike, scale: number = 1): void {
    if (this.isPanZooming) {
      this.$panZoomThrottle.onNext({
        centroid: new Vector2(pos),
        scale
      });
    }
  }

  /** Once the panning and zooming event throttle expires, this function is called to send
   * the 'pan-zooming' event.
   * @param panZoomingArg The panning and zooming arg.
   */
  private $doThrottledPanZooming(panZoomingArg: PanZoomEventArg): void {
    this.location = panZoomingArg.centroid;
    this.$events.notify("pan-zooming", panZoomingArg);
  }

  /** Updates state and sends the 'end-pan-zoom' event. */
  endPanZoom(): void {
    if (this.isPanZooming) {
      this.isPanZooming = false;
      this.$mouseScale = 1;
      this.$panZoomThrottle.flush();
      this.$events.notify("end-pan-zoom");
    }
  }

  /** Updates state and sends the 'cancel-pan-zoom' event. */
  cancelPanZoom(): void {
    if (this.isPanZooming) {
      this.isPanZooming = false;
      this.$mouseScale = 1;
      this.$panZoomThrottle.cancel();
      this.$events.notify("cancel-pan-zoom");
    }
  }

  /** Sends the 'end-pan-zoom' event. */
  zoomOrReset(centroid: Utils.IPointLike): void {
    if (!this.isInteracting && !this.isPanZooming) {
      this.$events.notify("zoom-or-reset", new Vector2(centroid));
    }
  }

  /** Sends the 'accept' event. */
  accept() {
    this.$events.notify("accept");
  }

  /** Sends the 'select' event if the argument isn't currently being edited, and it's not
   * null, or sends the 'deselect' event if we're currently editing and the argument is null.
   * @param editShape The shape the user wishes to select.
   */
  select(editShape: IShape) {
    if (editShape == null) {
      this.deselect();
    } else if (editShape !== this.$editShape) {
      this.$editShape = editShape;
      this.$events.notify("select", editShape);
    }
  }

  /** Sends the 'deselect' event.
   * @param force Whether to force a deselect event to be sent. This can be useful to force
   * reinitialisation of a tool for example, even if we're not changing the edit shape. */
  deselect(force: boolean = false) {
    if (force || this.$editShape != null) {
      this.$editShape = null;
      this.$events.notify("deselect");
    }
  }

  /** Sends the 'delete' event. */
  delete() {
    this.$events.notify("delete");
  }

  /** Normalise a mouse event into a flag suitable for bitwise combination to determine which
   * buttons are down. */
  private $normaliseMouseButton(e: MouseEvent): MouseButton {
    switch (e.which) {
      //IE ≥ 9.0, Gecko ≥ 1.0, Webkit ≥ 523, Opera ≥ 8.0, Konqueror ≥ 4.3 should
      //all use the following values for e.which.
      case 2:
        return MouseButton.middle; //Middle mouse button.
      case 3:
        return MouseButton.right; //Right mouse button.
      case 1:
      default:
        return MouseButton.left; //Left mouse button
    }
  };

  /** Gets the mouse position on the stage in stage coordinates, between 0 and the coordinate
   * extent. */
  getMouseStagePosition() {
    const stage = this.$stage;
    return stage.globalToLocal(stage.mouseX, stage.mouseY);
  }

  /** Handle all mouse events. Doesn't need to be notified of mousemove events, as it will
   * register and deregister those as needed. Any others are fair game.
   * @param e The mouse event to handle.
   */
  handleMouseEvents(e: MouseEvent): boolean {
    const button = this.$normaliseMouseButton(e);

    switch (e.type) {
      case "mousedown":
        this.mouseButtons |= button;

        //mousemove is unsubscribed in endDraw() and endPanZoom().
        if (this.mouseButtons === MouseButton.left) {
          this.$interactionDispose.add(
            ngElement(document.body).mdsOn("mousemove", () => {
              if (this.mouseButtons === MouseButton.left) {
                this.interact(this.getMouseStagePosition());
              }
            }));
          this.startInteract(this.getMouseStagePosition());
        } else if ((this.mouseButtons & MouseButton.right) !== 0) {
          this.$interactionDispose.add(
            ngElement(document.body).mdsOn("mousemove", () => {
              if ((this.mouseButtons & (MouseButton.middle | MouseButton.right)) !== 0) {
                this.panZooming(this.getMouseStagePosition(), this.$mouseScale);
              }
            }));
          //TODO
          //this.startFunction(this.getMouseUserPosition());
          this.$mouseScale = 1;
          this.startPanZoom(this.getMouseStagePosition());
        } else if ((this.mouseButtons & MouseButton.middle) !== 0) {
          this.zoomOrReset(this.getMouseStagePosition());
          e.preventDefault();
          return false;
        }
        break;

      case "contextmenu":
        //Disable the context menu.
        e.preventDefault();
        return false;

      case "DOMMouseScroll":
      case "mousewheel":
        //Firefox, everyone else.
        const pos = this.getMouseStagePosition();
        //Firefox uses e.detail = -3 for one click up on mouse wheel.
        //Everyone else uses e.wheelDelta == 120 for one click up on mouse wheel.
        //TODO: "wheelDelta" is non-standard, needs update. See here https://developer.mozilla.org/en-US/docs/Web/Events/mousewheel
        let scale = 1 + (e.type === "DOMMouseScroll" ? e["detail"] / -30 : e["wheelDelta"] / 1200);

        if (this.isPanZooming) {
          //TODO
          //Offset the scale by the amount we've already zoomed during the current
          //pan/zoom (IE right mouse is down and they start wheeling the mouse).
          //Otherwise we are clipped between [0.9, 1.1] of the scale when we started.
          this.$mouseScale *= scale;
          this.panZooming(pos, this.$mouseScale);
        } else {
          this.startPanZoom(pos);
          this.$mouseScale *= scale;
          this.panZooming(pos, this.$mouseScale);
          this.endPanZoom();
        }
        e.preventDefault();
        return false;

      case "mouseup":
        this.mouseButtons &= ~button;
        if (button === MouseButton.left) {
          this.endInteract();
        } else {
          this.endPanZoom();
        }
        e.preventDefault();
        return false;
    }
    return true;
  }

  private $getTouchPositions(event: TouchEvent): Utils.Vector2[] {
    const touches: Utils.Vector2[] = [];
    for (let i = 0, len = event.touches.length; i < len; ++i) {
      const touch = event.touches[i];
      touches.push(new Vector2(touch.pageX, touch.pageY));
    }
    return touches;
  }

  /** Handles all touch events. */
  handleTouchEvents(event: TouchEvent): boolean {
    this.touches = this.$getTouchPositions(event);
    const numTouches = this.touches.length;

    const touchSpan = Rect.boundingRect(this.touches);
    this.$touchDistance = touchSpan.getDiagonalLength();

    if (numTouches > 0) {
      let centroid = Vector2.centroid(...this.touches);
      this.$context.pageToCanvas(centroid, centroid);
      this.touchCentroid = this.$context.canvasToStage(centroid, centroid);
    } else {
      this.touchCentroid = null;
    }

    switch (event.type) {
      case "touchstart":
        this.$touchIsCaptured = true;
        if (numTouches === 1) {
          this.startInteract(new Vector2(this.touchCentroid));
        } else if (numTouches > 1) {
          this.cancelInteract();
          this.$startTouchDistance = this.$touchDistance;
          this.startPanZoom(this.touchCentroid);
        }
        break;
      case "touchmove":
        if (numTouches === 1) {
          this.interact(this.touchCentroid);
        } else if (numTouches > 1) {
          this.panZooming(this.touchCentroid, this.$touchDistance / this.$startTouchDistance);
        }
        break;
      default:
        //touchend or touchcancel.
        if (numTouches === 0) {
          this.$touchIsCaptured = false;
          this.$startTouchDistance = undefined;
          this.$touchDistance = undefined;
          this.touchCentroid = undefined;

          if (this.isInteracting) {
            if (event.type === "touchcancel") {
              this.cancelInteract();
            } else {
              this.endInteract();
            }
          }
          else if (this.isPanZooming) {
            this.endPanZoom();
          }
        }
    }
    if (this.$touchIsCaptured) {
      event.preventDefault();
      return false;
    }
  }

  /** Handles all keyboard events for the image editor.
   * @param event The keyboard event. */
  handleKeyEvents(event: KeyboardEvent): void {
    switch (event.keyCode) {
      case 27:
        if (this.isInteracting) {
          this.cancelInteract();
        } else if (this.isPanZooming) {
          this.cancelPanZoom();
        } else {
          this.deselect();
        }
        break;
      case 46:
        this.delete();
        break;
    }
  }

  /** Overrides Traceable.setTracing() to also allow enabling or disable event tracing for this
   * class. */
  setTracing(options: Utils.Traceable.Options & { "inputManager.traceEvents"?: boolean }): void {
    super.setTracing(options);
    if ("inputManager.traceEvents" in options) {
      this.traceEvents(options["inputManager.traceEvents"]);
    }
  }

  /** Enables or disables event tracing for this class. When enabled, all events will be printed to
   * this class's $trace function. */
  traceEvents(enable: boolean, selectTraceArgs?: (event: string, args: any[]) => any[]) {
    const registrationName = "$traceObservers";
    if (typeof selectTraceArgs !== "function") {
      selectTraceArgs = (event, args) => ["ImageEditor.input", event,
        ...(args.map(arg =>
          arg instanceof Vector2
            ? (<Utils.Vector2>arg).toString(3)
            : arg))];
    }
    if (enable) {
      if (this[registrationName] == null) {
        const events: InteractionEvents[] = [
          "start-interact", "interact", "end-interact", "cancel-interact", "finished-interact",
          "accept", "select", "deselect", "delete",
          "start-pan-zoom", "pan-zooming", "end-pan-zoom", "cancel-pan-zoom", "finished-pan-zoom", "zoom-or-reset"];
        this[registrationName] = createDisposable(
          events.map(event => this.observe(event, (...args) => {
            this.$trace && this.$trace.apply(this, selectTraceArgs(event, args));
          })));
      }
    } else {
      if (typeof this[registrationName] === "function") {
        this[registrationName]();
        this[registrationName] = undefined;
      }
    }
  }

  /** Sets up the standard event listener configuration on the provided element. Some events
   * are bound to the body to provide a clean user experience, but all ongoing events
   * begin on the provided element.
   * @param canvas The provided element. Most events begin on this element, such as mousedown.
   */
  addStandardEventListeners(element: HTMLElement): Utils.Disposable {
    const observe = (el: HTMLElement, events: string[], handler: EventListener | EventListenerObject) =>
      createDisposable(
        events.map(event => {
          el.addEventListener(event, handler);
          return () => el.removeEventListener(event, handler);
        }));

    const mouseHandler = this.handleMouseEvents.bind(this);
    const touchHandler = this.handleTouchEvents.bind(this);
    const keyHandler = this.handleKeyEvents.bind(this);

    return createDisposable(
      observe(element, ["mousedown", "contextmenu", "DOMMouseScroll", "mousewheel"], mouseHandler),
      observe(document.body, ["mouseup", "mousecancel"], mouseHandler),
      observe(element, ["touchstart", "touchmove"], touchHandler),
      observe(document.body, ["touchend", "touchcancel"], touchHandler),
      observe(document.body, ["keydown"], keyHandler)
    );
  }

  static $inject = ["imageEditor.displayContext", "imageEditor.stage"];
  constructor(
    private $context: DisplayContext,
    private $stage: Stage) {
    super();
    this.$events = new Events();
    this.$interactThrottle = new Throttle<Utils.Vector2>(15, true);
    this.$interactThrottle.observe(this.$doThrottledInteract.bind(this));
    this.$panZoomThrottle = new Throttle<PanZoomEventArg>(15, true);
    this.$panZoomThrottle.observe(this.$doThrottledPanZooming.bind(this));

    this.$interactionDispose = createDisposable();
    const disposeCurrentInteraction = () => {
      const dispose = this.$interactionDispose;
      this.$interactionDispose = createDisposable();
      dispose();
    };
    this.observe("finished-interact", disposeCurrentInteraction);
    this.observe("finished-pan-zoom", disposeCurrentInteraction);
  }
}