import { module as ngModule, element as $ } from "angular";
import {
  TinymceConfigurationController,
  tinymceConfigurationRequire
} from "../mds-rich-text-editor.component";
import blueprintPanelServiceModule, {
    serviceName as blueprintPanelServiceName,
    BlueprintPanelsService
  } from "../../../admin/reportFormatters/dialogs/blueprint-panel.service";
import { Editor } from 'tinymce';
import widgetsServiceModule, {
  serviceName as widgetsServiceName,
  WidgetsService
} from '../../reportFormatter/widgets/widgets.service';



/** A class which watches for selection/cursor changes in the blueprint editor, and forward them to
 * a named blueprint widget editing component. */
class EditWidgetsPlugin extends TinymceConfigurationController {
  /** The bound blueprint panel id to communicate with. */
  panelId: string;

  static $inject = [
    blueprintPanelServiceName,
    widgetsServiceName
  ];

  constructor(
    private readonly panels: BlueprintPanelsService,
    private readonly widgets: WidgetsService) {
    super();
  }

  setup(editor: Editor) {
    editor.on("NodeChange", () => this.selectionChanged(editor));
  }

  /** Should be called whenever the selected node in the tinymce editor is changed. This can be
   * called directly from the tinymce "NodeChange" event. */
  selectionChanged(editor: Editor) {
    const panel = this.panels.get(this.panelId);
    if (!panel) {
      return;
    }

    const selectedNode = editor.selection.getNode();
    const widget = this.widgets.closest($(selectedNode));
    if (widget) {
      panel.show(widget).then(result => {
        switch (result) {
          case "remove":
            widget.remove();
            // explicit fall through.
          case "accept":
            editor.setDirty(true);
            editor.fire("change");
            break;
        }
      });
    } else if (selectedNode !== editor.getElement()) {
      // The above check avoids hiding the panel when focus moves out of tinymce. For some reason
      // it started becoming a problem when I started using menus in the panel, but I've seen it
      // happen when clicking into the inputs on the panel too. Some recent change has started
      // notifying tinymce of node change when it didn't before. In those cases, tinymce seems to
      // set the selected node to the root of the editor, which we can just check.
      panel.hide();
    }
  }
}

export default ngModule("midas.utility.tinymce.editWidgetsPlugin", [
  blueprintPanelServiceModule.name,
  widgetsServiceModule.name
])
.component("editWidgetsPlugin", {
  require: {
    ...tinymceConfigurationRequire
  },
  controller: EditWidgetsPlugin,
  bindings: {
      panelId: "@?"
  }
});