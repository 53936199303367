import { Study } from "../../../businessModels";
import { IDirective, IScope, IController } from "angular";
import { Disposable, createDisposable } from '../../../utility/utils';

export interface IMeasurementsScope extends IScope {
    study: Study;
    sections: Array<IMeasurementSection>;
    options: { [examId: number]: any };
    sectionClassName: string,
    measurementVariant: string
}

export interface IMeasurementSection {
    id: string;
    text: string;
}

export interface MeasurementsController extends IController {}
export class MeasurementsController {
  static $inject = ["$scope"];
  constructor(
    private $scope: IMeasurementsScope
  ) { }

    addSection(section: IMeasurementSection): Disposable {
        const sections = this.$scope.sections || (this.$scope.sections = []);
        if (sections.indexOf(section) < 0) {
            sections.push(section);
            return createDisposable(() => {
                const i = sections.indexOf(section);
                if (i >= 0) {
                    sections.splice(i, 1);
                }
            });
        }
        return createDisposable();
    }

    setMeasurementDefault(group: string) {
      this.$scope.$broadcast("setMeasurementDefault", { group: group });
    }
}

export class MeasurementsDirective implements IDirective {
    restrict = "E";
    templateUrl = require("./mdsMeasurements.html");
    transclude = true;
    scope: { [key: string]: string } = {
        study: "=",
        measurementVariant: "@?",
        viewVariant: "@?",
        options: "=?"
    };
    controller = MeasurementsController;
}

export default [() => new MeasurementsDirective()];