import { module as ngModule, ILogService } from 'angular';
import authModule, { serviceName as authServiceName, IAuthService } from "../../authService";
import { ObservableCallback, IDisposable, Events } from '../../utility/utils';

// I could likely wrangle SignalR into a module format using Webpack, but I CBF right now. I don't
// like having to import it like this and access it via jquery. It's old.
import * as $ from 'jquery';
import "signalr";

const notifyStudyChangedEvent = 'NotifyStudyChanged';
const notifyBlueprintPublishedEvent = 'NotifyBlueprintPublished';

export enum ConnectionState {
  connected = $.signalR.connectionState.connected,
  connecting = $.signalR.connectionState.connecting,
  disconnected = $.signalR.connectionState.disconnected,
  reconnecting = $.signalR.connectionState.reconnecting
}

/** A service for subscribing to push notifications from the server. */
export class PushService {
  private readonly connection: SignalR.Hub.Connection;
  private readonly proxy: SignalR.Hub.Proxy;
  private events = new Events();
  public state: ConnectionState = ConnectionState.disconnected;

  static $inject = [authServiceName, "$log"];
  constructor(auth: IAuthService, $log: ILogService) {
    const url = location.pathname.replace(/[\\/]+$/, "") + "/push";
    this.connection = $.hubConnection(url, { useDefaultPath: false });
    this.proxy = this.connection.createHubProxy("PushHub");

    // All server events need to be subscribed before the connection starts.
    this.proxy.on(notifyStudyChangedEvent, (...args: any[]) => {
      this.events.notify(notifyStudyChangedEvent, args);
    });
    this.proxy.on(notifyBlueprintPublishedEvent, (...args: any[]) => {
      this.events.notify(notifyBlueprintPublishedEvent, args);
    });

    this.connection.stateChanged(stateChange => {
      const state = this.state = stateChange.newState;

      if (state === ConnectionState.connected) {
        this.proxy.invoke('Subscribe')
          .then(
            () => $log.info("Subscribed for institute push notifications"),
            error => $log.error('Subscribing to institute notifications failed: ', error));
      } else if (state === ConnectionState.disconnected) {
        // If the user has logged back in but the connection was in the middle of disconnecting then
        // reconnect now.
        if (auth.isAuthenticated()) {
          const identity = auth.getUser();
          if (identity.bearerToken != null) {
            this.connection.qs = { access_token: identity.bearerToken };
          }
          // If we disconnected due to an error, back off for 15 seconds so we don't spam.
          if (this.connection.lastError != null) {
            $log.error("Push service connection error", this.connection.lastError);
            this.connection.lastError = null;
            setTimeout(() => {
              if (this.state === ConnectionState.disconnected) {
                this.connection.start();
              }
            }, 15000);
          } else { // If there was no error, just go ahead and start now.
            this.connection.start();
          }
        }
      }
    });

    auth.onUserChanged(identity => {
      if (this.state === ConnectionState.connected || this.state === ConnectionState.connecting) {
        this.connection.stop(true, true);
      }

      this.connection.qs = null;

      // If we're not disconnected right now (perhaps we just started disconnecting but haven't
      // finished yet) then the re-authentication and re-connection will be handled in the state
      // change event.
      if (this.state === ConnectionState.disconnected && auth.isAuthenticated()) {
        if (identity.bearerToken != null) {
          this.connection.qs = { access_token: identity.bearerToken };
        }
        this.connection.start();
      }
    });
  }

  /** Subscribes for notifications from the server that any study has been updated for this
   * institute. */
  onStudyChanged(callback: ObservableCallback<undefined | any[]>): IDisposable {
    return this.events.observe(notifyStudyChangedEvent, callback);
  }

  /** Subscribes for notifications from the server that any plueprint has been published
   * for this institute. */
  onBlueprintPublished(callback: ObservableCallback<undefined | any[]>): IDisposable {
    return this.events.observe(notifyBlueprintPublishedEvent, callback);
  }

}

export const serviceName = "pushService";

export default ngModule("midas.utility.push", [authModule.name])
  .service(serviceName, PushService);