import disposable, {
    Disposable
} from "../disposable";

/** Type of function which should be used as a callback for the observe() function. */
export type ObservableCallback<T> = (arg?: T) => (void | any);

/**
 * Returns a function which removes a single instance of item from array. Does not maintain array
 * order, but does remove items efficiently.
 * @param array The array to remove from.
 * @param item The item to remove an instance of.
 * @returns A function which removes item from array when called.
 */
export function unorderedRemoveLater<T>(array: T[], item: T) {
    if (array == null) {
        return (() => undefined);
    }
    return function () {
        let result = undefined;
        const i = array.indexOf(item);
        if (i >= 0) {
            result = array[i];
            array[i] = array[array.length - 1];
            array.pop();
        }
        return result;
    };
};

/**
 * Registers an observer for a named callback, returning a deregistration function.
 * Calling the returned function removes the callback from the observer list and returns it.
 * The order of observers is not guaranteed to remain stable.
 *
 * @param observers An object for storing observer registrations against event names.
 * @param eventName The name of the event on this object to observe.
 * @param callback The callback to register to the event.
 * @returns A deregistration function for this observer.
 * @example
 * let observers = {};
 * let dispose = utils.observe(observers, "eventName", value => console.log("Hello", value));
 * utils.notify(observers, "eventName", "world")
 * dispose();
 *
 * @memberOf UtilsService
 */
export function observe<T>(observers: { [event: string]: ObservableCallback<T>[] }, event: string, callback: ObservableCallback<T>);
/**
 * Registers an observer for a named callback, returning a deregistration function.
 * Calling the returned function removes the callback from the observer list and returns it.
 * The order of observers is not guaranteed to remain stable.
 *
 * @param observers An object for storing observer registrations against event names.
 * @param callback The callback to register to the event.
 * @returns A deregistration function for this observer.
 * @example
 * let observers = [];
 * dispose = utils.observe(observers, value -> console.log("Hello", value));
 * utils.notify(observers, "world");
 * dispose.destroyWith(scopeOrElement);
 *
 * @memberOf UtilsService
 */
export function observe<T>(observers: ObservableCallback<T>[], callback: ObservableCallback<T>);
export function observe<T>(): Disposable {
    const events = arguments[0];
    let obs: ObservableCallback<T>[];
    let callback: ObservableCallback<T>;
    switch (arguments.length) {
        case 3:
            const eventName = arguments[1];
            obs = events[eventName] || (events[eventName] = []);
            callback = arguments[2];
            break;
        case 2:
            obs = events;
            callback = arguments[1];
            break;
        default:
            throw new Error("observe requires 2 or 3 arguments");
    }
    if (typeof callback !== "function") {
        throw new Error("callback must be a function");
    }

    obs.push(callback);
    //Create the registration disposal function to return.
    return disposable(unorderedRemoveLater(obs, callback));
}


/**
 * Notifies all observers of a named event by producing a value to them.
 *
 * @param {ObservableCallback<T>[]} observers An array for storing observer registrations.
 * @param value Optional value to send to all observers.
 * @example
 * let observers = [];
 * let dispose = utils.observe(observers, value => console.log("Hello", value));
 * utils.notify(observers, "world");
 * @memberOf UtilsService
 */
export function notify<T>(observers: ObservableCallback<T>[], value?: T): void;
/**
 * Notifies all observers of an event by producing a value to them.
 *
 * @template T The event argument.
 * @param observers An object for storing observer registrations.
 * @param eventName The name of the event to register for.
 * @param value Optional value to send to all observers.
 * @example
 * let observers = {};
 * let dispose = utils.observe(observers, "eventName", value => console.log("Hello", value));
 * utils.notify(observers, "eventName", "world");
 * @memberOf UtilsService
 */
export function notify<T>(observers: { [event: string]: ObservableCallback<T>[] }, eventName: string, value?: T): void;
export function notify<T>() {
    const events = arguments[0];
    let obs: ObservableCallback<T>[];
    let value: T;
    switch (arguments.length) {
        case 3:
            const eventName = arguments[1];
            obs = events[eventName] || (events[eventName] = []);
            value = arguments[2];
            break;
        case 2:
            obs = events;
            value = arguments[1];
            break;
        case 1:
            obs = events;
            value = null;
            break;
        default:
            throw new Error("notify requires 1 to 3 arguments");
    }

    if (obs == null) {
        return;
    }
    if (Array.isArray(obs)) {
        for (var callback of obs) {
            if (typeof callback === 'function') {
                callback(value);
            }
        }
    }
};