import {
  module as ngModule,
  INgModelController,
  IComponentOptions,
  IController,
  FileSaver
} from "angular";
import { ExamType, Exam } from "../../../businessModels";
import { BindingOf } from "../../../utility/componentBindings";
import tinyMceEditorModule from "../../../tinymce.module";
import reportModeModule from "../../../utility/tinymce/plugins/report-preview-plugin";
import insertMarkerModule from "../../../utility/tinymce/plugins/insert-marker-plugin";
import outlineModeModule from "../../../utility/tinymce/plugins/outline-mode-plugin";
import cursorCharactersPlugin from "../../../utility/tinymce/plugins/cursor-characters-plugin"
import insertConditionModule from "../../../utility/tinymce/plugins/insert-condition-plugin";
import insertOptionsModule from "../../../utility/tinymce/plugins/insert-options-plugin";
import insertExpressionModule from "../../../utility/tinymce/plugins/insert-expression-plugin";
import disableEditPluginModule from "../../../utility/tinymce/plugins/protect-widgets-plugin";
import "./blueprint-editor.component.scss";
import studyDialog from "../dialogs/report-formatter-choose-study.dialog";
import blueprintPanelConditionModule from "../dialogs/blueprint-panel.component";
import stickyModule from "../../../utility/layout/mdsSticky";
import "tinymce/plugins/paste";
import "tinymce/plugins/visualchars";
import insertLocationPlugin from "../../../utility/tinymce/plugins/insert-location-plugin";
import blueprintPanelsPlugin from "../../../utility/tinymce/plugins/edit-widgets-plugin";
import autoFocusPlugin from "../../../utility/tinymce/plugins/auto-focus-plugin";
import pastePlugin from '../../../utility/tinymce/plugins/paste-plugin';
import instituteSettingsPlugin from '../../../utility/tinymce/plugins/institute-settings-plugin';
import fixedToolbarPlugin from '../../../utility/tinymce/plugins/fixed-toolbar-plugin';

/** These imports put types on "angular"; */
import "angular-file-saver";
import "ng-file-upload";

/** Binds an editable report formatter html template. */
class BlueprintEditorController implements IController, IBlueprintEditorBindings {
  /** The ngModel controller to handle transmission of changes to parent. */
  ngModel: INgModelController;

  /** The local template instance to bind to. */
  template: string;

  /** Bound module used to provide to measurement type dialog. */
  examType: ExamType;
  exam: Exam;

  static $inject = [
    "$mdDialog",
    "businessModels",
    "FileSaver",
    "$mdToast"];

  constructor(
    private readonly fileSaver: FileSaver,
    private readonly mdToast: angular.material.IToastService) {
  }

  $onInit() {
    this.ngModel.$render = () => {
      this.template = this.ngModel.$viewValue;
    };
  }

  templateChanged(): void {
    this.ngModel.$setViewValue(this.template);
  }

  onElementChanged(): void {
    this.ngModel.$setViewValue(this.template);
  }

  upload(file: File): void {
    if (file == null) {
      return;
    }
    const reader = new FileReader();
    reader.onload = () => {
      this.template = <string> reader.result;
      this.mdToast.showSimple("Blueprint updated.");
      this.templateChanged();
    };
    reader.onerror = () =>
      this.mdToast.showSimple("There as an issue reading the uploaded Blueprint. Please check the file and try again.");
    reader.readAsText(file);
  }

  download(): void {
    this.fileSaver.saveAs(
      new Blob(
        [this.template], {
          type: "text/html;charset=utf-8"
        }),
        `blueprint-${this.examType.key}.html`
      );
  }
}

interface IBlueprintEditorBindings {
  examType: ExamType;
}

const bindings: BindingOf<IBlueprintEditorBindings> = {
  examType: "<"
};

const componentOptions: IComponentOptions = {
  controller: BlueprintEditorController,
  templateUrl: require("./blueprint-editor.component.html"),
  require: {
    ngModel: "^"
  },
  bindings
};

export default ngModule("midas.admin.blueprint.blueprintEditor", [
  tinyMceEditorModule.name,
  insertMarkerModule.name,
  insertConditionModule.name,
  insertOptionsModule.name,
  reportModeModule.name,
  cursorCharactersPlugin.name,
  blueprintPanelsPlugin.name,
  studyDialog.name,
  blueprintPanelConditionModule.name,
  stickyModule.name,
  insertLocationPlugin.name,
  insertExpressionModule.name,
  disableEditPluginModule.name,
  outlineModeModule.name,
  autoFocusPlugin.name,
  pastePlugin.name,
  instituteSettingsPlugin.name,
  fixedToolbarPlugin.name,
  "ngFileSaver",
  "ngFileUpload"
]).component("blueprintEditor", componentOptions);