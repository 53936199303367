import { IComponentOptions, IComponentController } from "angular";
import { BindingOf, IChangesObject } from "../../../utility/componentBindings";

interface Bindings {
    selectedLevel: string;
}

export namespace VisualStack {
    /** Implementors of a stack level must conform to this interface. */
    export interface ILevel {
        levelId?: string;
        select();
        deselect();
        update(level: number, depth: number);
    }
    
    /** When a stack level is registered with a visual stack component controller, this registration
     * object is returned. */
    export interface IRegistration {
        deregister();
        select();
    }
}

/** Defines the controller for a visual stack component. */
export class VisualStack implements IComponentController, Bindings {
    selectedLevel: string;

    private readonly levels = new Array<VisualStack.ILevel>();
    private currentLevel: VisualStack.ILevel;

    $onChanges(obj: IChangesObject<Bindings>): void {
        if (obj.selectedLevel) {
            this.selectedLevel = obj.selectedLevel.currentValue;
            this.select(this.selectedLevel);
        }
    }

    reset() {
        if (this.levels.length === 0)
            return;
        this.select(this.levels[0]);
    }

    next() {
        if (!this.canNavigate())
            return;

        const index = this.levels.indexOf(this.currentLevel);
        if (index === this.levels.length - 1)
            return;

        this.select(this.levels[index + 1]);
    }
    
    previous() {
        if (!this.canNavigate())
            return;

        const index = this.levels.indexOf(this.currentLevel);
        if (index == 0)
            return;

        this.select(this.levels[index - 1]);
    }

    register(level: VisualStack.ILevel): VisualStack.IRegistration {
        this.levels.push(level);
        if (!this.currentLevel) {
            this.select(level, false);
        }

        this.updateLevels();
        
        return {
            deregister: () => this.deregister(level),
            select: () => this.select(level)
        };
    }

    private deregister(level: VisualStack.ILevel) {
        const index = this.levels.indexOf(level);
        if (index !== -1) {
            this.levels.splice(index, 1);
        }
    }

    private canNavigate() : boolean {
        if (!this.currentLevel || this.levels.length < 2)
            return false;

        return true;
    }

    select(level: VisualStack.ILevel | string, updateLevels: boolean = true) {
        if (!level)
            return;

        level = typeof level === "string" ? this.getLevel(level) : level;

        if (level === this.currentLevel)
            return;

        if (this.currentLevel){
            this.currentLevel.deselect();
        }
        level.select();
        this.currentLevel = level;

        if(updateLevels)
            this.updateLevels();
    }

    getLevel(levelId: string): VisualStack.ILevel {
            for (var level of this.levels) {
                if (level.levelId === levelId) {
                    return level;
                }
            }
            return null;
    }

    updateLevels() {
        const indexOfSelectedLevel = this.levels.indexOf(this.currentLevel);
        for (var levelIndex = 0; levelIndex < this.levels.length; levelIndex++) {
            const item = this.levels[levelIndex];
            item.update(levelIndex, indexOfSelectedLevel - levelIndex);
        }
    }
}

export default <IComponentOptions> {
    template: `<div class="mds-visual-stack-container" ng-transclude></div>`,
    transclude: true,
    controller: VisualStack,
    bindings: {
        "selectedLevel": "<?"
    } as BindingOf<Bindings>
}
