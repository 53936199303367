import {
  IController,
  module as ngModule,
  IPromise,
  IQService,
  IDeferred
} from "angular";
import { BindingOf } from "../../../utility/componentBindings";
import operandModule from "../expressionEditor/expression-editor.component";
import blueprintPanelServiceModule, {
  BlueprintPanelsService,
  serviceName as panelsServiceName
} from "./blueprint-panel.service";
import { ExpressionController } from "../../../utility/reportFormatter/widgets/expression.service";
import { ConditionalController } from "../../../utility/reportFormatter/widgets/conditional.service";
import { BlueprintExpression, ExpressionOperatorType, isUnaryOp, isBinaryOp, IBinaryExpression, IUnaryExpression, getResultType } 
from "../../../utility/reportFormatter/widgets/condition-expression.models";
import { allOperators } from "..//expressionEditor/expression-editor.filters";
import { WidgetController } from "../../../utility/reportFormatter/widgets/widget";
import { MarkerController } from '../../../utility/reportFormatter/widgets/marker.service';
import filtersModule from "../expressionEditor/expression-editor.filters";
import inlineHelpModule from '../../../utility/inlineHelp/inlineHelp.component';
import "./blueprint-panel.component.scss";

type PanelShowResult = "accept" | "cancel" | "remove";

/** Public interface to a blueprint panel. */
export interface IBlueprintPanel {
  /** The unique name of this panel. */
  readonly panelId;
  /** Gets whether this blueprint panel is showing for a widget. */
  readonly isShowing;
  /** Shows the blueprint panel with the provided widget and setting. Returns a promise which will
   * resolve when a finalise action happens on the panel, similar to how material dialogs/toasts
   * work. */
  show(widget: WidgetController): IPromise<PanelShowResult>;
  /** Hides the panel. Any waiting promise is resolved with "cancel". */
  hide(): void;
}

/** The panel shown alongside the Blueprint editor to allow editing of conditions. */
class BlueprintPanelController implements IController, IBlueprintPanelComponentBindings, IBlueprintPanel {

  /** The ID for this panel. Used by the blueprint panel service to allow communication to this
   * panel. */
  readonly panelId: string;

  private _result: IDeferred<PanelShowResult>;

  /** Whether the accept button should be displayed. */
  requireAccept: boolean;

  /** Whether the remove button should be displayed. */
  canRemove: boolean;

  /** The controller of the widget currently being edited. */
  public widget: null | ExpressionController | MarkerController | ConditionalController = null;

  public hasName: boolean;

  /** Variable name. Not relevant unless editing a variable widget. */
  public name?: string;

  public hasExpression: boolean;
  /** Widget expression. */
  public expression?: BlueprintExpression;
  /** Gets the kind of widget being displayed by this panel. */
  public type: "output-value" | "set-marker" | "condition";

  public extendOperators = allOperators;

  static $inject = [panelsServiceName, "$q"];
  constructor(
    private readonly panels: BlueprintPanelsService,
    private readonly $q: IQService) {
  }

  $onInit() {
    this.panels.register(this);
  }

  $onDestroy() {
    this.panels.unregister(this);
  }

  /** Removes the panel widget, resolving any waiting response with "remove". */
  remove(): void {
    if (this.widget && this._result) {
      this._result.resolve("remove");
      this.reset();
    }
  }

  /** Accepts the panel widget, setting any values back to the widget, and resolving any waiting
   * response with "accept". */
  accept(): void {
    if (this.widget && this._result) {
      if (this.widget instanceof MarkerController) {
        this.widget.nameText = this.name;
      } else {
        this.widget.expression = this.expression;
      }
      this._result.resolve("accept");
      this.reset();
    }
  }

  /** Wraps the current expression in a new binary or unary expression with the provided operator.
   */
  extendExpression(kind: ExpressionOperatorType) {
    if (isUnaryOp(kind)) {
      this.expression = <IUnaryExpression> {
        type: "unary-expression",
        operand1: this.expression,
        operator: kind
      };
    } else if (isBinaryOp(kind)) {
      this.expression = <IBinaryExpression> {
        type: "binary-expression",
        operand1: this.expression,
        operator: kind,
        operand2: null
      };
    }
  }

  /** Gets whether this blueprint panel is showing for a widget. */
  get isShowing() {
    return this.widget != null && this._result != null;
  }

  /** Shows the blueprint panel with the provided widget and setting. Returns a promise which will
   * resolve when a finalise action happens on the panel, similar to how material dialogs/toasts
   * work. Will throw if the widget is of an unsupported type. */
  show(widget: WidgetController): IPromise<PanelShowResult> {
    if (widget === this.widget) {
      return this._result.promise;
    }

    this.hide();

    if (widget instanceof ExpressionController) {
      this.type = "output-value";
      this.expression = widget.expression;
      this.hasExpression = true;
    } else if (widget instanceof MarkerController) {
      this.type = "set-marker";
      this.name = widget.nameText;
      this.hasName = true;
    } else if (widget instanceof ConditionalController) {
      this.type = "condition";
      this.expression = widget.expression;
      this.hasExpression = true;
    } else {
      throw Error("widget must be ExpressionController | MarkerController | ConditionalController");
    }

    this._result = this.$q.defer();
    this.widget = widget;
    return this._result.promise;
  }

  hide() {
    if (this.widget && this._result) {
      this._result.resolve("cancel");
      this.reset();
    }
  }

  /** Called from the template when the user clicks the remove expression button. */
  onExpressionRemove(): void {
    this.expression = null;
  }

  /** Gets user land displayable text for the actual type of an expression. */
  getActualTypeText(expr: BlueprintExpression = this.expression) {
    const type = getResultType(expr);
    switch(type) {
      case "boolean": return "true/false";
      case "number": return "a number";
      case "string": return "text";
    }
    return type;
  }

  /** Resets the panel back to an empty state. Doesn't resolve any promises, so do that before
   * calling this. */
  private reset() {
    this.type = null;
    this.widget = null;
    this.hasName = false;
    this.name = null;
    this.hasExpression = false;
    this.expression = null;
    this._result = null;
    this.canRemove = true;
  }
}

interface IBlueprintPanelComponentBindings {
  panelId: string;
}

export default ngModule("midas.admin.blueprint.panel", [
  operandModule.name,
  blueprintPanelServiceModule.name,
  filtersModule.name,
  inlineHelpModule.name
]).component("mdsBlueprintPanel",
  ({
    controller: BlueprintPanelController,
    templateUrl: require("./blueprint-panel.component.html"),
    bindings: {
      panelId: "@"
    } as BindingOf<IBlueprintPanelComponentBindings>
  }) as any);