import * as angular from "angular";

export class PromptLoseChangesAndNavigateService {
    private $modal;
    private $state;
    private models;
    private subs: PromptLoseChangesArguments[];
    private redirecting = false;
    private $q: angular.IQService;

    static $inject = ["$rootScope", "businessModels", "$state", "$uibModal", "$q"];

    constructor(
        $rootScope: angular.IScope, models, $state, $modal, $q
    ) {
        this.$modal = $modal;
        this.$state = $state;
        this.models = models;
        this.subs = new Array<PromptLoseChangesArguments>();
        this.listen($rootScope);
        this.$q = $q;
    }

    subscribe(args: PromptLoseChangesArguments) {
        this.subs.push(args);
        return () => {
            var index = this.subs.indexOf(args);
            if (index === -1)
                return;
            this.subs.splice(index, 1);
        };
    }

    private listen(scope: angular.IScope) : void {
        scope.$on("$stateChangeStart",
            (e, toState, toParams, fromState) => {
                //Ignore state change if it is just a layout change (full-screen toggle)
                if (toState.name.replace(".layout", "") === fromState.name.replace(".layout", ""))
                    return;
                //Check flag to ignore next navigation as it is initiated by the service when ignoring changes and continuing with the state change
                if (this.redirecting) {
                    this.redirecting = false;
                    return;
                }
                var dirty = this.getDirtySubs();
                if (dirty.length === 0)
                    return;
                //Prevent navigation
                e.preventDefault();
                this.$modal.open({ templateUrl: require("./promptLoseChanges.html") })
                    .result.then((save) => this.saveOrRejectAll(save, toState, toParams));
            });
    }

    private getDirtySubs() : Array<PromptLoseChangesArguments> {
        const dirty = new Array<PromptLoseChangesArguments>();
        for (let sub of this.subs) {
            try {
                if (sub.isDirty())
                    dirty.push(sub);
            }
            catch (ex) {
                console.log("Error in subscription.isDirty()", ex);
            }
        }
        return dirty;
    }

    hasChanges(): boolean {
        return this.getDirtySubs().length !== 0;
    }

    /* Input parameters:
    * save - whether changes are to be saved
    * toState - destination state
    * toParams - destination params
    * basicSave - this parameter is used for the save function execution flow's interruption,
    * straight after performing all the necessary saving steps, 
    * to reload the application after system's upgrade
    */
    saveOrRejectAll(save: boolean, toState, toParams, basicSave = false): angular.IPromise<any> {
        //If user wants to save then we need to let all subscribers know to do so TODO: I'm not sure
        //what would happen if multiple children needed to save at once. It's not a problem now, but
        //consider a chain of promises.
        var promises = [];
        if (save) {
            for (let sub of this.subs) {
                var promise = sub.save(toState, toParams, basicSave);
                promises.push(promise);
            }
        } else {
            this.models.breeze.rejectChanges();
            this.redirecting = true;
            this.$state.go(toState, toParams);
        }
        return this.$q.all(promises);
    }
}

export class PromptLoseChangesArguments {
    isDirty;
    save;
    constructor(isDirty, saveFn) {
        this.isDirty = isDirty;
        this.save = saveFn;
    }
}

export default PromptLoseChangesAndNavigateService;