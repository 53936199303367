  /** The extent of the internal unitless coordinate system. For instance if this is 1000,
   * then the range of values for the internal coordinate system in horizontal dimension is
   * [0, 1000]. It can be useful to set this to a value greater than 1 to get around canvas
   * limitations, such as sub pixel font size rounding, and easeljs hit testing, neither of which
   * like being in a [0, 1] coordinate system. */
  export const coordExtent = 1000;

  /** The width we expect to be displaying images at, in centimetres. */
  export const defaultCanvasWidth = 21; //21cm = A4 page width.
  /** The number of dots (pixels) per cm we want. */
  export const DefaultDotsPerCentimetre = 118; //118 dots per cm = 300 dpi.