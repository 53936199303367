import { MeasurementController, IMeasurementScope } from "../MeasurementController";
import * as angular from "angular";
class DXHipController extends MeasurementController {
  static $inject = ["$scope"];
  constructor(scope: IMeasurementScope) {
    super(scope);
  }

  clickRightOK() {
    this.setMeasurementValue("DXHipRtBones", "No fracture or dislocation is present. ");
    this.setMeasurementValue("DXHipRtSoftTissue", "Normal. ");
    this.setMeasurementValue("DXHipRtJoints", "The joint spaces are normal. ");
    this.setMeasurementValue("DXHipRtOtherComment", "None. ");
    console.log("Right All normal clicked")
  }

  clickLeftOK() {
    this.setMeasurementValue("DXHipLtBones", "Normal. No fractures are present. ");
    this.setMeasurementValue("DXHipLtSoftTissue", "Normal. ");
    this.setMeasurementValue("DXHipLtJoints", "The joint spaces are normal. ");
    this.setMeasurementValue("DXHipLtOtherComment", "None. ");
    console.log("Left All normal clicked")
  }
}

angular.module("midas.utility.measurement.views").controller("DXHipCtrl", DXHipController);