import {
    module as ngModule,
    IDirective,
    IAttributes,
    IScope,
    IAugmentedJQuery,
    ILogService
} from "angular";
import { isNullOrWhitespace } from './mdsUtils';

/** Can be used wherever src needs to be set and we're using a literal template string that
 * needs to be processed by webpack. EG `<ng-include src="'app/template.html'"></ng-include>`.
 * Webpack won't be able to understand the URL because it's enclosed with ''. Instead we can use
 * `<ng-include src-raw="app/template.html"></ng-include>`, which will set up the src attribute
 * appropriately for ng-include, while still being processable by webpack (if webpack is set up to
 * parse that attribute). */

export default ngModule("midas.utility.srcRaw", [])
    .directive("srcRaw", ["$log", ($log: ILogService) => {
        return <IDirective>{
            restrict: "A",
            priority: 500, // ng-include is 400 so we want to be before that.
            compile($element: IAugmentedJQuery, $attr: IAttributes) {
                const srcRaw = $attr.srcRaw;
                if (!isNullOrWhitespace($attr.src)) {
                    $log.error("src-raw attribute and src attribute present on same element: " + $element);
                }
                if (!isNullOrWhitespace($attr.ngSrc)) {
                    $log.error("src-raw attribute and ng-src attribute present on same element: " + $element);
                }
                if (!isNullOrWhitespace(srcRaw)) {
                    $attr.$set("src", `'${srcRaw}'`);
                }
            }
        };
    }]);