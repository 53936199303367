import * as angular from "angular";
import "angular-material";
import * as diagramTemplateUrl from "./inlineHelpDialog.template.html";

export interface InlineHelp extends angular.IController {}
export class InlineHelp implements angular.IController {
    /** Whether the title transclusion slot asked to hide the close button. */
    hideTitleClose: boolean;
    /** Whether the title transclusion slot was filled. */
    hasTitle: boolean;
    /** The ambient theme of the directive so the dialog can also access it. */
    theme: angular.material.ITheme;
    /** The lazily compiled template for the dialog portion of the directive. */
    static $compiledDialog: angular.IPromise<angular.ITemplateLinkingFunction>;
    /** The URL of the template for the dialog this directive shows when a user asks for more
     * help. This template is compiled lazily, and a link function cached in $compiledDialog. */
    static diagramTemplateUrl = diagramTemplateUrl;

    static $inject = [
        "$element", "$scope", "$mdDialog", "$compile", "$templateRequest", "$log", "$mdToast", "$q",
        "$transclude"
    ];

    constructor(
        private readonly $element: angular.IAugmentedJQuery,
        private readonly $scope: angular.IScope,
        private readonly $mdDialog: angular.material.IDialogService,
        private readonly $compile: angular.ICompileService,
        private readonly $templateRequest: angular.ITemplateRequestService,
        private readonly $log: angular.ILogService,
        private readonly $mdToast: angular.material.IToastService,
        private readonly $q: angular.IQService,
        private readonly $transclude: angular.ITranscludeFunction) { }

    /** Loads the diagram template and compiles it, then returns the linking function. */
    private getDialogLinkFn() {
        return InlineHelp.$compiledDialog || (InlineHelp.$compiledDialog =
            this.$templateRequest(InlineHelp.diagramTemplateUrl)
                   .then(template => this.$compile(template)));
    }

    $onInit() {
        this.theme = (this.$element.controller('mdTheme') || {}).$mdTheme;
        this.hasTitle = this.$transclude.isSlotFilled("title");
    }

    /** Takes the compiled dialog templates, and manually transcludes the content that the
     * user provided into the appropriate place within.
     * @param dialogTemplate The compiled template for the dialog service.
     * @param dialogScope The scope of the dialog. Any scopes created during transclusion will
     * be destroyed when this scope is destroyed. */
    private transcludeDialogContent(
        dialogTemplate: angular.IAugmentedJQuery,
        dialogScope: angular.IScope
    ): void {
        const transcludeTo = dialogTemplate[0].querySelector("[mds-transclude='']");
        if (transcludeTo) {
            const transcludeTarget = angular.element(transcludeTo);
            const transcluded = this.$transclude(undefined);
            transcludeTarget.children().remove();
            transcludeTarget.append(transcluded);
        }
    }

    private transcludeDialogTitle(
        dialogTemplate: angular.IAugmentedJQuery,
        dialogScope: angular.IScope
    ) {
        if (this.$transclude.isSlotFilled("title")) {
            const transcludeTo = dialogTemplate[0].querySelector('[mds-transclude="title"]');
            if (transcludeTo) {
                const transcludeTarget = angular.element(transcludeTo);
                const transcluded = this.$transclude(undefined, undefined, "title");
                transcludeTarget.children().remove();
                transcludeTarget.append(transcluded);

                //Manually bind the hide-close attribute to this controller.
                const hideCloseExpr = transcluded.attr("hide-close");
                if (hideCloseExpr) {
                    dialogScope.$watch(hideCloseExpr, (hideClose: boolean) =>
                        this.hideTitleClose = hideClose);
                }
            }
        }
    }

    /** Shows the dialog using the provided values along with default options. */
    private showDialog(contentElement: angular.IAugmentedJQuery, targetEvent?: MouseEvent) {
        return this.$mdDialog.show({
            contentElement: contentElement[0],
            clickOutsideToClose: true,
            targetEvent,
            escapeToClose: true
        })
    }

    show($event?: MouseEvent) {
        return this.getDialogLinkFn()
            .then(link => this.$q<any>((resolve, reject) => {
                //Pass a child scope of the scope of this directive to the dialog. That way this
                //controller and all bindings are available to the dialog, but the lifetime of the
                //scope and any children which are compiled can be controlled separately from the
                //button which initially shows it.
                link(this.$scope.$new(false, this.$scope), (dialogClone, dialogScope) => {

                    this.transcludeDialogContent(dialogClone, dialogScope);
                    this.transcludeDialogTitle(dialogClone, dialogScope);

                    this.showDialog(dialogClone, $event)
                        .finally(() => {
                            dialogScope.$destroy();
                            dialogClone.remove(); //Also triggers $destroy() on element.
                        })
                        .then(resolve, reject);
                });
            }))
            .catch(err => {
                if (err) { //The dialog rejects with undefined when you click outside it.
                    this.$log.error("Error showing inline help text", err);
                    this.$mdToast.showSimple("Error showing inline help text. Please contact MIDAS.");
                    return this.$q.reject(err);
                }
            });
    }

    confirm() {
        return this.$mdDialog.confirm();
    }
    cancel(response?: any) {
        return this.$mdDialog.cancel(response);
    }
    hide(response?: any) {
        return this.$mdDialog.hide(response);
    }
}

const component: angular.IComponentOptions = {
    controller: InlineHelp,
    controllerAs: "$ctrl",
    transclude: {
        title: "?mdsInlineHelpTitle"
    },
    templateUrl: require("./inlineHelp.component.html")
};

export default angular.module("mdsInlineHelp", ["ngMaterial"])
                      .component("mdsInlineHelp", component)