import * as angular from "angular";
import { IController } from "angular";

class BillingInformationController implements IController {

    public dummyModel = {
      processedExams: 80,
      remainingExams: 20,
      examCostOverSub: 2.5,
      reportTypes: [
        { reportType: "MSK", quantity: 15 }, 
        { reportType: "Uper Abdo", quantity: 25 }, 
        { reportType: "Vascular", quantity: 40 }
      ]
    };

    static $inject = [];

    constructor() {}

    $onInit(): void {}
}

export default angular.module("midas.admin.billingInformation", [])
.component("billingInformation", {
  controller: BillingInformationController,
  templateUrl: require("./billingInformation.component.html")  
});