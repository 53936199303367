import ReferenceGroup from './groups/ReferenceGroup';
import { IScope, IRootScopeService, IAngularEvent } from 'angular';
import { IStateService, IStateParamsService } from 'angular-ui-router';
import { LoadingStatus } from '../../utility/utils';
import { BusinessModelService, Study } from "../../businessModels";
import { LoDashStatic } from 'lodash';
import ReferenceService from './referencesService';

/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS103: Rewrite code to no longer use __guard__
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */

 interface IReferenceScope extends IScope {
  groups: ReferenceGroup[];
  selectedGroup: ReferenceGroup;
  studies: Study[];
  mode: "groups" | "item" | "thumbnails";
  thumbnailStatus: LoadingStatus;
  loadFullItem(thumb: any): any;
  back(): void;
  loadThumbnails(group: ReferenceGroup);
  currentStateIs(state: string): boolean;
  selectStudy(stud: Study): ReferenceGroup[];
  setGlobalGroup(group: ReferenceGroup): IAngularEvent;
 }

export default [
  "$rootScope", "$state", "$stateParams", "loadingStatus", "references", "businessModels", "lodash",
  ($rootScope: IRootScopeService,
    $state: IStateService,
    $stateParams: IStateParamsService,
    loadingStatus: typeof LoadingStatus,
    references: ReferenceService,
    models: BusinessModelService,
    _: LoDashStatic) =>
    ({
      restrict: "E",
      templateUrl: require("./mdsReferences.html"),
      scope: {
        study: "=",
        history: "="
      },
      link($scope: IReferenceScope) {

        let thumbnailStatus;

        $scope.$on('$destroy', () => $scope.selectedGroup != null ? $scope.selectedGroup.stopPolling() : undefined);

        $scope.$on("reference-select", function(e,args) {
          if (e.defaultPrevented) { return; }
          e.preventDefault();
          const group = _.find($scope.groups, g => g.name === args.category);
          if (!group) { return; }
          return $scope.loadThumbnails(group)
          .then(function() {
            $scope.mode = "thumbnails";
            if (!args.id) { return; }
            const thumb = _.find(group.thumbnails, t => (t.id === args.id) && (!args.subCategory || (t.subCategory === args.subCategory)));
            if (!thumb) { return; }
            return $scope.loadFullItem(thumb);
          });
        });

        $scope.$on("reference-polling", function(e, args) {
          console.log('receive event for polling');
          if (args.handled) { return; }
          args.handled = true;
          const group = _.find($scope.groups, g => g.name === args.category);
          if (!group) { return; }
          return group.startPolling();
        });

        //Refresh if current category is being viewed
        $scope.$on("reference-refresh", function(e, args) {
          if (!args.ifCategory || (args.ifCategory === ($scope.selectedGroup != null ? $scope.selectedGroup.name : undefined))) {
            if (args.backToGroup) { $scope.mode = "thumbnails"; }
            return ($scope.selectedGroup != null ? $scope.selectedGroup.loadThumbnails() : undefined);
          }
        });

        $scope.$on("reference-reload", function(e, args) {
          if (!args.ifCategory || (args.ifCategory === ($scope.selectedGroup != null ? $scope.selectedGroup.name : undefined))) {
            return $scope.trigger.reload = true;
          }
        });

        $scope.$on("reference-reset", function(e, args) {
           $scope.mode = "groups";
        });

        $scope.trigger = {
          reload: false
        };

        $scope.thumbnailStatus = (thumbnailStatus = new loadingStatus());

        $scope.mode = "groups";

        $scope.currentStateIs = stateName => $state.includes(stateName);

        $scope.selectStudy = function(study) {
          $scope.selectedStudy = study;
          $scope.mode = "groups";
          return $scope.groups = references.listGroups(study, $scope.currentStudy);
        };

        $scope.currentStudy = $scope.study;
        $scope.selectStudy($scope.study);

        $scope.studies = $scope.history;

        $scope.loadThumbnails = function(group: ReferenceGroup) {
          if ($scope.selectedGroup != null) {
            $scope.selectedGroup.stopPolling();
          }
          $scope.selectedGroup = group;
          $scope.mode = "thumbnails";
          group.clearThumbnails();
          const promise = group.loadThumbnails();
          thumbnailStatus.track(promise);
          return promise.then(function(thumbnails) {
            //Go straight to full item if there is only one thumbnail
            if (thumbnails.length === 1) { $scope.loadFullItem(thumbnails[0]); }
            return group.startPolling();
          });
        };

        $scope.loadFullItem = function(thumbnail) {
          $scope.mode = "item";
          return $scope.selectedGroup.setItem(thumbnail);
        };

        //Toggles whether an image is included in the report and saves the new value. Nothing should
        //really be modified on the reference side, but we'd be ignoring practicality if we prevented
        //people pinning images there.
        $scope.toggleInReport = function(image) {
          if ($scope.study.userCanEdit() && !models.User.current.hasRole('Read-Only Mode')) {
            const valueToSave = !image.isIncludedInReport;
            return models.save(() => image.isIncludedInReport = valueToSave);
          }
        };

        $scope.back = function() {
          //Go back to group list if there is only one thumbnail
          if (($scope.mode === "item") && ($scope.selectedGroup.thumbnails.length === 1)) { $scope.mode = "groups";
          } else if ($scope.mode === "item") { $scope.mode = "thumbnails";
          } else if ($scope.mode === "thumbnails") { $scope.mode = "groups"; }

          if ($scope.mode === "groups") {
              if ($scope.selectedGroup != null) {
                $scope.selectedGroup.stopPolling();
              }
              return $scope.selectedGroup = null;
            }
        };

        $rootScope.$on("setGlobalGroup", function(_event, name) {
          let foundGroup;
          for (let group of $scope.groups) {
            if (group.name === name) {
              foundGroup = group;
              break;
            }
          }
          if (foundGroup === null) { return; }
          return $scope.loadThumbnails(foundGroup);
        });

        return $scope.setGlobalGroup = group => $rootScope.$broadcast("setGlobalGroup", group.name);
      }
    })
  
];

function __guardMethod__(obj, methodName, transform) {
  if (typeof obj !== 'undefined' && obj !== null && typeof obj[methodName] === 'function') {
    return transform(obj, methodName);
  } else {
    return undefined;
  }
}