import * as angular from "angular";
import reportEditorModule, { ToolbarAttributes } from "../mds-rich-text-editor.component";
import businessModelsModule, { BusinessModelService } from "../../../businessModels";
import * as tinymce from "tinymce";
import { TinymceConfigurationController, tinymceConfigurationRequire } from "../../../utility/tinymce/mds-rich-text-editor.component";

const insertTooltip = "Insert glossary entry";
const noGlossaryTooltip = "No glossary items available";
const loadingGlossaryTooltip = "Loading glossary items...";

class GlossaryMenuController extends TinymceConfigurationController {
    /** The root button options for the button which opens the menu. */
    buttonOptions = {
        classes: "widget btn open-glossary",
        type: "menubutton",
        tooltip: insertTooltip,
        text: "Glossary",
        icon: false,
        disabled: true,
        menu: <any> false
    };

    static $inject = ["businessModels", "$attrs"];
    constructor(private readonly models: BusinessModelService, private readonly $attrs: angular.IAttributes) {
        super();
    }

    configure(options: tinymce.Settings) {
        const toolbar = ToolbarAttributes.FromAttributes(this.$attrs);
        this.extendToolbar(toolbar.id, "glossary", options);
    }

    setup(editor: tinymce.Editor) {
        const glossaryMenu = this.buttonOptions;

        glossaryMenu.menu = false;
        glossaryMenu.tooltip = loadingGlossaryTooltip;

        this.models.GlossaryCategory.list().then(cats => {
            glossaryMenu.menu = cats
                .filter(category => category.canView && !category.isDeleted)
                .map(category => {
                    const menu = category.glossary
                        .filter(item => item.canView && !item.isDeleted)
                        .map(item => {
                            return {
                                id: `glossary-item-${item.id}`,
                                text: item.abbreviation,
                                onclick() { editor.insertContent(item.entry); }
                            };
                        });
                    return {
                        text: category.name,
                        id: `glossary-category-${category.id}`,
                        menu
                    };
                })
                .filter(category => category.menu.length > 0);

            glossaryMenu.disabled = !glossaryMenu.menu || glossaryMenu.menu.length === 0;
            glossaryMenu.tooltip = glossaryMenu.disabled ? noGlossaryTooltip : insertTooltip;
        });

        editor.addButton("glossary", glossaryMenu);
    }
}

// When this module is imported, it adds the "glossary" plugin to tinymce.
export default angular.module("midas.utility.tinymce.glossary-menu", [
    reportEditorModule.name,
    businessModelsModule.name
]).component("glossaryMenu", {
    require: tinymceConfigurationRequire,
    controller: GlossaryMenuController
});