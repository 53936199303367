import { MeasurementController, IMeasurementScope } from "../MeasurementController";
import * as angular from "angular";
class DXPelvisController extends MeasurementController {
  static $inject = ["$scope"];
  constructor(scope: IMeasurementScope) {
    super(scope);
  }

  clickOK() {
    this.setMeasurementValue("DXPelvisRtOtherComment", "The sacrum and sacroiliac joints are intact. The innominate bones are normal. The hips are well preserved and normal. ");
    this.setMeasurementValue("DXPelvisConclusion", "Normal pelvis, hips, and sacroiliac joints. ");
    console.log("All normal clicked")
  }

}

angular.module("midas.utility.measurement.views").controller("DXPelvisCtrl", DXPelvisController);
