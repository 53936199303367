import ReferenceGroup from "./ReferenceGroup";
import { Study, BusinessModelService } from "../../../businessModels";
import { LoDashStatic } from "lodash";
import * as angular from "angular";
import "angular-ui-router";
import * as thumbnailUrl from "../templates/diagramThumbnail.html";
import * as fullUrl from "../templates/diagramFull.html";

export default class DiagramGroup extends ReferenceGroup {
    constructor(public study: Study,
        public currentStudy: Study,
        private businessModels: BusinessModelService,
        private $rootScope: angular.IScope,
        private lodash: LoDashStatic,
        private $q: angular.IQService,
        private $state: angular.ui.IStateService) {

        super("Diagrams", thumbnailUrl, fullUrl);

        this.iconClass = "fa fa-paint-brush";
    }

    loadThumbnails() {
        this.thumbnails =
            this.lodash.toArray(
                this.lodash.flatten(
                    this.lodash.pluck(this.study.exams, "diagrams"), true))
            .filter(d => d.isDeleted == false);
        return this.$q.when(this.thumbnails);
    }

    currentDiagramMatches() {
        if (!this.selectedItem) {
            return false;
        }
        const isNewView = this.$state.includes("midas.studies.view.diagrams.new");
        const isEditView = this.$state.includes("midas.studies.view.diagrams.edit");
        const correctView = isNewView || isEditView;
        const correctTemplate = this.$state.params["templateId"] === "" + this.selectedItem.template.id;
        return correctView && correctTemplate;
    }


    copyToCurrent() {
        if (!this.selectedItem) {
            return;
        }
        return this.businessModels.Diagram.find({ id: this.selectedItem.id }, "elements")
            .then((diagram) => {
                if (!diagram) {
                    return;
                }
                this.$rootScope.$broadcast("applyDiagramElement",
                {
                    studyId: this.currentStudy.id,
                    templateId: diagram.template.id,
                    elements: diagram.elements
                });

            });
    }
}