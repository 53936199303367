import { IPointLike, Vector2 } from "./vector2";

export class Triangle {

    constructor(
        public x0: number, public y0: number,
        public x1: number, public y1: number,
        public x2: number, public y2: number) {}

    area() {
        const a = this.x0 * (this.y1 - this.y2);
        const b = this.x1 * (this.y2 - this.y0);
        const c = this.x2 * (this.y0 - this.y1);
        return Math.abs((a + b + c) / 2);
    }

    /** Gets the centroid of the triangle. */
    centroid() {
        return {
            x: (this.x0 + this.x1 + this.x2) / 3,
            y: (this.y0 + this.y1 + this.y2) / 3
        };
    }

    /** Expands the triangle evenly in all directions from it's centroid. */
    expand(amount: number) {
        const centroid = this.centroid();
        let offset = new Vector2(this.x0, this.y0).subtract(centroid).normalise().multiply(amount);
        this.x0 += offset.x;
        this.y0 += offset.y;
        offset = new Vector2(this.x1, this.y1).subtract(centroid).normalise().multiply(amount);
        this.x1 += offset.x;
        this.y1 += offset.y;
        offset = new Vector2(this.x2, this.y2).subtract(centroid).normalise().multiply(amount);
        this.x2 += offset.x;
        this.y2 += offset.y;
        return this;
    }

    /** Gets whether this circle contains a point. A point exactly on the
     * edge is considered contained.
     * @param p The point to test against this shape. */
    contains(p: IPointLike) {
        // Barycentric hit detection algorithm. Works for either triangle winding by including the
        // sign calculation in there.
        // See a demo here https://jsfiddle.net/PerroAZUL/zdaY8/1/.
        var A = 0.5 * (-this.y1 * this.x2 + this.y0 * (-this.x1 + this.x2) + this.x0 * (this.y1 - this.y2) + this.x1 * this.y2);
        var sign = A < 0 ? -1 : 1;
        var s = (this.y0 * this.x2 - this.x0 * this.y2 + (this.y2 - this.y0) * p.x + (this.x0 - this.x2) * p.y) * sign;
        if (s >= 0) {
            var t = (this.x0 * this.y1 - this.y0 * this.x1 + (this.y0 - this.y1) * p.x + (this.x1 - this.x0) * p.y) * sign;
            return t >= 0 && (s + t) <= 2 * A * sign;
        }
        return false;
    }

    /** Moves this triangle by the provided amount, without changing it's size. */
    translate(p: IPointLike | number): this {
        const x = typeof p === "number" ? p : p.x;
        const y = typeof p === "number" ? p : p.y;
        this.x0 += x;
        this.y0 += y;
        this.x1 += x;
        this.y1 += y;
        this.x2 += x;
        this.y2 += y;
        return this;
    }

    /** Returns a copy of this triangle. */
    clone() {
        return new Triangle(
            this.x0, this.y0,
            this.x1, this.y1,
            this.x2, this.y2);
    }

    /** Returns a new tuple containing the 3 vertices which make up this triangle. */
    toVerts(): [IPointLike, IPointLike, IPointLike] {
        return [{
                x: this.x0,
                y: this.y0
            },
            {
                x: this.x1,
                y: this.y1
            },
            {
                x: this.x2,
                y: this.y2
            }
        ];
    }

    /** Sets this triangle to match the provided vertices. */
    setFromVerts(verts: [IPointLike, IPointLike, IPointLike]): this {
        this.x0 = verts[0].x;
        this.y0 = verts[0].y;
        this.x1 = verts[1].x;
        this.y1 = verts[1].y;
        this.x2 = verts[2].x;
        this.y2 = verts[2].y;
        return this;
    }

    /** Creates a new triangle from a set of vertices. */
    static fromVerts(verts: [IPointLike, IPointLike, IPointLike]) {
        return new Triangle(
            verts[0].x, verts[0].y,
            verts[1].x, verts[1].y,
            verts[2].x, verts[2].y);
    }
}

export default Triangle;