import * as angular from 'angular';
import { IPointLike, Vector2 } from '../geometry/vector2';
import { NestedArray } from '../mdsUtils';
import { notify, observe, unorderedRemoveLater } from '../events/rawEvents';
import { Disposable, createDisposable } from '../disposable';

export namespace Grip {
  /** Types which can be bound to Grip. */
  export type Bindable = IPointLike | ((offset: IPointLike) => void);
  /** A potentially infinitely nested array of types, bindable to a Grip. */
  export type ManyBindables = (Grip.Bindable | NestedArray<Grip.Bindable>)[];
}
/** A type which simplifies drag operations by providing drag start, drag, and revert operations,
 * along with the ability to bind callbacks or points for update. */
export class Grip {
  /** The start of the current ongoing drag operation. */
  readonly grabStart = new Vector2();
  /** The most recent drag position. */
  readonly lastDrag = new Vector2();
  /** Bound observer functions. */
  private readonly observers: ((p: IPointLike) => void)[] = [];
  /** Bound points. */
  private readonly bound: IPointLike[] = [];
  /**
   * Create a new Grip with the provided initial bindings.
   * @param initialBindings Objects to bind on construction. There is no way to unbind these,
   * so use bind() instead if you need finer grained control. */
  constructor(...initialBindings: (Grip.Bindable | NestedArray<Grip.Bindable>)[]);
  constructor() {
    //Bind any objects passed in as points, and functions as observers. Push directly into
    //arrays to avoid creating disposables, since we won't ever deregister these.
    const ctorBind = (args: IArguments | NestedArray<Grip.Bindable>) => {
      for (var i = 0; i < args.length; ++i) {
        var arg: Grip.Bindable | NestedArray<Grip.Bindable> = args[i];
        if (arg != null) {
          if (angular.isArray(arg)) {
            ctorBind(arg);
          } else if (typeof arg === "function") {
            this.observers.push(arg);
          } else {
            this.bound.push(arg);
          }
        }
      }
    };
    ctorBind(arguments);
  }

  /**
   * Initiates a new grab-drag operation. The next drag will offset based on the distance from
   * this point.
   * @param p
   */
  grab(p: IPointLike) {
    this.lastDrag.set(p);
    return this.grabStart.set(p);
  }

  /**
   * Handles a drag operation, offsetting all bound points by the drag amount relative to the last
   * grab or drag.
   */
  drag(p: IPointLike) {
    const offset = new Vector2(p).subtract(this.lastDrag);
    if ((offset.x !== 0) || (offset.y !== 0)) {
      this.lastDrag.set(p);

      for (let b of this.bound) {
        b.x += offset.x;
        b.y += offset.y;
      }

      notify(this.observers, offset);
    }
    return offset;
  }

  /** Immediately drags back to the last grab location. */
  revert() {
    return this.drag(this.grabStart);
  }

  /** Binds a point to this grip. It will be translated relative to this grip as the grip is
   * dragged. All bound points are updated before bound callbacks are invoked.
   * @param p The point object to bind to this grip.
   * @returns A function for removing this binding from the grip.
   */
  bind(p: IPointLike): Disposable;
  /** Binds a callback to this grip. It will be called with the offset of each new drag from
   * the previous drag. All bound points are updated before bound callbacks are invoked. Callback
   * order is not guaranteed to be stable.
   * @param callback The callback to notify of drag operations.
   * @returns A function for removing this binding from the grip.
   */
  bind(callback: (offset: IPointLike) => void): Disposable;
  /** Binds a number of points or callbacks to this grip.
   * @param pointsOrCallbacks The points and callbacks to bind.
   * @returns A function for removing this binding from the grip.
   */
  bind(...pointsOrCallbacks: Grip.ManyBindables);
  bind() {
    const dispose = createDisposable();
    for (let i = 0, end = arguments.length; i < end; i++) {
      var arg = arguments[i];
      if (arg != null) {
        this.doBind(dispose, arg);
      }
    }
    return dispose;
  }

  private doBind(dispose: Disposable, item: Grip.Bindable | Grip.ManyBindables) {
    if (angular.isArray(item)) {
      for (let i of item) {
        if (i != null) {
          this.doBind(dispose, i);
        }
      }
    } else if (typeof item === "function") {
      dispose.add(observe(this.observers, item));
    } else {
      this.bound.push(item);
      dispose.add(unorderedRemoveLater(this.bound, item));
    }
  }
}