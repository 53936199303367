/** Copied from https://github.com/angular-ui/ui-tinymce, which doesn't have a maintainer. We are
 * pushing tinymce to the limit, and this code is quite short, so it's handy to have complete
 * control over it.
 *
 * The primary functional change is to require '^ngModel' instead of 'ngModel'. That lets us avoid a
 * bunch of gymnastics while building our own editor up with plugins, and is more consistent with
 * other uses of ng-model. */

import * as tinymce from "tinymce";
import {
  module as ngModule,
  IRootScopeService,
  ICompileService,
  ITimeoutService,
  IWindowService,
  ISCEService,
  extend,
  isUndefined,
  INgModelController,
  IFormController,
  IPromise
} from 'angular';

// The tinymce type definitions are not fresh. Here are some updates for things specifically
// used here.
declare module "tinymce" {
  export function init(settings: tinymce.Settings): void | IPromise<void>;
}

/**
 * Binds a TinyMCE widget to <textarea> elements.
 */
export default ngModule('ui.tinymce', [])
  .value('uiTinymceConfig', {})
  .directive('uiTinymce', ['$rootScope', '$compile', '$timeout', '$window', '$sce', 'uiTinymceConfig', 'uiTinymceService',
    function($rootScope: IRootScopeService, $compile: ICompileService, $timeout: ITimeoutService, $window: IWindowService, $sce: ISCEService, uiTinymceConfig: any, uiTinymceService: UiTinymceService) {
    uiTinymceConfig = uiTinymceConfig || {};

    return {
      require: ['^ngModel', '^?form'],
      priority: 599,
      link: function(scope, element, attrs, ctrls) {
        if (!$window.tinymce) {
          return;
        }

        const ngModel: INgModelController = ctrls[0];
        const form: IFormController = ctrls[1] || null;

        let expression;
        const options: any = {
          debounce: true
        };
        let tinyInstance;

        const updateView = function(editor: tinymce.Editor) {
            var content = editor.getContent({format: options.format}).trim();
            content = $sce.trustAsHtml(content);

            ngModel.$setViewValue(content);
            if (!$rootScope.$$phase) {
              scope.$digest();
            }
          };

        function toggleDisable(disabled: boolean) {
          if (disabled) {
            ensureInstance();

            if (tinyInstance) {
              tinyInstance.getBody().setAttribute('contenteditable', false);
            }
          } else {
            ensureInstance();

            if (tinyInstance && !tinyInstance.settings.readonly && tinyInstance.getDoc()) {
              tinyInstance.getBody().setAttribute('contenteditable', true);
            }
          }
        }

        // fetch a unique ID from the service
        var uniqueId = uiTinymceService.getUniqueId();
        attrs.$set('id', uniqueId);

        expression = {};

        extend(expression, scope.$eval(attrs.uiTinymce));

        //Debounce update and save action
        var debouncedUpdate = (function(debouncedUpdateDelay: number) {
          var debouncedUpdateTimer;
          return function(ed) {
	        $timeout.cancel(debouncedUpdateTimer);
	         debouncedUpdateTimer = $timeout(function() {
              return (function(ed) {
                if (ed.isDirty()) {
                  ed.save();
                  updateView(ed);
                }
              })(ed);
            }, debouncedUpdateDelay);
          };
        })(400);

        var setupOptions = {
          // Update model when calling setContent
          // (such as from the source editor popup)
          setup: function(ed) {
            ed.on('init', function() {
              ngModel.$render();
              ngModel.$setPristine();
                ngModel.$setUntouched();
              if (form) {
                form.$setPristine();
              }
            });

            // Update model when:
            // - a button has been clicked [ExecCommand]
            // - the editor content has been modified [change]
            // - the node has changed [NodeChange]
            // - an object has been resized (table, image) [ObjectResized]
            ed.on('ExecCommand change NodeChange ObjectResized', function() {
              if (!options.debounce) {
                ed.save();
                updateView(ed);
              	return;
              }
              debouncedUpdate(ed);
            });

            ed.on('blur', function() {
              element[0].blur();
              ngModel.$setTouched();
              if (!$rootScope.$$phase) {
                scope.$digest();
              }
            });

            ed.on('remove', function() {
              element.remove();
            });

            if (uiTinymceConfig.setup) {
              uiTinymceConfig.setup(ed, {
                updateView: updateView
              });
            }

            if (expression.setup) {
              expression.setup(ed, {
                updateView: updateView
              });
            }
          },
          format: expression.format || 'html',
          selector: '#' + attrs.id
        };
        // extend options with initial uiTinymceConfig and
        // options from directive attribute value
        extend(options, uiTinymceConfig, expression, setupOptions);
        // Wrapped in $timeout due to $tinymce:refresh implementation, requires
        // element to be present in DOM before instantiating editor when
        // re-rendering directive
        $timeout(function() {
          tinymce.init(options);

          var maybeInitPromise = tinymce.init(options);
          if(maybeInitPromise && typeof maybeInitPromise.then === 'function') {
            maybeInitPromise.then(function() {
              toggleDisable(scope.$eval(attrs.ngDisabled));
            });
          } else {
            toggleDisable(scope.$eval(attrs.ngDisabled));
          }

        });

        ngModel.$formatters.unshift(function(modelValue) {
          return modelValue ? $sce.trustAsHtml(modelValue) : '';
        });

        ngModel.$parsers.unshift(function(viewValue) {
          return viewValue ? $sce.getTrustedHtml(viewValue) : '';
        });
        // Force an update of the view value after updating the formatters. This is required after
        // changing the ngModel require to '^ngModel' from 'ngModel', as the ngModel controller
        // could now have already run it's initial render and decide not to do so again because
        // there are no model changes.
        (function forceUpdateViewValue() {
          let value = ngModel.$modelValue;
          const formatters = ngModel.$formatters;
          let idx = formatters.length;
          while(idx--) {
            value = formatters[idx](value);
          }
          ngModel.$viewValue = value;
        }());

        ngModel.$render = function() {
          ensureInstance();

          var viewValue = ngModel.$viewValue ?
            $sce.getTrustedHtml(ngModel.$viewValue) : '';

          // instance.getDoc() check is a guard against null value
          // when destruction & recreation of instances happen
          if (tinyInstance &&
            tinyInstance.getDoc()
          ) {
            tinyInstance.setContent(viewValue);
            // Triggering change event due to TinyMCE not firing event &
            // becoming out of sync for change callbacks
            tinyInstance.fire('change');
          }
        };

        attrs.$observe('disabled', toggleDisable);

        // This block is because of TinyMCE not playing well with removal and
        // recreation of instances, requiring instances to have different
        // selectors in order to render new instances properly
        var unbindEventListener = scope.$on('$tinymce:refresh', function(e, id) {
          var eid = attrs.id;
          if (isUndefined(id) || id === eid) {
            var parentElement = element.parent();
            var clonedElement = element.clone();
            clonedElement.removeAttr('id');
            clonedElement.removeAttr('style');
            clonedElement.removeAttr('aria-hidden');
            tinyInstance.remove();
            parentElement.append($compile(clonedElement)(scope));
            unbindEventListener();
          }
        });

        scope.$on('$destroy', function() {
          ensureInstance();

          if (tinyInstance) {
            tinyInstance.remove();
            tinyInstance = null;
          }
        });

        function ensureInstance() {
          if (!tinyInstance) {
            tinyInstance = tinymce.EditorManager.get(attrs.id);
          }
        }
      }
    };
  }])
  .service('uiTinymceService',
    /** A service is used to create unique ID's, this prevents duplicate ID's if there are multiple
     * editors on screen. */
    function() {
        // return a new instance of the service
        return {
            uniqueId: 0,
            /** getUniqueId returns a unique ID */
            getUniqueId() {
                this.uniqueId++;
                return 'ui-tinymce-' + this.uniqueId;
            }
        }
    });

interface UiTinymceService {
    getUniqueId(): string;
}