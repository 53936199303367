import * as angular from "angular";
import { errorsServiceName, ErrorNotificationService } from "./errorNotificationService";
import { moduleName as sentryModuleName } from "./sentry-config";
import logDecoratorProvider from "./angularLogDecorator";
import { User } from "../../businessModels";
import { IRootScopeService } from 'angular';

export default angular.module("midas.errors", [sentryModuleName])
    .service(errorsServiceName, ErrorNotificationService)
    .config(logDecoratorProvider)
    .run(["$rootScope", errorsServiceName, ($rootScope: IRootScopeService, errors: ErrorNotificationService) => {
        $rootScope.$on("userChanged", (event, user: User) => {
            errors.registerUser(user);
        });
    }]);