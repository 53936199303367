
/** A MIDAS property definition used in DICOM Property mappings. */

import { ExamType, Physician, Technician, Modality, Site } from "../../../businessModels";
import * as textPropertyTemplateUrl from "./templates/textProperty.html";
import * as autoCompletePropertyTemplateUrl from "./templates/autoCompleteProperty.html";

export interface IEntityProperty {
  /** The display value presented to the user, IE. "Exam Type". */
  display: string;
  /** The path to the property from the server-side Study entity's context. IE. "Exam[0].StudyTypeId". */
  path: string;
  /** The Uri to the HTML template used to edit this type of property. */
  inputTemplate: string;
  /** Used to gather the display text for a given property value. IE, show "Transthoracic Echo" instead of the ID "112" */
  getDisplay(derivedValue: string): string;
}

/** A property that is represented and edited as free text. */
class TextEntityProperty implements IEntityProperty {
  constructor(readonly display: string, readonly path: string) {
  }
  readonly inputTemplate = textPropertyTemplateUrl;

  getDisplay(derivedValue: string): string {
    return derivedValue;
  }
}

/**
 * A property that has it's value selected from a pre-set list of entities.
 * The value is usually stored as an ID for a foreign key,
 * but is shown to the user with a display value.
 */
class CollectionEntityProperty implements IEntityProperty {
  constructor(
    readonly display: string,
    readonly path: string,
    readonly collection: ICollectionEntityPropertyItem[]) {
  }

  readonly inputTemplate = autoCompletePropertyTemplateUrl;

  getDisplay(derivedValue: string): string {
    if (!this.collection) {
      return derivedValue;
    }
    for (var item of this.collection) {
      if (item.id === derivedValue) {
        return item.display;
      }
    }
    return derivedValue;
  }
}

/** An entry the user can select from to represent a value for a mapped collection property. */
interface ICollectionEntityPropertyItem {
  /** The value to be set on the entity when mapped. */
  id: string;
  /** The display value of the entity to be presented to the user */
  display: string;
}

/** A function to return the preset list of mappable MIDAS properties */
export function construct(
  modules: Modality[],
  examTypes: ExamType[],
  physicians: Physician[],
  technicians: Technician[],
  sites: Site[]): IEntityProperty[] {
  return [
    new CollectionEntityProperty(
      "Module",
      "ModalityId",
      modules.map(type => {
        return {
          id: type.id.toString(),
          display: type.key
        };
      })),
    new CollectionEntityProperty(
      "Exam type",
      "Exams[0].StudyTypeId",
      examTypes.map(type => {
        return {
          id: type.id.toString(),
          display: type.key
        };
      })),
    new TextEntityProperty("Patient UR Number", "Patient.UrNumber"),
    new TextEntityProperty("Patient First Name", "Patient.Person.FirstName"),
    new TextEntityProperty("Patient Last Name", "Patient.Person.LastName"),
    new TextEntityProperty("Exam Title", "Exams[0].Title"),
    new TextEntityProperty("Exam Accession Number", "Exams[0].AccessionNumber"),
    new CollectionEntityProperty(
      "Physician",
      "PhysicianId",
      physicians.map(x => {
        return {
          id: x.id.toString(),
          display: `${x.firstName} ${x.lastName}`
        };
      })),
    new CollectionEntityProperty(
      "Sonographer",
      "TechnicianId",
      technicians.map(x => {
        return {
          id: x.id.toString(),
          display: `${x.firstName} ${x.lastName}`
        };
      })),
    new CollectionEntityProperty(
      "Site",
      "SiteId",
      sites.map(site => {
        return {
          id: site.id.toString(),
          display: site.name
        };
      }))
  ];
}