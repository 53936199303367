import { LoDashStatic } from 'lodash';
import { IScope, IControllerConstructor } from 'angular';
import { IStateService } from 'angular-ui-router';
import { Study } from "../../businessModels";
import "./notes/notes.css";

/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS205: Consider reworking code to avoid use of IIFEs
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */

export default [
  "lodash", "$scope", "$state", "study", "layoutStateService",
  <IControllerConstructor>function(_: LoDashStatic, $scope: IScope, $state: IStateService,
    study: Study, lss) {

    let exam;
    $scope.examCount = study.exams.length;

    //Pre-calculate the route if there's only 1 exam so we don't do it every digest. The study and
    //exam won't change.
    $scope.editState = $scope.examCount === 1 ?
        `.edit({studyId: ${study.id}, examId: ${study.exams[0].id}})`
      :
        "";
    $scope.study = study; 
    
    $scope.layoutState = function(baseState, editArgs = false, args) { 
      args =  args != null ? args : {};
      if (editArgs) { 
        args.studyId = study.id;
        args.examId = study.exams[0] != null ? study.exams[0].id : undefined;
        baseState = baseState + ".edit";
      }
      if (lss.current && lss.current.state) {
        args.layoutState = lss.current.state;
        args.layoutArgs = lss.current.args;
        baseState = baseState + ".layout";
      }
      const href = $state.href(baseState, args);
      return href;
    };
    
    $scope.layoutBasedOn = function(base, log = false) {
      const index = $state.current.name.indexOf(base); 
      const match = index !== -1; 
      if (log) { console.log('based?', $state.current.name, base, index, match); }
      return match;
    };
    
    const examKeys = ((() => {
      const result = [];
      for (exam of study.exams) {
        if (exam.type) {
          result.push(exam.type.key);
        }
      }
      return result;
    })());

    const examTypeHasTemplate = _.any(study.institute.diagramTemplates, x => _.contains(examKeys, x.examType != null ? x.examType.key : undefined) && (x.isDeleted !== true));
    $scope.hasDiagramTemplates = examTypeHasTemplate || _.any(study.exams, x => x.diagrams.length !== 0);
    $scope.isDiagramHref = () => $state.includes("midas.studies.view.diagrams");
    
    $scope.hasNotes = (): boolean => {
      return study.notes.length > 0;
    }    

    return $scope.getDiagramHref = function() {
      let state;
      if (!$scope.hasDiagramTemplates) { return null; }
      const needToList = false;
      const needToEdit = false;
      const needToCreate = false;
      let params = null;
      for (exam of study.exams) {
        if ((exam.diagrams.length > 1) ||           
          ((exam.diagrams.length === 1) && (params !== null)) || 
          ((exam.type != null ? exam.type.templates.length : undefined) > 1) || 
          (((exam.type != null ? exam.type.templates.length : undefined) === 1) && (params !== null))) {
          state = "midas.studies.view.diagrams";
          params = null;
          break;
        } else if (exam.diagrams.length !== 0) { 
          state = "midas.studies.view.diagrams.edit";
          params =
            {templateId: exam.diagrams[0].template.id};
        } else if (exam.type.templates.length !== 0) { 
          state = "midas.studies.view.diagrams.new";
          params =
            {templateId: exam.type.templates[0].id};
        }
      }      
      return $scope.layoutState(state, false, params);
    };
  }
];
