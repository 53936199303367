import * as angular from "angular";
import {
    SearchExecutorProvider,
    StudySearchExecutorApi,
    PatientSearchExecutorApi
} from "./searchExecutorProviders";
import {
    SearchTermProvider,
    patientTermProvider,
    physicianTermProvider,
    genericTermProvider,
    technicianTermProvider,
    moduleTermProvider,
    noteTermProvider,
    examTypeTermProvider,
    recordTypeTermProvider,
    dobTermProvider,
    studyDateTermProvider,
    statusTermProvider
} from "./searchTermProviders";
import siteTermModule from "./site-search-term-provider";
import {
    SearchTermService,
    mdsLoadSearchTerms,
    mdsSearchProvider
} from "./mdsSearchDirectives";
import mdsSearchResults from "./mdsSearchResults";
import mdsCard from "./mdsCard";
import snapModule from "angular-snap"; // For mdsSearchDirectives
import momentModule from "../../moment.module";
import breezeModule from "../../breeze.module";

export default angular.module("midas.utility.search", [
    snapModule,
    momentModule.name,
    breezeModule.name,
    mdsSearchResults.name,
    siteTermModule.name
])
    .service("searchExecutorProvider", SearchExecutorProvider)
    .service("studySearchExecutor", StudySearchExecutorApi)
    .service("patientSearchExecutor", PatientSearchExecutorApi)
    .service("searchTermProvider", SearchTermProvider)
    .service("patientTermProvider", patientTermProvider)
    .service("physicianTermProvider", physicianTermProvider)
    .service("genericTermProvider", genericTermProvider)
    .service("technicianTermProvider", technicianTermProvider)
    .service("moduleTermProvider", moduleTermProvider)
    .service("noteTermProvider", noteTermProvider)
    .service("examTypeTermProvider", examTypeTermProvider)
    .service("recordTypeTermProvider", recordTypeTermProvider)
    .service("dobTermProvider", dobTermProvider)
    .service("studyDateTermProvider", studyDateTermProvider)
    .service("statusTermProvider", statusTermProvider)
    .service("searchTermService", SearchTermService)
    .directive("mdsLoadSearchTerms", mdsLoadSearchTerms)
    .directive("mdsSearchProvider", mdsSearchProvider)
    .directive("mdsCard", mdsCard);

export {
    SearchExecutorProvider,
    StudySearchExecutorApi,
    PatientSearchExecutorApi,
    ISearchTerm,
    ISearchApi,
    SearchResultViewModel
} from "./searchExecutorProviders";

export {
    SearchTermProvider
} from "./searchTermProviders";

export {
    SearchTermService
} from "./mdsSearchDirectives";