import * as angular from "angular";
import layoutCtrl from "./layoutCtrl";
import mdsLayoutManager from "./mdsLayoutManager";
import mdsLayoutRegion from "./mdsLayoutRegion";
import layoutStateService from "./layoutStateService";
import layoutStateProvider from "./layoutStateProvider";
import mdsLayoutScope from "./mdsLayoutScope";
import mdsViewBox from "./mdsViewBox";
import { mdsLayoutTrigger, mdsGridContainer, mdsGridLayout } from "./mdsLayoutDirectives";
import mousewheelModule from "angular-mousewheel";
import animationsModule from "../animations";
import searchModule from "../search/search";
import sessionSiteModule from "../../midas/site/session-site.component";
import pushModule from '../../utility/push/push.service';

export default angular.module("midas.utility.layout", [
    mousewheelModule, // Required by mdsViewBox.
    animationsModule.name, // Required by layoutCtrl.ts
    searchModule.name,
    sessionSiteModule.name, // Required component in app.html
    pushModule.name // Required by layoutCtrl
])
    .provider("layoutState", layoutStateProvider)
    .service("layoutStateService", layoutStateService)
    .controller("layoutCtrl", layoutCtrl)
    .directive("mdsLayoutScope", mdsLayoutScope)
    .directive("mdsLayoutManager", mdsLayoutManager)
    .directive("mdsLayoutRegion", mdsLayoutRegion)
    .directive("mdsLayoutTrigger", mdsLayoutTrigger)
    .directive("mdsGridContainer", mdsGridContainer)
    .directive("mdsGridLayout", mdsGridLayout)
    .directive("mdsViewBox", mdsViewBox);