
import RenderableShape, { shadowDefaultColour, shadowDefaultExtraThickness } from "./renderableShape";
import { Shape } from "createjs";
import { Vector2, IPointLike } from "../../../utility/utils";
import { coordExtent } from "../imageEditorVars";

/** A filled arrow cap which can render itself to a canvas. */
export default class ArrowCap extends RenderableShape  {
    /** The handle to the graphical representation of this shape. */
    ink: Shape;
    /** The back of the arrow head. */
    from: Vector2;
    /** The tip of the arrow head. */
    to: Vector2;
    /** The thickness of the arrow cap at its base. */
    thickness: number;
    /** The colour to use to draw the arrow cap. */
    colour: string;
    /** The colour to use to draw the shadow. */
    shadowColour: string = shadowDefaultColour;
    /** How much thicker the shadow should be compared to the shape. */
    shadowExtraThickness: number = shadowDefaultExtraThickness * coordExtent;
  
    /** Creates a filled arrow head between 2 points, with a configurable width at the base.
     * @param ink The graphics object to draw too.
     * @param from The back of the arrow head.
     * @param to The tip of the arrow head.
     * @param thickness The width of the flared end of the arrow.
     * @param colour The colour of the arrow.
     */
    static render(ink: Shape, from: IPointLike, to: IPointLike, thickness: number, colour: string) {
      thickness = thickness > 10 ? thickness : 10;
      const lineAxis = new Vector2(to).subtract(from).normalise();
      //A normal to the main axis of the arrow head.
      const lineNormal = lineAxis.clone().swap().multiply(-1, 1);
      //One of the flared ends of the arrow.
      const a = lineNormal.clone().multiply(thickness / 2).add(from);
      //The other flared end of the arrow.
      const b = lineNormal.clone().multiply(-thickness / 2).add(from);
      ink
        .graphics
        .endStroke()
        .beginFill(colour)
        .moveTo(to.x, to.y)
        .lineTo(a.x, a.y)
        .lineTo(b.x, b.y)
        .endFill();
      return ink;
    };
  
    render(drawShadow: boolean = false, target: Shape = this.ink): Shape {
      target.graphics.clear();
      if (drawShadow) {
        const shift = this.to.clone().subtract(this.from).normalise().multiply(this.shadowExtraThickness);
        const from = this.from.clone().subtract(shift);
        const to = shift.add(this.to);
        ArrowCap.render(target, from, to, this.thickness + this.shadowExtraThickness, this.shadowColour);
      }
      return ArrowCap.render(target, this.from, this.to, this.thickness, this.colour);
    }
  
    bakeTransform(): void {
        this.$trace && this.$trace("ArrowCap.bakeTransform()");
        const m = this.ink.getMatrix(RenderableShape.ScratchMatrix);
        m.transformPoint(this.from.x, this.from.y, this.from);
        m.transformPoint(this.to.x, this.to.y, this.to);
        RenderableShape.resetInkTransform(this.ink);
    }
  }