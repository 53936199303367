import { isArray } from 'angular';

/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */

export default ["$animate", $animate =>
    ({
      restrict: "E",
      require: "^mdsLayoutManager",
      replace: false,
      template: 
        "<div ui-view='{{key}}'></div>",
      scope: { 
        key: "@"
      },
      link($scope, $element, _$attr, layoutManagerCtrl) {
    
        layoutManagerCtrl.registerRegion({
          key: $scope.key,
          addClass(classNames) { 
            if (!isArray(classNames)) { classNames = classNames.split(","); }
            for (let className of classNames) { $animate.addClass($element, className); }
          },
          removeClass(classNames) { 
            if (!isArray(classNames)) { classNames = classNames.split(","); }
            for (let className of classNames) { $animate.removeClass($element, className); }
          }
        });
      
        return $scope.$on("layoutStateChangeRequested", function(_event, layoutState) {
          //Add region as arguement if there are none present
          if (!layoutState.args) { return layoutState.args = $scope.key; }
        });
      }
    })
  
];
