import { module as ngModule } from "angular";
import { Settings } from "tinymce";
import reportEditorModule, {
    TinymceConfigurationController,
    tinymceConfigurationRequire
} from "../mds-rich-text-editor.component";
import "./fixed-toolbar-plugin.scss";

let id = 0;

/** Controller for the component which houses a fixed toolbar instead of the default floating
 * toolbar that tinymce uses. Any existing ID is used, otherwise one is created. */
class FixedToolbarPlugin extends TinymceConfigurationController {
    static $inject = ["$element"]
    constructor(private readonly $element: JQuery) {
        super();
    }
    configure(options: Settings) {
        const el = this.$element[0];
        if (!el.id) {
            el.id = `tinymce-fixed-toolbar-plugin-${id}`;
        }

        options.fixed_toolbar_container = `#${el.id}`;
    }
}

export default ngModule("midas.utility.tinymce.fixed-toolbar-plugin", [reportEditorModule.name])
.component("fixedToolbar", {
    require: tinymceConfigurationRequire,
    controller: FixedToolbarPlugin
});