import "angular-material";
import { IController, IComponentOptions, IScope, ILogService, module as ngModule, element as ngElement} from "angular";
import { BusinessModelService, MeasurementType } from "../../../businessModels";
var angular = require("angular");
var angularDragula = require('angularjs-dragula');
import "../../../../node_modules/angularjs-dragula/dist/dragula.css";

export interface IMTypeModel {
    msmType: MeasurementType;
    options: string[];
    newOption: string;
}

interface MeasurementTypeOptionsEditorController extends IController { }
class MeasurementTypeOptionsEditorController {

  selectedMeasurementType: IMTypeModel;  

  static $inject = ["$scope", "$log", "$mdToast", "$mdDialog", "businessModels", "dragulaService"];
  constructor(
    private readonly $scope: IScope,
    private readonly $log: ILogService,
    private readonly $mdToast: angular.material.IToastService,
    private readonly mdDialog: angular.material.IDialogService,
    private readonly models: BusinessModelService,
    private readonly dragulaService: any ) {}

  $onChanges(changes) {
    if (changes.selectedMeasurementType) {
      if (!changes.selectedMeasurementType.isFirstChange() && // This is to skip the form's initialisation step.
        // The dragula bag will be setup only once per modality 
        // with a selection of a first MeasurementType for this modality
        changes.selectedMeasurementType.previousValue === null) {
        // Since md-chips are not the direct children of the div annotated 
        // the dragula directive, the default dragular container needs to be 
        // placed by the container representing the md-chips wrapper.
        let msmKey = changes.selectedMeasurementType.currentValue.msmType.modalityKey;
        let bagName = '"chips-bag-' + msmKey + '"';
        let dragulaBox = document.querySelector("div[dragula='" + bagName + "']");
        var bag = this.dragulaService.find(ngElement(dragulaBox).scope(), "chips-bag-" + msmKey);
        
        if (bag) {

          var actualContainer = document.querySelector("div[dragula='" + bagName + "'] > md-chips > md-chips-wrap");
          if (actualContainer) {

            // When md-chips are set as read-only, there is a hidden span element added
            // at the beginning of the md-chips list. It interferes with dragging being 
            // an array element and needs to be removed.
            var hiddenElement = document.querySelector("div[dragula='" + bagName + "'] > md-chips > md-chips-wrap > .md-visually-hidden");
            if (hiddenElement) {
              actualContainer.removeChild(hiddenElement);
            }

            // Replace the initial container with the chips' wrapper container         
            bag.drake.containers = [];
            bag.drake.containers.push(actualContainer);
          }

          const eventName = 'chips-bag-' + msmKey + '.drop-model';
          this.$scope.$on(eventName, () => {
            if (this.selectedMeasurementType) {
              // Sync ngModel with drake model
              this.selectedMeasurementType.options = bag.drake.models[0].map(x => x);
              // Save changes to DB
              this.updateOptions();
            }
          });
        }
      }
    }
  }

  addNewOption(event: any) {
    if (event.key === "Enter") {
      if (this.selectedMeasurementType.newOption.length > 0 &&
        this.selectedMeasurementType.options.indexOf(this.selectedMeasurementType.newOption) === -1) {
        let opts = this.selectedMeasurementType.options.slice();
        opts.push(this.selectedMeasurementType.newOption);
        this.selectedMeasurementType.options = opts;
        this.selectedMeasurementType.newOption = "";
        this.updateOptions();
      }
    }
  }

  updateOptions(): void {
    this.selectedMeasurementType.msmType.dropdownOptions = this.selectedMeasurementType.options;
    this.models.save()
      .then(() => {
        this.$mdToast.showSimple("Options updated.")
      })
      .catch(e => {
        this.$log.error("Exeption: ", e);
        this.models.breeze.rejectChanges();
        this.$mdToast.showSimple("There was a problem updating the options. Please try again.")
      })
  }

  restoreDefault(measurementType: MeasurementType) {
    var confirm = this.mdDialog.confirm()
      .title('Are you sure you want to restore to default options?')
      .textContent('The options will be reset to default, you can\'t undo this action.')
      .ok('Yes')
      .cancel('No');
    this.mdDialog.show(confirm)
      .then(() => this.deleteCommitAndReload(measurementType))
      .then(() => this.$log.debug("The options for the following measurement key have been reset to default", measurementType.key))
  }

  private deleteCommitAndReload(mtype: MeasurementType): angular.IPromise<void> {
    if (mtype.deleteCustomizedMeasurementList()) {
      return this.models.save()        
        .then(() => {
          this.selectedMeasurementType = {
              msmType: mtype,
              options: mtype.dropdownOptions
          } as IMTypeModel
        })
        .then(() => this.$mdToast.showSimple("Options are restored to default"));   
    } else this.$mdToast.showSimple("There was a problem restoring the options")
  }
}

const componentOptions: IComponentOptions = {
  controller: MeasurementTypeOptionsEditorController,
  templateUrl: require("./measurement-type-options-editor.component.html"),
  bindings: {
    selectedMeasurementType: "<"
  }
};

export default ngModule("midas.admin.measurementTypeOptionsEditor", [angularDragula(angular)])
  .component("measurementTypeOptionsEditor", componentOptions);