import * as angular from "angular";
import { IStateProvider } from "angular-ui-router";

export interface IAdminSection {
    category: string;
    title: string;
    state: string;
    templateUrl?: angular.ui.IState["templateUrl"];
    template?: angular.ui.IState["template"];
    controller?: angular.ui.IState["controller"];
}

export interface IAdminService {
    getSections(): Array<IAdminSection>;
}

export class AdminProvider implements angular.IServiceProvider {
    private sections: Array<IAdminSection> = [];

    static $inject = ["$stateProvider"];
    constructor(private stateProvider: IStateProvider) { }

    $get(): IAdminService {
        return {
            getSections: () => {
                return this.sections;
            }
        };
    }

    registerSection(section: IAdminSection) {
        this.sections.push(section);
        const state = `midas.admin.${section.state}`;
        this.stateProvider.state({
            name: state,
            url: `/${section.state}`,
            views: {
                "content": {
                    templateUrl: section.templateUrl,
                    template: section.template,
                    controller: section.controller,
                    controllerAs: "$ctrl"
                }
            }
        });
        return this;
    }
}