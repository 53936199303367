import { Line } from "../shapes/shapes";
import { IShape, ImageEditorController } from "../imageEditorCore";
import ToolActivationContext from "./toolActivationContext";
import { ILineScope, ILineModel, LineTool } from "./lineTool";
import { IDirectiveFactory, IAttributes, IAugmentedJQuery } from "angular";

export interface IArrowModel extends ILineModel {
    arrowStart?: boolean;
    arrowEnd?: boolean;
}

export interface IArrowScope extends ILineScope {
    config: {
    arrowStart?: boolean;
    arrowEnd?: boolean;
    thickness?: number;
    primaryColour?: string;
    };
}

export class ArrowTool extends LineTool<IArrowModel> {

    protected $createViewModel(dataModel?: IArrowModel): Line {
        const vm = super.$createViewModel(dataModel);
        if (dataModel) {
            vm.arrowStart = dataModel.arrowStart;
            vm.arrowEnd = dataModel.arrowEnd;
        }
        return vm;
    }

    protected $updateDataModel(viewModel: Line = this.$viewModel, dataModel?: IArrowModel): IArrowModel {
        dataModel = super.$updateDataModel(viewModel, dataModel);
        dataModel.arrowStart = viewModel.arrowStart;
        dataModel.arrowEnd = viewModel.arrowEnd;
        return dataModel;
    }

    /** Returns true if the model is a line, and has either a start or end arrow. */
    isValid(dataModel: IShape): dataModel is IArrowModel {
        return dataModel && dataModel.type === this.$shapeType && (dataModel["arrowStart"] || dataModel["arrowEnd"]);
    }

    activate(context: ToolActivationContext): void {
        super.activate(context);

        const colourTemplate = `<mds-colour-select-control label="'Foreground'" tool-config="config" colour="config.primaryColour" colours="colours"></mds-colour-select-control>`;
        const coloursControl = context.controls.createHtmlControl<ILineScope>(colourTemplate);
        coloursControl.scope.config = this.$scope.config;
        coloursControl.scope.colours = this.$scope.colours;

        const arrowTemplate = `<mds-arrow-caps-control arrow-start="config.arrowStart" arrow-end="config.arrowEnd"></mds-arrow-caps-control>`;
        const arrowControl = context.controls.createHtmlControl<ILineScope>(arrowTemplate);
        arrowControl.scope.config = this.$scope.config;

        context.dispose.add(
            this.watchConfig("arrowStart", this.$pipe.setViewModel("arrowStart")),
            this.watchConfig("arrowEnd", this.$pipe.setViewModel("arrowEnd")));
    }
}

export default <IDirectiveFactory>(() => ({
    require: "^mdsImageEditor",
    restrict: "E",
    scope: {
        config: "=",
        colours: "="
    },
    link(scope: any, _element: IAugmentedJQuery, _attrs: IAttributes, ctrl: ImageEditorController) {
        ctrl.tools.registerTool("arrow", new ArrowTool("arrow", ctrl, scope));
    }
}));
