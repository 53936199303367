import { ILogService, module as ngModule } from 'angular';
import { Modality, Exam, MeasurementType } from '../../../businessModels';
import { MeasurementViewModel } from './MeasurementViewModel';

export const serviceName = "measurementContext";

type ListDict<T> = { [key: string]: T } & T[];

export type MeasurementValueDict = { [key: string]: MeasurementViewModel["value"] | boolean };
export type MeasurementTypeLookup = ListDict<MeasurementType>;
export type MeasurementLookup = ListDict<MeasurementViewModel>;
export type ReadOnlyMeasurementLookup = Readonly<ListDict<Readonly<MeasurementViewModel>>>;

/** A service which creates/updates lists and lookups of measurement view models from exam and
 * modality instances. */
export class MeasurementContextService {
    static $inject = ["$log"];
    constructor(private readonly $log: ILogService) { }

    /** Creates a new list/dictionary of measurement view models by merging the measurements from
     * the provided arguments. If an exam is provided, only it's measurement values are added;
     * measurement types from it's modality must be added separately. */
    create(...models: (Modality | Exam)[]): MeasurementLookup {
        if (models == null || models.length === 0) {
            return [] as MeasurementLookup;
        } else {
            return models.reduce(
                (measurements, model) => this.merge(measurements, model),
                <MeasurementLookup>[]);
        }
    }

    /** Merges measurement view models into the provided list/dictionary.
     * @param measurements The existing measurements object to merge into. An empty on is created if
     * this is null or undefined.
     * @param model The model to merge. If a modality, new measurement view models from measurement
     * types which aren't already in the lookup are added without values. Those with existing values
     * are not overridden. If an exam, measurement values and measurement types from the connected
     * modality (if present) are merged in. In that case any new view models will have a connection
     * to the exam so they can be saved. */
    merge(measurements: MeasurementLookup, model: Modality | Exam): MeasurementLookup {
        if (model == null) {
            this.$log.error("model is null");
            return;
        } else if (measurements == null) {
            measurements = [] as MeasurementLookup;
        }

        let exam: Exam = undefined;
        if (model instanceof Exam) {
            if (model.type == null) {
                this.$log.error(`Exam.type must be assigned in `, model);
            } else if (model.type.modality == null) {
                this.$log.error(`Exam.type.modality must be assigned in `, model);
            } else {
                const modalityKey = model.type.modality.key;

                for (let value of model.getMeasurements()) {
                    const type = value.type;

                    // prevent issues where measurement types of other modalities can sneak in -
                    // e.g. via MIDAS.Normandy.Core.Jobs.Dicom.CalculatedMeasurements.CalculateAge.
                    // We should really just fix this properly.
                    if (type == null) {
                        this.$log.error(`Exam ${model.id} of modality ${modalityKey} has measurement value without measurement type information. It is probably from an unrelated modality.`);
                        continue;
                    } else if (type.modalityKey !== modalityKey) {
                        this.$log.error(`Exam ${model.id} of modality ${modalityKey} has measurement ${type.key} from an unrelated modality named ${type.modalityKey}`);
                        continue;
                    }

                    let vm = measurements[type.key];
                    if (vm) {
                        if (vm.type !== type) {
                            this.$log.error("Multiple MeasurementTypes with identical key: " + type.key, type, vm.type);
                        } else {
                            if (vm.original != null && vm.original !== value) {
                                this.$log.warn(`Existing MeasurementValue for ${type.key} will be overridden (existing, new)`, vm.original, value);
                            }
                            const wasDirty = vm.isDirty;
                            vm.original = value;
                            // Keep any existing changes, otherwise match new backing value.
                            if (!wasDirty) {
                                vm.revertChanges();
                            }
                        }
                    } else {
                        vm = MeasurementViewModel.createExisting(value);
                        measurements[type.key] = vm;
                        measurements.push(vm);
                    }
                }
            }

            if (model.study) {
                exam =  model;
                model = model.study.modality;
            }
        }

        if (model instanceof Modality) {
            for (let type of model.getMeasurementTypes()) {
                if (measurements.hasOwnProperty(type.key)) {
                    const vm = measurements[type.key];
                    if (vm.type !== type) {
                        this.$log.error("Multiple MeasurementTypes with identical key: " + type.key, type, vm.type);
                    }
                } else {
                    const vm = MeasurementViewModel.createNew(type, exam);
                    measurements[type.key] = vm;
                    measurements.push(vm);
                }
            }
        } else {
            this.$log.error(`model must be an instance of Exam or Modality, but is `, model);
        }

        return measurements;
    }

    /** Creates a new dictionary of measurement key -> measurement value from a merged measurement
     * lookup. */
    getValueLookup(measurements: MeasurementLookup): MeasurementValueDict {
        const measurementsValues = <MeasurementValueDict>{ };
        for (var mvm of measurements) {
            if (!mvm.isNew) { // We're only interested in measurements with values.
                measurementsValues[mvm.key] = mvm.value;
            }
        }
        return measurementsValues;
    }
}

export default ngModule("midas.measurements.measurementContext.service", [])
    .service(serviceName, MeasurementContextService);
