import { ITool } from "./tool";
import { Traceable, Events, ObservableCallback, IDisposable, IPointLike, Vector2, createDisposable } from "../../../utility/utils";
import ToolActivationContext from "./toolActivationContext";
import { IShape, ImageEditorController } from "../imageEditorCore";

/** Events emitted by the ToolManager class. */
export type ToolEvents = "activated" | "deactivated" | "registered" | "removed";

/** Manages the set of tools for the ImageEditor directive, providing various operations and events,
 * including managing the lifetime of the current ToolActivationContext. These tools use cm as their
 * coordinate system, meaning they need to convert to stage coordinates when creating shapes. */
export default class ToolManager extends Traceable {
  private $events = new Events();
  /** The currently active tool activation context. */
  private $activationContext: ToolActivationContext;
  /** The currently registered tools. */
  registered: { [toolId: string]: ITool } = {};
  /** Gets the currently active tool. */
  activeTool: ITool;

  /** Observe tool events. */
  observe(event: ToolEvents, callback: ObservableCallback<ITool>): IDisposable {
    return this.$events.observe(event, callback);
  }

  /** Finds the first tool which indicates it can edit the provided model, or undefined.
   * @param model The model to edit.
   */
  findToolFor(model: IShape): ITool {
    for (let toolName in this.registered) if (this.registered.hasOwnProperty(toolName)) {
      const tool = this.registered[toolName];
      if (this.safeIsValid(tool, model)) {
        return tool;
      }
    }
    return undefined;
  }

  activateForShape(editModel: IShape) {
    const tool = this.findToolFor(editModel);
    //If the tool or the model aren't active then start editing them.
    if ((tool && tool !== this.activeTool) || this.$activationContext && !this.$activationContext.isEditing(editModel)) {
      this.deactivateActiveTool();
      this.safeActivateTool(tool, editModel);
    }
  }

  /** Activates a tool by id or reference, deactivating the currently active tool.
   * @param tool The tool to activate.
   */
  activate(tool: string | ITool): void {
    const newActiveTool = typeof tool === "string" ? this.registered[tool] : tool;
    if (newActiveTool !== this.activeTool) {
      this.deactivateActiveTool();
      this.safeActivateTool(newActiveTool, null);
    }
  }

  /** Deactivates the current tool, and reactivates it with a fresh activation context. Useful
   * to cancel whatever's currently going on with a tool and start fresh. */
  reactivate(): void {
    const tool = this.activeTool;
    if (tool) {
      this.deactivateActiveTool();
      this.safeActivateTool(tool, null);
    }
  }

  /** Registers a tool object with a dictionary of the events it wishes to receive.
   * @param toolId The id of the tool to register.
   * @param toolInfo
   */
  registerTool(toolId: string, toolInfo: ITool): ITool {
    if (toolId == null) {
      throw new Error("tool being registered must have an id");
    }
    if (this.registered[toolId] != null) {
      throw new Error(`a tool is already registered under the id '${toolId}'`);
    }
    if (toolInfo.id != null && toolInfo.id !== toolId) {
      throw new Error(`if toolInfo.id (${toolInfo.id}) is defined then it must equal toolId (${toolId})`);
    }
    toolInfo.id = toolId;
    this.registered[toolId] = toolInfo;
    this.$events.notify("registered", toolInfo);
    return toolInfo;
  }

  /** Removes a tool by ID or reference.
   * @param tool The tool to remove from the tool list.
   * @returns The tool reference.
   */
  removeTool(tool: string | ITool): ITool {
    const toolInfo = typeof tool === "string" ? this.registered[tool] : tool;
    if (toolInfo != null) {
      if (this.activeTool === toolInfo) {
        this.deactivateActiveTool();
      }
      delete this.registered[toolInfo.id];
      this.$events.notify("removed", toolInfo);
    }
    return toolInfo;
  }

  /** Returns the result of calling tool.hitTest(point) on the first tool which returns true for
    * isValid(model).
    * @param model The model to hit test against.
    * @param point The point to check.
    */
  hitTest(model: IShape, point: IPointLike): boolean {
    const tool = this.findToolFor(model);
    if (!tool) {
      this.$error && this.$error("couldn't find tool for model", model);
    }
    return this.safeHitTest(tool, model, point);
  }

  /** Gets whether the provided model is currently being edited by a tool. */
  isEditing(model: IShape) {
    return this.$activationContext && this.$activationContext.isEditing(model);
  }

  safeActivateTool(tool: ITool, editModel: IShape): void {
    if (tool) {
      const ctrl = this.$ctrl;
      if (typeof tool.activate === "function") {
        const locals = {
          editModel: editModel != null && this.safeIsValid(tool, editModel) ? editModel : null
        };
        const ac = this.$activationContext = ctrl.$injectorInstantiate(ToolActivationContext, undefined, locals);
        if (ac.isEditing(editModel)) {
          this.$ctrl.history.rebuildCache();
          ac.dispose.add(() => ctrl.history.rebuildCache());
        }
        try {
          tool.activate(ac);
        } catch (ex) {
          this.$error && this.$error("Exception in tool.activate", ex);
          this.$activationContext = null;
          ac.dispose();
          throw ex;
        }
      }
      ctrl.context.update();
      this.activeTool = tool;
      this.$events.notify("activated", tool);
    }
  }

  deactivateActiveTool(): void {
    const tool = this.activeTool;
    if (tool) {
      this.activeTool = null;
      const activationContext = this.$activationContext;
      if (activationContext) {
        this.$activationContext = null;
        activationContext.dispose();
      }
      this.$ctrl.context.update();
      this.$events.notify("deactivated", tool);
    }
  }

  safeIsValid(tool: ITool, model: IShape): boolean {
    if (tool && model && typeof tool.isValid === "function") {
      try {
        return tool.isValid(model);
      } catch (ex) {
        this.$error && this.$error("Exception in tool.isValid", ex);
      }
    }
    return false;
  }

  safeHitTest(tool: ITool, model: IShape, p: IPointLike): boolean {
    if (tool && model && typeof tool.hitTest === "function") {
      try {
        return tool.hitTest(model, p);
      } catch (ex) {
        this.$error && this.$error("Exception in tool.hitTest", ex);
      }
    }
    return false;
  }

  safeRender(tool: ITool, model: IShape): createjs.DisplayObject | createjs.DisplayObject[] {
    if (tool && model && typeof tool.render === "function") {
      try {
        return tool.render(model);
      } catch (ex) {
        this.$error && this.$error("Exception in tool.render", ex);
      }
    }
    return null;
  }

  /** Overrides Traceable.setTracing() to also allow enabling or disable event tracing for this
   * class. */
  setTracing(options: Traceable.Options & { "toolManager.traceEvents"?: boolean }): void {
    super.setTracing(options);
    if ("toolManager.traceEvents" in options) {
      this.traceEvents(options["toolManager.traceEvents"]);
    }
  }

  /** Enables or disables event tracing for this class. When enabled, all events will be printed to
    * this class's $trace function. */
  traceEvents(enable: boolean, selectTraceArgs?: (event: string, args: any[]) => any[]) {
    const registrationName = "$traceObservers";
    if (typeof selectTraceArgs !== "function") {
      selectTraceArgs = (event, args) => ["ImageEditor.tools", event,
        ...(args.map(arg =>
          arg instanceof Vector2
            ? (<Vector2>arg).toString(3)
            : arg))];
    }
    if (enable) {
      if (this[registrationName] == null) {
        const events: ToolEvents[] = ["activated", "deactivated", "registered", "removed"];
        this[registrationName] = createDisposable(
          events.map(event => this.observe(event, (...args) => {
            this.$trace && this.$trace.apply(this, selectTraceArgs(event, args));
          })));
      }
    } else {
      if (typeof this[registrationName] === "function") {
        this[registrationName]();
        this[registrationName] = undefined;
      }
    }
  }

  static $inject = ["imageEditor"];
  constructor(
    private $ctrl: ImageEditorController) {
    super();
  }
}