import * as angular from "angular";
import { ExamType } from "../../../businessModels";

export interface IGlossaryRestrictPromptResult {
    roleVisibility: GlossaryRoleVisibility;
    examSelections: ExamSelections;
}

export class ExamSelections {
    constructor(examTypes: Array<ExamType>, selected?: Array<ExamType> | ReadonlyArray<ExamType>) {
        const existingKeys = selected === null ? [] : (selected as Array<ExamType>).map(x => x.key);
        this.selections = [];
        for (var et of examTypes) {
            this.selections.push({
                examType: et,
                selected: existingKeys.indexOf(et.key) !== -1
            });
        }
        const state = this.getSelectionState();
        this.selectAllOverride = state !== SelectionState.Indeterminate;
    }

    available: Array<ExamType>;
    selections: Array<IExamTypeSelection>;
    selectAllOverride: boolean;

    isAll(): boolean {
        return this.getSelectionState() === SelectionState.All;
    }

    isIndeterminate(): boolean {
        return this.getSelectionState() === SelectionState.Indeterminate;
    }

    toggleAll() {
        const target = !this.isAll()
        for (var selection of this.selections) {
            selection.selected = target;
        }
    }

    getSelectionState(): SelectionState {
        const firstSelection = this.selections[0].selected;
        const indeterminate = !this.selections.every(x => x.selected === firstSelection);
        if (indeterminate)
            return SelectionState.Indeterminate;

        return firstSelection ? SelectionState.All : SelectionState.None;
    }
}

export enum SelectionState {
    All,
    None,
    Indeterminate
}

export interface IExamTypeSelection {
    examType: ExamType;
    selected: boolean;
}

export enum GlossaryRoleVisibility {
    All = 0,
    Sonographer = 1,
    Physician = 2
}

interface IGlossaryRestrictPromptDialogLocals {
    examSelections: ExamSelections,
    roleVisibility: GlossaryRoleVisibility
}

interface Controller extends angular.IController { }
class Controller implements angular.IController, IGlossaryRestrictPromptDialogLocals {
    examSelections: ExamSelections;
    roleVisibility: GlossaryRoleVisibility;

    static $inject = ["$mdDialog"];
    constructor(private readonly mdDialog: angular.material.IDialogService) {
    }

    accept() {
        this.mdDialog.hide(this.createResult());
    }

    cancel() {
        this.mdDialog.cancel();
    }

    private createResult(): IGlossaryRestrictPromptResult {
        return {
            examSelections: this.examSelections,
            roleVisibility: this.roleVisibility
        };
    }
}

export class GlossaryRestrictPromptDialogOptions implements angular.material.IDialogOptions {
    constructor(selections: ExamSelections, roleVisibility: GlossaryRoleVisibility) {
        this.locals = {
            examSelections: selections,
            roleVisibility: roleVisibility
        };
    }

    locals: IGlossaryRestrictPromptDialogLocals;

    openFrom = ".glossary-category-restrict";
    controller = Controller;
    controllerAs = "$ctrl";
    bindToController = true;
    templateUrl = require('./glossaryRestrictPrompt.html');
}