import { MeasurementController, IMeasurementScope } from "../MeasurementController";
import * as angular from "angular";
class ThighController extends MeasurementController {
  static $inject = ["$scope"];
  constructor(scope: IMeasurementScope) {
    super(scope);
  }

  clickRightOK() {
    this.setMeasurementValue("MSKThighRtAnteriorNormal", "ALL NORMAL");
    this.setMeasurementValue("MSKThighRtPosteriorNormal", "ALL NORMAL");
    this.setMeasurementValue("MSKThighRtMedialNormal", "ALL NORMAL");
    this.setMeasurementValue("MSKThighRtGlutealNormal", "ALL NORMAL");
    this.setMeasurementValue("MSKThighRtNervesNormal", "ALL NORMAL");
    this.setMeasurementValue("MSKThighRtVasculatureNormal", "ALL NORMAL");
    console.log("Right All normal clicked")
  }
  clickLeftOK(){
    this.setMeasurementValue("MSKThighLtAnteriorNormal", "ALL NORMAL");
    this.setMeasurementValue("MSKThighLtPosteriorNormal", "ALL NORMAL");
    this.setMeasurementValue("MSKThighLtMedialNormal", "ALL NORMAL");
    this.setMeasurementValue("MSKThighLtGlutealNormal", "ALL NORMAL");
    this.setMeasurementValue("MSKThighLtNervesNormal", "ALL NORMAL");
    this.setMeasurementValue("MSKThighLtVasculatureNormal", "ALL NORMAL");
    console.log("Right All normal clicked")
  }
}

angular.module("midas.utility.measurement.views").controller("ThighCtrl", ThighController);