/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import * as angular from "angular";
angular.module("midas.utility.measurement.views").controller("NSWCTOECtrl", ["$scope", function($scope) {
 const { values } = $scope.measurements;
 const setupDefaultEntries = function() {
   values.ECTOELVComment.value = "Normal wall thickness, cavity size and systolic function";
   values.ECTOERVComment.value = "Normal size and contractility";
   values.ECTOELAComment.value = "Normal size. No thrombus detected";
   values.ECTOELAAppendageComment.value = "No thrombus detected. Normal Doppler flows";
   values.ECTOERAComment.value = "Normal size. No thrombus detected";
   values.ECTOEIASComment.value = "Intact without obvious shunt";
   values.ECTOEPericardiumComment.value = "Not thickened";
   values.ECTOEAorticRootComment.value = "Normal size aortic root";
   values.ECTOEAortaComment.value = "Normal aortic root and ascending aorta dimension";
   values.ECTOEAVComment.value = "Trileaflet. Opens well. No regurgitation. No evidence of vegetations";
   values.ECTOEMVComment.value = "Morphologically normal. No mitral valve prolapse. No evidence of vegetations";
   values.ECTOETVComment.value = "Morphologically normal. Trivial tricuspid regurgitation. No valve prolapse. No evidence of vegetations";
   values.ECTOEPVComment.value = "Morphologically normal. Trivial pulmonary regurgitation. No evidence of vegetations. No pulmonary stenosis";
   values.ECTOEConclusion1.value = "Normal biventricular function";
   values.ECTOEConclusion2.value = "Normal atrial size";
   values.ECTOEConclusion3.value = "Normal valvular structure and function";
 };
 $scope.clickMeToo = () => setupDefaultEntries();
 if (!values.ECDEMStudyType.value) { 
   values.ECDEMStudyType.value = "Transoesophageal Echo";
 }
}
]);
