/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import * as angular from "angular";
angular.module("midas.utility.measurement.views").controller("SANTOECtrl", ["$scope", function($scope) {
 const { values } = $scope.measurements;
 const setupDefaultEntries = function() {
   values.ECTOE2LVComment.value = "Normal LV wall thickness, cavity size and systolic function";
   values.ECTOE2RVComment.value = "Normal right ventricle size and contractility";
   values.ECTOE2LAComment.value = "Normal left atrium size. No thrombus detected";
   values.ECTOE2LAAppendageComment.value = "No thrombus detected. Normal Doppler flows";
   values.ECTOE2RAComment.value = "Normal right atrium size. No thrombus detected";
   values.ECTOE2IASComment.value = "Intact interatrial septum without obvious shunt";
   values.ECTOE2PericardiumComment.value = "The pericardium is not thickened";
   values.ECTOE2AortaComment.value = "Normal aortic root and ascending aorta dimension";
   values.ECTOE2AVComment.value = "Trileaflet aortic valve. Opens well. No regurgitation. No evidence of vegetations";
   values.ECTOE2MVComment.value = "Morphologically normal mitral valve. No mitral valve prolapse. No evidence of vegetations";
   values.ECTOE2TVComment.value = "Morphologically normal tricuspid valve. Trivial tricuspid regurgitation. No valve prolapse. No evidence of vegetations";
   values.ECTOE2PVComment.value = "Morphologically normal pulmonary valve. Trivial pulmonary regurgitation. No evidence of vegetations. No pulmonary stenosis";
   values.ECTOEConclusion1.value = "Normal biventricular function";
   values.ECTOEConclusion2.value = "Normal atrial size";
   values.ECTOEConclusion3.value = "Normal valvular structure and function";
 };
 $scope.clickMeToo = () => setupDefaultEntries();
 if (!values.ECDEMStudyType.value) { 
   values.ECDEMStudyType.value = "Transoesophageal Echo";
 }
}
]);
