/*
  These export provide view models used across the DICOM Property Mapping module.
*/

import { IEntityProperty } from "./studyProperties";
import { IDicomTag, dicomTags } from "../../../utility/dicomTags";
import { StudyPropertyDerivedFromDicom } from "../../../businessModels";

/** A representation of the DICOM property mapping.  */
export interface IMappingViewModel {
  /** The DICOM tag to match against. */
  dicomTag: IDicomTagViewModel;
  /** The DICOM value the tag must contain to successfully match. */
  dicomValue: string;
  /** The MIDAS property definition that will be set if successfully matched. */
  studyProperty: IEntityProperty;
  /** The value the MIDAS property will set if successfully matched. */
  studyValue: string;
  /** The business entity for this mapping. */
  entity: StudyPropertyDerivedFromDicom;
  /** If the mapping is currently open for editing on the User Interface */
  selected?: boolean;
}

/** A representation of the DICOM tag used in property mapping. */
export interface IDicomTagViewModel {
  /** The user display value */
  display: string;
  /** The group of the DICOM tag, represented as the first 4 digits of the code. */
  group: string;
  /** The specific element of the DICOM tag, represented as the last 4 digits of the code.  */
  element: string;
}

/** Produces DICOM tag view models from the predefined list of DICOM tags. */
export abstract class DicomViewModelFactory {
  private static viewModels: IDicomTagViewModel[];

  static getViewModels(): IDicomTagViewModel[] {
    if (!this.viewModels) {
      this.viewModels = this.produceViewModels();
    }
    return this.viewModels;
  }

  private static produceViewModels(): IDicomTagViewModel[] {
    let result: IDicomTagViewModel[] = [];
    for (var key in dicomTags) {
      if (dicomTags.hasOwnProperty(key)) {
        const element: IDicomTag = dicomTags[key];
        const groupStr: string = key.substring(0, 4);
        const elementStr: string = key.substr(4);
        result.push({
          display: element.name,
          group: groupStr,
          element: elementStr
        });
      }
    }
    result = result.sort(this.sortDicomTagByDisplayName);
    return result;
  }

  /** Sorts DICOM tags by display name. */
  private static sortDicomTagByDisplayName = (a: IDicomTagViewModel, b: IDicomTagViewModel): number => {
    const displayA: string = a.display.toUpperCase();
    const displayB: string = b.display.toUpperCase();
    if (displayA < displayB) {
      return -1;
    }
    if (displayA > displayB) {
      return 1;
    }
    return 0;
  }
}