import * as Shapes from "./shapes/shapes";
import * as angular from "angular";
import { coordExtent } from "./imageEditorVars";
import { DisplayContext } from "./imageEditorCore";
import * as cjs from "createjs";
import { Grip } from "./grip";
import {
  createDisposable,
  Vector2,
  Events,
  IPointLike,
  Disposable,
  IMaybeTraceable,
  traceIgnore,
  ObservableCallback,
  IDisposable,
  NestedArray,
  focus,
  blur,
  Traceable
} from "../../utility/utils";

/** A type which the image editor can use as a control. */
export interface IControl extends IMaybeTraceable {
  /** Checks hit detection against this control.
   * @param p The point to hit test against this control.
   */
  hitTest(p: IPointLike): boolean;

  /** The visible part of the control, which is rendered to the stage. Can be null if the
   * control has no visual component. */
  ink: cjs.DisplayObject;

  /** Signals that the user is beginning to interact with the control.
   * @param p The normalised user point at which the user began interacting.
   */
  interactStart?(p: IPointLike): void;
  /** Signals that the user is interacting with the control, after interactStart().
   * @param p The normalised user point at which the user is interacting.
   */
  interact?(p: IPointLike): void;
  /** Signals that the user has finished interacting with the control.
   */
  interactEnd?(): void;
  /** Signals that the user has cancelled an interaction with the control.
   */
  interactCancel?(): void;
  /** Allows the control to perform any cleanup required when it is destroyed. */
  dispose: Disposable;
}

/** A control which provides drag operations relative to the drag start point. The operation
 * uses control relative coordinates, and the control position is updated as it is interacted
 * with. This control is best for creating controls for points that you want the user to be able
 * to drag around. */
export class GripControl implements IControl {
  static trace = traceIgnore;

  /** A container which can be used to normalise the size of the grip, to undo user layer
    * zooming. */
  private $invertContainer: cjs.Container = null;
  /** Gets whether this control is in the middle of an interaction. */
  isInteracting: boolean = false;
  /** Gets the display object which draws this control if one exists, otherwise null. */
  get ink() { return this.$invertContainer; }
  /** Called when this control is removed from the stage and destroyed */
  dispose = createDisposable();

  /** Sets the current display position of the control. Must not be called while the control is
   * being interacted with by the user.
   * @param p The point to set the position to.
   * @param simulateInteraction Whether to set the position by simulating an interaction, which
   * will case any bound points and callbacks to be updated with the new position too.
  */
  setPosition(p: IPointLike, simulateInteraction: boolean = false) {
    if (this.isInteracting) {
      throw Error("Can't call GripControl.setPosition() while the control is being interacted with");
    }
    GripControl.trace && GripControl.trace(`GripControl.setPosition({ ${p.x.toPrecision(3)}, ${p.y.toPrecision(3)} }, ${simulateInteraction})`);
    if (simulateInteraction) {
      this.interactStart(this.grip.lastDrag);
      this.interact(p);
      this.interactEnd();
    } else {
      if (this.$invertContainer) {
        this.$invertContainer.x = p.x;
        this.$invertContainer.y = p.y;
      }
      this.grip.lastDrag.set(p);
      this.grip.grabStart.set(p);
    }
  }

  interactStart(p: IPointLike): void {
    if (this.isInteracting) {
      throw Error("Can't call GripControl.interactStart() while the control is being interacted with");
    }
    GripControl.trace && GripControl.trace(`GripControl.interactStart({ ${p.x.toPrecision(3)}, ${p.y.toPrecision(3)} })`);
    try {
      this.grip.grab(p);
    } finally {
      this.isInteracting = true;
    }
  }

  interact(p: IPointLike): void {
    if (!this.isInteracting) {
      throw Error("Can't call GripControl.interact() unless the control is being interacted with");
    }
    GripControl.trace && GripControl.trace(`GripControl.interact({ ${p.x.toPrecision(3)}, ${p.y.toPrecision(3)} })`);
    this.grip.drag(p);
  }

  interactCancel(): void {
    if (!this.isInteracting) {
      throw Error("Can't call GripControl.interactCancel() unless the control is being interacted with");
    }
    GripControl.trace && GripControl.trace(`GripControl.interactCancel()`);
    try {
      this.grip.revert();
    } finally {
      this.isInteracting = false;
    }
  }

  interactEnd(): void {
    if (!this.isInteracting) {
      throw Error("Can't call GripControl.interactEnd() unless the control is being interacted with");
    }
    GripControl.trace && GripControl.trace(`GripControl.interactEnd()`);
    try {
      this.grip.grabStart.set(this.grip.lastDrag);
    } finally {
      this.isInteracting = false;
    }
  }

  /** Checks for hit test against the ink, or if there is no ink succeeds anyway. */
  hitTest(p: IPointLike): boolean {
    GripControl.trace && GripControl.trace(`GripControl.hitTest({ ${p.x.toPrecision(3)}, ${p.y.toPrecision(3)} }) with${this.$overlay ? "" : "out"} overlay`);
    const inverter = this.$invertContainer;
    if (inverter != null && inverter.parent == null) { //Removed from stage.
      return false;
    }
    if (this.$overlay != null) {
      const localP = inverter.parent.localToLocal(p.x, p.y, inverter);
      return this.$overlay.hitTest(localP);
    }
    return true;
  }

  /** Bind a point to this grip. It will be translated relative to this grip as the grip is
   * dragged. All bound points are updated before bound callbacks are invoked.
   * @param p The point object to bind to this grip.
   * @returns A function for removing this binding from the grip.
   */
  bind(p: IPointLike): Disposable;
  /** Bind a callback to this grip, so you can be notified of the relative position from the
   * drag start as it is dragged.
   * @param callback A function which gets the difference the grip has moved since the last
   * callback.
   * @returns A function for removing this binding from the grip.
   */
  bind(callback: (diff: IPointLike) => void): Disposable;
  /** Bind any number of points or callbacks to this grip.
   * @param bindings One or more functions or points to bind to this grip control.
   * @returns A function for removing these bindings from the grip.
   */
  bind(...bindings: Grip.ManyBindables): Disposable;
  bind(...bindings: Grip.ManyBindables): Disposable {
    return this.dispose.add(this.grip.bind(...bindings));
  }

  constructor(private grip: Grip, private $overlay?: Shapes.RenderableShape, initialDisplayPos?: IPointLike) {
    if ($overlay) {
      this.$invertContainer = new cjs.Container();
      this.$invertContainer.addChild($overlay.render());
      this.grip.bind(this.$invertContainer);
      if (initialDisplayPos) {
        this.$invertContainer.x = initialDisplayPos.x;
        this.$invertContainer.y = initialDisplayPos.y;
      }
    }
    if (initialDisplayPos) {
      this.grip.lastDrag.set(initialDisplayPos);
      this.grip.grabStart.set(initialDisplayPos);
    }
  }
}

/** A control which provides interaction operations within some visual area. The operation uses
 * normalised user coordinates, not control relative coordinates, and the control itself isn't
 * automatically moved with the interaction. This control is good for implementing new draw
 * behaviour. */
export class InteractControl implements IControl {
  /** A container which can be used to normalise the size of the grip, to undo user layer
    * zooming. */
  private $invertContainer: cjs.Container = null;
  /** Event listener subscriptions. */
  private $events: Events;
  /** Gets whether this control is in the middle of an interaction. */
  isInteracting: boolean = false;
  /** Gets the display object which draws this control if one exists, otherwise null. */
  get ink() { return this.$invertContainer; }
  /** Called when this control is removed from the stage and destroyed */
  dispose = createDisposable();
  private $startInteract = new Vector2();
  private $lastInteract = new Vector2();

  /** Sets the current display position of the control. Must not be called while the control is
   * being interacted with by the user.
   * @param p The point to set the display position to. */
  setPosition(p: IPointLike) {
    if (this.isInteracting) {
      throw Error("Can't call InteractControl.setPosition() while the control is being interacted with");
    }
    if (this.$invertContainer) {
      this.$invertContainer.x = p.x;
      this.$invertContainer.y = p.y;
    }
  }

  interactStart(p: IPointLike): void {
    if (this.isInteracting) {
      throw Error("Can't call InteractControl.interactStart() while the control is being interacted with");
    }
    try {
      this.$startInteract.set(p);
      this.$events.notify("start", p);
    } finally {
      this.isInteracting = true;
    }
  }

  interact(p: IPointLike): void {
    if (!this.isInteracting) {
      throw Error("Can't call InteractControl.interact() unless the control is being interacted with");
    }
    this.$lastInteract.set(p);
    this.$events.notify("interact", p);
  }

  interactCancel(): void {
    if (!this.isInteracting) {
      throw Error("Can't call InteractControl.interactCancel() unless the control is being interacted with");
    }
    try {
      this.$lastInteract.set(this.$startInteract);
      this.$events.notify("cancel", this.$startInteract);
    } finally {
      this.isInteracting = false;
    }
  }

  interactEnd(): void {
    if (!this.isInteracting) {
      throw Error("Can't call InteractControl.interactEnd() unless the control is being interacted with");
    }
    try {
      this.$startInteract.set(this.$lastInteract);
      this.$events.notify("end", this.$lastInteract);
    } finally {
      this.isInteracting = false;
    }
  }

  /** Checks for hit test against the ink, or if there is no ink succeeds anyway. */
  hitTest(p: IPointLike) {
    const inverter = this.$invertContainer;
    if (inverter != null && inverter.parent == null) { //Removed from stage.
      return false;
    }
    if (this.$overlay != null) {
      const localP = inverter.parent.localToLocal(p.x, p.y, inverter);
      return this.$overlay.hitTest(localP);
    }
    return true;
  }

  /** Register for a number of observables in one subscription.
   * @param start Function which is called when an interaction begins on this control, passed
   * the normalised coordinates of the interaction.
   * @param interact Function which is called after start, when an interaction continues on
   * this control (the control is dragged), passed the normalised coordinates of the interaction.
   * @param end Function which is called when an interaction ends on this control, passed
   * the normalised coordinates of the final interaction (when the user stopped dragging).
   * @param cancel Function which is called when an interaction is cancelled on this control, passed
   * the normalised coordinates of the start of the interaction.
   */
  subscribe(start?: ObservableCallback<IPointLike>, interact?: ObservableCallback<IPointLike>, end?: ObservableCallback<IPointLike>, cancel?: ObservableCallback<IPointLike>): Disposable {
    const registration = createDisposable();
    if (start) { registration.add(this.$events.observe("start", start)); }
    if (interact) { registration.add(this.$events.observe("interact", interact)); }
    if (end) { registration.add(this.$events.observe("end", end)); }
    if (cancel) { registration.add(this.$events.observe("cancel", cancel)); }
    this.dispose.add(registration);
    return registration;
  }

  constructor(private $overlay?: Shapes.RenderableShape, initialDisplayPos?: IPointLike) {
    this.$events = new Events();
    if ($overlay) {
      this.$invertContainer = new cjs.Container();
      this.$invertContainer.addChild($overlay.render());
      if (initialDisplayPos) {
        this.$invertContainer.x = initialDisplayPos.x;
        this.$invertContainer.y = initialDisplayPos.y;
      }
    }
  }
}

/** A control which allows HTML to be rendered, either in the DOM or as part of the stage.
 * It can be used as is, but is generally specialised by subclassing. */
export class HtmlControl<TScope extends angular.IScope> implements IControl {
  /** The scope this control is linked against. Only set once compiled. */
  scope: TScope;
  /** The HTML element which this control manages. Only set once compiled. */
  element: HTMLElement;

  /** A container which can be used to normalise the size of the control, to undo user layer
   * zooming. */
  private $invertContainer: cjs.Container = null;

  /** If set, this is the container for the element on the stage. */
  get ink() { return this.$invertContainer; }
  protected $events: Events;
  /** Called when this control is removed from the stage and destroyed */
  dispose = createDisposable();

  /** Always returns false as this control doesn't interact with the stage. */
  hitTest(_: IPointLike) { return false; }

  observe(eventName: string, callback: ObservableCallback<any>): IDisposable {
    return this.$events.observe(eventName, callback);
  }

  notify(eventName: string, value: any): void {
    this.$events.notify(eventName, value);
  }

  /** Compiles some HTML and links it to the scope of this control. Does not add the HTML to the
   * DOM.
   * @param template The HTML to compile. */
  compile(template: string | HTMLElement | JQuery, scope: TScope) {
    if (this.element != null) {
      throw Error("Control has already been compiled");
    }
    this.element = this.$compile(<any>template)(scope)[0];
    this.scope = scope;
  }

  /** Appends the compiled HTML in this.element to the provided element.
   * @param appendToElement The HTML element to append to, or a selector to get one.
   */
  appendToElement(appendToElement: string | HTMLElement | JQuery = "#diagramToolbox") {
    const parent = angular.element(appendToElement);
    if (parent == null || parent.length === 0) {
      throw Error("No parent element found to append to");
    }
    if (this.element.parentElement != null) {
      this.element.remove();
    }
    parent[0].appendChild(this.element);
    this.dispose.add(() => this.element.remove());
  }

  /** Creates (if it's not already created) and positions a cjs DOM element on the canvas.
   * @param p The position of the DOM element. It will contain the compiled HTML in this.element.
   */
  toCanvasElement(p: IPointLike, scale: number = 1 / coordExtent): cjs.Container {
    if (this.$invertContainer == null) {
      this.$invertContainer = new cjs.Container();
      const dom = new cjs.DOMElement(this.element);
      dom.name = "DOM element";
      this.$invertContainer.addChild(dom);
    }
    this.$invertContainer.x = p.x;
    this.$invertContainer.y = p.y;
    const dom = this.$invertContainer.getChildAt(0);
    //TODO: This isn't right. It's arbitrarily scaled right now, not normalised.
    dom.scaleX = dom.scaleY = scale;
    return this.$invertContainer;
  }

  /** Creates a new HTML control. */
  constructor(private readonly $compile: angular.ICompileService) {
    this.$events = new Events();
    this.dispose.add(() => this.$events.clear());
  }
}

interface YesNoControlOptions {
  /** Receives click events from the control. By default this sends standard control events, so
   * if you change this, those events will no longer be sent. */
  click?(): void;
  /** Whether to display this part of the control as disabled. */
  disable?: boolean;
  /** Whether to hide this part of the control. */
  hide?: boolean;
  /** The text to display in this part of the control */
  text?: string;
  /** The tooltip for this part of the control */
  tooltip?: string;
  /** The classes to add to the button part. */
  buttonClass?: NestedArray<string>;
  /** The classes to add to the content part. */
  contentClass?: NestedArray<string>;
}

export interface YesNoControlScope extends angular.IScope {
  /** Classes for the container element. */
  buttonWrapperClass: NestedArray<string>;
  /** Scope options for the yes part of the control */
  yes: YesNoControlOptions,
  /** Scope options for the no part of the control */
  no: YesNoControlOptions
}

/** A control with a yes button and a no button with configurable visuals. The buttons are placed
 * on the image editor toolbox by default, but can also be placed on the stage as part of the
 * display tree. */
export class YesNoControl extends HtmlControl<YesNoControlScope> {

  /** Register for a number of observables in one subscription.
   * @param yes Function which is called when the user clicks the yes button.
   * @param no Function which is called when the user clicks the no button.
   */
  subscribe(yes?: ObservableCallback<void>, no?: ObservableCallback<void>): Disposable {
    const registration = createDisposable();
    if (yes) { registration.add(this.$events.observe("yes", yes)); }
    if (no) { registration.add(this.$events.observe("no", no)); }
    this.dispose.add(registration);
    return registration;
  }

  observe(eventName: "yes" | "no", callback: ObservableCallback<void>): IDisposable {
    return this.$events.observe(eventName, callback);
  }

  notify(eventName: "yes" | "no"): void {
    this.$events.notify(eventName);
  }

  protected $configureYesNoScope(scope: YesNoControlScope): YesNoControlScope {
    scope.buttonWrapperClass = ["btn-group", "editor-buttons"];
    scope.yes = {
      click: () => this.notify("yes"),
      disable: false,
      hide: false,
      buttonClass: ["btn", "btn-success"],
      contentClass: ["glyphicon", "glyphicon-ok"],
      tooltip: "Accept",
      text: undefined
    };
    scope.no = {
      click: () => this.notify("no"),
      disable: false,
      hide: false,
      buttonClass: ["btn", "btn-danger"],
      contentClass: ["glyphicon", "glyphicon-remove"],
      tooltip: "Cancel",
      text: undefined
    };
    return scope;
  }

  constructor($compile: angular.ICompileService, scope: YesNoControlScope, appendToElement: string | HTMLElement | JQuery = "#diagramToolbox") {
    super($compile);
    this.$configureYesNoScope(scope);
    this.compile(YesNoControl.template, scope);
    this.appendToElement(appendToElement);
  }

  /** The angular HTML template for this control. */
  static template =
`
<div ng-class="buttonWrapperClass">
<button class="yes"
        ng-class="yes.buttonClass"
        type="button"
        ng-click="yes.click()"
        ng-disabled="yes.disabled"
        ng-hide="yes.hide"
        title="{{yes.tooltip}}">
  <span ng-class="yes.contentClass">{{yes.text}}</span>
</button>
<button class="no"
        ng-class="no.buttonClass"
        type="button"
        ng-click="no.click()"
        ng-disabled="no.disabled"
        ng-hide="no.hide"
        title="{{no.tooltip}}">
  <span ng-class="no.contentClass">{{no.text}}</span>
</button>
</div>`;
}

interface TextBoxControlScope extends angular.IScope {
  textarea: {
    wrapperStyle: { [key: string]: any }
    style: { [key: string]: any; };
    class: NestedArray<string>;
    rows: number;
    text: string;
  }
}

/** A HTML control with a textarea. The control is placed on the image editor toolbox by default,
  * but can also be placed on the stage as part of the display tree. */
export class TextBoxControl extends HtmlControl<TextBoxControlScope> {
  static template =
`<span ng-style='textarea.wrapperStyle'>
<span class="form" role="form">
  <span class="form-group">
    <textarea ng-model='textarea.text' rows='textarea.rows' ng-class="textarea.class" ng-style='textarea.style'></textarea>
  </span>
</span>
</span>`;

  observe(eventName: "changed", callback: ObservableCallback<string>): IDisposable {
    if (eventName === "changed") {
      return this.scope.$watch("textarea.text", callback);
    }
  }

  protected $configureTextBoxScope(scope: TextBoxControlScope) {
    scope.textarea = {
      wrapperStyle: {},
      style: {},
      rows: 3,
      class: ["form-control"],
      text: ""
    };
  }

  /** Gives focus to the text area of this control. */
  focus() {
    focus(this.element.querySelector("textarea"));
  }

  /** Blurs focus on the text area of this control. */
  blur() {
    blur(this.element.querySelector("textarea"));
  }

  /** Creates a new text box control and appends it to the provided element.
   * @param appendToCssSelector CSS selector to get the HTML element to append this control to.
   */
  constructor($compile: angular.ICompileService, scope: TextBoxControlScope, appendToElement: string | HTMLElement | JQuery = "#diagramToolbox") {
    super($compile);
    this.$configureTextBoxScope(scope);
    this.compile(TextBoxControl.template, scope);
    this.appendToElement(appendToElement);
  }
}

/** Manages a set of controls. Multiple instances of this class can be active at once. They each
 * manage their own distinct set of controls. */
export class ControlManager extends Traceable {
  private $controls: IControl[] = [];
  /** Dispose this control manager, disposing any controls it created, and deregistering any
   * event handlers. */
  dispose: Disposable;

  /** The display container for housing the controls. */
  container: cjs.Container;
  /** The control which is currently being interacted with. */
  activeControl: IControl;

  /** The color used to draw grip circles by default. */
  defaultGripColour = "rgba(0, 0, 255, 0.5)";
  /** The radius of grip cicrcles by default. */
  defaultGripRadius = coordExtent * 0.01;

  /** Creates a new grip control without any visual element. This version accepts interactions
   * when the user drags anywhere on the stage rather than just a grip shape. */
  createGripControl(index?: number): GripControl;
  /** Creates a new grip control. These are useful for dragging points or shapes around.
   * @param controlPos The position of the control to begin with.
   * @param displayShape The visual representation of the interaction area if provided. Also for
   * hit tests when the user first begins interaction. Defaults to a circle.
   * @param index The index in the control list that the grip should be created. This can be used
   * to set which controls are "on top" of others. */
  createGripControl(controlPos: IPointLike, displayShape?: Shapes.RenderableShape, index?: number): GripControl;
  createGripControl(controlPos?: IPointLike | number, displayShape?: Shapes.RenderableShape, index?: number): GripControl {
    if (typeof controlPos === "number") {
      index = <number>controlPos;
      controlPos = undefined;
    } else if (displayShape == null && controlPos != null) {
      displayShape = new Shapes.Circle(
        { x: 0, y: 0 },
        this.defaultGripRadius,
        "transparent", 0.001,
        this.defaultGripColour);
    }
    const control = new GripControl(new Grip(), displayShape, <any>controlPos);
    return this.addControl(control, index);
  }

  /** Creates a new HTML control in the diagram toolbox, or the provided element.
   * @param html The HTML to compile against the control's scope.
   * @param parent The parent element to attach the control to. Defaults to the diagram toolbox.
   */
  createHtmlControl(html: string | HTMLElement | JQuery, parent?: string | HTMLElement | JQuery): HtmlControl<angular.IScope>;
  /** Creates a new HTML control in the diagram toolbox, or the provided element.
   * @param html The HTML to compile against the control's scope.
   * @param parent The parent element to attach the control to. Defaults to the diagram toolbox.
   */
  createHtmlControl<TScope extends angular.IScope>(html: string | HTMLElement | JQuery, parent?: string | HTMLElement | JQuery): HtmlControl<TScope>;
  createHtmlControl<TScope extends angular.IScope>(html: string | HTMLElement | JQuery, parent?: string | HTMLElement | JQuery): HtmlControl<TScope> {
    const control = new HtmlControl<TScope>(this.$compile);
    try {
      const scope = <TScope>this.$rootScope.$new(true);
      control.dispose.add(scope.$destroy.bind(scope));
      control.compile(html, scope);
      control.appendToElement(parent);
      return this.addControl(control);
    }
    catch (error) {
      control.dispose();
      throw error;
    }
  }


  /** Creates a new yes/no button control in the diagram toolbox, or the provided element. */
  createYesNoControl(parent?: string | HTMLElement | JQuery): YesNoControl {
    const scope = <YesNoControlScope>this.$rootScope.$new(true);
    let control: YesNoControl;
    try {
      control = new YesNoControl(this.$compile, scope, parent);
      control.dispose.add(scope.$destroy.bind(scope));
      return this.addControl(control);
    }
    catch (error) {
      if (control) {
        control.dispose();
      }
      scope.$destroy();
      throw error;
    }
  }

    /** Creates a new text box control with yes/no buttons in the diagram toolbox, or the
     * provided element. */
    createTextBoxControl(text: string, parent?: string | HTMLElement | JQuery): TextBoxControl {
      const scope = <TextBoxControlScope>this.$rootScope.$new(true);
      let control: TextBoxControl;
      try {
        control = new TextBoxControl(this.$compile, scope, parent);
        control.scope.textarea.text = text;
        return this.addControl(control);
      }
      catch (error) {
        if (control) {
          control.dispose();
        }
        scope.$destroy();
        throw error;
      }
    }

    /** Creates a new interaction control for the entire canvas. This is useful for tools which
     * need to draw wherever the user clicks. */
    createInteractControl(): InteractControl;
    /** Creates a new interaction control for some part of the canvas, defined by the provided
     * shape and position.
     * @param controlPos The position of the control to begin with.
     * @param displayShape The visual representation of the interaction area if provided. Also for
     * hit tests when the user first begins interaction. If not provided, hit test assumes the
     * entire canvas is the control and returns true.
     */
    createInteractControl(controlPos: IPointLike, displayShape: Shapes.RenderableShape, index?: number): InteractControl;
    createInteractControl(controlPos?: IPointLike, displayShape?: Shapes.RenderableShape, index?: number): InteractControl {
      const control = new InteractControl(displayShape, controlPos);
      return this.addControl(control, index);
    }

    /** Adds a control to the stage, or moves an existing control. Also wires up the control's
     * trace settings to match this manager.
     * @param control The control to add.
     * @param index If provided, the index to insert the control at. Defaults to the back.
     * @returns The control. */
    addControl<TControl extends IControl>(control: TControl, index?: number): TControl {
      if (control == null) {
        this.$error && this.$error("imageEditor.controls.addControl() was called with a null control");
        return control;
      }
      this.$copyTraceSettingsTo(control);
      const controls = this.$controls;
      if (index == null) {
        index = this.$controls.length;
      } else {
        index = index < 0 ? 0 : index > controls.length ? controls.length : index;
      }
      const i = controls.indexOf(control);
      if (i >= 0 && i !== index) {
        controls.splice(i, 1);
        this.container.removeChild(control.ink);
      }
      controls.splice(index, 0, control);
      if (control.ink) {
        this.container.removeAllChildren();
        for (let c of this.$controls) if (c.ink) {
          this.container.addChild(c.ink);
        }
        this.$context.undoUserZoomOn(control.ink);
        this.$context.update();
      }
      return control;
    }

  /** Removes and destroys all controls currently managed by this class. */
  clear() {
    this.safeInteractCancel();
    this.container.removeAllChildren();
    for (let control of this.$controls) {
      control.dispose();
    }
    this.$controls.length = 0;
    this.$context.update();
  };

    /** Hit tests against all of the controls managed by this class, returning the first which
     * returns true.
     * @param p The point to hit test against the controls.
     * @returns The first control to return true for the hit test, or undefined if none did.
     */
    findControlAt(p: IPointLike): IControl {
      for (let c of this.$controls) if (c.hitTest(p)) {
        return c;
      }
      return undefined;
    }

    /** Removes the provided control. It will no longer be displayed or trigger events. By default
     * it is also disposed.
     * @param control The control to remove.
     * @param dispose Whether to dispose the control as well. Defaults to true.
     */
    remove(control: IControl, dispose: boolean = true): boolean {
      const controls = this.$controls;
      const i = controls.indexOf(control);
      if (i >= 0) {
        if (this.activeControl != null && controls[i] === this.activeControl) {
          this.safeInteractCancel();
        }
        controls.splice(i, 1);
        this.container.removeChild(control.ink);
        if (dispose) {
          control.dispose();
        }
        this.$context.update();
        return true;
      }
      return false;
    }

  /** Applies scaling to each control in the control layer to invert the current zoom level,
   * thereby ensuring all controls are the same size, no matter the zoom level. */
  standardiseControlsSize() {
    const context = this.$context;
    for (let ink of this.container.children) {
      context.undoUserZoomOn(ink);
    }
    this.$context.update();
  }

    safeInteractStart(control: IControl, p: IPointLike): void {
      this.safeInteractCancel();
      if (control) {
        this.activeControl = control;
        if (typeof control.interactStart === "function") {
          try {
            control.interactStart(p);
          } catch (ex) {
            this.$error && this.$error("Exception in tool.interactStart", ex);
            this.activeControl = null;
          }
        }
        this.$context.update();
      }
    }

  safeInteract(p: IPointLike): void {
    const control = this.activeControl;
    if (control && typeof control.interact === "function") {
      try {
        control.interact(p);
      } catch (ex) {
        this.$error && this.$error("Exception in tool.interact", ex);
      } finally {
        this.$context.update();
      }
    }
  }

  safeInteractEnd(): void {
    const control = this.activeControl;
    if (control) {
      this.activeControl = null;
      if (typeof control.interactEnd === "function") {
        try {
          control.interactEnd();
        } catch (ex) {
          this.$error && this.$error("Exception in tool.interactEnd", ex);
        } finally {
          this.$context.update();
        }
      }
    }
  }

  safeInteractCancel(): void {
    const control = this.activeControl;
    this.activeControl = null;
    if (control) {
      if (typeof control.interactCancel === "function") {
        try {
          control.interactCancel();
        } catch (ex) {
          this.$error && this.$error("Exception in tool.interactCancel", ex);
        } finally {
          this.$context.update();
        }
      }
    }
  }

  /** Copies this tool's trace settings to the provided maybe tracable, if it actually is
   * traceable. */
  protected $copyTraceSettingsTo(traceable: IMaybeTraceable) {
    if (traceable && typeof traceable.setTracing === "function") {
      traceable.setTracing({ error: this.$error, warn: this.$warn, trace: this.$trace });
    }
  }

  static $inject = ["imageEditor.displayContext", "$rootScope", "$compile"];
  constructor(
    private readonly $context: DisplayContext,
    private readonly $rootScope: angular.IRootScopeService,
    private readonly $compile: angular.ICompileService) {
    super();
    this.container = new cjs.Container();
    this.container.name = "Control Manager";
    this.$context.control.addChild(this.container);
    this.dispose = createDisposable(
      () => this.$context.control.removeChild(this.container),
      () => {
        this.clear();
        const container = this.container;
        if (container && container.parent) {
          container.parent.removeChild(container);
        }
      },
      $context.panZoom.observe("pan-zoom", () => this.standardiseControlsSize()),
      $context.panZoom.observe("goto-pan-zoom", () => this.standardiseControlsSize()));
  }
}

export default ControlManager;