/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import * as angular from "angular";
angular.module("midas.utility.measurement.views").controller("ShoulderCtrl", ["$scope", function($scope) {
 const { values } = $scope.measurements;
 const setRightOK = function() {
   values.MSKShoulderRtLHBTNormal.value = "ALL NORMAL";
   values.MSKShoulderRtSubscapularisNormal.value = "ALL NORMAL";
   values.MSKShoulderRtSupraspinatusNormal.value = "ALL NORMAL";
   values.MSKShoulderRtSubacromialBursaNormal.value = "ALL NORMAL";
   values.MSKShoulderRtInfraspinatusNormal.value = "ALL NORMAL";
   values.MSKShoulderRtPosteriorCapsuleNormal.value = "ALL NORMAL";
   values.MSKShoulderRtGHJointNormal.value = "ALL NORMAL";
   values.MSKShoulderRtSplinoglenoidNotchNormal.value = "ALL NORMAL";
   values.MSKShoulderRtSupraspinousNotchNormal.value = "ALL NORMAL";
   values.MSKShoulderRtACJointNormal.value = "ALL NORMAL";
   values.MSKShoulderRtTeresMinorNormal.value = "ALL NORMAL";
   values.MSKShoulderRtTeresMajorNormal.value = "ALL NORMAL";
   values.MSKShoulderRtPectoralisMajorNormal.value = "ALL NORMAL";
   values.MSKShoulderRtSoftTissueMassNormal.value = "ALL NORMAL";
   console.log("Right All normal clicked");
 };
 $scope.clickRightOK = () => setRightOK();
 const setLeftOK = function() {
   values.MSKShoulderLtLHBTNormal.value = "ALL NORMAL";
   values.MSKShoulderLtSubscapularisNormal.value = "ALL NORMAL";
   values.MSKShoulderLtSupraspinatusNormal.value = "ALL NORMAL";
   values.MSKShoulderLtSubacromialBursaNormal.value = "ALL NORMAL";
   values.MSKShoulderLtInfraspinatusNormal.value = "ALL NORMAL";
   values.MSKShoulderLtPosteriorCapsuleNormal.value = "ALL NORMAL";
   values.MSKShoulderLtGHJointNormal.value = "ALL NORMAL";
   values.MSKShoulderLtSplinoglenoidNotchNormal.value = "ALL NORMAL";
   values.MSKShoulderLtSupraspinousNotchNormal.value = "ALL NORMAL";
   values.MSKShoulderLtACJointNormal.value = "ALL NORMAL";
   values.MSKShoulderLtTeresMinorNormal.value = "ALL NORMAL";
   values.MSKShoulderLtTeresMajorNormal.value = "ALL NORMAL";
   values.MSKShoulderLtPectoralisMajorNormal.value = "ALL NORMAL";
   values.MSKShoulderLtSoftTissueMassNormal.value = "ALL NORMAL";
   console.log("Left All normal clicked");
 };
 $scope.clickLeftOK = () => setLeftOK();
}
 ]);
// CoffeeScript