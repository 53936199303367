import * as angular from "angular";
import { BindingOf, IChangesObject } from "../../../utility/componentBindings";
import { Glossary, GlossaryCategory } from "../../../businessModels";

interface IGlossaryEntrySelected {
    $entry: Glossary;
}

interface ComponentBindings {
    category: GlossaryCategory;
    onSelected: (obj: IGlossaryEntrySelected) => void;
}

class Controller implements angular.IComponentController, ComponentBindings {

    onSelected: (obj: IGlossaryEntrySelected) => void;

    category: GlossaryCategory;
    entries: Array<Glossary>;

    $onChanges(obj: IChangesObject<ComponentBindings>): void {
        if (!obj.category)
            return;

        this.category = obj.category.currentValue;
        if(this.category)
            this.entries = this.category.glossary;
    }

    filter(): Array<Glossary> {
        if (!this.entries)
            return null;
        return this.entries.filter(x => !x.isDeleted);
    }

    select(entry: Glossary) {
        this.onSelected({ $entry: entry });
    }
}

export default <angular.IComponentOptions> {
    templateUrl: require('./glossaryEntryList.html'),
    controller: Controller,
    bindings: <BindingOf<ComponentBindings>> {
        category: '<',
        onSelected: '&'
    }
};