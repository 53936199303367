/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */

export default ["layoutStateService", layoutStateService =>
  ({
    restrict: "E",
    replace: true,
    template: 
      "<div ng-transclude></div>",
    transclude: true,
    scope: false,
    link($scope) {
      $scope.$on("layoutStateChangeRequested", (event, layoutState) => layoutStateService.requestStateChange(layoutState));
  
      return layoutStateService.registerScope({
          notifyStateChanged(layoutState) { return $scope.$broadcast("layoutStateChanged", layoutState); }});
    }
  })

];
