/**
 * A raw expand directive purely responsible for enacting and animating the expansion based on the
 * expression inside the directive attribute value.
 */

import "angular-animate";
import * as angular from "angular";
import { IChangesObject, BindingOf } from "../componentBindings";
import { ExpansionPanel } from "./expansionPanel.component";

/** MatAccordion's display modes. */
export type MdsAccordionDisplayMode = 'default' | 'flat';

interface Bindings {
    /** Whether the expansion indicator should be hidden on all sub panels. */
    hideToggle?: boolean;
    /**
     * The display mode used for all expansion panels in the accordion. Currently two display
     * modes exist:
     *   default - a gutter-like spacing is placed around any expanded panel, placing the expanded
     *     panel at a different elevation from the reset of the accordion. This will be used if
     *     the value is not set.
     *   flat - no spacing is placed around expanded panels, showing all panels at the same
     *     elevation.
     */
    displayMode?: MdsAccordionDisplayMode;

    /** Whether to allow multiple panels to be open at once. Defaults to false. */
    multi?: boolean;
}

export interface Accordion extends Bindings, angular.IController {}
export class Accordion implements angular.IController {
    static $inject = ["$element"];
    constructor($element: angular.IAugmentedJQuery) {
        $element.addClass("mds-accordion");
    }

    private readonly _panels: ExpansionPanel[] = [];

    registerPanel(panel: ExpansionPanel) {
        if (panel != null && this._panels.indexOf(panel) < 0) {
            this._panels.push(panel);
        }
        return panel;
    }

    removePanel(panel: ExpansionPanel) {
        const index = this._panels.indexOf(panel);
        if (index >= 0) {
            this._panels.splice(index, 1);
        }
        return panel;
    }

    onPanelOpen(panel: ExpansionPanel) {
        if (panel && !this.multi) {
            for (var item of this._panels) if (item !== panel) {
                item.close();
            }
        }
    }
}


export default <angular.IComponentOptions>{
    controller: Accordion,
    transclude: true,
    template: "<ng-transclude></ng-transclude>",
    bindings: <BindingOf<Bindings>>{
        hideToggle: "<?",
        displayMode: "@?",
        multi: "<?"
    },
};

