import * as angular from "angular";
import * as moment from "moment";
import "moment/locale/en-AU";
import "moment-timezone";

export default angular.module("midas.moment", [])
    .constant("moment", moment)
    .config(() => {
        //Change 2 digit year parsing to always be in the past.
        moment["parseTwoDigitYear"] = function(input) {
            let year = parseInt(input) + 2000;
            return year > moment().year() ? year - 100 : year;
        };
        //Adds a function to the angular element prototype which mirrors the way scope.$on works. IE you
        //can register an event to an element, and the return value is a function to deregister that
        //same handler.
        angular.element.prototype.mdsOn = function(...args) {
            this.on(...args);
            return () => this.off(...args);
        };
    });

export { Moment, MomentStatic } from "moment";