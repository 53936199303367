import * as angular from "angular";
import "angular-material";
import "ng-file-upload";
import { BusinessModelService } from "../../businessModels";
import { LoadingStatus } from "../../utility/utils";

interface AdminReportTemplateController extends angular.IController { }
class AdminReportTemplateController implements angular.IController {
    static $inject =
      ["$scope", "loadingStatus", "businessModels", "Upload", "$mdToast", "$mdDialog", "FileSaver"];

    constructor(private scope: IAdminReportTemplateScope,
                loadingStatus: typeof LoadingStatus,
                private models: BusinessModelService,
                private uploadService: angular.angularFileUpload.IUploadService,
                private mdToast: angular.material.IToastService,
                private mdDialog: angular.material.IDialogService,
                /* Polyfills for the HTML5 saveAs file functions
                See https://github.com/alferov/angular-file-saver */
                private fileSaver: angular.FileSaver) {
        this.scope.uploadStatus = new loadingStatus();
    }

    uploadFile(file: File) {
        if (file === null) {
            return;
        }
        const config: angular.angularFileUpload.IFileUploadConfigFile = {
            url: "api/midas/UploadReportTemplate/",
            data: file,
            method: "POST"
        };
        const wait = this.uploadService.upload(config);
        this.scope.uploadStatus.track(wait);
        wait.then(() => this.mdToast.showSimple("Template successfully uploaded."))
            .catch(() => {
                this.mdDialog.show(this.mdDialog.alert()
                    .textContent("There was a problem uploading the template. Please try again.").ok("OK"));
            });
    }

    download(): void {
        this.models.Practice.downloadReportTemplate()
            .then((download) => {
                const headers = download.headers();
                const filename =
                  this.getFileNameFromContentDispositionHeader(headers["content-disposition"]) || "download.bin";
                this.fileSaver.saveAs(download.data, filename);
            })
            .catch(() => {
                this.mdDialog.show(this.mdDialog.alert()
                    .textContent("There was a problem downloading the template. Please try again.").ok("OK"));
            });
    }

    private getFileNameFromContentDispositionHeader(cd: string): string {
        if (cd === null || cd.length === 0) {
            return null;
        }

        // Shamelessly stolen from http://stackoverflow.com/a/40940790
        const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
        const matches = filenameRegex.exec(cd);
        if (matches === null || matches[1] === null) {
            return null;
        }

        const filename = matches[1].replace(/['"]/g, "");
        return filename;
    }
}

interface IAdminReportTemplateScope {
    uploadStatus: LoadingStatus;
}

const options = <angular.IComponentOptions> {
    controller: AdminReportTemplateController,
    templateUrl: require("./reportTemplate.html")
};

export default angular.module("midas.admin.reportTemplate", ["ngFileSaver"])
    .component("reportTemplate", options);