import { module as ngModule, element as ngElement } from "angular";
import {
  BlueprintExpression,
  ExpressionOperatorType,
  getResultType,
  getAllowedOperandTypes,
} from "../../../utility/reportFormatter/widgets/condition-expression.models";
import {
  serviceName,
  IBlueprintExpressionSerialiser
} from "../../../utility/reportFormatter/widgets/condition-expression-serialiser.service";

const operatorMap = {
  "&&": "And",
  "||": "Or",
  "===": "Equals",
  "!==": "Does Not Equal",
  ">": "Greater Than",
  ">=": "Greater Than Or Equal To",
  "<": "Less Than",
  "<=": "Less Than Or Equal To",
  "| exists": "Exists",
  "| doesNotExist": "Does Not Exist"
};
export const getOperatorDisplayOrDefault = (operator: ExpressionOperatorType) =>
  operatorMap[operator] || operator;

export const expressionText = [serviceName, (serialiser: IBlueprintExpressionSerialiser) =>
  (exp: BlueprintExpression) => {
    if (exp == null) {
      return "Incomplete expression";
    }
    try {
      const html = serialiser.serialise(exp);
      const element = ngElement(html);
      return element.text();
    } catch {
     return "Incomplete expression";
    }
}];

const commonOps: ExpressionOperatorType[] = [
  "===", "!=="
];
const binaryOperators: ExpressionOperatorType[] = [
  "&&",
  "||"
];
const stringOps: ExpressionOperatorType[] = [
];
const numberOps: ExpressionOperatorType[] = [
  ">", ">=", "<", "<=", "+", "-", "*", "/"
];
const measurementOps: ExpressionOperatorType[] = [
  "| exists", "| doesNotExist"
];

export const allOperators = commonOps
  .concat(numberOps)
  .concat(stringOps)
  .concat(binaryOperators)
  .concat(measurementOps)



export default ngModule("midas.admin.blueprint.expressionFilters", [])
  .filter("operatorDisplay", () => getOperatorDisplayOrDefault)
  .filter("expressionText", expressionText)
  .filter("resultType", () => getResultType)
  .filter("allowedOperandTypes", () => getAllowedOperandTypes);