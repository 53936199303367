import { Exam, MeasurementType, MeasurementValue } from "../../../businessModels";
import { isNullOrWhitespace } from '../../../utility/utils';

/** Tries to convert a value to an integer number, returning the original if the result is NaN. If
 * passed a float, the result will be coerced to an integer. */
const tryInt = function (str: any) {
  const parsed = parseInt(str);
  return isNaN(parsed) ? str : parsed;
};
/** Tries to convert a value to a float number, returning the original if the result is NaN. */
const tryFloat = function (str: any) {
  const parsed = parseFloat(str);
  return isNaN(parsed) ? str : Math.round(parsed * 1000) / 1000;
};

/** A view model for displaying measurements. Can be created either with an existing
 * `MeasurementValue`, or with a `MeasurementType`. */
export class MeasurementViewModel {
  /** If this view model is for a measurement type with dropdown options then this value will be
   * assigned an array of the individual options, including an empty option. If this is not a
   * dropdown type then this property will not be defined. */
  readonly options?: ReadonlyArray<string>;
  /** The unique key of the measurement type. This will always be set. */
  readonly key: string;
  /** The current value of the view model. This may be assigned without changing the backing
   * measurement value until `this.updateOriginal()` is called. */
  value: string | number;
  /** A reference to the measurement type which describes this measurement. This will always be
   * defined. */
  readonly type: MeasurementType;
  /** A reference to the measurement value backing this view model. May not be defined, but will be
   * updated if a new measurement is created for this model by `this.updateOriginal()`. This value
   * should not be manually set by users. */
  original?: MeasurementValue;

  /** Gets whether the data type for this measurement is a known C# type. Currently supports
   * `System.String`, `System.Int32`, and `System.Single`. */
  get isTypeValid(): boolean {
    switch (this.type.dataType) {
      case "System.String":
      case "System.Int32":
      case "System.Single":
        return true;
      default: return false;
    }
  }

  /** Whether this view model has an existing MeasurementValue assigned to it. If so, calling
   * `this.updateOriginal()` will create a new measurement value, attached to the exam. */
  get isNew() {
    return (this.original == null);
  }

  /** Gets whether this measurement view model's value has been changed from the original value. */
  get isDirty() {
    if (this.original != null) {
      const original = this.original.value;
      if (typeof this.value === "number") {
        return original !== (`${this.value}`);
      } else {
        return original !== this.value;
      }
    } else {
      return (this.value != null);
    }
  }

  /** Creates a `MeasurementViewModel` for an existing measurement value. */
  static createExisting(measurementValue: MeasurementValue) {
    return new MeasurementViewModel(undefined, measurementValue, undefined);
  }

  /** Creates a `MeasurementViewModel` for a measurement type. If `exam` is provided then
   * `this.updateOriginal()` will create a new measurement value attached to that exam. */
  static createNew(measurementType: MeasurementType, exam?: Exam) {
    return new MeasurementViewModel(exam, undefined, measurementType);
  }

  /** Creates a new measurement view model. `MeasurementViewModel.createNew()` and
   * `MeasurementViewModel.createExisting()` are more readable alternatives. There are 2 main ways
   * this can be called:
   * * With `exam` and `type`, meaning there is no current value, but if the value is assigned and
   *   then `this.updateOriginal()` is called then a new measurement will be created and attached to
   *   the exam.
   * * With `original`, which wil display and allow update of that measurement value.
   * @param exam If `original` is not provided, then this must be provided if
   * `this.updateOriginal()` is going to be called, so the new measurement can be associated with
   * the correct exam.
   * @param original The measurement value to display/update. If this is not null, then exam doesn't
   * need to be provided.
   * @param type The measurement type to display. If original is provided then this must either be
   * null (it will then be derived from the measurement value), or this must match the value's type.
   * If original is not provided then this must be provided. */
  constructor(private readonly exam?: Exam, original?: MeasurementValue, type?: MeasurementType) {
    this.original = original;
    let value: string | number;
    if (this.original != null) {
      if ((type != null) && (this.original.type !== type)) {
        throw new Error("'type' must be null or correlate with the supplied 'original' value");
      }
      this.value = this.original.value;
      this.type = this.original.type;
    } else if (type != null) {
      this.type = type;
    } else {
      throw new Error("Either 'original' or 'type' is required");
    }

    if (this.type != null) {
      this.key = this.type.key;
    } else {
      throw new Error("No type provided or found on view model. Did you forget to expand?");
    }

    if (this.type.dropdownOptions != null) {
      const options: string[] = [""];
      for (const option of this.type.dropdownOptions) {
        if (!isNullOrWhitespace(option)) {
          options.push(option);
        }
      }
      this.options = options;
    }

    // If the data types are numeric then define getters and setters to convert them to Number
    // JS type so that number type inputs can use them.
    if (this.type.dataType === "System.Int32") {
      value = original == null ? undefined : tryInt(original.value);
      Object.defineProperty(this, "value", {
        get() { return value; },
        set(val: string | number) { value = tryInt(val); },
        enumerable: true
      });
    } else if (this.type.dataType === "System.Single") {
      value = original == null ? undefined : tryFloat(original.value);
      Object.defineProperty(this, "value", {
        get() { return value; },
        set(val: string | number) { value = tryFloat(val); },
        enumerable: true
      });
    } else {
      this.value = original != null ? original.value : undefined;
    }
  }

  /** Writes any changes in `this.value` back to the `MeasurementValue` if this was created with
   * one, or if it has no `MeasurementValue` and this was constructed with an `exam`, then a new
   * `MeasurementValue` is created for that exam and the value in this view model is written to it.
   * If there's no value or exam then an error is thrown. */
  updateOriginal() {
    if (this.isDirty) {
      const val: string = (this.value != null) ? `${this.value}` : undefined;
      if (this.isNew) {
        if (this.exam == null) {
          throw Error("Can't call updateOriginal() when both this.exam and this.original are null for measurement key " + this.key);
        } else {
          this.original = this.exam.newMeasurement(this.type, val);
        }
      } else {
        this.original.value = val;
      }
      return true;
    }
    return false;
  };

  /** Reverts `this.value` back to the current value of the backing `MeasurementValue` if it has
   * one, or null if not. */
  revertChanges() {
    this.value = this.isNew ? undefined : this.original.value;
  };
}

