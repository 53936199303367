import ReferenceGroup from "./ReferenceGroup";
import { Study } from "../../../businessModels";
import { IQService } from "angular";
import * as thumbnailUrl from "../templates/measurementThumbnail.html";
import * as fullUrl from "../templates/measurementFull.html";

export default class MeasurementGroup extends ReferenceGroup {
    constructor(public study: Study, private $q: IQService) {
        super("Measurements", thumbnailUrl, fullUrl);

        this.iconClass = "fa fa-bar-chart";
    }

    loadThumbnails() {
        this.thumbnails = this.study.exams.slice(0);
        return this.$q.when(this.thumbnails);
    }
}