import { isArray, IAugmentedJQuery, IScope, element as ngElement } from "angular";
import { NestedArray } from './mdsUtils';

function pushFlat<T>(array: T[], item: T | NestedArray<T>) {
    if (item != null) {
        if (isArray(item)) {
            for (let subItem of item) {
                pushFlat(array, subItem);
            }
        } else {
            array.push(item);
        }
    }
};

/** Safely disposes of something disposable like.
 * @returns True if it disposed without error, or false if there was an error or the object was
 * not compatible. */
export function safeDispose(d: IDisposable): boolean {
    try {
        if (typeof d === "function") {
            d();
            return true;
        }
    } catch (_) { }
    return false;
}

export const createDisposable = <DisposeService>function createDisposable(...args: (IDisposable | NestedArray<IDisposable>)[]) {
    const disposables: IDisposable[] = [];
    pushFlat(disposables, args);

    const disposeFn: Disposable = (function(this: typeof disposables) {
        for (var d of this) {
            safeDispose(d);
        }
        this.length = 0;
    }).bind(disposables);

    disposeFn.add = (function(this: typeof disposables, ...args: (IDisposable | NestedArray<IDisposable>)[]) {
        pushFlat(this, args);
        return disposeFn;
    }).bind(disposables);

    // Dispose this when a scope or element is destroyed.
    disposeFn.disposeWith = (function(this: typeof disposeFn, scopeOrElement: IScope | IAugmentedJQuery | HTMLElement) {
        if (typeof (<angular.IScope>scopeOrElement).$on === "function") {
            (<angular.IScope>scopeOrElement).$on("$destroy", this);
            return;
        }
        const el = ngElement(scopeOrElement);
        if (el.length > 0) {
            el.one("$destroy", this);
            return;
        }
        throw Error("scopeOrElement must provide $on() or one().");
    }).bind(disposeFn);

    return disposeFn;
};
export default createDisposable;


/** Anything which looks like a disposable function. */
export type IDisposable = () => void;

/** Factory function for creating new disposables. */
export interface DisposeService {
    /** Creates a new disposable wrapping any provided dispose functions, no
     *  matter how they are nested. */
    (...dispose: (IDisposable | NestedArray<IDisposable>)[]): Disposable;
}

/** A class which behaves as a composite disposable, allowing many other disposable like objects to
 * be collected and disposed at once. Disposal of this object can be bound to scope or element
 * destruction. */
export interface Disposable extends IDisposable {
    /** Disposes any resources managed by this object. */
    (): void;
    /** Adds a disposable resource to this, so it is disposed with this object.
     * @param disposable The resource to bind to this disposable.
     * @returns Calling instance. */
    add(disposable: IDisposable): this;
    /** Adds many disposable resource to this, unwrapping any level of array nesting.
     * @param dispose Disposable resources to bind to this.
     * @returns Calling instance. */
    add(...dispose: (IDisposable | NestedArray<IDisposable>)[]): this;
    /** Causes this object to be disposed when the provided object is destroyed.
     * @param scopeOrElement scope or element to bind this to. */
    disposeWith(scopeOrElement: IAugmentedJQuery | IScope): void;
}