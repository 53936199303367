import * as angular from "angular";
import {
  Patient,
  BusinessModelService,
  Technician,
  IEnum,
  Physician
} from "../../businessModels";
import {
  LoDashStatic
} from "lodash";
import { ISearchTerm } from "./searchExecutorProviders";
import {
    Predicate as BreezePredicate,
    EntityQuery as BreezeEntityQuery,
    FilterQueryOp as BreezeFilterQueryOp
} from "breeze";
import { splitLastPeriod, startsWith } from '../../utility/utils';
import ExamTypeNameConfigurationService from "../../admin/institute/examTypeNameConfigurationService";

type AggregateSearchTermProvider = {
  get(keys: string | string[]): any;
} & ISearchTermProvider;

export interface ISearchTermProvider {
  minimumTermLength ? : number;
  category ? : string;
  /** Gets a list of terms or a promise for them. */
  getTerms(query: ISelect2Query): undefined | any[] | angular.IPromise < any[] > ;
  /** Tramsforms a single item in the list returned from getTerms(). */
  transform(term: any, queryTerm ? : string): ISearchTerm;
}

export interface ISelect2Query {
  term: string;
  callback: Function;
}

export class SearchTermProvider {
  static $inject = ["$injector", "lodash", "$q"];
  constructor(private $injector: angular.auto.IInjectorService, private $_: LoDashStatic, private $q: angular.IQService) {}

  get(keys: string | string[]) {

    //keys can be passed in as a comma separated list or as an array
    if (typeof keys === "string") {
      keys = keys.split(",");
    }

    const providers: ISearchTermProvider[] = [];
    if (keys) {
      for (let key of keys) {
        providers.push( < ISearchTermProvider > this.$injector.get(`${key}TermProvider`));
      }
    }

    const executeAndCallback = (provider: ISearchTermProvider, response: {
      more: boolean,
      results: any[]
    }, query: ISelect2Query) => {
      const terms = provider.getTerms(query);
      if (terms == null) {
        return this.$q.when([]);
      }
      return this.$q.when(terms).then(results => {
        if (!results.length) {
          return;
        }
        let transformed = results.map(result => provider.transform(result, query.term));
        let existing = this.$_.find(response.results, {
          text: provider.category
        });
        if (existing != null) {
          for (let child of transformed) {
            existing.children.push(child);
          }
        } else {
          let category = {
            text: provider.category,
            children: transformed
          };
          response.results.push(category);
        }
        return query.callback(response);
      });
    }

    return {
      providers,
      getTerms(query: ISelect2Query) {
        query.term = query.term.trim().replace(/\s{2,}/, ' ');
        let response = {
          more: false,
          results: []
        };
        query.callback(response);
        return providers
          .filter(provider => (!provider.minimumTermLength || (provider.minimumTermLength <= query.term.length)))
          .map(provider => executeAndCallback(provider, response, query));
      }
    };
  }
}

class SearchTerm implements ISearchTerm {
  constructor(public value: string, public text: string, public type: string, public id: string = `${value}_${type}`) {}
}

/** Creates a search predicate factory, which creates search predicates for matching a search
 * string against a set of properties on a breeze object.
 * @param properties The properties on the object to match the text against.
 * @param splitOn How to split the search text into fragments.
 * @returns A function which accepts a search string and returns a breeze predicate.
 */
function createSearchPredicateFactory(properties: string[], splitOn = /\s+/) {
  if (properties == null || properties.length === 0) {
    throw Error("properties can't be empty");
  }
  const contains = (property: string, term: string) =>
    BreezePredicate.and(
      BreezePredicate.create(property, "!=", null),
      BreezePredicate.create(property, BreezeFilterQueryOp.Contains, term.toLowerCase()));
  return (query: string) => {
    if (query == null || (query = query.trim()).length === 0) {
      return undefined;
    }
    return BreezePredicate.and(
      query.toLowerCase().split(splitOn).map(term =>
        BreezePredicate.or(properties.map(prop => contains(prop, term))))
    );
  };
}

export const patientTermProvider = ["businessModels", "$q", function (businessModels: BusinessModelService, $q: angular.IQService): ISearchTermProvider {
  const predicateFactory = createSearchPredicateFactory(["urNumber", "person.firstName", "person.lastName"]);
  const listPatients = (searchText): angular.IPromise < Patient[] > => {
    let query = BreezeEntityQuery.from("Patients")
      .expand("person")
      .where("isDeleted", "!=", "true")
      .where(predicateFactory(searchText));
    return new $q((resolve, reject) => businessModels.breeze.executeQuery(query)
      .then(data =>
        resolve( < Patient[] > businessModels.asBusinessModel(data.results)),
        reject));
  };

  return {
    minimumTermLength: 3,
    category: "Patients",
    transform(patientOrProperty: string | Patient, queryTerm: string) {
      //We want to group patient properties, ie "UR Number: ABCDEFG" AND actual patients under the
      //same heading.
      if (angular.isString(patientOrProperty)) {
        return new SearchTerm(queryTerm, `${patientOrProperty}: ${queryTerm}`, patientOrProperty);
      }
      return new SearchTerm(`${patientOrProperty.id}`,
        `${patientOrProperty.lastName.toUpperCase()} ${patientOrProperty.firstName}`,
        "patient");
    },
    getTerms(query: ISelect2Query): angular.IPromise < (string | Patient)[] > {
      return listPatients(query.term).then(function (patients: (Patient | string)[]) {
        patients.push("UR Number", "Last Name", "First Name");
        return patients;
      });
    }
  };
}];

export const physicianTermProvider = ["businessModels", function (businessModels: BusinessModelService): ISearchTermProvider {
  const predicateFactory = createSearchPredicateFactory(["providerNumber", "person.firstName", "person.lastName"]);
  const listPhysicians = (searchText) => {
    let query = BreezeEntityQuery.from("Physicians")
      .expand("person")
      .where("isDeleted", "!=", "true")
      .where(predicateFactory(searchText));
    return <Physician[] >
      businessModels.asBusinessModel(businessModels.breeze.executeQueryLocally(query));
  };

  return {
    minimumTermLength: 3,
    category: "Staff: Physicians",
    transform(phys: Physician, queryTerm: string) {
      const providerText = phys.providerNumber ? ` (${phys.providerNumber})` : "";
      return new SearchTerm(`${phys.id}`,
        `${phys.lastName.toUpperCase()} ${phys.firstName}${providerText}`,
        "phys");
    },
    getTerms(query: ISelect2Query) {
      return listPhysicians(query.term);
    }
  };
}];

export const technicianTermProvider = ["businessModels", function (businessModels: BusinessModelService): ISearchTermProvider {
  const predicateFactory = createSearchPredicateFactory(["person.firstName", "person.lastName"]);
  const listTechs = (searchText) => {
    let query = BreezeEntityQuery.from("Technicians")
      .expand("person")
      .where("isDeleted", "!=", "true")
      .where(predicateFactory(searchText));
    return <Technician[] >
      businessModels.asBusinessModel(businessModels.breeze.executeQueryLocally(query));
  };

  return {
    minimumTermLength: 3,
    category: "Staff: Technicians",
    transform(tech: Technician, queryTerm: string) {
      return new SearchTerm(`${tech.id}`,
        `${tech.lastName.toUpperCase()} ${tech.firstName}`,
        "tech");
    },
    getTerms(query: ISelect2Query) {
      return listTechs(query.term);
    }
  };
}];

export const genericTermProvider = ["$q", function ($q: angular.IQService): ISearchTermProvider {
  return {
    minimumTermLength: 1,
    category: "Search By",
    transform(property: string, queryTerm: string) {
      return new SearchTerm(queryTerm, property + ": " + queryTerm, property);
    },
    getTerms(query: ISelect2Query) {
      let properties = [];
      let id = parseInt(query.term, 10);
      if (!isNaN(id) && (id.toString().length === query.term.length)) {
        properties.push("ID");
      }
      return $q.when(properties);
    }
  };
}];

const filterEnums = (enums: IEnum[], term: string) => enums.filter(
    e => !term.length || (e.key.toUpperCase().indexOf(term.toUpperCase()) > -1)).map(e => e);

const transformEnum = (enumeration: IEnum, type: string) => new SearchTerm(`${enumeration.id}`, `${enumeration.key}`, type);


/** Creates a function which checks a search string against a number of terms. The search string
 * is split on white space, and each fragment is checked against the set of terms. If every
 * search fragment matches at least one term, returns true.
 * @param terms The terms the matcher will check against.
 * @param emptyQueryMatches Whether a null or empty query string matches or not.
 * @param splitOn The regular expression to split the query string on.
 * @returns A function for checking a string against the terms.
 * @example
 * const match = createTermMatcher(['has', 'jewels']);
 * match('has') == true;
 * match('wel as') == true;
 * match('I want jewels') == false;
 */
function createTermMatcher(terms: string[], emptyQueryMatches = false, splitOn = /\s+/) {
  if (terms == null || terms.length === 0) {
    throw Error("terms can't be empty");
  }
  for (let i = 0; i < terms.length; ++i) {
    terms[i] = ("" + terms[i]).toLowerCase();
  }
  return function (query: string) {
    if (query == null || (query = query.trim()).length === 0) {
      return emptyQueryMatches;
    }
    const fragments = query.split(splitOn);
    return fragments.every(fragment => terms.some(term => {
      if (term.indexOf(fragment) >= 0) {
        return true;
      }
    }));
  }
};

export const statusTermProvider = ["businessModels", function (businessModels: BusinessModelService): ISearchTermProvider {
  return {
    category: "Status",
    transform(status) {
      return transformEnum(status, "status");
    },
    getTerms(query: ISelect2Query) {
      return businessModels.Status.list().then(function (statuses) {
        let test = filterEnums(statuses, query.term);
        return test;
      })
    }
  };
}];

export const moduleTermProvider = ["$q", "businessModels", function ($q: angular.IQService, businessModels: BusinessModelService): ISearchTermProvider {
  return {
    category: "Module",
    transform(module) {
      return transformEnum(module, "module");
    },
    getTerms(query) {
      return businessModels.Modality.list().then(function (modalities) {
        //dont need to filter on module, if there's only one
        if (modalities.length === 1) {
          return $q.when([]);
        }
        return filterEnums(modalities, query.term);
      });
    }
  };
}];

export const noteTermProvider = ["$q", function ($q: angular.IQService): ISearchTermProvider {
  const matches = $q.when([new SearchTerm("has-notes", "Has: Notes", "studiesWithNotes")]);
  const match = createTermMatcher(["notes", "has:", "with:"], true);
  return {
    category: "Notes",
    transform(term: SearchTerm) {
      return term;
    },
    getTerms(query) {
      return match(query.term) ? matches : undefined;
    }
  };
}];

export const examTypeTermProvider = [
    "$q", "businessModels", "examTypeNameConfigurationService",
    function (
        $q: angular.IQService,
        models: BusinessModelService,
        mappingService: ExamTypeNameConfigurationService): ISearchTermProvider {
        return {
            category: "Exam Type",
            transform(module) {
              return transformEnum(module, "examType");
            },
            getTerms(query) {
              const examTypes = models.ExamType.listActive();
              // dont need to filter on exam, if there's only one
              const enums = filterEnums(examTypes.map(e => {
                            return {
                                id: e.id,
                                key: mappingService.getCustomName(e.key).examType,
                                orderIndex: e.orderIndex
                            } as IEnum;
                        }) as IEnum[], query.term);
              return $q.when(enums);
            }
        };
    }
];

export const recordTypeTermProvider = ["$q", "lodash", function ($q: angular.IQService, lodash: LoDashStatic): ISearchTermProvider {
  return {
    category: "Type",
    transform(recordType) {
      return new SearchTerm(recordType, recordType, "recordType");
    },
    getTerms(query) {
      let recordTypes = ["Patients", "Studies"];
      if (query.term.length === 0) {
        return $q.when(recordTypes);
      }
      return $q.when(lodash.filter(recordTypes, type => type.toUpperCase().indexOf(query.term.toUpperCase()) > -1));
    }
  };
}];

export const dobTermProvider = ["$q", "businessModels",
  ($q: angular.IQService, models: BusinessModelService): ISearchTermProvider =>
  ({
    minimumTermLength: 2,
    category: "Patients",
    transform(parsed) {
      let id = parsed.toISOString();
      let text = `dob: ${models.User.current.formatDate(parsed)}`;
      return new SearchTerm(id, text, "patientDob");
    },
    getTerms(query) {
      let user = models.User.current;
      if ((user == null) || (query.term.length === 0)) {
        return $q.when([]);
      }
      let parsed = user.parseDate(query.term, false); //Parse as UTC, birthday doesn't care about zone.
      return $q.when(parsed.isValid() ? [parsed] : []);
    }
  })
];

export const studyDateTermProvider = ["$q", "businessModels", "lodash",
  ($q: angular.IQService, models: BusinessModelService,  _: LoDashStatic) =>

  ({
    minimumTermLength: 2,
    category: "Study Active",

    transform(term) {
      let lowerTerm = term.toLowerCase();
      let user = models.User.current;
      switch (false) {
        case lowerTerm !== "today":
          return new SearchTerm("today", "Today", "studiesToday");
        case lowerTerm !== "yesterday":
          return new SearchTerm("yesterday", "Yesterday", "studiesYesterday");
        case lowerTerm !== "this month":
          return new SearchTerm("this month", "This Month", "studiesThisMonth");
        case lowerTerm !== "last month":
          return new SearchTerm("last month", "Last Month", "studiesLastMonth");
        case !startsWith(lowerTerm, "last "):
          return new SearchTerm(lowerTerm, term, "studiesLastPeriod");
        case !startsWith(lowerTerm, "after: "):
          let datePart = term.substring("after: ".length);
          return new SearchTerm(datePart, `After: ${user.formatDate(datePart)}`, "studiesAfter");
        case !startsWith(lowerTerm, "before: "):
          datePart = term.substring("before: ".length);
          return new SearchTerm(datePart, `Before: ${user.formatDate(datePart)}`, "studiesBefore");
        case !startsWith(lowerTerm, "on: "):
          datePart = term.substring("on: ".length);
          return new SearchTerm(datePart, `On: ${user.formatDate(datePart)}`, "studiesOn");
      }
    },

    getTerms(query) {
      let parsed;
      if (startsWith("today", query.term)) {
        return $q.when(["Today"]);
      }
      if (startsWith("yesterday", query.term)) {
        return $q.when(["Yesterday"]);
      }
      if (startsWith("this month", query.term)) {
        return $q.when(["This Month"]);
      }
      if (/las?t?/i.test(query.term)) {
        parsed = splitLastPeriod(query.term, "days"); //Split date with day backup resolution
        return $q.when((() => {
          if (parsed != null) {
            parsed[0] = "Last"; //capitalise first letter.
            return [parsed.join(" ")]; //use the normalised parsed values for better display.
          } else {
            return ["Last 7 days", "Last 30 days", "Last Month"];
          }
        })());
      }
      let user = models.User.current;
      if (user != null) {
        parsed = user.parseDate(query.term);
        if (parsed.isValid()) {
          let dateStr = parsed.format();
          return $q.when([`After: ${dateStr}`, `Before: ${dateStr}`, `On: ${dateStr}`]);
        }
      }
      return $q.when([]);
    }
  })

];