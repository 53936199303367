import { IPromise, IQService as Q } from "angular";
import { Predicate, EntityQuery } from "breeze";
import * as moment from "moment";
import {
    IPatientDuplicateHistoryEntity,
    PatientDuplicateHistory,
    Patient,
    BusinessModelService,
    Entity,
    IBusinessModel,
    IStudyEntity
} from "../../../businessModels";
const levenshtein: (a: string, b: string) => number = require('js-levenshtein');

/** Internal types, useful for testing. */
export declare namespace internal {
/** Minimal patient information returned by a select statement from breeze. */
    export interface MinPatient {
        "id": number;
        "urNumber": string;
        "person_FirstName": string;
        "person_LastName": string;
        "person_Dob": string;
    }
}

/** Duplicate patient information. */
export class PatientDuplicate {
    /** The date of the first name of the duplicate. */
    readonly firstName: string;
    /** The date of the last name of the duplicate. */
    readonly lastName: string;
    /** The date of the birth of the duplicate. */
    readonly dob: Date;
    /** The id of the patient which is a potential duplicate of the primary patient. */
    readonly patientId: number;
    /** How closely the patient matches the primary patient. 0 indicates a perfect match, with
     * higher numbers indicating progressively worse matches. */
    score: number = 0;
    /** Extra information describing why a particular score was given. */
    reasons: string[] = [];
    /** The UR number of the duplicate. */
    readonly urNumber: string;
    /** If set, this contains the number of studies the duplicate patient has. */
    studyCount?: number;

    constructor(minPatient: internal.MinPatient) {
        this.patientId = minPatient.id;
        this.urNumber = minPatient.urNumber;
        this.firstName = minPatient["person_FirstName"];
        this.lastName = minPatient["person_LastName"];
        this.dob = new Date(Date.parse(minPatient["person_Dob"]));
    }

    /** Updates the score and adds an option reason. */
    updateScore(score: number, reason?: string) {
        this.score += score;
        if (reason) {
            this.reasons.push(reason);
        }
    }
}

export class PatientDuplicateService {
    /** Calculates the distance between 2 strings, 0 being identical, higher being more different.
     * Case sensitive. */
    distance = levenshtein;

    /** The distance 2 strings must be to be considered similar, where distance is defined by
     *  this.distance(). */
    similarityTolerance: number = 2;

    /** The total score under which a duplicate is considered noteworthy enough to return, where
     * score is calculated using a custom algorithm based on this.distance() for text distance. */
    duplicateTolerance: number = 5;

    /** The minimum ratio of matched name parts to total parts for which a duplicate is considered
     * noteworthy enough to return. EG "Franklin Ross" compared with "Franklin Heath Ross" will
     * have a ratio of 0.666, so it would be considered a noteworthy match.
     */
    minMatchedRatio: number = 0.5;

    static $inject = ["businessModels", "$q"];
    constructor(private readonly $models: BusinessModelService, private readonly $q: Q) { }

    /** Creates a breeze query for getting potential duplicates to further investigate. */
    private createQuery(patient: Patient): EntityQuery {
        if (patient == null) {
            return null;
        }

        const narrowingOptions = [];
        const dob = patient.dob;
        if (dob != null) {
            //Match on dob and try swapped day and month.
            narrowingOptions.push(this.matchDob(dob));
            narrowingOptions.push(this.matchDob(new Date(dob.getFullYear(), dob.getDate(), dob.getMonth())));
        }
        if (patient.urNumber != null && patient.urNumber.trim() !== "") {
            narrowingOptions.push(new Predicate("urNumber", "==", patient.urNumber));
        }
        const queryPredicate = Predicate.and(
            new Predicate("id", "!=", patient.id),
            new Predicate("isDeleted", "!=", true),
            Predicate.or(narrowingOptions));

        const keys = ["id", "urNumber", "person.firstName", "person.lastName", "person.dob"];

        return EntityQuery.from("Patients")
            .where(queryPredicate)
            .select(keys);
    }

    private matchDob(dob: Date | moment.Moment): Predicate {
        const date = moment(dob);
        return Predicate.and(
            new Predicate("person.dob", ">=", date.clone().startOf("day")),
            new Predicate("person.dob", "<=", date.clone().endOf("day")));
    }

    /** Loads potential duplicates from the server by getting all entities where some reasonably
     * unique thing does match, such as birthdays or UR number. These still need to be scored and
     * filtered. */
    private getPotentialDuplicates(patient: Patient): IPromise<PatientDuplicate[]> {
        const query = this.createQuery(patient);
        if (!query) {
            return this.$q.when([]);
        }
        return new this.$q((resolve, reject) =>  this.$models.breeze.executeQuery(query,
            result => resolve(result.results.map(minPatient => new PatientDuplicate(<any>minPatient))),
            reject));
    }

    private splitNames(first: string, last: string): string[] {
        const whitespace = /\s+/;
        return first.split(whitespace).concat(last.split(whitespace));
    }

    private scoreNames(dup: PatientDuplicate, first: string, last: string): boolean {
        const dupFirst = (dup.firstName || "").toLocaleLowerCase();
        const dupLast = (dup.lastName || "").toLocaleLowerCase();
        first = (first || "").toLocaleLowerCase();
        last = (last || "").toLocaleLowerCase();

        const names = this.splitNames(first, last).filter(name => name !== "");
        const dupNamesSet = this.splitNames(dupFirst, dupLast).filter(name => name !== "");

        if (names.length === 0 || dupNamesSet.length === 0) {
            dup.updateScore(0, "One of the patients has no names.");
            return true;
        }

        /** Whether the names are all in order. If there are any unmatched, this will be false. */
        let isInOrder = true;
        /** The combined score of all matches that are within tolerance. */
        let score = 0;
        /** The number of matches which are within tolerance. */
        let matchedCount = 0;
        /** The number of names parts which are over tolerance and don't match any other part. */
        let unmatchedCount = 0;
        /** All name parts, processed with the best score for that name part and the text. */
        const scores = names.map((name, i) => {
            let min = Infinity;
            let iOfMin: number = -1;
            for (var i = 0; i < dupNamesSet.length; ++i) {
                const score = this.distance(name, dupNamesSet[i]);
                if (score < min) {
                    min = score;
                    iOfMin = i;
                }
            }
            isInOrder = isInOrder && iOfMin === 0; //In order check.
            if (min > this.similarityTolerance) {
                unmatchedCount += 1;
                return {
                    score: min,
                    name,
                    dup: undefined
                };
            } else {
                matchedCount += 1;
                const result = {
                    score: min,
                    name,
                    dup: dupNamesSet[iOfMin]
                };
                score += min;
                dupNamesSet.splice(iOfMin, 1);
                return result;
            }
        });
        unmatchedCount += dupNamesSet.length;
        /** The ratio of matched name parts to total parts. Will be 1.0 if all parts were matched
         * on both sides and 0.5 if two names out of 4 don't match (for example). */
        const matchedRatio = matchedCount / (matchedCount + unmatchedCount);

        let reason: string;
        if (score === 0 && matchedRatio === 1.0) {
            // If the names are a perfect match on both sides, check the order for more info.
            if (isInOrder) {
                reason = "The names are identical.";
            } else {
                reason = "The names are identical, but in a different order.";
                score += 1;
            }
        } else if (score <= (this.similarityTolerance * matchedCount) && matchedRatio >= this.minMatchedRatio) {
            // If the names are similar enough considering how many there are and at least
            // this.minMatchedRatio ratio of the names matched:
            score += unmatchedCount;
            if (unmatchedCount > 0) {
                reason = "The names are similar, but some parts don't match or are extra names."
            } else {
                reason = "The names are similar.";
            }
        } else {
            // The score is too high or too many names weren't matched.
            dup.updateScore(score + unmatchedCount, "WARNING: The names are not similar.");
            return false;
        }

        dup.updateScore(score, reason);
        return true;
    }

    private scoreUrNumber(dup: PatientDuplicate, urNumber: string): boolean {
        urNumber = urNumber || "";
        const dupUrNumber = dup.urNumber || "";
        //Add a duplicate if there's a patient with identical UR number, no
        //matter what their names are.
        if (urNumber.toLocaleLowerCase() === dupUrNumber.toLocaleLowerCase()) {
            if (urNumber !== dupUrNumber) {
                dup.updateScore(0, "The UR numbers differ only in casing.");
            } else {
                dup.updateScore(0, "The UR numbers are identical.");
            }
            return true;
        } else {
            //Otherwise, if the name section detected a duplicate, also check
            //the UR number for tolerance and add that information. If it's not
            //close then lower the score beyond the tolerance by half, but still
            //add it with a warning.
            let score = this.distance(urNumber, dupUrNumber);
            score = score <= this.similarityTolerance
                ? score :
                ((score - this.similarityTolerance) / 2) + this.similarityTolerance;
            dup.updateScore(score, score <= this.similarityTolerance
                ? "The UR numbers are similar."
                : "WARNING: The UR numbers are NOT similar.");
            return score <= this.similarityTolerance;
        }
    }

    private scoreDob(dup: PatientDuplicate, dob: Date): boolean {
        if (dob && dup.dob && dup.dob.getFullYear() === dob.getFullYear()) {
            if (dup.dob.getMonth() === dob.getMonth() && dup.dob.getDate() === dob.getDate()) {
                dup.updateScore(0, "The date of birth is identical.");
                return true;
            } else if (dup.dob.getMonth() === dob.getDate() && dup.dob.getDate() === dob.getMonth()) {
                dup.updateScore(1, "The date of birth has swapped month and day.");
                return true;
            }
        }
        dup.updateScore(this.similarityTolerance + 1, "WARNING: The date of births do not match");
        return false;
    }

    /** Searches the server for potential duplicates and returns a list of likely candidates, with
     * score and reasons for the score.
     * @param patient The patient to find duplicates for.
     */
    search(patient: Patient): IPromise<PatientDuplicate[]> {
        if (patient == null) {
            return this.$q.when([]);
        }

        return this.getPotentialDuplicates(patient).then(potentialDuplicates => {
            const first = (patient.firstName || "").toLocaleLowerCase();
            const last = (patient.lastName || "").toLocaleLowerCase();
            const urNumber = patient.urNumber;

            return potentialDuplicates.map(dup => {
                const namesMatch = this.scoreNames(dup, first, last);
                const urMatches = this.scoreUrNumber(dup, urNumber);
                if (namesMatch || urMatches) {
                    this.scoreDob(dup, patient.dob);
                    return dup.score <= this.duplicateTolerance ? dup : null;
                }
                return null;
            }).filter(x => x != null);
        }).then(potentialDuplicates => Patient
            .list({ "id": potentialDuplicates.map(dup => dup.patientId) }, ["person", "studies"], 50)
            .then(patients => {
                for (var dup of potentialDuplicates) {
                    for (var patient of patients) {
                        if (patient.id === dup.patientId) {
                            dup.studyCount = patient.getLocalStudies().length;
                        }
                    }
                }
                return potentialDuplicates;
            }));
    }

    /** Merges a patient into another patient by moving all of the studies of the duplicate over
     * to the primary patient, and deleting the duplicate. 
     * @param primaryPatientId The primary patient which will get all of the studies.
     * @param duplicatePatientId The duplicate patient to be deleted.
     * @returns The patient duplicate log entry describing the operation, after it has been saved
     * to the database. */
    mergeDuplicate(primaryPatientId: number, duplicatePatientId: number): IPromise<PatientDuplicateHistory> {
        if (primaryPatientId == null) { throw Error("contextPatientId is null"); }
        if (duplicatePatientId == null) { throw Error("duplicatePatientId is null"); }

        const query = EntityQuery.from("Patients")
            .where(Predicate.and(
                new Predicate("isDeleted", "!=", true),
                new Predicate("id", "==", duplicatePatientId)))
            .expand(["person", "studies"]);

        return new this.$q<Patient>((resolve, reject) => {
            this.$models.breeze.executeQuery(query,
                response => {
                    if (response.results.length !== 1) {
                        return this.$q.reject("Duplicate patient not found in database");
                    }
                    resolve(this.$models.asBusinessModel<Patient>(<any>response.results[0]));
                },
                reject);
        })
        .then<PatientDuplicateHistory>(duplicate => {
            const bms = this.$models;
            // Need a custom query to ensure we get deleted studies as well.
            const studiesQuery = EntityQuery.from("Studies")
                .where(new Predicate('patientId', '==', duplicate.id));
            const studyEntities = bms.breeze.executeQueryLocally(studiesQuery) as IStudyEntity[];

            const duplicateLog = <IPatientDuplicateHistoryEntity>bms.breeze.createEntity("PatientDuplicateHistory");
            duplicateLog.primaryId = primaryPatientId;
            duplicateLog.duplicate = duplicate.breezeEntity();
            duplicateLog.mergedBy = bms.asBreezeEntity(bms.User.current);
            duplicateLog.timestamp = new Date(Date.now());
            const entities: (Entity | IBusinessModel)[] = [
                duplicateLog,
            ];

            const rejectChanges = () => {
                for (var model of entities) {
                    try {
                        const entity = <Entity>bms.asBreezeEntity(model);
                        entity.entityAspect.rejectChanges();
                    } catch (err) {
                        console.log("Error reverting changes", err);
                    }
                }
            };

            try {
                for (var study of studyEntities) {
                    if (study) {
                        study.patientId = primaryPatientId;
                        entities.push(study, bms.breeze.createEntity("PatientDuplicateHistoryStudyLink", {
                            duplicateHistory: duplicateLog,
                            study: study
                        }));
                    }
                }
                duplicate.delete();
                entities.push(duplicateLog.duplicate, duplicateLog.duplicate.person);
            } catch (err) {
                rejectChanges();
                return this.$q.reject(err);
            }

            return this.$models.saveEntities(entities).then(() => {
                //Detach the now soft-deleted duplicate patient.
                const patient = duplicateLog.duplicate;
                const person = patient.person;
                if (patient) { patient.entityAspect.setDetached(); }
                if (person) { person.entityAspect.setDetached(); }
                return <PatientDuplicateHistory>bms.asBusinessModel(duplicateLog);
            }, err => {
                rejectChanges();
                return this.$q.reject(err);
            });
        });
    }

    /** Undoes a previous patient duplicate merge, by reinstating the patient which was deleted, and
     * moving all of it's original studies back to it.  
     * @param duplicateEntry The duplicate log entry describing the original merge operation.
     * @returns The newly un-deleted patient with all of it's studies, after the changes have been
     * saved to the database. */
    reinstateDuplicate(duplicateEntry: PatientDuplicateHistory): IPromise<Patient> {
        if (duplicateEntry == null) { throw Error("duplicate shouldn't be null"); }

        const dedupedPatient = duplicateEntry.duplicate;
        const dupEntity = duplicateEntry.breezeEntity();
        const query = EntityQuery.from("PatientDuplicateHistory")
            .where(new Predicate("id", "==", dupEntity.id))
            .expand(["primary.person", "duplicate.person", "studies.study"]);
        const bms = this.$models;
        
        return new this.$q<typeof dupEntity>((resolve, reject) => bms.breeze.executeQuery(query,
            () => resolve(dupEntity), //Results will be merged with duplicate on success.
            reject))
            .then<Patient>(dup => {
                const entities: (Entity | IBusinessModel)[] = [];

                const rejectChanges = () => {
                    for (var model of entities) {
                        try {
                            const entity = <Entity>bms.asBreezeEntity(model);
                            entity.entityAspect.rejectChanges();
                        } catch (err) {
                            console.log("Error reverting changes", err);
                        }
                    }
                };
    
                try {
                    const dupId = dup.duplicateId;
                    //Enumerate a copy, otherwise they're removed as we call setDeleted().
                    for (var link of dup.studies.slice()) {
                        if (link) {
                            link.study.patientId = dupId;
                            entities.push(link.study);
                            link.entityAspect.setDeleted();
                            entities.push(link);
                        }
                    }

                    dup.duplicate.person.isDeleted = false;
                    entities.push(dup.duplicate.person);
                    dup.duplicate.isDeleted = false;
                    entities.push(dup.duplicate);
                    dup.entityAspect.setDeleted();
                    entities.push(dup);
                } catch (err) {
                    rejectChanges();
                    return this.$q.reject(err);
                }
    
                return new this.$q<Patient>((resolve, reject) =>
                    this.$models.saveEntities(entities).then(
                        () => resolve(dedupedPatient),
                        err => {
                            rejectChanges();
                            reject(err);
                        }));
            });
    }
}

export default PatientDuplicateService;