import { IShape, IImageEditorScope, ImageEditorController } from "../imageEditorCore";
import {
    IPointLike,
    Events,
    Vector2,
    Triangle as TriangleGeometry,
    Rect as RectGeometry
} from "../../../utility/utils";
import { Tool, IToolScope } from "./tool";
import { Rectangle, Triangle } from "../shapes/shapes";
import ToolActivationContext from "./toolActivationContext";
import { GripControl } from "../imageEditorControls";
import { IDirectiveFactory, IAttributes, IAugmentedJQuery } from "angular";
import { PatternService } from "../patterns";

interface IRectModel extends IShape {
    type: "rect";
    thickness: number;
    primaryColour: string;
    secondaryColour?: string;
    pattern?: string;
    points: [IPointLike, IPointLike];
}

interface IRectScope extends IImageEditorScope {
    config: {
    thickness?: number;
    primaryColour?: string;
    secondaryColour?: string;
    pattern?: string;
    };
    colours: string[];
}

class RectTool<TModel extends IRectModel> extends Tool<Rectangle, TModel> {
    $shapeType: "rect";
    $scope: IRectScope;
    /** The initial size of the rectangle in cm. */
    static readonly initialSize = { x: 2, y: 2 };

    /** Always show the selection shadow for the rect tool. */
    protected $showShadow() { return true; }

    protected $createViewModel(dataModel?: IRectModel): Rectangle {
        const cmToStage = this.cmToStage(1);
        let points = dataModel && (<IPointLike[]>dataModel.points) || [{ x: 0, y: 0 }, RectTool.initialSize];
        points = points.map(p => new Vector2(p).multiply(cmToStage));
        let model = dataModel || this.$scope.config;
        const vm = new Rectangle(RectGeometry.boundingRect(points), this.cmToStage(model.thickness), model.primaryColour, model.secondaryColour, this.patternService, model.pattern);
        if (this.compositeOp) {
            vm.ink.compositeOperation = this.compositeOp;
        }
        this.$copyTraceSettingsTo(vm);
        return vm;
    }

    protected $updateDataModel(viewModel: Rectangle = this.$viewModel, dataModel?: IRectModel): IRectModel {
        dataModel = dataModel || <any>{};
        dataModel.type = this.$shapeType;
        dataModel.thickness = this.stageToCm(viewModel.thickness);
        dataModel.primaryColour = viewModel.colour;
        dataModel.secondaryColour = viewModel.backgroundColour;
        dataModel.pattern = viewModel.pattern;
        const stageToCm = this.stageToCm(1);
        const d = viewModel.dimensions;
        dataModel.points = [
            new Vector2(d.x, d.y).multiply(stageToCm),
            new Vector2(d.x + d.width, d.y + d.height).multiply(stageToCm)
        ];
        return dataModel;
    }

    $createResizeShape(context: ToolActivationContext) {
        const edgeLen = context.controls.defaultGripRadius * 2;
        const colour = context.controls.defaultGripColour;
        const tri = new TriangleGeometry(
            0, 0,
            edgeLen, 0,
            0, edgeLen);
        tri.translate(-this.$viewModel.shadowExtraThickness);
        return new Triangle(tri, undefined, undefined, colour);
    }

    $getPositionUpdater(control: GripControl, getPos: () => IPointLike) {
        return () => {
            if (!control.isInteracting) {
            control.setPosition(getPos(), false);
            }
        };
    }

    $createMoveAndResizeControls(context: ToolActivationContext) {
        const d = this.$viewModel.dimensions;
        const grips: GripControl[] = [];
        const events = new Events();
        context.dispose.add(() => events.clear());

        //Create a grip controls which resize the text box. They're each drawn as triangles on the
        //corner of the box. There's a fair amount of complication required by this code to make
        //sure they all move as the box is resized or moved by the other grips, hence the event.

        let resizeGripShape = this.$createResizeShape(context);
        grips.push(context.controls.createGripControl(new Vector2(d), resizeGripShape));
        grips[0].bind(p => {
            d.x += p.x;
            d.y += p.y;
            d.width -= p.x;
            d.height -= p.y;
            events.notify("size-changed");
            this.update()
        });
        events.observe("size-changed",
            this.$getPositionUpdater(grips[0],
            () => d));

        resizeGripShape = this.$createResizeShape(context);
        resizeGripShape.ink.rotation = 90;
        grips.push(context.controls.createGripControl(new Vector2(d.xMax, d.y), resizeGripShape));
        grips[1].bind(p => {
            d.y += p.y;
            d.width += p.x;
            d.height -= p.y;
            events.notify("size-changed");
            this.update()
        });
        events.observe("size-changed",
            this.$getPositionUpdater(grips[1],
            () => new Vector2(d.xMax, d.y)));

        resizeGripShape = this.$createResizeShape(context);
        resizeGripShape.ink.rotation = 180;
        grips.push(context.controls.createGripControl(new Vector2(d.xMax, d.yMax), resizeGripShape));
        grips[2].bind(p => {
            d.width += p.x;
            d.height += p.y;
            events.notify("size-changed");
            this.update()
        });
        events.observe("size-changed",
            this.$getPositionUpdater(grips[2],
            () => new Vector2(d.xMax, d.yMax)));

        resizeGripShape = this.$createResizeShape(context);
        resizeGripShape.ink.rotation = 270;
        grips.push(context.controls.createGripControl(new Vector2(d.x, d.yMax), resizeGripShape));
        grips[3].bind(p => {
            d.x += p.x;
            d.width -= p.x;
            d.height += p.y;
            events.notify("size-changed");
            this.update()
        });
        events.observe("size-changed",
            this.$getPositionUpdater(grips[3],
            () => new Vector2(d.x, d.yMax)));

        //Create grip control which moves the text around.
        context.controls.createGripControl().bind(
            this.$viewModel.dimensions,
            () => {
            events.notify("size-changed");
            this.update();
            });
    }

    /** Returns true if the model is a square. */
    isValid(dataModel: IShape): dataModel is TModel {
    return dataModel && dataModel.type === this.$shapeType;
    }

    activate(context: ToolActivationContext) {
    super.activate(context);
    const editModel = <IRectModel>context.editModel;
    if (this.$isEditing) {
        this.$viewModel = this.$createViewModel(editModel);
        this.$ctrl.context.scratch.addChild(this.$viewModel.ink);
        this.$createMoveAndResizeControls(context);
        this.addAcceptDeselectButtons(context);
        this.update();
    } else {
        let initialPlacementControl = context.controls.createInteractControl();
        initialPlacementControl.subscribe(
        p => {
            this.$viewModel = this.$createViewModel(editModel);
            this.$viewModel.dimensions.setLocation(p);
            context.scratchLayer.addChild(this.$viewModel.ink);
            this.update();
        },
        p => {
            this.$viewModel.dimensions.setLocation(p);
            this.update();
        },
        () => {
            context.controls.remove(initialPlacementControl);
            initialPlacementControl = null;
            this.$createMoveAndResizeControls(context);
            this.addAcceptDeselectButtons(context);
        },
        () => context.deselect(true));
    }

    const pipe = this.$pipe;
    context.dispose.add(
        this.watchConfig("thickness", pipe.unitToStage("cm"), pipe.setViewModel("thickness")),
        this.watchConfig("primaryColour", pipe.setViewModel("colour")),
        this.watchConfig("secondaryColour", pipe.setViewModel("backgroundColour")),
        this.watchConfig("pattern", pipe.setViewModel("pattern")));

    context.observe("accept", () => {
        this.$viewModel.bakeTransform();
        context.addOrUpdate(this.$updateDataModel(this.$viewModel, editModel));
        context.deselect(true);
    });
    context.observe("deselect", () => context.reactivate());
    context.observe("delete", () => {
        context.removeEditShape();
        context.reactivate()
    });
    }

    constructor(
        public id: string,
        ctrl: ImageEditorController,
        scope: IToolScope,
        private readonly patternService: PatternService,
        public compositeOp: string = null,
        shapeType: string = "rect"
    ) {
        super(ctrl, scope, shapeType);
    }
}

export default ["patternService", <IDirectiveFactory>((patternService: PatternService) => ({
    require: "^mdsImageEditor",
    restrict: "E",
    scope: <{ [key: string]: string }>{
        config: "="
    },

    link(scope: IToolScope, _element: IAugmentedJQuery, _attr: IAttributes, ctrl: ImageEditorController) {
        ctrl.tools.registerTool("rect", new RectTool("rect", ctrl, scope, patternService));
    }
}))];