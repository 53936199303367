import RenderableShape, { shadowDefaultExtraThickness, shadowDefaultColour } from "./renderableShape";
import { Shape } from "createjs";
import { Rect, IPointLike, LogFunction } from "../../../utility/utils";
import { PatternService } from "../patterns";
import { coordExtent } from "../imageEditorVars";

/** A filled rectangle which can render itself to a canvas. It is rendered as a border box
 * so that everything is drawn within the bounds of the rectangle dimesions. One drawback right
 * now is that it is defined as an axis aligned box, meaning it can't be rotated. The rendered
 * shape can be by rotating the transform matrix, but that can't be saved or reflected in the
 * geometry definition. I should fix this, either by defining a rotation, or specifying the
 * rect with 4 vertices which would make this more of a Quad(rilateral) than a rectangle. */
export default class Rectangle extends RenderableShape {
    /** The handle to the graphical representation of this shape. */
    ink: Shape;
    /** The size and position of the rectangle. */
    dimensions: Rect;
    /** Stroke thickness. */
    thickness: number;
    /** The colour to use to draw the rectangle. */
    colour: string;
    /** The fill colour. */
    backgroundColour: string;
    /** The key of the pattern to use to fill this shape. */
    pattern: string;
    /** The colour to use to draw the shadow. */
    shadowColour: string = shadowDefaultColour;
    /** How much thicker the shadow should be compared to the shape. */
    shadowExtraThickness: number = shadowDefaultExtraThickness * coordExtent;
  
    /** Does a sanity check on the provided arguments to the render function, printing information
     * to trace if that's provided, doing nothing otherwise. */
    static sanityCheck(logger: LogFunction, width: number, height: number, borderThickness: number, paddingX?: number, paddingY?: number) {
      if (logger) {
        if (width < 0 || height < 0 || width > coordExtent || height > coordExtent) {
            logger(`Size dimensions should be within the interval [0, ${coordExtent}], but are { x: ${width}, y: ${height} }`);
        }
        if (paddingX < 0 || paddingY < 0 || paddingX > (coordExtent * .5) || paddingY > (coordExtent * .5)) {
            logger(`Padding dimensions should be within the interval [0, ${coordExtent / 2}], but are { x: ${paddingX}, y: ${paddingY} }`);
        }
        if (borderThickness < 0 || borderThickness > (coordExtent * 0.5)) {
            logger(`Padding dimensions should be within the interval [0, ${coordExtent / 2}], but is ${borderThickness}`);
        }
        if (((borderThickness + paddingX) * 2) >= width) {
            logger(`Border + horizontal padding leaves no space for content (border: ${borderThickness}, padding.x: ${paddingX}, width: ${width})`);
        }
        if (((borderThickness + paddingY) * 2) >= height) {
            logger(`Border + vertical padding leaves no space for content (border: ${borderThickness}, padding.x: ${paddingY}, height: ${height})`);
        }
      }
    }
  
    /** Renders a filled square onto the provided graphics object. */
    static render(ink: Shape, dimensions: Rect, borderThickness: number, borderColour: string, backgroundColour?: string, patternService?: PatternService, pattern?: string, debug?: boolean): Shape {
        if (debug) {
          Rectangle.renderDebugBoxModel(ink, dimensions, borderThickness);
        } else {
          Rectangle.renderBorderBox(ink, dimensions.x, dimensions.y, dimensions.width, dimensions.height, borderThickness, borderColour);
  
          if (backgroundColour || (pattern && patternService)) {
            if (pattern && patternService) {
              patternService.beginFill(ink.graphics, pattern, borderColour, backgroundColour);
            } else {
              ink.graphics.beginFill(backgroundColour);
            }
            ink.graphics
              .drawRect(
                dimensions.x + borderThickness,
                dimensions.y + borderThickness,
                dimensions.width - (2 * borderThickness),
                dimensions.height - (2 * borderThickness))
              .endFill();
          }
        }
  
      return ink;
    }
  
  
    /** Renders a debug mode for rectangles, showing the box as a browser displays the box model
     * in debug mode.
     * @param ink The graphics object to draw on.
     * @param dimensions The size of the outer edge of the rectangle.
     * @param borderThickness The thickness of the border on all sides.
     * @param paddingX The thickness of the padding in the horizontal direction.
     * @param paddingY The thickness of the padding in the vertical direction.
     * @param debugMarginColour The colour to draw the margin.
     * @param debugBorderColour The colour to draw the border.
     * @param debugPaddingColour The colour to draw the padding.
     * @param debugContentColour The colour to draw the content area.
     */
    static renderDebugBoxModel(ink: Shape, dimensions: Rect, borderThickness: number, paddingX = 0, paddingY = 0, debugMarginColour = "#F8CB9C", debugBorderColour = "#FCDB9A", debugPaddingColour = "#C1CD89", debugContentColour = "#8AB3BF") {
        ink.graphics
          .endFill()
          .endStroke()
          .beginFill(debugMarginColour)
          .drawRect(dimensions.x, dimensions.y, dimensions.width, dimensions.height)
          .endFill();
  
        Rectangle.renderBorderBox(ink, dimensions.x, dimensions.y, dimensions.width, dimensions.height, borderThickness, debugBorderColour);
  
        if (paddingX > 0 || paddingY > 0) {
          ink.graphics
            .beginFill(debugPaddingColour)
            .drawRect(dimensions.x + borderThickness, dimensions.y + borderThickness, dimensions.width - (2 * borderThickness), dimensions.height - (2 * borderThickness))
            .endFill();
        }
  
        ink.graphics
        .beginFill(debugContentColour)
        .drawRect(
            dimensions.x + paddingX + borderThickness,
            dimensions.y + paddingY + borderThickness,
            dimensions.width - (2 * (paddingX + borderThickness)),
            dimensions.height - (2 * (paddingY + borderThickness)))
        .endFill();
    }
  
    /** Draws an unfilled rectangle from (x, y) to (x + width, y + height). The stroke is drawn
     * completely within this boundary, unlike ink.graphics.drawRect() which draws exactly on the
     * the rect line. */
    static renderBorderBox(ink: Shape, x: number, y: number, width: number, height: number, thickness: number, colour: string) {
      if (thickness > 0) {
        const xThickness = Math.min(thickness, width * 0.5);
        const yThickness = Math.min(thickness, height * 0.5);
        ink.graphics
          .endStroke()
          .beginFill(colour)
          .drawRect(x, y, width, yThickness)
          .drawRect(x, y, xThickness, height)
          .drawRect(x, height + y - yThickness, width, yThickness)
          .drawRect(width + x - xThickness, y, xThickness, height)
          .endFill()
      }
    }
  
    /** Clears the graphics object and redraws this shape to it. Should be called whenever changes
     * are made to one of the properties which isn't yet reflected in the graphics object. */
    render(drawShadow: boolean = false, target: Shape = this.ink): Shape {
      if (this.$trace) {
          Rectangle.sanityCheck(this.$trace, this.dimensions.width, this.dimensions.height, this.thickness, 0, 0);
      }
      target.graphics.clear();
      if (drawShadow) {
        const shadow = this.dimensions.clone().expand(this.shadowExtraThickness);
        Rectangle.render(target, shadow, this.shadowExtraThickness, this.shadowColour);
      }
      return Rectangle.render(target, this.dimensions, this.thickness, this.colour, this.backgroundColour, this.patternService, this.pattern);
    }
  
    protected getBounds() {
      return this.dimensions.clone();
    }
  
    /** Checks hit detection against this square. Takes into account both the draw
     * position and the current ink transform.
     * @param p The point to hit test against this square.
     */
    hitTest(p: IPointLike): boolean {
      if (this.ink.parent != null) {
        p = this.ink
          .getMatrix(RenderableShape.ScratchMatrix)
          .invert()
          .transformPoint(p.x, p.y, RenderableShape.ScratchPoint);
        return this.dimensions.contains(p);
      }
      return false;
    }
  
    /** Bakes the current ink position into the points and re-renders. Essentially shifts any offset
     * from the ink position to the actual pixel positions. */
    bakeTransform(): void {
      this.$trace && this.$trace("Rectangle.bakeTransform() - only bakes position");
      this.dimensions.translate(this.ink);
      this.ink.x = this.ink.y = 0;
    }
  
    constructor(rect: Rect, thickness: number, colour: string, backgroundColour?: string, private readonly patternService?: PatternService, pattern?: string) {
      super();
      this.dimensions = rect;
      this.thickness = thickness;
      this.colour = colour;
      this.backgroundColour = backgroundColour;
      this.pattern = pattern;
      this.ink = new Shape();
      this.ink.name = "Rectangle";
    }
  }