import { auto, ILogService, ILogCall } from "angular";
import { LogLevel, IErrorNotificationService, errorsServiceName } from "./errorNotificationService";

/** This class redirects log functions to a notification service with a given log level. */
class LogRedirector {
  constructor(private readonly $log: ILogService, private readonly errorService: IErrorNotificationService) {}

  public redirect<K extends keyof ILogService>(fn: K, level: LogLevel): LogRedirector {
    const original: ILogCall = this.$log[fn] as ILogCall;
    this.$log[fn] = (...args: any[]) => {
      // call original error function of log service
      original(...args);

      // cannot log to notification service if we have no information.
      if(!args || args.length === 0) {
        return;
      }

      let message: string = null;
      let error: Error = null;
      // arguments sent to the log service that are not the message or Error will be attached as an
      // 'extra' object
      let extraArray: any[] = args;

      const messageOrError: any = args[0];

      // ie. $log.error("Problem")
      if(typeof messageOrError === "string") {
        message = messageOrError;
        // ie. $log.error("Problem", ex)
        if(args.length > 1 && args[1] instanceof Error) {
          error = args[1];
          extraArray = args.splice(0, 2);
        } else {
          extraArray = args.splice(0, 1);
        }
      // ie. $log.error(ex)
      } else if(messageOrError instanceof Error) {
        error = messageOrError;
        extraArray = args.splice(0, 1);
      }

      // extra object will name any extra arguments arg0, arg1, etc
      let extraObject: any = {};
      for(var x: number = 0; x < extraArray.length; x++) {
        const arg: any = extraArray[x];
        extraObject["arg" + x] = arg;
      }

      this.errorService.log(message, level, extraObject, error);
    };
    return this;
  }
}

/** This decorator replaces the $log.error function calls.
 *  It preserves the original $log.error function (logging to console)
 *  and adds a call to the injected Error Notification Service
 */
const decorateLog = ($log: ILogService, errorService: IErrorNotificationService): ILogService => {
  const redirector = new LogRedirector($log, errorService);
  redirector
    .redirect("log", LogLevel.Debug)
    .redirect("debug", LogLevel.Debug)
    .redirect("info", LogLevel.Info)
    .redirect("warn", LogLevel.Warning)
    .redirect("error", LogLevel.Error);
  return $log;
};
decorateLog.$inject = ["$delegate", errorsServiceName];

export default class AngularLogDecoratorFactory {
  static $inject = ["$provide"];
  constructor($provide: auto.IProvideService) {
      $provide.decorator("$log", decorateLog);
  }
}