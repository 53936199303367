import {
  IParseService,
  IAttributes,
  IScope,
  ICompiledExpression,
  IAugmentedJQuery,
  ITimeoutService
} from "angular";
import {
  LocalContext,
  createDisposable,
  blur,
  focus
} from "../utility/utils";

interface FocusOptions {
    handler: ICompiledExpression,
    timeout: ICompiledExpression,
    in?(event?: Event): void,
    out?(event?: Event): void,
    promise: any
}

//Parses the event handler from the directive name, and an optional mds-focus-timeout attribute
//which controls how long to wait for a matched focus back in/out event to cancel an earlier
//event. Defaults to 25 if none is supplied.
const parseAttributes = ($parse: IParseService, attrs: IAttributes, directiveName: string) =>
  <FocusOptions>{
    handler: $parse(attrs[directiveName]),
    timeout: (attrs.mdsFocusTimeout != null) ? $parse(attrs.mdsFocusTimeout) : (_: IScope) => 25
  };

//Does the work of binding to the appropriate events for the mds-focus/blur-inside and events.
const bindEvents = function(element: IAugmentedJQuery, focus: FocusOptions) {
    const observedEvents = [
        element.mdsOn("focusin", focus.in),
        element.mdsOn("focusout", focus.out)
    ];
    observedEvents.push(
        element.mdsOn("$destroy", function() {
            for (var dereg of observedEvents) { try { dereg(); } catch (error) {} }
        }));
    return observedEvents;
};

//Creates a pseudo-event which fires only when focus leaves an element and all of its children for
//some amount of time without focusing back inside. This effectively gives a "focus left me and
//all of my children" event.
//Has an optional 'mds-focus-timeout' attribute which controls how long to wait for paired
//blur/focus events before issuing this pseudo-event.
export const mdsBlurInside = ["$parse", "$timeout", ($parse: IParseService, $timeout: ITimeoutService) => ({
    restrict: "A",
    link(scope: IScope, element: IAugmentedJQuery, attrs: IAttributes) {
        const focus = parseAttributes($parse, attrs, "mdsBlurInside");

        focus.out = function(event) {
            if (focus.promise == null) {
                focus.promise = $timeout(function() {
                    focus.promise = undefined;
                    focus.handler(scope, new LocalContext(event, element));
                },
                focus.timeout(scope));
                return;
            }
        };

        focus.in = function() {
            if (focus.promise != null) {
                $timeout.cancel(focus.promise);
                focus.promise = undefined;
            }
        };

        return bindEvents(element, focus);
    }
})];

//Creates a pseudo-event which fires only when focus enters this element or any of its children
//without having just left any other one of them. Essentially this should only fire when focus
//transitions from outside this element to inside, but not when transitioning between children of
//this element.
//Has an optional 'mds-focus-timeout' attribute which controls how long to wait for paired
//blur/focus events before issuing this pseudo-event.
export const mdsFocusInside = ["$parse", "$timeout",
  ($parse: IParseService, $timeout: ITimeoutService) =>
    ({
      restrict: "A",
      link(scope, element, attrs) {
        const focus = parseAttributes($parse, attrs, "mdsFocusInside");

        focus.in = function(event) {
          if (focus.promise != null) {
            $timeout.cancel(focus.promise);
            delete focus.promise;
          } else {
            focus.handler(scope, new LocalContext(event, element));
            return;
          }
        };

        focus.out = function() {
          if (focus.promise != null) { $timeout.cancel(focus.promise); }
          focus.promise = $timeout((() => delete focus.promise), (focus.timeout(scope)));
        };

        return bindEvents(element, focus);
      }
    })
  
];

//This directive causes the element it is on to become focused if its bound boolean argument is set to true.
export const mdsFocusWhen = () => ({
  restrict: 'A',
  link: function(scope, element, attrs) {
    scope.$watch(attrs.mdsFocusWhen, function(value) {
      if (value === true) { 
        focus(element);
      }
    });
  }
});

//This directive can be placed on an element to stop propagation of all listed events at that level.
//For example:
//
//Stop the click event from propagating past this div.
// <div mds-stop-propagation="click">Hello</div>
//
//Stop the click and mousedown and mouseup events from propagating past this div, but also show an
//alert when they click on this div.
// <div mds-stop-propagation="click, mousedown, mouseup" ng-click="alert('Boo')">Hello</div>
export const mdsStopPropagation = () => ({
    restrict: 'A',
    link(scope: IScope, element: IAugmentedJQuery, attr: IAttributes) {
        if (attr.stopPropagation != null) {
            for (var event of attr.stopPropagation.split(',')) {
                event = event.trim().toLowerCase();
                if (event !== "") {
                    element.bind(event, function(e) { e.stopPropagation(); });
                }
            }
        }
    }
  })
;