/*
 * decaffeinate suggestions:
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */

export default [
    "$scope", "$state", "$stateParams", "layoutStateService",
    function($scope, $state, $stateParams, layoutStateService) {

        $scope.isMobile = ($state.current.data != null ? $state.current.data.isMobile : undefined) != null ? ($state.current.data != null ? $state.current.data.isMobile : undefined) : false;
        $scope.studyId = $stateParams.studyId;
        $scope.imageId = $stateParams.imageId;

        if (layoutStateService.contentHeight) { $scope.contentHeight = layoutStateService.contentHeight; }

    }
];
