﻿import * as angular from "angular";
import "angular-material";
import "ng-file-upload";
import { User, RoleName, BusinessModelService, Site, PersonTitleType } from "../../businessModels";
import { createDialogOptions } from "./userManagementNew";
import { IPasswordPromptType } from "../../utility/userPassword";

export interface IEditUser {
    user: User;
    isAdmin: boolean;
    isPhysician: boolean;
    isTechnician: boolean;
    newPassword: string;
    repeat: string;
    signature: string;
    canEditFinalReport: boolean;
    canSeeMeasurementKeys: boolean;
    readOnlyMode: boolean;
    sites: Site[];
    opened: boolean;
    isOpened: boolean;
}

class UserManagementListController implements angular.IController {
    users: User[];
    editUsers: IEditUser[];
    siteList: Site[];
    selectedSites: Site[];
    titleTypes: PersonTitleType[];

    totalItems: number;
    currentPage: number;
    itemsPerPage: number;
    maxSize: number; //Number of pager buttons to show

    static $inject = [
        "$scope",
        "$log",
        "$mdDialog",
        "$mdToast",
        "Upload",
        "businessModels",
        "passwordPrompt",
        "$q"
    ];
    constructor(
        private readonly $scope: angular.IScope,
        private readonly $log: angular.ILogService,
        private readonly $mdDialog: angular.material.IDialogService,
        private readonly $mdToast: angular.material.IToastService,
        private readonly upload: angular.angularFileUpload.IUploadService,
        private readonly models: BusinessModelService,
        private readonly passwordPrompt: IPasswordPromptType,
        private readonly $q: angular.IQService
    ) {}

    $onInit(): void {        
        this.load();
        this.selectedSites = Array<Site>();
      
        //Set pagination
        this.itemsPerPage = 5;        
        this.currentPage = 1;
        this.maxSize = 5;
    }

    private load() {
        var self = this;
        self.$q.all({
            sites: self.models.Site.list(),
            titleTypes: self.models.PersonTitleType.list(),
            users: self.models.User.list({ "person.institute.id": self.models.Institute.current.id },
            "person.physicians, person.technicians, userRoles.role, userSites.site"),

        })
        .then(result => {
            self.siteList = result.sites;
            self.titleTypes = result.titleTypes;
            self.users = result.users;
            self.editUsers = result.users.map((u: User) => {
                return { 
                  user: u,
                  opened: false,
                  get isOpened() {
                    return this.opened;
                  },
                  set isOpened(newValue) {
                    this.opened = newValue;
                    if (this.opened) { self.populateEditUser(u)}
                  }
                } as IEditUser
            }) as Array<IEditUser>;
            self.totalItems = result.users.length;
        });
    }

    applySiteFilter() {
    var self = this;
        if (self.selectedSites.length > 0) {
            var filteredUsers = [];
            self.selectedSites.forEach(site => {
              if (site == null) {
                self.users
                  .filter(u => u.sites.length == 0)
                  .forEach(u => {
                    if (filteredUsers.filter(f => f.id == u.id).length == 0) filteredUsers.push(u);
                  })
              } else {
                self.users
                  .filter(u => u.sites.some(s => s.id == site.id))
                  .forEach(u => {
                    if (filteredUsers.filter(f => f.id == u.id).length == 0) filteredUsers.push(u);
                  })
              }                
            })
            self.editUsers = filteredUsers.map((u: User) => {
                return { 
                  user: u,
                  opened: false,
                  get isOpened() {
                    return this.opened;
                  },
                  set isOpened(newValue) {
                    this.opened = newValue;
                    if (this.opened) { self.populateEditUser(u)}
                  } } as IEditUser
            }) as Array<IEditUser>;
        } else {
            self.editUsers = self.users.map((u: User) => {
                return { 
                  user: u,
                  opened: false,
                  get isOpened() {
                    return this.opened;
                  },
                  set isOpened(newValue) {
                    this.opened = newValue;
                    if (this.opened) { self.populateEditUser(u)}
                  } } as IEditUser
            }) as Array<IEditUser>;
        }
        self.totalItems = self.editUsers.length;
    }

    private reload(user) {        
        this.models.User.find({ "id": user.id }, "person.physicians, person.technicians, person.personTitleType, userRoles.role, userSites.site")
          .then(result => this.populateEditUser(result));
    }

    setItemsPerPage() {
        this.currentPage = 1;
    }

    populateEditUser(user: User): void {
        var edit_user = this.editUsers.filter(u => u.user.id == user.id)[0];
        edit_user.isAdmin = user.hasRole('Is Administrator');
        edit_user.isPhysician = user.physician !== null;
        edit_user.isTechnician = user.technician !== null;
        edit_user.newPassword = null;
        edit_user.repeat = null;
        edit_user.signature = (user.physician !== null) ?
            ("api/midas/signaturedata/" + user.physician.id + "?" + new Date().getTime()) : null;
        edit_user.canEditFinalReport = user.hasRole('Can Edit Final Reports');
        edit_user.canSeeMeasurementKeys = user.hasRole('Can See Measurement Keys');
        edit_user.readOnlyMode = user.hasRole('Read-Only Mode');
        edit_user.sites = user.sites;
    }

    onNew(): void {
        const newUser = this.models.User.create();
        newUser.institute = this.models.Institute.current;
        const editUser = {
            user: newUser,
            isAdmin: false,
            isPhysician: false,
            isTechnician: false,
            newPassword: null,
            repeat: null,
            signature: null,
            canEditFinalReport: null,
            canSeeMeasurementKeys: null,
            readOnlyMode: null
        } as IEditUser;
        const opt: angular.material.IDialogOptions = createDialogOptions(editUser, this.titleTypes);
        this.$mdDialog.show(opt)
            .then(() => {                
                this.onSave("creating")
            });
    }

    onDelete(user: User): void {
        user.delete();
        this.onSave("deleting");
    }

    onChangePassword(user: User) {
        const opt: angular.material.IDialogOptions = this.passwordPrompt(user);
        this.$mdDialog.show(opt);
    }

    saveChanges = (user: User): void => {
        if (this.$scope.editUserForm.$valid)
            this.onSave('saving', user);
    }

    onSave = (msg: string, user: User = null): angular.IPromise<void> => {
        return this.models.save()
          .then(() => { if (user) this.reload(user); else this.load()})
            .catch(e => {
                this.$mdToast.showSimple("There was a problem " + msg + " the user. Please try again.");
                this.$log.error("Exeption: ", e);
                this.models.breeze.rejectChanges();
                this.reload(user);
            });
    }

    updateSites(editUser: IEditUser) {
        editUser.user.sites.forEach(oldSite => {      
            if (editUser.sites.filter(x => x.id === oldSite.id).length == 0) 
                editUser.user.removeSite(oldSite)
        });
        editUser.sites.forEach(newSite => {      
            if (editUser.user.sites.filter(x => x.id === newSite.id).length == 0) 
                editUser.user.addSite(newSite)
        });
        this.onSave('saving', editUser.user);
    }
     
    toggleRole(user: User, roleName: RoleName): void {
        if (!user || !roleName) {
            return
        }
        if (user.hasRole(roleName)) {
            user.removeRole(roleName);
        } else {
            user.addRole(roleName);
            // remove read-only role if any other role is added
            if (user.hasRole('Read-Only Mode')) {
                user.removeRole('Read-Only Mode');
            }
        }
        this.onSave("saving", user);
    };

    toggleReadOnlyRole(user: User, roleName: RoleName): void {
        if (!user || !roleName) { return }
        if (user.hasRole(roleName)) {
            user.removeRole(roleName);
        } else {
          //for the read-only user remove all the other roles
          for (let role of user.roles) {
              user.removeRole(role.name);
          }
          if (user.physician) {
              user.physician.isDeleted = true;
          }
          if (user.technician) {
              user.technician.isDeleted = true;
          }
          user.addRole(roleName);
        }
        this.onSave("saving", user);
    };

    togglePhysician = (user: User): void => {
        if (!user) { return }
        if (user.physician) {
            user.physician.isDeleted = !user.physician.isDeleted;
            if (user.physician) user.removeRole('Read-Only Mode');
        } else {
            this.models.Physician.create(user.person);
            if (user.hasRole('Read-Only Mode')) {
                user.removeRole('Read-Only Mode')
            }
        }        
        this.onSave("saving", user);
    }

    toggleTechnician = (user: User): void => {
        if (!user) { return }
        if (user.technician) {
            user.technician.isDeleted = !user.technician.isDeleted;
            if (user.technician) user.removeRole('Read-Only Mode');
        } else {
            this.models.Technician.create(user.person)
            if (user.hasRole('Read-Only Mode')) {
                user.removeRole('Read-Only Mode')
            }
        }
        this.onSave("saving", user);
    };

    uploadFile(files: File[], invalidFiles: File[], user: User) {

        if (invalidFiles && invalidFiles.length > 0) {
            this.$mdToast.showSimple(this.errorTypeMessage(invalidFiles[0]));
            return;
        }

        if (!files || files.length !== 1 || !user.physician ) {
            return;
        }

        if (files[0].type.split('/')[0] !== "image") {
            this.$mdToast.showSimple(`${files[0].name} is not an image file`);
            return;
        }

        this.upload.upload({
            url: `api/midas/UploadSignature/${user.physician.id}/`,
            data: files,
            method: "POST"
        } as angular.angularFileUpload.IFileUploadConfigFile)
        .then(() => this.reload(user))
        .catch(() => {
            this.$mdToast.showSimple("There was a problem uploading the signature. Please try again.");
        });
    }

    private errorTypeMessage(invalidFile: File): string {
        if (invalidFile["$error"]) {
            switch (invalidFile["$error"]) {
                case "maxSize":
                    return `${invalidFile.name} is too large, it must be smaller than ${invalidFile["$errorParam"]}`;
                case "pattern":
                    return `${invalidFile.name} is not an image file`;
                default:
                    return `${invalidFile.name} had an unspecified error`;
            }
        } else {
            return `${invalidFile.name} had an unspecified error`;
        }
    }

    validateEmail(scope: angular.IScope, user: User): void {
        if (scope.editUserForm.email.$modelValue) {
            let emailPattern = /^([a-zA-Z0-9])+([a-zA-Z0-9._%+-])+@([a-zA-Z0-9_.-])+\.(([a-zA-Z]){2,6})$/;
            let isValid = emailPattern.test(scope.editUserForm.email.$modelValue);
            scope.editUserForm.email.$setValidity("email", isValid);
            if (isValid) this.saveChanges(user);
        }        
  }
}

export default angular.module("midas.user.management.list", ["ui.bootstrap.pagination"])
.component("userManagementList",
    {
        controller: UserManagementListController,
        templateUrl: require('./userManagementList.html')
    });