import { BindingOf } from "../../../utility/componentBindings";
import { PatientDuplicate as Duplicate } from "./duplicateDetection.service";
import "./duplicateLists.scss";
import { PatientDuplicateHistory } from "../../../businessModels";

interface Bindings {
    /** Fired when the user clicks the UNDO DUPLICATE button.  */
    onDedupe: (locals: { duplicate: Duplicate, $event: MouseEvent }) => void;
    /** The duplicate history items to display. */
    merged: PatientDuplicateHistory[];
}

interface MergedPatientListComponent extends angular.IController, Bindings {}
class MergedPatientListComponent implements angular.IController, Bindings {}

export default <angular.IComponentOptions>{
    controller: MergedPatientListComponent,
    bindings: <BindingOf<Bindings>>{
        onDedupe: "&",
        merged: "<"
    },
    templateUrl: require("./mergedPatientList.component.html")
};