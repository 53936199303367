import "./measurementDiagram.css";
import {
  startsWith,
  denormaliseAttr,
  isNullOrWhitespace,
  Unit,
  Throttle
} from '../../../utility/utils';
import {
  ICompileService,
  IScope,
  IAugmentedJQuery,
  IAttributes,
  element as ngElement,
  ILogService,
  ITemplateLinkingFunction,
  ICacheObject
} from "angular";
import { MeasurementViewScope } from './measurementView';
import { MeasurementViewModel } from './MeasurementViewModel';

export interface MeasurementOptions {
  [key: string]: string | boolean;
}

interface MeasurementViewExtendedScope extends MeasurementViewScope {
  measurement : MeasurementViewModel
}

declare const process;

export default [
  "$compile",
  "measurementCache",
  "$log",
  "throttle",
  function(
    $compile: ICompileService,
    measurementCache: ICacheObject,
    $log: ILogService,
    throttle: typeof Throttle
  ) {
    //Gets the appropriate HTML template for the provided type. If 'variant' is supplied then a
    //variant filename is made by appending 'variant' to the default is attempted first.
    const getVariants = function(measurement: MeasurementViewModel, options: MeasurementOptions, variant: string = null) {
      let baseTemplateUrls: string[] = [];
      if (measurement != null) {
        if (measurement.options != null) {
          if (options != null ? options.editable : undefined) {
            baseTemplateUrls.push("dropdownEditable.html");
          } else if (options != null && options.flat != null && (options.flat === "true" || options.flat === true)) {
            baseTemplateUrls.push("dropdownFlat.html");
          } else if (options != null ? options.toggle : undefined) {
            baseTemplateUrls.push("dropdownToggle.html");
          }
          // Always fall back on the base dropdown if an option or variant isn't found.
          baseTemplateUrls.push("dropdown.html");
        } else {
          if (measurement.type != null) {
            switch (measurement.type.dataType) {
              case "System.String":
                if ((options != null ? options.multiline : undefined) === true) {
                  baseTemplateUrls.push("stringMultiline.html");
                } else {
                  baseTemplateUrls.push("string.html");
                }
                break;
              case "System.Int32":
                baseTemplateUrls.push("int32.html");
                break;
              case "System.Single":
                baseTemplateUrls.push("single.html");
                break;
            }
          }
        }

        // Always fall back on "otherewise.html" if nothing else matches. Use otherwiseDebug in debug.
        if (process.env.NODE_ENV !== 'production') {
          baseTemplateUrls.push("otherwiseDebug.html");
        }

        baseTemplateUrls.push("otherwise.html");

        if (variant != null) {
          //Check the variant first if requested.
          return baseTemplateUrls.map(url => url.replace(".html", `${variant}.html`))
                                 .concat(baseTemplateUrls);
        }
      }
      return baseTemplateUrls;
    };

    let getCompiledError = function() {
      const tmp = $compile(
        ngElement("<span class='bg-danger'>Error</span>")
      );
      getCompiledError = () => tmp;
      return tmp;
    };

    function addContext(scope: IScope, message: string) {
      const exam = scope.exam;
      if (exam) {
        message += ` for exam #${exam.id}`;
      const examType = exam && exam.type;
        if (examType) {
          message += ` of type ${examType.key}`;
        }
      }
      return message;
    }

    function getTemplate(urls: string[]) {
      // Early out on the first url if available.
      let variant = measurementCache.get(urls[0]);
      if (variant != null) {
        return variant;
      }

      // If not, check the rest and fill them in to the one that finally resolved.
      let i = 1;
      const len = urls.length;
      for ( ; i < len; ++i) {
        variant = measurementCache.get(urls[i]);
        if (variant != null) {
          const resolvedUrl = urls[i];
          for (--i; i >= 0; --i) {
            console.log(`${urls[i]} permanently resolved to ${resolvedUrl}`);
            measurementCache.put(urls[i], variant);
          }
        }
        return variant;
      }
    }

    // Batch compilation, linking, and DOM updates into batches of 50. This gives way better
    // apparent performance because it prevents everything locking up for large measurement
    // templates, and the part that the user is looking at usually loads first which means much
    // faster time to interactive.
    const throttleTime = 50; // ms
    const maxBatchSize = 50; // Max number of items per batch.

    const linkThrottle = new throttle<Unit>(throttleTime, true);
    const batched: (() => void)[] = [];
    linkThrottle.observe(() => {
      const thisBatch = batched.splice(0, Math.min(maxBatchSize, batched.length));
      for (var linker of thisBatch) {
        try {
          linker();
        } catch (ex) {
          $log.error("Error in measurement directive linker", ex);
        }
      }
      // Trigger another batch if there are any left.
      if (batched.length > 0) {
        linkThrottle.onNext(Unit.instance);
      }
    });

    function batch(action: () => void) {
      batched.push(action);
      linkThrottle.onNext(Unit.instance);
    }

    return {
      restrict: "E",
      requires: "^mdsMeasurementView",
      scope: true,
      link(scope: MeasurementViewExtendedScope, element: IAugmentedJQuery, attrs: IAttributes) {
        let options: MeasurementOptions;

        scope.shouldHide = function() {
          return scope.editable !== true && !(scope.measurement.value || scope.measurement.value === 0) && options["hide-if-empty"];
        };

        // A child scope which can be destroyed independently of this directive's scope, allowing
        // us to recompile at will and clean up the older compiled code.
        let childScope: IScope = null;

        let isDirty = false;

        // Loads and installs a template variant into the DOM for this measurement's type.
        const reload = function(variant: string = null) {
          isDirty = true;

          batch(() => {
            // Debounce this call as it's possible for it to be queued several times in quick
            // succession from variant or DOM changes. We should only compile and link once.
            if (isDirty) {
              isDirty = false;
            } else {
              return;
            }

            // Reload the options and key from the directive element.
            options = scope.options = { };
            // We can't use the attr object because it's not necessarily updated. We just want
            // raw attribute values here anyway so we can read the DOM.
            const rawAttrs = Array.from(element[0].attributes).filter(x =>{
                var n = x.name.toLowerCase()
                return n !== "id" && n !== "class";
              }).map(x => ({
              key: x.name,
              value: x.value
            }));
            for (let attr of rawAttrs) {
              if (startsWith(attr.key, "opt")) {
                const optionKey = attr.key.replace("-", "").substr(3).toLowerCase();
                options[optionKey] = "value" in attr && attr.value.length > 0 ? attr.value : true;
              } else if (attr.key.toLowerCase() === "key") {
                scope.key = attr.value;
                scope.measurement = scope.measurements.values[scope.key];
              }
            }

            if (isNullOrWhitespace(scope.key)) {
              $log.error(addContext(scope, "mds-measurement requires a non null/whitespace 'key' attribute"));
              return;
            }

            if (scope.measurement == null) {
              scope.measurements.addNotFound(scope.key);
              $log.error(addContext(scope, `mds-measurement key '${scope.key}' not found in study's measurements`));
              return;
            } else {
              scope.measurement["isMapped"] = true;
            }

            // Load the appropriate measurement variant and install it into the DOM.
            let key, value;
            let needsClone = false;
            const variants = getVariants(scope.measurement, options, variant);

            let compiled: ITemplateLinkingFunction = null;
            //If the directive has no attributes other than key then use a pre-compiled version.
            if (rawAttrs.length === 1) {
              const cachedName = `compiled ${(typeof variants == "string") ? variants : variants[0]}`;
              compiled = measurementCache.get(cachedName);
              if (compiled == null) {
                const resolved = getTemplate(variants);
                if (resolved) {
                  compiled = $compile(ngElement(resolved));
                  measurementCache.put(cachedName, compiled);
                }
              }
              needsClone = true;
            }
            //If the directive has custom attributes then load and compile from scratch.
            if (compiled == null) {
              const resolved = getTemplate(variants);
              if (resolved) {
                try {
                  const template = ngElement(resolved);
                  for (key in attrs) {
                    value = attrs[key];
                    if (!startsWith(key, "$")) {
                      if (key === "class") {
                        template.addClass(value);
                      } else {
                        template.attr(denormaliseAttr(key), value);
                      }
                    }
                  }
                  if (scope.measurement && "netError" in scope.measurement) {
                    delete scope.measurement["netError"];
                  }
                  compiled = $compile(template);
                } catch (reason) {
                  if (scope.measurement != null) {
                    scope.measurement["netError"] = reason;
                  }
                  needsClone = true;
                  return getCompiledError();
                }
              }
            }

            // Destroy any old scope to clean up the previously linked template, and then link
            // the new template, replacing the current contents of this directive.
            if (childScope) {
              childScope.$destroy();
            }
            childScope = scope.$new();
            element.empty();
            if (needsClone) {
              compiled(childScope, cloned => element.append(cloned));
            } else {
              compiled(childScope).appendTo(element);
            }
          });
        };

        // Reload whenever the variant changes.
        if ("measurements" in scope) {
          const sub = scope.measurements.onVariantChanged(variant => reload(variant));
          scope.$on("$destroy", sub as () => void);
        }

        // Reload whenever any attribute values change. We don't need to trigger any digest here
        // because the reload will be batched, and that batching process manages it for us.
        const watchAttrs = new MutationObserver(() => {
          reload("measurements" in scope ? scope.measurements.variant : null);
        });
        watchAttrs.observe(element[0], { attributes: true, subtree: false });
        scope.$on("$destroy", () => watchAttrs.disconnect());

        // Watch for set measurement button pressed and update accordingly.
        if (!isNullOrWhitespace(attrs.group)) {
          scope.$on("setMeasurementDefault", (_event, args) => {
            if (!isNullOrWhitespace(args.group)) {
              if (args.group === attrs.group) {
                scope.measurement.value = scope.measurement.type.dropdownOptions[0];
              } else {
                var groups = attrs.group.split(",");
                if (groups.includes(args.group)) {
                  scope.measurement.value = scope.measurement.type.dropdownOptions[0];
                }
              }
            }
          })
        }

        // Perform the initial build of the measurement.
        reload("measurements" in scope ? scope.measurements.variant : null);
      }
    }
  }
];