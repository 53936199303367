import * as angular from "angular";
import {
  IAugmentedJQuery,
  IScope,
  IQService,
  ICompileService,
  IController,
  ICacheObject,
  ITemplateLinkingFunction,
  IPromise,
  ILogService,
  IHttpService
} from "angular";
import { Exam, Modality, BusinessModelService, ExamType } from "../../../businessModels";
import { MeasurementViewModel as ViewModel } from "./MeasurementViewModel";
import { LoadingStatus } from "../../loadingStatus";
import measurementTemplateCacheModule from "../measurementTemplateCache.service";
import { MeasurementContextService } from "./measurementContext.service";
import { MeasurementUIElement } from "../config/mds-measurement-layout";
import { Events } from "../../../utility/events/events";
import serviceModule, { serviceName, IMeasurementTypeUnit, MeasurementUnitService } from "../config/mds-measurement-unit.service";
import newLayoutItemComponent from "./../config/mds-measurement-layout-item-new.component";

/** Webpack require function for the measurement views folder. */
const measurementViews = require["context"]("../view", true, /\.html$/i);
/** Lookup from lowercase contextual template path to the exact webpack require path. */
const measurementViewKeys = (<string[]> measurementViews.keys()).reduce((set, file) => {
  set[file.toLocaleLowerCase()] = file;
  return set;
}, {} as { [key: string]: string });
/** Gets the URL to a file on disk that matches a variant URL under the template views folder.
 * @param variantUrl The path to the template variant from app/utility/measurement/view/. Filename
 * is not case sensitive. */
function variantUrlOrDefault(variantUrl: string): string {
  const webpackFileKey = measurementViewKeys[variantUrl.toLocaleLowerCase()];
  return webpackFileKey ? measurementViews(webpackFileKey) : undefined;
}

let measurementCache: ICacheObject;

export interface MeasurementViewScope extends IScope {
  exam: Exam | ExamType;
  modality: Modality;
  status: LoadingStatus;
  measurements: Measurements;
  registerDiagramApi: (api: any) => any;

  emptyValue: () => null;
  description: (itemOrKey: ViewModel | string) => string;
  units: (itemOrKey: ViewModel | string) => string;
  dataType: (itemOrKey: ViewModel | string) => string;
  value: (itemOrKey: ViewModel | string) => string | number;
  oldValue: (itemOrKey: ViewModel | string) => string | number;
  hasValue: (itemOrKey: ViewModel | string) => boolean;
  descriptionOrKey: (itemOrKey: ViewModel | string) => string;
  isNew: (itemOrKey: ViewModel | string) => boolean;

  /** The custom layout elements stored for this Exam Type, for this Institute. */
  custom: MeasurementUIElement[];

  measurementVariant: string;
}

type ListDict<T> = { [key: string]: T } & T[];

const variantChangedEvent = "variant-changed";

class Measurements {
  /** Measurements which are requested but not found */
  public readonly notFound: string[] = [];
  /** The original variant this instance was created with. */
  public readonly measurementVariant: string;

  private readonly _events = new Events();

  constructor(
    /** The currently active variant. */
    public variant: string,
    public readonly values: ViewModel[]
  ) {
    this.measurementVariant = variant;
  }

  /** Use the provided variant, or the default variant if null is passed. */
  useVariant(variant = null) {
    if (this.variant !== variant) {
      this.variant = variant;
      this._events.notify(variantChangedEvent, variant);
    }
  }
  /** Toggles the variant back to default, or from default to the provided variant. */
  toggleVariant(variant) {
    this.variant = this.variant != null ? null : variant;
    this._events.notify(variantChangedEvent, this.variant);
  }
  /** Toggles the variant back to the original passed into this directive, or from the original to
   * the provided variant. */
  toggleOriginalVariant(variant) {
    this.variant = this.variant === this.measurementVariant
      ? null
      : variant;

      this._events.notify(variantChangedEvent, this.variant);
  }
  /** Resets the current measurement variant to that passed in via the attributes. */
  useOriginalVariant() {
    if (this.variant !== this.measurementVariant) {
      this.variant = this.measurementVariant;
      this._events.notify(variantChangedEvent, this.variant);
    }
  }
  /** Adds a key to the list of measurement keys which weren't found for debugging. */
  addNotFound(key: string) {
    if (this.notFound.indexOf(key) < 0) {
      this.notFound.push(key);
    }
  }

  /** Register for a callback when the variant is changed via one of the functions. */
  onVariantChanged(callback: (variant: string) => void) {
    return this._events.observe(variantChangedEvent, callback);
  }
}

/** Creates a measurement getter bound to a particular value dictionary */
const createMeasurementGetter = (values: ListDict<ViewModel>) => {
  return function(itemOrKey: ViewModel | string): ViewModel {
    return typeof itemOrKey === "string" ? values[itemOrKey] : itemOrKey;
  };
};

// tslint:disable-next-line:max-classes-per-file
export class MeasurementView implements IController {
  private oldExam: Exam | ExamType = null;
  private oldViewVariant = null;
  private oldCustomTemplate: MeasurementUIElement[];
  private diagramApis = [];

  /** The list of custom MeasurementType units for the loaded exam */
  private measurementTypeUnits: IMeasurementTypeUnit[];

  public static $inject = [
    "$scope",
    "$element",
    "$q",
    "$compile",
    "businessModels",
    "loadingStatus",
    "$log",
    "$http",
    "measurementContext",
    serviceName
  ];
  constructor(
    private readonly $scope: MeasurementViewScope,
    private $element: IAugmentedJQuery,
    private readonly $q: IQService,
    private readonly $compile: ICompileService,
    private readonly models: BusinessModelService,
    loadingStatus: typeof LoadingStatus,
    private readonly $log: ILogService,
    private readonly $http: IHttpService,
    private readonly measurementContext: MeasurementContextService,
    private readonly unitService: MeasurementUnitService
  ) {
    this.$scope.status = new loadingStatus();

  }

  $onInit() {
    this.unitService.getMeasurementTypeCustomUnits(
      this.$scope.exam instanceof Exam ? this.$scope.exam.type.id : (this.$scope.exam as ExamType).id)
      .then(x => this.measurementTypeUnits = x).then(() => {
        this.$element.addClass("mds-measurement-view");
        this.$scope.emptyValue = () => null;
        this.$scope.description = (itemOrKey: ViewModel | string) => {
          const measurement = this.getMeasurement(itemOrKey);
          const type = measurement == null ? null : measurement.type;
          return type == null ? null : type.description;
        };
        this.$scope.units =       (itemOrKey: ViewModel | string) => {
          const measurement = this.getMeasurement(itemOrKey);
          const type = measurement == null ? null : measurement.type;
          if (type == null) {
            return null;
          }
          else {
            const customs = _.filter(this.measurementTypeUnits, {"key": type.key});
            return customs.length > 0 ? customs[0].unit : type.units;
          }
        };
        this.$scope.dataType =    (itemOrKey: ViewModel | string) => {
          const measurement = this.getMeasurement(itemOrKey);
          const type = measurement == null ? null : measurement.type;
          return type == null ? null : type.dataType;
        };
        this.$scope.value =       (itemOrKey: ViewModel | string) => {
          const measurement = this.getMeasurement(itemOrKey);
          return measurement == null ? null : measurement.value;
        };
        this.$scope.oldValue =    (itemOrKey: ViewModel | string) => {
          const measurement = this.getMeasurement(itemOrKey);
          if (measurement) {
            const original = measurement.original;
            if (original) {
              return original.value;
            }
          }
          return null;
        };
        this.$scope.options =     (itemOrKey: ViewModel | string) => {
          const measurement = this.getMeasurement(itemOrKey);
          return measurement == null ? null : measurement.options;
        };
        this.$scope.hasValue =    (itemOrKey: ViewModel | string) => {
          const measurement = this.getMeasurement(itemOrKey);
          return measurement == null ? false : measurement.value != null;
        };
        this.$scope.descriptionOrKey = (itemOrKey: ViewModel | string) => {
          const vm = this.getMeasurement(itemOrKey);
          const type = vm != null ? vm.type : null;
          const description = type.description;
          return description != null ? description : (typeof itemOrKey === "string" ? itemOrKey : null);
        };
        this.$scope.isNew = (itemOrKey: ViewModel | string) => {
          const measurement = this.getMeasurement(itemOrKey);
          return measurement == null ? false : measurement.isNew;
        };

        this.$scope.registerDiagramApi = api => this.diagramApis.push(api);

        // view options may not be passed into this.$scope, as it is optional. For instance: in the reference area usage.
        if (this.$scope.viewOptions != null) {
          this.$scope.viewOptions.isDirty = () => this.$scope.measurements.values.some(v => v.isDirty);
        }

        // this.$scope.$on "$stateChangeStart", saveChanges
        this.$scope.$on("acceptEdit", () => this.saveChanges());
        this.$scope.$on("cancelEdit", () => this.cancelChanges());

        // Reload the view whenever the exam or viewVariant this.$scope variables change.
        this.$scope.$watch("exam", () => this.loadView());
        this.$scope.$watch("viewVariant", () => this.loadView());
        this.$scope.$watch("customTemplate", () => this.loadView());
      });
  }

  /** Compiles a template and replaces the element this was declared on with the result. The
   * compiled template is cached for later reuse to improve load times. */
  replaceElement(variantUrl: string) {
    const cachedName = `compiled ${variantUrl}`;
    let compiled: ITemplateLinkingFunction | IPromise<ITemplateLinkingFunction> =
      measurementCache.get<ITemplateLinkingFunction>(cachedName);
    if (compiled == null) {
      compiled = this.$http.get(variantUrl).then((response) => {
        const template = angular.element(response.data);
        const c = this.$compile(template);
        measurementCache.put(cachedName, c);
        return c;
      });
    }
    return this.$q.when(compiled).then(x =>
      x(this.$scope, (clone) => {
        this.$element.replaceWith(clone);
        this.$element = clone;
    }));
  } 

  /** Default measurement getter which has no measurement dictionary. Overridden later. */
  private getMeasurement(itemOrKey: ViewModel | string): ViewModel {
    return angular.isString(itemOrKey) ? null : itemOrKey;
  }

  loadView(): IPromise<void> {
    try {
      // Absolutely never refresh unless we must. Prevents double reloads when both watches
      // change.
      if (this.oldExam === this.$scope.exam &&
          this.oldViewVariant === this.$scope.viewVariant &&
          this.oldCustomTemplate === this.$scope.customTemplate) {
          return this.$q.when();
      }
      let exam: Exam;
      let examType: ExamType;
      if (this.$scope.exam instanceof Exam) {
        exam = this.$scope.exam;
        examType = this.$scope.exam.type;
      } else {
        examType = this.$scope.exam;
      }
      if (!examType) return;

      this.oldExam = this.$scope.exam;
      this.oldViewVariant = this.$scope.viewVariant;
      this.oldCustomTemplate = this.$scope.customTemplate;

      this.$scope.modality = examType.modality;
      const viewModels = this.measurementContext.create(exam != null ? exam : examType.modality);
      this.$scope.measurements = new Measurements(this.$scope.measurementVariant, viewModels);

      this.getMeasurement = createMeasurementGetter(viewModels);

      /* See if we have a custom template.
      TODO: Once we have customised all the static html templates we can remove the associated code. */
      let templateUrl: string;
      if (this.$scope.customTemplate != null) {
        templateUrl = require("./measurementView.custom.html");
      } else {
        templateUrl = this.getStaticVariantUrl(examType);
      }
      const replace = this.replaceElement(templateUrl);
      this.$scope.status.track(replace);
      return replace as angular.IPromise<any>;
    } 
    catch (ex) {
      this.$log.error("Error loading the measurement view", ex);
      return this.$q.when();
    }
  }

  getStaticVariantUrl(examType: ExamType) {
    // Attempt to load a series of increasingly more generic views and install the first
    // one which webpack reports is available.
    const examTypeKey = examType.key;
    const templatePath = `./${this.$scope.modality.key}/${examTypeKey}`;
    const fallbackPath = "./fallback";
    let variantUrl: string;
    if (this.$scope.viewVariant != null) {
      variantUrl = variantUrlOrDefault(`${templatePath}/${this.$scope.viewVariant}.html`)
      || variantUrlOrDefault(`${fallbackPath}/${this.$scope.viewVariant}.html`);
    }
    variantUrl = variantUrl
      || variantUrlOrDefault(`${templatePath}.html`)
      || variantUrlOrDefault(`${fallbackPath}.html`);
    this.$log.info(`Resolved measurement template view to ${variantUrl}`);
    return variantUrl;
  }

  saveChanges() {
    if (!(this.$scope.exam instanceof Exam)) {
      return;
    }
    const measurements = this.$scope.measurements != null ? this.$scope.measurements.values : undefined;
    if (measurements != null) {

      let anyChanged = false;
      for (const measurement of measurements) {
        const thisChanged = measurement.updateOriginal();
        anyChanged = anyChanged || thisChanged;
      }
      this.$scope.exam.isDirty = anyChanged;
      for (const diagram of this.$scope.exam.diagrams) {
        const template = diagram.template;
        if (template == null) { continue; }
        const ruleElements = template.ruleElements;
        if (ruleElements && ruleElements.length > 0) {
          diagram.isRuleElementsDirty = anyChanged;
        }
      }

      const savePromise = this.$q.when<any>(import(/* webpackChunkName: "canvg" */ "canvg"))
        .then(canvg => {
          canvg.default();
          /* Ensure each capture visual call is done in serial,
          as it scrolls the element into view one by one.
          The element must be on screen for it to be captured. */
          let wait = this.$q.when([]);
          for (const api of this.diagramApis) {
            if (typeof api.captureVisual === "function") {
              wait = wait.then(() => api.captureVisual());
            }
          }
          wait.then(
            () => this.$scope.$emit("editAccepted"),
            error => this.$scope.$emit("editAccepted", error));
          return wait;
        });

      this.models.save.status.track(savePromise);
    }
  }

  cancelChanges() {
    const measurements = this.$scope.measurements != null ? this.$scope.measurements.values : undefined;
    if (measurements != null) {
      for (const measurement of measurements) {
        measurement.revertChanges();
      }
    }
  }
}

export default angular.module(
  "midas.utility.measurement.measurementView", 
  [measurementTemplateCacheModule.name, serviceModule.name, newLayoutItemComponent.name]
).directive("mdsMeasurementView", ["measurementCache",
  function(cache: ICacheObject) {
    measurementCache = cache;

    return {
      restrict: "AE",
      scope: {
        exam: "=",
        measurementVariant: "@?", //readonly
        viewVariant: "@?",
        viewOptions: "=?",
        customTemplate: "=?",
        editable: "=?"
      },
      controller: MeasurementView
    };
  }
]);