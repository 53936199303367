/*
 * decaffeinate suggestions:
 * DS101: Remove unnecessary use of Array.from
 * DS102: Remove unnecessary code created because of implicit returns
 * DS104: Avoid inline assignments
 * DS205: Consider reworking code to avoid use of IIFEs
 * DS206: Consider reworking classes to avoid initClass
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import * as angular from "angular";
import * as moment from "moment";
import * as _ from "lodash";

/** Allowed calendar time resolutions in MomentJS. */
export type MomentResolutions = "years" | "months" | "weeks" | "days" | "hours" | "minutes" | "seconds" | "year" | "month" | "week" | "day" | "hour" | "minute" | "second";

/** An object with no value. Useful to signify an empty payload type distinct from void, null,
 * and undefined. */
export class Unit {
  /** A reusable instance of Unit. */
  static instance = new Unit();
}

/** A function which accepts all arguments and does nothing. */
export const noop: (..._) => void = function () { };

/** Defines an arbitrarily nested array of some type.
 * @template T Type contained in this array or a descendant array. */
export interface NestedArray<T> extends Array<T | NestedArray<T>> { }

/* Finds the first element under 'node' which matches the css selector using the browser supplied
query selector. */
export function cssQuery(node: angular.IAugmentedJQuery | Element | string, cssQueryString: string) {
  return (angular.element(node))[0].querySelector(cssQueryString);
}

/** Efficient startsWith implementation which doesn't search more than it needs to. */
export function startsWith(principle: string, prefix: string): boolean {
  return (principle.lastIndexOf(prefix, 0)) !== -1;
}

/** Efficient endsWith implementation which doesn't search more than it needs to. */
export function endsWith(principle: string, suffix: string): boolean {
  return (principle.indexOf(suffix, principle.length - suffix.length)) !== -1;
}

/**
 * Checks whether a string is null, empty, or contains only whitespace characters.
 * @param str The string to test.
 * @return True if str is null, empty, or whitespace.
*/
export function isNullOrWhitespace(str: string) {
  return (str == null) || /^\s*$/.test(str);
}

/**
 * If str is null or contains only whitespace then null is returned, otherwise str.
 * @param str The string to operate on
*/
export function nonWhitespaceOrNull(str: string): string | null {
  if (isNullOrWhitespace(str)) {
    return null;
  } else {
    return str;
  }
}

/** Join items into a grammatical list (for English rules at least).
* @param items The list items to join.
* @param joiner The joiner placed between each item except the last and second last.
* @param finalJoiner The joiner placed between the last and second last item.
* @example grammaticalJoin([1, 2, 3], ", ", ", and ") -> "1, 2, and 3". */
export function grammaticalJoin(items: string[], joiner: string = ", ", finalJoiner: string = joiner) {
 if (items.length === 0) {
   return "";
 }

 let joined = items[0];
 for (var i = 1, last = items.length - 1; i < last; ++i) {
   joined += ", " + items[i];
 }
 if (items.length > 1) {
   joined += finalJoiner + items[items.length - 1];
 }

 return joined;
}

/** Gets a case sensitive hash code for a string. If case insensitivity is desired, convert the
 * string to lower before passing it in. */
export function hashString(s: string): number {
  if (s == null) { return 0; }
  let hash = 17;
  if (s.length > 0) {
    for (let i = 0, end = s.length; i <= end; i++) {
      const chr = s.charCodeAt(i);
      hash = ((hash << 5) - hash) + chr;  //(hash * 31) + chr
      hash |= 0;
    }  //Convert to 32bit integer
  }
  return hash;
}

/** Combines multiple hashes in an order dependant way. This is the most common way to combine
 * hashes. May be called with a number of arguments, or a single array of values to hash. */
export function combineHashes(...hashes: number[]): number {
  hashes = (hashes.length === 1) && angular.isArray(hashes[0]) ? <any>hashes[0] : hashes;
  return hashes.reduce(((last, hash) => ((last << 5) - last) + hash), 17); //(last * 31) + hash
}


/** Combines multiple hashes in an order independent way. This is useful when you want to create a
 * hash #code for a set of values where order is not important. May be called with a number of
 * arguments, or a single array of values to hash. */
export function combineHashesUnordered(...hashes: number[]): number {
  hashes = (hashes.length === 1) && angular.isArray(hashes[0]) ? <any>hashes[0] : hashes;
  return hashes.reduce(((last, hash) => last ^ hash), 0);
}

/** Denormalises an attribute name from ngModel to ng-model, and DataId to data-id. */
export function denormaliseAttr(normalisedAttr: string) {
  if ((normalisedAttr === null) || "") { return ""; }
  if ((normalisedAttr != null ? normalisedAttr.length : undefined) === 1) { return normalisedAttr[0].toLowerCase(); }
  return normalisedAttr.replace(/[A-Z]/g, ($0, offset) => (offset === 0 ? "" : "-") + $0.toLowerCase());
}

/** Validates and splits some text of the form "Last {number} {period}" into an array containing the
 * components, such as ["Last", "5", "days"]. */
export function splitLastPeriod(text: string, resolution: MomentResolutions = "days") {
  const matches = /(last)\s+(\d+)\s*(\w+)?/i.exec(text);
  if (matches != null) { return [matches[1], matches[2], matches[3] != null ? matches[3] : resolution]; } else { return null; }
}

/** Parses some text of the form "Last {number} {period}" into a date offset from 'fromDate', which
 * defaults to now. Eg "Last 5 days" returns a date 5 days back in time from 'fromDate'. */
export function parseLastPeriod(text: string, resolution : MomentResolutions= "days", fromDate = moment()) {
  const parsed = splitLastPeriod(text, resolution);
  if (parsed != null) {
    const start = fromDate.clone().subtract(parsed[1], parsed[2]);
    if (fromDate.diff(start, resolution, true) >= 1.0) { return start; }
  }
  return null;
}

let _$timeout: angular.ITimeoutService = undefined;
/** Gets the $timeout service. This is weird I know, but I just didn't want to mess around too much
 * and I also didn't want to have to inject these. */
function get$timeout() {
  return _$timeout || (_$timeout = angular.injector(["ng"]).get("$timeout"));
}

/** Calls focus() on the DOM element or jQLite element at a later execution stage so that
 * whatever the caller is doing at that moment is finished by the time it executes. */
export function focus(node: string | Element | angular.IAugmentedJQuery): angular.IPromise<void> {
  return get$timeout()(() => (angular.element(node))[0].focus());
}

/** Calls focus() on the DOM element or jQLite element at a later execution stage so that
 * whatever the caller is doing at that moment is finished by the time it executes. */
export function blur(node: string | Element | angular.IAugmentedJQuery): angular.IPromise<void> {
  return get$timeout()(() => (angular.element(node))[0].blur());
}

export function resizeSnapContent (isOpen: Boolean) {
  if (isOpen === true) {
    (document.getElementsByTagName('snap-content')[0] as HTMLElement).style.width = window.innerWidth-600+"px";                  
  } else {
    (document.getElementsByTagName('snap-content')[0] as HTMLElement).style.width = "100%";
  }
}
