import * as angular from "angular";
import { Crypto } from "../../utility/utils";
import { IEditUser } from "./userManagementList";
import { BusinessModelService, PersonTitleType } from "../../businessModels";

interface UserManagementNewController extends angular.IController { }
class UserManagementNewController implements angular.IController {

    editUser: IEditUser;
    titleTypes: PersonTitleType[];

    static $inject = ["$mdDialog", "$log", "$mdToast", "crypto", "businessModels", "editUser", "titleTypes"];
    constructor(
        private readonly $mdDialog: angular.material.IDialogService,
        private readonly $log: angular.ILogService,
        private readonly $mdToast: angular.material.IToastService,
        private readonly crypto: Crypto,
        private readonly models: BusinessModelService,
        editUser: IEditUser,
        titleTypes: PersonTitleType[]
    ) {
        this.editUser = editUser;
        this.titleTypes = titleTypes;
    }

    accept(): void {
        this.editUser.user.password = this.crypto.sha1(this.editUser.newPassword, true);
        this.$mdDialog.hide(this.editUser);
    }
    
    cancel(): void {
        this.$mdDialog.cancel();
    }

    validateUserName(scope: angular.IScope): void {
        let userName = scope.newUserForm.userName;
        if (userName.$modelValue) {
            this.models.User.list({ "person.institute.id": this.models.Institute.current.id, userName: userName.$modelValue }, 1)
                .then(results => {
                    userName.$setValidity("available", results.length === 0);
                })
                .catch(e => {
                    this.$mdToast.showSimple("There was a problem checking the username. Please try again.");
                    this.$log.error("Exeption: ", e);
                    userName.$setValidity("available", false);
                });
        }
    }

    validatePassword(scope: angular.IScope): void {
        if (scope.newUserForm.password.$modelValue && scope.newUserForm.repeat.$modelValue) {
            if (scope.newUserForm.password.$modelValue === scope.newUserForm.repeat.$modelValue) {
                scope.newUserForm.password.$setValidity("match", true)
            }
            else {
                scope.newUserForm.password.$setValidity("match", false)
            }
        }        
    }

    validateEmail(scope: angular.IScope): void {
        if (scope.newUserForm.email.$modelValue) {
            let emailPattern = /^([a-zA-Z0-9])+([a-zA-Z0-9._%+-])+@([a-zA-Z0-9_.-])+\.(([a-zA-Z]){2,6})$/;
            scope.newUserForm.email.$setValidity("email", emailPattern.test(scope.newUserForm.email.$modelValue));
        }        
    }

}

export function createDialogOptions(editUser: IEditUser, titleTypes: PersonTitleType[])
    : angular.material.IDialogOptions {
    return {
        templateUrl: require('./userManagementNew.html'),
        controller: UserManagementNewController,
        parent: angular.element(document.body),
        openFrom: "#newUserDialog",
        locals: {
            editUser: editUser,
            titleTypes: titleTypes
        },
        controllerAs: "$ctrl",
        bindToController: true
    }
}