import * as angular from "angular";

//Configure 401 redirect to login page

class UnauthorisedInterceptor implements angular.IHttpInterceptor {
    static $inject = ["$location", "$stateParams", "$q"];

    constructor(private $location: angular.ILocationService,
        private stateParams: angular.ui.IStateParamsService,
        private $q: angular.IQService) {
    }

    //Requires fat arrow to protect 'this' reference
    responseError = (rejection): angular.IPromise<any> => {
        const inst = this.stateParams["inst"];
        const loginBase = `/${inst}/login`;

        //Eedirect to login if 401, we know the current institute, and we're not already on the login page.
        if (rejection.status === 401 && inst && this.$location.path().indexOf(loginBase) === -1) {
            //I would prefer to build the URL using the $state service but it would cause a circular dependency 
            //$state needs $http which needs the provider which needs $state
            this.$location.path(`${loginBase}/expired`);
        }

        return this.$q.reject(rejection);
    };
}

let interceptorName = "unauthorisedInterceptor";
export default
    angular.module("midas.unauthorisedHttpInterceptor", ["ui.router"])
           .service(interceptorName, UnauthorisedInterceptor)
           .config(["$httpProvider", ($httpProvider: angular.IHttpProvider) =>
                $httpProvider.interceptors.push(interceptorName)]);