import { IController, module as ngModule } from "angular";
import { ExamType } from "../../../businessModels";

interface CreateFormatterDialogController extends IController {}
class CreateFormatterDialogController {

  examType: ExamType;
  examTypes: ExamType[];

  static $inject = ["$mdDialog"];
  constructor(
    private readonly $mdDialog: angular.material.IDialogService) {}

  accept() {
    this.$mdDialog.hide({
      examType: this.examType
    });
  }

  cancel() {
    this.$mdDialog.cancel();
  }

  filter(searchText) {
    return this.examTypes.filter(x => x.key.toUpperCase().indexOf(searchText.toUpperCase()) !== -1);
  }
}

const createOptions = (examTypes: ExamType[]): angular.material.IDialogOptions => {
  return {
    controller: CreateFormatterDialogController,
    templateUrl: require("./report-formatter-create.dialog.html"),
    controllerAs: "$ctrl",
    bindToController: true,
    fullscreen: true,
    clickOutsideToClose: true,
    locals: {
      examTypes
    }
  };
};

export default ngModule("midas.admin.reportFormatters.createDialog", [])
  .decorator("$mdDialog", ["$delegate", ($mdDialog: angular.material.IDialogService) => {
    $mdDialog.showCreateFormatterPrompt =
      (examTypes) => $mdDialog.show(createOptions(examTypes));
    return $mdDialog;
  }
]);

declare module "angular" {
  export namespace material {
    interface IDialogService {
      /**
       * Prompts the user for properties required to create a new report formatter.
       * @param examTypes The exam types to present to the user.
       * @returns A promise of the chosen exam type to create a formatter for..
       */
      showCreateFormatterPrompt(examTypes: ExamType[]): IPromise<{examType: ExamType}>;
    }
  }
}