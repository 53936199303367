/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import * as angular from "angular";
angular.module("midas.utility.measurement.views").controller("IVCCtrl", ["$scope", function($scope) {
 console.log("IVC");
 $scope.findings =
  {open: true};
 const { values } = $scope.measurements;

 const canCalc = () => values.IVCMaxDiameter.value && values.IVCMinDiameter.value;

 const recalcIndex = function() { 
   if (!canCalc()) { return values.IVCCavalIndex.value = null;
   } else { return values.IVCCavalIndex.value = (values.IVCMaxDiameter.value - values.IVCMinDiameter.value)/(values.IVCMaxDiameter.value*100); }
 };

 $scope.$watch("measurements.values.IVCMaxDiameter.value", recalcIndex);
 return $scope.$watch("measurements.values.IVCMinDiameter.value", recalcIndex);
}
]);