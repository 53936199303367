import { MeasurementType } from '../../../businessModels';
import { BlueprintContextController } from '../blueprintContext.directive';

/** A condition expression in which an operator is applied to a single operand to produce a result. */
export interface IUnaryExpression {
  /** Unary expression type. */
  type: "unary-expression";

  /** The operand to operate against. */
  operand1: BlueprintExpression;

  /** The operator to apply to the operand. */
  operator: UnaryOperatorType;
}

/** A condition expression in which an operator is applied to a two operands to produce a result. */
export interface IBinaryExpression {
  /** Binary expression type. */
  type: "binary-expression";

  /** The left hand side of the binary expression. */
  operand1: BlueprintExpression;

  /** The right hand side of the binary expression. */
  operand2: BlueprintExpression;

  /** The operator to be applied to the operands. */
  operator: BinaryOperatorType;
}

/** An expression which evaluates to a literal string. */
export interface ILiteralString {
  /** Literal string expression type. */
  type: "literal-string";

  /** The literal string value. */
  value: string;
}

/** An expression which evaluates to a literal number. */
export interface ILiteralNumber {
  /** Literal number expression type. */
  type: "literal-number";

  /** The literal number value. */
  value: number;
}

/** An expression which evaluates to a measurement value. */
export interface IMeasurementTypeExpression {
  /** Measurement type expression. */
  type: "measurement-type";

  /** The measurement key to evaluate. */
  value: string;
}

/** An expression which evaluates whether a marker has been output in the current context. */
export interface ICheckMarkerExpression {
  /** The type of expression. */
  type: "check-marker";

  /** The marker key to check. */
  value: string;
}

/** An expression which can be used as an Operand or a evaluated literal value. */
export type BlueprintExpression =
  IUnaryExpression | IBinaryExpression |
  ILiteralNumber | ILiteralString | IMeasurementTypeExpression | ICheckMarkerExpression;

export type LiteralOperandType =
  "literal-string" | "literal-number" | "measurement-type" | "check-marker";
export type ConditionOperandType =
  "unary-expression" | "binary-expression" | LiteralOperandType;

export type UnaryOperatorType = "| exists" | "| doesNotExist";
export type BinaryNumberOperatorType = ">" | ">=" | "<" | "<=" | "+" | "-" | "*" | "/" | "===" | "!==";
export type BinaryExpressionOperatorType = "&&" | "||";
export type BinaryOperatorType = BinaryNumberOperatorType | BinaryExpressionOperatorType;
export type ExpressionOperatorType = UnaryOperatorType | BinaryOperatorType;

export type ExpressionResultType = "number" | "string" | "boolean";

/** Operators for testing the existence of something, like a measurement or marker. */
export const existentialOperators: ReadonlyArray<ExpressionOperatorType> = [
  "| exists", "| doesNotExist"
];
/** Operators for working with 2 numbers. */
export const binaryNumberOperators: ReadonlyArray<ExpressionOperatorType> = [
  "===","!==",">", ">=", "<", "<=", "+", "-", "*", "/"
];
/** Operators for comparing 2 string values. This is empty right now even though strings can be
 * compared for equality. The decision was made because we don't think users will want to check
 * comments and things for equality. The idea of text as something that can be compared is a fairly
 * programmer oriented thing. Strings with dropdown options are another matter. */
export const binaryStringOperators: ReadonlyArray<ExpressionOperatorType> = [ ];
/** Operators for comparing 2 string values where one operand is a measurement with a finite set of
 * choices. These often do need to be compared with equality. */
export const binaryStringMultipleChoiceOperators: ReadonlyArray<ExpressionOperatorType> = [
  "===", "!=="
];
/** Operators for combining 2 boolean values. */
export const binaryLogicalConnectives: ReadonlyArray<ExpressionOperatorType> = [
  "&&", "||"
];

/** Operators for comparing 2 strings, where the left value is a measurement. */
export const binaryStringMeasurementOperators = existentialOperators.concat(binaryStringOperators);
/** Operators for comparing 2 numbers, where the left value is a measurement. */
export const binaryNumberMeasurementOperators = existentialOperators.concat(binaryNumberOperators);

export const allUnaryOps = existentialOperators;
export const allBinaryOps = binaryNumberOperators.concat(binaryLogicalConnectives);

/** Type guard to determine whether an operator is a unary op. */
export function isUnaryOp(op: ExpressionOperatorType): op is UnaryOperatorType {
  return allUnaryOps.indexOf(op) >= 0;
}

/** Type guard to determine whether an operator is a binary op. */
export function isBinaryOp (op: ExpressionOperatorType): op is BinaryOperatorType {
  return allBinaryOps.indexOf(op) >= 0;
}

/** Gets the measurement type for an expression, or null. */
function getType(expr: IMeasurementTypeExpression, context?: BlueprintContextController): MeasurementType | null {
  if (context && expr) {
    const type = context.measurementTypes[expr.value];
    if (type) {
      return type;
    }
  }
  return null;
}

const anyType: ReadonlyArray<ExpressionResultType> = ["number", "string", "boolean"];

/** Checks whether the two type sets contain a matching member. If either is null then undefined is
 * returned, as we don't have enough information to make a meaningful comparison. */
export function typesMatch(a: ExpressionResultType | ReadonlyArray<ExpressionResultType>, b: ExpressionResultType | ReadonlyArray<ExpressionResultType>) {
  if (a && b) {
    if (typeof a === "string") {
      return (typeof b === "string") ? a === b : b.indexOf(a) >= 0;
    } else {
      return (typeof b === "string") ? a.indexOf(b) >= 0 : a === b;
    }
  }
  return undefined;
}

/** Get the types that are valid operands for an operation. */
export function getAllowedOperandTypes(op: ExpressionOperatorType): ExpressionResultType | ReadonlyArray<ExpressionResultType> {
  if (op == null) {
    return null;
  }

  switch (op) {
    case "!==":
    case "===":
      return anyType;
    case "&&":
    case "||":
      return "boolean";
    case ">":
    case "<":
    case ">=":
    case "<=":
    case "+":
    case "-":
    case "*":
    case "/":
      return "number";
    case "| exists":
    case "| doesNotExist":
      // These actually require expression operands of type "measurement-type" | "check-marker".
      // I'm not sure where the best place is to check these cases, but they're not "types", so
      // they don't seem to fit here.
      return null;
  }

  return null;
}

/** Works out the type of the result of an expression based on the various operators and operands.
 */
export function getResultType(expr: BlueprintExpression, context?: BlueprintContextController): ExpressionResultType | null {
  if (expr == null || expr.type == null) {
    return null;
  }

  switch (expr.type) {
    case "binary-expression":
      switch (expr.operator) {
        case "!==":
        case "===":
        case "&&":
        case "||":
        case ">":
        case "<":
        case ">=":
        case "<=":
          return "boolean";

        case "+":
        case "-":
        case "*":
        case "/":
          return "number";
      }
      break;

    case "unary-expression":
      return "boolean";

    case "literal-number":
      return "number";

    case "literal-string":
      return "string";

    case "measurement-type":
      const mt = getType(expr, context);
      if (mt) {
        switch (mt.dataType) {
          case "System.String":
            return "string";

          case "System.Single":
          case "System.Int32":
            return "number";
        }
      }
      break;

      // No `case "check-marker":` because it doesn't really have a type of it's own. Instead it
      // must be operated on by a unary exists or not exists operator.
  }

  return null;
};

/** Gets a read only array of expression operators which are applicable to the expression, based on
 * the types of the operand(s). */
export function getOperators (expr: IBinaryExpression | IUnaryExpression, context?: BlueprintContextController): ReadonlyArray<ExpressionOperatorType> {
  if (expr == null || expr.type == null) {
    return null;
  }

  // Always try to use the first operand type.
  if (expr.operand1 != null) {

    if (expr.operand1.type === "check-marker") {
      return existentialOperators;
    }

    switch (getResultType(expr.operand1, context)) {
      case "boolean": return binaryLogicalConnectives;
      case "string":
        if (expr.operand1.type === "measurement-type") {
          const mt = getType(expr.operand1, context);
          if (mt.dropdownOptions && mt.dropdownOptions.length > 0) {
            return binaryStringMultipleChoiceOperators;
          }
          return binaryStringMeasurementOperators;
        } else {
          return binaryStringOperators;
        }
      case "number":
        return expr.operand1.type === "measurement-type"
          ? binaryNumberMeasurementOperators
          : binaryNumberOperators;
    }
  }

  // Fall back on the second operand type if the first doesn't work.
  if (expr.type === "binary-expression" && expr.operand2 != null) {
    switch (getResultType(expr.operand2, context)) {
      case "boolean": return binaryLogicalConnectives;
      case "string": return binaryStringOperators;
      case "number": return binaryNumberOperators;
    }
  }

  return null;
};