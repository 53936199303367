import { Vector2, IPointLike } from "../../../utility/utils";
import { Shape } from "createjs";
import Line from "./line";
import { PatternService } from "../patterns";
import { coordExtent } from "../imageEditorVars";
import ArrowCap from "./arrowCap";
import RenderableShape, { shadowDefaultExtraThickness, shadowDefaultColour } from "./renderableShape";
import Circle from "./circle";

/** A point on a path of some kind which may have a cached normal. */
export type PointWithNormal = IPointLike & {
    /** The normal to a path at the point. */
    normal?: IPointLike;
};

/** An optionally filled spline which can draw itself to a canvas, including end caps. */
export default class Spline extends RenderableShape {
  /** The handle to the graphical representation of this shape. */
  ink: Shape;
  /** The spline segment knots. */
  points: Vector2[];
  /** The thickness of the spline. */
  thickness: number;
  /** The colour to use to draw the spline. */
  colour: string;
  /** The colour fill the spline, if it's filled. */
  backgroundColour: string;
  /** The colour to use to draw the shadow. */
  shadowColour: string = shadowDefaultColour;
  /** How much thicker the shadow should be compared to the shape. */
  shadowExtraThickness: number = shadowDefaultExtraThickness * coordExtent;
  /** Whether to draw an arrow on the start of the spline. */
  arrowStart: boolean;
  /** Whether to draw an arrow on the end of the spline. */
  arrowEnd: boolean;
  /** The key of the pattern to use to fill this spline. */
  pattern: string;
  /**
   * A factor (between 0 and 1) to reduce the size of curves by limiting the
   * distance of control points from anchor points. For example, z=.5 limits control points to
   * half the distance of the closer adjacent anchor point. Defaults to 0.5
   */
  tension: number;
  /**
   * Adjusts the size of curves depending on how acute the angle between points is. Curves are
   * reduced as acuteness increases, and this factor controls by how much.
   * 1 = curves are reduced in direct proportion to acuteness.
   * 0 = curves are not reduced at all based on acuteness in between = the reduction is basically a percentage of the full reduction.
   * Defaults to 0.75
   */
  angleDamping: number;
  /** Whether to draw the debug guides for the spline, which aid in visualising knots and control points. */
  drawDebugGuides: boolean;

  /**
   * Render a set of points as a spline. Also calculates normals for each point and stores them at
   * point.normal. These are useful for positioning UI elements outside the curve.
   * @param ink The graphics object to draw to.
   * @param pts The knot points of the spline, which the resulting spline will pass through. The
   * @param thickness
   * @param colour
   * @param secondaryColour
   * @param tension
   * @param angleDamping
   * @param pattern
   * @param arrowStart
   * @param arrowEnd
   * @param debugGuides
   */
  static render(ink: Shape, pts: (IPointLike & { normal?: IPointLike })[], thickness: number, colour: string, secondaryColour: string, tension: number = 0.5, angleDamping: number = 0.75, patternService?: PatternService, patternName: string = null, arrowStart: boolean = false, arrowEnd: boolean = false, arrowOverhang: number = 0, debugGuides: boolean = false) {
    let p: PointWithNormal;
    switch (pts.length) {
      case 0: break;
      case 1:
        p = pts[0];
        Circle.render(ink, p, thickness / 2, colour);
        if (p.normal != null) {
          p.normal.x = 0; p.normal.y = -1;
        } else {
          p.normal = new Vector2(0, -1);
        }
        break;
      case 2:
        Line.render(ink, pts, thickness, colour, arrowStart, arrowEnd, arrowOverhang);
        let calcNormals = (a: PointWithNormal, b: PointWithNormal) => {
          const tmp = new Vector2(a).subtract(b).normalise();
          if (a.normal != null) { tmp.writeTo(a.normal); } else { a.normal = tmp.clone(); }
          tmp.multiply(-1);
          if (b.normal != null) { tmp.writeTo(b.normal); } else { a.normal = tmp; }
        };
        calcNormals(pts[0], pts[1]);
        break;
      default:
        const spline = new SplineLayout(pts, patternService, tension, angleDamping);
        spline.renderTo(ink, thickness, colour, secondaryColour, patternName, arrowStart, arrowEnd, arrowOverhang);

        if (debugGuides) {
          spline.renderDebugGuides(ink);
        }
      }

    return ink;
  }

  render(drawShadow: boolean = false, target: Shape = this.ink) {
    target.graphics.clear();
    if (drawShadow) {
      if (this.points.length < 3) {
        Spline.render(target, this.points, this.thickness + this.shadowExtraThickness, this.shadowColour, "transparent", this.tension, this.angleDamping, null, null, this.arrowStart, this.arrowEnd, this.shadowExtraThickness / 2, false);
        Spline.render(target, this.points, this.thickness, this.colour, this.backgroundColour, this.tension, this.angleDamping, this.patternService, this.pattern, this.arrowStart, this.arrowEnd, 0, this.drawDebugGuides);
      } else {
        //Avoid calculating the spline layout twice when rendering a shadow.
        const spline = new SplineLayout(this.points, this.patternService, this.tension, this.angleDamping);
        spline.renderTo(target, this.thickness + this.shadowExtraThickness, this.shadowColour, "transparent", this.pattern, this.arrowStart, this.arrowEnd, this.shadowExtraThickness / 2);
        spline.renderTo(target, this.thickness, this.colour, this.backgroundColour, this.pattern, this.arrowStart, this.arrowEnd, 0);
        if (this.drawDebugGuides) {
          spline.renderDebugGuides(target);
        }
      }
      return target;
    }
    return Spline.render(target, this.points, this.thickness, this.colour, this.backgroundColour, this.tension, this.angleDamping, this.patternService, this.pattern, this.arrowStart, this.arrowEnd, 0, this.drawDebugGuides);
  }

  /**
   * Bakes the current ink position into the points and re-renders. Essentially shifts any offset from
   * the ink position to the actual pixel positions.
   */
  bakeTransform() {
    this.$trace && this.$trace("Spline.bakeTransform()");
    const m = this.ink.getMatrix(RenderableShape.ScratchMatrix);
    if (!m.isIdentity()) {
      for (var point of this.points) {
        m.transformPoint(point.x, point.y, point);
      }
      RenderableShape.resetInkTransform(this.ink);
    }
  }

  /**
   * Creates a new spline.
   * @param points The knot points of the spline.
   * @param drawStartArrow Whether to draw an arrow cap at the start of the spline.
   * @param drawEndArrow Whether to draw an arrow cap at the end of the spline.
   * @param thickness The thickness of the spline.
   * @param colour The colour of the spline.
   * @param secondaryColour The colour of the fill, if the spline is filled.
   * @param patternService If provided, this shape is able to fill itself with patterns.
   * @param pattern The pattern to fill the spline with.
   * @param tension A factor (between 0 and 1) to reduce the size of curves by limiting the
   * distance of control points from anchor points. For example, z=.5 limits control points to
   * half the distance of the closer adjacent anchor point.
   * @param angleDamping Adjusts the size of curves depending on how acute the angle between
   * points is. Curves are reduced as acuteness increases, and this factor controls by how much.
   * 1 = curves are reduced in direct proportion to acuteness
   * 0 = curves are not reduced at all based on acuteness
   * in between = the reduction is basically a percentage of the full reduction
   */
  constructor(
    points: IPointLike[],
    thickness: number,
    colour: string,
    secondaryColour: string,
    drawStartArrow: boolean,
    drawEndArrow: boolean,
    private readonly patternService: PatternService,
    pattern?: string,
    tension: number = 0.5,
    angleDamping: number = 0.75) {
      super();
    this.arrowStart = drawStartArrow;
    this.arrowEnd= drawEndArrow;
    this.thickness = thickness;
    this.colour = colour;
    this.backgroundColour = secondaryColour;
    this.pattern = pattern;
    this.tension = tension;
    this.angleDamping = angleDamping;
    this.ink = new Shape();
    this.ink.name = "Spline";
    this.points = points.map(p => new Vector2(p));
    this.drawDebugGuides = false;

    this.render();
  }
}
  
/** A set of control points and knots, created for a specific tension and angle damping factor
 * which can be used to render a spline. */
export class SplineLayout {
  /** Whether the first and last point of the spline are close enough to be considered equal. In
   * this case the spline is closed, and the control points are calculated such that the spline
   * is continuous between the start and end as well as it's length. */
  isClosed: boolean = false;
  /** List of control points before each point. */
  firstControls: IPointLike[];
  /** List of control points after each point. */
  secondControls: IPointLike[];
  /** The knots which make up this spline. The spline will pass through each of these, and the
   * control points are calculated based on these. */
  knots: PointWithNormal[];

  private static closest(toThis: PointWithNormal, a: PointWithNormal, b: PointWithNormal): PointWithNormal {
    let dx = toThis.x - a.x;
    let dy = toThis.y - a.y;
    const da = ((dx * dx) + (dy * dy));
    dx = toThis.x - b.x;
    dy = toThis.y - b.y;
    const db = ((dx * dx) + (dy * dy));
    return da < db ? a : b;
  };

  public renderTo(ink: Shape, thickness: number, colour: string, secondaryColour: string, patternName: string = null, arrowStart: boolean = false, arrowEnd: boolean = false, arrowOverhang: number = 0): void {
    let p = this.knots[0];
    let c0: IPointLike, c1: IPointLike;
    const g = ink.graphics;

    if (this.patternService) {
      this.patternService.beginFill(g, patternName, colour, secondaryColour);
    }

    //Begin the spline with an arrow if necessary.
    if (!this.isClosed && arrowStart) {
      const tip = new Vector2(p);
      if (arrowOverhang !== 0) {
        tip.add(new Vector2(p.normal).multiply(2 * arrowOverhang));
      }
      const base = thickness > 10 
        ? tip.clone().subtract(new Vector2(p.normal).multiply(thickness * 2 - arrowOverhang))
        : tip.clone().subtract(new Vector2(p.normal).multiply(20 - arrowOverhang));
      ArrowCap.render(ink, base, tip, thickness * 2 - arrowOverhang, colour);
      g.setStrokeStyle(thickness).beginStroke(colour);
      g.moveTo(base.x, base.y);
    } else {
      g.setStrokeStyle(thickness).beginStroke(colour);
      g.moveTo(p.x, p.y);
    }

    const end = this.knots.length - 1;
    for (let i = 1; i < end; i++) {
      c0 = this.secondControls[i - 1];
      c1 = this.firstControls[i];
      p = this.knots[i];
      g.bezierCurveTo(c0.x, c0.y, c1.x, c1.y, p.x, p.y);
    }

    c0 = this.secondControls[end - 1];
    c1 = this.firstControls[end];
    p = this.knots[end];
    //Finish the shape with an arrow if necessary.
    if (!this.isClosed && arrowEnd) {
      const tip = new Vector2(p);
      if (arrowOverhang !== 0) {
        tip.add(new Vector2(p.normal).multiply(arrowOverhang * 2));
      }
      const base = thickness > 10 
        ? tip.clone().subtract(new Vector2(p.normal).multiply(thickness * 2 - arrowOverhang))
        : tip.clone().subtract(new Vector2(p.normal).multiply(20 - arrowOverhang));
      g.bezierCurveTo(c0.x, c0.y, c1.x, c1.y, base.x, base.y);
      ArrowCap.render(ink, base, tip, thickness * 2 - arrowOverhang, colour);
    } else {
      g.bezierCurveTo(c0.x, c0.y, c1.x, c1.y, p.x, p.y);
    }

    g.endFill();
  }

  /**
   * Calculates 2 control points per knot for a cubic Bézier curve. When rendered as such the
   * resulting curve will touch all knots, and interpolate smoothly between them. If the first and
   * last point are the same, then the curve will be closed and continuous at all knots.
   * Note: I don't believe this is a "natural" cubic spline (although I could be wrong). I chose it
   * because it provides a tight curve with no real overshoot around tight corners, which makes it
   * feel easy to handle when a user is drawing with it.
   * @param knots The knots from which to calculate control points.
   * @param patternService If provided, this shape is able to fill itself with patterns.
   * @param tension The desired tension of the spline. Default is usually fine.
   * @param angleDamping How much to damp curves based on the acuteness of angles between segments.
   * @param equalityTolerance Distance between points within which they're considered equal.
   */
  constructor(knots: PointWithNormal[], private readonly patternService?: PatternService, tension: number = 0.5, angleDamping: number = 0.75, equalityTolerance: number = coordExtent * 0.005) {
    let p = this.knots = knots;
    // Make sure tension is between 0 and 1 (too messy otherwise)
    tension = tension < 0 ? 0 : tension > 1 ? 1 : tension;
    // Make sure angleFactor is between 0 and 1
    angleDamping = angleDamping < 0 ? 0 : angleDamping > 1 ? 1 : angleDamping;

    let len = p.length;
    if (len < 3) {
      throw new Error("Need at least 3 points to draw a spline");
    }

    function closest(toThis: PointWithNormal, a: PointWithNormal, b: PointWithNormal): PointWithNormal {
      let dx = toThis.x - a.x;
      let dy = toThis.y - a.y;
      const da = ((dx * dx) + (dy * dy));
      dx = toThis.x - b.x;
      dy = toThis.y - b.y;
      const db = ((dx * dx) + (dy * dy));
      return da < db ? a : b;
    };

    //Gets the distance between 2 points. Always a slow operation due to the square root.
    const dist = Vector2.distance;
    const equals = Vector2.equals;

    //Sets of first and second control points for each knot.
    let firstCtrl: IPointLike[] = this.firstControls = [null];
    let secondCtrl: IPointLike[] = this.secondControls = [null];


    function addCtrls(prev: PointWithNormal, current: PointWithNormal, next: PointWithNormal) {
      const dPrev = dist(prev, current);
      const dNext = dist(current, next);
      let C: number;
      {
        const dOp = dist(prev, next);
        let cos = (((dNext * dNext) + (dPrev * dPrev)) - (dOp * dOp)) / (2 * dNext * dPrev);
        cos = cos < 0 ? 0 : cos > 1 ? 1 : cos;
        C = Math.acos(cos); // Angle on current between prev and next.
      }

      const magnitude = Math.min(dPrev, dNext); //Length of shorter adjacent edge.

      const sumComp = new Vector2(prev).subtract(current).normalise()
        .add(new Vector2(next).subtract(current).normalise());

      const theta = Math.atan2(sumComp.y, sumComp.x);  // angle of the new vector

      //Set the curve normal at this point so that others can make use of it. It's a good place to
      //put knot controls for instance.
      sumComp.normalise().multiply(-1);
      if (current.normal != null) {
        sumComp.writeTo(current.normal);
      } else {
        current.normal = sumComp;
      }

      // Distance of curve control points from current point: a fraction the length of the shorter
      // adjacent triangle side
      let controlDist = magnitude * tension;
      // Scale the distance based on the acuteness of the angle. Prevents big loops around long,
      // sharp-angled triangles.
      const controlScaleFactor = C / Math.PI;
      // Mess with this for some fine-tuning
      controlDist *= ((1 - angleDamping) + (angleDamping * controlScaleFactor));
      // The angle from the current point to control points: the new vector angle plus 90 degrees (tangent to the curve).
      const controlAngle = theta + (Math.PI / 2);
      const ctrl1 = Vector2.fromPolar(controlDist, controlAngle).add(current);
      const ctrl2 = Vector2.fromPolar(controlDist, controlAngle + Math.PI).add(current);
      //Swap the control points if the second control point is closer to the previous knot. This
      //happens due the discontinuity of atan2 at (-1,0). I can't be bothered working out a neater
      //solution, and this is pretty simple and reasonably efficient.
      if (closest(prev, ctrl1, ctrl2) === ctrl2) {
        firstCtrl.push(ctrl2);
        return secondCtrl.push(ctrl1);
      } else {
        firstCtrl.push(ctrl1);
        return secondCtrl.push(ctrl2);
      }
    };

    // Loop through all the points (except the first and last if not a closed line) to get curve
    // control points for each.
    for (let i = 1, end = len - 1; i < end; i++) {
      addCtrls(p[i - 1], p[i], p[i + 1]);
    }

    //If first and last point can be considered equal then fix their control points so that they
    //appear to be the same point. Also calculate their normals for UI elements to use.
    if (equals(p[0], p[len - 1], equalityTolerance)) {
      this.isClosed = true;
      addCtrls(p[1], p[0], p[len - 2]);
      secondCtrl[0] = firstCtrl.pop();
      firstCtrl.push(secondCtrl.pop());

      knots[len - 1].normal = knots[0].normal; //Update normal on original final point too.
      //Use the same start and end point in a new array so we don't mess up the one we got passed
      p = this.knots = knots.slice();
      p[len - 1] = p[0];

    } else { //Otherwise make the end control points push towards the first internal control point.
      secondCtrl[0] = new Vector2(firstCtrl[1])
        .subtract(p[0])
        .multiply(tension)
        .add(p[0]);
      firstCtrl.push(new Vector2(secondCtrl[secondCtrl.length - 1])
        .subtract(p[len - 1])
        .multiply(tension)
        .add(p[len - 1]));
      let ctx = p[0];
      ctx.normal = new Vector2(secondCtrl[0]).subtract(ctx).normalise().multiply(-1);
      ctx = p[len - 1];
      ctx.normal = new Vector2(firstCtrl[len - 1]).subtract(ctx).normalise().multiply(-1);
    }
  }

  /** Renders debug guides for the knots and control points from this object. */
  renderDebugGuides(ink: Shape) {
    for (let i = 0; i < this.knots.length; i++) {
      const p = this.knots[i];
      if (p != null) {
        if (p.normal != null) {
          Line.render(ink, [new Vector2(p.normal).multiply(0.05).add(p), p], 0.001, "rebeccapurple");
        }
        if (this.firstControls[i] != null) {
          Line.render(ink, [this.firstControls[i], p], 0.001, "red");
        }
        if (this.secondControls[i] != null) {
          Line.render(ink, [this.secondControls[i], p], 0.001, "orange");
        }
      }
    }
  }
}