export interface IDicomTag {
  readonly vr: string;
  readonly name: string;
}
/* Taken from the npm package 'dicom-data-dictionary' */
export const dicomTags: { readonly [key: string]: IDicomTag } = {
  "00000000": { vr: "UL", name: "CommandGroupLength" },
  "00000001": { vr: "UL", name: "CommandLengthToEnd" },
  "00000002": { vr: "UI", name: "AffectedSOPClassUID" },
  "00000003": { vr: "UI", name: "RequestedSOPClassUID" },
  "00000010": { vr: "SH", name: "CommandRecognitionCode" },
  "00000100": { vr: "US", name: "CommandField" },
  "00000110": { vr: "US", name: "MessageID" },
  "00000120": { vr: "US", name: "MessageIDBeingRespondedTo" },
  "00000200": { vr: "AE", name: "Initiator" },
  "00000300": { vr: "AE", name: "Receiver" },
  "00000400": { vr: "AE", name: "FindLocation" },
  "00000600": { vr: "AE", name: "MoveDestination" },
  "00000700": { vr: "US", name: "Priority" },
  "00000800": { vr: "US", name: "CommandDataSetType" },
  "00000850": { vr: "US", name: "NumberOfMatches" },
  "00000860": { vr: "US", name: "ResponseSequenceNumber" },
  "00000900": { vr: "US", name: "Status" },
  "00000901": { vr: "AT", name: "OffendingElement" },
  "00000902": { vr: "LO", name: "ErrorComment" },
  "00000903": { vr: "US", name: "ErrorID" },
  "00001000": { vr: "UI", name: "AffectedSOPInstanceUID" },
  "00001001": { vr: "UI", name: "RequestedSOPInstanceUID" },
  "00001002": { vr: "US", name: "EventTypeID" },
  "00001005": { vr: "AT", name: "AttributeIdentifierList" },
  "00001008": { vr: "US", name: "ActionTypeID" },
  "00001020": { vr: "US", name: "NumberOfRemainingSuboperations" },
  "00001021": { vr: "US", name: "NumberOfCompletedSuboperations" },
  "00001022": { vr: "US", name: "NumberOfFailedSuboperations" },
  "00001023": { vr: "US", name: "NumberOfWarningSuboperations" },
  "00001030": { vr: "AE", name: "MoveOriginatorApplicationEntityTitle" },
  "00001031": { vr: "US", name: "MoveOriginatorMessageID" },
  "00004000": { vr: "LT", name: "DialogReceiver" },
  "00004010": { vr: "LT", name: "TerminalType" },
  "00005010": { vr: "SH", name: "MessageSetID" },
  "00005020": { vr: "SH", name: "EndMessageID" },
  "00005110": { vr: "LT", name: "DisplayFormat" },
  "00005120": { vr: "LT", name: "PagePositionID" },
  "00005130": { vr: "CS", name: "TextFormatID" },
  "00005140": { vr: "CS", name: "NormalReverse" },
  "00005150": { vr: "CS", name: "AddGrayScale" },
  "00005160": { vr: "CS", name: "Borders" },
  "00005170": { vr: "IS", name: "Copies" },
  "00005180": { vr: "CS", name: "CommandMagnificationType" },
  "00005190": { vr: "CS", name: "Erase" },
  "000051A0": { vr: "CS", name: "Print" },
  "000051B0": { vr: "US", name: "Overlays" },
  "00020000": { vr: "UL", name: "FileMetaInformationGroupLength" },
  "00020001": { vr: "OB", name: "FileMetaInformationVersion" },
  "00020002": { vr: "UI", name: "MediaStorageSOPClassUID" },
  "00020003": { vr: "UI", name: "MediaStorageSOPInstanceUID" },
  "00020010": { vr: "UI", name: "TransferSyntaxUID" },
  "00020012": { vr: "UI", name: "ImplementationClassUID" },
  "00020013": { vr: "SH", name: "ImplementationVersionName" },
  "00020016": { vr: "AE", name: "SourceApplicationEntityTitle" },
  "00020100": { vr: "UI", name: "PrivateInformationCreatorUID" },
  "00020102": { vr: "OB", name: "PrivateInformation" },
  "00041130": { vr: "CS", name: "FileSetID" },
  "00041141": { vr: "CS", name: "FileSetDescriptorFileID" },
  "00041142": { vr: "CS", name: "SpecificCharacterSetOfFileSetDescriptorFile" },
  "00041200": { vr: "UL", name: "OffsetOfTheFirstDirectoryRecordOfTheRootDirectoryEntity" },
  "00041202": { vr: "UL", name: "OffsetOfTheLastDirectoryRecordOfTheRootDirectoryEntity" },
  "00041212": { vr: "US", name: "FileSetConsistencyFlag" },
  "00041220": { vr: "SQ", name: "DirectoryRecordSequence" },
  "00041400": { vr: "UL", name: "OffsetOfTheNextDirectoryRecord" },
  "00041410": { vr: "US", name: "RecordInUseFlag" },
  "00041420": { vr: "UL", name: "OffsetOfReferencedLowerLevelDirectoryEntity" },
  "00041430": { vr: "CS", name: "DirectoryRecordType" },
  "00041432": { vr: "UI", name: "PrivateRecordUID" },
  "00041500": { vr: "CS", name: "ReferencedFileID" },
  "00041504": { vr: "UL", name: "MRDRDirectoryRecordOffset" },
  "00041510": { vr: "UI", name: "ReferencedSOPClassUIDInFile" },
  "00041511": { vr: "UI", name: "ReferencedSOPInstanceUIDInFile" },
  "00041512": { vr: "UI", name: "ReferencedTransferSyntaxUIDInFile" },
  "0004151A": { vr: "UI", name: "ReferencedRelatedGeneralSOPClassUIDInFile" },
  "00041600": { vr: "UL", name: "NumberOfReferences" },
  "00080001": { vr: "UL", name: "LengthToEnd" },
  "00080005": { vr: "CS", name: "SpecificCharacterSet" },
  "00080006": { vr: "SQ", name: "LanguageCodeSequence" },
  "00080008": { vr: "CS", name: "ImageType" },
  "00080010": { vr: "SH", name: "RecognitionCode" },
  "00080012": { vr: "DA", name: "InstanceCreationDate" },
  "00080013": { vr: "TM", name: "InstanceCreationTime" },
  "00080014": { vr: "UI", name: "InstanceCreatorUID" },
  "00080016": { vr: "UI", name: "SOPClassUID" },
  "00080018": { vr: "UI", name: "SOPInstanceUID" },
  "0008001A": { vr: "UI", name: "RelatedGeneralSOPClassUID" },
  "0008001B": { vr: "UI", name: "OriginalSpecializedSOPClassUID" },
  "00080020": { vr: "DA", name: "StudyDate" },
  "00080021": { vr: "DA", name: "SeriesDate" },
  "00080022": { vr: "DA", name: "AcquisitionDate" },
  "00080023": { vr: "DA", name: "ContentDate" },
  "00080024": { vr: "DA", name: "OverlayDate" },
  "00080025": { vr: "DA", name: "CurveDate" },
  "0008002A": { vr: "DT", name: "AcquisitionDateTime" },
  "00080030": { vr: "TM", name: "StudyTime" },
  "00080031": { vr: "TM", name: "SeriesTime" },
  "00080032": { vr: "TM", name: "AcquisitionTime" },
  "00080033": { vr: "TM", name: "ContentTime" },
  "00080034": { vr: "TM", name: "OverlayTime" },
  "00080035": { vr: "TM", name: "CurveTime" },
  "00080040": { vr: "US", name: "DataSetType" },
  "00080041": { vr: "LO", name: "DataSetSubtype" },
  "00080042": { vr: "CS", name: "NuclearMedicineSeriesType" },
  "00080050": { vr: "SH", name: "AccessionNumber" },
  "00080051": { vr: "SQ", name: "IssuerOfAccessionNumberSequence" },
  "00080052": { vr: "CS", name: "QueryRetrieveLevel" },
  "00080054": { vr: "AE", name: "RetrieveAETitle" },
  "00080056": { vr: "CS", name: "InstanceAvailability" },
  "00080058": { vr: "UI", name: "FailedSOPInstanceUIDList" },
  "00080060": { vr: "CS", name: "Modality" },
  "00080061": { vr: "CS", name: "ModalitiesInStudy" },
  "00080062": { vr: "UI", name: "SOPClassesInStudy" },
  "00080064": { vr: "CS", name: "ConversionType" },
  "00080068": { vr: "CS", name: "PresentationIntentType" },
  "00080070": { vr: "LO", name: "Manufacturer" },
  "00080080": { vr: "LO", name: "InstitutionName" },
  "00080081": { vr: "ST", name: "InstitutionAddress" },
  "00080082": { vr: "SQ", name: "InstitutionCodeSequence" },
  "00080090": { vr: "PN", name: "ReferringPhysicianName" },
  "00080092": { vr: "ST", name: "ReferringPhysicianAddress" },
  "00080094": { vr: "SH", name: "ReferringPhysicianTelephoneNumbers" },
  "00080096": { vr: "SQ", name: "ReferringPhysicianIdentificationSequence" },
  "00080100": { vr: "SH", name: "CodeValue" },
  "00080102": { vr: "SH", name: "CodingSchemeDesignator" },
  "00080103": { vr: "SH", name: "CodingSchemeVersion" },
  "00080104": { vr: "LO", name: "CodeMeaning" },
  "00080105": { vr: "CS", name: "MappingResource" },
  "00080106": { vr: "DT", name: "ContextGroupVersion" },
  "00080107": { vr: "DT", name: "ContextGroupLocalVersion" },
  "0008010B": { vr: "CS", name: "ContextGroupExtensionFlag" },
  "0008010C": { vr: "UI", name: "CodingSchemeUID" },
  "0008010D": { vr: "UI", name: "ContextGroupExtensionCreatorUID" },
  "0008010F": { vr: "CS", name: "ContextIdentifier" },
  "00080110": { vr: "SQ", name: "CodingSchemeIdentificationSequence" },
  "00080112": { vr: "LO", name: "CodingSchemeRegistry" },
  "00080114": { vr: "ST", name: "CodingSchemeExternalID" },
  "00080115": { vr: "ST", name: "CodingSchemeName" },
  "00080116": { vr: "ST", name: "CodingSchemeResponsibleOrganization" },
  "00080117": { vr: "UI", name: "ContextUID" },
  "00080201": { vr: "SH", name: "TimezoneOffsetFromUTC" },
  "00081000": { vr: "AE", name: "NetworkID" },
  "00081010": { vr: "SH", name: "StationName" },
  "00081030": { vr: "LO", name: "StudyDescription" },
  "00081032": { vr: "SQ", name: "ProcedureCodeSequence" },
  "0008103E": { vr: "LO", name: "SeriesDescription" },
  "0008103F": { vr: "SQ", name: "SeriesDescriptionCodeSequence" },
  "00081040": { vr: "LO", name: "InstitutionalDepartmentName" },
  "00081048": { vr: "PN", name: "PhysiciansOfRecord" },
  "00081049": { vr: "SQ", name: "PhysiciansOfRecordIdentificationSequence" },
  "00081050": { vr: "PN", name: "PerformingPhysicianName" },
  "00081052": { vr: "SQ", name: "PerformingPhysicianIdentificationSequence" },
  "00081060": { vr: "PN", name: "NameOfPhysiciansReadingStudy" },
  "00081062": { vr: "SQ", name: "PhysiciansReadingStudyIdentificationSequence" },
  "00081070": { vr: "PN", name: "OperatorsName" },
  "00081072": { vr: "SQ", name: "OperatorIdentificationSequence" },
  "00081080": { vr: "LO", name: "AdmittingDiagnosesDescription" },
  "00081084": { vr: "SQ", name: "AdmittingDiagnosesCodeSequence" },
  "00081090": { vr: "LO", name: "ManufacturerModelName" },
  "00081100": { vr: "SQ", name: "ReferencedResultsSequence" },
  "00081110": { vr: "SQ", name: "ReferencedStudySequence" },
  "00081111": { vr: "SQ", name: "ReferencedPerformedProcedureStepSequence" },
  "00081115": { vr: "SQ", name: "ReferencedSeriesSequence" },
  "00081120": { vr: "SQ", name: "ReferencedPatientSequence" },
  "00081125": { vr: "SQ", name: "ReferencedVisitSequence" },
  "00081130": { vr: "SQ", name: "ReferencedOverlaySequence" },
  "00081134": { vr: "SQ", name: "ReferencedStereometricInstanceSequence" },
  "0008113A": { vr: "SQ", name: "ReferencedWaveformSequence" },
  "00081140": { vr: "SQ", name: "ReferencedImageSequence" },
  "00081145": { vr: "SQ", name: "ReferencedCurveSequence" },
  "0008114A": { vr: "SQ", name: "ReferencedInstanceSequence" },
  "0008114B": { vr: "SQ", name: "ReferencedRealWorldValueMappingInstanceSequence" },
  "00081150": { vr: "UI", name: "ReferencedSOPClassUID" },
  "00081155": { vr: "UI", name: "ReferencedSOPInstanceUID" },
  "0008115A": { vr: "UI", name: "SOPClassesSupported" },
  "00081160": { vr: "IS", name: "ReferencedFrameNumber" },
  "00081161": { vr: "UL", name: "SimpleFrameList" },
  "00081162": { vr: "UL", name: "CalculatedFrameList" },
  "00081163": { vr: "FD", name: "TimeRange" },
  "00081164": { vr: "SQ", name: "FrameExtractionSequence" },
  "00081167": { vr: "UI", name: "MultiFrameSourceSOPInstanceUID" },
  "00081195": { vr: "UI", name: "TransactionUID" },
  "00081197": { vr: "US", name: "FailureReason" },
  "00081198": { vr: "SQ", name: "FailedSOPSequence" },
  "00081199": { vr: "SQ", name: "ReferencedSOPSequence" },
  "00081200": { vr: "SQ", name: "StudiesContainingOtherReferencedInstancesSequence" },
  "00081250": { vr: "SQ", name: "RelatedSeriesSequence" },
  "00082110": { vr: "CS", name: "LossyImageCompressionRetired" },
  "00082111": { vr: "ST", name: "DerivationDescription" },
  "00082112": { vr: "SQ", name: "SourceImageSequence" },
  "00082120": { vr: "SH", name: "StageName" },
  "00082122": { vr: "IS", name: "StageNumber" },
  "00082124": { vr: "IS", name: "NumberOfStages" },
  "00082127": { vr: "SH", name: "ViewName" },
  "00082128": { vr: "IS", name: "ViewNumber" },
  "00082129": { vr: "IS", name: "NumberOfEventTimers" },
  "0008212A": { vr: "IS", name: "NumberOfViewsInStage" },
  "00082130": { vr: "DS", name: "EventElapsedTimes" },
  "00082132": { vr: "LO", name: "EventTimerNames" },
  "00082133": { vr: "SQ", name: "EventTimerSequence" },
  "00082134": { vr: "FD", name: "EventTimeOffset" },
  "00082135": { vr: "SQ", name: "EventCodeSequence" },
  "00082142": { vr: "IS", name: "StartTrim" },
  "00082143": { vr: "IS", name: "StopTrim" },
  "00082144": { vr: "IS", name: "RecommendedDisplayFrameRate" },
  "00082200": { vr: "CS", name: "TransducerPosition" },
  "00082204": { vr: "CS", name: "TransducerOrientation" },
  "00082208": { vr: "CS", name: "AnatomicStructure" },
  "00082218": { vr: "SQ", name: "AnatomicRegionSequence" },
  "00082220": { vr: "SQ", name: "AnatomicRegionModifierSequence" },
  "00082228": { vr: "SQ", name: "PrimaryAnatomicStructureSequence" },
  "00082229": { vr: "SQ", name: "AnatomicStructureSpaceOrRegionSequence" },
  "00082230": { vr: "SQ", name: "PrimaryAnatomicStructureModifierSequence" },
  "00082240": { vr: "SQ", name: "TransducerPositionSequence" },
  "00082242": { vr: "SQ", name: "TransducerPositionModifierSequence" },
  "00082244": { vr: "SQ", name: "TransducerOrientationSequence" },
  "00082246": { vr: "SQ", name: "TransducerOrientationModifierSequence" },
  "00082251": { vr: "SQ", name: "AnatomicStructureSpaceOrRegionCodeSequenceTrial" },
  "00082253": { vr: "SQ", name: "AnatomicPortalOfEntranceCodeSequenceTrial" },
  "00082255": { vr: "SQ", name: "AnatomicApproachDirectionCodeSequenceTrial" },
  "00082256": { vr: "ST", name: "AnatomicPerspectiveDescriptionTrial" },
  "00082257": { vr: "SQ", name: "AnatomicPerspectiveCodeSequenceTrial" },
  "00082258": { vr: "ST", name: "AnatomicLocationOfExaminingInstrumentDescriptionTrial" },
  "00082259": { vr: "SQ", name: "AnatomicLocationOfExaminingInstrumentCodeSequenceTrial" },
  "0008225A": { vr: "SQ", name: "AnatomicStructureSpaceOrRegionModifierCodeSequenceTrial" },
  "0008225C": { vr: "SQ", name: "OnAxisBackgroundAnatomicStructureCodeSequenceTrial" },
  "00083001": { vr: "SQ", name: "AlternateRepresentationSequence" },
  "00083010": { vr: "UI", name: "IrradiationEventUID" },
  "00084000": { vr: "LT", name: "IdentifyingComments" },
  "00089007": { vr: "CS", name: "FrameType" },
  "00089092": { vr: "SQ", name: "ReferencedImageEvidenceSequence" },
  "00089121": { vr: "SQ", name: "ReferencedRawDataSequence" },
  "00089123": { vr: "UI", name: "CreatorVersionUID" },
  "00089124": { vr: "SQ", name: "DerivationImageSequence" },
  "00089154": { vr: "SQ", name: "SourceImageEvidenceSequence" },
  "00089205": { vr: "CS", name: "PixelPresentation" },
  "00089206": { vr: "CS", name: "VolumetricProperties" },
  "00089207": { vr: "CS", name: "VolumeBasedCalculationTechnique" },
  "00089208": { vr: "CS", name: "ComplexImageComponent" },
  "00089209": { vr: "CS", name: "AcquisitionContrast" },
  "00089215": { vr: "SQ", name: "DerivationCodeSequence" },
  "00089237": { vr: "SQ", name: "ReferencedPresentationStateSequence" },
  "00089410": { vr: "SQ", name: "ReferencedOtherPlaneSequence" },
  "00089458": { vr: "SQ", name: "FrameDisplaySequence" },
  "00089459": { vr: "FL", name: "RecommendedDisplayFrameRateInFloat" },
  "00089460": { vr: "CS", name: "SkipFrameRangeFlag" },
  "00100010": { vr: "PN", name: "PatientName" },
  "00100020": { vr: "LO", name: "PatientID" },
  "00100021": { vr: "LO", name: "IssuerOfPatientID" },
  "00100022": { vr: "CS", name: "TypeOfPatientID" },
  "00100024": { vr: "SQ", name: "IssuerOfPatientIDQualifiersSequence" },
  "00100030": { vr: "DA", name: "PatientBirthDate" },
  "00100032": { vr: "TM", name: "PatientBirthTime" },
  "00100040": { vr: "CS", name: "PatientSex" },
  "00100050": { vr: "SQ", name: "PatientInsurancePlanCodeSequence" },
  "00100101": { vr: "SQ", name: "PatientPrimaryLanguageCodeSequence" },
  "00100102": { vr: "SQ", name: "PatientPrimaryLanguageModifierCodeSequence" },
  "00101000": { vr: "LO", name: "OtherPatientIDs" },
  "00101001": { vr: "PN", name: "OtherPatientNames" },
  "00101002": { vr: "SQ", name: "OtherPatientIDsSequence" },
  "00101005": { vr: "PN", name: "PatientBirthName" },
  "00101010": { vr: "AS", name: "PatientAge" },
  "00101020": { vr: "DS", name: "PatientSize" },
  "00101021": { vr: "SQ", name: "PatientSizeCodeSequence" },
  "00101030": { vr: "DS", name: "PatientWeight" },
  "00101040": { vr: "LO", name: "PatientAddress" },
  "00101050": { vr: "LO", name: "InsurancePlanIdentification" },
  "00101060": { vr: "PN", name: "PatientMotherBirthName" },
  "00101080": { vr: "LO", name: "MilitaryRank" },
  "00101081": { vr: "LO", name: "BranchOfService" },
  "00101090": { vr: "LO", name: "MedicalRecordLocator" },
  "00102000": { vr: "LO", name: "MedicalAlerts" },
  "00102110": { vr: "LO", name: "Allergies" },
  "00102150": { vr: "LO", name: "CountryOfResidence" },
  "00102152": { vr: "LO", name: "RegionOfResidence" },
  "00102154": { vr: "SH", name: "PatientTelephoneNumbers" },
  "00102160": { vr: "SH", name: "EthnicGroup" },
  "00102180": { vr: "SH", name: "Occupation" },
  "001021A0": { vr: "CS", name: "SmokingStatus" },
  "001021B0": { vr: "LT", name: "AdditionalPatientHistory" },
  "001021C0": { vr: "US", name: "PregnancyStatus" },
  "001021D0": { vr: "DA", name: "LastMenstrualDate" },
  "001021F0": { vr: "LO", name: "PatientReligiousPreference" },
  "00102201": { vr: "LO", name: "PatientSpeciesDescription" },
  "00102202": { vr: "SQ", name: "PatientSpeciesCodeSequence" },
  "00102203": { vr: "CS", name: "PatientSexNeutered" },
  "00102210": { vr: "CS", name: "AnatomicalOrientationType" },
  "00102292": { vr: "LO", name: "PatientBreedDescription" },
  "00102293": { vr: "SQ", name: "PatientBreedCodeSequence" },
  "00102294": { vr: "SQ", name: "BreedRegistrationSequence" },
  "00102295": { vr: "LO", name: "BreedRegistrationNumber" },
  "00102296": { vr: "SQ", name: "BreedRegistryCodeSequence" },
  "00102297": { vr: "PN", name: "ResponsiblePerson" },
  "00102298": { vr: "CS", name: "ResponsiblePersonRole" },
  "00102299": { vr: "LO", name: "ResponsibleOrganization" },
  "00104000": { vr: "LT", name: "PatientComments" },
  "00109431": { vr: "FL", name: "ExaminedBodyThickness" },
  "00120010": { vr: "LO", name: "ClinicalTrialSponsorName" },
  "00120020": { vr: "LO", name: "ClinicalTrialProtocolID" },
  "00120021": { vr: "LO", name: "ClinicalTrialProtocolName" },
  "00120030": { vr: "LO", name: "ClinicalTrialSiteID" },
  "00120031": { vr: "LO", name: "ClinicalTrialSiteName" },
  "00120040": { vr: "LO", name: "ClinicalTrialSubjectID" },
  "00120042": { vr: "LO", name: "ClinicalTrialSubjectReadingID" },
  "00120050": { vr: "LO", name: "ClinicalTrialTimePointID" },
  "00120051": { vr: "ST", name: "ClinicalTrialTimePointDescription" },
  "00120060": { vr: "LO", name: "ClinicalTrialCoordinatingCenterName" },
  "00120062": { vr: "CS", name: "PatientIdentityRemoved" },
  "00120063": { vr: "LO", name: "DeidentificationMethod" },
  "00120064": { vr: "SQ", name: "DeidentificationMethodCodeSequence" },
  "00120071": { vr: "LO", name: "ClinicalTrialSeriesID" },
  "00120072": { vr: "LO", name: "ClinicalTrialSeriesDescription" },
  "00120081": { vr: "LO", name: "ClinicalTrialProtocolEthicsCommitteeName" },
  "00120082": { vr: "LO", name: "ClinicalTrialProtocolEthicsCommitteeApprovalNumber" },
  "00120083": { vr: "SQ", name: "ConsentForClinicalTrialUseSequence" },
  "00120084": { vr: "CS", name: "DistributionType" },
  "00120085": { vr: "CS", name: "ConsentForDistributionFlag" },
  "00140023": { vr: "ST", name: "CADFileFormat" },
  "00140024": { vr: "ST", name: "ComponentReferenceSystem" },
  "00140025": { vr: "ST", name: "ComponentManufacturingProcedure" },
  "00140028": { vr: "ST", name: "ComponentManufacturer" },
  "00140030": { vr: "DS", name: "MaterialThickness" },
  "00140032": { vr: "DS", name: "MaterialPipeDiameter" },
  "00140034": { vr: "DS", name: "MaterialIsolationDiameter" },
  "00140042": { vr: "ST", name: "MaterialGrade" },
  "00140044": { vr: "ST", name: "MaterialPropertiesFileID" },
  "00140045": { vr: "ST", name: "MaterialPropertiesFileFormat" },
  "00140046": { vr: "LT", name: "MaterialNotes" },
  "00140050": { vr: "CS", name: "ComponentShape" },
  "00140052": { vr: "CS", name: "CurvatureType" },
  "00140054": { vr: "DS", name: "OuterDiameter" },
  "00140056": { vr: "DS", name: "InnerDiameter" },
  "00141010": { vr: "ST", name: "ActualEnvironmentalConditions" },
  "00141020": { vr: "DA", name: "ExpiryDate" },
  "00141040": { vr: "ST", name: "EnvironmentalConditions" },
  "00142002": { vr: "SQ", name: "EvaluatorSequence" },
  "00142004": { vr: "IS", name: "EvaluatorNumber" },
  "00142006": { vr: "PN", name: "EvaluatorName" },
  "00142008": { vr: "IS", name: "EvaluationAttempt" },
  "00142012": { vr: "SQ", name: "IndicationSequence" },
  "00142014": { vr: "IS", name: "IndicationNumber " },
  "00142016": { vr: "SH", name: "IndicationLabel" },
  "00142018": { vr: "ST", name: "IndicationDescription" },
  "0014201A": { vr: "CS", name: "IndicationType" },
  "0014201C": { vr: "CS", name: "IndicationDisposition" },
  "0014201E": { vr: "SQ", name: "IndicationROISequence" },
  "00142030": { vr: "SQ", name: "IndicationPhysicalPropertySequence" },
  "00142032": { vr: "SH", name: "PropertyLabel" },
  "00142202": { vr: "IS", name: "CoordinateSystemNumberOfAxes " },
  "00142204": { vr: "SQ", name: "CoordinateSystemAxesSequence" },
  "00142206": { vr: "ST", name: "CoordinateSystemAxisDescription" },
  "00142208": { vr: "CS", name: "CoordinateSystemDataSetMapping" },
  "0014220A": { vr: "IS", name: "CoordinateSystemAxisNumber" },
  "0014220C": { vr: "CS", name: "CoordinateSystemAxisType" },
  "0014220E": { vr: "CS", name: "CoordinateSystemAxisUnits" },
  "00142210": { vr: "OB", name: "CoordinateSystemAxisValues" },
  "00142220": { vr: "SQ", name: "CoordinateSystemTransformSequence" },
  "00142222": { vr: "ST", name: "TransformDescription" },
  "00142224": { vr: "IS", name: "TransformNumberOfAxes" },
  "00142226": { vr: "IS", name: "TransformOrderOfAxes" },
  "00142228": { vr: "CS", name: "TransformedAxisUnits" },
  "0014222A": { vr: "DS", name: "CoordinateSystemTransformRotationAndScaleMatrix" },
  "0014222C": { vr: "DS", name: "CoordinateSystemTransformTranslationMatrix" },
  "00143011": { vr: "DS", name: "InternalDetectorFrameTime" },
  "00143012": { vr: "DS", name: "NumberOfFramesIntegrated" },
  "00143020": { vr: "SQ", name: "DetectorTemperatureSequence" },
  "00143022": { vr: "DS", name: "SensorName" },
  "00143024": { vr: "DS", name: "HorizontalOffsetOfSensor" },
  "00143026": { vr: "DS", name: "VerticalOffsetOfSensor" },
  "00143028": { vr: "DS", name: "SensorTemperature" },
  "00143040": { vr: "SQ", name: "DarkCurrentSequence" },
  "00143050": { vr: "OB|OW", name: "DarkCurrentCounts" },
  "00143060": { vr: "SQ", name: "GainCorrectionReferenceSequence" },
  "00143070": { vr: "OB|OW", name: "AirCounts" },
  "00143071": { vr: "DS", name: "KVUsedInGainCalibration" },
  "00143072": { vr: "DS", name: "MAUsedInGainCalibration" },
  "00143073": { vr: "DS", name: "NumberOfFramesUsedForIntegration" },
  "00143074": { vr: "LO", name: "FilterMaterialUsedInGainCalibration" },
  "00143075": { vr: "DS", name: "FilterThicknessUsedInGainCalibration" },
  "00143076": { vr: "DA", name: "DateOfGainCalibration" },
  "00143077": { vr: "TM", name: "TimeOfGainCalibration" },
  "00143080": { vr: "OB", name: "BadPixelImage" },
  "00143099": { vr: "LT", name: "CalibrationNotes" },
  "00144002": { vr: "SQ", name: "PulserEquipmentSequence" },
  "00144004": { vr: "CS", name: "PulserType" },
  "00144006": { vr: "LT", name: "PulserNotes" },
  "00144008": { vr: "SQ", name: "ReceiverEquipmentSequence" },
  "0014400A": { vr: "CS", name: "AmplifierType" },
  "0014400C": { vr: "LT", name: "ReceiverNotes" },
  "0014400E": { vr: "SQ", name: "PreAmplifierEquipmentSequence" },
  "0014400F": { vr: "LT", name: "PreAmplifierNotes" },
  "00144010": { vr: "SQ", name: "TransmitTransducerSequence" },
  "00144011": { vr: "SQ", name: "ReceiveTransducerSequence" },
  "00144012": { vr: "US", name: "NumberOfElements" },
  "00144013": { vr: "CS", name: "ElementShape" },
  "00144014": { vr: "DS", name: "ElementDimensionA" },
  "00144015": { vr: "DS", name: "ElementDimensionB" },
  "00144016": { vr: "DS", name: "ElementPitch" },
  "00144017": { vr: "DS", name: "MeasuredBeamDimensionA" },
  "00144018": { vr: "DS", name: "MeasuredBeamDimensionB" },
  "00144019": { vr: "DS", name: "LocationOfMeasuredBeamDiameter" },
  "0014401A": { vr: "DS", name: "NominalFrequency" },
  "0014401B": { vr: "DS", name: "MeasuredCenterFrequency" },
  "0014401C": { vr: "DS", name: "MeasuredBandwidth" },
  "00144020": { vr: "SQ", name: "PulserSettingsSequence" },
  "00144022": { vr: "DS", name: "PulseWidth" },
  "00144024": { vr: "DS", name: "ExcitationFrequency" },
  "00144026": { vr: "CS", name: "ModulationType" },
  "00144028": { vr: "DS", name: "Damping" },
  "00144030": { vr: "SQ", name: "ReceiverSettingsSequence" },
  "00144031": { vr: "DS", name: "AcquiredSoundpathLength" },
  "00144032": { vr: "CS", name: "AcquisitionCompressionType" },
  "00144033": { vr: "IS", name: "AcquisitionSampleSize" },
  "00144034": { vr: "DS", name: "RectifierSmoothing" },
  "00144035": { vr: "SQ", name: "DACSequence" },
  "00144036": { vr: "CS", name: "DACType" },
  "00144038": { vr: "DS", name: "DACGainPoints" },
  "0014403A": { vr: "DS", name: "DACTimePoints" },
  "0014403C": { vr: "DS", name: "DACAmplitude" },
  "00144040": { vr: "SQ", name: "PreAmplifierSettingsSequence" },
  "00144050": { vr: "SQ", name: "TransmitTransducerSettingsSequence" },
  "00144051": { vr: "SQ", name: "ReceiveTransducerSettingsSequence" },
  "00144052": { vr: "DS", name: "IncidentAngle" },
  "00144054": { vr: "ST", name: "CouplingTechnique" },
  "00144056": { vr: "ST", name: "CouplingMedium" },
  "00144057": { vr: "DS", name: "CouplingVelocity" },
  "00144058": { vr: "DS", name: "CrystalCenterLocationX" },
  "00144059": { vr: "DS", name: "CrystalCenterLocationZ" },
  "0014405A": { vr: "DS", name: "SoundPathLength" },
  "0014405C": { vr: "ST", name: "DelayLawIdentifier" },
  "00144060": { vr: "SQ", name: "GateSettingsSequence" },
  "00144062": { vr: "DS", name: "GateThreshold" },
  "00144064": { vr: "DS", name: "VelocityOfSound" },
  "00144070": { vr: "SQ", name: "CalibrationSettingsSequence" },
  "00144072": { vr: "ST", name: "CalibrationProcedure" },
  "00144074": { vr: "SH", name: "ProcedureVersion" },
  "00144076": { vr: "DA", name: "ProcedureCreationDate" },
  "00144078": { vr: "DA", name: "ProcedureExpirationDate" },
  "0014407A": { vr: "DA", name: "ProcedureLastModifiedDate" },
  "0014407C": { vr: "TM", name: "CalibrationTime" },
  "0014407E": { vr: "DA", name: "CalibrationDate" },
  "00145002": { vr: "IS", name: "LINACEnergy" },
  "00145004": { vr: "IS", name: "LINACOutput" },
  "00180010": { vr: "LO", name: "ContrastBolusAgent" },
  "00180012": { vr: "SQ", name: "ContrastBolusAgentSequence" },
  "00180014": { vr: "SQ", name: "ContrastBolusAdministrationRouteSequence" },
  "00180015": { vr: "CS", name: "BodyPartExamined" },
  "00180020": { vr: "CS", name: "ScanningSequence" },
  "00180021": { vr: "CS", name: "SequenceVariant" },
  "00180022": { vr: "CS", name: "ScanOptions" },
  "00180023": { vr: "CS", name: "MRAcquisitionType" },
  "00180024": { vr: "SH", name: "SequenceName" },
  "00180025": { vr: "CS", name: "AngioFlag" },
  "00180026": { vr: "SQ", name: "InterventionDrugInformationSequence" },
  "00180027": { vr: "TM", name: "InterventionDrugStopTime" },
  "00180028": { vr: "DS", name: "InterventionDrugDose" },
  "00180029": { vr: "SQ", name: "InterventionDrugCodeSequence" },
  "0018002A": { vr: "SQ", name: "AdditionalDrugSequence" },
  "00180030": { vr: "LO", name: "Radionuclide" },
  "00180031": { vr: "LO", name: "Radiopharmaceutical" },
  "00180032": { vr: "DS", name: "EnergyWindowCenterline" },
  "00180033": { vr: "DS", name: "EnergyWindowTotalWidth" },
  "00180034": { vr: "LO", name: "InterventionDrugName" },
  "00180035": { vr: "TM", name: "InterventionDrugStartTime" },
  "00180036": { vr: "SQ", name: "InterventionSequence" },
  "00180037": { vr: "CS", name: "TherapyType" },
  "00180038": { vr: "CS", name: "InterventionStatus" },
  "00180039": { vr: "CS", name: "TherapyDescription" },
  "0018003A": { vr: "ST", name: "InterventionDescription" },
  "00180040": { vr: "IS", name: "CineRate" },
  "00180042": { vr: "CS", name: "InitialCineRunState" },
  "00180050": { vr: "DS", name: "SliceThickness" },
  "00180060": { vr: "DS", name: "KVP" },
  "00180070": { vr: "IS", name: "CountsAccumulated" },
  "00180071": { vr: "CS", name: "AcquisitionTerminationCondition" },
  "00180072": { vr: "DS", name: "EffectiveDuration" },
  "00180073": { vr: "CS", name: "AcquisitionStartCondition" },
  "00180074": { vr: "IS", name: "AcquisitionStartConditionData" },
  "00180075": { vr: "IS", name: "AcquisitionTerminationConditionData" },
  "00180080": { vr: "DS", name: "RepetitionTime" },
  "00180081": { vr: "DS", name: "EchoTime" },
  "00180082": { vr: "DS", name: "InversionTime" },
  "00180083": { vr: "DS", name: "NumberOfAverages" },
  "00180084": { vr: "DS", name: "ImagingFrequency" },
  "00180085": { vr: "SH", name: "ImagedNucleus" },
  "00180086": { vr: "IS", name: "EchoNumbers" },
  "00180087": { vr: "DS", name: "MagneticFieldStrength" },
  "00180088": { vr: "DS", name: "SpacingBetweenSlices" },
  "00180089": { vr: "IS", name: "NumberOfPhaseEncodingSteps" },
  "00180090": { vr: "DS", name: "DataCollectionDiameter" },
  "00180091": { vr: "IS", name: "EchoTrainLength" },
  "00180093": { vr: "DS", name: "PercentSampling" },
  "00180094": { vr: "DS", name: "PercentPhaseFieldOfView" },
  "00180095": { vr: "DS", name: "PixelBandwidth" },
  "00181000": { vr: "LO", name: "DeviceSerialNumber" },
  "00181002": { vr: "UI", name: "DeviceUID" },
  "00181003": { vr: "LO", name: "DeviceID" },
  "00181004": { vr: "LO", name: "PlateID" },
  "00181005": { vr: "LO", name: "GeneratorID" },
  "00181006": { vr: "LO", name: "GridID" },
  "00181007": { vr: "LO", name: "CassetteID" },
  "00181008": { vr: "LO", name: "GantryID" },
  "00181010": { vr: "LO", name: "SecondaryCaptureDeviceID" },
  "00181011": { vr: "LO", name: "HardcopyCreationDeviceID" },
  "00181012": { vr: "DA", name: "DateOfSecondaryCapture" },
  "00181014": { vr: "TM", name: "TimeOfSecondaryCapture" },
  "00181016": { vr: "LO", name: "SecondaryCaptureDeviceManufacturer" },
  "00181017": { vr: "LO", name: "HardcopyDeviceManufacturer" },
  "00181018": { vr: "LO", name: "SecondaryCaptureDeviceManufacturerModelName" },
  "00181019": { vr: "LO", name: "SecondaryCaptureDeviceSoftwareVersions" },
  "0018101A": { vr: "LO", name: "HardcopyDeviceSoftwareVersion" },
  "0018101B": { vr: "LO", name: "HardcopyDeviceManufacturerModelName" },
  "00181020": { vr: "LO", name: "SoftwareVersions" },
  "00181022": { vr: "SH", name: "VideoImageFormatAcquired" },
  "00181023": { vr: "LO", name: "DigitalImageFormatAcquired" },
  "00181030": { vr: "LO", name: "ProtocolName" },
  "00181040": { vr: "LO", name: "ContrastBolusRoute" },
  "00181041": { vr: "DS", name: "ContrastBolusVolume" },
  "00181042": { vr: "TM", name: "ContrastBolusStartTime" },
  "00181043": { vr: "TM", name: "ContrastBolusStopTime" },
  "00181044": { vr: "DS", name: "ContrastBolusTotalDose" },
  "00181045": { vr: "IS", name: "SyringeCounts" },
  "00181046": { vr: "DS", name: "ContrastFlowRate" },
  "00181047": { vr: "DS", name: "ContrastFlowDuration" },
  "00181048": { vr: "CS", name: "ContrastBolusIngredient" },
  "00181049": { vr: "DS", name: "ContrastBolusIngredientConcentration" },
  "00181050": { vr: "DS", name: "SpatialResolution" },
  "00181060": { vr: "DS", name: "TriggerTime" },
  "00181061": { vr: "LO", name: "TriggerSourceOrType" },
  "00181062": { vr: "IS", name: "NominalInterval" },
  "00181063": { vr: "DS", name: "FrameTime" },
  "00181064": { vr: "LO", name: "CardiacFramingType" },
  "00181065": { vr: "DS", name: "FrameTimeVector" },
  "00181066": { vr: "DS", name: "FrameDelay" },
  "00181067": { vr: "DS", name: "ImageTriggerDelay" },
  "00181068": { vr: "DS", name: "MultiplexGroupTimeOffset" },
  "00181069": { vr: "DS", name: "TriggerTimeOffset" },
  "0018106A": { vr: "CS", name: "SynchronizationTrigger" },
  "0018106C": { vr: "US", name: "SynchronizationChannel" },
  "0018106E": { vr: "UL", name: "TriggerSamplePosition" },
  "00181070": { vr: "LO", name: "RadiopharmaceuticalRoute" },
  "00181071": { vr: "DS", name: "RadiopharmaceuticalVolume" },
  "00181072": { vr: "TM", name: "RadiopharmaceuticalStartTime" },
  "00181073": { vr: "TM", name: "RadiopharmaceuticalStopTime" },
  "00181074": { vr: "DS", name: "RadionuclideTotalDose" },
  "00181075": { vr: "DS", name: "RadionuclideHalfLife" },
  "00181076": { vr: "DS", name: "RadionuclidePositronFraction" },
  "00181077": { vr: "DS", name: "RadiopharmaceuticalSpecificActivity" },
  "00181078": { vr: "DT", name: "RadiopharmaceuticalStartDateTime" },
  "00181079": { vr: "DT", name: "RadiopharmaceuticalStopDateTime" },
  "00181080": { vr: "CS", name: "BeatRejectionFlag" },
  "00181081": { vr: "IS", name: "LowRRValue" },
  "00181082": { vr: "IS", name: "HighRRValue" },
  "00181083": { vr: "IS", name: "IntervalsAcquired" },
  "00181084": { vr: "IS", name: "IntervalsRejected" },
  "00181085": { vr: "LO", name: "PVCRejection" },
  "00181086": { vr: "IS", name: "SkipBeats" },
  "00181088": { vr: "IS", name: "HeartRate" },
  "00181090": { vr: "IS", name: "CardiacNumberOfImages" },
  "00181094": { vr: "IS", name: "TriggerWindow" },
  "00181100": { vr: "DS", name: "ReconstructionDiameter" },
  "00181110": { vr: "DS", name: "DistanceSourceToDetector" },
  "00181111": { vr: "DS", name: "DistanceSourceToPatient" },
  "00181114": { vr: "DS", name: "EstimatedRadiographicMagnificationFactor" },
  "00181120": { vr: "DS", name: "GantryDetectorTilt" },
  "00181121": { vr: "DS", name: "GantryDetectorSlew" },
  "00181130": { vr: "DS", name: "TableHeight" },
  "00181131": { vr: "DS", name: "TableTraverse" },
  "00181134": { vr: "CS", name: "TableMotion" },
  "00181135": { vr: "DS", name: "TableVerticalIncrement" },
  "00181136": { vr: "DS", name: "TableLateralIncrement" },
  "00181137": { vr: "DS", name: "TableLongitudinalIncrement" },
  "00181138": { vr: "DS", name: "TableAngle" },
  "0018113A": { vr: "CS", name: "TableType" },
  "00181140": { vr: "CS", name: "RotationDirection" },
  "00181141": { vr: "DS", name: "AngularPosition" },
  "00181142": { vr: "DS", name: "RadialPosition" },
  "00181143": { vr: "DS", name: "ScanArc" },
  "00181144": { vr: "DS", name: "AngularStep" },
  "00181145": { vr: "DS", name: "CenterOfRotationOffset" },
  "00181146": { vr: "DS", name: "RotationOffset" },
  "00181147": { vr: "CS", name: "FieldOfViewShape" },
  "00181149": { vr: "IS", name: "FieldOfViewDimensions" },
  "00181150": { vr: "IS", name: "ExposureTime" },
  "00181151": { vr: "IS", name: "XRayTubeCurrent" },
  "00181152": { vr: "IS", name: "Exposure" },
  "00181153": { vr: "IS", name: "ExposureInuAs" },
  "00181154": { vr: "DS", name: "AveragePulseWidth" },
  "00181155": { vr: "CS", name: "RadiationSetting" },
  "00181156": { vr: "CS", name: "RectificationType" },
  "0018115A": { vr: "CS", name: "RadiationMode" },
  "0018115E": { vr: "DS", name: "ImageAndFluoroscopyAreaDoseProduct" },
  "00181160": { vr: "SH", name: "FilterType" },
  "00181161": { vr: "LO", name: "TypeOfFilters" },
  "00181162": { vr: "DS", name: "IntensifierSize" },
  "00181164": { vr: "DS", name: "ImagerPixelSpacing" },
  "00181166": { vr: "CS", name: "Grid" },
  "00181170": { vr: "IS", name: "GeneratorPower" },
  "00181180": { vr: "SH", name: "CollimatorGridName" },
  "00181181": { vr: "CS", name: "CollimatorType" },
  "00181182": { vr: "IS", name: "FocalDistance" },
  "00181183": { vr: "DS", name: "XFocusCenter" },
  "00181184": { vr: "DS", name: "YFocusCenter" },
  "00181190": { vr: "DS", name: "FocalSpots" },
  "00181191": { vr: "CS", name: "AnodeTargetMaterial" },
  "001811A0": { vr: "DS", name: "BodyPartThickness" },
  "001811A2": { vr: "DS", name: "CompressionForce" },
  "00181200": { vr: "DA", name: "DateOfLastCalibration" },
  "00181201": { vr: "TM", name: "TimeOfLastCalibration" },
  "00181210": { vr: "SH", name: "ConvolutionKernel" },
  "00181240": { vr: "IS", name: "UpperLowerPixelValues" },
  "00181242": { vr: "IS", name: "ActualFrameDuration" },
  "00181243": { vr: "IS", name: "CountRate" },
  "00181244": { vr: "US", name: "PreferredPlaybackSequencing" },
  "00181250": { vr: "SH", name: "ReceiveCoilName" },
  "00181251": { vr: "SH", name: "TransmitCoilName" },
  "00181260": { vr: "SH", name: "PlateType" },
  "00181261": { vr: "LO", name: "PhosphorType" },
  "00181300": { vr: "DS", name: "ScanVelocity" },
  "00181301": { vr: "CS", name: "WholeBodyTechnique" },
  "00181302": { vr: "IS", name: "ScanLength" },
  "00181310": { vr: "US", name: "AcquisitionMatrix" },
  "00181312": { vr: "CS", name: "InPlanePhaseEncodingDirection" },
  "00181314": { vr: "DS", name: "FlipAngle" },
  "00181315": { vr: "CS", name: "VariableFlipAngleFlag" },
  "00181316": { vr: "DS", name: "SAR" },
  "00181318": { vr: "DS", name: "dBdt" },
  "00181400": { vr: "LO", name: "AcquisitionDeviceProcessingDescription" },
  "00181401": { vr: "LO", name: "AcquisitionDeviceProcessingCode" },
  "00181402": { vr: "CS", name: "CassetteOrientation" },
  "00181403": { vr: "CS", name: "CassetteSize" },
  "00181404": { vr: "US", name: "ExposuresOnPlate" },
  "00181405": { vr: "IS", name: "RelativeXRayExposure" },
  "00181411": { vr: "DS", name: "ExposureIndex" },
  "00181412": { vr: "DS", name: "TargetExposureIndex" },
  "00181413": { vr: "DS", name: "DeviationIndex" },
  "00181450": { vr: "DS", name: "ColumnAngulation" },
  "00181460": { vr: "DS", name: "TomoLayerHeight" },
  "00181470": { vr: "DS", name: "TomoAngle" },
  "00181480": { vr: "DS", name: "TomoTime" },
  "00181490": { vr: "CS", name: "TomoType" },
  "00181491": { vr: "CS", name: "TomoClass" },
  "00181495": { vr: "IS", name: "NumberOfTomosynthesisSourceImages" },
  "00181500": { vr: "CS", name: "PositionerMotion" },
  "00181508": { vr: "CS", name: "PositionerType" },
  "00181510": { vr: "DS", name: "PositionerPrimaryAngle" },
  "00181511": { vr: "DS", name: "PositionerSecondaryAngle" },
  "00181520": { vr: "DS", name: "PositionerPrimaryAngleIncrement" },
  "00181521": { vr: "DS", name: "PositionerSecondaryAngleIncrement" },
  "00181530": { vr: "DS", name: "DetectorPrimaryAngle" },
  "00181531": { vr: "DS", name: "DetectorSecondaryAngle" },
  "00181600": { vr: "CS", name: "ShutterShape" },
  "00181602": { vr: "IS", name: "ShutterLeftVerticalEdge" },
  "00181604": { vr: "IS", name: "ShutterRightVerticalEdge" },
  "00181606": { vr: "IS", name: "ShutterUpperHorizontalEdge" },
  "00181608": { vr: "IS", name: "ShutterLowerHorizontalEdge" },
  "00181610": { vr: "IS", name: "CenterOfCircularShutter" },
  "00181612": { vr: "IS", name: "RadiusOfCircularShutter" },
  "00181620": { vr: "IS", name: "VerticesOfThePolygonalShutter" },
  "00181622": { vr: "US", name: "ShutterPresentationValue" },
  "00181623": { vr: "US", name: "ShutterOverlayGroup" },
  "00181624": { vr: "US", name: "ShutterPresentationColorCIELabValue" },
  "00181700": { vr: "CS", name: "CollimatorShape" },
  "00181702": { vr: "IS", name: "CollimatorLeftVerticalEdge" },
  "00181704": { vr: "IS", name: "CollimatorRightVerticalEdge" },
  "00181706": { vr: "IS", name: "CollimatorUpperHorizontalEdge" },
  "00181708": { vr: "IS", name: "CollimatorLowerHorizontalEdge" },
  "00181710": { vr: "IS", name: "CenterOfCircularCollimator" },
  "00181712": { vr: "IS", name: "RadiusOfCircularCollimator" },
  "00181720": { vr: "IS", name: "VerticesOfThePolygonalCollimator" },
  "00181800": { vr: "CS", name: "AcquisitionTimeSynchronized" },
  "00181801": { vr: "SH", name: "TimeSource" },
  "00181802": { vr: "CS", name: "TimeDistributionProtocol" },
  "00181803": { vr: "LO", name: "NTPSourceAddress" },
  "00182001": { vr: "IS", name: "PageNumberVector" },
  "00182002": { vr: "SH", name: "FrameLabelVector" },
  "00182003": { vr: "DS", name: "FramePrimaryAngleVector" },
  "00182004": { vr: "DS", name: "FrameSecondaryAngleVector" },
  "00182005": { vr: "DS", name: "SliceLocationVector" },
  "00182006": { vr: "SH", name: "DisplayWindowLabelVector" },
  "00182010": { vr: "DS", name: "NominalScannedPixelSpacing" },
  "00182020": { vr: "CS", name: "DigitizingDeviceTransportDirection" },
  "00182030": { vr: "DS", name: "RotationOfScannedFilm" },
  "00183100": { vr: "CS", name: "IVUSAcquisition" },
  "00183101": { vr: "DS", name: "IVUSPullbackRate" },
  "00183102": { vr: "DS", name: "IVUSGatedRate" },
  "00183103": { vr: "IS", name: "IVUSPullbackStartFrameNumber" },
  "00183104": { vr: "IS", name: "IVUSPullbackStopFrameNumber" },
  "00183105": { vr: "IS", name: "LesionNumber" },
  "00184000": { vr: "LT", name: "AcquisitionComments" },
  "00185000": { vr: "SH", name: "OutputPower" },
  "00185010": { vr: "LO", name: "TransducerData" },
  "00185012": { vr: "DS", name: "FocusDepth" },
  "00185020": { vr: "LO", name: "ProcessingFunction" },
  "00185021": { vr: "LO", name: "PostprocessingFunction" },
  "00185022": { vr: "DS", name: "MechanicalIndex" },
  "00185024": { vr: "DS", name: "BoneThermalIndex" },
  "00185026": { vr: "DS", name: "CranialThermalIndex" },
  "00185027": { vr: "DS", name: "SoftTissueThermalIndex" },
  "00185028": { vr: "DS", name: "SoftTissueFocusThermalIndex" },
  "00185029": { vr: "DS", name: "SoftTissueSurfaceThermalIndex" },
  "00185030": { vr: "DS", name: "DynamicRange" },
  "00185040": { vr: "DS", name: "TotalGain" },
  "00185050": { vr: "IS", name: "DepthOfScanField" },
  "00185100": { vr: "CS", name: "PatientPosition" },
  "00185101": { vr: "CS", name: "ViewPosition" },
  "00185104": { vr: "SQ", name: "ProjectionEponymousNameCodeSequence" },
  "00185210": { vr: "DS", name: "ImageTransformationMatrix" },
  "00185212": { vr: "DS", name: "ImageTranslationVector" },
  "00186000": { vr: "DS", name: "Sensitivity" },
  "00186011": { vr: "SQ", name: "SequenceOfUltrasoundRegions" },
  "00186012": { vr: "US", name: "RegionSpatialFormat" },
  "00186014": { vr: "US", name: "RegionDataType" },
  "00186016": { vr: "UL", name: "RegionFlags" },
  "00186018": { vr: "UL", name: "RegionLocationMinX0" },
  "0018601A": { vr: "UL", name: "RegionLocationMinY0" },
  "0018601C": { vr: "UL", name: "RegionLocationMaxX1" },
  "0018601E": { vr: "UL", name: "RegionLocationMaxY1" },
  "00186020": { vr: "SL", name: "ReferencePixelX0" },
  "00186022": { vr: "SL", name: "ReferencePixelY0" },
  "00186024": { vr: "US", name: "PhysicalUnitsXDirection" },
  "00186026": { vr: "US", name: "PhysicalUnitsYDirection" },
  "00186028": { vr: "FD", name: "ReferencePixelPhysicalValueX" },
  "0018602A": { vr: "FD", name: "ReferencePixelPhysicalValueY" },
  "0018602C": { vr: "FD", name: "PhysicalDeltaX" },
  "0018602E": { vr: "FD", name: "PhysicalDeltaY" },
  "00186030": { vr: "UL", name: "TransducerFrequency" },
  "00186031": { vr: "CS", name: "TransducerType" },
  "00186032": { vr: "UL", name: "PulseRepetitionFrequency" },
  "00186034": { vr: "FD", name: "DopplerCorrectionAngle" },
  "00186036": { vr: "FD", name: "SteeringAngle" },
  "00186038": { vr: "UL", name: "DopplerSampleVolumeXPositionRetired" },
  "00186039": { vr: "SL", name: "DopplerSampleVolumeXPosition" },
  "0018603A": { vr: "UL", name: "DopplerSampleVolumeYPositionRetired" },
  "0018603B": { vr: "SL", name: "DopplerSampleVolumeYPosition" },
  "0018603C": { vr: "UL", name: "TMLinePositionX0Retired" },
  "0018603D": { vr: "SL", name: "TMLinePositionX0" },
  "0018603E": { vr: "UL", name: "TMLinePositionY0Retired" },
  "0018603F": { vr: "SL", name: "TMLinePositionY0" },
  "00186040": { vr: "UL", name: "TMLinePositionX1Retired" },
  "00186041": { vr: "SL", name: "TMLinePositionX1" },
  "00186042": { vr: "UL", name: "TMLinePositionY1Retired" },
  "00186043": { vr: "SL", name: "TMLinePositionY1" },
  "00186044": { vr: "US", name: "PixelComponentOrganization" },
  "00186046": { vr: "UL", name: "PixelComponentMask" },
  "00186048": { vr: "UL", name: "PixelComponentRangeStart" },
  "0018604A": { vr: "UL", name: "PixelComponentRangeStop" },
  "0018604C": { vr: "US", name: "PixelComponentPhysicalUnits" },
  "0018604E": { vr: "US", name: "PixelComponentDataType" },
  "00186050": { vr: "UL", name: "NumberOfTableBreakPoints" },
  "00186052": { vr: "UL", name: "TableOfXBreakPoints" },
  "00186054": { vr: "FD", name: "TableOfYBreakPoints" },
  "00186056": { vr: "UL", name: "NumberOfTableEntries" },
  "00186058": { vr: "UL", name: "TableOfPixelValues" },
  "0018605A": { vr: "FL", name: "TableOfParameterValues" },
  "00186060": { vr: "FL", name: "RWaveTimeVector" },
  "00187000": { vr: "CS", name: "DetectorConditionsNominalFlag" },
  "00187001": { vr: "DS", name: "DetectorTemperature" },
  "00187004": { vr: "CS", name: "DetectorType" },
  "00187005": { vr: "CS", name: "DetectorConfiguration" },
  "00187006": { vr: "LT", name: "DetectorDescription" },
  "00187008": { vr: "LT", name: "DetectorMode" },
  "0018700A": { vr: "SH", name: "DetectorID" },
  "0018700C": { vr: "DA", name: "DateOfLastDetectorCalibration" },
  "0018700E": { vr: "TM", name: "TimeOfLastDetectorCalibration" },
  "00187010": { vr: "IS", name: "ExposuresOnDetectorSinceLastCalibration" },
  "00187011": { vr: "IS", name: "ExposuresOnDetectorSinceManufactured" },
  "00187012": { vr: "DS", name: "DetectorTimeSinceLastExposure" },
  "00187014": { vr: "DS", name: "DetectorActiveTime" },
  "00187016": { vr: "DS", name: "DetectorActivationOffsetFromExposure" },
  "0018701A": { vr: "DS", name: "DetectorBinning" },
  "00187020": { vr: "DS", name: "DetectorElementPhysicalSize" },
  "00187022": { vr: "DS", name: "DetectorElementSpacing" },
  "00187024": { vr: "CS", name: "DetectorActiveShape" },
  "00187026": { vr: "DS", name: "DetectorActiveDimensions" },
  "00187028": { vr: "DS", name: "DetectorActiveOrigin" },
  "0018702A": { vr: "LO", name: "DetectorManufacturerName" },
  "0018702B": { vr: "LO", name: "DetectorManufacturerModelName" },
  "00187030": { vr: "DS", name: "FieldOfViewOrigin" },
  "00187032": { vr: "DS", name: "FieldOfViewRotation" },
  "00187034": { vr: "CS", name: "FieldOfViewHorizontalFlip" },
  "00187036": { vr: "FL", name: "PixelDataAreaOriginRelativeToFOV" },
  "00187038": { vr: "FL", name: "PixelDataAreaRotationAngleRelativeToFOV" },
  "00187040": { vr: "LT", name: "GridAbsorbingMaterial" },
  "00187041": { vr: "LT", name: "GridSpacingMaterial" },
  "00187042": { vr: "DS", name: "GridThickness" },
  "00187044": { vr: "DS", name: "GridPitch" },
  "00187046": { vr: "IS", name: "GridAspectRatio" },
  "00187048": { vr: "DS", name: "GridPeriod" },
  "0018704C": { vr: "DS", name: "GridFocalDistance" },
  "00187050": { vr: "CS", name: "FilterMaterial" },
  "00187052": { vr: "DS", name: "FilterThicknessMinimum" },
  "00187054": { vr: "DS", name: "FilterThicknessMaximum" },
  "00187056": { vr: "FL", name: "FilterBeamPathLengthMinimum" },
  "00187058": { vr: "FL", name: "FilterBeamPathLengthMaximum" },
  "00187060": { vr: "CS", name: "ExposureControlMode" },
  "00187062": { vr: "LT", name: "ExposureControlModeDescription" },
  "00187064": { vr: "CS", name: "ExposureStatus" },
  "00187065": { vr: "DS", name: "PhototimerSetting" },
  "00188150": { vr: "DS", name: "ExposureTimeInuS" },
  "00188151": { vr: "DS", name: "XRayTubeCurrentInuA" },
  "00189004": { vr: "CS", name: "ContentQualification" },
  "00189005": { vr: "SH", name: "PulseSequenceName" },
  "00189006": { vr: "SQ", name: "MRImagingModifierSequence" },
  "00189008": { vr: "CS", name: "EchoPulseSequence" },
  "00189009": { vr: "CS", name: "InversionRecovery" },
  "00189010": { vr: "CS", name: "FlowCompensation" },
  "00189011": { vr: "CS", name: "MultipleSpinEcho" },
  "00189012": { vr: "CS", name: "MultiPlanarExcitation" },
  "00189014": { vr: "CS", name: "PhaseContrast" },
  "00189015": { vr: "CS", name: "TimeOfFlightContrast" },
  "00189016": { vr: "CS", name: "Spoiling" },
  "00189017": { vr: "CS", name: "SteadyStatePulseSequence" },
  "00189018": { vr: "CS", name: "EchoPlanarPulseSequence" },
  "00189019": { vr: "FD", name: "TagAngleFirstAxis" },
  "00189020": { vr: "CS", name: "MagnetizationTransfer" },
  "00189021": { vr: "CS", name: "T2Preparation" },
  "00189022": { vr: "CS", name: "BloodSignalNulling" },
  "00189024": { vr: "CS", name: "SaturationRecovery" },
  "00189025": { vr: "CS", name: "SpectrallySelectedSuppression" },
  "00189026": { vr: "CS", name: "SpectrallySelectedExcitation" },
  "00189027": { vr: "CS", name: "SpatialPresaturation" },
  "00189028": { vr: "CS", name: "Tagging" },
  "00189029": { vr: "CS", name: "OversamplingPhase" },
  "00189030": { vr: "FD", name: "TagSpacingFirstDimension" },
  "00189032": { vr: "CS", name: "GeometryOfKSpaceTraversal" },
  "00189033": { vr: "CS", name: "SegmentedKSpaceTraversal" },
  "00189034": { vr: "CS", name: "RectilinearPhaseEncodeReordering" },
  "00189035": { vr: "FD", name: "TagThickness" },
  "00189036": { vr: "CS", name: "PartialFourierDirection" },
  "00189037": { vr: "CS", name: "CardiacSynchronizationTechnique" },
  "00189041": { vr: "LO", name: "ReceiveCoilManufacturerName" },
  "00189042": { vr: "SQ", name: "MRReceiveCoilSequence" },
  "00189043": { vr: "CS", name: "ReceiveCoilType" },
  "00189044": { vr: "CS", name: "QuadratureReceiveCoil" },
  "00189045": { vr: "SQ", name: "MultiCoilDefinitionSequence" },
  "00189046": { vr: "LO", name: "MultiCoilConfiguration" },
  "00189047": { vr: "SH", name: "MultiCoilElementName" },
  "00189048": { vr: "CS", name: "MultiCoilElementUsed" },
  "00189049": { vr: "SQ", name: "MRTransmitCoilSequence" },
  "00189050": { vr: "LO", name: "TransmitCoilManufacturerName" },
  "00189051": { vr: "CS", name: "TransmitCoilType" },
  "00189052": { vr: "FD", name: "SpectralWidth" },
  "00189053": { vr: "FD", name: "ChemicalShiftReference" },
  "00189054": { vr: "CS", name: "VolumeLocalizationTechnique" },
  "00189058": { vr: "US", name: "MRAcquisitionFrequencyEncodingSteps" },
  "00189059": { vr: "CS", name: "Decoupling" },
  "00189060": { vr: "CS", name: "DecoupledNucleus" },
  "00189061": { vr: "FD", name: "DecouplingFrequency" },
  "00189062": { vr: "CS", name: "DecouplingMethod" },
  "00189063": { vr: "FD", name: "DecouplingChemicalShiftReference" },
  "00189064": { vr: "CS", name: "KSpaceFiltering" },
  "00189065": { vr: "CS", name: "TimeDomainFiltering" },
  "00189066": { vr: "US", name: "NumberOfZeroFills" },
  "00189067": { vr: "CS", name: "BaselineCorrection" },
  "00189069": { vr: "FD", name: "ParallelReductionFactorInPlane" },
  "00189070": { vr: "FD", name: "CardiacRRIntervalSpecified" },
  "00189073": { vr: "FD", name: "AcquisitionDuration" },
  "00189074": { vr: "DT", name: "FrameAcquisitionDateTime" },
  "00189075": { vr: "CS", name: "DiffusionDirectionality" },
  "00189076": { vr: "SQ", name: "DiffusionGradientDirectionSequence" },
  "00189077": { vr: "CS", name: "ParallelAcquisition" },
  "00189078": { vr: "CS", name: "ParallelAcquisitionTechnique" },
  "00189079": { vr: "FD", name: "InversionTimes" },
  "00189080": { vr: "ST", name: "MetaboliteMapDescription" },
  "00189081": { vr: "CS", name: "PartialFourier" },
  "00189082": { vr: "FD", name: "EffectiveEchoTime" },
  "00189083": { vr: "SQ", name: "MetaboliteMapCodeSequence" },
  "00189084": { vr: "SQ", name: "ChemicalShiftSequence" },
  "00189085": { vr: "CS", name: "CardiacSignalSource" },
  "00189087": { vr: "FD", name: "DiffusionBValue" },
  "00189089": { vr: "FD", name: "DiffusionGradientOrientation" },
  "00189090": { vr: "FD", name: "VelocityEncodingDirection" },
  "00189091": { vr: "FD", name: "VelocityEncodingMinimumValue" },
  "00189092": { vr: "SQ", name: "VelocityEncodingAcquisitionSequence" },
  "00189093": { vr: "US", name: "NumberOfKSpaceTrajectories" },
  "00189094": { vr: "CS", name: "CoverageOfKSpace" },
  "00189095": { vr: "UL", name: "SpectroscopyAcquisitionPhaseRows" },
  "00189096": { vr: "FD", name: "ParallelReductionFactorInPlaneRetired" },
  "00189098": { vr: "FD", name: "TransmitterFrequency" },
  "00189100": { vr: "CS", name: "ResonantNucleus" },
  "00189101": { vr: "CS", name: "FrequencyCorrection" },
  "00189103": { vr: "SQ", name: "MRSpectroscopyFOVGeometrySequence" },
  "00189104": { vr: "FD", name: "SlabThickness" },
  "00189105": { vr: "FD", name: "SlabOrientation" },
  "00189106": { vr: "FD", name: "MidSlabPosition" },
  "00189107": { vr: "SQ", name: "MRSpatialSaturationSequence" },
  "00189112": { vr: "SQ", name: "MRTimingAndRelatedParametersSequence" },
  "00189114": { vr: "SQ", name: "MREchoSequence" },
  "00189115": { vr: "SQ", name: "MRModifierSequence" },
  "00189117": { vr: "SQ", name: "MRDiffusionSequence" },
  "00189118": { vr: "SQ", name: "CardiacSynchronizationSequence" },
  "00189119": { vr: "SQ", name: "MRAveragesSequence" },
  "00189125": { vr: "SQ", name: "MRFOVGeometrySequence" },
  "00189126": { vr: "SQ", name: "VolumeLocalizationSequence" },
  "00189127": { vr: "UL", name: "SpectroscopyAcquisitionDataColumns" },
  "00189147": { vr: "CS", name: "DiffusionAnisotropyType" },
  "00189151": { vr: "DT", name: "FrameReferenceDateTime" },
  "00189152": { vr: "SQ", name: "MRMetaboliteMapSequence" },
  "00189155": { vr: "FD", name: "ParallelReductionFactorOutOfPlane" },
  "00189159": { vr: "UL", name: "SpectroscopyAcquisitionOutOfPlanePhaseSteps" },
  "00189166": { vr: "CS", name: "BulkMotionStatus" },
  "00189168": { vr: "FD", name: "ParallelReductionFactorSecondInPlane" },
  "00189169": { vr: "CS", name: "CardiacBeatRejectionTechnique" },
  "00189170": { vr: "CS", name: "RespiratoryMotionCompensationTechnique" },
  "00189171": { vr: "CS", name: "RespiratorySignalSource" },
  "00189172": { vr: "CS", name: "BulkMotionCompensationTechnique" },
  "00189173": { vr: "CS", name: "BulkMotionSignalSource" },
  "00189174": { vr: "CS", name: "ApplicableSafetyStandardAgency" },
  "00189175": { vr: "LO", name: "ApplicableSafetyStandardDescription" },
  "00189176": { vr: "SQ", name: "OperatingModeSequence" },
  "00189177": { vr: "CS", name: "OperatingModeType" },
  "00189178": { vr: "CS", name: "OperatingMode" },
  "00189179": { vr: "CS", name: "SpecificAbsorptionRateDefinition" },
  "00189180": { vr: "CS", name: "GradientOutputType" },
  "00189181": { vr: "FD", name: "SpecificAbsorptionRateValue" },
  "00189182": { vr: "FD", name: "GradientOutput" },
  "00189183": { vr: "CS", name: "FlowCompensationDirection" },
  "00189184": { vr: "FD", name: "TaggingDelay" },
  "00189185": { vr: "ST", name: "RespiratoryMotionCompensationTechniqueDescription" },
  "00189186": { vr: "SH", name: "RespiratorySignalSourceID" },
  "00189195": { vr: "FD", name: "ChemicalShiftMinimumIntegrationLimitInHz" },
  "00189196": { vr: "FD", name: "ChemicalShiftMaximumIntegrationLimitInHz" },
  "00189197": { vr: "SQ", name: "MRVelocityEncodingSequence" },
  "00189198": { vr: "CS", name: "FirstOrderPhaseCorrection" },
  "00189199": { vr: "CS", name: "WaterReferencedPhaseCorrection" },
  "00189200": { vr: "CS", name: "MRSpectroscopyAcquisitionType" },
  "00189214": { vr: "CS", name: "RespiratoryCyclePosition" },
  "00189217": { vr: "FD", name: "VelocityEncodingMaximumValue" },
  "00189218": { vr: "FD", name: "TagSpacingSecondDimension" },
  "00189219": { vr: "SS", name: "TagAngleSecondAxis" },
  "00189220": { vr: "FD", name: "FrameAcquisitionDuration" },
  "00189226": { vr: "SQ", name: "MRImageFrameTypeSequence" },
  "00189227": { vr: "SQ", name: "MRSpectroscopyFrameTypeSequence" },
  "00189231": { vr: "US", name: "MRAcquisitionPhaseEncodingStepsInPlane" },
  "00189232": { vr: "US", name: "MRAcquisitionPhaseEncodingStepsOutOfPlane" },
  "00189234": { vr: "UL", name: "SpectroscopyAcquisitionPhaseColumns" },
  "00189236": { vr: "CS", name: "CardiacCyclePosition" },
  "00189239": { vr: "SQ", name: "SpecificAbsorptionRateSequence" },
  "00189240": { vr: "US", name: "RFEchoTrainLength" },
  "00189241": { vr: "US", name: "GradientEchoTrainLength" },
  "00189250": { vr: "CS", name: "ArterialSpinLabelingContrast" },
  "00189251": { vr: "SQ", name: "MRArterialSpinLabelingSequence" },
  "00189252": { vr: "LO", name: "ASLTechniqueDescription" },
  "00189253": { vr: "US", name: "ASLSlabNumber" },
  "00189254": { vr: "FD", name: "ASLSlabThickness" },
  "00189255": { vr: "FD", name: "ASLSlabOrientation" },
  "00189256": { vr: "FD", name: "ASLMidSlabPosition" },
  "00189257": { vr: "CS", name: "ASLContext" },
  "00189258": { vr: "UL", name: "ASLPulseTrainDuration" },
  "00189259": { vr: "CS", name: "ASLCrusherFlag" },
  "0018925A": { vr: "FD", name: "ASLCrusherFlow" },
  "0018925B": { vr: "LO", name: "ASLCrusherDescription" },
  "0018925C": { vr: "CS", name: "ASLBolusCutoffFlag" },
  "0018925D": { vr: "SQ", name: "ASLBolusCutoffTimingSequence" },
  "0018925E": { vr: "LO", name: "ASLBolusCutoffTechnique" },
  "0018925F": { vr: "UL", name: "ASLBolusCutoffDelayTime" },
  "00189260": { vr: "SQ", name: "ASLSlabSequence" },
  "00189295": { vr: "FD", name: "ChemicalShiftMinimumIntegrationLimitInppm" },
  "00189296": { vr: "FD", name: "ChemicalShiftMaximumIntegrationLimitInppm" },
  "00189301": { vr: "SQ", name: "CTAcquisitionTypeSequence" },
  "00189302": { vr: "CS", name: "AcquisitionType" },
  "00189303": { vr: "FD", name: "TubeAngle" },
  "00189304": { vr: "SQ", name: "CTAcquisitionDetailsSequence" },
  "00189305": { vr: "FD", name: "RevolutionTime" },
  "00189306": { vr: "FD", name: "SingleCollimationWidth" },
  "00189307": { vr: "FD", name: "TotalCollimationWidth" },
  "00189308": { vr: "SQ", name: "CTTableDynamicsSequence" },
  "00189309": { vr: "FD", name: "TableSpeed" },
  "00189310": { vr: "FD", name: "TableFeedPerRotation" },
  "00189311": { vr: "FD", name: "SpiralPitchFactor" },
  "00189312": { vr: "SQ", name: "CTGeometrySequence" },
  "00189313": { vr: "FD", name: "DataCollectionCenterPatient" },
  "00189314": { vr: "SQ", name: "CTReconstructionSequence" },
  "00189315": { vr: "CS", name: "ReconstructionAlgorithm" },
  "00189316": { vr: "CS", name: "ConvolutionKernelGroup" },
  "00189317": { vr: "FD", name: "ReconstructionFieldOfView" },
  "00189318": { vr: "FD", name: "ReconstructionTargetCenterPatient" },
  "00189319": { vr: "FD", name: "ReconstructionAngle" },
  "00189320": { vr: "SH", name: "ImageFilter" },
  "00189321": { vr: "SQ", name: "CTExposureSequence" },
  "00189322": { vr: "FD", name: "ReconstructionPixelSpacing" },
  "00189323": { vr: "CS", name: "ExposureModulationType" },
  "00189324": { vr: "FD", name: "EstimatedDoseSaving" },
  "00189325": { vr: "SQ", name: "CTXRayDetailsSequence" },
  "00189326": { vr: "SQ", name: "CTPositionSequence" },
  "00189327": { vr: "FD", name: "TablePosition" },
  "00189328": { vr: "FD", name: "ExposureTimeInms" },
  "00189329": { vr: "SQ", name: "CTImageFrameTypeSequence" },
  "00189330": { vr: "FD", name: "XRayTubeCurrentInmA" },
  "00189332": { vr: "FD", name: "ExposureInmAs" },
  "00189333": { vr: "CS", name: "ConstantVolumeFlag" },
  "00189334": { vr: "CS", name: "FluoroscopyFlag" },
  "00189335": { vr: "FD", name: "DistanceSourceToDataCollectionCenter" },
  "00189337": { vr: "US", name: "ContrastBolusAgentNumber" },
  "00189338": { vr: "SQ", name: "ContrastBolusIngredientCodeSequence" },
  "00189340": { vr: "SQ", name: "ContrastAdministrationProfileSequence" },
  "00189341": { vr: "SQ", name: "ContrastBolusUsageSequence" },
  "00189342": { vr: "CS", name: "ContrastBolusAgentAdministered" },
  "00189343": { vr: "CS", name: "ContrastBolusAgentDetected" },
  "00189344": { vr: "CS", name: "ContrastBolusAgentPhase" },
  "00189345": { vr: "FD", name: "CTDIvol" },
  "00189346": { vr: "SQ", name: "CTDIPhantomTypeCodeSequence" },
  "00189351": { vr: "FL", name: "CalciumScoringMassFactorPatient" },
  "00189352": { vr: "FL", name: "CalciumScoringMassFactorDevice" },
  "00189353": { vr: "FL", name: "EnergyWeightingFactor" },
  "00189360": { vr: "SQ", name: "CTAdditionalXRaySourceSequence" },
  "00189401": { vr: "SQ", name: "ProjectionPixelCalibrationSequence" },
  "00189402": { vr: "FL", name: "DistanceSourceToIsocenter" },
  "00189403": { vr: "FL", name: "DistanceObjectToTableTop" },
  "00189404": { vr: "FL", name: "ObjectPixelSpacingInCenterOfBeam" },
  "00189405": { vr: "SQ", name: "PositionerPositionSequence" },
  "00189406": { vr: "SQ", name: "TablePositionSequence" },
  "00189407": { vr: "SQ", name: "CollimatorShapeSequence" },
  "00189410": { vr: "CS", name: "PlanesInAcquisition" },
  "00189412": { vr: "SQ", name: "XAXRFFrameCharacteristicsSequence" },
  "00189417": { vr: "SQ", name: "FrameAcquisitionSequence" },
  "00189420": { vr: "CS", name: "XRayReceptorType" },
  "00189423": { vr: "LO", name: "AcquisitionProtocolName" },
  "00189424": { vr: "LT", name: "AcquisitionProtocolDescription" },
  "00189425": { vr: "CS", name: "ContrastBolusIngredientOpaque" },
  "00189426": { vr: "FL", name: "DistanceReceptorPlaneToDetectorHousing" },
  "00189427": { vr: "CS", name: "IntensifierActiveShape" },
  "00189428": { vr: "FL", name: "IntensifierActiveDimensions" },
  "00189429": { vr: "FL", name: "PhysicalDetectorSize" },
  "00189430": { vr: "FL", name: "PositionOfIsocenterProjection" },
  "00189432": { vr: "SQ", name: "FieldOfViewSequence" },
  "00189433": { vr: "LO", name: "FieldOfViewDescription" },
  "00189434": { vr: "SQ", name: "ExposureControlSensingRegionsSequence" },
  "00189435": { vr: "CS", name: "ExposureControlSensingRegionShape" },
  "00189436": { vr: "SS", name: "ExposureControlSensingRegionLeftVerticalEdge" },
  "00189437": { vr: "SS", name: "ExposureControlSensingRegionRightVerticalEdge" },
  "00189438": { vr: "SS", name: "ExposureControlSensingRegionUpperHorizontalEdge" },
  "00189439": { vr: "SS", name: "ExposureControlSensingRegionLowerHorizontalEdge" },
  "00189440": { vr: "SS", name: "CenterOfCircularExposureControlSensingRegion" },
  "00189441": { vr: "US", name: "RadiusOfCircularExposureControlSensingRegion" },
  "00189442": { vr: "SS", name: "VerticesOfThePolygonalExposureControlSensingRegion" },
  "00189447": { vr: "FL", name: "ColumnAngulationPatient" },
  "00189449": { vr: "FL", name: "BeamAngle" },
  "00189451": { vr: "SQ", name: "FrameDetectorParametersSequence" },
  "00189452": { vr: "FL", name: "CalculatedAnatomyThickness" },
  "00189455": { vr: "SQ", name: "CalibrationSequence" },
  "00189456": { vr: "SQ", name: "ObjectThicknessSequence" },
  "00189457": { vr: "CS", name: "PlaneIdentification" },
  "00189461": { vr: "FL", name: "FieldOfViewDimensionsInFloat" },
  "00189462": { vr: "SQ", name: "IsocenterReferenceSystemSequence" },
  "00189463": { vr: "FL", name: "PositionerIsocenterPrimaryAngle" },
  "00189464": { vr: "FL", name: "PositionerIsocenterSecondaryAngle" },
  "00189465": { vr: "FL", name: "PositionerIsocenterDetectorRotationAngle" },
  "00189466": { vr: "FL", name: "TableXPositionToIsocenter" },
  "00189467": { vr: "FL", name: "TableYPositionToIsocenter" },
  "00189468": { vr: "FL", name: "TableZPositionToIsocenter" },
  "00189469": { vr: "FL", name: "TableHorizontalRotationAngle" },
  "00189470": { vr: "FL", name: "TableHeadTiltAngle" },
  "00189471": { vr: "FL", name: "TableCradleTiltAngle" },
  "00189472": { vr: "SQ", name: "FrameDisplayShutterSequence" },
  "00189473": { vr: "FL", name: "AcquiredImageAreaDoseProduct" },
  "00189474": { vr: "CS", name: "CArmPositionerTabletopRelationship" },
  "00189476": { vr: "SQ", name: "XRayGeometrySequence" },
  "00189477": { vr: "SQ", name: "IrradiationEventIdentificationSequence" },
  "00189504": { vr: "SQ", name: "XRay3DFrameTypeSequence" },
  "00189506": { vr: "SQ", name: "ContributingSourcesSequence" },
  "00189507": { vr: "SQ", name: "XRay3DAcquisitionSequence" },
  "00189508": { vr: "FL", name: "PrimaryPositionerScanArc" },
  "00189509": { vr: "FL", name: "SecondaryPositionerScanArc" },
  "00189510": { vr: "FL", name: "PrimaryPositionerScanStartAngle" },
  "00189511": { vr: "FL", name: "SecondaryPositionerScanStartAngle" },
  "00189514": { vr: "FL", name: "PrimaryPositionerIncrement" },
  "00189515": { vr: "FL", name: "SecondaryPositionerIncrement" },
  "00189516": { vr: "DT", name: "StartAcquisitionDateTime" },
  "00189517": { vr: "DT", name: "EndAcquisitionDateTime" },
  "00189524": { vr: "LO", name: "ApplicationName" },
  "00189525": { vr: "LO", name: "ApplicationVersion" },
  "00189526": { vr: "LO", name: "ApplicationManufacturer" },
  "00189527": { vr: "CS", name: "AlgorithmType" },
  "00189528": { vr: "LO", name: "AlgorithmDescription" },
  "00189530": { vr: "SQ", name: "XRay3DReconstructionSequence" },
  "00189531": { vr: "LO", name: "ReconstructionDescription" },
  "00189538": { vr: "SQ", name: "PerProjectionAcquisitionSequence" },
  "00189601": { vr: "SQ", name: "DiffusionBMatrixSequence" },
  "00189602": { vr: "FD", name: "DiffusionBValueXX" },
  "00189603": { vr: "FD", name: "DiffusionBValueXY" },
  "00189604": { vr: "FD", name: "DiffusionBValueXZ" },
  "00189605": { vr: "FD", name: "DiffusionBValueYY" },
  "00189606": { vr: "FD", name: "DiffusionBValueYZ" },
  "00189607": { vr: "FD", name: "DiffusionBValueZZ" },
  "00189701": { vr: "DT", name: "DecayCorrectionDateTime" },
  "00189715": { vr: "FD", name: "StartDensityThreshold" },
  "00189716": { vr: "FD", name: "StartRelativeDensityDifferenceThreshold" },
  "00189717": { vr: "FD", name: "StartCardiacTriggerCountThreshold" },
  "00189718": { vr: "FD", name: "StartRespiratoryTriggerCountThreshold" },
  "00189719": { vr: "FD", name: "TerminationCountsThreshold" },
  "00189720": { vr: "FD", name: "TerminationDensityThreshold" },
  "00189721": { vr: "FD", name: "TerminationRelativeDensityThreshold" },
  "00189722": { vr: "FD", name: "TerminationTimeThreshold" },
  "00189723": { vr: "FD", name: "TerminationCardiacTriggerCountThreshold" },
  "00189724": { vr: "FD", name: "TerminationRespiratoryTriggerCountThreshold" },
  "00189725": { vr: "CS", name: "DetectorGeometry" },
  "00189726": { vr: "FD", name: "TransverseDetectorSeparation" },
  "00189727": { vr: "FD", name: "AxialDetectorDimension" },
  "00189729": { vr: "US", name: "RadiopharmaceuticalAgentNumber" },
  "00189732": { vr: "SQ", name: "PETFrameAcquisitionSequence" },
  "00189733": { vr: "SQ", name: "PETDetectorMotionDetailsSequence" },
  "00189734": { vr: "SQ", name: "PETTableDynamicsSequence" },
  "00189735": { vr: "SQ", name: "PETPositionSequence" },
  "00189736": { vr: "SQ", name: "PETFrameCorrectionFactorsSequence" },
  "00189737": { vr: "SQ", name: "RadiopharmaceuticalUsageSequence" },
  "00189738": { vr: "CS", name: "AttenuationCorrectionSource" },
  "00189739": { vr: "US", name: "NumberOfIterations" },
  "00189740": { vr: "US", name: "NumberOfSubsets" },
  "00189749": { vr: "SQ", name: "PETReconstructionSequence" },
  "00189751": { vr: "SQ", name: "PETFrameTypeSequence" },
  "00189755": { vr: "CS", name: "TimeOfFlightInformationUsed" },
  "00189756": { vr: "CS", name: "ReconstructionType" },
  "00189758": { vr: "CS", name: "DecayCorrected" },
  "00189759": { vr: "CS", name: "AttenuationCorrected" },
  "00189760": { vr: "CS", name: "ScatterCorrected" },
  "00189761": { vr: "CS", name: "DeadTimeCorrected" },
  "00189762": { vr: "CS", name: "GantryMotionCorrected" },
  "00189763": { vr: "CS", name: "PatientMotionCorrected" },
  "00189764": { vr: "CS", name: "CountLossNormalizationCorrected" },
  "00189765": { vr: "CS", name: "RandomsCorrected" },
  "00189766": { vr: "CS", name: "NonUniformRadialSamplingCorrected" },
  "00189767": { vr: "CS", name: "SensitivityCalibrated" },
  "00189768": { vr: "CS", name: "DetectorNormalizationCorrection" },
  "00189769": { vr: "CS", name: "IterativeReconstructionMethod" },
  "00189770": { vr: "CS", name: "AttenuationCorrectionTemporalRelationship" },
  "00189771": { vr: "SQ", name: "PatientPhysiologicalStateSequence" },
  "00189772": { vr: "SQ", name: "PatientPhysiologicalStateCodeSequence" },
  "00189801": { vr: "FD", name: "DepthsOfFocus" },
  "00189803": { vr: "SQ", name: "ExcludedIntervalsSequence" },
  "00189804": { vr: "DT", name: "ExclusionStartDatetime" },
  "00189805": { vr: "FD", name: "ExclusionDuration" },
  "00189806": { vr: "SQ", name: "USImageDescriptionSequence" },
  "00189807": { vr: "SQ", name: "ImageDataTypeSequence" },
  "00189808": { vr: "CS", name: "DataType" },
  "00189809": { vr: "SQ", name: "TransducerScanPatternCodeSequence" },
  "0018980B": { vr: "CS", name: "AliasedDataType" },
  "0018980C": { vr: "CS", name: "PositionMeasuringDeviceUsed" },
  "0018980D": { vr: "SQ", name: "TransducerGeometryCodeSequence" },
  "0018980E": { vr: "SQ", name: "TransducerBeamSteeringCodeSequence" },
  "0018980F": { vr: "SQ", name: "TransducerApplicationCodeSequence" },
  "0018A001": { vr: "SQ", name: "ContributingEquipmentSequence" },
  "0018A002": { vr: "DT", name: "ContributionDateTime" },
  "0018A003": { vr: "ST", name: "ContributionDescription" },
  "0020000D": { vr: "UI", name: "StudyInstanceUID" },
  "0020000E": { vr: "UI", name: "SeriesInstanceUID" },
  "00200010": { vr: "SH", name: "StudyID" },
  "00200011": { vr: "IS", name: "SeriesNumber" },
  "00200012": { vr: "IS", name: "AcquisitionNumber" },
  "00200013": { vr: "IS", name: "InstanceNumber" },
  "00200014": { vr: "IS", name: "IsotopeNumber" },
  "00200015": { vr: "IS", name: "PhaseNumber" },
  "00200016": { vr: "IS", name: "IntervalNumber" },
  "00200017": { vr: "IS", name: "TimeSlotNumber" },
  "00200018": { vr: "IS", name: "AngleNumber" },
  "00200019": { vr: "IS", name: "ItemNumber" },
  "00200020": { vr: "CS", name: "PatientOrientation" },
  "00200022": { vr: "IS", name: "OverlayNumber" },
  "00200024": { vr: "IS", name: "CurveNumber" },
  "00200026": { vr: "IS", name: "LUTNumber" },
  "00200030": { vr: "DS", name: "ImagePosition" },
  "00200032": { vr: "DS", name: "ImagePositionPatient" },
  "00200035": { vr: "DS", name: "ImageOrientation" },
  "00200037": { vr: "DS", name: "ImageOrientationPatient" },
  "00200050": { vr: "DS", name: "Location" },
  "00200052": { vr: "UI", name: "FrameOfReferenceUID" },
  "00200060": { vr: "CS", name: "Laterality" },
  "00200062": { vr: "CS", name: "ImageLaterality" },
  "00200070": { vr: "LO", name: "ImageGeometryType" },
  "00200080": { vr: "CS", name: "MaskingImage" },
  "002000AA": { vr: "IS", name: "ReportNumber" },
  "00200100": { vr: "IS", name: "TemporalPositionIdentifier" },
  "00200105": { vr: "IS", name: "NumberOfTemporalPositions" },
  "00200110": { vr: "DS", name: "TemporalResolution" },
  "00200200": { vr: "UI", name: "SynchronizationFrameOfReferenceUID" },
  "00200242": { vr: "UI", name: "SOPInstanceUIDOfConcatenationSource" },
  "00201000": { vr: "IS", name: "SeriesInStudy" },
  "00201001": { vr: "IS", name: "AcquisitionsInSeries" },
  "00201002": { vr: "IS", name: "ImagesInAcquisition" },
  "00201003": { vr: "IS", name: "ImagesInSeries" },
  "00201004": { vr: "IS", name: "AcquisitionsInStudy" },
  "00201005": { vr: "IS", name: "ImagesInStudy" },
  "00201020": { vr: "LO", name: "Reference" },
  "00201040": { vr: "LO", name: "PositionReferenceIndicator" },
  "00201041": { vr: "DS", name: "SliceLocation" },
  "00201070": { vr: "IS", name: "OtherStudyNumbers" },
  "00201200": { vr: "IS", name: "NumberOfPatientRelatedStudies" },
  "00201202": { vr: "IS", name: "NumberOfPatientRelatedSeries" },
  "00201204": { vr: "IS", name: "NumberOfPatientRelatedInstances" },
  "00201206": { vr: "IS", name: "NumberOfStudyRelatedSeries" },
  "00201208": { vr: "IS", name: "NumberOfStudyRelatedInstances" },
  "00201209": { vr: "IS", name: "NumberOfSeriesRelatedInstances" },
  "002031xx": { vr: "CS", name: "SourceImageIDs" },
  "00203401": { vr: "CS", name: "ModifyingDeviceID" },
  "00203402": { vr: "CS", name: "ModifiedImageID" },
  "00203403": { vr: "DA", name: "ModifiedImageDate" },
  "00203404": { vr: "LO", name: "ModifyingDeviceManufacturer" },
  "00203405": { vr: "TM", name: "ModifiedImageTime" },
  "00203406": { vr: "LO", name: "ModifiedImageDescription" },
  "00204000": { vr: "LT", name: "ImageComments" },
  "00205000": { vr: "AT", name: "OriginalImageIdentification" },
  "00205002": { vr: "LO", name: "OriginalImageIdentificationNomenclature" },
  "00209056": { vr: "SH", name: "StackID" },
  "00209057": { vr: "UL", name: "InStackPositionNumber" },
  "00209071": { vr: "SQ", name: "FrameAnatomySequence" },
  "00209072": { vr: "CS", name: "FrameLaterality" },
  "00209111": { vr: "SQ", name: "FrameContentSequence" },
  "00209113": { vr: "SQ", name: "PlanePositionSequence" },
  "00209116": { vr: "SQ", name: "PlaneOrientationSequence" },
  "00209128": { vr: "UL", name: "TemporalPositionIndex" },
  "00209153": { vr: "FD", name: "NominalCardiacTriggerDelayTime" },
  "00209154": { vr: "FL", name: "NominalCardiacTriggerTimePriorToRPeak" },
  "00209155": { vr: "FL", name: "ActualCardiacTriggerTimePriorToRPeak" },
  "00209156": { vr: "US", name: "FrameAcquisitionNumber" },
  "00209157": { vr: "UL", name: "DimensionIndexValues" },
  "00209158": { vr: "LT", name: "FrameComments" },
  "00209161": { vr: "UI", name: "ConcatenationUID" },
  "00209162": { vr: "US", name: "InConcatenationNumber" },
  "00209163": { vr: "US", name: "InConcatenationTotalNumber" },
  "00209164": { vr: "UI", name: "DimensionOrganizationUID" },
  "00209165": { vr: "AT", name: "DimensionIndexPointer" },
  "00209167": { vr: "AT", name: "FunctionalGroupPointer" },
  "00209213": { vr: "LO", name: "DimensionIndexPrivateCreator" },
  "00209221": { vr: "SQ", name: "DimensionOrganizationSequence" },
  "00209222": { vr: "SQ", name: "DimensionIndexSequence" },
  "00209228": { vr: "UL", name: "ConcatenationFrameOffsetNumber" },
  "00209238": { vr: "LO", name: "FunctionalGroupPrivateCreator" },
  "00209241": { vr: "FL", name: "NominalPercentageOfCardiacPhase" },
  "00209245": { vr: "FL", name: "NominalPercentageOfRespiratoryPhase" },
  "00209246": { vr: "FL", name: "StartingRespiratoryAmplitude" },
  "00209247": { vr: "CS", name: "StartingRespiratoryPhase" },
  "00209248": { vr: "FL", name: "EndingRespiratoryAmplitude" },
  "00209249": { vr: "CS", name: "EndingRespiratoryPhase" },
  "00209250": { vr: "CS", name: "RespiratoryTriggerType" },
  "00209251": { vr: "FD", name: "RRIntervalTimeNominal" },
  "00209252": { vr: "FD", name: "ActualCardiacTriggerDelayTime" },
  "00209253": { vr: "SQ", name: "RespiratorySynchronizationSequence" },
  "00209254": { vr: "FD", name: "RespiratoryIntervalTime" },
  "00209255": { vr: "FD", name: "NominalRespiratoryTriggerDelayTime" },
  "00209256": { vr: "FD", name: "RespiratoryTriggerDelayThreshold" },
  "00209257": { vr: "FD", name: "ActualRespiratoryTriggerDelayTime" },
  "00209301": { vr: "FD", name: "ImagePositionVolume" },
  "00209302": { vr: "FD", name: "ImageOrientationVolume" },
  "00209307": { vr: "CS", name: "UltrasoundAcquisitionGeometry" },
  "00209308": { vr: "FD", name: "ApexPosition" },
  "00209309": { vr: "FD", name: "VolumeToTransducerMappingMatrix" },
  "0020930A": { vr: "FD", name: "VolumeToTableMappingMatrix" },
  "0020930C": { vr: "CS", name: "PatientFrameOfReferenceSource" },
  "0020930D": { vr: "FD", name: "TemporalPositionTimeOffset" },
  "0020930E": { vr: "SQ", name: "PlanePositionVolumeSequence" },
  "0020930F": { vr: "SQ", name: "PlaneOrientationVolumeSequence" },
  "00209310": { vr: "SQ", name: "TemporalPositionSequence" },
  "00209311": { vr: "CS", name: "DimensionOrganizationType" },
  "00209312": { vr: "UI", name: "VolumeFrameOfReferenceUID" },
  "00209313": { vr: "UI", name: "TableFrameOfReferenceUID" },
  "00209421": { vr: "LO", name: "DimensionDescriptionLabel" },
  "00209450": { vr: "SQ", name: "PatientOrientationInFrameSequence" },
  "00209453": { vr: "LO", name: "FrameLabel" },
  "00209518": { vr: "US", name: "AcquisitionIndex" },
  "00209529": { vr: "SQ", name: "ContributingSOPInstancesReferenceSequence" },
  "00209536": { vr: "US", name: "ReconstructionIndex" },
  "00220001": { vr: "US", name: "LightPathFilterPassThroughWavelength" },
  "00220002": { vr: "US", name: "LightPathFilterPassBand" },
  "00220003": { vr: "US", name: "ImagePathFilterPassThroughWavelength" },
  "00220004": { vr: "US", name: "ImagePathFilterPassBand" },
  "00220005": { vr: "CS", name: "PatientEyeMovementCommanded" },
  "00220006": { vr: "SQ", name: "PatientEyeMovementCommandCodeSequence" },
  "00220007": { vr: "FL", name: "SphericalLensPower" },
  "00220008": { vr: "FL", name: "CylinderLensPower" },
  "00220009": { vr: "FL", name: "CylinderAxis" },
  "0022000A": { vr: "FL", name: "EmmetropicMagnification" },
  "0022000B": { vr: "FL", name: "IntraOcularPressure" },
  "0022000C": { vr: "FL", name: "HorizontalFieldOfView" },
  "0022000D": { vr: "CS", name: "PupilDilated" },
  "0022000E": { vr: "FL", name: "DegreeOfDilation" },
  "00220010": { vr: "FL", name: "StereoBaselineAngle" },
  "00220011": { vr: "FL", name: "StereoBaselineDisplacement" },
  "00220012": { vr: "FL", name: "StereoHorizontalPixelOffset" },
  "00220013": { vr: "FL", name: "StereoVerticalPixelOffset" },
  "00220014": { vr: "FL", name: "StereoRotation" },
  "00220015": { vr: "SQ", name: "AcquisitionDeviceTypeCodeSequence" },
  "00220016": { vr: "SQ", name: "IlluminationTypeCodeSequence" },
  "00220017": { vr: "SQ", name: "LightPathFilterTypeStackCodeSequence" },
  "00220018": { vr: "SQ", name: "ImagePathFilterTypeStackCodeSequence" },
  "00220019": { vr: "SQ", name: "LensesCodeSequence" },
  "0022001A": { vr: "SQ", name: "ChannelDescriptionCodeSequence" },
  "0022001B": { vr: "SQ", name: "RefractiveStateSequence" },
  "0022001C": { vr: "SQ", name: "MydriaticAgentCodeSequence" },
  "0022001D": { vr: "SQ", name: "RelativeImagePositionCodeSequence" },
  "0022001E": { vr: "FL", name: "CameraAngleOfView" },
  "00220020": { vr: "SQ", name: "StereoPairsSequence" },
  "00220021": { vr: "SQ", name: "LeftImageSequence" },
  "00220022": { vr: "SQ", name: "RightImageSequence" },
  "00220030": { vr: "FL", name: "AxialLengthOfTheEye" },
  "00220031": { vr: "SQ", name: "OphthalmicFrameLocationSequence" },
  "00220032": { vr: "FL", name: "ReferenceCoordinates" },
  "00220035": { vr: "FL", name: "DepthSpatialResolution" },
  "00220036": { vr: "FL", name: "MaximumDepthDistortion" },
  "00220037": { vr: "FL", name: "AlongScanSpatialResolution" },
  "00220038": { vr: "FL", name: "MaximumAlongScanDistortion" },
  "00220039": { vr: "CS", name: "OphthalmicImageOrientation" },
  "00220041": { vr: "FL", name: "DepthOfTransverseImage" },
  "00220042": { vr: "SQ", name: "MydriaticAgentConcentrationUnitsSequence" },
  "00220048": { vr: "FL", name: "AcrossScanSpatialResolution" },
  "00220049": { vr: "FL", name: "MaximumAcrossScanDistortion" },
  "0022004E": { vr: "DS", name: "MydriaticAgentConcentration" },
  "00220055": { vr: "FL", name: "IlluminationWaveLength" },
  "00220056": { vr: "FL", name: "IlluminationPower" },
  "00220057": { vr: "FL", name: "IlluminationBandwidth" },
  "00220058": { vr: "SQ", name: "MydriaticAgentSequence" },
  "00221007": { vr: "SQ", name: "OphthalmicAxialMeasurementsRightEyeSequence" },
  "00221008": { vr: "SQ", name: "OphthalmicAxialMeasurementsLeftEyeSequence" },
  "00221010": { vr: "CS", name: "OphthalmicAxialLengthMeasurementsType" },
  "00221019": { vr: "FL", name: "OphthalmicAxialLength" },
  "00221024": { vr: "SQ", name: "LensStatusCodeSequence" },
  "00221025": { vr: "SQ", name: "VitreousStatusCodeSequence" },
  "00221028": { vr: "SQ", name: "IOLFormulaCodeSequence" },
  "00221029": { vr: "LO", name: "IOLFormulaDetail" },
  "00221033": { vr: "FL", name: "KeratometerIndex" },
  "00221035": { vr: "SQ", name: "SourceOfOphthalmicAxialLengthCodeSequence" },
  "00221037": { vr: "FL", name: "TargetRefraction" },
  "00221039": { vr: "CS", name: "RefractiveProcedureOccurred" },
  "00221040": { vr: "SQ", name: "RefractiveSurgeryTypeCodeSequence" },
  "00221044": { vr: "SQ", name: "OphthalmicUltrasoundAxialMeasurementsTypeCodeSequence" },
  "00221050": { vr: "SQ", name: "OphthalmicAxialLengthMeasurementsSequence" },
  "00221053": { vr: "FL", name: "IOLPower" },
  "00221054": { vr: "FL", name: "PredictedRefractiveError" },
  "00221059": { vr: "FL", name: "OphthalmicAxialLengthVelocity" },
  "00221065": { vr: "LO", name: "LensStatusDescription" },
  "00221066": { vr: "LO", name: "VitreousStatusDescription" },
  "00221090": { vr: "SQ", name: "IOLPowerSequence" },
  "00221092": { vr: "SQ", name: "LensConstantSequence" },
  "00221093": { vr: "LO", name: "IOLManufacturer" },
  "00221094": { vr: "LO", name: "LensConstantDescription" },
  "00221096": { vr: "SQ", name: "KeratometryMeasurementTypeCodeSequence" },
  "00221100": { vr: "SQ", name: "ReferencedOphthalmicAxialMeasurementsSequence" },
  "00221101": { vr: "SQ", name: "OphthalmicAxialLengthMeasurementsSegmentNameCodeSequence" },
  "00221103": { vr: "SQ", name: "RefractiveErrorBeforeRefractiveSurgeryCodeSequence" },
  "00221121": { vr: "FL", name: "IOLPowerForExactEmmetropia" },
  "00221122": { vr: "FL", name: "IOLPowerForExactTargetRefraction" },
  "00221125": { vr: "SQ", name: "AnteriorChamberDepthDefinitionCodeSequence" },
  "00221130": { vr: "FL", name: "LensThickness" },
  "00221131": { vr: "FL", name: "AnteriorChamberDepth" },
  "00221132": { vr: "SQ", name: "SourceOfLensThicknessDataCodeSequence" },
  "00221133": { vr: "SQ", name: "SourceOfAnteriorChamberDepthDataCodeSequence" },
  "00221135": { vr: "SQ", name: "SourceOfRefractiveErrorDataCodeSequence" },
  "00221140": { vr: "CS", name: "OphthalmicAxialLengthMeasurementModified" },
  "00221150": { vr: "SQ", name: "OphthalmicAxialLengthDataSourceCodeSequence" },
  "00221153": { vr: "SQ", name: "OphthalmicAxialLengthAcquisitionMethodCodeSequence" },
  "00221155": { vr: "FL", name: "SignalToNoiseRatio" },
  "00221159": { vr: "LO", name: "OphthalmicAxialLengthDataSourceDescription" },
  "00221210": { vr: "SQ", name: "OphthalmicAxialLengthMeasurementsTotalLengthSequence" },
  "00221211": { vr: "SQ", name: "OphthalmicAxialLengthMeasurementsSegmentalLengthSequence" },
  "00221212": { vr: "SQ", name: "OphthalmicAxialLengthMeasurementsLengthSummationSequence" },
  "00221220": { vr: "SQ", name: "UltrasoundOphthalmicAxialLengthMeasurementsSequence" },
  "00221225": { vr: "SQ", name: "OpticalOphthalmicAxialLengthMeasurementsSequence" },
  "00221230": { vr: "SQ", name: "UltrasoundSelectedOphthalmicAxialLengthSequence" },
  "00221250": { vr: "SQ", name: "OphthalmicAxialLengthSelectionMethodCodeSequence" },
  "00221255": { vr: "SQ", name: "OpticalSelectedOphthalmicAxialLengthSequence" },
  "00221257": { vr: "SQ", name: "SelectedSegmentalOphthalmicAxialLengthSequence" },
  "00221260": { vr: "SQ", name: "SelectedTotalOphthalmicAxialLengthSequence" },
  "00221262": { vr: "SQ", name: "OphthalmicAxialLengthQualityMetricSequence" },
  "00221273": { vr: "LO", name: "OphthalmicAxialLengthQualityMetricTypeDescription" },
  "00221300": { vr: "SQ", name: "IntraocularLensCalculationsRightEyeSequence" },
  "00221310": { vr: "SQ", name: "IntraocularLensCalculationsLeftEyeSequence" },
  "00221330": { vr: "SQ", name: "ReferencedOphthalmicAxialLengthMeasurementQCImageSequence" },
  "00240010": { vr: "FL", name: "VisualFieldHorizontalExtent" },
  "00240011": { vr: "FL", name: "VisualFieldVerticalExtent" },
  "00240012": { vr: "CS", name: "VisualFieldShape" },
  "00240016": { vr: "SQ", name: "ScreeningTestModeCodeSequence" },
  "00240018": { vr: "FL", name: "MaximumStimulusLuminance" },
  "00240020": { vr: "FL", name: "BackgroundLuminance" },
  "00240021": { vr: "SQ", name: "StimulusColorCodeSequence" },
  "00240024": { vr: "SQ", name: "BackgroundIlluminationColorCodeSequence" },
  "00240025": { vr: "FL", name: "StimulusArea" },
  "00240028": { vr: "FL", name: "StimulusPresentationTime" },
  "00240032": { vr: "SQ", name: "FixationSequence" },
  "00240033": { vr: "SQ", name: "FixationMonitoringCodeSequence" },
  "00240034": { vr: "SQ", name: "VisualFieldCatchTrialSequence" },
  "00240035": { vr: "US", name: "FixationCheckedQuantity" },
  "00240036": { vr: "US", name: "PatientNotProperlyFixatedQuantity" },
  "00240037": { vr: "CS", name: "PresentedVisualStimuliDataFlag" },
  "00240038": { vr: "US", name: "NumberOfVisualStimuli" },
  "00240039": { vr: "CS", name: "ExcessiveFixationLossesDataFlag" },
  "00240040": { vr: "CS", name: "ExcessiveFixationLosses" },
  "00240042": { vr: "US", name: "StimuliRetestingQuantity" },
  "00240044": { vr: "LT", name: "CommentsOnPatientPerformanceOfVisualField" },
  "00240045": { vr: "CS", name: "FalseNegativesEstimateFlag" },
  "00240046": { vr: "FL", name: "FalseNegativesEstimate" },
  "00240048": { vr: "US", name: "NegativeCatchTrialsQuantity" },
  "00240050": { vr: "US", name: "FalseNegativesQuantity" },
  "00240051": { vr: "CS", name: "ExcessiveFalseNegativesDataFlag" },
  "00240052": { vr: "CS", name: "ExcessiveFalseNegatives" },
  "00240053": { vr: "CS", name: "FalsePositivesEstimateFlag" },
  "00240054": { vr: "FL", name: "FalsePositivesEstimate" },
  "00240055": { vr: "CS", name: "CatchTrialsDataFlag" },
  "00240056": { vr: "US", name: "PositiveCatchTrialsQuantity" },
  "00240057": { vr: "CS", name: "TestPointNormalsDataFlag" },
  "00240058": { vr: "SQ", name: "TestPointNormalsSequence" },
  "00240059": { vr: "CS", name: "GlobalDeviationProbabilityNormalsFlag" },
  "00240060": { vr: "US", name: "FalsePositivesQuantity" },
  "00240061": { vr: "CS", name: "ExcessiveFalsePositivesDataFlag" },
  "00240062": { vr: "CS", name: "ExcessiveFalsePositives" },
  "00240063": { vr: "CS", name: "VisualFieldTestNormalsFlag" },
  "00240064": { vr: "SQ", name: "ResultsNormalsSequence" },
  "00240065": { vr: "SQ", name: "AgeCorrectedSensitivityDeviationAlgorithmSequence" },
  "00240066": { vr: "FL", name: "GlobalDeviationFromNormal" },
  "00240067": { vr: "SQ", name: "GeneralizedDefectSensitivityDeviationAlgorithmSequence" },
  "00240068": { vr: "FL", name: "LocalizedDeviationfromNormal" },
  "00240069": { vr: "LO", name: "PatientReliabilityIndicator" },
  "00240070": { vr: "FL", name: "VisualFieldMeanSensitivity" },
  "00240071": { vr: "FL", name: "GlobalDeviationProbability" },
  "00240072": { vr: "CS", name: "LocalDeviationProbabilityNormalsFlag" },
  "00240073": { vr: "FL", name: "LocalizedDeviationProbability" },
  "00240074": { vr: "CS", name: "ShortTermFluctuationCalculated" },
  "00240075": { vr: "FL", name: "ShortTermFluctuation" },
  "00240076": { vr: "CS", name: "ShortTermFluctuationProbabilityCalculated" },
  "00240077": { vr: "FL", name: "ShortTermFluctuationProbability" },
  "00240078": { vr: "CS", name: "CorrectedLocalizedDeviationFromNormalCalculated" },
  "00240079": { vr: "FL", name: "CorrectedLocalizedDeviationFromNormal" },
  "00240080": { vr: "CS", name: "CorrectedLocalizedDeviationFromNormalProbabilityCalculated" },
  "00240081": { vr: "FL", name: "CorrectedLocalizedDeviationFromNormalProbability" },
  "00240083": { vr: "SQ", name: "GlobalDeviationProbabilitySequence" },
  "00240085": { vr: "SQ", name: "LocalizedDeviationProbabilitySequence" },
  "00240086": { vr: "CS", name: "FovealSensitivityMeasured" },
  "00240087": { vr: "FL", name: "FovealSensitivity" },
  "00240088": { vr: "FL", name: "VisualFieldTestDuration" },
  "00240089": { vr: "SQ", name: "VisualFieldTestPointSequence" },
  "00240090": { vr: "FL", name: "VisualFieldTestPointXCoordinate" },
  "00240091": { vr: "FL", name: "VisualFieldTestPointYCoordinate" },
  "00240092": { vr: "FL", name: "AgeCorrectedSensitivityDeviationValue" },
  "00240093": { vr: "CS", name: "StimulusResults" },
  "00240094": { vr: "FL", name: "SensitivityValue" },
  "00240095": { vr: "CS", name: "RetestStimulusSeen" },
  "00240096": { vr: "FL", name: "RetestSensitivityValue" },
  "00240097": { vr: "SQ", name: "VisualFieldTestPointNormalsSequence" },
  "00240098": { vr: "FL", name: "QuantifiedDefect" },
  "00240100": { vr: "FL", name: "AgeCorrectedSensitivityDeviationProbabilityValue" },
  "00240102": { vr: "CS", name: "GeneralizedDefectCorrectedSensitivityDeviationFlag " },
  "00240103": { vr: "FL", name: "GeneralizedDefectCorrectedSensitivityDeviationValue " },
  "00240104": { vr: "FL", name: "GeneralizedDefectCorrectedSensitivityDeviationProbabilityValue" },
  "00240105": { vr: "FL", name: "MinimumSensitivityValue" },
  "00240106": { vr: "CS", name: "BlindSpotLocalized" },
  "00240107": { vr: "FL", name: "BlindSpotXCoordinate" },
  "00240108": { vr: "FL", name: "BlindSpotYCoordinate " },
  "00240110": { vr: "SQ", name: "VisualAcuityMeasurementSequence" },
  "00240112": { vr: "SQ", name: "RefractiveParametersUsedOnPatientSequence" },
  "00240113": { vr: "CS", name: "MeasurementLaterality" },
  "00240114": { vr: "SQ", name: "OphthalmicPatientClinicalInformationLeftEyeSequence" },
  "00240115": { vr: "SQ", name: "OphthalmicPatientClinicalInformationRightEyeSequence" },
  "00240117": { vr: "CS", name: "FovealPointNormativeDataFlag" },
  "00240118": { vr: "FL", name: "FovealPointProbabilityValue" },
  "00240120": { vr: "CS", name: "ScreeningBaselineMeasured" },
  "00240122": { vr: "SQ", name: "ScreeningBaselineMeasuredSequence" },
  "00240124": { vr: "CS", name: "ScreeningBaselineType" },
  "00240126": { vr: "FL", name: "ScreeningBaselineValue" },
  "00240202": { vr: "LO", name: "AlgorithmSource" },
  "00240306": { vr: "LO", name: "DataSetName" },
  "00240307": { vr: "LO", name: "DataSetVersion" },
  "00240308": { vr: "LO", name: "DataSetSource" },
  "00240309": { vr: "LO", name: "DataSetDescription" },
  "00240317": { vr: "SQ", name: "VisualFieldTestReliabilityGlobalIndexSequence" },
  "00240320": { vr: "SQ", name: "VisualFieldGlobalResultsIndexSequence" },
  "00240325": { vr: "SQ", name: "DataObservationSequence" },
  "00240338": { vr: "CS", name: "IndexNormalsFlag" },
  "00240341": { vr: "FL", name: "IndexProbability" },
  "00240344": { vr: "SQ", name: "IndexProbabilitySequence" },
  "00280002": { vr: "US", name: "SamplesPerPixel" },
  "00280003": { vr: "US", name: "SamplesPerPixelUsed" },
  "00280004": { vr: "CS", name: "PhotometricInterpretation" },
  "00280005": { vr: "US", name: "ImageDimensions" },
  "00280006": { vr: "US", name: "PlanarConfiguration" },
  "00280008": { vr: "IS", name: "NumberOfFrames" },
  "00280009": { vr: "AT", name: "FrameIncrementPointer" },
  "0028000A": { vr: "AT", name: "FrameDimensionPointer" },
  "00280010": { vr: "US", name: "Rows" },
  "00280011": { vr: "US", name: "Columns" },
  "00280012": { vr: "US", name: "Planes" },
  "00280014": { vr: "US", name: "UltrasoundColorDataPresent" },
  "00280030": { vr: "DS", name: "PixelSpacing" },
  "00280031": { vr: "DS", name: "ZoomFactor" },
  "00280032": { vr: "DS", name: "ZoomCenter" },
  "00280034": { vr: "IS", name: "PixelAspectRatio" },
  "00280040": { vr: "CS", name: "ImageFormat" },
  "00280050": { vr: "LO", name: "ManipulatedImage" },
  "00280051": { vr: "CS", name: "CorrectedImage" },
  "0028005F": { vr: "LO", name: "CompressionRecognitionCode" },
  "00280060": { vr: "CS", name: "CompressionCode" },
  "00280061": { vr: "SH", name: "CompressionOriginator" },
  "00280062": { vr: "LO", name: "CompressionLabel" },
  "00280063": { vr: "SH", name: "CompressionDescription" },
  "00280065": { vr: "CS", name: "CompressionSequence" },
  "00280066": { vr: "AT", name: "CompressionStepPointers" },
  "00280068": { vr: "US", name: "RepeatInterval" },
  "00280069": { vr: "US", name: "BitsGrouped" },
  "00280070": { vr: "US", name: "PerimeterTable" },
  "00280071": { vr: "US|SS", name: "PerimeterValue" },
  "00280080": { vr: "US", name: "PredictorRows" },
  "00280081": { vr: "US", name: "PredictorColumns" },
  "00280082": { vr: "US", name: "PredictorConstants" },
  "00280090": { vr: "CS", name: "BlockedPixels" },
  "00280091": { vr: "US", name: "BlockRows" },
  "00280092": { vr: "US", name: "BlockColumns" },
  "00280093": { vr: "US", name: "RowOverlap" },
  "00280094": { vr: "US", name: "ColumnOverlap" },
  "00280100": { vr: "US", name: "BitsAllocated" },
  "00280101": { vr: "US", name: "BitsStored" },
  "00280102": { vr: "US", name: "HighBit" },
  "00280103": { vr: "US", name: "PixelRepresentation" },
  "00280104": { vr: "US|SS", name: "SmallestValidPixelValue" },
  "00280105": { vr: "US|SS", name: "LargestValidPixelValue" },
  "00280106": { vr: "US|SS", name: "SmallestImagePixelValue" },
  "00280107": { vr: "US|SS", name: "LargestImagePixelValue" },
  "00280108": { vr: "US|SS", name: "SmallestPixelValueInSeries" },
  "00280109": { vr: "US|SS", name: "LargestPixelValueInSeries" },
  "00280110": { vr: "US|SS", name: "SmallestImagePixelValueInPlane" },
  "00280111": { vr: "US|SS", name: "LargestImagePixelValueInPlane" },
  "00280120": { vr: "US|SS", name: "PixelPaddingValue" },
  "00280121": { vr: "US|SS", name: "PixelPaddingRangeLimit" },
  "00280200": { vr: "US", name: "ImageLocation" },
  "00280300": { vr: "CS", name: "QualityControlImage" },
  "00280301": { vr: "CS", name: "BurnedInAnnotation" },
  "00280302": { vr: "CS", name: "RecognizableVisualFeatures" },
  "00280303": { vr: "CS", name: "LongitudinalTemporalInformationModified" },
  "00280400": { vr: "LO", name: "TransformLabel" },
  "00280401": { vr: "LO", name: "TransformVersionNumber" },
  "00280402": { vr: "US", name: "NumberOfTransformSteps" },
  "00280403": { vr: "LO", name: "SequenceOfCompressedData" },
  "00280404": { vr: "AT", name: "DetailsOfCoefficients" },
  "002804x0": { vr: "US", name: "RowsForNthOrderCoefficients" },
  "002804x1": { vr: "US", name: "ColumnsForNthOrderCoefficients" },
  "002804x2": { vr: "LO", name: "CoefficientCoding" },
  "002804x3": { vr: "AT", name: "CoefficientCodingPointers" },
  "00280700": { vr: "LO", name: "DCTLabel" },
  "00280701": { vr: "CS", name: "DataBlockDescription" },
  "00280702": { vr: "AT", name: "DataBlock" },
  "00280710": { vr: "US", name: "NormalizationFactorFormat" },
  "00280720": { vr: "US", name: "ZonalMapNumberFormat" },
  "00280721": { vr: "AT", name: "ZonalMapLocation" },
  "00280722": { vr: "US", name: "ZonalMapFormat" },
  "00280730": { vr: "US", name: "AdaptiveMapFormat" },
  "00280740": { vr: "US", name: "CodeNumberFormat" },
  "002808x0": { vr: "CS", name: "CodeLabel" },
  "002808x2": { vr: "US", name: "NumberOfTables" },
  "002808x3": { vr: "AT", name: "CodeTableLocation" },
  "002808x4": { vr: "US", name: "BitsForCodeWord" },
  "002808x8": { vr: "AT", name: "ImageDataLocation" },
  "00280A02": { vr: "CS", name: "PixelSpacingCalibrationType" },
  "00280A04": { vr: "LO", name: "PixelSpacingCalibrationDescription" },
  "00281040": { vr: "CS", name: "PixelIntensityRelationship" },
  "00281041": { vr: "SS", name: "PixelIntensityRelationshipSign" },
  "00281050": { vr: "DS", name: "WindowCenter" },
  "00281051": { vr: "DS", name: "WindowWidth" },
  "00281052": { vr: "DS", name: "RescaleIntercept" },
  "00281053": { vr: "DS", name: "RescaleSlope" },
  "00281054": { vr: "LO", name: "RescaleType" },
  "00281055": { vr: "LO", name: "WindowCenterWidthExplanation" },
  "00281056": { vr: "CS", name: "VOILUTFunction" },
  "00281080": { vr: "CS", name: "GrayScale" },
  "00281090": { vr: "CS", name: "RecommendedViewingMode" },
  "00281100": { vr: "US|SS", name: "GrayLookupTableDescriptor" },
  "00281101": { vr: "US|SS", name: "RedPaletteColorLookupTableDescriptor" },
  "00281102": { vr: "US|SS", name: "GreenPaletteColorLookupTableDescriptor" },
  "00281103": { vr: "US|SS", name: "BluePaletteColorLookupTableDescriptor" },
  "00281104": { vr: "US", name: "AlphaPaletteColorLookupTableDescriptor" },
  "00281111": { vr: "US|SS", name: "LargeRedPaletteColorLookupTableDescriptor" },
  "00281112": { vr: "US|SS", name: "LargeGreenPaletteColorLookupTableDescriptor" },
  "00281113": { vr: "US|SS", name: "LargeBluePaletteColorLookupTableDescriptor" },
  "00281199": { vr: "UI", name: "PaletteColorLookupTableUID" },
  "00281200": { vr: "US|SS|OW", name: "GrayLookupTableData" },
  "00281201": { vr: "OW", name: "RedPaletteColorLookupTableData" },
  "00281202": { vr: "OW", name: "GreenPaletteColorLookupTableData" },
  "00281203": { vr: "OW", name: "BluePaletteColorLookupTableData" },
  "00281204": { vr: "OW", name: "AlphaPaletteColorLookupTableData" },
  "00281211": { vr: "OW", name: "LargeRedPaletteColorLookupTableData" },
  "00281212": { vr: "OW", name: "LargeGreenPaletteColorLookupTableData" },
  "00281213": { vr: "OW", name: "LargeBluePaletteColorLookupTableData" },
  "00281214": { vr: "UI", name: "LargePaletteColorLookupTableUID" },
  "00281221": { vr: "OW", name: "SegmentedRedPaletteColorLookupTableData" },
  "00281222": { vr: "OW", name: "SegmentedGreenPaletteColorLookupTableData" },
  "00281223": { vr: "OW", name: "SegmentedBluePaletteColorLookupTableData" },
  "00281300": { vr: "CS", name: "BreastImplantPresent" },
  "00281350": { vr: "CS", name: "PartialView" },
  "00281351": { vr: "ST", name: "PartialViewDescription" },
  "00281352": { vr: "SQ", name: "PartialViewCodeSequence" },
  "0028135A": { vr: "CS", name: "SpatialLocationsPreserved" },
  "00281401": { vr: "SQ", name: "DataFrameAssignmentSequence" },
  "00281402": { vr: "CS", name: "DataPathAssignment" },
  "00281403": { vr: "US", name: "BitsMappedToColorLookupTable" },
  "00281404": { vr: "SQ", name: "BlendingLUT1Sequence" },
  "00281405": { vr: "CS", name: "BlendingLUT1TransferFunction" },
  "00281406": { vr: "FD", name: "BlendingWeightConstant" },
  "00281407": { vr: "US", name: "BlendingLookupTableDescriptor" },
  "00281408": { vr: "OW", name: "BlendingLookupTableData" },
  "0028140B": { vr: "SQ", name: "EnhancedPaletteColorLookupTableSequence" },
  "0028140C": { vr: "SQ", name: "BlendingLUT2Sequence" },
  "0028140D": { vr: "CS", name: "BlendingLUT2TransferFunction" },
  "0028140E": { vr: "CS", name: "DataPathID" },
  "0028140F": { vr: "CS", name: "RGBLUTTransferFunction" },
  "00281410": { vr: "CS", name: "AlphaLUTTransferFunction" },
  "00282000": { vr: "OB", name: "ICCProfile" },
  "00282110": { vr: "CS", name: "LossyImageCompression" },
  "00282112": { vr: "DS", name: "LossyImageCompressionRatio" },
  "00282114": { vr: "CS", name: "LossyImageCompressionMethod" },
  "00283000": { vr: "SQ", name: "ModalityLUTSequence" },
  "00283002": { vr: "US|SS", name: "LUTDescriptor" },
  "00283003": { vr: "LO", name: "LUTExplanation" },
  "00283004": { vr: "LO", name: "ModalityLUTType" },
  "00283006": { vr: "US|OW", name: "LUTData" },
  "00283010": { vr: "SQ", name: "VOILUTSequence" },
  "00283110": { vr: "SQ", name: "SoftcopyVOILUTSequence" },
  "00284000": { vr: "LT", name: "ImagePresentationComments" },
  "00285000": { vr: "SQ", name: "BiPlaneAcquisitionSequence" },
  "00286010": { vr: "US", name: "RepresentativeFrameNumber" },
  "00286020": { vr: "US", name: "FrameNumbersOfInterest" },
  "00286022": { vr: "LO", name: "FrameOfInterestDescription" },
  "00286023": { vr: "CS", name: "FrameOfInterestType" },
  "00286030": { vr: "US", name: "MaskPointers" },
  "00286040": { vr: "US", name: "RWavePointer" },
  "00286100": { vr: "SQ", name: "MaskSubtractionSequence" },
  "00286101": { vr: "CS", name: "MaskOperation" },
  "00286102": { vr: "US", name: "ApplicableFrameRange" },
  "00286110": { vr: "US", name: "MaskFrameNumbers" },
  "00286112": { vr: "US", name: "ContrastFrameAveraging" },
  "00286114": { vr: "FL", name: "MaskSubPixelShift" },
  "00286120": { vr: "SS", name: "TIDOffset" },
  "00286190": { vr: "ST", name: "MaskOperationExplanation" },
  "00287FE0": { vr: "UT", name: "PixelDataProviderURL" },
  "00289001": { vr: "UL", name: "DataPointRows" },
  "00289002": { vr: "UL", name: "DataPointColumns" },
  "00289003": { vr: "CS", name: "SignalDomainColumns" },
  "00289099": { vr: "US", name: "LargestMonochromePixelValue" },
  "00289108": { vr: "CS", name: "DataRepresentation" },
  "00289110": { vr: "SQ", name: "PixelMeasuresSequence" },
  "00289132": { vr: "SQ", name: "FrameVOILUTSequence" },
  "00289145": { vr: "SQ", name: "PixelValueTransformationSequence" },
  "00289235": { vr: "CS", name: "SignalDomainRows" },
  "00289411": { vr: "FL", name: "DisplayFilterPercentage" },
  "00289415": { vr: "SQ", name: "FramePixelShiftSequence" },
  "00289416": { vr: "US", name: "SubtractionItemID" },
  "00289422": { vr: "SQ", name: "PixelIntensityRelationshipLUTSequence" },
  "00289443": { vr: "SQ", name: "FramePixelDataPropertiesSequence" },
  "00289444": { vr: "CS", name: "GeometricalProperties" },
  "00289445": { vr: "FL", name: "GeometricMaximumDistortion" },
  "00289446": { vr: "CS", name: "ImageProcessingApplied" },
  "00289454": { vr: "CS", name: "MaskSelectionMode" },
  "00289474": { vr: "CS", name: "LUTFunction" },
  "00289478": { vr: "FL", name: "MaskVisibilityPercentage" },
  "00289501": { vr: "SQ", name: "PixelShiftSequence" },
  "00289502": { vr: "SQ", name: "RegionPixelShiftSequence" },
  "00289503": { vr: "SS", name: "VerticesOfTheRegion" },
  "00289505": { vr: "SQ", name: "MultiFramePresentationSequence" },
  "00289506": { vr: "US", name: "PixelShiftFrameRange" },
  "00289507": { vr: "US", name: "LUTFrameRange" },
  "00289520": { vr: "DS", name: "ImageToEquipmentMappingMatrix" },
  "00289537": { vr: "CS", name: "EquipmentCoordinateSystemIdentification" },
  "0032000A": { vr: "CS", name: "StudyStatusID" },
  "0032000C": { vr: "CS", name: "StudyPriorityID" },
  "00320012": { vr: "LO", name: "StudyIDIssuer" },
  "00320032": { vr: "DA", name: "StudyVerifiedDate" },
  "00320033": { vr: "TM", name: "StudyVerifiedTime" },
  "00320034": { vr: "DA", name: "StudyReadDate" },
  "00320035": { vr: "TM", name: "StudyReadTime" },
  "00321000": { vr: "DA", name: "ScheduledStudyStartDate" },
  "00321001": { vr: "TM", name: "ScheduledStudyStartTime" },
  "00321010": { vr: "DA", name: "ScheduledStudyStopDate" },
  "00321011": { vr: "TM", name: "ScheduledStudyStopTime" },
  "00321020": { vr: "LO", name: "ScheduledStudyLocation" },
  "00321021": { vr: "AE", name: "ScheduledStudyLocationAETitle" },
  "00321030": { vr: "LO", name: "ReasonForStudy" },
  "00321031": { vr: "SQ", name: "RequestingPhysicianIdentificationSequence" },
  "00321032": { vr: "PN", name: "RequestingPhysician" },
  "00321033": { vr: "LO", name: "RequestingService" },
  "00321034": { vr: "SQ", name: "RequestingServiceCodeSequence" },
  "00321040": { vr: "DA", name: "StudyArrivalDate" },
  "00321041": { vr: "TM", name: "StudyArrivalTime" },
  "00321050": { vr: "DA", name: "StudyCompletionDate" },
  "00321051": { vr: "TM", name: "StudyCompletionTime" },
  "00321055": { vr: "CS", name: "StudyComponentStatusID" },
  "00321060": { vr: "LO", name: "RequestedProcedureDescription" },
  "00321064": { vr: "SQ", name: "RequestedProcedureCodeSequence" },
  "00321070": { vr: "LO", name: "RequestedContrastAgent" },
  "00324000": { vr: "LT", name: "StudyComments" },
  "00380004": { vr: "SQ", name: "ReferencedPatientAliasSequence" },
  "00380008": { vr: "CS", name: "VisitStatusID" },
  "00380010": { vr: "LO", name: "AdmissionID" },
  "00380011": { vr: "LO", name: "IssuerOfAdmissionID" },
  "00380014": { vr: "SQ", name: "IssuerOfAdmissionIDSequence" },
  "00380016": { vr: "LO", name: "RouteOfAdmissions" },
  "0038001A": { vr: "DA", name: "ScheduledAdmissionDate" },
  "0038001B": { vr: "TM", name: "ScheduledAdmissionTime" },
  "0038001C": { vr: "DA", name: "ScheduledDischargeDate" },
  "0038001D": { vr: "TM", name: "ScheduledDischargeTime" },
  "0038001E": { vr: "LO", name: "ScheduledPatientInstitutionResidence" },
  "00380020": { vr: "DA", name: "AdmittingDate" },
  "00380021": { vr: "TM", name: "AdmittingTime" },
  "00380030": { vr: "DA", name: "DischargeDate" },
  "00380032": { vr: "TM", name: "DischargeTime" },
  "00380040": { vr: "LO", name: "DischargeDiagnosisDescription" },
  "00380044": { vr: "SQ", name: "DischargeDiagnosisCodeSequence" },
  "00380050": { vr: "LO", name: "SpecialNeeds" },
  "00380060": { vr: "LO", name: "ServiceEpisodeID" },
  "00380061": { vr: "LO", name: "IssuerOfServiceEpisodeID" },
  "00380062": { vr: "LO", name: "ServiceEpisodeDescription" },
  "00380064": { vr: "SQ", name: "IssuerOfServiceEpisodeIDSequence" },
  "00380100": { vr: "SQ", name: "PertinentDocumentsSequence" },
  "00380300": { vr: "LO", name: "CurrentPatientLocation" },
  "00380400": { vr: "LO", name: "PatientInstitutionResidence" },
  "00380500": { vr: "LO", name: "PatientState" },
  "00380502": { vr: "SQ", name: "PatientClinicalTrialParticipationSequence" },
  "00384000": { vr: "LT", name: "VisitComments" },
  "003A0004": { vr: "CS", name: "WaveformOriginality" },
  "003A0005": { vr: "US", name: "NumberOfWaveformChannels" },
  "003A0010": { vr: "UL", name: "NumberOfWaveformSamples" },
  "003A001A": { vr: "DS", name: "SamplingFrequency" },
  "003A0020": { vr: "SH", name: "MultiplexGroupLabel" },
  "003A0200": { vr: "SQ", name: "ChannelDefinitionSequence" },
  "003A0202": { vr: "IS", name: "WaveformChannelNumber" },
  "003A0203": { vr: "SH", name: "ChannelLabel" },
  "003A0205": { vr: "CS", name: "ChannelStatus" },
  "003A0208": { vr: "SQ", name: "ChannelSourceSequence" },
  "003A0209": { vr: "SQ", name: "ChannelSourceModifiersSequence" },
  "003A020A": { vr: "SQ", name: "SourceWaveformSequence" },
  "003A020C": { vr: "LO", name: "ChannelDerivationDescription" },
  "003A0210": { vr: "DS", name: "ChannelSensitivity" },
  "003A0211": { vr: "SQ", name: "ChannelSensitivityUnitsSequence" },
  "003A0212": { vr: "DS", name: "ChannelSensitivityCorrectionFactor" },
  "003A0213": { vr: "DS", name: "ChannelBaseline" },
  "003A0214": { vr: "DS", name: "ChannelTimeSkew" },
  "003A0215": { vr: "DS", name: "ChannelSampleSkew" },
  "003A0218": { vr: "DS", name: "ChannelOffset" },
  "003A021A": { vr: "US", name: "WaveformBitsStored" },
  "003A0220": { vr: "DS", name: "FilterLowFrequency" },
  "003A0221": { vr: "DS", name: "FilterHighFrequency" },
  "003A0222": { vr: "DS", name: "NotchFilterFrequency" },
  "003A0223": { vr: "DS", name: "NotchFilterBandwidth" },
  "003A0230": { vr: "FL", name: "WaveformDataDisplayScale" },
  "003A0231": { vr: "US", name: "WaveformDisplayBackgroundCIELabValue" },
  "003A0240": { vr: "SQ", name: "WaveformPresentationGroupSequence" },
  "003A0241": { vr: "US", name: "PresentationGroupNumber" },
  "003A0242": { vr: "SQ", name: "ChannelDisplaySequence" },
  "003A0244": { vr: "US", name: "ChannelRecommendedDisplayCIELabValue" },
  "003A0245": { vr: "FL", name: "ChannelPosition" },
  "003A0246": { vr: "CS", name: "DisplayShadingFlag" },
  "003A0247": { vr: "FL", name: "FractionalChannelDisplayScale" },
  "003A0248": { vr: "FL", name: "AbsoluteChannelDisplayScale" },
  "003A0300": { vr: "SQ", name: "MultiplexedAudioChannelsDescriptionCodeSequence" },
  "003A0301": { vr: "IS", name: "ChannelIdentificationCode" },
  "003A0302": { vr: "CS", name: "ChannelMode" },
  "00400001": { vr: "AE", name: "ScheduledStationAETitle" },
  "00400002": { vr: "DA", name: "ScheduledProcedureStepStartDate" },
  "00400003": { vr: "TM", name: "ScheduledProcedureStepStartTime" },
  "00400004": { vr: "DA", name: "ScheduledProcedureStepEndDate" },
  "00400005": { vr: "TM", name: "ScheduledProcedureStepEndTime" },
  "00400006": { vr: "PN", name: "ScheduledPerformingPhysicianName" },
  "00400007": { vr: "LO", name: "ScheduledProcedureStepDescription" },
  "00400008": { vr: "SQ", name: "ScheduledProtocolCodeSequence" },
  "00400009": { vr: "SH", name: "ScheduledProcedureStepID" },
  "0040000A": { vr: "SQ", name: "StageCodeSequence" },
  "0040000B": { vr: "SQ", name: "ScheduledPerformingPhysicianIdentificationSequence" },
  "00400010": { vr: "SH", name: "ScheduledStationName" },
  "00400011": { vr: "SH", name: "ScheduledProcedureStepLocation" },
  "00400012": { vr: "LO", name: "PreMedication" },
  "00400020": { vr: "CS", name: "ScheduledProcedureStepStatus" },
  "00400026": { vr: "SQ", name: "OrderPlacerIdentifierSequence" },
  "00400027": { vr: "SQ", name: "OrderFillerIdentifierSequence" },
  "00400031": { vr: "UT", name: "LocalNamespaceEntityID" },
  "00400032": { vr: "UT", name: "UniversalEntityID" },
  "00400033": { vr: "CS", name: "UniversalEntityIDType" },
  "00400035": { vr: "CS", name: "IdentifierTypeCode" },
  "00400036": { vr: "SQ", name: "AssigningFacilitySequence" },
  "00400039": { vr: "SQ", name: "AssigningJurisdictionCodeSequence" },
  "0040003A": { vr: "SQ", name: "AssigningAgencyOrDepartmentCodeSequence" },
  "00400100": { vr: "SQ", name: "ScheduledProcedureStepSequence" },
  "00400220": { vr: "SQ", name: "ReferencedNonImageCompositeSOPInstanceSequence" },
  "00400241": { vr: "AE", name: "PerformedStationAETitle" },
  "00400242": { vr: "SH", name: "PerformedStationName" },
  "00400243": { vr: "SH", name: "PerformedLocation" },
  "00400244": { vr: "DA", name: "PerformedProcedureStepStartDate" },
  "00400245": { vr: "TM", name: "PerformedProcedureStepStartTime" },
  "00400250": { vr: "DA", name: "PerformedProcedureStepEndDate" },
  "00400251": { vr: "TM", name: "PerformedProcedureStepEndTime" },
  "00400252": { vr: "CS", name: "PerformedProcedureStepStatus" },
  "00400253": { vr: "SH", name: "PerformedProcedureStepID" },
  "00400254": { vr: "LO", name: "PerformedProcedureStepDescription" },
  "00400255": { vr: "LO", name: "PerformedProcedureTypeDescription" },
  "00400260": { vr: "SQ", name: "PerformedProtocolCodeSequence" },
  "00400261": { vr: "CS", name: "PerformedProtocolType" },
  "00400270": { vr: "SQ", name: "ScheduledStepAttributesSequence" },
  "00400275": { vr: "SQ", name: "RequestAttributesSequence" },
  "00400280": { vr: "ST", name: "CommentsOnThePerformedProcedureStep" },
  "00400281": { vr: "SQ", name: "PerformedProcedureStepDiscontinuationReasonCodeSequence" },
  "00400293": { vr: "SQ", name: "QuantitySequence" },
  "00400294": { vr: "DS", name: "Quantity" },
  "00400295": { vr: "SQ", name: "MeasuringUnitsSequence" },
  "00400296": { vr: "SQ", name: "BillingItemSequence" },
  "00400300": { vr: "US", name: "TotalTimeOfFluoroscopy" },
  "00400301": { vr: "US", name: "TotalNumberOfExposures" },
  "00400302": { vr: "US", name: "EntranceDose" },
  "00400303": { vr: "US", name: "ExposedArea" },
  "00400306": { vr: "DS", name: "DistanceSourceToEntrance" },
  "00400307": { vr: "DS", name: "DistanceSourceToSupport" },
  "0040030E": { vr: "SQ", name: "ExposureDoseSequence" },
  "00400310": { vr: "ST", name: "CommentsOnRadiationDose" },
  "00400312": { vr: "DS", name: "XRayOutput" },
  "00400314": { vr: "DS", name: "HalfValueLayer" },
  "00400316": { vr: "DS", name: "OrganDose" },
  "00400318": { vr: "CS", name: "OrganExposed" },
  "00400320": { vr: "SQ", name: "BillingProcedureStepSequence" },
  "00400321": { vr: "SQ", name: "FilmConsumptionSequence" },
  "00400324": { vr: "SQ", name: "BillingSuppliesAndDevicesSequence" },
  "00400330": { vr: "SQ", name: "ReferencedProcedureStepSequence" },
  "00400340": { vr: "SQ", name: "PerformedSeriesSequence" },
  "00400400": { vr: "LT", name: "CommentsOnTheScheduledProcedureStep" },
  "00400440": { vr: "SQ", name: "ProtocolContextSequence" },
  "00400441": { vr: "SQ", name: "ContentItemModifierSequence" },
  "00400500": { vr: "SQ", name: "ScheduledSpecimenSequence" },
  "0040050A": { vr: "LO", name: "SpecimenAccessionNumber" },
  "00400512": { vr: "LO", name: "ContainerIdentifier" },
  "00400513": { vr: "SQ", name: "IssuerOfTheContainerIdentifierSequence" },
  "00400515": { vr: "SQ", name: "AlternateContainerIdentifierSequence" },
  "00400518": { vr: "SQ", name: "ContainerTypeCodeSequence" },
  "0040051A": { vr: "LO", name: "ContainerDescription" },
  "00400520": { vr: "SQ", name: "ContainerComponentSequence" },
  "00400550": { vr: "SQ", name: "SpecimenSequence" },
  "00400551": { vr: "LO", name: "SpecimenIdentifier" },
  "00400552": { vr: "SQ", name: "SpecimenDescriptionSequenceTrial" },
  "00400553": { vr: "ST", name: "SpecimenDescriptionTrial" },
  "00400554": { vr: "UI", name: "SpecimenUID" },
  "00400555": { vr: "SQ", name: "AcquisitionContextSequence" },
  "00400556": { vr: "ST", name: "AcquisitionContextDescription" },
  "00400560": { vr: "SQ", name: "SpecimenDescriptionSequence" },
  "00400562": { vr: "SQ", name: "IssuerOfTheSpecimenIdentifierSequence" },
  "0040059A": { vr: "SQ", name: "SpecimenTypeCodeSequence" },
  "00400600": { vr: "LO", name: "SpecimenShortDescription" },
  "00400602": { vr: "UT", name: "SpecimenDetailedDescription" },
  "00400610": { vr: "SQ", name: "SpecimenPreparationSequence" },
  "00400612": { vr: "SQ", name: "SpecimenPreparationStepContentItemSequence" },
  "00400620": { vr: "SQ", name: "SpecimenLocalizationContentItemSequence" },
  "004006FA": { vr: "LO", name: "SlideIdentifier" },
  "0040071A": { vr: "SQ", name: "ImageCenterPointCoordinatesSequence" },
  "0040072A": { vr: "DS", name: "XOffsetInSlideCoordinateSystem" },
  "0040073A": { vr: "DS", name: "YOffsetInSlideCoordinateSystem" },
  "0040074A": { vr: "DS", name: "ZOffsetInSlideCoordinateSystem" },
  "004008D8": { vr: "SQ", name: "PixelSpacingSequence" },
  "004008DA": { vr: "SQ", name: "CoordinateSystemAxisCodeSequence" },
  "004008EA": { vr: "SQ", name: "MeasurementUnitsCodeSequence" },
  "004009F8": { vr: "SQ", name: "VitalStainCodeSequenceTrial" },
  "00401001": { vr: "SH", name: "RequestedProcedureID" },
  "00401002": { vr: "LO", name: "ReasonForTheRequestedProcedure" },
  "00401003": { vr: "SH", name: "RequestedProcedurePriority" },
  "00401004": { vr: "LO", name: "PatientTransportArrangements" },
  "00401005": { vr: "LO", name: "RequestedProcedureLocation" },
  "00401006": { vr: "SH", name: "PlacerOrderNumberProcedure" },
  "00401007": { vr: "SH", name: "FillerOrderNumberProcedure" },
  "00401008": { vr: "LO", name: "ConfidentialityCode" },
  "00401009": { vr: "SH", name: "ReportingPriority" },
  "0040100A": { vr: "SQ", name: "ReasonForRequestedProcedureCodeSequence" },
  "00401010": { vr: "PN", name: "NamesOfIntendedRecipientsOfResults" },
  "00401011": { vr: "SQ", name: "IntendedRecipientsOfResultsIdentificationSequence" },
  "00401012": { vr: "SQ", name: "ReasonForPerformedProcedureCodeSequence" },
  "00401060": { vr: "LO", name: "RequestedProcedureDescriptionTrial" },
  "00401101": { vr: "SQ", name: "PersonIdentificationCodeSequence" },
  "00401102": { vr: "ST", name: "PersonAddress" },
  "00401103": { vr: "LO", name: "PersonTelephoneNumbers" },
  "00401400": { vr: "LT", name: "RequestedProcedureComments" },
  "00402001": { vr: "LO", name: "ReasonForTheImagingServiceRequest" },
  "00402004": { vr: "DA", name: "IssueDateOfImagingServiceRequest" },
  "00402005": { vr: "TM", name: "IssueTimeOfImagingServiceRequest" },
  "00402006": { vr: "SH", name: "PlacerOrderNumberImagingServiceRequestRetired" },
  "00402007": { vr: "SH", name: "FillerOrderNumberImagingServiceRequestRetired" },
  "00402008": { vr: "PN", name: "OrderEnteredBy" },
  "00402009": { vr: "SH", name: "OrderEntererLocation" },
  "00402010": { vr: "SH", name: "OrderCallbackPhoneNumber" },
  "00402016": { vr: "LO", name: "PlacerOrderNumberImagingServiceRequest" },
  "00402017": { vr: "LO", name: "FillerOrderNumberImagingServiceRequest" },
  "00402400": { vr: "LT", name: "ImagingServiceRequestComments" },
  "00403001": { vr: "LO", name: "ConfidentialityConstraintOnPatientDataDescription" },
  "00404001": { vr: "CS", name: "GeneralPurposeScheduledProcedureStepStatus" },
  "00404002": { vr: "CS", name: "GeneralPurposePerformedProcedureStepStatus" },
  "00404003": { vr: "CS", name: "GeneralPurposeScheduledProcedureStepPriority" },
  "00404004": { vr: "SQ", name: "ScheduledProcessingApplicationsCodeSequence" },
  "00404005": { vr: "DT", name: "ScheduledProcedureStepStartDateTime" },
  "00404006": { vr: "CS", name: "MultipleCopiesFlag" },
  "00404007": { vr: "SQ", name: "PerformedProcessingApplicationsCodeSequence" },
  "00404009": { vr: "SQ", name: "HumanPerformerCodeSequence" },
  "00404010": { vr: "DT", name: "ScheduledProcedureStepModificationDateTime" },
  "00404011": { vr: "DT", name: "ExpectedCompletionDateTime" },
  "00404015": { vr: "SQ", name: "ResultingGeneralPurposePerformedProcedureStepsSequence" },
  "00404016": { vr: "SQ", name: "ReferencedGeneralPurposeScheduledProcedureStepSequence" },
  "00404018": { vr: "SQ", name: "ScheduledWorkitemCodeSequence" },
  "00404019": { vr: "SQ", name: "PerformedWorkitemCodeSequence" },
  "00404020": { vr: "CS", name: "InputAvailabilityFlag" },
  "00404021": { vr: "SQ", name: "InputInformationSequence" },
  "00404022": { vr: "SQ", name: "RelevantInformationSequence" },
  "00404023": { vr: "UI", name: "ReferencedGeneralPurposeScheduledProcedureStepTransactionUID" },
  "00404025": { vr: "SQ", name: "ScheduledStationNameCodeSequence" },
  "00404026": { vr: "SQ", name: "ScheduledStationClassCodeSequence" },
  "00404027": { vr: "SQ", name: "ScheduledStationGeographicLocationCodeSequence" },
  "00404028": { vr: "SQ", name: "PerformedStationNameCodeSequence" },
  "00404029": { vr: "SQ", name: "PerformedStationClassCodeSequence" },
  "00404030": { vr: "SQ", name: "PerformedStationGeographicLocationCodeSequence" },
  "00404031": { vr: "SQ", name: "RequestedSubsequentWorkitemCodeSequence" },
  "00404032": { vr: "SQ", name: "NonDICOMOutputCodeSequence" },
  "00404033": { vr: "SQ", name: "OutputInformationSequence" },
  "00404034": { vr: "SQ", name: "ScheduledHumanPerformersSequence" },
  "00404035": { vr: "SQ", name: "ActualHumanPerformersSequence" },
  "00404036": { vr: "LO", name: "HumanPerformerOrganization" },
  "00404037": { vr: "PN", name: "HumanPerformerName" },
  "00404040": { vr: "CS", name: "RawDataHandling" },
  "00404041": { vr: "CS", name: "InputReadinessState" },
  "00404050": { vr: "DT", name: "PerformedProcedureStepStartDateTime" },
  "00404051": { vr: "DT", name: "PerformedProcedureStepEndDateTime" },
  "00404052": { vr: "DT", name: "ProcedureStepCancellationDateTime" },
  "00408302": { vr: "DS", name: "EntranceDoseInmGy" },
  "00409094": { vr: "SQ", name: "ReferencedImageRealWorldValueMappingSequence" },
  "00409096": { vr: "SQ", name: "RealWorldValueMappingSequence" },
  "00409098": { vr: "SQ", name: "PixelValueMappingCodeSequence" },
  "00409210": { vr: "SH", name: "LUTLabel" },
  "00409211": { vr: "US|SS", name: "RealWorldValueLastValueMapped" },
  "00409212": { vr: "FD", name: "RealWorldValueLUTData" },
  "00409216": { vr: "US|SS", name: "RealWorldValueFirstValueMapped" },
  "00409224": { vr: "FD", name: "RealWorldValueIntercept" },
  "00409225": { vr: "FD", name: "RealWorldValueSlope" },
  "0040A007": { vr: "CS", name: "FindingsFlagTrial" },
  "0040A010": { vr: "CS", name: "RelationshipType" },
  "0040A020": { vr: "SQ", name: "FindingsSequenceTrial" },
  "0040A021": { vr: "UI", name: "FindingsGroupUIDTrial" },
  "0040A022": { vr: "UI", name: "ReferencedFindingsGroupUIDTrial" },
  "0040A023": { vr: "DA", name: "FindingsGroupRecordingDateTrial" },
  "0040A024": { vr: "TM", name: "FindingsGroupRecordingTimeTrial" },
  "0040A026": { vr: "SQ", name: "FindingsSourceCategoryCodeSequenceTrial" },
  "0040A027": { vr: "LO", name: "VerifyingOrganization" },
  "0040A028": { vr: "SQ", name: "DocumentingOrganizationIdentifierCodeSequenceTrial" },
  "0040A030": { vr: "DT", name: "VerificationDateTime" },
  "0040A032": { vr: "DT", name: "ObservationDateTime" },
  "0040A040": { vr: "CS", name: "ValueType" },
  "0040A043": { vr: "SQ", name: "ConceptNameCodeSequence" },
  "0040A047": { vr: "LO", name: "MeasurementPrecisionDescriptionTrial" },
  "0040A050": { vr: "CS", name: "ContinuityOfContent" },
  "0040A057": { vr: "CS", name: "UrgencyOrPriorityAlertsTrial" },
  "0040A060": { vr: "LO", name: "SequencingIndicatorTrial" },
  "0040A066": { vr: "SQ", name: "DocumentIdentifierCodeSequenceTrial" },
  "0040A067": { vr: "PN", name: "DocumentAuthorTrial" },
  "0040A068": { vr: "SQ", name: "DocumentAuthorIdentifierCodeSequenceTrial" },
  "0040A070": { vr: "SQ", name: "IdentifierCodeSequenceTrial" },
  "0040A073": { vr: "SQ", name: "VerifyingObserverSequence" },
  "0040A074": { vr: "OB", name: "ObjectBinaryIdentifierTrial" },
  "0040A075": { vr: "PN", name: "VerifyingObserverName" },
  "0040A076": { vr: "SQ", name: "DocumentingObserverIdentifierCodeSequenceTrial" },
  "0040A078": { vr: "SQ", name: "AuthorObserverSequence" },
  "0040A07A": { vr: "SQ", name: "ParticipantSequence" },
  "0040A07C": { vr: "SQ", name: "CustodialOrganizationSequence" },
  "0040A080": { vr: "CS", name: "ParticipationType" },
  "0040A082": { vr: "DT", name: "ParticipationDateTime" },
  "0040A084": { vr: "CS", name: "ObserverType" },
  "0040A085": { vr: "SQ", name: "ProcedureIdentifierCodeSequenceTrial" },
  "0040A088": { vr: "SQ", name: "VerifyingObserverIdentificationCodeSequence" },
  "0040A089": { vr: "OB", name: "ObjectDirectoryBinaryIdentifierTrial" },
  "0040A090": { vr: "SQ", name: "EquivalentCDADocumentSequence" },
  "0040A0B0": { vr: "US", name: "ReferencedWaveformChannels" },
  "0040A110": { vr: "DA", name: "DateOfDocumentOrVerbalTransactionTrial" },
  "0040A112": { vr: "TM", name: "TimeOfDocumentCreationOrVerbalTransactionTrial" },
  "0040A120": { vr: "DT", name: "DateTime" },
  "0040A121": { vr: "DA", name: "Date" },
  "0040A122": { vr: "TM", name: "Time" },
  "0040A123": { vr: "PN", name: "PersonName" },
  "0040A124": { vr: "UI", name: "UID" },
  "0040A125": { vr: "CS", name: "ReportStatusIDTrial" },
  "0040A130": { vr: "CS", name: "TemporalRangeType" },
  "0040A132": { vr: "UL", name: "ReferencedSamplePositions" },
  "0040A136": { vr: "US", name: "ReferencedFrameNumbers" },
  "0040A138": { vr: "DS", name: "ReferencedTimeOffsets" },
  "0040A13A": { vr: "DT", name: "ReferencedDateTime" },
  "0040A160": { vr: "UT", name: "TextValue" },
  "0040A167": { vr: "SQ", name: "ObservationCategoryCodeSequenceTrial" },
  "0040A168": { vr: "SQ", name: "ConceptCodeSequence" },
  "0040A16A": { vr: "ST", name: "BibliographicCitationTrial" },
  "0040A170": { vr: "SQ", name: "PurposeOfReferenceCodeSequence" },
  "0040A171": { vr: "UI", name: "ObservationUIDTrial" },
  "0040A172": { vr: "UI", name: "ReferencedObservationUIDTrial" },
  "0040A173": { vr: "CS", name: "ReferencedObservationClassTrial" },
  "0040A174": { vr: "CS", name: "ReferencedObjectObservationClassTrial" },
  "0040A180": { vr: "US", name: "AnnotationGroupNumber" },
  "0040A192": { vr: "DA", name: "ObservationDateTrial" },
  "0040A193": { vr: "TM", name: "ObservationTimeTrial" },
  "0040A194": { vr: "CS", name: "MeasurementAutomationTrial" },
  "0040A195": { vr: "SQ", name: "ModifierCodeSequence" },
  "0040A224": { vr: "ST", name: "IdentificationDescriptionTrial" },
  "0040A290": { vr: "CS", name: "CoordinatesSetGeometricTypeTrial" },
  "0040A296": { vr: "SQ", name: "AlgorithmCodeSequenceTrial" },
  "0040A297": { vr: "ST", name: "AlgorithmDescriptionTrial" },
  "0040A29A": { vr: "SL", name: "PixelCoordinatesSetTrial" },
  "0040A300": { vr: "SQ", name: "MeasuredValueSequence" },
  "0040A301": { vr: "SQ", name: "NumericValueQualifierCodeSequence" },
  "0040A307": { vr: "PN", name: "CurrentObserverTrial" },
  "0040A30A": { vr: "DS", name: "NumericValue" },
  "0040A313": { vr: "SQ", name: "ReferencedAccessionSequenceTrial" },
  "0040A33A": { vr: "ST", name: "ReportStatusCommentTrial" },
  "0040A340": { vr: "SQ", name: "ProcedureContextSequenceTrial" },
  "0040A352": { vr: "PN", name: "VerbalSourceTrial" },
  "0040A353": { vr: "ST", name: "AddressTrial" },
  "0040A354": { vr: "LO", name: "TelephoneNumberTrial" },
  "0040A358": { vr: "SQ", name: "VerbalSourceIdentifierCodeSequenceTrial" },
  "0040A360": { vr: "SQ", name: "PredecessorDocumentsSequence" },
  "0040A370": { vr: "SQ", name: "ReferencedRequestSequence" },
  "0040A372": { vr: "SQ", name: "PerformedProcedureCodeSequence" },
  "0040A375": { vr: "SQ", name: "CurrentRequestedProcedureEvidenceSequence" },
  "0040A380": { vr: "SQ", name: "ReportDetailSequenceTrial" },
  "0040A385": { vr: "SQ", name: "PertinentOtherEvidenceSequence" },
  "0040A390": { vr: "SQ", name: "HL7StructuredDocumentReferenceSequence" },
  "0040A402": { vr: "UI", name: "ObservationSubjectUIDTrial" },
  "0040A403": { vr: "CS", name: "ObservationSubjectClassTrial" },
  "0040A404": { vr: "SQ", name: "ObservationSubjectTypeCodeSequenceTrial" },
  "0040A491": { vr: "CS", name: "CompletionFlag" },
  "0040A492": { vr: "LO", name: "CompletionFlagDescription" },
  "0040A493": { vr: "CS", name: "VerificationFlag" },
  "0040A494": { vr: "CS", name: "ArchiveRequested" },
  "0040A496": { vr: "CS", name: "PreliminaryFlag" },
  "0040A504": { vr: "SQ", name: "ContentTemplateSequence" },
  "0040A525": { vr: "SQ", name: "IdenticalDocumentsSequence" },
  "0040A600": { vr: "CS", name: "ObservationSubjectContextFlagTrial" },
  "0040A601": { vr: "CS", name: "ObserverContextFlagTrial" },
  "0040A603": { vr: "CS", name: "ProcedureContextFlagTrial" },
  "0040A730": { vr: "SQ", name: "ContentSequence" },
  "0040A731": { vr: "SQ", name: "RelationshipSequenceTrial" },
  "0040A732": { vr: "SQ", name: "RelationshipTypeCodeSequenceTrial" },
  "0040A744": { vr: "SQ", name: "LanguageCodeSequenceTrial" },
  "0040A992": { vr: "ST", name: "UniformResourceLocatorTrial" },
  "0040B020": { vr: "SQ", name: "WaveformAnnotationSequence" },
  "0040DB00": { vr: "CS", name: "TemplateIdentifier" },
  "0040DB06": { vr: "DT", name: "TemplateVersion" },
  "0040DB07": { vr: "DT", name: "TemplateLocalVersion" },
  "0040DB0B": { vr: "CS", name: "TemplateExtensionFlag" },
  "0040DB0C": { vr: "UI", name: "TemplateExtensionOrganizationUID" },
  "0040DB0D": { vr: "UI", name: "TemplateExtensionCreatorUID" },
  "0040DB73": { vr: "UL", name: "ReferencedContentItemIdentifier" },
  "0040E001": { vr: "ST", name: "HL7InstanceIdentifier" },
  "0040E004": { vr: "DT", name: "HL7DocumentEffectiveTime" },
  "0040E006": { vr: "SQ", name: "HL7DocumentTypeCodeSequence" },
  "0040E008": { vr: "SQ", name: "DocumentClassCodeSequence" },
  "0040E010": { vr: "UT", name: "RetrieveURI" },
  "0040E011": { vr: "UI", name: "RetrieveLocationUID" },
  "0040E020": { vr: "CS", name: "TypeOfInstances" },
  "0040E021": { vr: "SQ", name: "DICOMRetrievalSequence" },
  "0040E022": { vr: "SQ", name: "DICOMMediaRetrievalSequence" },
  "0040E023": { vr: "SQ", name: "WADORetrievalSequence" },
  "0040E024": { vr: "SQ", name: "XDSRetrievalSequence" },
  "0040E030": { vr: "UI", name: "RepositoryUniqueID" },
  "0040E031": { vr: "UI", name: "HomeCommunityID" },
  "00420010": { vr: "ST", name: "DocumentTitle" },
  "00420011": { vr: "OB", name: "EncapsulatedDocument" },
  "00420012": { vr: "LO", name: "MIMETypeOfEncapsulatedDocument" },
  "00420013": { vr: "SQ", name: "SourceInstanceSequence" },
  "00420014": { vr: "LO", name: "ListOfMIMETypes" },
  "00440001": { vr: "ST", name: "ProductPackageIdentifier" },
  "00440002": { vr: "CS", name: "SubstanceAdministrationApproval" },
  "00440003": { vr: "LT", name: "ApprovalStatusFurtherDescription" },
  "00440004": { vr: "DT", name: "ApprovalStatusDateTime" },
  "00440007": { vr: "SQ", name: "ProductTypeCodeSequence" },
  "00440008": { vr: "LO", name: "ProductName" },
  "00440009": { vr: "LT", name: "ProductDescription" },
  "0044000A": { vr: "LO", name: "ProductLotIdentifier" },
  "0044000B": { vr: "DT", name: "ProductExpirationDateTime" },
  "00440010": { vr: "DT", name: "SubstanceAdministrationDateTime" },
  "00440011": { vr: "LO", name: "SubstanceAdministrationNotes" },
  "00440012": { vr: "LO", name: "SubstanceAdministrationDeviceID" },
  "00440013": { vr: "SQ", name: "ProductParameterSequence" },
  "00440019": { vr: "SQ", name: "SubstanceAdministrationParameterSequence" },
  "00460012": { vr: "LO", name: "LensDescription" },
  "00460014": { vr: "SQ", name: "RightLensSequence" },
  "00460015": { vr: "SQ", name: "LeftLensSequence" },
  "00460016": { vr: "SQ", name: "UnspecifiedLateralityLensSequence" },
  "00460018": { vr: "SQ", name: "CylinderSequence" },
  "00460028": { vr: "SQ", name: "PrismSequence" },
  "00460030": { vr: "FD", name: "HorizontalPrismPower" },
  "00460032": { vr: "CS", name: "HorizontalPrismBase" },
  "00460034": { vr: "FD", name: "VerticalPrismPower" },
  "00460036": { vr: "CS", name: "VerticalPrismBase" },
  "00460038": { vr: "CS", name: "LensSegmentType" },
  "00460040": { vr: "FD", name: "OpticalTransmittance" },
  "00460042": { vr: "FD", name: "ChannelWidth" },
  "00460044": { vr: "FD", name: "PupilSize" },
  "00460046": { vr: "FD", name: "CornealSize" },
  "00460050": { vr: "SQ", name: "AutorefractionRightEyeSequence" },
  "00460052": { vr: "SQ", name: "AutorefractionLeftEyeSequence" },
  "00460060": { vr: "FD", name: "DistancePupillaryDistance" },
  "00460062": { vr: "FD", name: "NearPupillaryDistance" },
  "00460063": { vr: "FD", name: "IntermediatePupillaryDistance" },
  "00460064": { vr: "FD", name: "OtherPupillaryDistance" },
  "00460070": { vr: "SQ", name: "KeratometryRightEyeSequence" },
  "00460071": { vr: "SQ", name: "KeratometryLeftEyeSequence" },
  "00460074": { vr: "SQ", name: "SteepKeratometricAxisSequence" },
  "00460075": { vr: "FD", name: "RadiusOfCurvature" },
  "00460076": { vr: "FD", name: "KeratometricPower" },
  "00460077": { vr: "FD", name: "KeratometricAxis" },
  "00460080": { vr: "SQ", name: "FlatKeratometricAxisSequence" },
  "00460092": { vr: "CS", name: "BackgroundColor" },
  "00460094": { vr: "CS", name: "Optotype" },
  "00460095": { vr: "CS", name: "OptotypePresentation" },
  "00460097": { vr: "SQ", name: "SubjectiveRefractionRightEyeSequence" },
  "00460098": { vr: "SQ", name: "SubjectiveRefractionLeftEyeSequence" },
  "00460100": { vr: "SQ", name: "AddNearSequence" },
  "00460101": { vr: "SQ", name: "AddIntermediateSequence" },
  "00460102": { vr: "SQ", name: "AddOtherSequence" },
  "00460104": { vr: "FD", name: "AddPower" },
  "00460106": { vr: "FD", name: "ViewingDistance" },
  "00460121": { vr: "SQ", name: "VisualAcuityTypeCodeSequence" },
  "00460122": { vr: "SQ", name: "VisualAcuityRightEyeSequence" },
  "00460123": { vr: "SQ", name: "VisualAcuityLeftEyeSequence" },
  "00460124": { vr: "SQ", name: "VisualAcuityBothEyesOpenSequence" },
  "00460125": { vr: "CS", name: "ViewingDistanceType" },
  "00460135": { vr: "SS", name: "VisualAcuityModifiers" },
  "00460137": { vr: "FD", name: "DecimalVisualAcuity" },
  "00460139": { vr: "LO", name: "OptotypeDetailedDefinition" },
  "00460145": { vr: "SQ", name: "ReferencedRefractiveMeasurementsSequence" },
  "00460146": { vr: "FD", name: "SpherePower" },
  "00460147": { vr: "FD", name: "CylinderPower" },
  "00480001": { vr: "FL", name: "ImagedVolumeWidth" },
  "00480002": { vr: "FL", name: "ImagedVolumeHeight" },
  "00480003": { vr: "FL", name: "ImagedVolumeDepth" },
  "00480006": { vr: "UL", name: "TotalPixelMatrixColumns" },
  "00480007": { vr: "UL", name: "TotalPixelMatrixRows" },
  "00480008": { vr: "SQ", name: "TotalPixelMatrixOriginSequence" },
  "00480010": { vr: "CS", name: "SpecimenLabelInImage" },
  "00480011": { vr: "CS", name: "FocusMethod" },
  "00480012": { vr: "CS", name: "ExtendedDepthOfField" },
  "00480013": { vr: "US", name: "NumberOfFocalPlanes" },
  "00480014": { vr: "FL", name: "DistanceBetweenFocalPlanes" },
  "00480015": { vr: "US", name: "RecommendedAbsentPixelCIELabValue" },
  "00480100": { vr: "SQ", name: "IlluminatorTypeCodeSequence" },
  "00480102": { vr: "DS", name: "ImageOrientationSlide" },
  "00480105": { vr: "SQ", name: "OpticalPathSequence" },
  "00480106": { vr: "SH", name: "OpticalPathIdentifier" },
  "00480107": { vr: "ST", name: "OpticalPathDescription" },
  "00480108": { vr: "SQ", name: "IlluminationColorCodeSequence" },
  "00480110": { vr: "SQ", name: "SpecimenReferenceSequence" },
  "00480111": { vr: "DS", name: "CondenserLensPower" },
  "00480112": { vr: "DS", name: "ObjectiveLensPower" },
  "00480113": { vr: "DS", name: "ObjectiveLensNumericalAperture" },
  "00480120": { vr: "SQ", name: "PaletteColorLookupTableSequence" },
  "00480200": { vr: "SQ", name: "ReferencedImageNavigationSequence" },
  "00480201": { vr: "US", name: "TopLeftHandCornerOfLocalizerArea" },
  "00480202": { vr: "US", name: "BottomRightHandCornerOfLocalizerArea" },
  "00480207": { vr: "SQ", name: "OpticalPathIdentificationSequence" },
  "0048021A": { vr: "SQ", name: "PlanePositionSlideSequence" },
  "0048021E": { vr: "SL", name: "RowPositionInTotalImagePixelMatrix" },
  "0048021F": { vr: "SL", name: "ColumnPositionInTotalImagePixelMatrix" },
  "00480301": { vr: "CS", name: "PixelOriginInterpretation" },
  "00500004": { vr: "CS", name: "CalibrationImage" },
  "00500010": { vr: "SQ", name: "DeviceSequence" },
  "00500012": { vr: "SQ", name: "ContainerComponentTypeCodeSequence" },
  "00500013": { vr: "FD", name: "ContainerComponentThickness" },
  "00500014": { vr: "DS", name: "DeviceLength" },
  "00500015": { vr: "FD", name: "ContainerComponentWidth" },
  "00500016": { vr: "DS", name: "DeviceDiameter" },
  "00500017": { vr: "CS", name: "DeviceDiameterUnits" },
  "00500018": { vr: "DS", name: "DeviceVolume" },
  "00500019": { vr: "DS", name: "InterMarkerDistance" },
  "0050001A": { vr: "CS", name: "ContainerComponentMaterial" },
  "0050001B": { vr: "LO", name: "ContainerComponentID" },
  "0050001C": { vr: "FD", name: "ContainerComponentLength" },
  "0050001D": { vr: "FD", name: "ContainerComponentDiameter" },
  "0050001E": { vr: "LO", name: "ContainerComponentDescription" },
  "00500020": { vr: "LO", name: "DeviceDescription" },
  "00520001": { vr: "FL", name: "ContrastBolusIngredientPercentByVolume" },
  "00520002": { vr: "FD", name: "OCTFocalDistance" },
  "00520003": { vr: "FD", name: "BeamSpotSize" },
  "00520004": { vr: "FD", name: "EffectiveRefractiveIndex" },
  "00520006": { vr: "CS", name: "OCTAcquisitionDomain" },
  "00520007": { vr: "FD", name: "OCTOpticalCenterWavelength" },
  "00520008": { vr: "FD", name: "AxialResolution" },
  "00520009": { vr: "FD", name: "RangingDepth" },
  "00520011": { vr: "FD", name: "ALineRate" },
  "00520012": { vr: "US", name: "ALinesPerFrame" },
  "00520013": { vr: "FD", name: "CatheterRotationalRate" },
  "00520014": { vr: "FD", name: "ALinePixelSpacing" },
  "00520016": { vr: "SQ", name: "ModeOfPercutaneousAccessSequence" },
  "00520025": { vr: "SQ", name: "IntravascularOCTFrameTypeSequence" },
  "00520026": { vr: "CS", name: "OCTZOffsetApplied" },
  "00520027": { vr: "SQ", name: "IntravascularFrameContentSequence" },
  "00520028": { vr: "FD", name: "IntravascularLongitudinalDistance" },
  "00520029": { vr: "SQ", name: "IntravascularOCTFrameContentSequence" },
  "00520030": { vr: "SS", name: "OCTZOffsetCorrection" },
  "00520031": { vr: "CS", name: "CatheterDirectionOfRotation" },
  "00520033": { vr: "FD", name: "SeamLineLocation" },
  "00520034": { vr: "FD", name: "FirstALineLocation" },
  "00520036": { vr: "US", name: "SeamLineIndex" },
  "00520038": { vr: "US", name: "NumberOfPaddedAlines" },
  "00520039": { vr: "CS", name: "InterpolationType" },
  "0052003A": { vr: "CS", name: "RefractiveIndexApplied" },
  "00540011": { vr: "US", name: "NumberOfEnergyWindows" },
  "00540012": { vr: "SQ", name: "EnergyWindowInformationSequence" },
  "00540013": { vr: "SQ", name: "EnergyWindowRangeSequence" },
  "00540014": { vr: "DS", name: "EnergyWindowLowerLimit" },
  "00540015": { vr: "DS", name: "EnergyWindowUpperLimit" },
  "00540016": { vr: "SQ", name: "RadiopharmaceuticalInformationSequence" },
  "00540017": { vr: "IS", name: "ResidualSyringeCounts" },
  "00540018": { vr: "SH", name: "EnergyWindowName" },
  "00540020": { vr: "US", name: "DetectorVector" },
  "00540021": { vr: "US", name: "NumberOfDetectors" },
  "00540022": { vr: "SQ", name: "DetectorInformationSequence" },
  "00540030": { vr: "US", name: "PhaseVector" },
  "00540031": { vr: "US", name: "NumberOfPhases" },
  "00540032": { vr: "SQ", name: "PhaseInformationSequence" },
  "00540033": { vr: "US", name: "NumberOfFramesInPhase" },
  "00540036": { vr: "IS", name: "PhaseDelay" },
  "00540038": { vr: "IS", name: "PauseBetweenFrames" },
  "00540039": { vr: "CS", name: "PhaseDescription" },
  "00540050": { vr: "US", name: "RotationVector" },
  "00540051": { vr: "US", name: "NumberOfRotations" },
  "00540052": { vr: "SQ", name: "RotationInformationSequence" },
  "00540053": { vr: "US", name: "NumberOfFramesInRotation" },
  "00540060": { vr: "US", name: "RRIntervalVector" },
  "00540061": { vr: "US", name: "NumberOfRRIntervals" },
  "00540062": { vr: "SQ", name: "GatedInformationSequence" },
  "00540063": { vr: "SQ", name: "DataInformationSequence" },
  "00540070": { vr: "US", name: "TimeSlotVector" },
  "00540071": { vr: "US", name: "NumberOfTimeSlots" },
  "00540072": { vr: "SQ", name: "TimeSlotInformationSequence" },
  "00540073": { vr: "DS", name: "TimeSlotTime" },
  "00540080": { vr: "US", name: "SliceVector" },
  "00540081": { vr: "US", name: "NumberOfSlices" },
  "00540090": { vr: "US", name: "AngularViewVector" },
  "00540100": { vr: "US", name: "TimeSliceVector" },
  "00540101": { vr: "US", name: "NumberOfTimeSlices" },
  "00540200": { vr: "DS", name: "StartAngle" },
  "00540202": { vr: "CS", name: "TypeOfDetectorMotion" },
  "00540210": { vr: "IS", name: "TriggerVector" },
  "00540211": { vr: "US", name: "NumberOfTriggersInPhase" },
  "00540220": { vr: "SQ", name: "ViewCodeSequence" },
  "00540222": { vr: "SQ", name: "ViewModifierCodeSequence" },
  "00540300": { vr: "SQ", name: "RadionuclideCodeSequence" },
  "00540302": { vr: "SQ", name: "AdministrationRouteCodeSequence" },
  "00540304": { vr: "SQ", name: "RadiopharmaceuticalCodeSequence" },
  "00540306": { vr: "SQ", name: "CalibrationDataSequence" },
  "00540308": { vr: "US", name: "EnergyWindowNumber" },
  "00540400": { vr: "SH", name: "ImageID" },
  "00540410": { vr: "SQ", name: "PatientOrientationCodeSequence" },
  "00540412": { vr: "SQ", name: "PatientOrientationModifierCodeSequence" },
  "00540414": { vr: "SQ", name: "PatientGantryRelationshipCodeSequence" },
  "00540500": { vr: "CS", name: "SliceProgressionDirection" },
  "00541000": { vr: "CS", name: "SeriesType" },
  "00541001": { vr: "CS", name: "Units" },
  "00541002": { vr: "CS", name: "CountsSource" },
  "00541004": { vr: "CS", name: "ReprojectionMethod" },
  "00541006": { vr: "CS", name: "SUVType" },
  "00541100": { vr: "CS", name: "RandomsCorrectionMethod" },
  "00541101": { vr: "LO", name: "AttenuationCorrectionMethod" },
  "00541102": { vr: "CS", name: "DecayCorrection" },
  "00541103": { vr: "LO", name: "ReconstructionMethod" },
  "00541104": { vr: "LO", name: "DetectorLinesOfResponseUsed" },
  "00541105": { vr: "LO", name: "ScatterCorrectionMethod" },
  "00541200": { vr: "DS", name: "AxialAcceptance" },
  "00541201": { vr: "IS", name: "AxialMash" },
  "00541202": { vr: "IS", name: "TransverseMash" },
  "00541203": { vr: "DS", name: "DetectorElementSize" },
  "00541210": { vr: "DS", name: "CoincidenceWindowWidth" },
  "00541220": { vr: "CS", name: "SecondaryCountsType" },
  "00541300": { vr: "DS", name: "FrameReferenceTime" },
  "00541310": { vr: "IS", name: "PrimaryPromptsCountsAccumulated" },
  "00541311": { vr: "IS", name: "SecondaryCountsAccumulated" },
  "00541320": { vr: "DS", name: "SliceSensitivityFactor" },
  "00541321": { vr: "DS", name: "DecayFactor" },
  "00541322": { vr: "DS", name: "DoseCalibrationFactor" },
  "00541323": { vr: "DS", name: "ScatterFractionFactor" },
  "00541324": { vr: "DS", name: "DeadTimeFactor" },
  "00541330": { vr: "US", name: "ImageIndex" },
  "00541400": { vr: "CS", name: "CountsIncluded" },
  "00541401": { vr: "CS", name: "DeadTimeCorrectionFlag" },
  "00603000": { vr: "SQ", name: "HistogramSequence" },
  "00603002": { vr: "US", name: "HistogramNumberOfBins" },
  "00603004": { vr: "US|SS", name: "HistogramFirstBinValue" },
  "00603006": { vr: "US|SS", name: "HistogramLastBinValue" },
  "00603008": { vr: "US", name: "HistogramBinWidth" },
  "00603010": { vr: "LO", name: "HistogramExplanation" },
  "00603020": { vr: "UL", name: "HistogramData" },
  "00620001": { vr: "CS", name: "SegmentationType" },
  "00620002": { vr: "SQ", name: "SegmentSequence" },
  "00620003": { vr: "SQ", name: "SegmentedPropertyCategoryCodeSequence" },
  "00620004": { vr: "US", name: "SegmentNumber" },
  "00620005": { vr: "LO", name: "SegmentLabel" },
  "00620006": { vr: "ST", name: "SegmentDescription" },
  "00620008": { vr: "CS", name: "SegmentAlgorithmType" },
  "00620009": { vr: "LO", name: "SegmentAlgorithmName" },
  "0062000A": { vr: "SQ", name: "SegmentIdentificationSequence" },
  "0062000B": { vr: "US", name: "ReferencedSegmentNumber" },
  "0062000C": { vr: "US", name: "RecommendedDisplayGrayscaleValue" },
  "0062000D": { vr: "US", name: "RecommendedDisplayCIELabValue" },
  "0062000E": { vr: "US", name: "MaximumFractionalValue" },
  "0062000F": { vr: "SQ", name: "SegmentedPropertyTypeCodeSequence" },
  "00620010": { vr: "CS", name: "SegmentationFractionalType" },
  "00640002": { vr: "SQ", name: "DeformableRegistrationSequence" },
  "00640003": { vr: "UI", name: "SourceFrameOfReferenceUID" },
  "00640005": { vr: "SQ", name: "DeformableRegistrationGridSequence" },
  "00640007": { vr: "UL", name: "GridDimensions" },
  "00640008": { vr: "FD", name: "GridResolution" },
  "00640009": { vr: "OF", name: "VectorGridData" },
  "0064000F": { vr: "SQ", name: "PreDeformationMatrixRegistrationSequence" },
  "00640010": { vr: "SQ", name: "PostDeformationMatrixRegistrationSequence" },
  "00660001": { vr: "UL", name: "NumberOfSurfaces" },
  "00660002": { vr: "SQ", name: "SurfaceSequence" },
  "00660003": { vr: "UL", name: "SurfaceNumber" },
  "00660004": { vr: "LT", name: "SurfaceComments" },
  "00660009": { vr: "CS", name: "SurfaceProcessing" },
  "0066000A": { vr: "FL", name: "SurfaceProcessingRatio" },
  "0066000B": { vr: "LO", name: "SurfaceProcessingDescription" },
  "0066000C": { vr: "FL", name: "RecommendedPresentationOpacity" },
  "0066000D": { vr: "CS", name: "RecommendedPresentationType" },
  "0066000E": { vr: "CS", name: "FiniteVolume" },
  "00660010": { vr: "CS", name: "Manifold" },
  "00660011": { vr: "SQ", name: "SurfacePointsSequence" },
  "00660012": { vr: "SQ", name: "SurfacePointsNormalsSequence" },
  "00660013": { vr: "SQ", name: "SurfaceMeshPrimitivesSequence" },
  "00660015": { vr: "UL", name: "NumberOfSurfacePoints" },
  "00660016": { vr: "OF", name: "PointCoordinatesData" },
  "00660017": { vr: "FL", name: "PointPositionAccuracy" },
  "00660018": { vr: "FL", name: "MeanPointDistance" },
  "00660019": { vr: "FL", name: "MaximumPointDistance" },
  "0066001A": { vr: "FL", name: "PointsBoundingBoxCoordinates" },
  "0066001B": { vr: "FL", name: "AxisOfRotation" },
  "0066001C": { vr: "FL", name: "CenterOfRotation" },
  "0066001E": { vr: "UL", name: "NumberOfVectors" },
  "0066001F": { vr: "US", name: "VectorDimensionality" },
  "00660020": { vr: "FL", name: "VectorAccuracy" },
  "00660021": { vr: "OF", name: "VectorCoordinateData" },
  "00660023": { vr: "OW", name: "TrianglePointIndexList" },
  "00660024": { vr: "OW", name: "EdgePointIndexList" },
  "00660025": { vr: "OW", name: "VertexPointIndexList" },
  "00660026": { vr: "SQ", name: "TriangleStripSequence" },
  "00660027": { vr: "SQ", name: "TriangleFanSequence" },
  "00660028": { vr: "SQ", name: "LineSequence" },
  "00660029": { vr: "OW", name: "PrimitivePointIndexList" },
  "0066002A": { vr: "UL", name: "SurfaceCount" },
  "0066002B": { vr: "SQ", name: "ReferencedSurfaceSequence" },
  "0066002C": { vr: "UL", name: "ReferencedSurfaceNumber" },
  "0066002D": { vr: "SQ", name: "SegmentSurfaceGenerationAlgorithmIdentificationSequence" },
  "0066002E": { vr: "SQ", name: "SegmentSurfaceSourceInstanceSequence" },
  "0066002F": { vr: "SQ", name: "AlgorithmFamilyCodeSequence" },
  "00660030": { vr: "SQ", name: "AlgorithmNameCodeSequence" },
  "00660031": { vr: "LO", name: "AlgorithmVersion" },
  "00660032": { vr: "LT", name: "AlgorithmParameters" },
  "00660034": { vr: "SQ", name: "FacetSequence" },
  "00660035": { vr: "SQ", name: "SurfaceProcessingAlgorithmIdentificationSequence" },
  "00660036": { vr: "LO", name: "AlgorithmName" },
  "00686210": { vr: "LO", name: "ImplantSize" },
  "00686221": { vr: "LO", name: "ImplantTemplateVersion" },
  "00686222": { vr: "SQ", name: "ReplacedImplantTemplateSequence" },
  "00686223": { vr: "CS", name: "ImplantType" },
  "00686224": { vr: "SQ", name: "DerivationImplantTemplateSequence" },
  "00686225": { vr: "SQ", name: "OriginalImplantTemplateSequence" },
  "00686226": { vr: "DT", name: "EffectiveDateTime" },
  "00686230": { vr: "SQ", name: "ImplantTargetAnatomySequence" },
  "00686260": { vr: "SQ", name: "InformationFromManufacturerSequence" },
  "00686265": { vr: "SQ", name: "NotificationFromManufacturerSequence" },
  "00686270": { vr: "DT", name: "InformationIssueDateTime" },
  "00686280": { vr: "ST", name: "InformationSummary" },
  "006862A0": { vr: "SQ", name: "ImplantRegulatoryDisapprovalCodeSequence" },
  "006862A5": { vr: "FD", name: "OverallTemplateSpatialTolerance" },
  "006862C0": { vr: "SQ", name: "HPGLDocumentSequence" },
  "006862D0": { vr: "US", name: "HPGLDocumentID" },
  "006862D5": { vr: "LO", name: "HPGLDocumentLabel" },
  "006862E0": { vr: "SQ", name: "ViewOrientationCodeSequence" },
  "006862F0": { vr: "FD", name: "ViewOrientationModifier" },
  "006862F2": { vr: "FD", name: "HPGLDocumentScaling" },
  "00686300": { vr: "OB", name: "HPGLDocument" },
  "00686310": { vr: "US", name: "HPGLContourPenNumber" },
  "00686320": { vr: "SQ", name: "HPGLPenSequence" },
  "00686330": { vr: "US", name: "HPGLPenNumber" },
  "00686340": { vr: "LO", name: "HPGLPenLabel" },
  "00686345": { vr: "ST", name: "HPGLPenDescription" },
  "00686346": { vr: "FD", name: "RecommendedRotationPoint" },
  "00686347": { vr: "FD", name: "BoundingRectangle" },
  "00686350": { vr: "US", name: "ImplantTemplate3DModelSurfaceNumber" },
  "00686360": { vr: "SQ", name: "SurfaceModelDescriptionSequence" },
  "00686380": { vr: "LO", name: "SurfaceModelLabel" },
  "00686390": { vr: "FD", name: "SurfaceModelScalingFactor" },
  "006863A0": { vr: "SQ", name: "MaterialsCodeSequence" },
  "006863A4": { vr: "SQ", name: "CoatingMaterialsCodeSequence" },
  "006863A8": { vr: "SQ", name: "ImplantTypeCodeSequence" },
  "006863AC": { vr: "SQ", name: "FixationMethodCodeSequence" },
  "006863B0": { vr: "SQ", name: "MatingFeatureSetsSequence" },
  "006863C0": { vr: "US", name: "MatingFeatureSetID" },
  "006863D0": { vr: "LO", name: "MatingFeatureSetLabel" },
  "006863E0": { vr: "SQ", name: "MatingFeatureSequence" },
  "006863F0": { vr: "US", name: "MatingFeatureID" },
  "00686400": { vr: "SQ", name: "MatingFeatureDegreeOfFreedomSequence" },
  "00686410": { vr: "US", name: "DegreeOfFreedomID" },
  "00686420": { vr: "CS", name: "DegreeOfFreedomType" },
  "00686430": { vr: "SQ", name: "TwoDMatingFeatureCoordinatesSequence" },
  "00686440": { vr: "US", name: "ReferencedHPGLDocumentID" },
  "00686450": { vr: "FD", name: "TwoDMatingPoint" },
  "00686460": { vr: "FD", name: "TwoDMatingAxes" },
  "00686470": { vr: "SQ", name: "TwoDDegreeOfFreedomSequence" },
  "00686490": { vr: "FD", name: "ThreeDDegreeOfFreedomAxis" },
  "006864A0": { vr: "FD", name: "RangeOfFreedom" },
  "006864C0": { vr: "FD", name: "ThreeDMatingPoint" },
  "006864D0": { vr: "FD", name: "ThreeDMatingAxes" },
  "006864F0": { vr: "FD", name: "TwoDDegreeOfFreedomAxis" },
  "00686500": { vr: "SQ", name: "PlanningLandmarkPointSequence" },
  "00686510": { vr: "SQ", name: "PlanningLandmarkLineSequence" },
  "00686520": { vr: "SQ", name: "PlanningLandmarkPlaneSequence" },
  "00686530": { vr: "US", name: "PlanningLandmarkID" },
  "00686540": { vr: "LO", name: "PlanningLandmarkDescription" },
  "00686545": { vr: "SQ", name: "PlanningLandmarkIdentificationCodeSequence" },
  "00686550": { vr: "SQ", name: "TwoDPointCoordinatesSequence" },
  "00686560": { vr: "FD", name: "TwoDPointCoordinates" },
  "00686590": { vr: "FD", name: "ThreeDPointCoordinates" },
  "006865A0": { vr: "SQ", name: "TwoDLineCoordinatesSequence" },
  "006865B0": { vr: "FD", name: "TwoDLineCoordinates" },
  "006865D0": { vr: "FD", name: "ThreeDLineCoordinates" },
  "006865E0": { vr: "SQ", name: "TwoDPlaneCoordinatesSequence" },
  "006865F0": { vr: "FD", name: "TwoDPlaneIntersection" },
  "00686610": { vr: "FD", name: "ThreeDPlaneOrigin" },
  "00686620": { vr: "FD", name: "ThreeDPlaneNormal" },
  "00700001": { vr: "SQ", name: "GraphicAnnotationSequence" },
  "00700002": { vr: "CS", name: "GraphicLayer" },
  "00700003": { vr: "CS", name: "BoundingBoxAnnotationUnits" },
  "00700004": { vr: "CS", name: "AnchorPointAnnotationUnits" },
  "00700005": { vr: "CS", name: "GraphicAnnotationUnits" },
  "00700006": { vr: "ST", name: "UnformattedTextValue" },
  "00700008": { vr: "SQ", name: "TextObjectSequence" },
  "00700009": { vr: "SQ", name: "GraphicObjectSequence" },
  "00700010": { vr: "FL", name: "BoundingBoxTopLeftHandCorner" },
  "00700011": { vr: "FL", name: "BoundingBoxBottomRightHandCorner" },
  "00700012": { vr: "CS", name: "BoundingBoxTextHorizontalJustification" },
  "00700014": { vr: "FL", name: "AnchorPoint" },
  "00700015": { vr: "CS", name: "AnchorPointVisibility" },
  "00700020": { vr: "US", name: "GraphicDimensions" },
  "00700021": { vr: "US", name: "NumberOfGraphicPoints" },
  "00700022": { vr: "FL", name: "GraphicData" },
  "00700023": { vr: "CS", name: "GraphicType" },
  "00700024": { vr: "CS", name: "GraphicFilled" },
  "00700040": { vr: "IS", name: "ImageRotationRetired" },
  "00700041": { vr: "CS", name: "ImageHorizontalFlip" },
  "00700042": { vr: "US", name: "ImageRotation" },
  "00700050": { vr: "US", name: "DisplayedAreaTopLeftHandCornerTrial" },
  "00700051": { vr: "US", name: "DisplayedAreaBottomRightHandCornerTrial" },
  "00700052": { vr: "SL", name: "DisplayedAreaTopLeftHandCorner" },
  "00700053": { vr: "SL", name: "DisplayedAreaBottomRightHandCorner" },
  "0070005A": { vr: "SQ", name: "DisplayedAreaSelectionSequence" },
  "00700060": { vr: "SQ", name: "GraphicLayerSequence" },
  "00700062": { vr: "IS", name: "GraphicLayerOrder" },
  "00700066": { vr: "US", name: "GraphicLayerRecommendedDisplayGrayscaleValue" },
  "00700067": { vr: "US", name: "GraphicLayerRecommendedDisplayRGBValue" },
  "00700068": { vr: "LO", name: "GraphicLayerDescription" },
  "00700080": { vr: "CS", name: "ContentLabel" },
  "00700081": { vr: "LO", name: "ContentDescription" },
  "00700082": { vr: "DA", name: "PresentationCreationDate" },
  "00700083": { vr: "TM", name: "PresentationCreationTime" },
  "00700084": { vr: "PN", name: "ContentCreatorName" },
  "00700086": { vr: "SQ", name: "ContentCreatorIdentificationCodeSequence" },
  "00700087": { vr: "SQ", name: "AlternateContentDescriptionSequence" },
  "00700100": { vr: "CS", name: "PresentationSizeMode" },
  "00700101": { vr: "DS", name: "PresentationPixelSpacing" },
  "00700102": { vr: "IS", name: "PresentationPixelAspectRatio" },
  "00700103": { vr: "FL", name: "PresentationPixelMagnificationRatio" },
  "00700207": { vr: "LO", name: "GraphicGroupLabel" },
  "00700208": { vr: "ST", name: "GraphicGroupDescription" },
  "00700209": { vr: "SQ", name: "CompoundGraphicSequence" },
  "00700226": { vr: "UL", name: "CompoundGraphicInstanceID" },
  "00700227": { vr: "LO", name: "FontName" },
  "00700228": { vr: "CS", name: "FontNameType" },
  "00700229": { vr: "LO", name: "CSSFontName" },
  "00700230": { vr: "FD", name: "RotationAngle" },
  "00700231": { vr: "SQ", name: "TextStyleSequence" },
  "00700232": { vr: "SQ", name: "LineStyleSequence" },
  "00700233": { vr: "SQ", name: "FillStyleSequence" },
  "00700234": { vr: "SQ", name: "GraphicGroupSequence" },
  "00700241": { vr: "US", name: "TextColorCIELabValue" },
  "00700242": { vr: "CS", name: "HorizontalAlignment" },
  "00700243": { vr: "CS", name: "VerticalAlignment" },
  "00700244": { vr: "CS", name: "ShadowStyle" },
  "00700245": { vr: "FL", name: "ShadowOffsetX" },
  "00700246": { vr: "FL", name: "ShadowOffsetY" },
  "00700247": { vr: "US", name: "ShadowColorCIELabValue" },
  "00700248": { vr: "CS", name: "Underlined" },
  "00700249": { vr: "CS", name: "Bold" },
  "00700250": { vr: "CS", name: "Italic" },
  "00700251": { vr: "US", name: "PatternOnColorCIELabValue" },
  "00700252": { vr: "US", name: "PatternOffColorCIELabValue" },
  "00700253": { vr: "FL", name: "LineThickness" },
  "00700254": { vr: "CS", name: "LineDashingStyle" },
  "00700255": { vr: "UL", name: "LinePattern" },
  "00700256": { vr: "OB", name: "FillPattern" },
  "00700257": { vr: "CS", name: "FillMode" },
  "00700258": { vr: "FL", name: "ShadowOpacity" },
  "00700261": { vr: "FL", name: "GapLength" },
  "00700262": { vr: "FL", name: "DiameterOfVisibility" },
  "00700273": { vr: "FL", name: "RotationPoint" },
  "00700274": { vr: "CS", name: "TickAlignment" },
  "00700278": { vr: "CS", name: "ShowTickLabel" },
  "00700279": { vr: "CS", name: "TickLabelAlignment" },
  "00700282": { vr: "CS", name: "CompoundGraphicUnits" },
  "00700284": { vr: "FL", name: "PatternOnOpacity" },
  "00700285": { vr: "FL", name: "PatternOffOpacity" },
  "00700287": { vr: "SQ", name: "MajorTicksSequence" },
  "00700288": { vr: "FL", name: "TickPosition" },
  "00700289": { vr: "SH", name: "TickLabel" },
  "00700294": { vr: "CS", name: "CompoundGraphicType" },
  "00700295": { vr: "UL", name: "GraphicGroupID" },
  "00700306": { vr: "CS", name: "ShapeType" },
  "00700308": { vr: "SQ", name: "RegistrationSequence" },
  "00700309": { vr: "SQ", name: "MatrixRegistrationSequence" },
  "0070030A": { vr: "SQ", name: "MatrixSequence" },
  "0070030C": { vr: "CS", name: "FrameOfReferenceTransformationMatrixType" },
  "0070030D": { vr: "SQ", name: "RegistrationTypeCodeSequence" },
  "0070030F": { vr: "ST", name: "FiducialDescription" },
  "00700310": { vr: "SH", name: "FiducialIdentifier" },
  "00700311": { vr: "SQ", name: "FiducialIdentifierCodeSequence" },
  "00700312": { vr: "FD", name: "ContourUncertaintyRadius" },
  "00700314": { vr: "SQ", name: "UsedFiducialsSequence" },
  "00700318": { vr: "SQ", name: "GraphicCoordinatesDataSequence" },
  "0070031A": { vr: "UI", name: "FiducialUID" },
  "0070031C": { vr: "SQ", name: "FiducialSetSequence" },
  "0070031E": { vr: "SQ", name: "FiducialSequence" },
  "00700401": { vr: "US", name: "GraphicLayerRecommendedDisplayCIELabValue" },
  "00700402": { vr: "SQ", name: "BlendingSequence" },
  "00700403": { vr: "FL", name: "RelativeOpacity" },
  "00700404": { vr: "SQ", name: "ReferencedSpatialRegistrationSequence" },
  "00700405": { vr: "CS", name: "BlendingPosition" },
  "00720002": { vr: "SH", name: "HangingProtocolName" },
  "00720004": { vr: "LO", name: "HangingProtocolDescription" },
  "00720006": { vr: "CS", name: "HangingProtocolLevel" },
  "00720008": { vr: "LO", name: "HangingProtocolCreator" },
  "0072000A": { vr: "DT", name: "HangingProtocolCreationDateTime" },
  "0072000C": { vr: "SQ", name: "HangingProtocolDefinitionSequence" },
  "0072000E": { vr: "SQ", name: "HangingProtocolUserIdentificationCodeSequence" },
  "00720010": { vr: "LO", name: "HangingProtocolUserGroupName" },
  "00720012": { vr: "SQ", name: "SourceHangingProtocolSequence" },
  "00720014": { vr: "US", name: "NumberOfPriorsReferenced" },
  "00720020": { vr: "SQ", name: "ImageSetsSequence" },
  "00720022": { vr: "SQ", name: "ImageSetSelectorSequence" },
  "00720024": { vr: "CS", name: "ImageSetSelectorUsageFlag" },
  "00720026": { vr: "AT", name: "SelectorAttribute" },
  "00720028": { vr: "US", name: "SelectorValueNumber" },
  "00720030": { vr: "SQ", name: "TimeBasedImageSetsSequence" },
  "00720032": { vr: "US", name: "ImageSetNumber" },
  "00720034": { vr: "CS", name: "ImageSetSelectorCategory" },
  "00720038": { vr: "US", name: "RelativeTime" },
  "0072003A": { vr: "CS", name: "RelativeTimeUnits" },
  "0072003C": { vr: "SS", name: "AbstractPriorValue" },
  "0072003E": { vr: "SQ", name: "AbstractPriorCodeSequence" },
  "00720040": { vr: "LO", name: "ImageSetLabel" },
  "00720050": { vr: "CS", name: "SelectorAttributeVR" },
  "00720052": { vr: "AT", name: "SelectorSequencePointer" },
  "00720054": { vr: "LO", name: "SelectorSequencePointerPrivateCreator" },
  "00720056": { vr: "LO", name: "SelectorAttributePrivateCreator" },
  "00720060": { vr: "AT", name: "SelectorATValue" },
  "00720062": { vr: "CS", name: "SelectorCSValue" },
  "00720064": { vr: "IS", name: "SelectorISValue" },
  "00720066": { vr: "LO", name: "SelectorLOValue" },
  "00720068": { vr: "LT", name: "SelectorLTValue" },
  "0072006A": { vr: "PN", name: "SelectorPNValue" },
  "0072006C": { vr: "SH", name: "SelectorSHValue" },
  "0072006E": { vr: "ST", name: "SelectorSTValue" },
  "00720070": { vr: "UT", name: "SelectorUTValue" },
  "00720072": { vr: "DS", name: "SelectorDSValue" },
  "00720074": { vr: "FD", name: "SelectorFDValue" },
  "00720076": { vr: "FL", name: "SelectorFLValue" },
  "00720078": { vr: "UL", name: "SelectorULValue" },
  "0072007A": { vr: "US", name: "SelectorUSValue" },
  "0072007C": { vr: "SL", name: "SelectorSLValue" },
  "0072007E": { vr: "SS", name: "SelectorSSValue" },
  "00720080": { vr: "SQ", name: "SelectorCodeSequenceValue" },
  "00720100": { vr: "US", name: "NumberOfScreens" },
  "00720102": { vr: "SQ", name: "NominalScreenDefinitionSequence" },
  "00720104": { vr: "US", name: "NumberOfVerticalPixels" },
  "00720106": { vr: "US", name: "NumberOfHorizontalPixels" },
  "00720108": { vr: "FD", name: "DisplayEnvironmentSpatialPosition" },
  "0072010A": { vr: "US", name: "ScreenMinimumGrayscaleBitDepth" },
  "0072010C": { vr: "US", name: "ScreenMinimumColorBitDepth" },
  "0072010E": { vr: "US", name: "ApplicationMaximumRepaintTime" },
  "00720200": { vr: "SQ", name: "DisplaySetsSequence" },
  "00720202": { vr: "US", name: "DisplaySetNumber" },
  "00720203": { vr: "LO", name: "DisplaySetLabel" },
  "00720204": { vr: "US", name: "DisplaySetPresentationGroup" },
  "00720206": { vr: "LO", name: "DisplaySetPresentationGroupDescription" },
  "00720208": { vr: "CS", name: "PartialDataDisplayHandling" },
  "00720210": { vr: "SQ", name: "SynchronizedScrollingSequence" },
  "00720212": { vr: "US", name: "DisplaySetScrollingGroup" },
  "00720214": { vr: "SQ", name: "NavigationIndicatorSequence" },
  "00720216": { vr: "US", name: "NavigationDisplaySet" },
  "00720218": { vr: "US", name: "ReferenceDisplaySets" },
  "00720300": { vr: "SQ", name: "ImageBoxesSequence" },
  "00720302": { vr: "US", name: "ImageBoxNumber" },
  "00720304": { vr: "CS", name: "ImageBoxLayoutType" },
  "00720306": { vr: "US", name: "ImageBoxTileHorizontalDimension" },
  "00720308": { vr: "US", name: "ImageBoxTileVerticalDimension" },
  "00720310": { vr: "CS", name: "ImageBoxScrollDirection" },
  "00720312": { vr: "CS", name: "ImageBoxSmallScrollType" },
  "00720314": { vr: "US", name: "ImageBoxSmallScrollAmount" },
  "00720316": { vr: "CS", name: "ImageBoxLargeScrollType" },
  "00720318": { vr: "US", name: "ImageBoxLargeScrollAmount" },
  "00720320": { vr: "US", name: "ImageBoxOverlapPriority" },
  "00720330": { vr: "FD", name: "CineRelativeToRealTime" },
  "00720400": { vr: "SQ", name: "FilterOperationsSequence" },
  "00720402": { vr: "CS", name: "FilterByCategory" },
  "00720404": { vr: "CS", name: "FilterByAttributePresence" },
  "00720406": { vr: "CS", name: "FilterByOperator" },
  "00720420": { vr: "US", name: "StructuredDisplayBackgroundCIELabValue" },
  "00720421": { vr: "US", name: "EmptyImageBoxCIELabValue" },
  "00720422": { vr: "SQ", name: "StructuredDisplayImageBoxSequence" },
  "00720424": { vr: "SQ", name: "StructuredDisplayTextBoxSequence" },
  "00720427": { vr: "SQ", name: "ReferencedFirstFrameSequence" },
  "00720430": { vr: "SQ", name: "ImageBoxSynchronizationSequence" },
  "00720432": { vr: "US", name: "SynchronizedImageBoxList" },
  "00720434": { vr: "CS", name: "TypeOfSynchronization" },
  "00720500": { vr: "CS", name: "BlendingOperationType" },
  "00720510": { vr: "CS", name: "ReformattingOperationType" },
  "00720512": { vr: "FD", name: "ReformattingThickness" },
  "00720514": { vr: "FD", name: "ReformattingInterval" },
  "00720516": { vr: "CS", name: "ReformattingOperationInitialViewDirection" },
  "00720520": { vr: "CS", name: "ThreeDRenderingType" },
  "00720600": { vr: "SQ", name: "SortingOperationsSequence" },
  "00720602": { vr: "CS", name: "SortByCategory" },
  "00720604": { vr: "CS", name: "SortingDirection" },
  "00720700": { vr: "CS", name: "DisplaySetPatientOrientation" },
  "00720702": { vr: "CS", name: "VOIType" },
  "00720704": { vr: "CS", name: "PseudoColorType" },
  "00720705": { vr: "SQ", name: "PseudoColorPaletteInstanceReferenceSequence" },
  "00720706": { vr: "CS", name: "ShowGrayscaleInverted" },
  "00720710": { vr: "CS", name: "ShowImageTrueSizeFlag" },
  "00720712": { vr: "CS", name: "ShowGraphicAnnotationFlag" },
  "00720714": { vr: "CS", name: "ShowPatientDemographicsFlag" },
  "00720716": { vr: "CS", name: "ShowAcquisitionTechniquesFlag" },
  "00720717": { vr: "CS", name: "DisplaySetHorizontalJustification" },
  "00720718": { vr: "CS", name: "DisplaySetVerticalJustification" },
  "00740120": { vr: "FD", name: "ContinuationStartMeterset" },
  "00740121": { vr: "FD", name: "ContinuationEndMeterset" },
  "00741000": { vr: "CS", name: "ProcedureStepState" },
  "00741002": { vr: "SQ", name: "ProcedureStepProgressInformationSequence" },
  "00741004": { vr: "DS", name: "ProcedureStepProgress" },
  "00741006": { vr: "ST", name: "ProcedureStepProgressDescription" },
  "00741008": { vr: "SQ", name: "ProcedureStepCommunicationsURISequence" },
  "0074100a": { vr: "ST", name: "ContactURI" },
  "0074100c": { vr: "LO", name: "ContactDisplayName" },
  "0074100e": { vr: "SQ", name: "ProcedureStepDiscontinuationReasonCodeSequence" },
  "00741020": { vr: "SQ", name: "BeamTaskSequence" },
  "00741022": { vr: "CS", name: "BeamTaskType" },
  "00741024": { vr: "IS", name: "BeamOrderIndexTrial" },
  "00741026": { vr: "FD", name: "TableTopVerticalAdjustedPosition" },
  "00741027": { vr: "FD", name: "TableTopLongitudinalAdjustedPosition" },
  "00741028": { vr: "FD", name: "TableTopLateralAdjustedPosition" },
  "0074102A": { vr: "FD", name: "PatientSupportAdjustedAngle" },
  "0074102B": { vr: "FD", name: "TableTopEccentricAdjustedAngle" },
  "0074102C": { vr: "FD", name: "TableTopPitchAdjustedAngle" },
  "0074102D": { vr: "FD", name: "TableTopRollAdjustedAngle" },
  "00741030": { vr: "SQ", name: "DeliveryVerificationImageSequence" },
  "00741032": { vr: "CS", name: "VerificationImageTiming" },
  "00741034": { vr: "CS", name: "DoubleExposureFlag" },
  "00741036": { vr: "CS", name: "DoubleExposureOrdering" },
  "00741038": { vr: "DS", name: "DoubleExposureMetersetTrial" },
  "0074103A": { vr: "DS", name: "DoubleExposureFieldDeltaTrial" },
  "00741040": { vr: "SQ", name: "RelatedReferenceRTImageSequence" },
  "00741042": { vr: "SQ", name: "GeneralMachineVerificationSequence" },
  "00741044": { vr: "SQ", name: "ConventionalMachineVerificationSequence" },
  "00741046": { vr: "SQ", name: "IonMachineVerificationSequence" },
  "00741048": { vr: "SQ", name: "FailedAttributesSequence" },
  "0074104A": { vr: "SQ", name: "OverriddenAttributesSequence" },
  "0074104C": { vr: "SQ", name: "ConventionalControlPointVerificationSequence" },
  "0074104E": { vr: "SQ", name: "IonControlPointVerificationSequence" },
  "00741050": { vr: "SQ", name: "AttributeOccurrenceSequence" },
  "00741052": { vr: "AT", name: "AttributeOccurrencePointer" },
  "00741054": { vr: "UL", name: "AttributeItemSelector" },
  "00741056": { vr: "LO", name: "AttributeOccurrencePrivateCreator" },
  "00741057": { vr: "IS", name: "SelectorSequencePointerItems" },
  "00741200": { vr: "CS", name: "ScheduledProcedureStepPriority" },
  "00741202": { vr: "LO", name: "WorklistLabel" },
  "00741204": { vr: "LO", name: "ProcedureStepLabel" },
  "00741210": { vr: "SQ", name: "ScheduledProcessingParametersSequence" },
  "00741212": { vr: "SQ", name: "PerformedProcessingParametersSequence" },
  "00741216": { vr: "SQ", name: "UnifiedProcedureStepPerformedProcedureSequence" },
  "00741220": { vr: "SQ", name: "RelatedProcedureStepSequence" },
  "00741222": { vr: "LO", name: "ProcedureStepRelationshipType" },
  "00741224": { vr: "SQ", name: "ReplacedProcedureStepSequence" },
  "00741230": { vr: "LO", name: "DeletionLock" },
  "00741234": { vr: "AE", name: "ReceivingAE" },
  "00741236": { vr: "AE", name: "RequestingAE" },
  "00741238": { vr: "LT", name: "ReasonForCancellation" },
  "00741242": { vr: "CS", name: "SCPStatus" },
  "00741244": { vr: "CS", name: "SubscriptionListStatus" },
  "00741246": { vr: "CS", name: "UnifiedProcedureStepListStatus" },
  "00741324": { vr: "UL", name: "BeamOrderIndex" },
  "00741338": { vr: "FD", name: "DoubleExposureMeterset" },
  "0074133A": { vr: "FD", name: "DoubleExposureFieldDelta" },
  "00760001": { vr: "LO", name: "ImplantAssemblyTemplateName" },
  "00760003": { vr: "LO", name: "ImplantAssemblyTemplateIssuer" },
  "00760006": { vr: "LO", name: "ImplantAssemblyTemplateVersion" },
  "00760008": { vr: "SQ", name: "ReplacedImplantAssemblyTemplateSequence" },
  "0076000A": { vr: "CS", name: "ImplantAssemblyTemplateType" },
  "0076000C": { vr: "SQ", name: "OriginalImplantAssemblyTemplateSequence" },
  "0076000E": { vr: "SQ", name: "DerivationImplantAssemblyTemplateSequence" },
  "00760010": { vr: "SQ", name: "ImplantAssemblyTemplateTargetAnatomySequence" },
  "00760020": { vr: "SQ", name: "ProcedureTypeCodeSequence" },
  "00760030": { vr: "LO", name: "SurgicalTechnique" },
  "00760032": { vr: "SQ", name: "ComponentTypesSequence" },
  "00760034": { vr: "CS", name: "ComponentTypeCodeSequence" },
  "00760036": { vr: "CS", name: "ExclusiveComponentType" },
  "00760038": { vr: "CS", name: "MandatoryComponentType" },
  "00760040": { vr: "SQ", name: "ComponentSequence" },
  "00760055": { vr: "US", name: "ComponentID" },
  "00760060": { vr: "SQ", name: "ComponentAssemblySequence" },
  "00760070": { vr: "US", name: "Component1ReferencedID" },
  "00760080": { vr: "US", name: "Component1ReferencedMatingFeatureSetID" },
  "00760090": { vr: "US", name: "Component1ReferencedMatingFeatureID" },
  "007600A0": { vr: "US", name: "Component2ReferencedID" },
  "007600B0": { vr: "US", name: "Component2ReferencedMatingFeatureSetID" },
  "007600C0": { vr: "US", name: "Component2ReferencedMatingFeatureID" },
  "00780001": { vr: "LO", name: "ImplantTemplateGroupName" },
  "00780010": { vr: "ST", name: "ImplantTemplateGroupDescription" },
  "00780020": { vr: "LO", name: "ImplantTemplateGroupIssuer" },
  "00780024": { vr: "LO", name: "ImplantTemplateGroupVersion" },
  "00780026": { vr: "SQ", name: "ReplacedImplantTemplateGroupSequence" },
  "00780028": { vr: "SQ", name: "ImplantTemplateGroupTargetAnatomySequence" },
  "0078002A": { vr: "SQ", name: "ImplantTemplateGroupMembersSequence" },
  "0078002E": { vr: "US", name: "ImplantTemplateGroupMemberID" },
  "00780050": { vr: "FD", name: "ThreeDImplantTemplateGroupMemberMatchingPoint" },
  "00780060": { vr: "FD", name: "ThreeDImplantTemplateGroupMemberMatchingAxes" },
  "00780070": { vr: "SQ", name: "ImplantTemplateGroupMemberMatching2DCoordinatesSequence" },
  "00780090": { vr: "FD", name: "TwoDImplantTemplateGroupMemberMatchingPoint" },
  "007800A0": { vr: "FD", name: "TwoDImplantTemplateGroupMemberMatchingAxes" },
  "007800B0": { vr: "SQ", name: "ImplantTemplateGroupVariationDimensionSequence" },
  "007800B2": { vr: "LO", name: "ImplantTemplateGroupVariationDimensionName" },
  "007800B4": { vr: "SQ", name: "ImplantTemplateGroupVariationDimensionRankSequence" },
  "007800B6": { vr: "US", name: "ReferencedImplantTemplateGroupMemberID" },
  "007800B8": { vr: "US", name: "ImplantTemplateGroupVariationDimensionRank" },
  "00880130": { vr: "SH", name: "StorageMediaFileSetID" },
  "00880140": { vr: "UI", name: "StorageMediaFileSetUID" },
  "00880200": { vr: "SQ", name: "IconImageSequence" },
  "00880904": { vr: "LO", name: "TopicTitle" },
  "00880906": { vr: "ST", name: "TopicSubject" },
  "00880910": { vr: "LO", name: "TopicAuthor" },
  "00880912": { vr: "LO", name: "TopicKeywords" },
  "01000410": { vr: "CS", name: "SOPInstanceStatus" },
  "01000420": { vr: "DT", name: "SOPAuthorizationDateTime" },
  "01000424": { vr: "LT", name: "SOPAuthorizationComment" },
  "01000426": { vr: "LO", name: "AuthorizationEquipmentCertificationNumber" },
  "04000005": { vr: "US", name: "MACIDNumber" },
  "04000010": { vr: "UI", name: "MACCalculationTransferSyntaxUID" },
  "04000015": { vr: "CS", name: "MACAlgorithm" },
  "04000020": { vr: "AT", name: "DataElementsSigned" },
  "04000100": { vr: "UI", name: "DigitalSignatureUID" },
  "04000105": { vr: "DT", name: "DigitalSignatureDateTime" },
  "04000110": { vr: "CS", name: "CertificateType" },
  "04000115": { vr: "OB", name: "CertificateOfSigner" },
  "04000120": { vr: "OB", name: "Signature" },
  "04000305": { vr: "CS", name: "CertifiedTimestampType" },
  "04000310": { vr: "OB", name: "CertifiedTimestamp" },
  "04000401": { vr: "SQ", name: "DigitalSignaturePurposeCodeSequence" },
  "04000402": { vr: "SQ", name: "ReferencedDigitalSignatureSequence" },
  "04000403": { vr: "SQ", name: "ReferencedSOPInstanceMACSequence" },
  "04000404": { vr: "OB", name: "MAC" },
  "04000500": { vr: "SQ", name: "EncryptedAttributesSequence" },
  "04000510": { vr: "UI", name: "EncryptedContentTransferSyntaxUID" },
  "04000520": { vr: "OB", name: "EncryptedContent" },
  "04000550": { vr: "SQ", name: "ModifiedAttributesSequence" },
  "04000561": { vr: "SQ", name: "OriginalAttributesSequence" },
  "04000562": { vr: "DT", name: "AttributeModificationDateTime" },
  "04000563": { vr: "LO", name: "ModifyingSystem" },
  "04000564": { vr: "LO", name: "SourceOfPreviousValues" },
  "04000565": { vr: "CS", name: "ReasonForTheAttributeModification" },
  "1000xxx0": { vr: "US", name: "EscapeTriplet" },
  "1000xxx1": { vr: "US", name: "RunLengthTriplet" },
  "1000xxx2": { vr: "US", name: "HuffmanTableSize" },
  "1000xxx3": { vr: "US", name: "HuffmanTableTriplet" },
  "1000xxx4": { vr: "US", name: "ShiftTableSize" },
  "1000xxx5": { vr: "US", name: "ShiftTableTriplet" },
  "1010xxxx": { vr: "US", name: "ZonalMap" },
  "20000010": { vr: "IS", name: "NumberOfCopies" },
  "2000001E": { vr: "SQ", name: "PrinterConfigurationSequence" },
  "20000020": { vr: "CS", name: "PrintPriority" },
  "20000030": { vr: "CS", name: "MediumType" },
  "20000040": { vr: "CS", name: "FilmDestination" },
  "20000050": { vr: "LO", name: "FilmSessionLabel" },
  "20000060": { vr: "IS", name: "MemoryAllocation" },
  "20000061": { vr: "IS", name: "MaximumMemoryAllocation" },
  "20000062": { vr: "CS", name: "ColorImagePrintingFlag" },
  "20000063": { vr: "CS", name: "CollationFlag" },
  "20000065": { vr: "CS", name: "AnnotationFlag" },
  "20000067": { vr: "CS", name: "ImageOverlayFlag" },
  "20000069": { vr: "CS", name: "PresentationLUTFlag" },
  "2000006A": { vr: "CS", name: "ImageBoxPresentationLUTFlag" },
  "200000A0": { vr: "US", name: "MemoryBitDepth" },
  "200000A1": { vr: "US", name: "PrintingBitDepth" },
  "200000A2": { vr: "SQ", name: "MediaInstalledSequence" },
  "200000A4": { vr: "SQ", name: "OtherMediaAvailableSequence" },
  "200000A8": { vr: "SQ", name: "SupportedImageDisplayFormatsSequence" },
  "20000500": { vr: "SQ", name: "ReferencedFilmBoxSequence" },
  "20000510": { vr: "SQ", name: "ReferencedStoredPrintSequence" },
  "20100010": { vr: "ST", name: "ImageDisplayFormat" },
  "20100030": { vr: "CS", name: "AnnotationDisplayFormatID" },
  "20100040": { vr: "CS", name: "FilmOrientation" },
  "20100050": { vr: "CS", name: "FilmSizeID" },
  "20100052": { vr: "CS", name: "PrinterResolutionID" },
  "20100054": { vr: "CS", name: "DefaultPrinterResolutionID" },
  "20100060": { vr: "CS", name: "MagnificationType" },
  "20100080": { vr: "CS", name: "SmoothingType" },
  "201000A6": { vr: "CS", name: "DefaultMagnificationType" },
  "201000A7": { vr: "CS", name: "OtherMagnificationTypesAvailable" },
  "201000A8": { vr: "CS", name: "DefaultSmoothingType" },
  "201000A9": { vr: "CS", name: "OtherSmoothingTypesAvailable" },
  "20100100": { vr: "CS", name: "BorderDensity" },
  "20100110": { vr: "CS", name: "EmptyImageDensity" },
  "20100120": { vr: "US", name: "MinDensity" },
  "20100130": { vr: "US", name: "MaxDensity" },
  "20100140": { vr: "CS", name: "Trim" },
  "20100150": { vr: "ST", name: "ConfigurationInformation" },
  "20100152": { vr: "LT", name: "ConfigurationInformationDescription" },
  "20100154": { vr: "IS", name: "MaximumCollatedFilms" },
  "2010015E": { vr: "US", name: "Illumination" },
  "20100160": { vr: "US", name: "ReflectedAmbientLight" },
  "20100376": { vr: "DS", name: "PrinterPixelSpacing" },
  "20100500": { vr: "SQ", name: "ReferencedFilmSessionSequence" },
  "20100510": { vr: "SQ", name: "ReferencedImageBoxSequence" },
  "20100520": { vr: "SQ", name: "ReferencedBasicAnnotationBoxSequence" },
  "20200010": { vr: "US", name: "ImageBoxPosition" },
  "20200020": { vr: "CS", name: "Polarity" },
  "20200030": { vr: "DS", name: "RequestedImageSize" },
  "20200040": { vr: "CS", name: "RequestedDecimateCropBehavior" },
  "20200050": { vr: "CS", name: "RequestedResolutionID" },
  "202000A0": { vr: "CS", name: "RequestedImageSizeFlag" },
  "202000A2": { vr: "CS", name: "DecimateCropResult" },
  "20200110": { vr: "SQ", name: "BasicGrayscaleImageSequence" },
  "20200111": { vr: "SQ", name: "BasicColorImageSequence" },
  "20200130": { vr: "SQ", name: "ReferencedImageOverlayBoxSequence" },
  "20200140": { vr: "SQ", name: "ReferencedVOILUTBoxSequence" },
  "20300010": { vr: "US", name: "AnnotationPosition" },
  "20300020": { vr: "LO", name: "TextString" },
  "20400010": { vr: "SQ", name: "ReferencedOverlayPlaneSequence" },
  "20400011": { vr: "US", name: "ReferencedOverlayPlaneGroups" },
  "20400020": { vr: "SQ", name: "OverlayPixelDataSequence" },
  "20400060": { vr: "CS", name: "OverlayMagnificationType" },
  "20400070": { vr: "CS", name: "OverlaySmoothingType" },
  "20400072": { vr: "CS", name: "OverlayOrImageMagnification" },
  "20400074": { vr: "US", name: "MagnifyToNumberOfColumns" },
  "20400080": { vr: "CS", name: "OverlayForegroundDensity" },
  "20400082": { vr: "CS", name: "OverlayBackgroundDensity" },
  "20400090": { vr: "CS", name: "OverlayMode" },
  "20400100": { vr: "CS", name: "ThresholdDensity" },
  "20400500": { vr: "SQ", name: "ReferencedImageBoxSequenceRetired" },
  "20500010": { vr: "SQ", name: "PresentationLUTSequence" },
  "20500020": { vr: "CS", name: "PresentationLUTShape" },
  "20500500": { vr: "SQ", name: "ReferencedPresentationLUTSequence" },
  "21000010": { vr: "SH", name: "PrintJobID" },
  "21000020": { vr: "CS", name: "ExecutionStatus" },
  "21000030": { vr: "CS", name: "ExecutionStatusInfo" },
  "21000040": { vr: "DA", name: "CreationDate" },
  "21000050": { vr: "TM", name: "CreationTime" },
  "21000070": { vr: "AE", name: "Originator" },
  "21000140": { vr: "AE", name: "DestinationAE" },
  "21000160": { vr: "SH", name: "OwnerID" },
  "21000170": { vr: "IS", name: "NumberOfFilms" },
  "21000500": { vr: "SQ", name: "ReferencedPrintJobSequencePullStoredPrint" },
  "21100010": { vr: "CS", name: "PrinterStatus" },
  "21100020": { vr: "CS", name: "PrinterStatusInfo" },
  "21100030": { vr: "LO", name: "PrinterName" },
  "21100099": { vr: "SH", name: "PrintQueueID" },
  "21200010": { vr: "CS", name: "QueueStatus" },
  "21200050": { vr: "SQ", name: "PrintJobDescriptionSequence" },
  "21200070": { vr: "SQ", name: "ReferencedPrintJobSequence" },
  "21300010": { vr: "SQ", name: "PrintManagementCapabilitiesSequence" },
  "21300015": { vr: "SQ", name: "PrinterCharacteristicsSequence" },
  "21300030": { vr: "SQ", name: "FilmBoxContentSequence" },
  "21300040": { vr: "SQ", name: "ImageBoxContentSequence" },
  "21300050": { vr: "SQ", name: "AnnotationContentSequence" },
  "21300060": { vr: "SQ", name: "ImageOverlayBoxContentSequence" },
  "21300080": { vr: "SQ", name: "PresentationLUTContentSequence" },
  "213000A0": { vr: "SQ", name: "ProposedStudySequence" },
  "213000C0": { vr: "SQ", name: "OriginalImageSequence" },
  "22000001": { vr: "CS", name: "LabelUsingInformationExtractedFromInstances" },
  "22000002": { vr: "UT", name: "LabelText" },
  "22000003": { vr: "CS", name: "LabelStyleSelection" },
  "22000004": { vr: "LT", name: "MediaDisposition" },
  "22000005": { vr: "LT", name: "BarcodeValue" },
  "22000006": { vr: "CS", name: "BarcodeSymbology" },
  "22000007": { vr: "CS", name: "AllowMediaSplitting" },
  "22000008": { vr: "CS", name: "IncludeNonDICOMObjects" },
  "22000009": { vr: "CS", name: "IncludeDisplayApplication" },
  "2200000A": { vr: "CS", name: "PreserveCompositeInstancesAfterMediaCreation" },
  "2200000B": { vr: "US", name: "TotalNumberOfPiecesOfMediaCreated" },
  "2200000C": { vr: "LO", name: "RequestedMediaApplicationProfile" },
  "2200000D": { vr: "SQ", name: "ReferencedStorageMediaSequence" },
  "2200000E": { vr: "AT", name: "FailureAttributes" },
  "2200000F": { vr: "CS", name: "AllowLossyCompression" },
  "22000020": { vr: "CS", name: "RequestPriority" },
  "30020002": { vr: "SH", name: "RTImageLabel" },
  "30020003": { vr: "LO", name: "RTImageName" },
  "30020004": { vr: "ST", name: "RTImageDescription" },
  "3002000A": { vr: "CS", name: "ReportedValuesOrigin" },
  "3002000C": { vr: "CS", name: "RTImagePlane" },
  "3002000D": { vr: "DS", name: "XRayImageReceptorTranslation" },
  "3002000E": { vr: "DS", name: "XRayImageReceptorAngle" },
  "30020010": { vr: "DS", name: "RTImageOrientation" },
  "30020011": { vr: "DS", name: "ImagePlanePixelSpacing" },
  "30020012": { vr: "DS", name: "RTImagePosition" },
  "30020020": { vr: "SH", name: "RadiationMachineName" },
  "30020022": { vr: "DS", name: "RadiationMachineSAD" },
  "30020024": { vr: "DS", name: "RadiationMachineSSD" },
  "30020026": { vr: "DS", name: "RTImageSID" },
  "30020028": { vr: "DS", name: "SourceToReferenceObjectDistance" },
  "30020029": { vr: "IS", name: "FractionNumber" },
  "30020030": { vr: "SQ", name: "ExposureSequence" },
  "30020032": { vr: "DS", name: "MetersetExposure" },
  "30020034": { vr: "DS", name: "DiaphragmPosition" },
  "30020040": { vr: "SQ", name: "FluenceMapSequence" },
  "30020041": { vr: "CS", name: "FluenceDataSource" },
  "30020042": { vr: "DS", name: "FluenceDataScale" },
  "30020050": { vr: "SQ", name: "PrimaryFluenceModeSequence" },
  "30020051": { vr: "CS", name: "FluenceMode" },
  "30020052": { vr: "SH", name: "FluenceModeID" },
  "30040001": { vr: "CS", name: "DVHType" },
  "30040002": { vr: "CS", name: "DoseUnits" },
  "30040004": { vr: "CS", name: "DoseType" },
  "30040006": { vr: "LO", name: "DoseComment" },
  "30040008": { vr: "DS", name: "NormalizationPoint" },
  "3004000A": { vr: "CS", name: "DoseSummationType" },
  "3004000C": { vr: "DS", name: "GridFrameOffsetVector" },
  "3004000E": { vr: "DS", name: "DoseGridScaling" },
  "30040010": { vr: "SQ", name: "RTDoseROISequence" },
  "30040012": { vr: "DS", name: "DoseValue" },
  "30040014": { vr: "CS", name: "TissueHeterogeneityCorrection" },
  "30040040": { vr: "DS", name: "DVHNormalizationPoint" },
  "30040042": { vr: "DS", name: "DVHNormalizationDoseValue" },
  "30040050": { vr: "SQ", name: "DVHSequence" },
  "30040052": { vr: "DS", name: "DVHDoseScaling" },
  "30040054": { vr: "CS", name: "DVHVolumeUnits" },
  "30040056": { vr: "IS", name: "DVHNumberOfBins" },
  "30040058": { vr: "DS", name: "DVHData" },
  "30040060": { vr: "SQ", name: "DVHReferencedROISequence" },
  "30040062": { vr: "CS", name: "DVHROIContributionType" },
  "30040070": { vr: "DS", name: "DVHMinimumDose" },
  "30040072": { vr: "DS", name: "DVHMaximumDose" },
  "30040074": { vr: "DS", name: "DVHMeanDose" },
  "30060002": { vr: "SH", name: "StructureSetLabel" },
  "30060004": { vr: "LO", name: "StructureSetName" },
  "30060006": { vr: "ST", name: "StructureSetDescription" },
  "30060008": { vr: "DA", name: "StructureSetDate" },
  "30060009": { vr: "TM", name: "StructureSetTime" },
  "30060010": { vr: "SQ", name: "ReferencedFrameOfReferenceSequence" },
  "30060012": { vr: "SQ", name: "RTReferencedStudySequence" },
  "30060014": { vr: "SQ", name: "RTReferencedSeriesSequence" },
  "30060016": { vr: "SQ", name: "ContourImageSequence" },
  "30060020": { vr: "SQ", name: "StructureSetROISequence" },
  "30060022": { vr: "IS", name: "ROINumber" },
  "30060024": { vr: "UI", name: "ReferencedFrameOfReferenceUID" },
  "30060026": { vr: "LO", name: "ROIName" },
  "30060028": { vr: "ST", name: "ROIDescription" },
  "3006002A": { vr: "IS", name: "ROIDisplayColor" },
  "3006002C": { vr: "DS", name: "ROIVolume" },
  "30060030": { vr: "SQ", name: "RTRelatedROISequence" },
  "30060033": { vr: "CS", name: "RTROIRelationship" },
  "30060036": { vr: "CS", name: "ROIGenerationAlgorithm" },
  "30060038": { vr: "LO", name: "ROIGenerationDescription" },
  "30060039": { vr: "SQ", name: "ROIContourSequence" },
  "30060040": { vr: "SQ", name: "ContourSequence" },
  "30060042": { vr: "CS", name: "ContourGeometricType" },
  "30060044": { vr: "DS", name: "ContourSlabThickness" },
  "30060045": { vr: "DS", name: "ContourOffsetVector" },
  "30060046": { vr: "IS", name: "NumberOfContourPoints" },
  "30060048": { vr: "IS", name: "ContourNumber" },
  "30060049": { vr: "IS", name: "AttachedContours" },
  "30060050": { vr: "DS", name: "ContourData" },
  "30060080": { vr: "SQ", name: "RTROIObservationsSequence" },
  "30060082": { vr: "IS", name: "ObservationNumber" },
  "30060084": { vr: "IS", name: "ReferencedROINumber" },
  "30060085": { vr: "SH", name: "ROIObservationLabel" },
  "30060086": { vr: "SQ", name: "RTROIIdentificationCodeSequence" },
  "30060088": { vr: "ST", name: "ROIObservationDescription" },
  "300600A0": { vr: "SQ", name: "RelatedRTROIObservationsSequence" },
  "300600A4": { vr: "CS", name: "RTROIInterpretedType" },
  "300600A6": { vr: "PN", name: "ROIInterpreter" },
  "300600B0": { vr: "SQ", name: "ROIPhysicalPropertiesSequence" },
  "300600B2": { vr: "CS", name: "ROIPhysicalProperty" },
  "300600B4": { vr: "DS", name: "ROIPhysicalPropertyValue" },
  "300600B6": { vr: "SQ", name: "ROIElementalCompositionSequence" },
  "300600B7": { vr: "US", name: "ROIElementalCompositionAtomicNumber" },
  "300600B8": { vr: "FL", name: "ROIElementalCompositionAtomicMassFraction" },
  "300600C0": { vr: "SQ", name: "FrameOfReferenceRelationshipSequence" },
  "300600C2": { vr: "UI", name: "RelatedFrameOfReferenceUID" },
  "300600C4": { vr: "CS", name: "FrameOfReferenceTransformationType" },
  "300600C6": { vr: "DS", name: "FrameOfReferenceTransformationMatrix" },
  "300600C8": { vr: "LO", name: "FrameOfReferenceTransformationComment" },
  "30080010": { vr: "SQ", name: "MeasuredDoseReferenceSequence" },
  "30080012": { vr: "ST", name: "MeasuredDoseDescription" },
  "30080014": { vr: "CS", name: "MeasuredDoseType" },
  "30080016": { vr: "DS", name: "MeasuredDoseValue" },
  "30080020": { vr: "SQ", name: "TreatmentSessionBeamSequence" },
  "30080021": { vr: "SQ", name: "TreatmentSessionIonBeamSequence" },
  "30080022": { vr: "IS", name: "CurrentFractionNumber" },
  "30080024": { vr: "DA", name: "TreatmentControlPointDate" },
  "30080025": { vr: "TM", name: "TreatmentControlPointTime" },
  "3008002A": { vr: "CS", name: "TreatmentTerminationStatus" },
  "3008002B": { vr: "SH", name: "TreatmentTerminationCode" },
  "3008002C": { vr: "CS", name: "TreatmentVerificationStatus" },
  "30080030": { vr: "SQ", name: "ReferencedTreatmentRecordSequence" },
  "30080032": { vr: "DS", name: "SpecifiedPrimaryMeterset" },
  "30080033": { vr: "DS", name: "SpecifiedSecondaryMeterset" },
  "30080036": { vr: "DS", name: "DeliveredPrimaryMeterset" },
  "30080037": { vr: "DS", name: "DeliveredSecondaryMeterset" },
  "3008003A": { vr: "DS", name: "SpecifiedTreatmentTime" },
  "3008003B": { vr: "DS", name: "DeliveredTreatmentTime" },
  "30080040": { vr: "SQ", name: "ControlPointDeliverySequence" },
  "30080041": { vr: "SQ", name: "IonControlPointDeliverySequence" },
  "30080042": { vr: "DS", name: "SpecifiedMeterset" },
  "30080044": { vr: "DS", name: "DeliveredMeterset" },
  "30080045": { vr: "FL", name: "MetersetRateSet" },
  "30080046": { vr: "FL", name: "MetersetRateDelivered" },
  "30080047": { vr: "FL", name: "ScanSpotMetersetsDelivered" },
  "30080048": { vr: "DS", name: "DoseRateDelivered" },
  "30080050": { vr: "SQ", name: "TreatmentSummaryCalculatedDoseReferenceSequence" },
  "30080052": { vr: "DS", name: "CumulativeDoseToDoseReference" },
  "30080054": { vr: "DA", name: "FirstTreatmentDate" },
  "30080056": { vr: "DA", name: "MostRecentTreatmentDate" },
  "3008005A": { vr: "IS", name: "NumberOfFractionsDelivered" },
  "30080060": { vr: "SQ", name: "OverrideSequence" },
  "30080061": { vr: "AT", name: "ParameterSequencePointer" },
  "30080062": { vr: "AT", name: "OverrideParameterPointer" },
  "30080063": { vr: "IS", name: "ParameterItemIndex" },
  "30080064": { vr: "IS", name: "MeasuredDoseReferenceNumber" },
  "30080065": { vr: "AT", name: "ParameterPointer" },
  "30080066": { vr: "ST", name: "OverrideReason" },
  "30080068": { vr: "SQ", name: "CorrectedParameterSequence" },
  "3008006A": { vr: "FL", name: "CorrectionValue" },
  "30080070": { vr: "SQ", name: "CalculatedDoseReferenceSequence" },
  "30080072": { vr: "IS", name: "CalculatedDoseReferenceNumber" },
  "30080074": { vr: "ST", name: "CalculatedDoseReferenceDescription" },
  "30080076": { vr: "DS", name: "CalculatedDoseReferenceDoseValue" },
  "30080078": { vr: "DS", name: "StartMeterset" },
  "3008007A": { vr: "DS", name: "EndMeterset" },
  "30080080": { vr: "SQ", name: "ReferencedMeasuredDoseReferenceSequence" },
  "30080082": { vr: "IS", name: "ReferencedMeasuredDoseReferenceNumber" },
  "30080090": { vr: "SQ", name: "ReferencedCalculatedDoseReferenceSequence" },
  "30080092": { vr: "IS", name: "ReferencedCalculatedDoseReferenceNumber" },
  "300800A0": { vr: "SQ", name: "BeamLimitingDeviceLeafPairsSequence" },
  "300800B0": { vr: "SQ", name: "RecordedWedgeSequence" },
  "300800C0": { vr: "SQ", name: "RecordedCompensatorSequence" },
  "300800D0": { vr: "SQ", name: "RecordedBlockSequence" },
  "300800E0": { vr: "SQ", name: "TreatmentSummaryMeasuredDoseReferenceSequence" },
  "300800F0": { vr: "SQ", name: "RecordedSnoutSequence" },
  "300800F2": { vr: "SQ", name: "RecordedRangeShifterSequence" },
  "300800F4": { vr: "SQ", name: "RecordedLateralSpreadingDeviceSequence" },
  "300800F6": { vr: "SQ", name: "RecordedRangeModulatorSequence" },
  "30080100": { vr: "SQ", name: "RecordedSourceSequence" },
  "30080105": { vr: "LO", name: "SourceSerialNumber" },
  "30080110": { vr: "SQ", name: "TreatmentSessionApplicationSetupSequence" },
  "30080116": { vr: "CS", name: "ApplicationSetupCheck" },
  "30080120": { vr: "SQ", name: "RecordedBrachyAccessoryDeviceSequence" },
  "30080122": { vr: "IS", name: "ReferencedBrachyAccessoryDeviceNumber" },
  "30080130": { vr: "SQ", name: "RecordedChannelSequence" },
  "30080132": { vr: "DS", name: "SpecifiedChannelTotalTime" },
  "30080134": { vr: "DS", name: "DeliveredChannelTotalTime" },
  "30080136": { vr: "IS", name: "SpecifiedNumberOfPulses" },
  "30080138": { vr: "IS", name: "DeliveredNumberOfPulses" },
  "3008013A": { vr: "DS", name: "SpecifiedPulseRepetitionInterval" },
  "3008013C": { vr: "DS", name: "DeliveredPulseRepetitionInterval" },
  "30080140": { vr: "SQ", name: "RecordedSourceApplicatorSequence" },
  "30080142": { vr: "IS", name: "ReferencedSourceApplicatorNumber" },
  "30080150": { vr: "SQ", name: "RecordedChannelShieldSequence" },
  "30080152": { vr: "IS", name: "ReferencedChannelShieldNumber" },
  "30080160": { vr: "SQ", name: "BrachyControlPointDeliveredSequence" },
  "30080162": { vr: "DA", name: "SafePositionExitDate" },
  "30080164": { vr: "TM", name: "SafePositionExitTime" },
  "30080166": { vr: "DA", name: "SafePositionReturnDate" },
  "30080168": { vr: "TM", name: "SafePositionReturnTime" },
  "30080200": { vr: "CS", name: "CurrentTreatmentStatus" },
  "30080202": { vr: "ST", name: "TreatmentStatusComment" },
  "30080220": { vr: "SQ", name: "FractionGroupSummarySequence" },
  "30080223": { vr: "IS", name: "ReferencedFractionNumber" },
  "30080224": { vr: "CS", name: "FractionGroupType" },
  "30080230": { vr: "CS", name: "BeamStopperPosition" },
  "30080240": { vr: "SQ", name: "FractionStatusSummarySequence" },
  "30080250": { vr: "DA", name: "TreatmentDate" },
  "30080251": { vr: "TM", name: "TreatmentTime" },
  "300A0002": { vr: "SH", name: "RTPlanLabel" },
  "300A0003": { vr: "LO", name: "RTPlanName" },
  "300A0004": { vr: "ST", name: "RTPlanDescription" },
  "300A0006": { vr: "DA", name: "RTPlanDate" },
  "300A0007": { vr: "TM", name: "RTPlanTime" },
  "300A0009": { vr: "LO", name: "TreatmentProtocols" },
  "300A000A": { vr: "CS", name: "PlanIntent" },
  "300A000B": { vr: "LO", name: "TreatmentSites" },
  "300A000C": { vr: "CS", name: "RTPlanGeometry" },
  "300A000E": { vr: "ST", name: "PrescriptionDescription" },
  "300A0010": { vr: "SQ", name: "DoseReferenceSequence" },
  "300A0012": { vr: "IS", name: "DoseReferenceNumber" },
  "300A0013": { vr: "UI", name: "DoseReferenceUID" },
  "300A0014": { vr: "CS", name: "DoseReferenceStructureType" },
  "300A0015": { vr: "CS", name: "NominalBeamEnergyUnit" },
  "300A0016": { vr: "LO", name: "DoseReferenceDescription" },
  "300A0018": { vr: "DS", name: "DoseReferencePointCoordinates" },
  "300A001A": { vr: "DS", name: "NominalPriorDose" },
  "300A0020": { vr: "CS", name: "DoseReferenceType" },
  "300A0021": { vr: "DS", name: "ConstraintWeight" },
  "300A0022": { vr: "DS", name: "DeliveryWarningDose" },
  "300A0023": { vr: "DS", name: "DeliveryMaximumDose" },
  "300A0025": { vr: "DS", name: "TargetMinimumDose" },
  "300A0026": { vr: "DS", name: "TargetPrescriptionDose" },
  "300A0027": { vr: "DS", name: "TargetMaximumDose" },
  "300A0028": { vr: "DS", name: "TargetUnderdoseVolumeFraction" },
  "300A002A": { vr: "DS", name: "OrganAtRiskFullVolumeDose" },
  "300A002B": { vr: "DS", name: "OrganAtRiskLimitDose" },
  "300A002C": { vr: "DS", name: "OrganAtRiskMaximumDose" },
  "300A002D": { vr: "DS", name: "OrganAtRiskOverdoseVolumeFraction" },
  "300A0040": { vr: "SQ", name: "ToleranceTableSequence" },
  "300A0042": { vr: "IS", name: "ToleranceTableNumber" },
  "300A0043": { vr: "SH", name: "ToleranceTableLabel" },
  "300A0044": { vr: "DS", name: "GantryAngleTolerance" },
  "300A0046": { vr: "DS", name: "BeamLimitingDeviceAngleTolerance" },
  "300A0048": { vr: "SQ", name: "BeamLimitingDeviceToleranceSequence" },
  "300A004A": { vr: "DS", name: "BeamLimitingDevicePositionTolerance" },
  "300A004B": { vr: "FL", name: "SnoutPositionTolerance" },
  "300A004C": { vr: "DS", name: "PatientSupportAngleTolerance" },
  "300A004E": { vr: "DS", name: "TableTopEccentricAngleTolerance" },
  "300A004F": { vr: "FL", name: "TableTopPitchAngleTolerance" },
  "300A0050": { vr: "FL", name: "TableTopRollAngleTolerance" },
  "300A0051": { vr: "DS", name: "TableTopVerticalPositionTolerance" },
  "300A0052": { vr: "DS", name: "TableTopLongitudinalPositionTolerance" },
  "300A0053": { vr: "DS", name: "TableTopLateralPositionTolerance" },
  "300A0055": { vr: "CS", name: "RTPlanRelationship" },
  "300A0070": { vr: "SQ", name: "FractionGroupSequence" },
  "300A0071": { vr: "IS", name: "FractionGroupNumber" },
  "300A0072": { vr: "LO", name: "FractionGroupDescription" },
  "300A0078": { vr: "IS", name: "NumberOfFractionsPlanned" },
  "300A0079": { vr: "IS", name: "NumberOfFractionPatternDigitsPerDay" },
  "300A007A": { vr: "IS", name: "RepeatFractionCycleLength" },
  "300A007B": { vr: "LT", name: "FractionPattern" },
  "300A0080": { vr: "IS", name: "NumberOfBeams" },
  "300A0082": { vr: "DS", name: "BeamDoseSpecificationPoint" },
  "300A0084": { vr: "DS", name: "BeamDose" },
  "300A0086": { vr: "DS", name: "BeamMeterset" },
  "300A0088": { vr: "FL", name: "BeamDosePointDepth" },
  "300A0089": { vr: "FL", name: "BeamDosePointEquivalentDepth" },
  "300A008A": { vr: "FL", name: "BeamDosePointSSD" },
  "300A00A0": { vr: "IS", name: "NumberOfBrachyApplicationSetups" },
  "300A00A2": { vr: "DS", name: "BrachyApplicationSetupDoseSpecificationPoint" },
  "300A00A4": { vr: "DS", name: "BrachyApplicationSetupDose" },
  "300A00B0": { vr: "SQ", name: "BeamSequence" },
  "300A00B2": { vr: "SH", name: "TreatmentMachineName" },
  "300A00B3": { vr: "CS", name: "PrimaryDosimeterUnit" },
  "300A00B4": { vr: "DS", name: "SourceAxisDistance" },
  "300A00B6": { vr: "SQ", name: "BeamLimitingDeviceSequence" },
  "300A00B8": { vr: "CS", name: "RTBeamLimitingDeviceType" },
  "300A00BA": { vr: "DS", name: "SourceToBeamLimitingDeviceDistance" },
  "300A00BB": { vr: "FL", name: "IsocenterToBeamLimitingDeviceDistance" },
  "300A00BC": { vr: "IS", name: "NumberOfLeafJawPairs" },
  "300A00BE": { vr: "DS", name: "LeafPositionBoundaries" },
  "300A00C0": { vr: "IS", name: "BeamNumber" },
  "300A00C2": { vr: "LO", name: "BeamName" },
  "300A00C3": { vr: "ST", name: "BeamDescription" },
  "300A00C4": { vr: "CS", name: "BeamType" },
  "300A00C6": { vr: "CS", name: "RadiationType" },
  "300A00C7": { vr: "CS", name: "HighDoseTechniqueType" },
  "300A00C8": { vr: "IS", name: "ReferenceImageNumber" },
  "300A00CA": { vr: "SQ", name: "PlannedVerificationImageSequence" },
  "300A00CC": { vr: "LO", name: "ImagingDeviceSpecificAcquisitionParameters" },
  "300A00CE": { vr: "CS", name: "TreatmentDeliveryType" },
  "300A00D0": { vr: "IS", name: "NumberOfWedges" },
  "300A00D1": { vr: "SQ", name: "WedgeSequence" },
  "300A00D2": { vr: "IS", name: "WedgeNumber" },
  "300A00D3": { vr: "CS", name: "WedgeType" },
  "300A00D4": { vr: "SH", name: "WedgeID" },
  "300A00D5": { vr: "IS", name: "WedgeAngle" },
  "300A00D6": { vr: "DS", name: "WedgeFactor" },
  "300A00D7": { vr: "FL", name: "TotalWedgeTrayWaterEquivalentThickness" },
  "300A00D8": { vr: "DS", name: "WedgeOrientation" },
  "300A00D9": { vr: "FL", name: "IsocenterToWedgeTrayDistance" },
  "300A00DA": { vr: "DS", name: "SourceToWedgeTrayDistance" },
  "300A00DB": { vr: "FL", name: "WedgeThinEdgePosition" },
  "300A00DC": { vr: "SH", name: "BolusID" },
  "300A00DD": { vr: "ST", name: "BolusDescription" },
  "300A00E0": { vr: "IS", name: "NumberOfCompensators" },
  "300A00E1": { vr: "SH", name: "MaterialID" },
  "300A00E2": { vr: "DS", name: "TotalCompensatorTrayFactor" },
  "300A00E3": { vr: "SQ", name: "CompensatorSequence" },
  "300A00E4": { vr: "IS", name: "CompensatorNumber" },
  "300A00E5": { vr: "SH", name: "CompensatorID" },
  "300A00E6": { vr: "DS", name: "SourceToCompensatorTrayDistance" },
  "300A00E7": { vr: "IS", name: "CompensatorRows" },
  "300A00E8": { vr: "IS", name: "CompensatorColumns" },
  "300A00E9": { vr: "DS", name: "CompensatorPixelSpacing" },
  "300A00EA": { vr: "DS", name: "CompensatorPosition" },
  "300A00EB": { vr: "DS", name: "CompensatorTransmissionData" },
  "300A00EC": { vr: "DS", name: "CompensatorThicknessData" },
  "300A00ED": { vr: "IS", name: "NumberOfBoli" },
  "300A00EE": { vr: "CS", name: "CompensatorType" },
  "300A00F0": { vr: "IS", name: "NumberOfBlocks" },
  "300A00F2": { vr: "DS", name: "TotalBlockTrayFactor" },
  "300A00F3": { vr: "FL", name: "TotalBlockTrayWaterEquivalentThickness" },
  "300A00F4": { vr: "SQ", name: "BlockSequence" },
  "300A00F5": { vr: "SH", name: "BlockTrayID" },
  "300A00F6": { vr: "DS", name: "SourceToBlockTrayDistance" },
  "300A00F7": { vr: "FL", name: "IsocenterToBlockTrayDistance" },
  "300A00F8": { vr: "CS", name: "BlockType" },
  "300A00F9": { vr: "LO", name: "AccessoryCode" },
  "300A00FA": { vr: "CS", name: "BlockDivergence" },
  "300A00FB": { vr: "CS", name: "BlockMountingPosition" },
  "300A00FC": { vr: "IS", name: "BlockNumber" },
  "300A00FE": { vr: "LO", name: "BlockName" },
  "300A0100": { vr: "DS", name: "BlockThickness" },
  "300A0102": { vr: "DS", name: "BlockTransmission" },
  "300A0104": { vr: "IS", name: "BlockNumberOfPoints" },
  "300A0106": { vr: "DS", name: "BlockData" },
  "300A0107": { vr: "SQ", name: "ApplicatorSequence" },
  "300A0108": { vr: "SH", name: "ApplicatorID" },
  "300A0109": { vr: "CS", name: "ApplicatorType" },
  "300A010A": { vr: "LO", name: "ApplicatorDescription" },
  "300A010C": { vr: "DS", name: "CumulativeDoseReferenceCoefficient" },
  "300A010E": { vr: "DS", name: "FinalCumulativeMetersetWeight" },
  "300A0110": { vr: "IS", name: "NumberOfControlPoints" },
  "300A0111": { vr: "SQ", name: "ControlPointSequence" },
  "300A0112": { vr: "IS", name: "ControlPointIndex" },
  "300A0114": { vr: "DS", name: "NominalBeamEnergy" },
  "300A0115": { vr: "DS", name: "DoseRateSet" },
  "300A0116": { vr: "SQ", name: "WedgePositionSequence" },
  "300A0118": { vr: "CS", name: "WedgePosition" },
  "300A011A": { vr: "SQ", name: "BeamLimitingDevicePositionSequence" },
  "300A011C": { vr: "DS", name: "LeafJawPositions" },
  "300A011E": { vr: "DS", name: "GantryAngle" },
  "300A011F": { vr: "CS", name: "GantryRotationDirection" },
  "300A0120": { vr: "DS", name: "BeamLimitingDeviceAngle" },
  "300A0121": { vr: "CS", name: "BeamLimitingDeviceRotationDirection" },
  "300A0122": { vr: "DS", name: "PatientSupportAngle" },
  "300A0123": { vr: "CS", name: "PatientSupportRotationDirection" },
  "300A0124": { vr: "DS", name: "TableTopEccentricAxisDistance" },
  "300A0125": { vr: "DS", name: "TableTopEccentricAngle" },
  "300A0126": { vr: "CS", name: "TableTopEccentricRotationDirection" },
  "300A0128": { vr: "DS", name: "TableTopVerticalPosition" },
  "300A0129": { vr: "DS", name: "TableTopLongitudinalPosition" },
  "300A012A": { vr: "DS", name: "TableTopLateralPosition" },
  "300A012C": { vr: "DS", name: "IsocenterPosition" },
  "300A012E": { vr: "DS", name: "SurfaceEntryPoint" },
  "300A0130": { vr: "DS", name: "SourceToSurfaceDistance" },
  "300A0134": { vr: "DS", name: "CumulativeMetersetWeight" },
  "300A0140": { vr: "FL", name: "TableTopPitchAngle" },
  "300A0142": { vr: "CS", name: "TableTopPitchRotationDirection" },
  "300A0144": { vr: "FL", name: "TableTopRollAngle" },
  "300A0146": { vr: "CS", name: "TableTopRollRotationDirection" },
  "300A0148": { vr: "FL", name: "HeadFixationAngle" },
  "300A014A": { vr: "FL", name: "GantryPitchAngle" },
  "300A014C": { vr: "CS", name: "GantryPitchRotationDirection" },
  "300A014E": { vr: "FL", name: "GantryPitchAngleTolerance" },
  "300A0180": { vr: "SQ", name: "PatientSetupSequence" },
  "300A0182": { vr: "IS", name: "PatientSetupNumber" },
  "300A0183": { vr: "LO", name: "PatientSetupLabel" },
  "300A0184": { vr: "LO", name: "PatientAdditionalPosition" },
  "300A0190": { vr: "SQ", name: "FixationDeviceSequence" },
  "300A0192": { vr: "CS", name: "FixationDeviceType" },
  "300A0194": { vr: "SH", name: "FixationDeviceLabel" },
  "300A0196": { vr: "ST", name: "FixationDeviceDescription" },
  "300A0198": { vr: "SH", name: "FixationDevicePosition" },
  "300A0199": { vr: "FL", name: "FixationDevicePitchAngle" },
  "300A019A": { vr: "FL", name: "FixationDeviceRollAngle" },
  "300A01A0": { vr: "SQ", name: "ShieldingDeviceSequence" },
  "300A01A2": { vr: "CS", name: "ShieldingDeviceType" },
  "300A01A4": { vr: "SH", name: "ShieldingDeviceLabel" },
  "300A01A6": { vr: "ST", name: "ShieldingDeviceDescription" },
  "300A01A8": { vr: "SH", name: "ShieldingDevicePosition" },
  "300A01B0": { vr: "CS", name: "SetupTechnique" },
  "300A01B2": { vr: "ST", name: "SetupTechniqueDescription" },
  "300A01B4": { vr: "SQ", name: "SetupDeviceSequence" },
  "300A01B6": { vr: "CS", name: "SetupDeviceType" },
  "300A01B8": { vr: "SH", name: "SetupDeviceLabel" },
  "300A01BA": { vr: "ST", name: "SetupDeviceDescription" },
  "300A01BC": { vr: "DS", name: "SetupDeviceParameter" },
  "300A01D0": { vr: "ST", name: "SetupReferenceDescription" },
  "300A01D2": { vr: "DS", name: "TableTopVerticalSetupDisplacement" },
  "300A01D4": { vr: "DS", name: "TableTopLongitudinalSetupDisplacement" },
  "300A01D6": { vr: "DS", name: "TableTopLateralSetupDisplacement" },
  "300A0200": { vr: "CS", name: "BrachyTreatmentTechnique" },
  "300A0202": { vr: "CS", name: "BrachyTreatmentType" },
  "300A0206": { vr: "SQ", name: "TreatmentMachineSequence" },
  "300A0210": { vr: "SQ", name: "SourceSequence" },
  "300A0212": { vr: "IS", name: "SourceNumber" },
  "300A0214": { vr: "CS", name: "SourceType" },
  "300A0216": { vr: "LO", name: "SourceManufacturer" },
  "300A0218": { vr: "DS", name: "ActiveSourceDiameter" },
  "300A021A": { vr: "DS", name: "ActiveSourceLength" },
  "300A0222": { vr: "DS", name: "SourceEncapsulationNominalThickness" },
  "300A0224": { vr: "DS", name: "SourceEncapsulationNominalTransmission" },
  "300A0226": { vr: "LO", name: "SourceIsotopeName" },
  "300A0228": { vr: "DS", name: "SourceIsotopeHalfLife" },
  "300A0229": { vr: "CS", name: "SourceStrengthUnits" },
  "300A022A": { vr: "DS", name: "ReferenceAirKermaRate" },
  "300A022B": { vr: "DS", name: "SourceStrength" },
  "300A022C": { vr: "DA", name: "SourceStrengthReferenceDate" },
  "300A022E": { vr: "TM", name: "SourceStrengthReferenceTime" },
  "300A0230": { vr: "SQ", name: "ApplicationSetupSequence" },
  "300A0232": { vr: "CS", name: "ApplicationSetupType" },
  "300A0234": { vr: "IS", name: "ApplicationSetupNumber" },
  "300A0236": { vr: "LO", name: "ApplicationSetupName" },
  "300A0238": { vr: "LO", name: "ApplicationSetupManufacturer" },
  "300A0240": { vr: "IS", name: "TemplateNumber" },
  "300A0242": { vr: "SH", name: "TemplateType" },
  "300A0244": { vr: "LO", name: "TemplateName" },
  "300A0250": { vr: "DS", name: "TotalReferenceAirKerma" },
  "300A0260": { vr: "SQ", name: "BrachyAccessoryDeviceSequence" },
  "300A0262": { vr: "IS", name: "BrachyAccessoryDeviceNumber" },
  "300A0263": { vr: "SH", name: "BrachyAccessoryDeviceID" },
  "300A0264": { vr: "CS", name: "BrachyAccessoryDeviceType" },
  "300A0266": { vr: "LO", name: "BrachyAccessoryDeviceName" },
  "300A026A": { vr: "DS", name: "BrachyAccessoryDeviceNominalThickness" },
  "300A026C": { vr: "DS", name: "BrachyAccessoryDeviceNominalTransmission" },
  "300A0280": { vr: "SQ", name: "ChannelSequence" },
  "300A0282": { vr: "IS", name: "ChannelNumber" },
  "300A0284": { vr: "DS", name: "ChannelLength" },
  "300A0286": { vr: "DS", name: "ChannelTotalTime" },
  "300A0288": { vr: "CS", name: "SourceMovementType" },
  "300A028A": { vr: "IS", name: "NumberOfPulses" },
  "300A028C": { vr: "DS", name: "PulseRepetitionInterval" },
  "300A0290": { vr: "IS", name: "SourceApplicatorNumber" },
  "300A0291": { vr: "SH", name: "SourceApplicatorID" },
  "300A0292": { vr: "CS", name: "SourceApplicatorType" },
  "300A0294": { vr: "LO", name: "SourceApplicatorName" },
  "300A0296": { vr: "DS", name: "SourceApplicatorLength" },
  "300A0298": { vr: "LO", name: "SourceApplicatorManufacturer" },
  "300A029C": { vr: "DS", name: "SourceApplicatorWallNominalThickness" },
  "300A029E": { vr: "DS", name: "SourceApplicatorWallNominalTransmission" },
  "300A02A0": { vr: "DS", name: "SourceApplicatorStepSize" },
  "300A02A2": { vr: "IS", name: "TransferTubeNumber" },
  "300A02A4": { vr: "DS", name: "TransferTubeLength" },
  "300A02B0": { vr: "SQ", name: "ChannelShieldSequence" },
  "300A02B2": { vr: "IS", name: "ChannelShieldNumber" },
  "300A02B3": { vr: "SH", name: "ChannelShieldID" },
  "300A02B4": { vr: "LO", name: "ChannelShieldName" },
  "300A02B8": { vr: "DS", name: "ChannelShieldNominalThickness" },
  "300A02BA": { vr: "DS", name: "ChannelShieldNominalTransmission" },
  "300A02C8": { vr: "DS", name: "FinalCumulativeTimeWeight" },
  "300A02D0": { vr: "SQ", name: "BrachyControlPointSequence" },
  "300A02D2": { vr: "DS", name: "ControlPointRelativePosition" },
  "300A02D4": { vr: "DS", name: "ControlPoint3DPosition" },
  "300A02D6": { vr: "DS", name: "CumulativeTimeWeight" },
  "300A02E0": { vr: "CS", name: "CompensatorDivergence" },
  "300A02E1": { vr: "CS", name: "CompensatorMountingPosition" },
  "300A02E2": { vr: "DS", name: "SourceToCompensatorDistance" },
  "300A02E3": { vr: "FL", name: "TotalCompensatorTrayWaterEquivalentThickness" },
  "300A02E4": { vr: "FL", name: "IsocenterToCompensatorTrayDistance" },
  "300A02E5": { vr: "FL", name: "CompensatorColumnOffset" },
  "300A02E6": { vr: "FL", name: "IsocenterToCompensatorDistances" },
  "300A02E7": { vr: "FL", name: "CompensatorRelativeStoppingPowerRatio" },
  "300A02E8": { vr: "FL", name: "CompensatorMillingToolDiameter" },
  "300A02EA": { vr: "SQ", name: "IonRangeCompensatorSequence" },
  "300A02EB": { vr: "LT", name: "CompensatorDescription" },
  "300A0302": { vr: "IS", name: "RadiationMassNumber" },
  "300A0304": { vr: "IS", name: "RadiationAtomicNumber" },
  "300A0306": { vr: "SS", name: "RadiationChargeState" },
  "300A0308": { vr: "CS", name: "ScanMode" },
  "300A030A": { vr: "FL", name: "VirtualSourceAxisDistances" },
  "300A030C": { vr: "SQ", name: "SnoutSequence" },
  "300A030D": { vr: "FL", name: "SnoutPosition" },
  "300A030F": { vr: "SH", name: "SnoutID" },
  "300A0312": { vr: "IS", name: "NumberOfRangeShifters" },
  "300A0314": { vr: "SQ", name: "RangeShifterSequence" },
  "300A0316": { vr: "IS", name: "RangeShifterNumber" },
  "300A0318": { vr: "SH", name: "RangeShifterID" },
  "300A0320": { vr: "CS", name: "RangeShifterType" },
  "300A0322": { vr: "LO", name: "RangeShifterDescription" },
  "300A0330": { vr: "IS", name: "NumberOfLateralSpreadingDevices" },
  "300A0332": { vr: "SQ", name: "LateralSpreadingDeviceSequence" },
  "300A0334": { vr: "IS", name: "LateralSpreadingDeviceNumber" },
  "300A0336": { vr: "SH", name: "LateralSpreadingDeviceID" },
  "300A0338": { vr: "CS", name: "LateralSpreadingDeviceType" },
  "300A033A": { vr: "LO", name: "LateralSpreadingDeviceDescription" },
  "300A033C": { vr: "FL", name: "LateralSpreadingDeviceWaterEquivalentThickness" },
  "300A0340": { vr: "IS", name: "NumberOfRangeModulators" },
  "300A0342": { vr: "SQ", name: "RangeModulatorSequence" },
  "300A0344": { vr: "IS", name: "RangeModulatorNumber" },
  "300A0346": { vr: "SH", name: "RangeModulatorID" },
  "300A0348": { vr: "CS", name: "RangeModulatorType" },
  "300A034A": { vr: "LO", name: "RangeModulatorDescription" },
  "300A034C": { vr: "SH", name: "BeamCurrentModulationID" },
  "300A0350": { vr: "CS", name: "PatientSupportType" },
  "300A0352": { vr: "SH", name: "PatientSupportID" },
  "300A0354": { vr: "LO", name: "PatientSupportAccessoryCode" },
  "300A0356": { vr: "FL", name: "FixationLightAzimuthalAngle" },
  "300A0358": { vr: "FL", name: "FixationLightPolarAngle" },
  "300A035A": { vr: "FL", name: "MetersetRate" },
  "300A0360": { vr: "SQ", name: "RangeShifterSettingsSequence" },
  "300A0362": { vr: "LO", name: "RangeShifterSetting" },
  "300A0364": { vr: "FL", name: "IsocenterToRangeShifterDistance" },
  "300A0366": { vr: "FL", name: "RangeShifterWaterEquivalentThickness" },
  "300A0370": { vr: "SQ", name: "LateralSpreadingDeviceSettingsSequence" },
  "300A0372": { vr: "LO", name: "LateralSpreadingDeviceSetting" },
  "300A0374": { vr: "FL", name: "IsocenterToLateralSpreadingDeviceDistance" },
  "300A0380": { vr: "SQ", name: "RangeModulatorSettingsSequence" },
  "300A0382": { vr: "FL", name: "RangeModulatorGatingStartValue" },
  "300A0384": { vr: "FL", name: "RangeModulatorGatingStopValue" },
  "300A0386": { vr: "FL", name: "RangeModulatorGatingStartWaterEquivalentThickness" },
  "300A0388": { vr: "FL", name: "RangeModulatorGatingStopWaterEquivalentThickness" },
  "300A038A": { vr: "FL", name: "IsocenterToRangeModulatorDistance" },
  "300A0390": { vr: "SH", name: "ScanSpotTuneID" },
  "300A0392": { vr: "IS", name: "NumberOfScanSpotPositions" },
  "300A0394": { vr: "FL", name: "ScanSpotPositionMap" },
  "300A0396": { vr: "FL", name: "ScanSpotMetersetWeights" },
  "300A0398": { vr: "FL", name: "ScanningSpotSize" },
  "300A039A": { vr: "IS", name: "NumberOfPaintings" },
  "300A03A0": { vr: "SQ", name: "IonToleranceTableSequence" },
  "300A03A2": { vr: "SQ", name: "IonBeamSequence" },
  "300A03A4": { vr: "SQ", name: "IonBeamLimitingDeviceSequence" },
  "300A03A6": { vr: "SQ", name: "IonBlockSequence" },
  "300A03A8": { vr: "SQ", name: "IonControlPointSequence" },
  "300A03AA": { vr: "SQ", name: "IonWedgeSequence" },
  "300A03AC": { vr: "SQ", name: "IonWedgePositionSequence" },
  "300A0401": { vr: "SQ", name: "ReferencedSetupImageSequence" },
  "300A0402": { vr: "ST", name: "SetupImageComment" },
  "300A0410": { vr: "SQ", name: "MotionSynchronizationSequence" },
  "300A0412": { vr: "FL", name: "ControlPointOrientation" },
  "300A0420": { vr: "SQ", name: "GeneralAccessorySequence" },
  "300A0421": { vr: "SH", name: "GeneralAccessoryID" },
  "300A0422": { vr: "ST", name: "GeneralAccessoryDescription" },
  "300A0423": { vr: "CS", name: "GeneralAccessoryType" },
  "300A0424": { vr: "IS", name: "GeneralAccessoryNumber" },
  "300A0431": { vr: "SQ", name: "ApplicatorGeometrySequence" },
  "300A0432": { vr: "CS", name: "ApplicatorApertureShape" },
  "300A0433": { vr: "FL", name: "ApplicatorOpening" },
  "300A0434": { vr: "FL", name: "ApplicatorOpeningX" },
  "300A0435": { vr: "FL", name: "ApplicatorOpeningY" },
  "300A0436": { vr: "FL", name: "SourceToApplicatorMountingPositionDistance" },
  "300C0002": { vr: "SQ", name: "ReferencedRTPlanSequence" },
  "300C0004": { vr: "SQ", name: "ReferencedBeamSequence" },
  "300C0006": { vr: "IS", name: "ReferencedBeamNumber" },
  "300C0007": { vr: "IS", name: "ReferencedReferenceImageNumber" },
  "300C0008": { vr: "DS", name: "StartCumulativeMetersetWeight" },
  "300C0009": { vr: "DS", name: "EndCumulativeMetersetWeight" },
  "300C000A": { vr: "SQ", name: "ReferencedBrachyApplicationSetupSequence" },
  "300C000C": { vr: "IS", name: "ReferencedBrachyApplicationSetupNumber" },
  "300C000E": { vr: "IS", name: "ReferencedSourceNumber" },
  "300C0020": { vr: "SQ", name: "ReferencedFractionGroupSequence" },
  "300C0022": { vr: "IS", name: "ReferencedFractionGroupNumber" },
  "300C0040": { vr: "SQ", name: "ReferencedVerificationImageSequence" },
  "300C0042": { vr: "SQ", name: "ReferencedReferenceImageSequence" },
  "300C0050": { vr: "SQ", name: "ReferencedDoseReferenceSequence" },
  "300C0051": { vr: "IS", name: "ReferencedDoseReferenceNumber" },
  "300C0055": { vr: "SQ", name: "BrachyReferencedDoseReferenceSequence" },
  "300C0060": { vr: "SQ", name: "ReferencedStructureSetSequence" },
  "300C006A": { vr: "IS", name: "ReferencedPatientSetupNumber" },
  "300C0080": { vr: "SQ", name: "ReferencedDoseSequence" },
  "300C00A0": { vr: "IS", name: "ReferencedToleranceTableNumber" },
  "300C00B0": { vr: "SQ", name: "ReferencedBolusSequence" },
  "300C00C0": { vr: "IS", name: "ReferencedWedgeNumber" },
  "300C00D0": { vr: "IS", name: "ReferencedCompensatorNumber" },
  "300C00E0": { vr: "IS", name: "ReferencedBlockNumber" },
  "300C00F0": { vr: "IS", name: "ReferencedControlPointIndex" },
  "300C00F2": { vr: "SQ", name: "ReferencedControlPointSequence" },
  "300C00F4": { vr: "IS", name: "ReferencedStartControlPointIndex" },
  "300C00F6": { vr: "IS", name: "ReferencedStopControlPointIndex" },
  "300C0100": { vr: "IS", name: "ReferencedRangeShifterNumber" },
  "300C0102": { vr: "IS", name: "ReferencedLateralSpreadingDeviceNumber" },
  "300C0104": { vr: "IS", name: "ReferencedRangeModulatorNumber" },
  "300E0002": { vr: "CS", name: "ApprovalStatus" },
  "300E0004": { vr: "DA", name: "ReviewDate" },
  "300E0005": { vr: "TM", name: "ReviewTime" },
  "300E0008": { vr: "PN", name: "ReviewerName" },
  "40000010": { vr: "LT", name: "Arbitrary" },
  "40004000": { vr: "LT", name: "TextComments" },
  "40080040": { vr: "SH", name: "ResultsID" },
  "40080042": { vr: "LO", name: "ResultsIDIssuer" },
  "40080050": { vr: "SQ", name: "ReferencedInterpretationSequence" },
  "400800FF": { vr: "CS", name: "ReportProductionStatusTrial" },
  "40080100": { vr: "DA", name: "InterpretationRecordedDate" },
  "40080101": { vr: "TM", name: "InterpretationRecordedTime" },
  "40080102": { vr: "PN", name: "InterpretationRecorder" },
  "40080103": { vr: "LO", name: "ReferenceToRecordedSound" },
  "40080108": { vr: "DA", name: "InterpretationTranscriptionDate" },
  "40080109": { vr: "TM", name: "InterpretationTranscriptionTime" },
  "4008010A": { vr: "PN", name: "InterpretationTranscriber" },
  "4008010B": { vr: "ST", name: "InterpretationText" },
  "4008010C": { vr: "PN", name: "InterpretationAuthor" },
  "40080111": { vr: "SQ", name: "InterpretationApproverSequence" },
  "40080112": { vr: "DA", name: "InterpretationApprovalDate" },
  "40080113": { vr: "TM", name: "InterpretationApprovalTime" },
  "40080114": { vr: "PN", name: "PhysicianApprovingInterpretation" },
  "40080115": { vr: "LT", name: "InterpretationDiagnosisDescription" },
  "40080117": { vr: "SQ", name: "InterpretationDiagnosisCodeSequence" },
  "40080118": { vr: "SQ", name: "ResultsDistributionListSequence" },
  "40080119": { vr: "PN", name: "DistributionName" },
  "4008011A": { vr: "LO", name: "DistributionAddress" },
  "40080200": { vr: "SH", name: "InterpretationID" },
  "40080202": { vr: "LO", name: "InterpretationIDIssuer" },
  "40080210": { vr: "CS", name: "InterpretationTypeID" },
  "40080212": { vr: "CS", name: "InterpretationStatusID" },
  "40080300": { vr: "ST", name: "Impressions" },
  "40084000": { vr: "ST", name: "ResultsComments" },
  "40100001": { vr: "CS", name: "LowEnergyDetectors" },
  "40100002": { vr: "CS", name: "HighEnergyDetectors" },
  "40100004": { vr: "SQ", name: "DetectorGeometrySequence" },
  "40101001": { vr: "SQ", name: "ThreatROIVoxelSequence" },
  "40101004": { vr: "FL", name: "ThreatROIBase" },
  "40101005": { vr: "FL", name: "ThreatROIExtents" },
  "40101006": { vr: "OB", name: "ThreatROIBitmap" },
  "40101007": { vr: "SH", name: "RouteSegmentID" },
  "40101008": { vr: "CS", name: "GantryType" },
  "40101009": { vr: "CS", name: "OOIOwnerType" },
  "4010100A": { vr: "SQ", name: "RouteSegmentSequence" },
  "40101010": { vr: "US", name: "PotentialThreatObjectID" },
  "40101011": { vr: "SQ", name: "ThreatSequence" },
  "40101012": { vr: "CS", name: "ThreatCategory" },
  "40101013": { vr: "LT", name: "ThreatCategoryDescription" },
  "40101014": { vr: "CS", name: "ATDAbilityAssessment" },
  "40101015": { vr: "CS", name: "ATDAssessmentFlag" },
  "40101016": { vr: "FL", name: "ATDAssessmentProbability" },
  "40101017": { vr: "FL", name: "Mass" },
  "40101018": { vr: "FL", name: "Density" },
  "40101019": { vr: "FL", name: "ZEffective" },
  "4010101A": { vr: "SH", name: "BoardingPassID" },
  "4010101B": { vr: "FL", name: "CenterOfMass" },
  "4010101C": { vr: "FL", name: "CenterOfPTO" },
  "4010101D": { vr: "FL", name: "BoundingPolygon" },
  "4010101E": { vr: "SH", name: "RouteSegmentStartLocationID" },
  "4010101F": { vr: "SH", name: "RouteSegmentEndLocationID" },
  "40101020": { vr: "CS", name: "RouteSegmentLocationIDType" },
  "40101021": { vr: "CS", name: "AbortReason" },
  "40101023": { vr: "FL", name: "VolumeOfPTO" },
  "40101024": { vr: "CS", name: "AbortFlag" },
  "40101025": { vr: "DT", name: "RouteSegmentStartTime" },
  "40101026": { vr: "DT", name: "RouteSegmentEndTime" },
  "40101027": { vr: "CS", name: "TDRType" },
  "40101028": { vr: "CS", name: "InternationalRouteSegment" },
  "40101029": { vr: "LO", name: "ThreatDetectionAlgorithmandVersion" },
  "4010102A": { vr: "SH", name: "AssignedLocation" },
  "4010102B": { vr: "DT", name: "AlarmDecisionTime" },
  "40101031": { vr: "CS", name: "AlarmDecision" },
  "40101033": { vr: "US", name: "NumberOfTotalObjects" },
  "40101034": { vr: "US", name: "NumberOfAlarmObjects" },
  "40101037": { vr: "SQ", name: "PTORepresentationSequence" },
  "40101038": { vr: "SQ", name: "ATDAssessmentSequence" },
  "40101039": { vr: "CS", name: "TIPType" },
  "4010103A": { vr: "CS", name: "DICOSVersion" },
  "40101041": { vr: "DT", name: "OOIOwnerCreationTime" },
  "40101042": { vr: "CS", name: "OOIType" },
  "40101043": { vr: "FL", name: "OOISize" },
  "40101044": { vr: "CS", name: "AcquisitionStatus" },
  "40101045": { vr: "SQ", name: "BasisMaterialsCodeSequence" },
  "40101046": { vr: "CS", name: "PhantomType" },
  "40101047": { vr: "SQ", name: "OOIOwnerSequence" },
  "40101048": { vr: "CS", name: "ScanType" },
  "40101051": { vr: "LO", name: "ItineraryID" },
  "40101052": { vr: "SH", name: "ItineraryIDType" },
  "40101053": { vr: "LO", name: "ItineraryIDAssigningAuthority" },
  "40101054": { vr: "SH", name: "RouteID" },
  "40101055": { vr: "SH", name: "RouteIDAssigningAuthority" },
  "40101056": { vr: "CS", name: "InboundArrivalType" },
  "40101058": { vr: "SH", name: "CarrierID" },
  "40101059": { vr: "CS", name: "CarrierIDAssigningAuthority" },
  "40101060": { vr: "FL", name: "SourceOrientation" },
  "40101061": { vr: "FL", name: "SourcePosition" },
  "40101062": { vr: "FL", name: "BeltHeight" },
  "40101064": { vr: "SQ", name: "AlgorithmRoutingCodeSequence" },
  "40101067": { vr: "CS", name: "TransportClassification" },
  "40101068": { vr: "LT", name: "OOITypeDescriptor" },
  "40101069": { vr: "FL", name: "TotalProcessingTime" },
  "4010106C": { vr: "OB", name: "DetectorCalibrationData" },
  "4FFE0001": { vr: "SQ", name: "MACParametersSequence" },
  "50xx0005": { vr: "US", name: "CurveDimensions" },
  "50xx0010": { vr: "US", name: "NumberOfPoints" },
  "50xx0020": { vr: "CS", name: "TypeOfData" },
  "50xx0022": { vr: "LO", name: "CurveDescription" },
  "50xx0030": { vr: "SH", name: "AxisUnits" },
  "50xx0040": { vr: "SH", name: "AxisLabels" },
  "50xx0103": { vr: "US", name: "DataValueRepresentation" },
  "50xx0104": { vr: "US", name: "MinimumCoordinateValue" },
  "50xx0105": { vr: "US", name: "MaximumCoordinateValue" },
  "50xx0106": { vr: "SH", name: "CurveRange" },
  "50xx0110": { vr: "US", name: "CurveDataDescriptor" },
  "50xx0112": { vr: "US", name: "CoordinateStartValue" },
  "50xx0114": { vr: "US", name: "CoordinateStepValue" },
  "50xx1001": { vr: "CS", name: "CurveActivationLayer" },
  "50xx2000": { vr: "US", name: "AudioType" },
  "50xx2002": { vr: "US", name: "AudioSampleFormat" },
  "50xx2004": { vr: "US", name: "NumberOfChannels" },
  "50xx2006": { vr: "UL", name: "NumberOfSamples" },
  "50xx2008": { vr: "UL", name: "SampleRate" },
  "50xx200A": { vr: "UL", name: "TotalTime" },
  "50xx200C": { vr: "OW|OB", name: "AudioSampleData" },
  "50xx200E": { vr: "LT", name: "AudioComments" },
  "50xx2500": { vr: "LO", name: "CurveLabel" },
  "50xx2600": { vr: "SQ", name: "CurveReferencedOverlaySequence" },
  "50xx2610": { vr: "US", name: "CurveReferencedOverlayGroup" },
  "50xx3000": { vr: "OW|OB", name: "CurveData" },
  "52009229": { vr: "SQ", name: "SharedFunctionalGroupsSequence" },
  "52009230": { vr: "SQ", name: "PerFrameFunctionalGroupsSequence" },
  "54000100": { vr: "SQ", name: "WaveformSequence" },
  "54000110": { vr: "OB|OW", name: "ChannelMinimumValue" },
  "54000112": { vr: "OB|OW", name: "ChannelMaximumValue" },
  "54001004": { vr: "US", name: "WaveformBitsAllocated" },
  "54001006": { vr: "CS", name: "WaveformSampleInterpretation" },
  "5400100A": { vr: "OB|OW", name: "WaveformPaddingValue" },
  "54001010": { vr: "OB|OW", name: "WaveformData" },
  "56000010": { vr: "OF", name: "FirstOrderPhaseCorrectionAngle" },
  "56000020": { vr: "OF", name: "SpectroscopyData" },
  "60xx0010": { vr: "US", name: "OverlayRows" },
  "60xx0011": { vr: "US", name: "OverlayColumns" },
  "60xx0012": { vr: "US", name: "OverlayPlanes" },
  "60xx0015": { vr: "IS", name: "NumberOfFramesInOverlay" },
  "60xx0022": { vr: "LO", name: "OverlayDescription" },
  "60xx0040": { vr: "CS", name: "OverlayType" },
  "60xx0045": { vr: "LO", name: "OverlaySubtype" },
  "60xx0050": { vr: "SS", name: "OverlayOrigin" },
  "60xx0051": { vr: "US", name: "ImageFrameOrigin" },
  "60xx0052": { vr: "US", name: "OverlayPlaneOrigin" },
  "60xx0060": { vr: "CS", name: "OverlayCompressionCode" },
  "60xx0061": { vr: "SH", name: "OverlayCompressionOriginator" },
  "60xx0062": { vr: "SH", name: "OverlayCompressionLabel" },
  "60xx0063": { vr: "CS", name: "OverlayCompressionDescription" },
  "60xx0066": { vr: "AT", name: "OverlayCompressionStepPointers" },
  "60xx0068": { vr: "US", name: "OverlayRepeatInterval" },
  "60xx0069": { vr: "US", name: "OverlayBitsGrouped" },
  "60xx0100": { vr: "US", name: "OverlayBitsAllocated" },
  "60xx0102": { vr: "US", name: "OverlayBitPosition" },
  "60xx0110": { vr: "CS", name: "OverlayFormat" },
  "60xx0200": { vr: "US", name: "OverlayLocation" },
  "60xx0800": { vr: "CS", name: "OverlayCodeLabel" },
  "60xx0802": { vr: "US", name: "OverlayNumberOfTables" },
  "60xx0803": { vr: "AT", name: "OverlayCodeTableLocation" },
  "60xx0804": { vr: "US", name: "OverlayBitsForCodeWord" },
  "60xx1001": { vr: "CS", name: "OverlayActivationLayer" },
  "60xx1100": { vr: "US", name: "OverlayDescriptorGray" },
  "60xx1101": { vr: "US", name: "OverlayDescriptorRed" },
  "60xx1102": { vr: "US", name: "OverlayDescriptorGreen" },
  "60xx1103": { vr: "US", name: "OverlayDescriptorBlue" },
  "60xx1200": { vr: "US", name: "OverlaysGray" },
  "60xx1201": { vr: "US", name: "OverlaysRed" },
  "60xx1202": { vr: "US", name: "OverlaysGreen" },
  "60xx1203": { vr: "US", name: "OverlaysBlue" },
  "60xx1301": { vr: "IS", name: "ROIArea" },
  "60xx1302": { vr: "DS", name: "ROIMean" },
  "60xx1303": { vr: "DS", name: "ROIStandardDeviation" },
  "60xx1500": { vr: "LO", name: "OverlayLabel" },
  "60xx3000": { vr: "OB|OW", name: "OverlayData" },
  "60xx4000": { vr: "LT", name: "OverlayComments" },
  "7FE00010": { vr: "OW|OB", name: "PixelData" },
  "7FE00020": { vr: "OW", name: "CoefficientsSDVN" },
  "7FE00030": { vr: "OW", name: "CoefficientsSDHN" },
  "7FE00040": { vr: "OW", name: "CoefficientsSDDN" },
  "7Fxx0010": { vr: "OW|OB", name: "VariablePixelData" },
  "7Fxx0011": { vr: "US", name: "VariableNextDataGroup" },
  "7Fxx0020": { vr: "OW", name: "VariableCoefficientsSDVN" },
  "7Fxx0030": { vr: "OW", name: "VariableCoefficientsSDHN" },
  "7Fxx0040": { vr: "OW", name: "VariableCoefficientsSDDN" },
  FFFAFFFA: { vr: "SQ", name: "DigitalSignaturesSequence" },
  FFFCFFFC: { vr: "OB", name: "DataSetTrailingPadding" },
  FFFEE000: { vr: "", name: "Item" },
  FFFEE00D: { vr: "", name: "ItemDelimitationItem" },
  FFFEE0DD: { vr: "", name: "SequenceDelimitationItem" }
};