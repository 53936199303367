import * as angular from 'angular';
import { IHttpService, ICacheFactoryService, ITemplateCacheService, ICacheObject, IPromise } from 'angular';

/** A kind of cache which knows how to look for multiple variants of a resource (template,
 * generally) and cache the first available one for efficient retrieval later. */
export class VariantCache {
    /** Assigned during angular service initialisation. Don't use outside of injection context. */
  static $http: IHttpService;
  /** Assigned during angular service initialisation. Don't use outside of injection context. */
  static $cacheFactory: ICacheFactoryService;
  /** Assigned during angular service initialisation. Don't use outside of injection context. */
  static $templateCache: ITemplateCacheService;

  /** The backing cache which this variant cache will use, or false for no caching. */
  private readonly cache: false | ICacheObject;
  constructor(cache: boolean | string | ICacheObject = true) {
    if (cache === true || cache == null) {
        this.cache = VariantCache.$templateCache;
    } else if (angular.isString(cache)) {
        this.cache = VariantCache.$cacheFactory.get(cache) || VariantCache.$cacheFactory(cache);
    } else if (typeof cache === "object") { //If cache instance.
      this.cache = cache;
    } else {
      this.cache = false;
    }
  }

  /** Adds an entry to the cache, if this variant cache has a backing cache. */
  put(urls: string | string[], value: any) {
    if (this.cache) {
      if (angular.isString(urls)) {
        this.cache.put(urls, value);
      } else {
        for (let url of urls) {
          this.cache.put(url, value);
        }
      }
    }
  }

  /** Gets a template from one or more urls. If the argument is a string then this call is
   * synonymous with $http.get. If it is an array of strings then they are tried in sequence
   * until one returns a success response. Any success is cached back through all previously
   * tried urls if 'cache' is provided so subsequent calls don't need to hit the server. */
  get(urls: string | string[]): IPromise<any> {
    if (angular.isString(urls)) {
      return this.getHttp(urls, this.cache);
    } else if (angular.isArray(urls) && urls.length > 0) {
      return this.tryNext(urls, this.cache);
    } else {
      throw new Error(
        "urls must be a url string or a non empty array of url strings"
      );
    }
  }

  private getHttp(url: string, cache: ICacheObject | false) {
    return VariantCache.$http.get(url, { cache });
  }

  /** Tries the url at 'index' and those after if it fails. If one passes then all preceding urls
   * cache the value which succeeded so the chain doesn't need to be walked again. */
  private tryNext(urls: string | string[], cache: ICacheObject | false, index = 0): IPromise<any> {
    const current = this.getHttp(urls[index], cache);
    if (index + 1 >= urls.length) {
      return current;
    }

    function updateCache(result: any) {
      for (var i = 0; i < index; ++i) {
        console.log(`${urls[i]} permanently resolved to ${urls[index]}`);
        (<ICacheObject>cache).put(urls[i], result.data);
      }
      return result;
    }

    return current.then(
      (cache && index > 0) ? updateCache : null,
      () => this.tryNext(urls, cache, index + 1));
  }
}

export default ["$http", "$templateCache", "$cacheFactory",
  function($http: IHttpService, $templateCache: ITemplateCacheService, $cacheFactory: ICacheFactoryService) {
    VariantCache.$http = $http;
    VariantCache.$templateCache = $templateCache;
    VariantCache.$cacheFactory = $cacheFactory;
    return VariantCache;
  }
];