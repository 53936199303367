import { IDirective } from 'angular';
import { IAuthService } from '../authService';

export default ["authService", (authService: IAuthService) =>
  (<IDirective>{
    restrict: 'A',
    link(scope, element, attrs) {
      const action = !attrs.mdsIfAuthenticated ? "show" : attrs.mdsIfAuthenticated;
      const performAction = function(flip) {
        if (((action === "show") && !flip) || ((action === "hide") && flip)) { return element.show();
        } else if (((action === "hide") && !flip) || ((action === "show") && flip)) { return element.hide(); }
      };

      return performAction(!authService.isAuthenticated());
    }
  })
];