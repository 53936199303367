import { Matrix2D, Shape, DisplayObject } from "createjs";
import { IPointLike, LogFunction, Traceable, IMaybeTraceable, Rect } from "../../../utility/utils";
import { coordExtent } from "../imageEditorVars";

/** The default colour used to draw shape shadows. */
export const shadowDefaultColour = "rgba(135, 206, 235, 0.6)"; //skyblue with alpha.
/** Normalised default size of shadows, on top of the shape's existing size. This still needs
 * to be multiplied by the coordinate extent. */
export const shadowDefaultExtraThickness = 0.01;

/** A shape which can render itself to a canvas, with shadows and hit detection. */
export default abstract class RenderableShape implements IMaybeTraceable {
  protected $trace?: LogFunction;
  /** Optionally allow tracing for this object to be configured. */
  setTracing?(options: Traceable.Options): void;
  /** Handle to the graphical representation of this shape. */
  ink: DisplayObject;
  /** The colour to use to draw the shape. */
  colour: string;
  /** Some shapes use a secondary colour for the background. */
  backgroundColour?: string;
  /** The colour to drawn as a shadow beneath the shape. */
  shadowColour: string;
  /** The extra thickness to add to a shape when rendering it's shadow. */
  shadowExtraThickness: number;
  /** Clears the graphics object and redraws this shape to it. Should be called whenever
   * changes are made which aren't yet reflected in the graphics object.
   * @param drawShadow Whether to draw a shadow beneath the shape.
   * @param target The display object to render to. Defaults to this.ink.
   * @returns The display object which was rendered to.
   */
  abstract render(drawShadow?: boolean, target?: DisplayObject): DisplayObject;
  /** Bakes the current ink position into the points and re-renders. Essentially shifts any
   * offset from the ink position to the actual point positions. */
  abstract bakeTransform(): void;
  /** Checks hit detection against this shape. Takes into account both the draw position and the
   * current ink position. Will return false if the ink of this shape shape has no parent.
   * @param p The point to hit test against this shape. */
  hitTest(p: IPointLike): boolean {
    const parent = this.ink.parent;
    if (parent != null) {
      const boundsCheck = this.tryLocalBounds(p.x, p.y);
      if (boundsCheck === true || boundsCheck === undefined) {
        this.$trace && this.$trace(`Expensive hitTest({x: ${p.x.toPrecision(4)}, y: ${p.y.toPrecision(4)}})`);
        const testInk = new Shape();
        testInk.x = this.ink.x;
        testInk.y = this.ink.y;
        this.render(false, testInk);
        parent.addChild(testInk);
        const localP = parent.localToLocal(p.x, p.y, testInk);
        try {
          return testInk.hitTest(localP.x, localP.y);
        } finally {
          parent.removeChild(testInk);
        }
      }
    }
    return false;
  }

  /** Resets translation, rotation, scale, and skew of the provided shape. */
  protected static resetInkTransform(shape: Shape) {
      shape.setTransform(0, 0, 1, 1, 0, 0, 0);
  }

  /** Checks a point against the bounds of this shape if it provides them. If it
   * doesn't then this returns undefined. The point is assumed to be local to the ink object,
   * as would be the case for hit detection. It is transformed appropriately for comparison
   * with the bounds object. */
  protected tryLocalBounds(x: number, y: number): boolean | undefined {
      if (typeof this.getBounds === "function") {
          const p = this.ink
              .getMatrix(RenderableShape.ScratchMatrix)
              .invert()
              .transformPoint(x, y, RenderableShape.ScratchPoint);
          return this.getBounds().contains(p);
      }
      return undefined;
  }

  /** If provided, this returns a best effort bounding rectangle for the shape. Don't expect
   * too much from the result, it need not be a minimal bounding rect for instance, but it can
   * be useful as a first stage hit detection before trying pixel perfect hit detection. The rect
   * should not have applied any transforms from it's ink object. */
  protected getBounds?(): Rect;

  /** Draws the bounds of this shape to a graphics object, if getBounds() is implemented. */
  renderBounds(ink: Shape = <Shape>this.ink, thickness: number = coordExtent * 0.001, colour: string = "rebeccapurple") {
    if (this.getBounds && ink && ink.graphics && thickness > 0) {
      const rect = this.getBounds();
      if (rect) {
        ink.graphics
          .endFill()
          .beginStroke(colour)
          .setStrokeStyle(thickness)
          .rect(rect.x, rect.y, rect.width, rect.height);
      }
    }
  }

  /** A matrix which can be reused for temporary work. */
  protected static ScratchMatrix = new Matrix2D();
  /** A point which can be reused for temporary work. */
  protected static ScratchPoint: IPointLike = { x: 0, y: 0 };
}