import { MeasurementController, IMeasurementScope } from "../MeasurementController";
import * as angular from "angular";
class DXWristController extends MeasurementController {
  static $inject = ["$scope"];
  constructor(scope: IMeasurementScope) {
    super(scope);
  }

  clickRightOK() {
    this.setMeasurementValue("DXWristRtBones", "No fracture or dislocation is present. ");
    this.setMeasurementValue("DXWristRtSoftTissue", "Normal. ");
    this.setMeasurementValue("DXWristRtJoints", "The joint spaces are normal. ");
    this.setMeasurementValue("DXWristRtOtherComment", "None. ");
    console.log("Right All normal clicked")
  }

  clickLeftOK() {
    this.setMeasurementValue("DXWristLtBones", "No fracture or dislocation is present. ");
    this.setMeasurementValue("DXWristLtSoftTissue", "Normal. ");
    this.setMeasurementValue("DXWristLtJoints", "The joint spaces are normal. ");
    this.setMeasurementValue("DXWristLtOtherComment", "None. ");
    console.log("Left All normal clicked")
  }
}

angular.module("midas.utility.measurement.views").controller("DXWristCtrl", DXWristController);
