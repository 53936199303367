import { module as ngModule, IController, ILogService, IDirective, IAugmentedJQuery, IPromise, IQService } from "angular";
import { Exam, ExamType, Modality } from "../../businessModels";
import { IChangesObject, BindingOf } from "../../utility/componentBindings";
import measurementContextServiceModule, {
    MeasurementContextService,
    MeasurementLookup,
    serviceName as measurementContextServiceName,
    MeasurementTypeLookup
} from "../../utility/measurement/directives/measurementContext.service";
import markerServiceModule, { serviceName as markerServiceName, MarkerService, MarkerController } from './widgets/marker.service';

/** The name of this directive. */
export const directiveName = "blueprintContext";

interface Bindings {
    [directiveName]?: Exam | ExamType | Modality;
}

/** This class has evolved a lot over time, and is basically a blueprint context at this point. It
 * provides common data to directives below it (which can require it) so that they don't have to all
 * end up doing the same work. For instance, many parts of the code need either a list or a lookup
 * of measurement types. This controller provides a flexible common place for that. */
export interface BlueprintContextController extends IController { }
/** A directive which sets up context for compiling reports against, such as measurement view models
 * based on a binding to an `Exam`, `ExamType`, or `Modality`. */
export class BlueprintContextController implements IController {
    /** Exam to display measurement values from. This is set from the directive attribute binding.
     * It will be null if the attribute binding was not an `Exam`. */
    exam?: Exam;
    /** The exam type, defining which measurement types are in context. This is set from the
     * directive attribute binding, and will be null if that was not an `ExamType`. */
    examType?: ExamType;
    /** Modality to display measurements from. This is set from the directive attribute binding. It
     * will never be null as all other allowed bindings will set it. */
    modality: Modality;

    /** The measurement types known to this context. */
    measurementTypes: MeasurementTypeLookup;

    /** Once `this.refreshAvailableMarkers() has been called, this will contain a reference to all
     * marker controllers beneath this directive. */
    markers: MarkerController[];

    static $inject = [measurementContextServiceName, "$log", "$element", "$q", markerServiceName];

    constructor(
        private readonly measurementService: MeasurementContextService,
        private readonly $log: ILogService,
        private readonly $element: IAugmentedJQuery,
        private readonly $q: IQService,
        private readonly markerService: MarkerService
    ) {
        this.measurementTypes = <MeasurementTypeLookup> [];
    }

    $onChanges(changes: IChangesObject<Bindings>) {
        const context = changes && changes.blueprintContext && changes.blueprintContext.currentValue;
        if (context == null) {
            this.exam = this.examType = null;
        } else if (context instanceof Exam) {
            this.exam = context;
            this.examType = context.type;
            this.modality = context.type.modality;
        } else if (context instanceof ExamType) {
            this.exam = null;
            this.examType = context;
            this.modality = context.modality;
        } else if (context instanceof Modality) {
            this.exam = this.examType = null;
            this.modality = context;
        } else {
            this.$log.error("Bound value must be an instance of Exam, ExamType, or Modality business models.");
        }

        this.refreshMeasurements();
    }

    /** Refreshes the measurements list/lookup from `this.exam` and `this.modality`. This is called
     * automatically when the bindings change, but can also be called to refresh measurement
     * values or other measurement data, which is not automatically watched. */
    refreshMeasurements(): IPromise<void> {
        const measurementsVMs = <MeasurementLookup> [];
        const types = this.measurementTypes = <MeasurementTypeLookup> [];

        if (this.exam) {
            this.measurementService.merge(measurementsVMs, this.exam);
        }
        // Avoid loading the modality if at all possible, but fall back to loading it and waiting
        // for the result before updating the context if necessary.
        if (this.modality) {
            if (this.modality.isLoaded()) {
                this.measurementService.merge(measurementsVMs, this.modality);
                updateTypes(types, measurementsVMs);
            } else {
                return this.modality.load().then(() => {
                    this.measurementService.merge(measurementsVMs, this.modality);
                    updateTypes(types, measurementsVMs);
                });
            }
        } else {
            updateTypes(types, measurementsVMs);
        }

        return this.$q.resolve();

        function updateTypes(types: MeasurementTypeLookup, vms: MeasurementLookup) {
            // Get a lookup of all measurement types.
            for (var vm of vms) {
                types.push(vm.type);
                types[vm.key] = vm.type;
            }
        }
    }

    /** Refreshes the available markers by scanning the DOM beneath this element for marker widgets.
     */
    refreshMarkers() {
        this.markers = this.markerService.descendants(this.$element);
    }
}

export default ngModule("midas.utility.reportFormatter.blueprintContext", [
    measurementContextServiceModule.name,
    markerServiceModule.name
])
.directive(directiveName, () => <IDirective> {
    restrict: "A",
    controller: BlueprintContextController,
    bindToController: true,
    scope: <BindingOf<Bindings>> {
        [directiveName]: "<"
    }
});
