/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import * as Hammer from "hammerjs";
import "./mdsViewBox.css";
import { Vector2, PanZoom } from "../../utility/utils";
import { ITimeoutService } from "angular";

export default ["$timeout", "PanZoom",
    ($timeout: ITimeoutService, panZoom: typeof PanZoom) =>
    ({
      restrict: "E",
      replace: true,
      transclude: true,
      scope: true,
      template:
        `<div class='view-box' \
ng-click='click($event)' \
msd-wheel='wheel($event, $delta)' \
ng-mousedown='mousedown($event)' \
ng-mouseup='mouseup($event)'> \
<div ng-transclude \
ng-class='contentClass' \
ng-style='contentStyle'></div> \
</div>`,
      link($scope, $element) {

        const domEl = $element[0];

        const maxScale = 5;
        $scope.contentStyle = {};
        $scope.contentClass = null;

        const refreshStyle = function(tform) {
          const transformStyle = `translate(${tform.pos.x}px, ${tform.pos.y}px) \
scale(${tform.scale.x}, ${tform.scale.y})`;
          $timeout(function() { //Ensure the changes are detected by angular, no matter who calls this.
            const s = $scope.contentStyle;
            return s.webkitTransform = (s.mozTransform = (s.msTransform = (s.transform = transformStyle)));
          });
        };

        const clientToLocal = function(x, y) {
          const clientBounds = domEl.getBoundingClientRect();
          return new Vector2(x - clientBounds.left, y - clientBounds.top);
        };

        const transform = new panZoom();
        transform.observe("start-pan-zoom", () => delete $scope.contentClass);
        transform.observe("pan-zoom", refreshStyle);
        transform.observe("end-pan-zoom", function(t) {
          refreshStyle(t);
          return stopMouseMove();
        });
        transform.observe("goto-pan-zoom", tform =>
          $timeout(function() {
            $scope.contentClass = "transition-transform-slow";
            return refreshStyle(tform);
          })
        );

        const zoomOrReset = function(centroid) {
          if (transform.scale.x === 1) {
            transform.zoom(centroid, maxScale);
          } else {
            transform.reset();
          }
        };

        //Simulate Hammerjs panning for the right mouse button.
        const observeMouseMove = () => document.body.addEventListener("mousemove", mousemove);
        var stopMouseMove = () => document.body.removeEventListener("mousemove", mousemove);
        $scope.$on("$destroy", stopMouseMove);
        var mousemove = event => transform.panZooming(clientToLocal(event.clientX, event.clientY));
        $scope.mousedown = function(event) {
          if (event.button === 2) {
            observeMouseMove();
            return transform.startPanZoom(clientToLocal(event.clientX, event.clientY));
          }
        };
        $scope.mouseup = function(event) {
          if (event.button === 2) {
            return transform.endPanZoom();
          }
        };


        $scope.click = function(event) {
          if (event.button === 1) {
            zoomOrReset(clientToLocal(event.clientX, event.clientY));
          }
        };

        $scope.wheel = function(event, normDelta) {
          event.preventDefault();
          const center = clientToLocal(event.originalEvent.clientX, event.originalEvent.clientY);
          let scale: number | Vector2 = 1 + (normDelta * 0.1);
          if (transform.isActive) {
            scale = transform.scale.clone().divide(transform.scale.start).multiply(scale);
            transform.panZooming(center, scale);
          } else {
            //Could use transform.zoom() here, but that's hooked up to a 0.5s transition,
            //so we'll simulate a full pan-zooming situation so it looks the same as that.
            transform.startPanZoom(center);
            transform.panZooming(center, scale);
            transform.endPanZoom();
          }
        };

        const pan = function(event) {
          event.preventDefault();
          const center = clientToLocal(event.center.x, event.center.y);
          if (transform.isActive) {
            //Mouse pan will always have 1 scale, but the mousewheel can also be used to zoom
            //in or out, so we need to avoid a plain mouse pan overriding it.
            const scale = event.scale === 1 ? null : event.scale;
            transform.panZooming(center, scale);
          } else {
            transform.startPanZoom(center);
          }
        };

        const panEnd = function() {
          transform.endPanZoom();
        };

        const pinch = function(event) {
          event.preventDefault();
          const center = clientToLocal(event.center.x, event.center.y);
          if (transform.isActive) {
            transform.panZooming(center, event.scale);
          } else {
            transform.startPanZoom(center);
          }
        };

        const pinchEnd = function() {
          transform.endPanZoom();
        };

        const doubleTap = function(event) {
          const center = clientToLocal(event.center.x, event.center.y);
          return zoomOrReset(center);
        };

        const manager = new Hammer.Manager($element[0]);
        const panner = manager.add(new Hammer.Pan());
        manager.add(new Hammer.Pinch()).recognizeWith(panner);
        manager.add(new Hammer.Tap({ event: 'doubletap', taps: 2 }));

        $element[0].addEventListener("contextmenu", function(e) { e.preventDefault(); return false; });
        manager.on("pan", pan);
        manager.on("panend", panEnd);
        manager.on("pinch", pinch);
        manager.on("pinchend", pinchEnd);
        manager.on("doubletap", doubleTap);

        refreshStyle(transform);

      }
    })
  
  ];
