import { IStateProvider } from 'angular-ui-router';


export default ["$stateProvider", function(this: any, $stateProvider: IStateProvider) {

  const self = this;

  const configure = (child, state) => {
    if (child && state) {
      child.parent = state;
      console.log(state);
    }
    if (child) {
      self.setState(child);
    }
  };

  self.state = function(name, state) {
    $stateProvider.state(name, state);
    $stateProvider.state(`${name}.layout`, { url: "/layout/:layoutState/:layoutArgs" });
    return this;
  };

  self.setState = state => {
    $stateProvider.state(state);
    if (state.parent) {
      state.name = state.parent.name + '.' + state.name;
    }
    if (state.children && state.children.length) {
      for (let child of state.children) { configure(child, state); }
    }
    return self;
  };




  self.$get = () => ({});
}
];
