import { module as ngModule, element as ngElement } from "angular";
import {
  BlueprintExpression,
  ExpressionOperatorType,
  IBinaryExpression,
  ILiteralNumber,
  IUnaryExpression,
  ILiteralString,
  IMeasurementTypeExpression,
  ICheckMarkerExpression
} from "./condition-expression.models";

export enum ConditionEncodingType {
  Inline,
  Block
}

/** A service to serialise and deserialise Blueprint expression elements to\from HTML elements to
 * expression models. */
export interface IBlueprintExpressionSerialiser {
  /**
   * Produce an HTML element based on a given expression.
   * @param expression the expression to produce an element for.
   * @returns The HTML element with the markup for an expression.
   */
  serialise(expression: BlueprintExpression): string;

  /**
   * Construct an expression model based on a given Blueprint HTML element.
   * @param expression the HTML string or element of the expression element
   * @param measurementTypes the measurement types to load into the model for this expression.
   * @returns the expression model.
   */
  deserialise(expression: string | Element | JQuery): BlueprintExpression;
}

class AngularBlueprintExpressionSerialiser implements IBlueprintExpressionSerialiser {

  deserialise(element: string | Element | JQuery): BlueprintExpression {
    if (typeof (element) === "string" || element instanceof Element) {
      element = ngElement(element);
    }
    const type = element.attr("data-expression-type");
    if (type == null) {
      throw new Error("Cannot parse expression element without 'data-expression-type' attribute.");
    }
    let value = element.text().trim();
    const ops = this.deserialiseOps(element);
    const operator = this.deserialiseOperator(element);
    switch (type) {
      case "binary-expression":
        if (ops.length !== 2) {
          throw new Error(`Expected 2 operands for binary expression. Got ${ops.length}."`);
        }
        if (operator == null) {
          throw new Error(`Expected an operator for a binary expression."`);
        }
        return <IBinaryExpression> {
          type,
          operand1: ops[0],
          operand2: ops[1],
          operator
        };
      case "unary-expression":
        if (ops.length !== 1) {
          throw new Error(`Expected 1 operand for unary expression. Got ${ops.length}."`);
        }
        if (operator == null) {
          throw new Error(`Expected an operator for a unary expression."`);
        }
        return <IUnaryExpression> {
          type,
          operand1: ops[0],
          operator
        };
      case "literal-number":
        return <ILiteralNumber> {
          type,
          value: Number(value)
        };
      case "literal-string":
        if (value.charAt(0) === "'" && value.charAt(value.length - 1) === "'") {
          value = value.substring(1, value.length - 1);
        }
        return <ILiteralString> {
          type,
          value
        };
      case "measurement-type":
        return <IMeasurementTypeExpression> {
          type,
          value
        };
      case "check-marker":
        const parsedMarker = /\$marker\("(.*)"\)/.exec(value);
        if (parsedMarker) {
          return <ICheckMarkerExpression> {
            type,
            value: parsedMarker[1]
          };
        }
      default:
        throw new Error("Unknown expression type: " + type);
    }
  }

  /**
   * Find all child operand expressions, if present in the HTML element.
   * @param element The expression HTML element
   * @returns An array of all child operand expressions.
   */
  private deserialiseOps(element: JQuery): BlueprintExpression[] {
    const opElements = element.children(".condition-expression");
    const expressions = new Array<BlueprintExpression>();
    opElements.each((_x, opElement) => {
      expressions.push(
        this.deserialise(opElement)
      );
    });
    return expressions;
  }

  /**
   * Return a single operator from this expression HTML element, if present.
   * @param element The expression HTML element.
   * @returns A single operand element, or null.
   * @throws If more than one expression operator element is found.
   */
  private deserialiseOperator(element: JQuery): ExpressionOperatorType {
    const operators = element.children(".expression-operator");
    if (operators.length === 0) {
      return null;
    }
    if (operators.length !== 1) {
      throw new Error("More than 1 operator found for this expression.");
    }
    return <ExpressionOperatorType> operators.text();
  }

  serialise(expression: BlueprintExpression): string {
    let content: string;
    if (expression == null || expression.type == null) {
      throw new Error("Expression must not be null or have a null type.");
    }
    switch (expression.type) {
      case "binary-expression":
        content =
          // tslint:disable-next-line:max-line-length
          `${this.serialise(expression.operand1)} ${this.serialiseOperator(expression.operator)} ${this.serialise(expression.operand2)}`;
        break;
      case "unary-expression":
        content =
          `(${this.serialise(expression.operand1)} ${this.serialiseOperator(expression.operator)})`;
        break;
      case "literal-string":
        content = `'${expression.value}'`;
        break;
        case "literal-number":
        case "measurement-type":
          content = expression.value == null ? "" : expression.value.toString();
          break;
        case "check-marker":
          content = expression.value == null ? "" : `$marker("${expression.value.replace('"', '\"')}")`;
          break;
      default:
        throw new Error("Unknown expression type");
    }
    // tslint:disable-next-line:max-line-length
    return `<span class="condition-expression condition-expression-${expression.type}" data-expression-type="${expression.type}">${content}</span>`;
  }

  private serialiseOperator(operator: ExpressionOperatorType): string {
    return `<span class="expression-operator">${operator}</span>`;
  }
}

export const serviceName = "expressionSerialiser";
export default ngModule("midas.utility.reportFormatter.expressionSerialiser", [])
  .service(serviceName, AngularBlueprintExpressionSerialiser);