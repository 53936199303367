import {
  module as ngModule,
  element as ngElement
} from "angular";
import { Editor } from "tinymce";
import reportEditorModule, {
  TinymceConfigurationController,
  tinymceConfigurationRequire
} from "../mds-rich-text-editor.component";
import isEditableModule, {
  serviceName as isEditableServiceName,
  EditableService
} from './editable.service';

/** Monitors the TinyMCE selection and adds zero width characters to allow the cursor to be placed
 * inside elements correctly. These characters will be removed when the selection moves outside of
 * the element. */
class CursorCharacterPluginController extends TinymceConfigurationController {

  /** The last element that had cursor characters inserted. */
  private lastElement: Text;

  /** The character to insert. */
  readonly zwnbs = String.fromCharCode(65279);

  static $inject = [isEditableServiceName];
  constructor(private readonly editable: EditableService) {
    super();
  }

  setup(editor: Editor) {
    editor.on("NodeChange", () => this.onSelectionChanged(editor));
  }

  /** Handle selection change and add\clear cursor characters if matched. */
  private onSelectionChanged(editor: Editor) {
    if (!editor.selection.isCollapsed()) {
      this.cleanLastElement();
      return;
    }

    let range = editor.selection.getRng(true);
    let node = range.startContainer;
    if (node === this.lastElement) {
      return;
    }

    // Clone so that changes here don't change the editor unless we assign them back.
    range = range.cloneRange()

    // At this point we know we have a collapsed selection, and we've just changed nodes.
    this.cleanLastElement();

    const isTextNode = node.nodeType === 3;

    const canEditSelectedRange = this.editable.canEdit(range);

    if (isTextNode) {

      // Check whether the selection is the start of an editable text element. This can happen if we
      // explicitly select one. In this case typing still gets appended to the previous, so we want
      // to actually force it to insert onto the start of this one. Strange.
      if (range.startOffset === 0 && canEditSelectedRange) {
        this.moveSelectionToStartOf(range.startContainer, editor);
        return;
      }

      // Check whether the cursor is at the end of a non-editable element, and the next element is
      // editable. In that case the user would expect to be able to type onto the start of the
      // editable text to the right of their cursor.
      if (range.startOffset === (<Text>node).length && !canEditSelectedRange) {
        const nextEditable = this.editable.findNext(node, editor.getElement());
        if (nextEditable != null) {
          range.setStart(nextEditable, 0);
          range.collapse(true);
          if (this.editable.canEdit(range)) {
            this.moveSelectionToStartOf(nextEditable, editor);
          }
        }
        return;
      }
    } else if (canEditSelectedRange) {
      // When the selection is not a text node but is editable, go and find the next editable thing
      // (Text node or a <br>) and select that instead.
      const nextEditable = this.editable.findNext(node, editor.getElement());
      if (nextEditable != null) {
        range.setStart(nextEditable, 0);
        range.collapse(true);
        if (this.editable.canEdit(range)) {
          this.moveSelectionToStartOf(nextEditable, editor);
        }
      }
    }
  }

  /** Removes the cursor character from the last selected element if it exists. */
  private cleanLastElement() {
    const text = this.lastElement;
    if (text == null) {
      return;
    }

    if (text.nodeValue.charAt(0) === this.zwnbs) {
      if (text.nodeValue.length === 1) {
        text.remove();
      } else {
        text.nodeValue = text.nodeValue.substr(1);
      }
    }

    this.lastElement = null;
  }

  /** Moves the editor's current selection to the start of the provided element, inserting a
   * dummy character to ensure it stays there. */
  private moveSelectionToStartOf(element: Node, editor: Editor) {
    if (element.nodeType === 3) { // TEXT
      // If it's already a text node then just reuse it, don't add a new one.
      const text = this.lastElement = <Text> element;
      text.nodeValue = this.zwnbs + text.nodeValue;
    } else {
      // If it's not a text node, such as a <br>, then we actually need to add a new text node.
      const text = this.lastElement = new Text(this.zwnbs);
      element.parentNode.insertBefore(text, element);
    }

    const rng = new Range();
    rng.setStart(this.lastElement, 1);
    editor.selection.setRng(rng);
    editor.nodeChanged();
  }
}

export default ngModule("midas.utility.tinymce.cursorCharactersPlugin", [
  reportEditorModule.name,
  isEditableModule.name
]).component("cursorCharactersPlugin", {
  require: {
    ...tinymceConfigurationRequire
  },
  controller: CursorCharacterPluginController
});