import { module as ngModule, IPromise, IQService, ILogService } from "angular";
import { Modality, MeasurementType } from "businessModels";
import * as jss from "js-search";

/** The shape of the JSON files containing tag information per modality. */
interface TagsJson {
    /** Measurement type key such as `"MRAortaEDV"` to a string containing all tags for that key,
     * such as `[ "Right", "End Diastolic Velocity" ]`. */
    [measurementKey: string]: string[];
}

export class MeasurementSearchService {
    /** There are so few modalities, and even fewer likely to be relevant to an individual client
     * that this will be fastest using a linear search rather than a lookup. */
    private readonly _loadedModalities: string[] = [];

    static $inject = ["$q", "$log"];
    constructor(
        private readonly $q: IQService,
        private readonly $log: ILogService
    ) {
    }

    /** Checks whether tags for measurement types in a modality have been loaded. */
    isLoaded(modality: Modality): boolean {
        return this._loadedModalities.indexOf(modality.key) >= 0;
    }

    /** If the tags for measurement types in a modality haven't been loaded, this will load them
     * into the `MeasurementType.tags` property. If the modality has already had tags loaded this
     * this is a noop. */
    loadTags(modality: Modality): IPromise<void> {
        if (!this.isLoaded(modality)) {
            return this.$q.all({
                tags: import(`./tags/${modality.key.toLowerCase()}.json`) as IPromise<TagsJson>,
                moduleLoad: modality.load()
            }).then(results => {
                const tags = results.tags as TagsJson;
                const notFound = [] as string[];
                modality.getMeasurementTypes().forEach(mt => {
                    const mtTags = tags[mt.key];
                    if (mtTags === undefined) {
                        notFound.push(mt.key);
                    } else {
                        mtTags.sort();
                        mt.tags = mtTags;
                    }
                });
                if (notFound.length > 0) {
                    this.$log.warn(
                      `No tags found for ${notFound.length} measurement types in ${modality.key} modality"`, notFound);
                }
            });
        }
        return this.$q.when();
    }

    /** Builds a search function for a set of measurements. Building a search index is a heavy
     * operation, so the result should be cached where possible. The resulting search function
     * should be fast enough for realtime use, such as in search fields. If the
     * `MeasurementType.tags` field isn't loaded then the search will still work, but will only take the
     * key into account. */
    buildTypeSearch(measurements: MeasurementType[]): IPromise<(searchText: string) => MeasurementType[]> {
        return this.$q.when(jss).then(jsSearch => {
            const search = new jsSearch.Search("id");
            search.addIndex("key");
            search.addIndex("tags");
            search.addDocuments(measurements);
            return search.search.bind(search);
        });
    }

    /** Builds a search function which allows searching over the tags and keys in a set of
     * measurement types. This is useful when providing autocomplete suggestions for tags and
     * measurement keys. If the `MeasurementType.tags` field isn't loaded then the search will still
     * work, but will only take the key into account. */
    buildTagSearch(measurements: MeasurementType[], includeKey: boolean = true):
      IPromise<(searchText: string) => string[]> {
        return this.$q.when(jss).then(jsSearch => {
            const search = new jsSearch.Search("tag");
            search.addIndex("tag");
            for (const mt of measurements) {
                if (includeKey) {
                    search.addDocument({ tag: mt.key });
                }
                if (mt.tags && mt.tags.length > 0) {
                    search.addDocuments(mt.tags.map(tag => ({ tag })));
                }
            }
            return (queryText: string) =>
                search.search(queryText).map((result: { tag: string }) => result.tag);
        });
    }
}

export const serviceName = "measurementTypeSearch";

export default ngModule("midas.admin.reportFormatters.measurementTypeSearchService", [])
    .service(serviceName, MeasurementSearchService);