import { module as ngModule, IController, IScope} from "angular";
import { IStateService } from "angular-ui-router";
import { IAuthService } from '../authService';
import { BusinessModelService, Study, Institute } from "../businessModels";

interface IDemoModule { name: string, requested: boolean }

interface DemoController extends IController {}
class DemoController implements IController {

  selected = { selected1: new Array<IDemoModule>(), selected2: new Array<IDemoModule>()};
  completeText = "Continue";
  stage = "signup";
  instGid = null;
  errorText: any;
  busy: boolean;
  demo: any;
  modules = {
    modules1: [
      { name: "Vascular", requested: true },
      { name: "Echo", requested: true },
      { name: "ECG", requested: true },
      { name: "ABP", requested: true },
      { name: "Holter", requested: true },
      { name: "CT", requested: true }
    ] as IDemoModule[],
    modules2: [
      { name: "Obstetrics", requested: true },
      { name: "Upper Abdo", requested: true },
      { name: "Small Parts", requested: true },
      { name: "MSK", requested: true },
      { name: "POC", requested: true }
    ] as IDemoModule[],
    modulesAll: function(): IDemoModule[]{
      var d = new Array<IDemoModule>();
      this.modules1.forEach(x => d.push(x));
      this.modules2.forEach(y => d.push(y));
      return d;
    },
    isValid: null
  }

  static $inject = ["$scope", "$state", "authService", "businessModels"];
  constructor( 
    private readonly $scope: IScope,
    private readonly $state: IStateService,    
    private readonly authService: IAuthService,
    private readonly businessModels: BusinessModelService
  ) { 
      if (this.authService.isAuthenticated()) {
        this.$state.go("splash");
        return;
      }

      this.$scope.$watch("$ctrl.modules.modules1",
        () => {
          this.selected.selected1 = [];
          for (let module of this.modules.modules1) { 
            if (module.requested) { this.selected.selected1.push(module) } }
          return this.modules.isValid = 
            (this.selected.selected1.length === 0 && this.selected.selected2.length === 0) ? null : true;
        }, true);

      this.$scope.$watch("$ctrl.modules.modules2",
        () => {
            this.selected.selected2 = [];
            for (let module of this.modules.modules2) { 
              if (module.requested) { this.selected.selected2.push(module) } }
            return this.modules.isValid = 
              (this.selected.selected1.length === 0 && this.selected.selected2.length === 0) ? null : true;
          }, true);
  }

  getFormValidationText = () => {
    const f = this.$scope.form;
    if (f) {
      if (f.$invalid && !f.$pristine && f.$error) {
        if (((f.$error.required != null ? f.$error.required.length : undefined) === 1) && f.modules.$invalid) {
            return "Choose at least 1 module";
          } else if (f.$error.required) {
            return "Please fill in all required fields";
          }
      }
    }
  };

  showError = (errorText) => {
    this.busy = false;
    this.completeText = "Try again";
    return this.errorText = errorText;
  };

  goToStudy = () => {
    var self = this;
    let p = Study.find({ 'status.masterName': 'saved' });
    p.then(function(study) {
      p = self.$state.go("midas.studies.view.details", {
        studyId: study.id,
        inst: self.instGid
      }
      );
      return self.businessModels.load.track(p, "Loading Tour");
    });
    return self.businessModels.load.track(p, "Loading Tour");
  };

  login = (deets) => {
    var self = this;
    self.instGid = deets.data;
    self.stage = "login";
    self.busy = true;
    self.completeText = "Logging in...";
    const p = self.authService.authenticate("Admin1", self.instGid, "anypassword", true);
    p.then(self.goToStudy, error => self.showError(error.data.error_description));
    return self.businessModels.load.track(p, "Logging In");
  };

  signup = () => {
    var self = this;
    self.busy = true;
    self.completeText = "Creating account...";
    self.errorText = null;
    self.demo.modules = self.modules.modulesAll();
    const p = Institute.signup(self.demo);
    p.then(self.login, error => self.showError(error.data.ExceptionMessage));
    return self.businessModels.load.track(p, "Creating Account");
  };

  complete = () => {
    if (this.stage === "signup") { return this.signup();
    } else if (this.stage === "login") { return this.login({ "data": this.instGid }); }
  };

  cancel = () => this.$state.go("splash");
}

export default ngModule("midas.anon.demo", [])
.component("demo", {
  controller: DemoController,
  templateUrl: require('./demo.html')
})