import {
  module as ngModule,
  IDirectiveFactory,
  IDirective,
  IWindowService,
  IAugmentedJQuery,
  IDocumentService,
  IScope
} from "angular";
import "angular-material";
import "./mdsSticky.scss";
import { throttle } from "lodash";

/**
 * A directive to calculate if an element is off screen and to apply a css class if so.
 * Used in liu of official "position: sticky" CSS3 support which has a number of bugs.
 * It is used as an attribute on an element, with an option css selector passed in to indicate
 * the selector to the scrolling container to monitor for changes.
 */
const mdsSticky: IDirectiveFactory = ($document: IDocumentService, $window: IWindowService) => {
  return <IDirective> {
    restrict: "A",
    link: ($scope: IScope, $element: IAugmentedJQuery) => {
      const onScroll = throttle(() => recalculate($window, $element.get(0)), 100);
      const selector = $element.attr("mds-sticky");
      const watch = selector == null ? $document : $document.find(selector) || $document;
      watch.on("scroll", onScroll);
      $scope.$on("$destroy", () => watch.off("scroll"));
    }
  };
};
mdsSticky.$inject = ["$document", "$window"];

const recalculate = ($window: IWindowService, element: HTMLElement) => {
  element.classList.remove("mds-sticky");
  const stick = isSticky($window, element);
  if (stick) {
    element.classList.add("mds-sticky");
  }
};
const isSticky = ($window: IWindowService, element: HTMLElement): boolean => {
  const elPos = $window.pageYOffset + element.getBoundingClientRect().top;
  return $window.pageYOffset >= elPos;
};

export default ngModule("midas.utility.layout.sticky", []).directive("mdsSticky", mdsSticky);