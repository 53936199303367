import * as angular from "angular";
import "angular-material";
import "ng-file-upload";
import {
    Study,
    StudyDocument,
    PatientDocument,
    BusinessModelService
} from "../../businessModels";
import { IStudyTitle } from "./StudyTitle";
import ReferenceService from "../../midas/reference/referencesService";

class DocViewModel {
    isPending?: boolean;
    constructor(
        public title: string,
        public type: string,
        public entity?: StudyDocument | PatientDocument) { }

    get id() {
        return this.entity ? this.entity.id : undefined;
    }
}

interface DocumentsCtrl extends angular.IController { }
class DocumentsCtrl implements angular.IController {
    patientDocuments: DocViewModel[];
    documents: DocViewModel[];
    selected?: DocViewModel;

    static $inject = ["$scope", "study", "title", "Upload", "businessModels", "references", "$q", "$mdToast", "$log"];
    constructor(
        $scope: angular.IScope,
        public study: Study,
        title: IStudyTitle,
        private readonly Upload: angular.angularFileUpload.IUploadService,
        private readonly models: BusinessModelService,
        private readonly references: ReferenceService,
        private readonly $q: angular.IQService,
        private readonly toast: angular.material.IToastService,
        private readonly $log: angular.ILogService) {
        title.text = "Documents";
        title.clearButtons();
        title.backButton.show = false;
        title.acceptButton.show = false;

        $scope.$watchCollection(() => study.documents, (newValue: StudyDocument[]) => {
            this.documents = newValue.map(doc => new DocViewModel(doc.localPath, doc.contentType, doc));
        });
    }

    reportUploadError(...messages: string[]) {
        if (messages.length > 0) {
            this.toast.show(this.toast.multi(messages, "Multiple upload failures"));
        }
    }

    uploadFiles(files: File[], invalidFiles: File[]) {
        if (invalidFiles && invalidFiles.length > 0) {
            const messages = invalidFiles.map(file => {
                switch (file["$error"]) {
                    case "maxSize":
                        return `${file.name} is too large, it must be smaller than ${file["$errorParam"]
                            }.`;
                    case "pattern":
                        return `${file.name} must be an image file or a PDF.`;
                    default:
                        return `${file.name} had an unspecified error.`;
                }
            });
            this.reportUploadError(...messages);
        }
        if (!files || files.length === 0) {
            return;
        }
        const uploads = files.map(file => {
            const doc = new DocViewModel(file.name, file.type);
            doc.isPending = true;
            this.documents.push(doc);
            return this.Upload.upload({
                url: `api/midas/UploadDocument/${this.study.id}`,
                data: {
                    file
                },
                method: "POST"
            }).then(() => {
                this.models.Study.find({ id: this.study.id }, "documents");
            });
        });
        this.$q
            .all(uploads)
            .catch(reason => {
                
                this.toast.showSimple("Some uploads failed. Please review your documents.");
                this.$log.error("Some document uploads failed", reason);
            });
    }

    select(doc: DocViewModel) {
        this.selected = doc;
        if (doc) {
            this.references.select("Documents", `${doc.entity.id}`);
        }
    };

    delete(doc: DocViewModel) {
        if (doc) {
            doc.entity.delete().then(() => {
                this.references.select("Documents");
            });
        }
    }
}

export default DocumentsCtrl;