import { IController, ILogService } from "angular";
import "angular-material";
import { IStateService } from "angular-ui-router";
import { PatientDuplicateService as DupService, PatientDuplicate as Duplicate } from "./duplicates/duplicateDetection.service";
import { PatientDuplicateHistory, Patient, BusinessModelService } from "../../businessModels";

interface PatientDetails extends IController {}
class PatientDetails implements IController {
  /** List of possible duplicates from the duplicates service. */
  possibleDuplicates?: Duplicate[];
  /** List of duplicates which were previously merged and can now be undone. */
  undoableDuplicates?: PatientDuplicateHistory[];

  static $inject = [
    "patient", 
    "businessModels", 
    "$state", 
    "$mdToast", 
    "$log", 
    "duplicatePatients"];
  constructor(
    private readonly patient: Patient,
    private readonly models: BusinessModelService,
    private readonly $state: IStateService,
    private readonly $mdToast: angular.material.IToastService,
    private readonly $log: ILogService,
    private readonly duplicates: DupService) {    
      this.loadMergedDuplicates();
      this.loadPotentialDuplicates();
  }

  /** Asynchronously loads patients which might be duplicates of this one. */
  loadPotentialDuplicates() {
    return this.duplicates.search(this.patient).then(
      duplicates => this.possibleDuplicates = duplicates,
      err => this.reportError("Error while searching for possible patient duplicates.", err)
    );
  }

  /** Asynchronously loads the patients which have already been merged into this one. */
  loadMergedDuplicates() {
    return this.patient.getDuplicateHistory().then(
      merged => this.undoableDuplicates = merged,
      err => this.reportError("Error while loading previously merged patients.", err));
  }

  reportError(message: string, err?) {
    this.$mdToast.showSimple(message);
    if (err) {
      this.$log.error(message, err);
    }
  }

  delete() {
    return this.models.save((() => this.patient.delete()), true, false)
      .then(() => this.$state.go("midas"));
  }

  saveDob() {
    const value = this.patient.dob;
    return this.models.save(() => this.patient.dob = value);
  }

  /** Navigates to the patient with the provided id. */
  viewPatient(patientId: number) {
    return this.$state.go("midas.patients.view", { patientId: patientId });
  }

  /** This will delete the provided duplicate, and merge it's studies into the context patient. */
  markDuplicate(duplicatePatientId: number) {
    if (!duplicatePatientId || !this.patient) {
      this.$log.info(`The duplicate or the patient is null`);
      return;
    }
    
    this.duplicates.mergeDuplicate(this.patient.id, duplicatePatientId).then(
      dup => {
        this.loadMergedDuplicates();
        this.loadPotentialDuplicates();

        this.$mdToast.show(
          this.$mdToast.simple()
            .textContent("Patient marked as duplicate and removed")
            .action("Undo"))
          .then(result => {
            if (result === "ok") {
              this.undoMerge(dup);
            }
          });
      })
      .catch(err => this.reportError("The patient could not be marked as a duplicate because of an error.", err));
  }

  undoMerge(duplicateHistory: PatientDuplicateHistory) {
    duplicateHistory.undo().then(
      patient => {
        this.loadMergedDuplicates();
        this.loadPotentialDuplicates();

        this.$mdToast.show(
          this.$mdToast.simple()
            .textContent("Undo successful")
            .action("View Patient"))
          .then(result => {
            if (result === "ok") {
              this.viewPatient(patient.id);
            }
          });
      })
      .catch(err => this.reportError("The patient could not be un-merged because of an error.", err));
  }
}

export default PatientDetails;