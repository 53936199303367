import * as angular from "angular";
import {
  LoadingStatus,
  Events,
  IDisposable,
  ObservableCallback,
  safeDispose,
  Moment,
  createDisposable,
  parseLastPeriod
} from "../utils";
import {
  BusinessModelService,
  Exam,
  Study,
  Patient
} from "../../businessModels";
import { LoDashStatic } from "lodash";
import * as breeze from "breeze";
import ExamTypeNameConfigurationService, { ISearchResult } from "../../admin/institute/examTypeNameConfigurationService";

type BreezeStatic = typeof breeze;

const maxDisplayCount = 100;

export interface ISearchTerm {

    id: string;
    /** The kind of search this term provides. This is a key which the executor uses. */
    type: string;
    /** The data for this search term, specific to the type. */
    value?: any;
    /** The text to display in the UI. */
    text: string;
}

export interface ISearchApi {
    toViewModel(model: any): SearchResultViewModel;
    execute(terms: any): angular.IPromise<Array<any>>;
}

export interface ISearchWindow extends angular.IWindowService {
    searchLoadingStatus: LoadingStatus;
}

export class SearchExecutorProvider {
    private $state: angular.ui.IStateService;
    private $location: angular.ILocationService;
    private $injector: angular.auto.IInjectorService;
    private $q: angular.IQService;
    recording: boolean;
    latest: Array<any>;
    current: any;
    listeners: Events;
    private $window: ISearchWindow;
    private LoadingStatus: LoadingStatus;

    static $inject = ["$state", "$location", "$injector", "$q", "$window", "loadingStatus"];
    constructor($state: angular.ui.IStateService, $location: angular.ILocationService,
        $injector: angular.auto.IInjectorService, $q: angular.IQService, $window: ISearchWindow, loadingStatus: LoadingStatus) {
        this.$state = $state;
        this.$location = $location;
        this.$injector = $injector;
        this.$q = $q;
        this.$window = $window;
        this.LoadingStatus = loadingStatus;

        this.latest = [];
        this.listeners = new Events();
    }

    record(callback: (viewModel: any) => void): IDisposable {
        if (typeof callback == "function") {
            this.recording = true;
            return this.listeners.observe("record", callback)
        }
        return null;
    }

    stop(): void {
        this.recording = false;
    }

    getLatest(): any {
        return this.latest;
    }

    setCurrent(vm: any): any {
        const current = this.getCurrent();
        if (current != null) {
            current.isCurrent = false;
        }
        vm.isCurrent = true;
        this.current = vm;

        this.listeners.notify("record", vm);
    }

    getCurrent(): any {
        return this.current;
    }

    navigateNext(): void {
        let current = this.getCurrent();
        if (!current) {
            return;
        }

        let currentIndex = this.getLatest().indexOf(current);
        if (currentIndex === -1) {
            return;
        }

        let nextIndex = currentIndex + 1;
        if (nextIndex > this.getLatest().length - 1) {
            return;
        }

        let nextItem = this.getLatest()[nextIndex];
        if (!nextItem) {
            return;
        }

        this.setCurrent(nextItem);
        this.$state.go(nextItem.stateName, nextItem.stateArgs);
    }

    get(keys: string | Array<string>) {
        const splitKeys = (keys instanceof Array) ? keys : ((typeof keys === "string") ? keys.split(",") : []);
        const executors = splitKeys.map(key => this.$injector.get(`${key}SearchExecutor`) as ISearchApi);
        const searcher = new SearchAggregator(executors, this.$state, this.$location, this.$q);

        searcher.observe("result", vm => {
            if (vm.getIsCurrent()) {
                this.setCurrent(vm);
            }
            if (this.recording) {
                this.latest.push(vm);
            }
        });
        searcher.observe("error", reason => console.warn('Error in search executor.', reason));
        return searcher;
    }
}

class SearchAggregator {
    private $stopCurrentSearch: IDisposable = null;
    private $events: Events;
    constructor(private $executors: ISearchApi[], private $state: angular.ui.IStateService, private $location: angular.ILocationService, private readonly $q: angular.IQService) {
        this.$events = new Events();
    }

    /** Subscribes to the start event, which is published when search() is called, before any
     * results are produced. */
    observe(eventName: "start", callback: ObservableCallback<void>): IDisposable;
    /** Subscribes to the end event, which is published when all results have been produced, or
     * when the cancel disposable is disposed. If end is produced due to the result sequence
     * completing, then the produced value is true. If end is produced because the cancel
     * disposable was disposed, the produced value is false. */
    observe(eventName: "end", callback: ObservableCallback<boolean>): IDisposable;
    /** Subscribes to the result event, each result is is published in the order they're received. */
    observe(eventName: "result", callback: ObservableCallback<SearchResultViewModel>): IDisposable;
    /** Subscribes to the error event, where error reasons from result promises are published.
     * These don't stop other results from being produced, they're just informative. */
    observe(eventName: "error", callback: ObservableCallback<any>): IDisposable;
    /** Subscribes to the list event, which produces all results as a list from a search that
     * ends without being cancelled. Each observer receives it's own list. */
    observe(eventName: "list", callback: ObservableCallback<SearchResultViewModel[]>): IDisposable;
    observe(eventName: "start" | "end" | "result" | "error" | "list", callback: ObservableCallback<any>): IDisposable {
        if (eventName === "list") {
            let results: SearchResultViewModel[] = null;
            return createDisposable(
                this.$events.observe("start", () => results = []),
                this.$events.observe("result", result => results.push(result)),
                this.$events.observe("end", success => {
                    try {
                        if (success) {
                            callback(results);
                        }
                    }
                    catch (err) {
                        console.warn("Callback threw exception:", err);
                    } finally {
                        results = null;
                    }
                }),
            );
        } else {
            return this.$events.observe(eventName, callback);
        }
    }

    /** Begins a search, cancelling any previous searches. Results and errors are surfaced
     * through the observe() function.
     * @param terms The search terms to search for.
     * @returns A disposable that can be disposed to immediately stop this search and issue an
     * 'end' event. Later searches are still possible.*/
    search(terms: ISearchTerm[]): IDisposable {
        if (this.$stopCurrentSearch) {
            safeDispose(this.$stopCurrentSearch);
        }

        let remaining = this.$executors.length;
        if (remaining == 0) {
            return createDisposable();
        }

        let cancel = this.$stopCurrentSearch = createDisposable();
        const end = (success?: boolean) => {
            this.$stopCurrentSearch = null;
            cancel = null;
            this.$events.notify("end", !!success);
        };
        cancel.add(end);

        const self = this;
        this.$events.notify("start");
        this.$executors.forEach(executor =>
            executor.execute(terms).then(results => {
                for (var i = 0; cancel != null && i < results.length; ++i) {
                    const vm = executor.toViewModel(results[i]);
                    vm.getIsCurrent = function() {
                        return self.$state.href(this.stateName, this.stateArgs)
                            === "#" + self.$location.path();
                    };
                    this.$events.notify("result", vm);
                }
            }, reason => {
                if (cancel != null) {
                    this.$events.notify("error", reason);
                }
            })
            //Equivalent to finally since we handle the exception case without propagating.
            .then(() => {
                if (cancel != null) {
                    --remaining;
                    if (remaining <= 0) {
                        end(true);
                    }
                }
            }));
        return cancel;
    }

    /** Begins a search, cancelling any previous searches. Results are surfaced via the returned
     * promise and observable functions. The promise which will be rejected if the search failed
     * or was cancelled.
     * @param terms The search terms to search for.
     * @returns A promise for the results. */
    searchList(terms: ISearchTerm[]): angular.IPromise<SearchResultViewModel[]> {
        return new this.$q((resolve, reject) => {
            let results: SearchResultViewModel[] = null;
            const dispose = createDisposable(
                this.$events.observe("start", () => results = []),
                this.$events.observe("result", result => results.push(result)),
                this.$events.observe("end", success => {
                    dispose();
                    if (success) {
                        resolve(results);
                    } else {
                        reject("Search unsuccessful");
                    }
                    results = null;
                }));
            this.search(terms);
        });
    }
}

export class StudySearchExecutorApi implements ISearchApi {
    static $inject = ["businessModels", "$q", "lodash", "moment", "breeze",
        "examTypeNameConfigurationService"];

    constructor(
        private readonly businessModels: BusinessModelService,
        private readonly $q: angular.IQService,
        private readonly lodash: LoDashStatic,
        private readonly moment: Moment,
        private readonly breeze: BreezeStatic,
        private readonly mappingService: ExamTypeNameConfigurationService) {
    }

    private queryStudyDateRange(query: breeze.EntityQuery, isoDateLower: moment.Moment,
        isoDateUpper: moment.Moment): breeze.EntityQuery {
        if (isoDateUpper != null) {
            query = query.where("performedAt", "<=", isoDateUpper);
        }

        if (isoDateLower != null) {
            query = query.where("performedAt", ">=", isoDateLower);
        }

        return query;
    }

    private queryOnDay(query: breeze.EntityQuery, isoDay: moment.Moment): breeze.EntityQuery {
        return this.queryStudyDateRange(query,
            isoDay.clone().startOf("day"), isoDay.clone().endOf("day"));
    }

    private getSecondary(exam: Exam): string {
        if (exam == null) {
            return null;
        }

        let templates: any = [];
        if (exam.type != null) {
            templates = exam.type.templates.filter((x) => x.isDeleted === false);
        }

        let mapped: ISearchResult = null;
        //New studies may have no exam type.
        if (exam.type !== null) {
            mapped = this.mappingService.getCustomName(exam.type.key);
        }
        let base: string;
        if (mapped !== null && mapped.matchFound === true) {
            base = mapped.examType;
        }
        else {
            base = exam.title !== null
                ? exam.title
                : (exam.type !== null
                    ? (exam.type.display != null ? exam.type.display : exam.type.key)
                    : "Unknown")
        }

        if (exam.title != null && templates.length > 1) {
            base += " (";
            for (let diagram of exam.diagrams) {
                base += diagram.name;
            }
            base += ")";
        }
        return base;
    }

    toViewModel(examOrStudy: Exam | Study): SearchResultViewModel {
        let study: Study;
        let exam: Exam;
        if (examOrStudy["study"]) {
            study = examOrStudy["study"];
            exam = <Exam>examOrStudy;
        } else {
            study = <Study>examOrStudy;
            const exams = study.exams;
            if (exams.length > 0) {
                exam = exams[0];
            }
        }

        if (study == null) {
            return null;
        }

        const viewModel = new SearchResultViewModel(study.id, "study");
        viewModel.abbreviation = "S";
        viewModel.primary = ((study.patient != null && study.patient.lastName != null)
            ? study.patient.lastName.toUpperCase() : "")
            + ` ${study.patient.firstName}`;
        viewModel.secondary = this.getSecondary(exam);

        const examTime = (study.exams[0] != null && study.exams[0].performedAt != null)
            ? study.exams[0].performedAt : study.reportedAt;
        viewModel.aside1 = this.moment(examTime)
            .format(this.businessModels.User.current.shortDateFormat);
        viewModel.aside2 = this.moment(examTime)
            .format(this.businessModels.User.current.shortTimeFormat);
        viewModel.aside3 = (study.status != null) ? study.status.key : null;
        viewModel.stateName = (study.status == null || study.status.key === "New")
            ? "midas.studies.pending" : "midas.studies.view.details";
        viewModel.stateArgs = {
            studyId: study.id,
            inst: this.businessModels.User.current.institute.key
        };
        viewModel.notification = study.notes.length > 0;
        return viewModel;
    }

    private sortByFirstExamDate(results: Exam[]): Exam[] {
        const intermediate = this.lodash.sortBy(results, (result) => -result.performedAt);
        return this.lodash.uniq(intermediate, (result) => result.study.id);
    }

    private whereTechnicianPredicate(techId: number): breeze.Predicate {
        return breeze.Predicate.or(
            breeze.Predicate.create("study.technicianId", "==", techId),
            breeze.Predicate.create("study.secondaryTechnicianId", "==", techId)
        );
    }

    execute(terms: ISearchTerm[]): angular.IPromise<Exam[]> {
        const emptyPromise = this.$q.resolve([]);
        if (terms != null && terms.length == null) {
            return emptyPromise;
        }

        let query = breeze.EntityQuery.from("Exams")
            .expand("study.patient.person, study.modality, " +
                "studyType.modality, study.status, diagrams.template, " +
                "study.notes")
            .where("study.isDeleted", "!=", "true")
            .orderByDesc("performedAt")
            .take(maxDisplayCount);

        let from: moment.Moment;
        let now: moment.Moment;
        for (let term of terms) {
            switch (term.type) {
                case "ID":
                    query = query.where("study.id", "==", term.value);
                    break;
                case "patient":
                    query = query.where("study.patientId", "==", term.value);
                    break;
                case "status":
                    query = query.where("study.statusId", "==", term.value);
                    break;
                case "module":
                    query = query.where("study.modalityId", "==", term.value);
                    break;
                case "examType":
                    query = query.where("studyTypeId", "==", term.value);
                    break;
                case "studiesWithNotes":
                    query = query.where("study.notes", "any", "content", "!=", null);
                    break;
                case "recordType":
                    if (term.value !== "Studies") {
                        return emptyPromise;
                    }
                    break;
                case "tech":
                    query = query.where(this.whereTechnicianPredicate(term.value));
                    break;
                case "phys":
                    query = query.where("study.physicianId", "==", term.value);
                    break;
                case "site":
                    query = query.where("study.siteId", "==", term.value);
                    break;
                case "myStudies":
                    const currentUser = this.businessModels.User.current;
                    const statuses = this.businessModels.Status.listNow();
                    const newStatus = this.lodash.find(statuses, x => x.key === "New");
                    const saved = this.lodash.find(statuses, x => x.key === "Saved");
                    const provisional = this.lodash.find(statuses, x => x.key === "Provisional");
                    const final = this.lodash.find(statuses, x => x.key === "Final");
                    query = query.where("study.statusId", "!=", final.id);
                    //Techs should be able to see 'New' studies that have not been assigned to anyone yet
                    let newPredicate = breeze.Predicate.create("study.statusId", "==", newStatus.id);

                    if (currentUser.technician && currentUser.physician) {
                        //Is assigned to current user as physician, technician or any new study
                        let techPredicate = this.whereTechnicianPredicate(currentUser.technician.id);
                        let physPredicate = breeze.Predicate.create("study.physicianId", "==", currentUser.physician.id);
                        let userPredicate = breeze.Predicate.or([techPredicate, physPredicate, newPredicate]);
                        query = query.where(userPredicate);
                    } else if (currentUser.technician) {
                        //Show all new studies, or any saved studies that are assigned to the technician
                        let savedPredicate = breeze.Predicate.create("study.statusId", "==", saved.id);
                        let techPredicate = this.whereTechnicianPredicate(currentUser.technician.id);
                        let mySavedStudiesPredicate = breeze.Predicate.and([savedPredicate, techPredicate]);
                        let myPredicate = breeze.Predicate.or([newPredicate, mySavedStudiesPredicate]);
                        query = query.where(myPredicate);
                    } else if (currentUser.physician) {
                        query = query.where("study.statusId", "==", provisional.id)
                                        .where("study.physicianId", "==", currentUser.physician.id);
                    }
                    break;
                case "studiesOn":
                    query = this.queryOnDay(query, this.moment(term.value));
                    break;
                case "studiesToday":
                    query = this.queryOnDay(query, this.moment());
                    break;
                case "studiesYesterday":
                    query = this.queryOnDay(query, this.moment().subtract(1, "day"));
                    break;
                case "studiesThisMonth":
                    now = this.moment();
                    from = now.clone().startOf("month");
                    query = this.queryStudyDateRange(query, from, now);
                    break;
                case "studiesLastMonth":
                    from = this.moment().clone().subtract(1, "month").startOf("month");
                    query = this.queryStudyDateRange(query, from, from.clone().endOf("month"));
                    break;
                case "studiesAfter":
                    query = this.queryStudyDateRange(query, this.moment(term.value).endOf("day"), null);
                    break;
                case "studiesBefore":
                    query = this.queryStudyDateRange(query, null, this.moment(term.value).startOf("day"));
                    break;
                case "studiesLastPeriod":
                    now = this.moment();
                    from = parseLastPeriod(term.value, "days", now);
                    query = this.queryStudyDateRange(query, from.startOf("day"), now.endOf("day"));
                    break;
                default:
                    console.warn(`No search executor found for term.type = ${term.type}`);
                    return emptyPromise;
            }
        }

        return new this.$q((resolve, reject) => this.businessModels.breeze.executeQuery(query)
            .then((data) =>
                resolve(this.sortByFirstExamDate(<Exam[]>this.businessModels.asBusinessModel(data.results))),
                reject));
    }
}

export class SearchResultViewModel {
    constructor(id: number, type: string) {
        this.id = id;
        this.type = type;
    }

    id: number;
    type: string;
    abbreviation: string;
    primary: string;
    secondary: string;
    aside1: string;
    aside2: string;
    aside3: string;
    stateName: string;
    stateArgs: any;
    getIsCurrent?: () => boolean;
    notification: boolean = false;
}

export class PatientSearchExecutorApi implements ISearchApi {

    static $inject = ["businessModels", "$q", "moment"];

    constructor(
        private readonly businessModels: BusinessModelService,
        private readonly $q: angular.IQService,
        private readonly moment: Moment) {
    }

    private queryOnDay(query: breeze.EntityQuery, property: string, isoDate: moment.Moment):
        breeze.EntityQuery {
        const date = this.moment(isoDate);
        return query.where(property, ">=", date.clone().startOf("day"))
            .where(property, "<=", date.clone().endOf("day"));
    }

    toViewModel(patient: Patient): SearchResultViewModel {
        const viewModel = new SearchResultViewModel(patient.id, "patient");
        viewModel.abbreviation = "P";
        viewModel.primary = (patient.lastName != null
                ? patient.lastName.toUpperCase()
                : "") +
            ` ${patient.firstName}`;
        viewModel.secondary = patient.urNumber;
        viewModel.aside3 = this.businessModels.User.current.formatDate(patient.dob);
        viewModel.stateName = "midas.patients.view";
        viewModel.stateArgs = {
            inst: this.businessModels.User.current.institute.key,
            patientId: patient.id
        };
        return viewModel;
    }

    execute(terms: ISearchTerm[]): angular.IPromise<Patient[]> {
        const emptyPromise = this.$q.when([]);
        if (terms != null && terms.length == null) {
            return emptyPromise;
        }

        let query = breeze.EntityQuery.from("Patients")
            .expand("person")
            .where("isDeleted", "!=", "true")
            .orderBy("id desc")
            .take(maxDisplayCount);

        const contains = breeze.FilterQueryOp.Contains;

        for (let term of terms) {
            switch (term.type) {
                case "ID":
                    query = query.where("id", "==", term.value);
                    break;
                case "patient":
                    query = query.where("id", "==", term.value);
                    break;
                case "recordType":
                    if (term.value !== "Patients") {
                        return emptyPromise;
                    }
                    break;
                case "UR Number":
                    query = query.where("toLower(urNumber)", contains, term.value);
                    break;
                case "First Name":
                    query = query.where("toLower(person.firstName)", contains, term.value);
                    break;
                case "Last Name":
                    query = query.where("toLower(person.lastName)", contains, term.value);
                    break;
                case "patientDob":
                    query = this.queryOnDay(query, "person.dob", this.moment(term.value));
                    break;
                default:
                    return emptyPromise;
            }
        }

        return new this.$q((resolve, reject) =>
            this.businessModels.breeze.executeQuery(query)
            .then((data) =>
                resolve(<Patient[]>this.businessModels.asBusinessModel(data.results)),
                reject));
    }
}