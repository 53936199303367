import { module, IDirective, material, IScope, IController } from "angular";
import { IMeasurementLayoutController, MeasurementUIElement, IMeasurementElement , ILabelElement } from "./mds-measurement-layout";
import serviceModule, { MeasurementUnitService, serviceName } from "./mds-measurement-unit.service";
import { MeasurementViewScope } from "../directives/measurementView";
import { ExamType, Exam } from "../../../businessModels";

interface IMeasurementLayoutItemScope extends MeasurementViewScope {
  element: MeasurementUIElement;
}

class MeasurementLayoutItemController implements IController {
  layout: IMeasurementLayoutController;  
  measurementKey: string;
  studyTypeId: number;
  measurementDataType: string;
  measurementTypeUnit: string;

  static $inject = ["$scope", "$mdToast", "$mdDialog"];
  constructor(
    private readonly $scope: IMeasurementLayoutItemScope,
    private readonly $mdToast: material.IToastService,
    private readonly $mdDialog: material.IDialogService) {

    if (
      (this.$scope.element as IMeasurementElement).type === "measurement") {
      this.measurementKey = (this.$scope.element as IMeasurementElement).key;
      this.studyTypeId = this.$scope.exam instanceof Exam ? this.$scope.exam.type.id : (this.$scope.exam as ExamType).id;
      this.measurementDataType = this.$scope.dataType(this.measurementKey)
      this.measurementTypeUnit = this.$scope.units(this.measurementKey);
    }
  }

  $onInit() { return; }

  remove(): void {
    this.layout.remove(this.$scope.element);
  }

  changeText(ev: MouseEvent) {
    const element = this.$scope.element;
    if (element.type !== "label") {
      throw new Error(`Cannot change text of ${element.type} element.`);
    }
    this.$mdDialog.show(
      this.$mdDialog.prompt()
        .title("Label")
        .textContent("Enter text for your label below")
        .placeholder("Label text")
        .ariaLabel("Label text")
        .initialValue(element.text)
        .targetEvent(ev)
        .ok("OK")
        .cancel("Cancel")
    ).then(text => element.text = text);
  }

  changeExpanderTitle(ev: MouseEvent) {
    const element = this.$scope.element;
    if (element.type !== "expander") {
      throw new Error(`Cannot change title of ${element.type} element.`);
    }
    this.$mdDialog.show(
      this.$mdDialog.prompt()
        .title("Title")
        .textContent("Enter text for your expander below")
        .placeholder("Expander title")
        .ariaLabel("Expander title")
        .initialValue(element.title)
        .targetEvent(ev)
        .required(true)
        .ok("OK")
        .cancel("Cancel")
    ).then(title => element.title = title);
  }

  /** Copy to clipboard success callback provided by angular-clipboard directive. */
  copyKeySuccess(key: string): void {
    this.$mdToast.showSimple(`"${key}" copied to clipboard.`);
  }

  /** Copy to clipboard error callback provided by angular-clipboard directive. */
  copyKeyError(key: string): void {
    this.$mdToast.showSimple(`There was a problem copying the key "${key}" to the clipboard.`);
  }

  changeAlignment(element: ILabelElement, alignment: "start" | "center" | "end") {
    element.align = alignment;
  }

  setFlat(element: IMeasurementElement, flat: boolean) {
    element.flat = flat;
  }

  openSearchMeasurementTypesDialog(element: IMeasurementElement) {
    this.$mdDialog.showMeasurementTypePrompt(this.$scope.modality.getMeasurementTypes())
      .then(mt => {
        element.key = mt.key;
      });
  }

  changeMeasurementUnits() {
    const element = this.$scope.element;
    if (element.type !== "measurement") {
      throw new Error(`Cannot change units of ${element.type} element.`);
    }
    this.$mdDialog.show({
      templateUrl: require("./mds-measurement-layout-item-unit-dialog.html"),
      clickOutsideToClose: true,
      locals: {
        measurementKey: this.measurementKey,
        studyTypeId: this.studyTypeId,
        measurementTypeUnit: this.measurementTypeUnit
      },
      controller: UnitDialogController,
      controllerAs: "$ctrl"
    }).then(unit => { 
      if (unit) this.measurementTypeUnit = unit
    });
  }
}

class UnitDialogController implements angular.IController {
  measurementKey: string;
  studyTypeId: number;
  measurementTypeUnit: string;

  //To be replaced later
  units = ['mm','mm[Hg]','cm/s2','gm/mSq'];

  static $inject = ["measurementKey", "studyTypeId", "measurementTypeUnit", "$mdDialog", serviceName];
  constructor(
    measurementKey: string,
    studyTypeId: number,
    measurementTypeUnit: string,
    private readonly $mdDialog: material.IDialogService,
    private readonly unitService: MeasurementUnitService) {
      this.measurementKey = measurementKey;
      this.studyTypeId = studyTypeId;
      this.measurementTypeUnit = measurementTypeUnit;
  }

  $onInit() { }

  save() {
    this.unitService.upsertMeasurementTypeUnit(
      this.measurementKey, this.studyTypeId, this.measurementTypeUnit)
    this.$mdDialog.hide(this.measurementTypeUnit);
  }

  cancel() {
    this.$mdDialog.hide();
  }
}

const directive: IDirective = {
  controller: MeasurementLayoutItemController,
  controllerAs: "$ctrl",
  bindToController: true,
  templateUrl: require("./mds-measurement-layout-item.component.html"),
  require: {
    layout: "^mdsMeasurementLayout"
  }
};

export default module(
  "midas.admin.measurementLayout.measurementLayoutItem", [serviceModule.name])
.directive("mdsMeasurementLayoutItem", () => directive);