import { module as ngModule } from "angular";
import mdsVisualStackModule from "../../directives/mdsVisualStack/mdsVisualStack";
import glossaryCategoryComponent from "./glossaryCategory";
import glossaryCategoryListComponent from "./glossaryCategoryList";
import glossaryEntryComponent from "./glossaryEntry";
import glossaryEntryListComponent from "./glossaryEntryList";
import glossarySectionComponent from "./glossarySection";
import tinyMceModule from "../../../tinymce.module";

export default ngModule("midas.admin.glossary", [
    tinyMceModule.name, //For glossaryEntry.ts
    mdsVisualStackModule.name
])
    .component("glossaryCategory", glossaryCategoryComponent)
    .component("glossaryCategoryList", glossaryCategoryListComponent)
    .component("glossaryEntry", glossaryEntryComponent)
    .component("glossaryEntryList", glossaryEntryListComponent)
    .component("glossarySection", glossarySectionComponent);