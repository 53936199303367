import { module, IHttpService,  IPromise } from "angular";

export interface IMeasurementTypeUnit{
  key: string,
  unit: string
}

/** A service to get and upsert MeasurementType units for an exam type. */
export class MeasurementUnitService {

  static $inject = ["$http"];
  constructor( private readonly $http: IHttpService ) {}

  upsertMeasurementTypeUnit(
    measurementKey: string, 
    studyTypeId: number, 
    unit: string): IPromise<string> {
      return this.$http.post(`api/midas/upsertMeasurementTypeUnit/`, 
        { measurementKey, studyTypeId, unit })
        .then(result => {
            return result.data as string;
        })
  }

    getMeasurementTypeCustomUnits(id: number): IPromise<any> {
      return this.$http.get(`api/midas/MeasurementTypeCustomUnits/${id}`)
      .then(result => {
        return (result.data as any)
          .map(x => { 
            return { key: x.key, unit: x.unit} as IMeasurementTypeUnit 
          });
      });
    }
}

export const serviceName = "mdsMeasurementUnitService";
export default module("midas.utility.measurement.unitService", [])
  .service(serviceName, MeasurementUnitService);