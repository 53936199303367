import { module as ngModule, IScope, IRootScopeService } from "angular";
import ReferenceService from "./referencesService";
import ReferenceDirective from "./mdsReferences";
import layoutModule from "../../utility/layout/layout";
import mdsTileControl from "../../utility/tileControl/tileControl";
import videogularModule from "../../videogular.module";
import "./references.scss";
import { Study } from "../../businessModels";

export default ngModule("midas.reference", [
    layoutModule.name,
    mdsTileControl.name,
    videogularModule.name
])
    .service("references", ReferenceService)
    .directive("mdsReferences", ReferenceDirective)
    .controller("referenceCtrl",
    ["layoutStateService", "$scope", "study", "history",
    (layoutStateService, $scope: IScope, study: Study, history) => {
        $scope.isFullscreen = () => {
            if (layoutStateService.current) {
                return layoutStateService.current.state === "fullScreenSlideLeft";
            }
        };
        $scope.study = study
        $scope.history = history
    }]);