import RenderableShape, { shadowDefaultColour, shadowDefaultExtraThickness } from "./renderableShape";
import { Shape } from "createjs";
import { Vector2, IPointLike } from "../../../utility/utils";
import { coordExtent } from "../imageEditorVars";
import Circle from "./circle";
import ArrowCap from "./arrowCap";
import { Rect } from "../../geometry/rect";

/** A line which can render itself to a canvas, with optional arrow caps. */
export default class Line extends RenderableShape {
    /** The handle to the graphical representation of this shape. */
    ink: Shape;
    /** The line segment delimiters. */
    points: Vector2[];
    /** The thickness of the line. */
    thickness: number;
    /** The colour to use to draw the line. */
    colour: string;
    /** The colour to use to draw the shadow. */
    shadowColour: string = shadowDefaultColour;
    /** How much thicker the shadow should be compared to the shape. */
    shadowExtraThickness: number = shadowDefaultExtraThickness * coordExtent;
    /** Whether to draw an arrow on the start of the line. */
    arrowStart: boolean;
    /** Whether to draw an arrow on the end of the line. */
    arrowEnd: boolean;
  
    /**
     *
     * @param ink
     * @param points
     * @param thickness
     * @param colour
     * @param arrowStart
     * @param arrowEnd
     * @param arrowOverhang An amount that any arrow caps should overhang the end points. Not
     * generally useful unless you're rendering shadows.
     */
    static render(ink: Shape, points: IPointLike[], thickness: number, colour: string, arrowStart?: boolean, arrowEnd?: boolean, arrowOverhang: number = 0) {
      const mitreLimit = 2.5;
      if (points.length === 1) {
        Circle.render(ink, points[0], thickness / 2, colour);
      } else if (points.length > 1) {
        const g = ink.graphics.setStrokeStyle(thickness, undefined, undefined, mitreLimit).beginStroke(colour);
        let p = points[0];
  
        if (arrowStart) {
          const tip = new Vector2(p);
          const arrowAxis = new Vector2(tip).subtract(points[1]).normalise();
          if (arrowOverhang !== 0) {
            tip.add(arrowAxis.clone().multiply(2 * arrowOverhang));
          }
          const base = thickness > 10 
            ? arrowAxis.clone().multiply((thickness * -2) + arrowOverhang).add(tip)
            : arrowAxis.clone().multiply(arrowOverhang - 20).add(tip);
          ArrowCap.render(ink, base, tip, thickness * 2 - arrowOverhang, colour);
          g.setStrokeStyle(thickness, undefined, undefined, mitreLimit).beginStroke(colour);
          g.moveTo(base.x, base.y);
        } else {
          g.moveTo(p.x, p.y);
        }
  
        const len = points.length - 1;
        for (let i = 1; i < len; ++i) {
          p = points[i];
          g.lineTo(p.x, p.y);
        }
  
        p = points[points.length - 1];
        if (arrowEnd) {
          const tip = new Vector2(p);
          const arrowAxis = new Vector2(tip).subtract(points[points.length - 2]).normalise()
          if (arrowOverhang !== 0) {
            tip.add(arrowAxis.clone().multiply(arrowOverhang * 2));
          }
          const base = thickness > 10 
            ? arrowAxis.clone().multiply((thickness * -2) + arrowOverhang).add(tip)
            : arrowAxis.clone().multiply(arrowOverhang - 20).add(tip);  
          g.lineTo(base.x, base.y);
          ArrowCap.render(ink, base, tip, thickness * 2 - arrowOverhang, colour);
        } else {
          g.lineTo(p.x, p.y);
        }
      }
      return ink;
    }
  
    render(drawShadow: boolean = false, target: Shape = this.ink) {
      target.graphics.clear();
      if (drawShadow) {
        Line.render(target, this.points, this.thickness + this.shadowExtraThickness, this.shadowColour, this.arrowStart, this.arrowEnd, this.shadowExtraThickness / 2);
      }
      return Line.render(target, this.points, this.thickness, this.colour, this.arrowStart, this.arrowEnd);
    }
  
    /** Bakes the current ink position into the points and re-renders. Essentially shifts any
     * offset from the ink position to the actual pixel positions. */
    bakeTransform(): void {
      this.$trace && this.$trace("Line.bakeTransform()");
      const m = this.ink.getMatrix(RenderableShape.ScratchMatrix);
      for (var point of this.points) {
        m.transformPoint(point.x, point.y, point);
      }
      RenderableShape.resetInkTransform(this.ink);
    }
  
    protected getBounds(): Rect {
      const thickness = (this.thickness || 0) * 1.5; //Extra thickness to include possible arrow caps.
      return Rect.boundingRect(this.points).expand(thickness);
    }
  
    constructor(points: IPointLike[], thickness: number, colour: string, arrowStart: boolean = false, arrowEnd: boolean = false) {
      super();
      this.points = points.map((p) => new Vector2(p));
      this.arrowStart = arrowStart;
      this.arrowEnd = arrowEnd;
      this.thickness = thickness;
      this.colour = colour;
      this.ink = new Shape();
      this.ink.name = "Line";
      this.render();
    }
  }
  