import { module as ngModule } from "angular";
import DiagramContainerDirective from "./directives/measurementDiagramContainer";
import DiagramAreaDirective from "./directives/measurementDiagramArea";
import DiagramPopupDirective from "./directives/measurementDiagramPopup";
import SetMeasurementDirective from "./directives/setMeasurement";
import CycleMeasurementDirective from "./directives/cycleMeasurement";
import measurementActions from "./config/mds-measurement-actions.component";
import measurementViewModule from "./directives/measurementView";
import customLayoutProvider from "../../utility/measurement/config/mds-custom-layout-provider.component";
import MeasurementDirective from "./directives/measurement";
import MeasurementsSectionDirective from "./directives/mdsMeasurementSection";
import MeasurementsDirective from "./directives/mdsMeasurements";
import srcRawModule from "../srcRaw";
import measurementTemplateCacheModule from "./measurementTemplateCache.service";
import allNormalButton from "../../utility/measurement/view/UIElements/allNormalButton";

import viewModule from "./view/views";
import utilityModule from "../../utility/utils";

const mod = ngModule("midas.utility.measurement", [
    viewModule.name,
    utilityModule.name,
    srcRawModule.name,
    measurementViewModule.name,
    customLayoutProvider.name,
    measurementTemplateCacheModule.name,
    allNormalButton.name,
    measurementActions.name
])
    .directive("mdsDiagramContainer", DiagramContainerDirective)
    .directive("mdsDiagramArea", DiagramAreaDirective)
    .directive("mdsDiagramPopup", DiagramPopupDirective)
    .directive("mdsSetMeasurement", SetMeasurementDirective)
    .directive("mdsCycleMeasurement", CycleMeasurementDirective)
    .directive("mdsMeasurement", MeasurementDirective)
    .directive("mdsMeasurementSection", MeasurementsSectionDirective)
    .directive("mdsMeasurements", MeasurementsDirective);

// Don't compile the measurement debug panel directive when in production mode.
declare const process;
if (process.env.NODE_ENV !== "production") {
    const measurementDebugPanelDirective = require("./directives/debugPanel").default;
    mod.directive("mdsMeasurementDebugPanel", measurementDebugPanelDirective);
}

export default mod;