import { IScope, IRootScopeService, IQService, IHttpService, ILocationService, IController, IControllerConstructor } from "angular";
import { BusinessModelService, Institute } from "../../businessModels";
import { LoadingStatus } from '../../utility/utils';
import { IStateService, IStateParamsService } from 'angular-ui-router';
import { LoDashStatic } from 'lodash';

/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS104: Avoid inline assignments
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */

export default
  ["$scope", "$rootScope", "loadingStatus", "$state", "$stateParams", "$q", "Upload", "lodash",
  "businessModels", "inst", "$anchorScroll", "$location", "$http",
  <IControllerConstructor>function($scope: IScope, $rootScope: IRootScopeService, loadingStatus:
  typeof LoadingStatus, $state: IStateService, $stateParams: IStateParamsService, $q: IQService,
  Upload, lodash: LoDashStatic, models: BusinessModelService, inst: Institute, $anchorScroll,
  $location: ILocationService, $http: IHttpService) {
    let goToStudy, pauseCountdown, stopCountdown, study, writeStudy;
    $scope.study = (study = {});
    $scope.canUpload = true;
    $scope.files = [];
    $scope.waitLoadTick = 100.0;
    $scope.redirecting = false;
    $scope.paused = false;
    const id = new Guid().create();
    $scope.ignoreExisting = false;
    
    $scope.batchSize = 5;

    const savedStatus = lodash.find(models.Status.listNow(), x => x.key === "Saved");
    
    models.Physician.list().then(function(p) { 
      $scope.physicians = p;
      if ($scope.physicians.length === 1) { return $scope.study.physician = $scope.physicians[0]; }});
        
    models.Technician.list().then(function(t) { 
      $scope.technicians = t;
      if ($scope.technicians.length === 1) { return $scope.study.technician = $scope.technicians[0]; }});
    
    const upload = file =>
      file.upload = Upload.upload({
        url: `api/midas/ImportNonDicomStudy/?gid=${id}&ignoreExisting=${$scope.ignoreExisting}`,
        data: { 
          file
        }}).then(function(response) { 
        $scope.uploadsRemaining--;
        file.done = true;
        if (!$scope.patient) {
          models.Study.find({ id: response.data.studyId })
          .then(x => study.entity = x);
          $scope.patient = { 
            firstName: response.data.firstName,
            lastName: response.data.lastName
          };
          $rootScope.$broadcast('duplicateCheckRequested', { 
            studyId: response.data.studyId,
            dupesFound,
            dupeSelected
          }
          );
          $scope.study.performedAt = response.data.performedAt;
          return $scope.study.id = response.data.studyId;
        }}).catch(function(error) {
          $scope.uploadsRemaining--;
          file.error = error;
          file.getErrorMessage = function() { let left, left1;
          return (left = (left1 = (this.error.data != null ? this.error.data.ExceptionMessage : undefined) != null ? (this.error.data != null ? this.error.data.ExceptionMessage : undefined) : (this.error.data != null ? this.error.data.Message : undefined)) != null ? left1 : (this.error.data != null ? this.error.data.ExceptionType : undefined)) != null ? left : this.error.statusText; };
          if (!$scope.dupeStudy && ((error.data != null ? error.data.type : undefined) === "StudyConflict") && (error.data != null ? error.data.studyId : undefined)) {
          return $scope.dupeStudy = { id: error.data.studyId, uid: error.data.studyUId }; }})
    ;
    
    $scope.uploadFiles = function(files, errFiles) {
      if (!files) { return; }
      $scope.canUpload = false;
      $scope.fileIndex = 0;
      const uploads = [];
      $scope.uploadsRemaining = files.length;
      const index = 0;
      for (let f of files) { $scope.files.push(f); }
      progressNext(0, files, $scope.batchSize)
      .then(() => $scope.canUpload = true);
    };
    
    var progressNext = function(index, files, batchSize) {
      const startIndex = index;
      const promises = [];
      while (index < (startIndex + batchSize)) {
        const batchItem = files[index++];
        if (batchItem) { promises.push(upload(batchItem)); }
      }
      
      if (!promises.length) { return $q.when([]); }
      
      return $q.all(promises)
      .then(() => progressNext(index, files, batchSize));
    };
    
    var dupesFound = function(dupes) {
      $scope.study.hasDuplicates = dupes != null ? dupes.length : undefined;
      $scope.study.dupeMessage = `${dupes.length} potential duplicates found`;
      if (dupes.length === 0) { return $scope.study.hasCheckedPatientDupes = true; }
    };
    
    var dupeSelected = function(dupe) {
      $scope.study.hasCheckedPatientDupes = true;
      if (dupe) { 
        $scope.study.dupeMessage = "Duplicate selected";
        return models.Patient.find({
          id: dupe.stateArgs.patientId})
        .then(function(x) { 
          console.log(x, dupe);
          return $scope.study.dupe = x;
        });
      } else { 
        return $scope.study.dupeMessage = "No match selected";
      }
    };
    
    $scope.recheckDuplicates = function() {
      $scope.study.hasCheckedPatientDupes = true;
      if (!$scope.study) { return; }
      return $rootScope.$broadcast('duplicateCheckRequested', { 
        studyId: $scope.study.id,
        dupesFound,
        dupeSelected
      }
      );
    };
    
    $scope.goToExistingStudy = function() {
      if (!$scope.dupeStudy) { return; }
      return $state.go("midas.studies.view.details", { 
        studyId: $scope.dupeStudy.id,
        inst: inst.key
      }
      );
    };
    
    $scope.cancelImport = () => $state.go("midas");
    
    $scope.ignoreExistingStudy = function() {
      $scope.dupeStudy = null;
      $scope.ignoreExisting = true;
      $scope.files = [];
      return $scope.uploadsRemaining = undefined;
    };
    
    $scope.goToStudy = (goToStudy = function(write = true) { 
      let wait = $q.when([]);
      if (write) { wait = writeStudy(); }
      return wait.then(() => 
        $state.go("midas.studies.view.details", { 
          studyId: $scope.study.id,
          inst: inst.key
        }
        )
      );
    });
    
    let countdownInterval = null;
    
    const startCountdown = function() {
      $location.hash('countdown');
      $anchorScroll();
      $scope.redirecting = true;
      const tick = function() {
        $scope.waitLoadTick = $scope.waitLoadTick - 1;
        $scope.$apply();
        
        if ($scope.waitLoadTick === 0) {
          clearInterval(countdownInterval);
          return goToStudy();
        }
      };
        
      return countdownInterval = setInterval(tick, 500);
    };
    
    $scope.pauseCountdown = (pauseCountdown = function() {
      $scope.paused = true;
      return stopCountdown();
    });
    
    $scope.stopCountdown = (stopCountdown = function() {
      clearInterval(countdownInterval);
      $scope.waitLoadTick = 10;
      return $scope.redirecting = false;
    });
      
    $scope.writeStudy = (writeStudy = function() {
      const write = function() {
        $scope.study.entity.physician = $scope.study.physician;
        $scope.study.entity.technician = $scope.study.technician;
        $scope.study.entity.status = savedStatus;
        if ($scope.study.dupe && ($scope.study.entity.patient.id !== $scope.study.dupe.id)) {
          $scope.study.entity.patient.isDeleted = true;
          return $scope.study.entity.patient = $scope.study.dupe;
        }
      };
      return models.save(write, false, false);
    });
    
    $scope.readyToGo = function() { 
      const isReady = $scope.canUpload && !$scope.paused && ($scope.study != null ? $scope.study.physician : undefined) && ($scope.study != null ? $scope.study.technician : undefined) && ($scope.study != null ? $scope.study.hasCheckedPatientDupes : undefined);
      if (isReady && !$scope.redirecting) { startCountdown(); }
      if (!isReady && $scope.redirecting) { stopCountdown(); }
      return isReady;
    };
      
    $scope.$on("$destroy", () => stopCountdown());
  }
  ];

 
class Guid {
  s4() {
    return Math.floor((1 + Math.random()) * 0x10000).toString(16).substring(1);
  }

  create() {
    return `${this.s4()}${this.s4()}-${this.s4()}-${this.s4()}-${this.s4()}-${this.s4()}${this.s4()}${this.s4()}`;
  }
}

