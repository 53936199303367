import * as angular from "angular";
import "angular-material";
import instituteSettingsMetadata from "./instituteSettingsMetadata";
import MultiToast from "./multiMessageToast";
import examTypeNameConfigurationService from "../admin/institute/examTypeNameConfigurationService";
import { IHttpProvider } from 'angular';
import { BusinessModelService } from "../businessModels";

export default angular.module("midas.config", ["ngMaterial"])
    .value("instituteSettingsMetadata", instituteSettingsMetadata)
    .service("examTypeNameConfigurationService", examTypeNameConfigurationService)
    .decorator("$mdToast", ["$delegate", ($mdToast: angular.material.IToastService) => {
        $mdToast.multi = MultiToast.toastOptions;
        return $mdToast;
    }])
    // https://blog.thoughtram.io/angularjs/2015/01/14/exploring-angular-1.3-speed-up-with-applyAsync.html
    .config(["$httpProvider", ($httpProvider: IHttpProvider) => $httpProvider.useApplyAsync(true)])
    .run(["$mdDateLocale", "businessModels", (
        $mdDateLocale: angular.material.IDateLocaleProvider, models: BusinessModelService) => {
        $mdDateLocale.formatDate = function(dateDate) {
            return dateDate ? models.User.current.formatDate(dateDate) : null;
        };
        $mdDateLocale.parseDate = function(dateString) {
            var m = models.User.current.parseDate(dateString);
            return m.isValid() ? m.toDate() : null;
        }}
    ]);

//Merge our decorated function into the toast service type.
declare module "angular" {
    export namespace material {
        interface IToastService {
            multi: (typeof MultiToast)["toastOptions"];
        }
    }
}