import { IToolScope, Tool } from "./tool";
import { ImageEditorController } from "../imageEditorCore";
import ToolActivationContext from "./toolActivationContext";
import { Vector2 } from "../../../utility/utils";
import { Drawing } from "../shapes/shapes";
import { IDirectiveFactory, IAttributes, IAugmentedJQuery } from "angular";

interface IDrawingModel {
    type: string;
    thickness: number;
    primaryColour: string;
    points: Vector2[];
}

interface IDrawingScope extends IToolScope {
    config: {
        thickness: number;
        primaryColour: string;
    }
}

export class PenTool extends Tool<Drawing, IDrawingModel> {
    $scope: IDrawingScope;

    protected $createViewModel(dataModel?: IDrawingModel): Drawing {
        const cmToStage = this.cmToStage(1);
        const points = ((dataModel && dataModel.points) || []).map(p => new Vector2(p).multiply(cmToStage));
        const model = dataModel || this.$scope.config;
        const vm = new Drawing(points, model.thickness * cmToStage, model.primaryColour);
        if (this.compositeOp) {
            vm.ink.compositeOperation = this.compositeOp;
        }
        this.$copyTraceSettingsTo(vm);
        return vm;
    }

    protected $updateDataModel(viewModel: Drawing = this.$viewModel, dataModel?: IDrawingModel): IDrawingModel {
        dataModel = dataModel || <any>{};
        dataModel.type = this.$shapeType;
        dataModel.thickness = this.stageToCm(viewModel.thickness);
        dataModel.primaryColour = viewModel.colour;
        const stageToCm = this.stageToCm(1);
        dataModel.points = viewModel.points.map(p => p.clone().multiply(stageToCm));
        return dataModel;
    }

    activate(context: ToolActivationContext) {
        super.activate(context);
        const editModel = <IDrawingModel>context.editModel;
        this.$viewModel = this.$createViewModel(editModel);
        this.$ctrl.context.scratch.addChild(this.$viewModel.ink);
        this.update();

        const pipe = this.$pipe;
        context.dispose.add(
            this.watchConfig("thickness", pipe.unitToStage("cm"), pipe.setViewModel("thickness")),
            this.watchConfig("primaryColour", pipe.setViewModel("colour")));

        if (context.editModel) {
            context.controls.createGripControl().bind(
                this.$viewModel.ink,
                () => context.updateStage());
            this.addAcceptDeselectButtons(context);
        } else {
            context.controls.createInteractControl().subscribe(
                p => this.$viewModel.addPoint(p, true, this.$isEditing),
                p => this.$viewModel.addPoint(p, true, this.$isEditing),
                context.accept.bind(context),
                context.deselect.bind(context, true));
        }
        context.observe("accept", () => {
            this.$viewModel.bakeTransform();
            context.addOrUpdate(this.$updateDataModel(this.$viewModel, editModel));
            context.deselect(true);
        });
        context.observe("deselect", context.reactivate.bind(context));
        context.observe("delete", () => {
            context.removeEditShape();
            context.reactivate()
        });
    }

    constructor(
        public id: string,
        shapeType: string,
        ctrl: ImageEditorController,
        scope: IToolScope,
        public compositeOp: string = null) {
        super(ctrl, scope, shapeType);
    }
}

 /**
  * This directive allows free drawing with configurable thickness and colour.
  * Requires a mapping to a config object of the following format:
  * scope.config = { primaryColour: 'white', thickness: 5 };
  * ...
  * <div pen-tool="config"></div>
  */
export default <IDirectiveFactory>(() => ({
    require: "^mdsImageEditor",
    restrict: "E",
    scope: <{ [key: string]: string }>{
        config: "="
    },

    link(scope: IToolScope, _element: IAugmentedJQuery, _attr: IAttributes, ctrl: ImageEditorController) {
        ctrl.tools.registerTool("pen", new PenTool("pen", "drawing", ctrl, scope));
    }
}));