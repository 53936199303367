
import { MeasurementController, IMeasurementScope } from "../MeasurementController";
import * as angular from "angular";

class STDIntestinalController extends MeasurementController {

  static $inject = ["$scope"];
  constructor(scope: IMeasurementScope) {
    super(scope);
    this.watch();
  }

  watch(): void {
    console.log("Starting");
    this.watchMeasurement("GEColonAscendingProxMWT", () => this.calcColonDescription1("GEColonAscendingProxMWT", "GEColonAscendingProxDescription"));
    this.watchMeasurement("GEColonAscendingMidMWT", () => this.calcColonDescription1("GEColonAscendingMidMWT", "GEColonAscendingMidDescription"));
    this.watchMeasurement("GEColonAscendingDistMWT", () => this.calcColonDescription1("GEColonAscendingDistMWT", "GEColonAscendingDistDescription"));
    this.watchMeasurement("GEColonDescendingProxMWT", () => this.calcColonDescription2("GEColonDescendingProxMWT", "GEColonDescendingProxDescription"));
    this.watchMeasurement("GEColonDescendingMidMWT", () => this.calcColonDescription2("GEColonDescendingMidMWT", "GEColonDescendingMidDescription"));
    this.watchMeasurement("GEColonDescendingDistMWT", () => this.calcColonDescription2("GEColonDescendingDistMWT", "GEColonDescendingDistDescription"));
    this.watchMeasurement("GEColonTransverseProxMWT", () => this.calcColonDescription1("GEColonTransverseProxMWT", "GEColonTransverseProxDescription"));
    this.watchMeasurement("GEColonTransverseMidMWT", () => this.calcColonDescription1("GEColonTransverseMidMWT", "GEColonTransverseMidDescription"));
    this.watchMeasurement("GEColonTransverseDistMWT", () => this.calcColonDescription1("GEColonTransverseDistMWT", "GEColonTransverseDistDescription"));
    this.watchMeasurement("GEColonSigmoidProxMWT", () => this.calcColonDescription2("GEColonSigmoidProxMWT", "GEColonSigmoidProxDescription"));
    this.watchMeasurement("GEColonSigmoidMidMWT", () => this.calcColonDescription2("GEColonSigmoidMidMWT", "GEColonSigmoidMidDescription"));
    this.watchMeasurement("GEColonSigmoidDistMWT", () => this.calcColonDescription2("GEColonSigmoidDistMWT", "GEColonSigmoidDistDescription"));
    this.watchMeasurement("GEColonAnastMWT", () => this.calcColonDescription2("GEColonAnastMWT", "GEColonAnastDescription"));
    this.watchMeasurement("GEColonCaecumMWT", () => this.calcColonDescription1("GEColonCaecumMWT", "GEColonCaecumDescription"));
    this.watchMeasurement("GEIleumMWT", () => this.calcColonDescription1("GEIleumMWT", "GEIleumDescription"));
    this.watchMeasurement("GEIleumProxMWT", () => this.calcColonDescription1("GEIleumProxMWT", "GEIleumProxDescription"));
    this.watchMeasurement("GEIleumDistMWT", () => this.calcColonDescription1("GEIleumDistMWT", "GEIleumDistDescription"));
    this.watchMeasurement("GEIleumSecondMWT", () => this.calcColonDescription1("GEIleumSecondMWT", "GEIleumSecondDescription"));
    this.watchMeasurement("GEIleumThirdMWT", () => this.calcColonDescription1("GEIleumThirdMWT", "GEIleumThirdDescription"));
    this.watchMeasurement("GESmallBowelRLQMWT", () => this.calcColonDescription2("GESmallBowelRLQMWT", "GESmallBowelRLQDescription"));
    this.watchMeasurement("GESmallBowelRUQMWT", () => this.calcColonDescription2("GESmallBowelRUQMWT", "GESmallBowelRUQDescription"));
    this.watchMeasurement("GESmallBowelLLQMWT", () => this.calcColonDescription2("GESmallBowelLLQMWT", "GESmallBowelLLQDescription"));
    this.watchMeasurement("GESmallBowelLUQMWT", () => this.calcColonDescription2("GESmallBowelLUQMWT", "GESmallBowelLUQDescription"));
    this.watchMeasurement("GESmallBowelSuprapubicMWT", () => this.calcColonDescription2("GESmallBowelSuprapubicMWT", "GESmallBowelSuprapubicDescription"));
  }

  calcColonDescription1(thicknessKey: string, descriptionKey: string) {
    const rawValue: number = Number(this.getMeasurementValue(thicknessKey));
    const key: string = this.getMeasurementValue(descriptionKey);
    console.log("Evaluate thickness", rawValue, key);
    if (isNaN(rawValue)) { return; }

    console.log("null string");
    if (rawValue <= 2.4) { this.setMeasurementValue(descriptionKey, "normal"); return; }
    if (rawValue <= 5.4) { this.setMeasurementValue(descriptionKey, "mild"); return; }
    if (rawValue <= 7.4) { this.setMeasurementValue(descriptionKey, "moderate"); return; }
    if (rawValue > 7.4) { this.setMeasurementValue(descriptionKey, "severe"); }

  }
  calcColonDescription2(thicknessKey: string, descriptionKey: string) {
    const rawValue: number = Number(this.getMeasurementValue(thicknessKey));
    const key: string = this.getMeasurementValue(descriptionKey);
    console.log("Evaluate thickness", rawValue, key);
    if (isNaN(rawValue)) { return; }
    {
      if (rawValue <= 3) { this.setMeasurementValue(descriptionKey, "normal"); return; }
      if (rawValue <= 5.4) { this.setMeasurementValue(descriptionKey, "mild"); return; }
      if (rawValue <= 7.4) { this.setMeasurementValue(descriptionKey, "moderate"); return; }
      if (rawValue > 7.4) { this.setMeasurementValue(descriptionKey, "severe"); return; }

    }
  }
}
 
angular.module("midas.utility.measurement.views").controller("STDIntestinalCtrl", STDIntestinalController);