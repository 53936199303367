/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import * as angular from "angular";
angular.module("midas.utility.measurement.views").controller("STDStressEchoCtrl", ["$scope", function($scope) {
  const preChamberKeys = ["STPreApicalAnt","STPreMidInf","STPreMidAnt","STPreBasalInf","STPreBasalAnt","STPreApicalInf","STPreApicalSept","STPreMidInfLat","STPreMidAntSept","STPreBasalInfLat","STPreBasalAntSept","STPreApicalLat","STPreMidInfSept","STPreMidAntLat","STPreBasalInfSept","STPreBasalAntLat"];
  const postChamberKeys = ["STApicalAnt","STMidInf","STMidAnt","STBasalInf","STBasalAnt","STApicalInf","STApicalSept","STMidInfLat","STMidAntSept","STBasalInfLat","STBasalAntSept","STApicalLat","STMidInfSept","STMidAntLat","STBasalInfSept","STBasalAntLat"];
  $scope.postAllNormal = () => postChamberKeys.map((key) => (($scope.measurements.values[key] != null ? $scope.measurements.values[key].value = "1" : undefined)));
  $scope.preAllNormal = () => preChamberKeys.map((key) => (($scope.measurements.values[key] != null ? $scope.measurements.values[key].value = "1" : undefined)));
  $scope.postClear = () => postChamberKeys.map((key) => (($scope.measurements.values[key] != null ? $scope.measurements.values[key].value = null : undefined)));
  $scope.preClear = () => preChamberKeys.map((key) => (($scope.measurements.values[key] != null ? $scope.measurements.values[key].value = null : undefined)));
}
]);
