import "angular-material";
import { IController, module, ILogService, IWindowService, IFormController } from "angular";
import { BusinessModelService, Site } from "../../businessModels";
import uniqueValidation from "../../utility/unique-validation.directive";
import mdsFocusWhen from "../../utility/utils";
import "./site-administration.component.scss";

class SiteViewModel {
  constructor(
    private readonly entity: Site,
    public isExpanded: boolean = false,
    public isNew: boolean = false,
    public isUpdating: boolean = false,
    public name: string = entity.name) {}

    public commit(): void {
      this.entity.name = this.name;
    }

    public delete(): void {
      this.entity.delete();
    }

    public form: IFormController;
}

class SitesController implements IController {
    public sites: SiteViewModel[];
    public siteNames: string[];
    public error: string;
    public forms: { [id: number]: IFormController; } = {};

    static $inject = [
      "businessModels",
      "$log",
      "$mdToast",
      "$window"];

    constructor(
      private readonly models: BusinessModelService,
      private readonly $log: ILogService,
      private readonly $mdToast: angular.material.IToastService,
      private readonly $window: IWindowService) {}

    $onInit(): void {
      this.models.Site
        .list()
        .then(models => {
          this.sites = models.map(x => new SiteViewModel(x));
          this.refreshSiteNames();
        } )
        .catch(x =>
          this.logAndDisplayError(x, "There was an error loading the sites. Please reload the page to try again."));
    }

    private refreshSiteNames(): void {
      this.siteNames = [];
      for (const site of this.sites) {
        this.siteNames.push(site.name);
      }
    }

    public reload(): void {
      this.$window.location.reload();
    }

    public create(): void {
      this.sites.splice(0, 0,
        new SiteViewModel(this.models.Site.create(), true, true)
      );
    }

    public cancel(site: SiteViewModel): void {
      this.removeSite(site);
      this.models.breeze.rejectChanges();
    }

    public delete(site: SiteViewModel): void {
      this.models
        .save(() => {
          site.delete();
        })
        .then(() => {
          this.removeSite(site);
          this.$mdToast.showSimple("Site deleted.");
        })
        .catch((x) => this.logAndDisplayError(x, "There was a problem deleting this site. Please try again"));
    }

    public update(site: SiteViewModel): void {
      site.commit();
      site.isUpdating = true;
      this.models
        .save()
        .then(() => {
          if (site.isNew) {
            site.isNew = false;
            this.$mdToast.showSimple("Site added.");
          } else {
            this.$mdToast.showSimple("Site updated.");
          }
          this.refreshSiteNames();
          site.form.$setPristine();
        })
        .catch((x) => this.logAndDisplayError(x, "There was a problem saving the site. Please try again."))
        .finally(() => site.isUpdating = false);
    }

    private logAndDisplayError(error: any, text: string): void {
      this.$log.error(error);
      this.$mdToast.showSimple(text);
    }

    private removeSite(site: SiteViewModel): void {
      this.sites.splice(this.sites.indexOf(site), 1);
    }
}

export default module("midas.admin.siteAdministration", 
  [uniqueValidation.name, mdsFocusWhen.name])
.component("siteAdministration", {
  controller: SitesController,
  templateUrl: require("./site-administration.component.html")  
});