import ReferenceGroup from "./ReferenceGroup";
import { IQService } from "angular";
import { LoDashStatic } from "lodash";
import { Study, BusinessModelService } from "../../../businessModels";
import * as thumbnailUrl from "../templates/imageThumbnail.html";
import * as fullUrl from "../templates/imageFull.html";

export default class ChartGroup extends ReferenceGroup {
    constructor(public study: Study,
        protected businessModels: BusinessModelService,
        private lodash: LoDashStatic,
        private $q: IQService) {
        super("Charts", thumbnailUrl, fullUrl);

        this.iconClass = "fa fa-pie-chart";
    }

    loadThumbnails() {
        return this.businessModels.Study.find({ id: this.study.id }, "charts.image")
            .then(() => {
                const charts = this.study.charts.slice(0);
                this.thumbnails = [];
                for (let i = 0; i < charts.length; i++) {
                    const chart = charts[i];
                    this.thumbnails.push(chart.image);
                }
                return this.$q.when(this.thumbnails);
            });
    }
}