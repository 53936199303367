import * as angular from "angular";
import * as tinymce from 'tinymce';
import angularTinyMceModule from "./utility/tinymce/angular-tinymce";
import toolbarButtonModule from "./utility/tinymce/Plugins/toolbarButton";
import 'tinymce/themes/modern/theme';
import mdsReportEditorModule from "./utility/tinymce/mds-rich-text-editor.component";

// Ensure the skins folder is available for tinymce to download async as it needs. This case is
// handled specifically in webpack.config.ts.
const requireSkins = require["context"]('tinymce/skins', true, /./);
requireSkins.keys().forEach(requireSkins);

export default angular.module("midas.tinymce", [
    angularTinyMceModule.name,
    mdsReportEditorModule.name,
    toolbarButtonModule.name]);