import { MeasurementController, IMeasurementScope } from "../MeasurementController";
import * as angular from "angular";

class CTNeckController extends MeasurementController {
  static $inject = ["$scope"];
  constructor(scope: IMeasurementScope) {
    super(scope);
  }

  clickOK() {
    this.setMeasurementValue("CTNeckRtOrbits", "Normal. ");
    this.setMeasurementValue("CTNeckRtNasopharynx", "Normal. ");
    this.setMeasurementValue("CTNeckRtSuprahyoidNeck", "Normal oropharynx, oral cavity, parapharyngeal space and retropharyngeal space. ");
    this.setMeasurementValue("CTNeckRtInfrahyoidNeck", "Normal larynx, hypopharynx and supraglottis. ");
    this.setMeasurementValue("CTNeckRtThyroid", "Normal. ");
    this.setMeasurementValue("CTNeckRtThoracicInlet", "Normal lung apices and brachial plexus.");
    this.setMeasurementValue("CTNeckRtLymphNodes", "Normal. No lymphadenopathy. ");
    this.setMeasurementValue("CTNeckRtVascularStructures", "Normal. ");
    this.setMeasurementValue("CTNeckRtOther", "None. ");
    this.setMeasurementValue("CTNeckConclusion", "Normal examination. ");
    console.log("All normal clicked")
  }

}

angular.module("midas.utility.measurement.views").controller("CTNeckCtrl", CTNeckController);