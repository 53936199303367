import * as Raven from "raven-js";
import * as angularRaven from "raven-js/plugins/angular";
import * as angular from "angular";

let shouldSend: boolean = false;

Raven.config("https://067946ba63e644f9814418ac1a996b4a@sentry.io/80674")
     .addPlugin(angularRaven, angular)
     .setShouldSendCallback(() => shouldSend)
     .install();

export function enable() { shouldSend = true; }
export function disable() { shouldSend = false; }

export const moduleName: string = "ngRaven";