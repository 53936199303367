import {
    module as ngModule,
    IScope,
    IAttributes} from "angular";
import { Editor, Settings, ui } from "tinymce";
import reportEditorModule, {
    TinymceConfigurationController,
    tinymceConfigurationRequire,
    ToolbarAttributes
} from "../mds-rich-text-editor.component";
import panelModule, {
  serviceName as blueprintPanelsName,
  BlueprintPanelsService
} from '../../../admin/reportFormatters/dialogs/blueprint-panel.service';
import conditionModule, {
  serviceName as condServiceName,
  ConditionalService} from '../../reportFormatter/widgets/conditional.service';
import isEditableModule, {
  serviceName as isEditableServiceName,
  EditableService
} from './editable.service';
import { TinymceButtonOptions } from './toolbarButton';
import { IDisposable, safeDispose } from '../../../utility/disposable';

/** A CSS selector for matching all relevant block elements. */
const blockSelector = [
  "p",
  "h1", "h2", "h3", "h4", "h5", "h6",
  "ol", "ul",
  "pre",
  "address",
  "blockquote",
  "dl",
  "div",
  "fieldset",
  "form",
  "hr",
  "noscript",
  "table"
].join(",");

const zwnbs = String.fromCharCode(65279);
const nbsp = '\xa0';

/**
 * TinyMCE plugin to provide a button which will prompt the user for a condition.
 * The blueprint-panel-plugin is responsible for handling the accept events and interacting with the template.
 */
class InsertConditionController extends TinymceConfigurationController {
  panelId: string;

  static $inject = [
    "$scope",
    "$attrs",
    condServiceName,
    isEditableServiceName,
    blueprintPanelsName
  ];
  constructor(
    private readonly $scope: IScope,
    private readonly $attrs: IAttributes,
    private readonly condService: ConditionalService,
    private readonly editable: EditableService,
    private readonly panels: BlueprintPanelsService) {
    super();
  }

  /** Add insert button to toolbar. */
  configure(options: Settings) {
    const toolbar = ToolbarAttributes.FromAttributes(this.$attrs);
    this.extendToolbar(toolbar.id, "insert-condition", options);
  }

  /** Define condition insert button and handle click. */
  setup(editor: Editor) {
    const me = this;
    const buttonOptions = <TinymceButtonOptions> {
      type: "button",
      tooltip: "Insert condition into report",
      text: "Insert Condition",
      onPostRender() { me.disableOnInvalidSelection(editor, this); },
      onclick: () => this.$scope.$apply(() => this.insert(editor))
    };
    editor.addButton("insert-condition", buttonOptions);
  }

  private disableOnInvalidSelection(editor: Editor, button: ui.Control): void {
    editor.on("NodeChange", () => {
      button.disabled(!this.editable.canEdit(editor.selection.getRng(true)));
    });
  }

  private insert(editor: Editor): void {
    const panel = this.panels.get(this.panelId);
    if (panel) {
      const range = editor.selection.getRng(true).cloneRange();
      const widget = this.condService.create(!range.collapsed && this.isBlockElement(editor));
      panel.show(widget).then(result => {
        if (result === "accept") {
          const cleanup = this.makeRangeSafe(range);
          try {
            widget.appendContent(range.collapsed
              ? nbsp // So there's selectable text there.
              : range.extractContents());
            widget.insert(editor);

            const contentNodes = widget.getContentNodes();
            if (contentNodes.length > 0) {
              editor.selection.select(contentNodes[0], true);
            }
            editor.nodeChanged();
          } finally {
            safeDispose(cleanup);
          }
        }
      });
    }
  }

  /** Ensures a range is safe by inserting some bogus HTML in some cases, so that operations which
   * extract or edit the DOM don't go backtracking past the start of the range looking for a text
   * element. That can lead to stuff ending up in places it shouldn't. */
  private makeRangeSafe(range: Range): IDisposable {
    if (range.startOffset === 0) {
      // A bogus node we sometimes insert to ensure tinymce/browser stops here, rather than
      // tracking back to the previous text node, which could be outside the selection.
      let bogusNode: Text = null;
      bogusNode = document.createTextNode(zwnbs);
      if (range.startContainer.nodeType === 3) {
        range.startContainer.parentNode.insertBefore(bogusNode, range.startContainer);
      } else {
        range.startContainer.insertBefore(bogusNode, range.startContainer.firstChild);
      }
      if (range.startContainer.nodeType !== 3) {
        // If the selection is not a text node then move it after the bogus node,
        // otherwise no change is needed.
        range.setStart(range.startContainer, 1);
      }
      return () => {
        if (bogusNode != null && bogusNode.nodeValue === zwnbs) {
          bogusNode.remove();
        }
      };
    }

    return null;
  }

  private isBlockElement(editor: Editor): boolean {
    return editor.selection.getNode().querySelector(blockSelector) != null;
  }
}

export default ngModule("midas.utility.tinymce.insert-condition", [
    reportEditorModule.name,
    conditionModule.name,
    panelModule.name,
    isEditableModule.name
]).component("insertConditionButton", {
    require: {
        ...tinymceConfigurationRequire
    },
    controller: InsertConditionController,
    bindings: {
      panelId: "@"
    }
});