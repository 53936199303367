import { MeasurementController, IMeasurementScope } from "../MeasurementController";
import * as angular from "angular";

class DXAnkleController extends MeasurementController {
  static $inject = ["$scope"];
  constructor(scope: IMeasurementScope) {
    super(scope);
  }

  clickRightOK() {
    this.setMeasurementValue("DXAnkleRtBones", "Normal anatomic alignment. No fracture or dislocation. ");
    this.setMeasurementValue("DXAnkleRtMineralization", "Normal. ");
    this.setMeasurementValue("DXAnkleRtJoints", "Normal. No arthritis. ");
    this.setMeasurementValue("DXAnkleRtOther", "None. ");
    console.log("Right All normal clicked")
  }

  clickLeftOK() {
    this.setMeasurementValue("DXAnkleLtBones", "Normal anatomic alignment. No fracture or dislocation. ");
    this.setMeasurementValue("DXAnkleLtMineralization", "Normal. ");
    this.setMeasurementValue("DXAnkleLtJoints", "Normal. No arthritis. ");
    this.setMeasurementValue("DXAnkleLtOther", "None. ");
    console.log("Left All normal clicked")
  }
}

angular.module("midas.utility.measurement.views").controller("DXAnkleCtrl", DXAnkleController);
